import { UIRouter } from '@uirouter/angular';
import { isEqual, isNil } from 'lodash-es';

export const customParamTypesUiRouterConfigs = (router: UIRouter) => {
    // A bool param type for cases where it also needs to be nullable (which is
    // not supported by the UI-Router built-in `bool` type)
    // Note: To keep with the convention set by UI-Router, we store the value in the URL as `'0' = false` and
    // `'1' = true`, although theoretically we could store it as any two different string values.
    router.urlService.config.type('nullable-bool', {
        encode: (value?: boolean) => (typeof value === 'boolean' ? `${Number(value)}` : undefined),
        decode: (urlValue?: string) => {
            if (!urlValue || (urlValue !== '0' && urlValue !== '1')) return undefined;

            return Boolean(parseInt(urlValue));
        },
        is: val => typeof val === 'boolean' || isNil(val),
        pattern: /[^/]+/,
        equals: (a, b) => (isNil(a) && isNil(b)) || isEqual(a, b),
    });
};
