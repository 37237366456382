import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { SuppliersResource } from '../../modules/core/resources/suppliers.resource';
import { NotificationService } from '../../modules/core/services/notification.service';

import {
    ActionsUnion,
    getAgentSuppliers,
    getAgentSuppliersError,
    getAgentSuppliersSuccess,
    getAllSuppliers,
    getAllSuppliersError,
    getAllSuppliersSuccess,
    getPropertySuppliers,
    getPropertySuppliersError,
    getPropertySuppliersSuccess,
    updateAgentSuppliers,
    updateAgentSuppliersError,
    updateAgentSuppliersSuccess,
    updatePropertySuppliers,
    updatePropertySuppliersError,
    updatePropertySuppliersSuccess,
} from './internal-suppliers.actions';

@Injectable()
export class InternalSuppliersEffects {
    constructor(
        private actions$: Actions<ActionsUnion>,
        private suppliersResource: SuppliersResource,
        private notificationService: NotificationService,
    ) {}

    public getAllSuppliers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAllSuppliers),
            switchMap(() =>
                this.suppliersResource.getAll().pipe(
                    map(suppliers => getAllSuppliersSuccess({ suppliers })),
                    catchError(error => {
                        this.notificationService.httpError(error, {}, 'Error loading suppliers. Please try refreshing');
                        return of(getAllSuppliersError());
                    }),
                ),
            ),
        ),
    );

    public getPropertySuppliers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getPropertySuppliers),
            switchMap(({ propertyId }) =>
                this.suppliersResource.getForProperty(propertyId).pipe(
                    map(suppliers => getPropertySuppliersSuccess({ suppliers, propertyId })),
                    catchError(error => {
                        this.notificationService.httpError(
                            error,
                            {},
                            'Error loading property suppliers. Please try refreshing',
                        );
                        return of(getPropertySuppliersError());
                    }),
                ),
            ),
        ),
    );

    public getAgentSuppliers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAgentSuppliers),
            switchMap(({ agentId }) =>
                this.suppliersResource.getForAgent(agentId).pipe(
                    map(suppliers => getAgentSuppliersSuccess({ suppliers, agentId })),
                    catchError(error => {
                        this.notificationService.httpError(
                            error,
                            {},
                            'Error loading agent suppliers. Please try refreshing',
                        );
                        return of(getAgentSuppliersError());
                    }),
                ),
            ),
        ),
    );

    public updatePropertySuppliers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updatePropertySuppliers),
            switchMap(({ propertyId, suppliers }) =>
                this.suppliersResource.updateForProperty(propertyId, suppliers).pipe(
                    map(suppliers => updatePropertySuppliersSuccess({ suppliers, propertyId })),
                    catchError(error => {
                        this.notificationService.httpError(
                            error,
                            {},
                            'Error updating property suppliers. Please try again',
                        );
                        return of(updatePropertySuppliersError());
                    }),
                ),
            ),
        ),
    );

    public updateAgentSuppliers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateAgentSuppliers),
            switchMap(({ agentId, suppliers }) =>
                this.suppliersResource.updateForAgent(agentId, suppliers).pipe(
                    map(suppliers => updateAgentSuppliersSuccess({ suppliers, agentId })),
                    catchError(error => {
                        this.notificationService.httpError(
                            error,
                            {},
                            'Error updating agent suppliers. Please try again',
                        );
                        return of(updateAgentSuppliersError());
                    }),
                ),
            ),
        ),
    );
}
