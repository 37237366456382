import { Injector } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { map } from 'rxjs/operators';

import { dashboardRoot, internalRoot, vendorRoot } from '../../common/constants/state-names.constants';
import { ThemeName, ThemeService } from '../../modules/core/services/theme.service';

export const vendorAppThemeUiRouterHook = (router: UIRouter, injector: Injector) => {
    router.transitionService.onBefore(
        {
            to: (_, transition) => {
                const fromLongName = transition.$from().toString();
                const toLongName = transition.$to().toString();

                const fromVoid = transition.$from().name === '';
                const fromVendorApp = fromLongName.startsWith(vendorRoot) || fromLongName.startsWith(dashboardRoot);
                const toVendorApp = toLongName.startsWith(vendorRoot) || toLongName.startsWith(dashboardRoot);

                // Ensure setting theme is only done when transitioning between apps or from void
                if (fromVendorApp && toVendorApp) return false;
                if (fromVoid && toVendorApp) return true;
                if (!fromVendorApp && toVendorApp) return true;
            },
        },
        () => {
            const themeService = injector.get(ThemeService);
            return themeService
                .setStyleTheme()
                .pipe(map(() => true))
                .toPromise();
        },
    );
};

export const internalAppThemeUiRouterHook = (router: UIRouter, injector: Injector) => {
    router.transitionService.onBefore(
        {
            to: (_, transition) => {
                const fromVoid = transition.$from().name === '';
                const fromInternalApp = transition.$from().toString().startsWith(internalRoot);
                const toInternalApp = transition.$to().toString().startsWith(internalRoot);

                if (fromInternalApp && toInternalApp) return false;
                if (fromVoid && toInternalApp) return true;
                if (!fromInternalApp && toInternalApp) return true;
            },
        },
        () => {
            const themeService = injector.get(ThemeService);
            const internalTheme = ThemeName.NurtureCloud;

            return themeService
                .setStyleTheme({ specificTheme: internalTheme })
                .pipe(map(() => true))
                .toPromise();
        },
    );
};
