import { NgModule } from '@angular/core';

import { APPOINTMENTS_TYPE_CONFIG, APPOINTMENTS_TYPE_DI_CONFIG } from './appointment-type.constants';
import { AUSTRALIAN_STATES_CONFIG, AUSTRALIAN_STATES_DI_CONFIG } from './australian-states.constants';
import { FILE_TAG_CONFIG, FILE_TAG_DI_CONFIG } from './file-tag.constant';
import { INTERNAL_PASSWORD_CONFIG, INTERNAL_PASSWORD_DI_CONFIG } from './internal-password.constants';
import { ROLES_CONFIG, ROLES_DI_CONFIG } from './roles.constant';
import { SERVICE_CONFIG, SERVICE_DI_CONFIG } from './service.constants';

// max price as determined by the back end internals
// note: can be removed once the api stops sending this value down when the user hasn't set a max price
export const apiMaxPrice = 2147483647;

@NgModule({
    providers: [
        { provide: ROLES_CONFIG, useValue: ROLES_DI_CONFIG },
        { provide: APPOINTMENTS_TYPE_CONFIG, useValue: APPOINTMENTS_TYPE_DI_CONFIG },
        { provide: FILE_TAG_CONFIG, useValue: FILE_TAG_DI_CONFIG },
        { provide: AUSTRALIAN_STATES_CONFIG, useValue: AUSTRALIAN_STATES_DI_CONFIG },
        { provide: INTERNAL_PASSWORD_CONFIG, useValue: INTERNAL_PASSWORD_DI_CONFIG },
        { provide: SERVICE_CONFIG, useValue: SERVICE_DI_CONFIG },
    ],
})
export class ConstantsModule {}
