import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';

import { canChangeCountryPermission } from '../../../../common/permissions/can-change-country.permission';
import { FeatureService } from '../../../core/services/feature.service';

@Component({
    selector: 'up-nav-landing',
    templateUrl: 'nav-landing.component.html',
    styleUrls: ['nav-landing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavLandingComponent {
    @Input() public customCtaTemplate: TemplateRef<unknown>;
    public readonly canChangeCountryPermission = canChangeCountryPermission;

    public get showPane(): boolean {
        return this.featureService.hasFeature('upsideMarketingPagesEnabled');
    }

    public get isPropertyReportLandingState(): boolean {
        return this.uiRouterGlobals.current.name === 'conversion.property-report.address';
    }

    constructor(private featureService: FeatureService, private uiRouterGlobals: UIRouterGlobals) {}
}
