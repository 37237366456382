import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';

@Pipe({ name: 'ncCalendarTimeFormat' })
export class CalendarTimeFormatPipe implements PipeTransform {
    public transform(value?: dayjs.ConfigType): string | undefined {
        if (isNil(value)) return undefined;

        return dayjs(value).calendar(undefined, {
            sameElse: 'D MMM YYYY [|] h:mm A',
        });
    }
}
