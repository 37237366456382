import { ChangeDetectorRef, Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type Display = 'inline' | 'block';
type LabelPosition = 'before' | 'after';

let switchCount = 0;

@Component({
    selector: 'up-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // Needed for Control Value Accessor
            // eslint-disable-next-line @angular-eslint/no-forward-ref
            useExisting: forwardRef(() => SwitchComponent),
            multi: true,
        },
    ],
})
export class SwitchComponent implements ControlValueAccessor, OnInit {
    @Input() public name = '';
    @Input() public id: string;
    @Input() public display: Display = 'block';
    @Input() public labelPosition: LabelPosition = 'after';
    @HostBinding('class.inline') public inlineClass: boolean;

    public uid = '';
    public checked = false;
    public focused = false;
    public disabled = false;
    private onChange: Function = () => {};

    constructor(private changeDetectorRef: ChangeDetectorRef) {
        switchCount++;

        this.uid = `switch-${switchCount}`;
    }

    public ngOnInit(): void {
        this.uid = this.id || this.uid;
    }

    // called when value of checkbox changes
    public onInputChange(event): void {
        event.stopPropagation();

        const value = event.target.checked;
        this.checked = value;

        this.onChange(value);
    }

    public onFocus(): void {
        this.focused = true;
    }

    public onBlur(): void {
        this.focused = false;
    }

    // Stops a click on the hidden input as we are wrapping it in an associated label element which will also trigger
    // a click event to a child input element, causing multiple clicks form being fired.
    public onInputClick(event: Event): void {
        event.stopPropagation();
    }

    // Control accessor methods
    public writeValue(value: any): void {
        this.checked = value;
        this.changeDetectorRef.markForCheck();
    }

    public registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    public registerOnTouched(): void {}

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
