import { Injectable } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { UserFacade } from '../../store/user/user.facade';

import { UserService } from './user.service';

@Injectable()
export class UserNotificationsService {
    constructor(private userFacade: UserFacade, private userService: UserService) {
        this.userService.userAuthDetailsUpdated$
            .pipe(filter(({ authenticated }) => !authenticated))
            .subscribe(() => this.userFacade.resetNotificationPreferencesState());
    }

    public userHasNotificationsSubscriptions(): Observable<boolean> {
        const isAuthenticated$ = this.userService.userAuthDetailsUpdated$.pipe(
            first(),
            map(({ authenticated }) => authenticated),
        );

        const hasSubscriptionBasedOnAuth$ = isAuthenticated$.pipe(
            filter(isAuthenticated => !isAuthenticated),
            map(() => false),
        );

        isAuthenticated$
            .pipe(filter(isAuthenticated$ => isAuthenticated$))
            .subscribe(() => this.userFacade.getNotificationsPreferences());

        const hasNotificationSubscriptionFromStore$ = this.userFacade.notificationsPreferencesState$.pipe(
            filter(state => !state.loadingNotificationsPreferences),
            map(state => !!state.notificationsPreferences.subscribedAt),
        );

        return merge(hasSubscriptionBasedOnAuth$, hasNotificationSubscriptionFromStore$);
    }
}
