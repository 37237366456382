import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EnvironmentService } from '../../../modules/core/services/environment.service';

@Injectable({ providedIn: 'root' })
export class XhrGetSvgCacheBustHttpInterceptor implements HttpInterceptor {
    constructor(private readonly environmentService: EnvironmentService) {}

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (req.method === 'GET' && req.url.endsWith('.svg')) {
            const modifiedReq = req.clone({
                /*
                 * We sometimes pull in the assets from one bucket from different domains (e.g. both Ray White Testing
                 * and Ray White Demo point at the same GCP storage bucket). Even though the bucket is correctly
                 * returning the Vary: Origin header, Chrome still often seems to cache the request with the
                 * original Origin header, causing an "'Access-Control-Allow-Origin' header has a value '{domain}'
                 * that is not equal to the supplied origin" CORS error. Generating a unique URL for each request
                 * circumvents the issue, which we do by just appending the current environment host as a param.
                 *
                 * Others running into a similar issue:
                 * - https://stackoverflow.com/questions/44800431/caching-effect-on-cors-no-access-control-allow-origin-header-is-present-on-th
                 * - https://issues.chromium.org/issues/41138799
                 * */
                params: req.params.set('host', this.environmentService.config.organisation.host),
            });

            return next.handle(modifiedReq);
        }

        return next.handle(req);
    }
}
