const baseTheme = require('./themes/upside.js');

const workplan = {
    interaction: '#6274c6',
    appointment: '#ff9187',
    reminder: '#218293',
    task: '#ffbd19',
};

const prospecting = {
    trophy: '#f4c324',
    callHighlightedBackground: '#fff6db',
    tagLeadPool: '#9282d6',
    workplanPropensity: '#B88C72',
};

const weeklyCampaignReport = {
    campaignReportVendorPrice: '#56BABC',
};

const reportingFunnel = {
    funnelStageAppraised: '#d65b23',
    funnelStateNotAppraised: '#ec9940',
    funnelStageNotConnected: '#64a7b5',
    funnelStageNotContacted: '#4f77a0',
};

const pricing = {
    pricePlanPremium: '#b18427',
    pricePlanSaver: '#3c4a8c',
    pricePlanClassic: '#f15c4f',
    pricePlanPrestige: '#8b8e9e',
    priceChangeBannerBackground: '#fae0cc',
};

const sms = {
    smsPreviewBackground: '#F8F4F1',
    smsPreviewDots: '#B88C72',
};

const vpaPackages = {
    vpaPackageByoGradient: 'linear-gradient(340deg, #27377e 7%, #4455a8 76%)',
    vpaPackageSilverGradient: 'linear-gradient(340deg, #454752 7%, #6b6f83 76%)',
    vpaPackageGoldGradient: 'linear-gradient(166deg, #F8D080 7%, #CA9D3F 76%)',
    vpaPackagePlatinumGradient: 'linear-gradient(340deg, #9b9b9b 7%, #cecece 76%)',
};

const reminderActions = {
    reminderActionAppraisal: '#c888d6',
    reminderActionBookAppointment: '#ff9187',
};

const texts = {
    linkTextDecoration: 'none',
};

const reporting = {
    reporting1: '#4c5067',
    reporting2: '#676d8a',
    reporting3: '#8b8e9d',
    reporting4: '#aaaaaa',
    reporting5: '#77aaa1',
    reporting6: '#588780',
    reporting7: '#387d74',
    reporting8: '#385c7d',
    reporting9: '#498e9f',
    reporting10: '#73a6b3',
    reporting11: '#e19c52',
    reporting12: '#c2721c',
    reporting13: '#c86234',
    reporting14: '#b04b1d',
    reporting15: '#e06656',
    reporting16: '#f1968b',
    reporting17: '#f08eb4',
    reporting18: '#c888d6',
    reporting19: '#996fec',
    reporting20: '#7746d9',
};

const contactGroups = {
    seller: '#7553d4',
    buyer: '#f78165',
    enquiry: '#218293',
};

const priceEstimateGauge = {
    priceEstimateGaugeLow: '#f15c4f',
    priceEstimateGaugeLowMedium: '#fd8f18',
    priceEstimateGaugeMedium: '#ffbd19',
    priceEstimateGaugeMediumHigh: '#5bc289',
    priceEstimateGaugeHigh: '#77aaa1',
};

const offerStatus = {
    offerStatusWithdrawn: '#ff9187',
};

exports.colors = {
    ...baseTheme.colors,
    ...workplan,
    ...prospecting,
    ...weeklyCampaignReport,
    ...reportingFunnel,
    ...pricing,
    ...sms,
    ...vpaPackages,
    ...reminderActions,
    ...reporting,
    ...contactGroups,
    ...priceEstimateGauge,
    ...offerStatus,
};
exports.fonts = baseTheme.fonts;
exports.texts = texts;
