<up-autocomplete
    class="c-suburb-autocomplete__input"
    [style]="'halo'"
    [icon]="'locationPinFill'"
    [iconColor]="'brand2Regular' | upColor"
    [showSearchButton]="!hideCta"
    [placeholder]="placeholder"
    (valueSelected)="onSelectAddress($event)"
    (termChanged)="searchForTerm($event)"
    (blur)="onBlur()"
    [displayWith]="displayFn"
    [filteredItems]="filteredAutocompleteResults$ | async"
    [label]="ctaLabel || 'Get Started'"
    [loading]="isLoadingMetadata"
    [passiveLoading]="loading$ | async"
    [value]="value"
    [id]="componentId + '-autocomplete'"
    [error]="!!error"
>
</up-autocomplete>

<nc-alert [size]="'small'" [type]="'negative'" class="u-margin-top--sm" *ngIf="error">
    {{ error }}
</nc-alert>
