import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { finalize } from 'rxjs/operators';

import { UpValidators } from '../../../common/form-validators/validators';
import { UserResource } from '../../core/resources/user.resource';

@Component({
    selector: 'up-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    @Input() public email?: string;
    public emailFailed: boolean;
    public loading: boolean;
    public formSubmissionAttempted: boolean;
    public emailControl: FormControl;

    constructor(private userResource: UserResource, private stateService: StateService) {}

    public ngOnInit(): void {
        this.emailControl = new FormControl(this.email || '', [Validators.required, UpValidators.strictEmailValidator]);
    }

    public forgotPassword(): void {
        this.formSubmissionAttempted = true;

        if (this.emailControl.invalid) {
            return;
        }

        this.loading = true;
        this.emailFailed = false;

        const email = this.emailControl.value.trim();
        this.userResource
            .forgotPassword({ email })
            .pipe(finalize(() => (this.loading = false)))
            .subscribe(
                () => this.stateService.go('user.check-email', { email, mode: 'reset-password' }),
                () => (this.emailFailed = true),
            );
    }
}
