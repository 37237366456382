import { ChangeDetectionStrategy, Component, HostBinding, Input, TemplateRef } from '@angular/core';

import { MenuComponent } from '../menu/menu.component';

@Component({
    selector: 'nc-menu-list',
    templateUrl: 'menu-list.component.html',
    styleUrls: ['menu-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuListComponent {
    @Input() public heading: string;
    @Input() public headingRef?: TemplateRef<any>;

    @HostBinding('class.is-appearance-small')
    public get isAppearanceSmall(): boolean {
        return this.menuComponent.appearance === 'small';
    }

    @HostBinding('class.is-appearance-large')
    public get isAppearanceLarge(): boolean {
        return this.menuComponent.appearance === 'large';
    }

    constructor(private readonly menuComponent: MenuComponent) {}

    @HostBinding('class.has-heading')
    public get hasHeading(): boolean {
        return !!(this.heading || this.headingRef);
    }
}
