<up-agent-profile-card
    [firstName]="agent.firstName"
    [transparentProfileImage]="useTransparentImage ? agent.transparentProfileImage : undefined"
    [avatar]="agent.avatar"
    [slug]="agent.slug"
>
    <div *ngIf="caption" class="u-text-label--sm u-margin-right--md u-margin-bottom--sm">{{ caption }}</div>
    <div class="name-rating">
        <up-link
            class="u-text-weight--semi-bold u-display--inline-block u-margin-right--sm"
            [target]="'_blank'"
            [upSref]="'agent-profiles.agent-profile'"
            [upSrefParams]="{ slug: agent.slug }"
            >{{ agent.firstName }} {{ agent.lastName }}</up-link
        >
        <up-rma-rating *ngIf="showRMARating" [size]="'small'" [rating]="agent.rating"></up-rma-rating>
    </div>
    <div>
        <up-icon [size]="'small'" [name]="'phone'"></up-icon>
        <up-link [href]="'tel:' + agent.phoneNumber">{{ agent.phoneNumber }}</up-link>
    </div>
    <div>
        <up-icon [size]="'small'" [name]="'envelope'"></up-icon>
        <up-link [href]="'mailto:' + agent.email">{{ agent.email }}</up-link>
    </div>
</up-agent-profile-card>
