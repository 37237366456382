<div
    class="autocomplete"
    [ngClass]="{
        'empty-search': !dropdownVisible || !term.length,
        'search-button-visible': showSearchButton,
        'style-halo': style === 'halo',
        'is-focused': isFocused,
        'is-loading': loading,
        'is-error': error,
        'is-list-visible': isListVisible,
        'is-list-above': isListAbove
    }"
    #autocompleteWrapper
    role="combobox"
    aria-haspopup="true"
    [attr.aria-expanded]="dropdownVisible && term.length"
>
    <div class="input-focus-glow"></div> <label class="u-hide-visually" [for]="inputId">Input your search term</label>
    <div class="input-wrapper">
        <up-input
            [error]="error"
            [clearable]="true"
            [icon]="icon"
            [internalLabel]="true"
            [size]="'large'"
            [style]="style === 'halo' ? 'full' : style"
            [fullWidth]="true"
            autocomplete="off"
            [label]="placeholder"
            (keydown)="onKeyDown($event)"
            [id]="inputId"
            [iconColor]="iconColor"
            class="input"
            role="textbox"
            type="text"
            [(ngModel)]="term"
            [disabled]="disabled || loading"
            [attr.aria-busy]="loading || passiveLoading"
            [attr.aria-activedescendant]="(filteredItems && filteredItems[activeItemIndex]) || ''"
            (ngModelChange)="onTermChanged($event)"
            (focus)="onFocus()"
            (blur)="onBlur()"
            [passiveLoading]="passiveLoading"
        >
        </up-input>
        <up-spinner class="loader" [size]="'small'"> </up-spinner>
    </div>
    <button
        nc-button
        [style]="'primary'"
        [size]="'large'"
        [attr.aria-label]="label"
        (click)="onValueSelected()"
        class="search-button"
        *ngIf="showSearchButton"
    >
        <span class="search-button-text u-text-body--sm u-padding-right--2xs"
            ><strong>{{ label }}</strong></span
        >
        <up-icon class="search-button-icon" [name]="buttonIcon" [color]="'current-color'"> </up-icon>
    </button>
    <ul class="list" [class.above]="isListAbove" [class.is-visible]="isListVisible" #dropdown role="listbox">
        <li class="list-item is-not-interactive" *ngIf="!filteredItems?.length && term.length">No results found</li>
        <li
            #dropdownItem
            class="list-item"
            role="option"
            *ngFor="let item of filteredItems; let i = index"
            (click)="onValueSelected(item)"
            [ngClass]="{ active: i === activeItemIndex }"
        >
            <span [innerHTML]="displayWith(item) | ncHighlightTextPartial: term"></span>
        </li>
    </ul>
</div>

<nc-help-text *ngIf="showHint" [type]="'error'"> Please select an item from the list </nc-help-text>
