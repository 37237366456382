<div
    class="modal"
    #modal
    [ngClass]="{
        'is-open': isVisible
    }"
    (click)="onClick($event)"
    [attr.aria-hidden]="ariaHidden"
>
    <div class="overlay" [@fadeInState]="ariaHidden ? animationStateOut : animationStateIn"></div>
    <div #modalOutOfBounds class="inner" tabindex="-1">
        <div
            #dialogHost
            class="dialog-host"
            role="dialog"
            aria-modal="true"
            [@attentionState]="attentionAnimationState"
            (@attentionState.done)="onAttentionAnimationDone()"
            [@fadeInState]="ariaHidden ? animationStateOut : animationStateIn"
            [@slideInState]="ariaHidden ? animationStateOut : animationStateIn"
            (@slideInState.done)="onAnimationDone($event)"
            [attr.aria-labelledby]="ariaLabelledBy"
        >
            <ng-template up-modal-host></ng-template>
        </div>
    </div>
</div>
