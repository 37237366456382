import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { MediaTagName } from '../../../../common/models/domain/media/media-tag-name.model';
import { MediaTagsService } from '../../../../common/services/media-tags/media-tags.service';

@Pipe({ name: 'ncPrettyMediaTag$' })
export class PrettyMediaTagPipe implements PipeTransform {
    constructor(private readonly mediaTagsService: MediaTagsService) {}

    public transform(value: MediaTagName): Observable<string> {
        return this.mediaTagsService.getMediaTagDisplayName(value);
    }
}
