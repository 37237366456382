<ul class="social-links-list">
    <li
        class="social-link-item"
        *ngFor="let link of socialLinks"
        [dataEvent]="link.trackingPrefix ? link.trackingPrefix + link.name : undefined"
    >
        <a
            target="_blank"
            [attr.aria-label]="link.name"
            [href]="link.href"
            class="social-link-anchor u-padding-right--md"
        >
            <nc-icon [name]="link.iconName" [color]="'current-color'"></nc-icon>
        </a>
    </li>
</ul>
