const raw = {
    _deepBlue: '#2d376e',
    _deepBlueLight: '#3c4a8c',
    _warmRed: '#f15c4f',
    _warmRedLight: '#ff9187',
    _glaucous: '#6274c6',
    _oceanGreen: '#5bc289',
    _carrotOrange: '#f79327',
    _whiteSmoke: '#f2efed',
};

const neutral = {
    black: '#000',
    white: '#fff',
    neutral1: '#f2efed',
    neutral2: '#f6f7f9',
    neutral3: '#f2f2f7',
    neutral4: '#e8e9ed',
    neutral5: '#bdbfc7',
    neutral6: '#7a7d8b',
    neutral7: '#666d8c',
    neutral8: '#4b5069',
};

const thirdParty = {
    ratemyagentStar: '#fdb032',
    corelogic: '#f04631',
    apm: '#0d2548',
};

const brand = {
    brand1Regular: raw._deepBlue,
    brand1Light: raw._deepBlueLight,
    brand1Lighter: raw._glaucous,
    brand2Regular: raw._warmRed,
    brand2Light: raw._warmRedLight,
};

const standard = {
    standard1: '#5bc289',
    standard2: '#ec9940',
    standard3: '#e54234',
};

const accent = {
    accent1: '#09b79b',
    accent2: raw._glaucous,
};

const state = {
    positive: raw._oceanGreen,
    warning: raw._carrotOrange,
    negative: raw._warmRed,
    disabled: neutral.neutral5,
};

const utility = {
    heading: neutral.neutral8,
    text: neutral.neutral8,
    textLight: neutral.neutral7,
    textXLight: neutral.neutral6,
    textInverse: neutral.white,
    textPositive: standard.standard1,
    textWarning: standard.standard2,
    textNegative: standard.standard3,
    border: neutral.neutral4,
    borderLight: neutral.neutral3,
};

const component = {
    link: brand.brand1Lighter,
};

const gradient = {
    gradientPrimary: `linear-gradient(213.97deg, ${accent.accent1} 0%, ${brand.brand1Light} 100%)`,
};

exports.fonts = {
    primary: "'Maison Neue', sans-serif",
    secondary: "'Maison Neue', sans-serif",
};

exports.colors = {
    ...raw,
    ...neutral,
    ...thirdParty,
    ...brand,
    ...standard,
    ...accent,
    ...state,
    ...utility,
    ...component,
    ...gradient,
};
