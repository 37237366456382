import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../../common/components/icon/icon.module';

import { TagComponent } from './components/tag/tag.component';

@NgModule({
    imports: [CommonModule, IconModule],
    exports: [TagComponent],
    declarations: [TagComponent],
})
export class TagModule {}
