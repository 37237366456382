import { Injector } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { UIRouterRx } from '@uirouter/rx';

import { permissionUiRouterHook } from '../modules/permissions/ui-router-hooks/permission.ui-router-hook';

import { customParamTypesUiRouterConfigs } from './configs/custom-param-types/custom-param-types.ui-router-configs';
import { four04UiRouterConfigs } from './configs/four04.ui-router-configs';
import { breadcrumbsRouterHooks } from './hooks/breadcrumbs.ui-router-hooks';
import { demoRedirectUiRouterHook } from './hooks/demo-redirect/demo-redirect-ui-router.hook';

export function RootUIRouterConfigFn(router: UIRouter, injector: Injector) {
    // To enable debug mode, import Category and Visualizer above:
    //
    // import { Category } from '@uirouter/angular';
    // import { Visualizer } from '@uirouter/visualizer';
    //
    // and uncomment 2 lines below:
    //
    // router.trace.enable(Category.TRANSITION);
    // router.plugin(Visualizer);

    router.plugin(UIRouterRx);

    customParamTypesUiRouterConfigs(router);
    permissionUiRouterHook(router, injector);
    four04UiRouterConfigs(router);
    breadcrumbsRouterHooks(router, injector);
    demoRedirectUiRouterHook(router);
}
