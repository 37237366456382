<nc-choose-your-own-adventure [heading]="'How else can we help you?'">
    <up-grid slot="options" [gutters]="'small'" [align]="'center'">
        <up-grid-col [col]="12" [col@md]="3">
            <nc-media-action-panel
                [heading]="'I am looking to sell'"
                [body]="'Get started with your local Upside agent'"
                [iconName]="'signboardFill'"
            >
                <a
                    nc-button
                    slot="action"
                    class="u-display--block"
                    [uiSref]="'conversion.get-started'"
                    [style]="'secondary'"
                    [dataEvent]="'postOfiGetStarted'"
                >
                    Get Started
                </a>
            </nc-media-action-panel>
        </up-grid-col>
        <up-grid-col [col]="12" [col@md]="3">
            <nc-media-action-panel
                [heading]="'Can I afford this property?'"
                [body]="'Use our free borrowing calculator'"
                [iconName]="'calculatorFill'"
            >
                <a
                    nc-button
                    slot="action"
                    class="u-display--block"
                    rel="noopener"
                    target="_blank"
                    href="https://shorefinancial.com.au/upside-calculator/a46"
                    [style]="'secondary'"
                    [dataEvent]="'postOfiCalculator'"
                >
                    Free Calculator
                </a>
            </nc-media-action-panel>
        </up-grid-col>
        <up-grid-col [col]="12" [col@md]="3">
            <nc-media-action-panel
                [heading]="'I\'m an investor'"
                [body]="'Get 2 months FREE property management'"
                [iconName]="'houseFill'"
            >
                <a
                    nc-button
                    slot="action"
                    class="u-display--block"
                    rel="noopener"
                    target="_blank"
                    href="https://friends.different.com.au/upside/?utm_source=partner&utm_medium=sms&utm_campaign=Upside_ofi"
                    [style]="'secondary'"
                    [dataEvent]="'postOfiPropertyManagement'"
                >
                    Property Management
                </a>
            </nc-media-action-panel>
        </up-grid-col>
    </up-grid>
</nc-choose-your-own-adventure>
