import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input, OnDestroy, PLATFORM_ID } from '@angular/core';

@Directive({ selector: '[upDisableInputMouseScroll]' })
export class DisableInputMouseScrollDirective implements OnDestroy {
    @Input() public upDisableInputMouseScroll;

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private el: ElementRef) {}

    public get active(): boolean {
        // Explicitly check for false value instead of it being falsy as directives tend to be used without binding
        return this.upDisableInputMouseScroll !== false;
    }

    @HostListener('focus')
    public onFocus(): void {
        if (!isPlatformBrowser(this.platformId) || !this.active) return;
        (<HTMLElement> this.el.nativeElement).addEventListener('mousewheel', this.mousewheelEventHandler);
    }

    @HostListener('blur')
    public onBlur(): void {
        this.destroyHandler();
    }

    public ngOnDestroy(): void {
        this.destroyHandler();
    }

    private destroyHandler(): void {
        (<HTMLElement> this.el.nativeElement).removeEventListener('mousewheel', this.mousewheelEventHandler);
    }

    private mousewheelEventHandler(event: MouseEvent): void {
        event.preventDefault();
    }
}
