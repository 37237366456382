import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { RequestCallBackFormData } from '../../../../common/components/request-call-back-form/request-call-back-form.component';
import { HistoryState } from '../../../../common/services/history.service';

export enum GetStartedActionType {
    SET_RETURN_TO = 'GetStarted:SET_RETURN_TO',
    REQUEST_CALLBACK = 'GetStarted:REQUEST_CALLBACK',
    REQUEST_CALLBACK_SUCCESS = 'GetStarted:REQUEST_CALLBACK_SUCCESS',
    REQUEST_CALLBACK_ERROR = 'GetStarted:REQUEST_CALLBACK_ERROR',
    SET_HAS_STARTED_FLOW = 'GetStarted:SET_HAS_STARTED_FLOW',
    SET_HAS_REQUESTED_CALLBACK = 'GetStarted:SET_HAS_REQUESTED_CALLBACK',
}

export namespace getStartedActions {
    export class SetReturnTo implements Action {
        public readonly type = GetStartedActionType.SET_RETURN_TO;
        constructor(public payload: HistoryState) {}
    }

    export class RequestCallback implements Action {
        public readonly type = GetStartedActionType.REQUEST_CALLBACK;
        constructor(public payload: RequestCallBackFormData) {}
    }

    export class RequestCallbackSuccess implements Action {
        public readonly type = GetStartedActionType.REQUEST_CALLBACK_SUCCESS;
    }

    export class RequestCallbackError implements Action {
        public readonly type = GetStartedActionType.REQUEST_CALLBACK_ERROR;
        constructor(public payload: HttpErrorResponse) {}
    }

    export class SetHasStartedFlow implements Action {
        public readonly type = GetStartedActionType.SET_HAS_STARTED_FLOW;
        constructor(public payload: boolean) {}
    }

    export class SetHasRequestedCallback implements Action {
        public readonly type = GetStartedActionType.SET_HAS_REQUESTED_CALLBACK;
        constructor(public payload: boolean) {}
    }
}

export type GetStartedActionsUnion =
    | getStartedActions.SetReturnTo
    | getStartedActions.RequestCallback
    | getStartedActions.RequestCallbackSuccess
    | getStartedActions.RequestCallbackError
    | getStartedActions.SetHasStartedFlow
    | getStartedActions.SetHasRequestedCallback;
