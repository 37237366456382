import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FirebaseApi } from '../../../common/models/firebase-api.model';

@Injectable()
export class FirebaseResource {
    private readonly baseUrl = '/api/v2/user/firebase-token';

    constructor(private httpClient: HttpClient) {}

    public all() {
        return this.httpClient.get<FirebaseApi.All.GET.Response>(this.baseUrl);
    }

    public push(id: string, body: FirebaseApi.Push.POST.Request) {
        return this.httpClient.post<FirebaseApi.Push.POST.Response>(`${this.baseUrl}/${id}/push-entity`, body);
    }
}
