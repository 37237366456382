<nc-check-in-card>
    <ng-container slot="header">
        <div class="heading"> Hello </div>
        <p> Unfortunately there isn't an ongoing open for this property at the moment. </p>
    </ng-container>
    <ng-container slot="body">
        <p class="u-text-label--sm u-margin-bottom--sm"> Book a private inspection with {{ agent.firstName }} </p>

        <div class="agent">
            <nc-avatar
                [size]="'medium'"
                class="u-margin-right--md"
                [name]="agent.fullName"
                [imgSrc]="agent.avatarUrl"
            ></nc-avatar>
            <div>
                <div class="u-text-heading--rg u-margin-bottom--2xs">
                    {{ agent.fullName }}
                </div>
                <div class="u-text-body--sm">
                    <div *ngIf="agent.phoneNumber as phoneNumber">
                        <nc-icon class="u-margin-right--2xs" [size]="'small'" [name]="'phoneFill'"></nc-icon>
                        <a nc-link [href]="'tel:' + phoneNumber">{{ phoneNumber }}</a>
                    </div>
                    <div *ngIf="agent.email as email">
                        <nc-icon class="u-margin-right--2xs" [size]="'small'" [name]="'envelopeFill'"></nc-icon>
                        <a nc-link [href]="'mailto:' + email">{{ email }}</a>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</nc-check-in-card>
