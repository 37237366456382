import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, map, switchMap } from 'rxjs/operators';

import { appraisalReport as appraisalReportModel } from '../../common/models/appraisal-report.model';
import { PropertyResource } from '../../modules/core/resources/property.resource';

import * as PropertyActions from './property.actions';

@Injectable()
export class PropertyEffects {
    public loadProperty = createEffect(() =>
        this.actions.pipe(
            ofType<PropertyActions.GetProperty>(PropertyActions.GET_PROPERTY),
            switchMap(action =>
                this.propertyResource.get(action.payload).pipe(
                    map(property => ({ type: PropertyActions.PROPERTY_LOADED, payload: property })),
                    catchError(error => throwError(error)),
                ),
            ),
        ),
    );

    public loadAppraisalReport = createEffect(() =>
        this.actions.pipe(
            ofType<PropertyActions.GetAppraisalReport>(PropertyActions.GET_APPRAISAL_REPORT),
            switchMap(action =>
                this.propertyResource.getLatestAppraisalReport(action.payload).pipe(
                    map((appraisalReport: appraisalReportModel.AppraisalReport) => ({
                        type: PropertyActions.GET_APPRAISAL_REPORT_SUCCESS,
                        payload: appraisalReport,
                    })),
                    catchError(error => of({ type: PropertyActions.GET_APPRAISAL_REPORT_ERROR, payload: error })),
                ),
            ),
        ),
    );

    public loadAppointments = createEffect(() =>
        this.actions.pipe(
            ofType<PropertyActions.GetPropertyAppointments>(PropertyActions.GET_APPOINTMENTS),
            switchMap(action =>
                this.propertyResource.getAppointmentsForProperty(action.payload).pipe(
                    map(response => ({ type: PropertyActions.GET_APPOINTMENTS_SUCCESS, payload: response.meetings })),
                    catchError(error => of({ type: PropertyActions.GET_APPOINTMENTS_ERROR, payload: error })),
                ),
            ),
        ),
    );

    constructor(private actions: Actions, private propertyResource: PropertyResource) {}
}
