import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { UIRouterModule } from '@uirouter/angular';

import { BrandLogoModule } from '../brand-logo/brand-logo.module';
import { CardModule } from '../card/card.module';
import { ListingKeyDetailsModule } from '../listing-key-details/listing-key-details.module';
import { ListingKeyFeaturesModule } from '../listing-key-features/listing-key-features.module';
import { LocalisationModule } from '../localisation/localisation.module';
import { PrettyMediaTagModule } from '../pretty-media-tag/pretty-media-tag.module';
import { PropertyImageModule } from '../property-image/property-image.module';
import { SharedVendorModule } from '../shared-vendor/shared-vendor.module';
import { SplitFormattedAddressModule } from '../split-formatted-address/split-formatted-address.module';

import { brochureStates } from './brochure.states';
import { AgentComponent } from './components/agent/agent.component';
import { BrochureComponent } from './components/brochure/brochure.component';
import { EventsListComponent } from './components/events-list/events-list.component';
import { FilesListComponent } from './components/files-list/files-list.component';
import { HeaderComponent } from './components/header/header.component';
import { MoreDetailsComponent } from './components/more-details/more-details.component';
import { PropertyReportUpsellComponent } from './components/property-report-upsell/property-report-upsell.component';
import { GetBrochureUseCase } from './use-cases/get-brochure.use-case';

@NgModule({
    imports: [
        CommonModule,
        UIRouterModule.forChild({ states: brochureStates }),
        CardModule,
        SharedVendorModule,
        ListingKeyDetailsModule,
        ListingKeyFeaturesModule,
        LocalisationModule,
        SplitFormattedAddressModule,
        BrandLogoModule,
        PrettyMediaTagModule,
        TranslocoModule,
        PropertyImageModule,
    ],
    providers: [GetBrochureUseCase],
    declarations: [
        BrochureComponent,
        HeaderComponent,
        EventsListComponent,
        FilesListComponent,
        MoreDetailsComponent,
        AgentComponent,
        PropertyReportUpsellComponent,
    ],
})
export class BrochureModule {}
