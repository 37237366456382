import { Component, Inject } from '@angular/core';

import { UP_COLOR_CONSTANTS, UpColorConstants } from '../../constants/colors.constants';

@Component({
    selector: 'up-map-marker',
    templateUrl: 'map-marker.component.html',
})
export class MapMarkerComponent {
    constructor(@Inject(UP_COLOR_CONSTANTS) public colors: UpColorConstants) {}
}
