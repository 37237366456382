import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { EnvironmentService } from '../../../modules/core/services/environment.service';
import { media } from '../../models/media.model';

@Component({
    selector: 'up-agent-profile-card',
    templateUrl: 'agent-profile-card.component.html',
    styleUrls: ['agent-profile-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentProfileCardComponent {
    @Input() public slug: string;
    @Input() public transparentProfileImage: media.File;
    @Input() public avatar: media.File;
    @Input() public firstName: string;

    public get orgName(): string {
        return this.environmentService.config.organisation.displayName;
    }

    public get noImages(): boolean {
        return !this.transparentProfileImage?.servingUrl && !this.avatar?.servingUrl;
    }

    constructor(private environmentService: EnvironmentService) {}
}
