import { Action, createReducer, on } from '@ngrx/store';

import { Suppliers } from '../../common/models/suppliers.model';
import { sliceReducer } from '../../utilities/slice-reducer';

import {
    getAgentSuppliers,
    getAgentSuppliersError,
    getAgentSuppliersSuccess,
    getAllSuppliers,
    getAllSuppliersError,
    getAllSuppliersSuccess,
    getPropertySuppliers,
    getPropertySuppliersError,
    getPropertySuppliersSuccess,
    resetCachedSuppliers,
    updateAgentSuppliers,
    updateAgentSuppliersError,
    updateAgentSuppliersSuccess,
    updatePropertySuppliers,
    updatePropertySuppliersError,
    updatePropertySuppliersSuccess,
} from './internal-suppliers.actions';

interface Data {
    allSuppliers?: Suppliers.Supplier[];
    propertySuppliers?: {
        [propertyId: string]: Suppliers.EntitySuppliers;
    };
    agentSuppliers?: {
        [agentId: string]: Suppliers.EntitySuppliers;
    };
}

interface Ui {
    isLoadingAllSuppliers: boolean;
    isLoadingPropertySuppliers: boolean;
    isLoadingAgentSuppliers: boolean;
    isUpdatingPropertySuppliers: boolean;
    updatePropertySuppliersSuccess?: boolean | undefined;
    isUpdatingAgentSuppliers: boolean;
    updateAgentSuppliersSuccess?: boolean | undefined;
}

export interface InternalSuppliersState {
    ui: Ui;
    data: Data;
}

export const featureId = 'internalSuppliers';
const initialState: InternalSuppliersState = {
    ui: {
        isLoadingAllSuppliers: false,
        isLoadingPropertySuppliers: false,
        isUpdatingPropertySuppliers: false,
        isUpdatingAgentSuppliers: false,
        isLoadingAgentSuppliers: false,
    },
    data: {},
};

const reducer = createReducer(
    initialState,

    on(getAllSuppliers, state => ({
        ...state,
        ui: sliceReducer(state.ui, { isLoadingAllSuppliers: true }),
    })),
    on(getAllSuppliersSuccess, (state, { suppliers }) => ({
        ...state,
        ui: sliceReducer(state.ui, { isLoadingAllSuppliers: false }),
        data: sliceReducer(state.data, { allSuppliers: suppliers }),
    })),
    on(getAllSuppliersError, state => ({
        ...state,
        ui: sliceReducer(state.ui, { isLoadingAllSuppliers: false }),
    })),

    on(getPropertySuppliers, state => ({
        ...state,
        ui: sliceReducer(state.ui, { isLoadingPropertySuppliers: true }),
    })),
    on(getPropertySuppliersSuccess, (state, { suppliers, propertyId }) => ({
        ...state,
        ui: sliceReducer(state.ui, { isLoadingPropertySuppliers: false }),
        data: sliceReducer(state.data, {
            propertySuppliers: sliceReducer(state.data.propertySuppliers, { [propertyId]: suppliers }),
        }),
    })),
    on(getPropertySuppliersError, state => ({
        ...state,
        ui: sliceReducer(state.ui, { isLoadingPropertySuppliers: false }),
    })),

    on(getAgentSuppliers, state => ({
        ...state,
        ui: sliceReducer(state.ui, { isLoadingAgentSuppliers: true }),
    })),
    on(getAgentSuppliersSuccess, (state, { suppliers, agentId }) => ({
        ...state,
        ui: sliceReducer(state.ui, { isLoadingAgentSuppliers: false }),
        data: sliceReducer(state.data, {
            agentSuppliers: sliceReducer(state.data.agentSuppliers, { [agentId]: suppliers }),
        }),
    })),
    on(getAgentSuppliersError, state => ({
        ...state,
        ui: sliceReducer(state.ui, { isLoadingAgentSuppliers: false }),
    })),

    on(updatePropertySuppliers, state => ({
        ...state,
        ui: sliceReducer(state.ui, {
            isUpdatingPropertySuppliers: true,
            updatePropertySuppliersSuccess: undefined,
        }),
    })),
    on(updatePropertySuppliersSuccess, (state, { suppliers, propertyId }) => ({
        ...state,
        ui: sliceReducer(state.ui, {
            isUpdatingPropertySuppliers: false,
            updatePropertySuppliersSuccess: true,
        }),
        data: sliceReducer(state.data, {
            propertySuppliers: sliceReducer(state.data.propertySuppliers, { [propertyId]: suppliers }),
        }),
    })),
    on(updatePropertySuppliersError, state => ({
        ...state,
        ui: sliceReducer(state.ui, {
            isUpdatingPropertySuppliers: false,
            updatePropertySuppliersSuccess: false,
        }),
    })),

    on(updateAgentSuppliers, state => ({
        ...state,
        ui: sliceReducer(state.ui, {
            isUpdatingAgentSuppliers: true,
            updateAgentSuppliersSuccess: undefined,
        }),
    })),
    on(updateAgentSuppliersSuccess, (state, { suppliers, agentId }) => ({
        ...state,
        ui: sliceReducer(state.ui, {
            isUpdatingAgentSuppliers: false,
            updateAgentSuppliersSuccess: true,
        }),
        data: sliceReducer(state.data, {
            agentSuppliers: sliceReducer(state.data.agentSuppliers, { [agentId]: suppliers }),
        }),
    })),
    on(updateAgentSuppliersError, state => ({
        ...state,
        ui: sliceReducer(state.ui, {
            isUpdatingAgentSuppliers: false,
            updateAgentSuppliersSuccess: false,
        }),
    })),

    on(resetCachedSuppliers, state => ({
        ...state,
        data: {},
    })),
);

export function internalSuppliersReducer(state: InternalSuppliersState, action: Action) {
    return reducer(state, action);
}
