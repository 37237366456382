<div matAutocompleteOrigin #origin="matAutocompleteOrigin" [class.is-loading]="isLoading$ | async" class="wrapper">
    <input
        class="locality-input"
        #queryInput
        matInput
        (input)="onQueryInputChange($event)"
        (blur)="onBlurInput()"
        (focus)="onFocus()"
        [placeholder]="placeholder"
        [disabled]="isDisabled$ | async"
        [matAutocomplete]="matAutocomplete"
        [matAutocompleteConnectedTo]="origin"
    />
    <nc-spinner class="locality-loader" *ngIf="isLoading$ | async" [size]="'small'"> </nc-spinner>
</div>

<mat-autocomplete
    class="u-margin-top--xs nc-mat-locality-autocomplete"
    (closed)="onAutocompleteClosed()"
    (optionSelected)="onSelectSearchResult($event.option.value)"
    autoActiveFirstOption
    #matAutocomplete="matAutocomplete"
>
    <mat-optgroup *ngIf="(suburbSearchResults$ | async)?.length" [label]="'Suburbs'">
        <mat-option
            class="nc-locality-autocomplete-option"
            *ngFor="let suburb of suburbSearchResults$ | async; trackBy: trackSearchResultBy"
            [value]="suburb"
            >{{ suburb.displayValue }}</mat-option
        >
    </mat-optgroup>
    <mat-optgroup *ngIf="(addressSearchResults$ | async)?.length" [label]="'Addresses'">
        <mat-option
            class="nc-locality-autocomplete-option"
            *ngFor="let address of addressSearchResults$ | async; trackBy: trackSearchResultBy"
            [value]="address"
            >{{ address.displayValue }}</mat-option
        >
    </mat-optgroup>
    <ng-container
        *ngIf="
            queryInput.value &&
            !(suburbSearchResults$ | async)?.length &&
            !(addressSearchResults$ | async)?.length &&
            (isLoading$ | async) === false &&
            (value$ | async) === undefined
        "
    >
        <mat-option [disabled]="true">
            {{ 'localityAutocomplete.emptyResults' | transloco }}
        </mat-option>
    </ng-container>
</mat-autocomplete>
