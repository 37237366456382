export const marks = {
    // Mark when angular starts booting
    angularStartBoot: 'angular-start-boot',
    // Mark when angular finished booting
    angularFinishBoot: 'angular-finish-boot',
};

export const measures = {
    // Should measure angular boot duration using angularStartBoot and angularFinishBoot marks
    angularBootDuration: 'angular-boot-duration',
};
