<nc-backdrop-floating-org-card>
    <ng-container slot="headingText"
        >Welcome to<br />
        NurtureCloud</ng-container
    >

    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="u-padding-bottom--md" [class.u-display--none]="hideStateControl$ | async">
            <up-select
                [fullWidth]="true"
                [label]="'State'"
                [formControlName]="'state'"
                [error]="isControlError('state')"
            >
                <up-select-option *ngFor="let state of availableStates" [value]="state">
                    {{ state }}
                </up-select-option>
            </up-select>
            <up-help-text [type]="'error'" *ngIf="isControlError('state')"> Please select a state </up-help-text>
        </div>

        <up-select [fullWidth]="true" [label]="'Role'" [formControlName]="'role'" [error]="isControlError('role')">
            <up-select-option *ngFor="let role of availableRoles" [value]="role">
                {{ role | upPrettifyRole }}
            </up-select-option>
        </up-select>
        <up-help-text [type]="'error'" *ngIf="isControlError('role')"> Please select a user role </up-help-text>

        <div class="controls u-margin-top--5xl-r">
            <button nc-link [disabled]="isLoading$ | async" (click)="signOut()" type="button"> Sign Out </button>
            <button nc-button type="submit" [style]="'primary'" [loading]="isLoading$ | async" class="get-started-btn">
                Get Started
            </button>
        </div>
    </form>
</nc-backdrop-floating-org-card>
