import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { reminderApi } from '../../../common/models/reminder-api.model';

@Injectable()
export class ReminderResource {
    private readonly baseUrl = '/api/v2';

    constructor(private httpClient: HttpClient) {}

    public addReminder(propertyId: string, reminder: reminderApi.create.post.Request) {
        return this.httpClient.post(`${this.baseUrl}/property/${propertyId}/reminder/`, reminder);
    }

    public getById(propertyId: string, reminderId: string) {
        return this.httpClient.get<reminderApi.getById.get.Response>(
            `${this.baseUrl}/property/${propertyId}/reminder/${reminderId}/`,
        );
    }

    public cancelReminder(propertyId: string, reminderId: string, note: string) {
        return this.httpClient.post<reminderApi.cancel.post.Response>(
            `${this.baseUrl}/property/${propertyId}/reminder/${reminderId}/cancel/`,
            <reminderApi.cancel.post.Request>{ note },
        );
    }

    public updateReminder(propertyId: string, reminderId: string, reminder: reminderApi.update.post.Request) {
        return this.httpClient.post<reminderApi.update.post.Response>(
            `${this.baseUrl}/property/${propertyId}/reminder/${reminderId}/`,
            reminder,
        );
    }
}
