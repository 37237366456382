<div class="container">
    <div *ngIf="brochure$ | async as brochure; else loading">
        <div class="hero-header">
            <nc-property-image
                [rawImageUrl]="heroImage$ | async"
                [useScrim]="(heroImage$ | async | upNullishDefault : null) === null"
                class="property-image"
            ></nc-property-image>
            <nc-container class="logo-container">
                <nc-brand-logo class="logo"></nc-brand-logo>
            </nc-container>
        </div>

        <nc-container class="main-content">
            <nc-header
                [propertyType]="brochure.propertyType"
                [formattedAddress]="brochure.address?.formattedAddress"
                [bedrooms]="brochure.bedrooms"
                [bathrooms]="brochure.bathrooms"
                [parking]="brochure.parking"
                [landSize]="brochure.landSize"
                [price]="brochure.advertisedPrice"
                [auctionDateTime]="brochure.auction?.dateTime"
                [hasFloorPlan]="hasFloorPlan$ | async"
                (openGallery)="openGallery()"
                (openFloorPlan)="openGallery(true)"
                [isForSale]="brochure.isForSale"
            ></nc-header>

            <up-grid *ngIf="brochure.isForSale" [gutters]="'small'">
                <up-grid-col [col]="12" [col@sm]="6">
                    <h3 class="heading">
                        Upcoming inspections
                        <span *ngIf="!!brochure.auction?.dateTime"> & auction </span>
                    </h3>
                    <nc-events-list
                        [opens]="brochure.opens"
                        [auctionDateTime]="brochure.auction?.dateTime"
                        [agentFirstName]="brochure.agent?.firstName"
                        [agentLastName]="brochure.agent?.lastName"
                        [propertyToken]="brochure.shared"
                    ></nc-events-list>
                </up-grid-col>
                <up-grid-col [col]="12" [col@sm]="6">
                    <h3 class="heading"> Get documents & Reports </h3>
                    <nc-files-list [files]="brochure.files" (download)="onDownload($event)"></nc-files-list>
                </up-grid-col>
            </up-grid>

            <h3 class="heading"> More details </h3>
            <up-grid [gutters]="'small'">
                <up-grid-col [col]="12" [col@sm]="6">
                    <nc-more-details
                        class="u-margin-bottom--xl"
                        [title]="brochure.title"
                        [bedrooms]="brochure.bedrooms"
                        [bathrooms]="brochure.bathrooms"
                        [parking]="brochure.parking"
                        [landSize]="brochure.landSize"
                        [body]="brochure.markdown"
                        [features]="brochure.features"
                        [hasFloorPlan]="hasFloorPlan$ | async"
                        (openGallery)="openGallery()"
                        (openFloorPlan)="openGallery(true)"
                    ></nc-more-details>
                </up-grid-col>
                <up-grid-col [col]="12" [col@sm]="6">
                    <div class="u-margin-bottom--xl">
                        <ul>
                            <ng-container *ngFor="let agentRoles of brochure.agentRoles">
                                <li *ngIf="agentRoles?.agent as agent">
                                    <nc-agent
                                        class="u-margin-bottom--md"
                                        [firstName]="agent.firstName"
                                        [lastName]="agent.lastName"
                                        [agency]="agent.agency?.name"
                                        [phoneNumber]="agent.phoneNumber"
                                        [email]="agent.email"
                                        [avatarUrl]="agent.avatar?.servingUrl"
                                    ></nc-agent>
                                </li>
                            </ng-container>
                        </ul>
                        <nc-property-report-upsell></nc-property-report-upsell>
                    </div>
                </up-grid-col>
            </up-grid>
        </nc-container>
    </div>
</div>

<ng-template #loading>
    <div class="hero-header">
        <div class="property-image">
            <up-placeholder class="hero-image" [width]="'100%'" [height]="'100%'"></up-placeholder>
        </div>
    </div>
    <nc-container class="main-content">
        <nc-header [loading]="true"></nc-header>

        <up-grid [gutters]="'small'">
            <up-grid-col [col]="12" [col@sm]="6">
                <div class="heading">
                    <up-placeholder [width]="'250px'" [height]="'22px'"></up-placeholder>
                </div>
                <nc-events-list [loading]="true"></nc-events-list>
            </up-grid-col>
            <up-grid-col [col]="12" [col@sm]="6">
                <div class="heading">
                    <up-placeholder [width]="'250px'" [height]="'22px'"></up-placeholder>
                </div>
                <nc-files-list [loading]="true"></nc-files-list>
            </up-grid-col>
        </up-grid>

        <div class="heading">
            <up-placeholder [width]="'250px'" [height]="'22px'"></up-placeholder>
        </div>
        <up-grid [gutters]="'small'">
            <up-grid-col [col]="12" [col@sm]="6">
                <nc-more-details [loading]="true"></nc-more-details>
            </up-grid-col>
            <up-grid-col [col]="12" [col@sm]="6">
                <div class="u-margin-bottom--xl">
                    <nc-agent class="u-margin-bottom--md" [loading]="true"></nc-agent>
                    <nc-property-report-upsell></nc-property-report-upsell>
                </div>
            </up-grid-col>
        </up-grid>
    </nc-container>
</ng-template>
