import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { StateService, UISref } from '@uirouter/angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FocusableElement, tabbable } from 'tabbable';

import { NavComponent } from './nav/nav.component';
import { NavPaneSecondaryComponent } from './nav-pane-secondary/nav-pane-secondary.component';
import { navigation } from './navigation.model';

@Injectable()
export class NavigationService {
    public hasNavPaneSecondary$: Observable<boolean>;
    private _closeMobileNavPane$ = new Subject<void>();
    private _closeNavDropdowns$ = new Subject<void>();
    private _collapseTabletPane$ = new Subject<void>();
    private _navConfig$ = new BehaviorSubject<navigation.Config>({});
    private _activeNavComponent = new BehaviorSubject<NavComponent>(undefined);
    private _activeNavPaneSecondaryComponent = new BehaviorSubject<NavPaneSecondaryComponent>(undefined);
    public closeMobileNavPane$ = this._closeMobileNavPane$.asObservable();
    public closeNavDropdowns$ = this._closeNavDropdowns$.asObservable();
    public collapseTabletPane$ = this._collapseTabletPane$.asObservable();
    public navConfig$ = this._navConfig$.asObservable();
    public activeNavComponent$ = this._activeNavComponent.asObservable();

    constructor(@Inject(DOCUMENT) private document: Document, private stateService: StateService) {
        this.hasNavPaneSecondary$ = this._activeNavPaneSecondaryComponent.pipe(map(activeNavPane => !!activeNavPane));
    }

    public closeMobilePane(): void {
        this._closeMobileNavPane$.next();
    }

    public closeDropdowns(): void {
        this._closeNavDropdowns$.next();
    }

    public collapseTabletPane(): void {
        this._collapseTabletPane$.next();
    }

    public focusNextElement(fromElement: FocusableElement, previous?: boolean): void {
        const tabbables = tabbable(this.document.body);
        const elIndex = tabbables.indexOf(fromElement);

        let target = fromElement;
        if (elIndex !== -1) {
            target = previous ? tabbables[elIndex - 1] : tabbables[elIndex + 1];
        }

        target?.focus();
    }

    public setNavConfig(navConfig?: navigation.Config): void {
        this._navConfig$.next(navConfig || {});
    }

    public resetNavConfig(): void {
        this._navConfig$.next({});
    }

    public setActiveNavPaneSecondaryComponent(component: NavPaneSecondaryComponent | undefined): void {
        this._activeNavPaneSecondaryComponent.next(component);
    }

    public setActiveNavComponent(navComponent: NavComponent | undefined): void {
        this._activeNavComponent.next(navComponent);
    }

    public collapseNavPaneSecondary(): void {
        const activeNavPaneSecondaryComponent = this._activeNavPaneSecondaryComponent.value;

        if (!activeNavPaneSecondaryComponent) return;

        activeNavPaneSecondaryComponent.collapse();
    }

    public setNavPaneSecondaryToggleable(toggleable: boolean): void {
        const activeNavPaneSecondaryComponent = this._activeNavPaneSecondaryComponent.value;

        if (!activeNavPaneSecondaryComponent) return;

        activeNavPaneSecondaryComponent.hideToggleButton(!toggleable);
    }

    public isUiSrefRelatedState(uiSref: UISref | undefined, exactMatch: boolean): boolean {
        if (!uiSref) return false;

        return exactMatch
            ? this.stateService.is(uiSref.state, uiSref.params)
            : this.stateService.includes(uiSref.state, uiSref.params);
    }
}
