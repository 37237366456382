import { ChangeDetectionStrategy, Component } from '@angular/core';

import { EnvironmentService } from '../../../modules/core/services/environment.service';
import { ThemeName, ThemeService } from '../../../modules/core/services/theme.service';
import { environment } from '../../models/environment.model';

@Component({
    selector: 'nc-backdrop-floating-org-card',
    templateUrl: 'backdrop-floating-org-card.component.html',
    styleUrls: ['backdrop-floating-org-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackdropFloatingOrgCardComponent {
    public orgName: environment.OrganisationName;
    public themeName: ThemeName;

    constructor(environmentService: EnvironmentService, themeService: ThemeService) {
        this.orgName = environmentService.config.organisation.name;
        this.themeName = themeService.themeName;
    }
}
