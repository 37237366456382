import moment from 'moment';

import { LikelihoodOfSelling } from '../constants/pipline.constant';
import { UserT } from '../services/user.service';

import { AddressT, LatLongT } from './address.model';
import { agent } from './agent.model';
import { Appointment } from './appointment.model';
import { auction } from './auction.model';
import { buyer } from './buyer.model';
import { campaign as campaignData } from './campaign.model';
import { ApiSuccessResponse } from './common.model';
import { conveyancer } from './conveyancer.model';
import { AppraisalType } from './domain/property-appraisal/appraisal-type.model';
import * as DomainModel from './domain.model';
import { AspectDto } from './dto/property/aspect.dto';
import { PropertyRatesAttributeValueDto } from './dto/property/property-rates-attribute-value.dto';
import { PropertyAppraisalTypeDto } from './dto/property-appraisals/property-appraisal-type.dto';
import { AttendedInspectionDto } from './dto/property-ofi/attended-inspection.dto';
import { lead } from './lead.model';
import { listings } from './listings.model';
import { media } from './media.model';
import { Offer } from './offer.model';
import { phase } from './phase.model';
import { portal } from './portal.model';
import { reminder } from './reminder.model';
import { task } from './task.model';
import { Teams } from './teams.model';
import { todo } from './todo.model';
import { user } from './user.model';
import { Utm } from './utm.model';

interface AppraisalReportListItem {
    id: string;
    viewed: boolean;
    lastSent: moment.Moment;
    created: moment.Moment;
}

interface AppraisalListResponse {
    reports: AppraisalReportListItem[];
}

export interface SaleT {
    salePrice: number;
    saleDate: string;
}

interface ComparablePropertyT {
    coreLogicPropertyId?: number;
    address: AddressT;
    propertyType: string;
    bedrooms: number;
    bathrooms: number;
    parking: number;
    landSize: number;
    imageURL?: string;
    mediumImageURL?: string;
    thumbnailImageURL?: string;
    distanceFromTarget?: number;
    saleHistory: SaleT[];
}

interface ComparableProperty {
    address?: string;
    location: LatLongT;
    bedrooms: number;
    bathrooms: number;
    parking: number;
    landSize: number;
    type: string;
    lastPrice: number;
    saleDate: string;
    imageURL: string;
    distanceFromTarget: number;
}

export interface PropertyData {
    address: string;
    coordinates?: CoordinatesT;
    created?: string;
}

interface CoordinatesT {
    latitude: number;
    longitude: number;
}

export type PropertyDataType = 'listing' | 'alert' | 'sold';

interface PropertyAlertDataT extends PropertyData {
    description: string;
    dateReceived?: string;
    authority: string;
}

interface PropertySoldDataT extends PropertyData {
    bedrooms: number;
    bathrooms: number;
    parking?: number;
    soldPrice?: number;
    soldDate: string;
    propertyType: string;
    upside: boolean;
    media: PropertyDataMediaT;
    id?: number;
    listingUrl?: string;
    slug?: string;
}

interface PropertyListingDataT extends PropertyData {
    bedrooms?: number;
    bathrooms?: number;
    parking?: number;
    displayPrice?: string;
    propertyType: string;
    media: PropertyDataMediaT;
    upside?: boolean;
    slug?: string;
    listingUrl?: string;
    id?: number;
    created?: string;
}

interface PropertyDataMediaT {
    category: string;
    url: string;
}

interface AgentAgency {
    id: string;
    name: string;
}

export interface AgentT {
    id: string;
    firstName: string;
    lastName: string;
    biography?: string;
    created: string;
    avatar?: media.File;
    team?: Teams.MinimalTeam;
    phoneNumber?: string;
    email?: string;
    defaultSupplier?: boolean;
    agency?: AgentAgency;
}
export interface BooleanPropertyFeatures {
    // general
    isNewDevelopment?: boolean;

    // indoor
    aircon?: boolean;
    alarm?: boolean;
    builtInWardrobes?: boolean;
    dishwasher?: boolean;
    ensuite?: boolean;
    floorboards?: boolean;
    gym?: boolean;
    heating?: boolean;
    indoorSpa?: boolean;
    intercom?: boolean;
    fireplace?: boolean;
    study?: boolean;
    furnished?: boolean;
    broadband?: boolean;
    internalLaundry?: boolean;
    petFriendly?: boolean;
    separateDiningRoom?: boolean;
    rumpusRoom?: boolean;
    vacuumSystem?: boolean;
    workshop?: boolean;
    smokingPermitted?: boolean;
    bath?: boolean;

    // outdoor
    balcony?: boolean;
    deck?: boolean;
    courtyard?: boolean;
    outdoorSpa?: boolean;
    inGroundPool?: boolean;
    aboveGroundPool?: boolean;
    shed?: boolean;
    tennisCourt?: boolean;
    secureParking?: boolean;
    fullyFenced?: boolean;
    pergola?: boolean;
    remoteGarage?: boolean;
    garden?: boolean;
    outdoorEntertainment?: boolean;

    // internal aircon details
    ductedHeating?: boolean;
    ductedCooling?: boolean;
    splitSystemHeating?: boolean;
    hydronicHeating?: boolean;
    gasHeating?: boolean;
    evaporativeCooling?: boolean;
    splitSystemAirCon?: boolean;
    reverseCycleAirCon?: boolean;

    // eco
    waterTank?: boolean;
    solarHotWater?: boolean;
    solarPanels?: boolean;
    greywaterSystem?: boolean;
    doubleGlazedWindows?: boolean;
    energyEfficientAppliances?: boolean;
    waterEfficientAppliances?: boolean;
    ceilingInsulation?: boolean;
    wallInsulation?: boolean;
    waterEfficientFixtures?: boolean;

    // location
    groundFloor?: boolean;
    waterViews?: boolean;
    cityViews?: boolean;
    northFacing?: boolean;

    // services
    cableOrSatellite?: boolean;
    gas?: boolean;
    payTV?: boolean;
}

export interface PropertyFeaturesT extends BooleanPropertyFeatures {
    // other
    councilRatesPerQuarter?: number;
    energyRating?: number;
    hotWater?: 'gas' | 'electric' | 'solar';
    internalSize?: number;
    landSize?: number;
    otherFeatures?: string;
    structuredInternalSize?: Property.AreaAttribute;
    structuredLandSize?: Property.AreaAttribute;
    aspect?: AspectDto;

    // Rates
    councilRates?: PropertyRatesAttributeValueDto;
    strataRates?: PropertyRatesAttributeValueDto;
    waterRates?: PropertyRatesAttributeValueDto;
}

export interface VendorInfoT {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailConfirmed: boolean;
    displayName?: string;
}

/** @deprecated - use PropertyType.Enum instead **/
export enum PropertyType {
    House = 'House',
    Apartment = 'Apartment',
    Unit = 'Unit',
    Flat = 'Flat',
    SemiDetached = 'SemiDetached',
    Duplex = 'Duplex',
    Land = 'Land',
    Townhouse = 'Townhouse',
    Studio = 'Studio',
    Terrace = 'Terrace',
    Villa = 'Villa',
    Acreage = 'Acreage',
    Rural = 'Rural',
    Other = 'Other',
    BlockOfUnits = 'BlockOfUnits',
    OffThePlan = 'OffThePlan',
    Retirement = 'Retirement',
}

export enum RuralPropertySubtype {
    Cropping = 'Cropping',
    Dairy = 'Dairy',
    Farmlet = 'Farmlet',
    Horticulture = 'Horticulture',
    Lifestyle = 'Lifestyle',
    Livestock = 'Livestock',
    Viticulture = 'Viticulture',
    MixedFarming = 'MixedFarming',
    Other = 'Other',
}

export type RetirementPropertySubtype = Exclude<PropertyType, PropertyType.Retirement>;

// Todo: We need to two separate types for reading/writing to the property attributes. Some of these attributes
//  (e.g. VENDOR_PORTAL_ENABLED) are write-only and aren't returned in the response.
export interface PropertyAttributesT {
    propertyType?: PropertyType;
    intAttributes?: {
        PRICE_EXPECTATION?: number;
        FRONTAGE_SIZE?: number;
        [key: string]: number;
    };
    boolAttributes?: {
        [key: string]: boolean;
        UNDER_OFFER_HIDDEN?: boolean;
        HIDE_SOLD_PRICE?: boolean;
        SEND_JUST_LISTED_SMS_NOTIFICATION?: boolean;
        SEND_JUST_SOLD_EMAIL_NOTIFICATION?: boolean;
        SEND_JUST_LISTED_WEEKLY_EMAIL_NOTIFICATION?: boolean;
        VENDOR_PORTAL_ENABLED?: boolean;
        CONTRACT_OF_SALE_ALWAYS_VISIBLE?: boolean;
    };
    stringAttributes?: {
        LIKELIHOOD_OF_SELLING?: LikelihoodOfSelling;
        ALARM_CODE?: string;
        APPROXIMATE_AREAS?: string;
        KEY_SAFE?: string;
        SELLING_REASON?: string;
        REPAIRS_RENOVATIONS?: string;
        [key: string]: string;
    };
    dateTimeAttributes?: {
        BUILT?: string;
        SETTLEMENT?: string;
    };
    areaAttributes?: {
        LAND_SIZE?: Property.AreaAttribute;
    };
}

export type CampaignStatusT = 'Inactive' | 'Active' | 'UnderOffer' | 'Sold';

export type ServiceT = 'Servicable' | 'Fringe' | 'Unservicable';

export interface PropertyAuthority {
    daysExclusive: number;
    expirationDate?: string;
    signedDate: string;
}

export enum LeadStatus {
    Hidden = 'Hidden',
    Pool = 'Pool',
    Prospect = 'Prospect',
}

export interface Valuation {
    date?: string;
    source?: string;
    type?: string;
    low: number;
    high: number;
    value?: number;
    rentalYield?: number;
    weeklyRentalEstimate?: number;
    bedrooms?: number;
    bathrooms?: number;
    parking?: number;
    propertyType?: string;
}

export class Property {
    public active: boolean;
    public address: AddressT;
    public advertisedPrice?: string;
    public agent: agent.PropertyAgent;
    public appraisal?: string;
    public archivedMedia: media.File[];
    public attributes: PropertyAttributesT;
    public auctionStatus: auction.AuctionStatus;
    public bathrooms: number;
    public bedrooms: number;
    public businessId?: string;
    public campaignStatus: CampaignStatusT;
    public campaignType: Property.CampaignType;
    public conveyancer: conveyancer.Conveyancer;
    public coreLogic: { largeImage: string; mediumImage: string; thumbnail: string };
    public created: string;
    public customAdvertisedPrice?: boolean;
    public displayPrice?: string;
    public domainListingStatus?: DomainModel.Domain.ListingStatus;
    public ensuites: number;
    public expectedSellingDate?: string;
    public features: PropertyFeaturesT;
    public hideFullAddress?: boolean;
    public id: string;
    public invalidReason?: phase.InvalidReasons;
    public invalidReasonDescription?: string;
    public leadScore?: lead.Score;
    public leadStatus?: LeadStatus;
    public listingUrl: string;
    public listingDate?: string;
    public lostAndSoldListing?: listings.LostAndSoldListing;
    public media: media.File[];
    public parking: number;
    public parkingCarport: number;
    public parkingGarage: number;
    public parkingOther: number;
    public phase: phase.Phases;
    public propertyAttributes?: any; //deprecated?
    public propertyAuthority?: PropertyAuthority;
    public publishSalePrice: number;
    public reaListingId?: string;
    public saleHistory: { salePrice: number; saleDate: number }[];
    public salePrice?: number;
    public salePriceMax?: number;
    public salePriceMin?: number;
    public salesAssistant?: AgentT;
    public salesRegion: string;
    public secondaryAgent?: AgentT;
    public service: ServiceT;
    public shared: string;
    public soldPrice?: number;
    public states: { [State in Property.States]?: Property.StateProgress };
    public timeZone: string;
    public toilets?: number;
    public type: string;
    public retirementPropertySubtype?: RetirementPropertySubtype[];
    public ruralPropertySubtype?: RuralPropertySubtype;
    public utm: Utm;
    public valuations: { [key: string]: Valuation };
    public vendor?: VendorInfoT;
    public vendorMedium: lead.Medium[];
    public verified: boolean;
    public videoUrl?: string;
    public virtualTourUrl?: string;
    public threeDimensionalFloorPlan?: string;
    public externalPropertySite?: string;
    public externalCustomPropertySite?: string;
    public externalOffers?: string;
    public supportAgent?: UserT;
    public reportLastViewed?: string;
    public reportExpiresAt?: string;
    public enabledPortals?: portal.Portal[];
    public prospectReason?: phase.ProspectReason;
    public prospectReasonDescription?: string;
    public workflowReminderCadence?: number;
    public businessName?: string;
    public agencyName?: string;
    public myDesktopExternalIds?: string[];
    public vaultExternalIds?: string[];
    public imageUrl?: string;
    public isDatabase: boolean;
    public vendorPortalEnabled?: boolean;
    public soldDate?: string;
    public archived?: 'NotTheOwner' | 'Cleanup' | 'PrimaryContactDeleted' | 'NotArchived';
    public archivedDisplayName?: string;
    public appraisalNote?: string;
    public agentPriceOpinion?: number;
    public agentPriceOpinionMin?: number;
    public agentPriceOpinionMax?: number;
    public vendorLastContacted?: string;
    public source?: 'NURTURE_CLOUD' | 'VAULT_RE' | 'ACTIVE_PIPE' | 'UNKNOWN';
    public appraisalType?: PropertyAppraisalTypeDto;
    public goLiveDate?: string;
    public contractOfSaleAlwaysVisible?: boolean;
}

export interface OwnersCorporation {
    annualFee: string;
    businessAddress: string;
    businessName: string;
    contactName: string;
    email: string;
    faxNumber: string;
    notes: string;
    phoneNumber: string;
}

type ActivityCategory =
    | 'Open'
    | 'Listing'
    | 'Note'
    | 'Call'
    | 'Text'
    | 'Offer'
    | 'Contract'
    | 'Auction'
    | 'Buyer'
    | 'System'
    | 'Email'
    | 'Reminder'
    | 'CompleteReminder'
    | 'Meeting'
    | 'Price'
    | 'Agent'
    | 'SalesManager'
    | 'Vendor'
    | 'Lead';

export interface Activity {
    category: ActivityCategory;
    dateTime: string;
    title: string;
    description?: string;
    user?: string;
    userId?: string;
    taskGroup?: string;
}

export namespace Property {
    // TODO: This should be replaced with CampaignTypeDto
    export enum CampaignType {
        None = 'None',
        PrivateTreaty = 'PrivateTreaty',
        Auction = 'Auction',
    }

    export interface Tenant {
        agentAddress?: string;
        agentEmail?: string;
        agentName?: string;
        agentPhoneNumber?: string;
        tenancyAgreement?: media.File;
        tenantEmail?: string;
        tenantName?: string;
        tenantPhoneNumber?: string;
        termEndDate?: string;
        weeklyRent?: string;
    }

    export interface PropertyCard {
        shortFormattedAddress?: string;
        id: string;
        formattedAddress?: string;
        imageUrl?: string;
        agent?: string;
        phase: phase.Phases;
        isDatabase: boolean;
    }

    export interface BaseOpen {
        dateTime: string;
        duration: string;
        id: string;
    }

    export namespace saleCampaign {
        export interface Offer {
            amount: number;
            dateTime: string;
            buyerId: string;
            status: string;
        }

        export interface CampaignBuyer {
            id: string;
            classification: string;
            firstName: string;
            lastInitial: string;
            opensAttended: number;
            financeApproved: boolean;
            highestOfferAmount: number;
            created: string;
        }

        export interface Buyers {
            [key: string]: CampaignBuyer;
        }

        export interface BuyerInterestStat {
            buyers: number;
            contractsRequested: number;
            openAttendees: number;
        }

        export interface BuyerInterestStats {
            [key: number]: BuyerInterestStat;
        }

        export interface Overview {
            totalBuyers: number;
            buyersAttendingAnOpen: number;
            buyersRequestingAContract: number;
            shortlistedBuyers: number;
            totalOffers: number;
        }
    }

    export namespace report {
        export interface DomainProperty {
            lowerPrice: number;
            midPrice: number;
            upperPrice: number;
            priceConfidence: string;
        }

        interface SeriesInfoValues {
            '25thPercentileSoldPrice'?: string;
            '5thPercentileSoldPrice'?: string;
            '75thPercentileSoldPrice'?: string;
            '95thPercentileSoldPrice'?: string;
            auctionNumberAuctioned?: string;
            auctionNumberSold?: string;
            daysOnMarket?: string;
            discountPercentage?: string;
            highestRentListingPrice?: string;
            highestSaleListingPrice?: string;
            highestSoldPrice?: string;
            lowestRentListingPrice?: string;
            lowestSaleListingPrice?: string;
            lowestSoldPrice?: string;
            medianRentListingPrice?: string;
            medianSaleListingPrice?: string;
            medianSoldPrice?: string;
            numberRentListing?: string;
            numberSaleListing?: string;
            numberSold?: string;
        }

        export interface SeriesInfo {
            year: number;
            month: number;
            values: SeriesInfoValues;
        }

        export interface Performance {
            seriesInfo: SeriesInfo[];
        }
    }

    export enum Service {
        // Intentionally spelled incorrectly due to how they are spelled in the API
        Serviceable = 'Servicable',
        Fringe = 'Fringe',
        Unserviceable = 'Unservicable',
    }

    export type States =
        /** @Deprecated */
        | 'vendor-request-stylist'
        /** @Deprecated */
        | 'vendor-stylist-questionnaire'
        /** @Deprecated */
        | 'stylist-appointment'
        /** @Deprecated */
        | 'stylist-report'
        /** @Deprecated */
        | 'valuation-automated'
        | 'suburb-service-available'
        | 'suburb-support-prompt-vendor'
        | 'payment-marketing'
        | 'payment-auction'
        | 'sell-support-welcome-call'
        | 'sell-vendor-request-agent-visit'
        | 'review-appraisal-request'
        /** @Deprecated */
        | 'assign-agent'
        | 'sell-agent-organise-appraisal-visit'
        /** @Deprecated */
        | 'sell-agent-appraisal-visit'
        /** @Deprecated */
        | 'select-campaign-type'
        /** @Deprecated */
        | 'sell-agent-organise-visit'
        /** @Deprecated */
        | 'sell-agent-visit'
        /** @Deprecated */
        | 'agent-complete-cma'
        | 'vendor-proceeding'
        /** @Deprecated */
        | 'send-agency-authority'
        /** @Deprecated */
        | 'agent-call-vendor'
        | 'sales-manager-review-agency-agreement'
        | 'campaign-auction-date-confirmed'
        | 'book-auctioneer'
        /** @Deprecated */
        | 'sell-agent-book-auctioneer'
        /** @Deprecated */
        | 'sell-auction-auctioneer-confirmed'
        /** @Deprecated */
        | 'sell-agent-auction-confirmed'
        | 'auction-process'
        /** @Deprecated */
        | 'campaign-agent-pre-auction-appointment'
        /** @Deprecated */
        | 'campaign-agent-pre-auction-meeting'
        | 'auction-cancellation-reason'
        | 'auction-cancelled'
        | 'campaign-auction'
        /** @Deprecated */
        | 'record-auction-winner'
        | 'record-winning-auction-offer'
        | 'convert-auction-listing'
        | 'campaign-conveyancing'
        | 'campaign-vendor-conveyancer-choice'
        | 'notify-provided-conveyancer'
        | 'notify-custom-conveyancer'
        | 'upload-conveyancing-docs'
        | 'sell-agent-acknowledge-contract'
        /** @Deprecated */
        | 'campaign-photographer-booked'
        /** @Deprecated */
        | 'campaign-vendor-request-photographs'
        | 'campaign-support-order-photographer'
        /** @Deprecated */
        | 'campaign-photographer-appointment'
        | 'campaign-photographs-ready'
        | 'photographer-upload-photographs'
        /** @Deprecated */
        | 'campaign-support-upload-photographs'
        | 'campaign-agent-approve-photographs'
        /** @Deprecated */
        | 'campaign-agent-plan-opens-schedule'
        | 'copywriting-process'
        | 'campaign-agent-request-copywriting'
        | 'campaign-copywriting'
        | 'campaign-live'
        | 'campaign-agent-create-listing'
        | 'campaign-vendor-approve-listing-details'
        | 'campaign-support-order-signboard'
        | 'campaign-support-upload-listing'
        | 'offer-process'
        | 'campaign-first-contract'
        | 'campaign-first-open'
        | 'campaign-first-offer'
        /** @Deprecated */
        | 'campaign-vendor-offer-accepted'
        | 'settlement-process'
        | 'put-up-sold-stickers'
        /** @Deprecated */
        | 'settlement-contract-and-deposit-accepted'
        /** @Deprecated */
        | 'settlement-cooling-off-period'
        | 'settlement-final-inspection'
        | 'settlement-exchange-settled'
        | 'settlement-new-vendor-address';

    export enum StateProgress {
        Incomplete = 'Incomplete',
        Complete = 'Complete',
        Cancelled = 'Cancelled',
    }

    export enum LikelihoodOfSelling {
        NotSure = 'Not Sure',
        Likely = 'Likely',
        Unlikely = 'Unlikely',
    }

    export interface MyProperty {
        id: string;
        address?: AddressT;
        phase: phase.Phases;
        agent?: {
            id: string;
            name: string;
            avatar?: media.File;
        };
        states: { [State in Property.States]?: Property.StateProgress };
        image: string;
        type: PropertyType;
        latestAppraisalDateTime?: string;
        timeZone: string;
        advertisedPrice: string;
        soldPrice: number;
    }

    export type AssociatedUserRole =
        | 'owner'
        | user.Role.Agent
        | user.Role.Vendor
        | user.Role.SalesManager
        | user.Role.Buyer;

    export interface AssociatedUser {
        userId?: string;
        name?: string;
        phoneNumber?: string;
        email?: string;
        role?: AssociatedUserRole;
    }

    export interface AreaAttribute {
        uom?: Property.AreaUnitOfMeasurement;
        value?: number;
    }

    export enum AreaUnitOfMeasurement {
        SquareMetres = 'SQM',
        Acres = 'AC',
        Hectares = 'HA',
    }

    export type SettableArchiveReasons = Exclude<
        Property['archived'],
        'NotArchived' | 'PrimaryContactDeleted' | 'Cleanup'
    >;
}

export namespace propertyApi {
    export namespace update {
        export namespace put {
            export type Request = Partial<Property>;
            export interface Response extends Property {}
        }
    }

    export namespace property {
        export namespace get {
            export enum ErrorCode {
                Forbidden = 'FORBIDDEN',
            }
            export type Response = Property;
        }
    }

    export namespace campaign {
        export namespace get {
            export interface Response extends campaignData.Campaign {}
        }
    }

    export namespace opens {
        export namespace get {
            export interface Response {
                buyers: buyer.Buyer[];
                opens: AttendedInspectionDto[];
                users: UserT[];
            }
        }
    }

    export namespace getVendorOpens {
        export namespace get {
            export type Response = Property.BaseOpen[];
        }
    }

    export namespace legalUpload {
        export namespace post {
            export type Response = Property;
        }
    }

    export namespace upload {
        export namespace post {
            export type Response = Property;
        }
    }

    export namespace corporation {
        export namespace get {
            export interface Response extends OwnersCorporation {}
        }

        export namespace post {
            export interface Request extends OwnersCorporation {
                id: string;
            }

            export interface Response extends OwnersCorporation {}
        }
    }

    export namespace valuationReport {
        export namespace post {
            export interface Request {
                files: media.File[];
                high?: number;
                id: string;
                low?: number;
                value: number;
            }

            export interface Response extends Property {}
        }
    }

    export namespace tenant {
        export namespace get {
            export interface Response extends Property.Tenant {}
        }
        export namespace post {
            export interface Request extends Property.Tenant {}
            export interface Response extends Property.Tenant {}
        }
    }

    export namespace providedConveyancerNotified {
        export namespace post {
            export interface Request {
                notes?: string;
            }
            export interface Response extends ApiSuccessResponse {}
        }
    }

    export namespace Domain {
        export namespace SyncListing {
            export namespace POST {
                export interface Response extends DomainModel.Domain.ListingStatus {}
            }
        }
    }

    export namespace sellSupportWelcomeCall {
        export namespace post {
            export interface Request {
                notes?: string;
            }
        }
    }

    export namespace campaignSupportOrderSignboard {
        export namespace post {
            export interface Request {
                id: string;
            }
            export interface Response extends ApiSuccessResponse {}
        }
    }

    export namespace campaignAgentPlanOpensSchedule {
        export namespace post {
            export interface Response extends ApiSuccessResponse {}
        }
    }

    export namespace agents {
        export namespace get {
            export type Response = AgentT[];
        }
    }

    export namespace reviewAgencyAgreement {
        export namespace post {
            export interface Response extends ApiSuccessResponse {}
        }
    }

    export namespace campaignAgentCreateListing {
        export namespace post {
            export interface Response extends ApiSuccessResponse {}
        }
    }

    export namespace sellVendorProceeding {
        export namespace post {
            export interface SelectCampaign {
                auctionSelected: boolean;
            }
        }
    }

    export namespace campaignSupportUploadListing {
        export namespace post {
            export interface Response extends ApiSuccessResponse {}
        }
    }

    export namespace putUpSoldStickers {
        export namespace post {
            export interface Response extends ApiSuccessResponse {}
        }
    }

    export namespace completeFinalInspection {
        export namespace post {
            export interface Response extends ApiSuccessResponse {}
        }
    }

    export namespace completeSettlement {
        export namespace post {
            export interface Response extends ApiSuccessResponse {}
        }
    }

    export namespace customConveyancerNotified {
        export namespace post {
            export interface Request extends conveyancer.Conveyancer {}
            export interface Response extends ApiSuccessResponse {}
        }
    }

    export namespace sellAgentAppraisalVisit {
        export namespace post {
            export interface Response extends ApiSuccessResponse {}
            export interface Request {
                prospectReason?: phase.ProspectReason;
                invalidReason?: phase.InvalidReasons;
                reason?: string;
                expectedSellingDate?: string;
                likelihoodOfSelling?: string;
                phase?: string;
                leadScore?: lead.Score;
                workflowReminderCadence?: number;
                appraisalType?: AppraisalType;
            }
        }
    }

    export namespace skipAppraisalRequest {
        export namespace post {
            export interface Response extends ApiSuccessResponse {}
        }
    }

    export namespace reviewAppraisalRequest {
        export namespace post {
            export interface Response extends ApiSuccessResponse {}
        }
    }

    export namespace getAllPropertyTodo {
        export namespace get {
            export interface Response {
                properties: {
                    [id: string]: todo.Property;
                };
                reminders: reminder.SimpleReminder[];
                tasks: task.TaskMinimal[];
                meetings: todo.Appointment[];
            }
        }
    }

    export namespace getAppointments {
        export namespace get {
            export interface Response {
                meetings: Appointment[];
                properties: todo.Property[];
            }
        }
    }

    export namespace changeCampaignType {
        export namespace post {
            export type Request = Property.CampaignType;
        }
    }

    export namespace addFilesWithTag {
        export namespace post {
            export type Response = Property;
        }
    }

    export namespace getOffers {
        export namespace get {
            export interface Response {
                offers: { [id: string]: Offer };
                buyers: { [id: string]: buyer.Details };
            }
        }
    }

    export namespace saleCampaign {
        export namespace get {
            export interface Response {
                advertisedPrice: string;
                overview: Property.saleCampaign.Overview;
                offers: Property.saleCampaign.Offer[];
                buyers: Property.saleCampaign.Buyers;
                buyerInterestStats: Property.saleCampaign.BuyerInterestStats;
                opens: campaignData.Event[];
                auction?: campaignData.Event;
                launched: string;
            }
        }
    }

    export namespace setLeadScore {
        export namespace put {
            export interface Response extends ApiSuccessResponse {}
        }
    }

    export namespace getPropertyActivity {
        export namespace get {
            export interface Response {
                activity: Activity[];
            }
        }
    }
}

export {
    PropertySoldDataT,
    PropertyAlertDataT,
    PropertyListingDataT,
    ComparablePropertyT,
    AppraisalReportListItem,
    AppraisalListResponse,
    ComparableProperty,
};
