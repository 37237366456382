import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SplitFormattedAddressComponent } from './components/split-formatted-address.component';

@NgModule({
    imports: [CommonModule],
    exports: [SplitFormattedAddressComponent],
    declarations: [SplitFormattedAddressComponent],
    providers: [],
})
export class SplitFormattedAddressModule {
}
