import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NguiMapModule } from '@ngui/map';
import { UIRouterModule } from '@uirouter/angular';

import { UpsideCommonModule } from '../../../common/common';
import { SharedModule } from '../../../common/shared.module';
import { SharedVendorModule } from '../../../modules/shared-vendor/shared-vendor.module';
import { VendorReviewsModule } from '../../../modules/vendor-reviews/vendor-reviews.module';
import { AppraisalBookerModule } from '../common/appraisal-booker/appraisal-booker.module';
import { ConversionCommonModule } from '../common/conversion-common.module';

import { AppraisalRequestedComponent } from './appraisal-requested/appraisal-requested.component';
import { AppraisalComponent } from './appraisal.component';
import { appraisalStates } from './appraisal.states';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ConversionCommonModule,
        UpsideCommonModule,
        NguiMapModule.forRoot({ apiUrl: 'https://maps.google.com/maps/api/js?key=MY_GOOGLE_API_KEY' }),
        UIRouterModule.forChild({ states: appraisalStates }),
        AppraisalBookerModule,
        SharedModule,
        SharedVendorModule,
        VendorReviewsModule,
    ],
    declarations: [AppraisalComponent, AppraisalRequestedComponent],
    entryComponents: [AppraisalComponent],
})
export class AppraisalModule {}
