<up-isolated-container [showLogo]="showLogo">
    <ng-container heading>
        <span class="u-text-weight--semi-bold">
            <ng-container *ngIf="!agent"> Request a call back </ng-container>
            <ng-container *ngIf="agent"> Request a call from: </ng-container>
        </span>
    </ng-container>
    <ng-container caption *ngIf="!agent">
        Please provide us with the following details and a friendly member of the Upside team will call you back.
    </ng-container>
    <ng-container>
        <up-agent-rating-card *ngIf="agent" [agent]="agent"> </up-agent-rating-card>

        <form [formGroup]="callbackForm" (ngSubmit)="onSubmit()">
            <div class="u-padding-bottom--md">
                <up-input
                    id="fullName"
                    [formControlName]="'name'"
                    [size]="'large'"
                    [fullWidth]="true"
                    [internalLabel]="true"
                    [error]="hasAttemptedFormSubmit && callbackForm.get('name').invalid"
                    [label]="'Your full name'"
                >
                </up-input>
                <up-help-text [type]="'error'" *ngIf="hasAttemptedFormSubmit && callbackForm.get('name').invalid">
                    Please enter your full name.
                </up-help-text>
            </div>

            <div class="u-padding-bottom--md">
                <up-input
                    id="phoneNumber"
                    [formControlName]="'phone'"
                    [size]="'large'"
                    [type]="'tel'"
                    [fullWidth]="true"
                    [internalLabel]="true"
                    [error]="hasAttemptedFormSubmit && callbackForm.get('phone').invalid"
                    [label]="'Your phone number'"
                >
                </up-input>
                <up-help-text [type]="'error'" *ngIf="hasAttemptedFormSubmit && callbackForm.get('phone').invalid">
                    Please enter a valid phone number.
                </up-help-text>
            </div>

            <div class="u-padding-bottom--md">
                <up-select [fullWidth]="true" [label]="'Preferred time'" [formControlName]="'preferredTime'">
                    <up-select-option [value]="preferredTimeEnum.AnyTime">Any time</up-select-option>
                    <up-select-option [value]="preferredTimeEnum.Morning">Morning</up-select-option>
                    <up-select-option [value]="preferredTimeEnum.Afternoon">Afternoon</up-select-option>
                    <up-select-option [value]="preferredTimeEnum.Evening">Evening</up-select-option>
                </up-select>
            </div>

            <up-alert class="u-margin-bottom--xl" [type]="'negative'" *ngIf="error"> {{ error }} </up-alert>
            <up-button
                class="u-margin-top--md"
                id="submit"
                [type]="'submit'"
                [size]="'large'"
                [loading]="loading"
                [fullWidth]="true"
                [style]="'primary'"
            >
                Request a Call Back
            </up-button>
        </form>
    </ng-container>
    <div footer> Need some help? Call <up-link [href]="'tel:1800877433'">1800 877 433</up-link> </div>
</up-isolated-container>
