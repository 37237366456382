import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CreateLeadByUserIdDto } from '../../../common/models/dto/lead/create-lead-by-user-id.dto';
import { LeadDto } from '../../../common/models/dto/lead/lead.dto';
import { OkDto } from '../../../common/models/dto/response/ok.dto';
import { leadApi } from '../../../common/models/lead-api.model';
import { lead } from '../../../common/models/lead.model';

export interface LeadResponse {
    lead: LeadDto;
}

const headers = new HttpHeaders().set('Content-Type', 'application/json');

const HEADERS_CONFIG = {
    withCredentials: true,
};

@Injectable()
export class LeadResource {
    private readonly baseUrl = '/api/v2/lead';

    constructor(private http: HttpClient) {}

    public create(lead: leadApi.create.post.Request): Observable<LeadResponse> {
        return this.http.post<LeadResponse>(`${this.baseUrl}/`, lead, { ...HEADERS_CONFIG, headers });
    }

    public createByUserId(body: CreateLeadByUserIdDto): Observable<LeadResponse> {
        return this.http.post<LeadResponse>(`${this.baseUrl}/by-user`, body);
    }

    public setVisibility(
        leadId: string,
        setAsProspect: boolean,
        leadScore?: lead.Score,
        workflowReminderCadence?: number,
    ): Observable<OkDto> {
        return this.http.post<OkDto>(
            `${this.baseUrl}/${leadId}/visibility`,
            { visible: setAsProspect, leadScore, workflowReminderCadence },
            { ...HEADERS_CONFIG, headers },
        );
    }

    public createForAgent(lead: leadApi.createForAgent.post.Request, agentId: string): Observable<LeadResponse> {
        return this.http.post<LeadResponse>(`${this.baseUrl}/agent/${agentId}/`, lead, HEADERS_CONFIG);
    }

    public createForAgentByUserId(agentId: string, request: CreateLeadByUserIdDto): Observable<LeadResponse> {
        return this.http.post<LeadResponse>(`${this.baseUrl}/agent/${agentId}/by-user`, request);
    }
}
