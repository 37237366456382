import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { autopilot } from '../../../common/models/autopilot.module';
import { ApiSuccessResponse } from '../../../common/models/common.model';

const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
};

const BASE_URL = '/api/v2/autopilot';

@Injectable()
export class AutopilotResource {
    constructor(private http: HttpClient) {}

    public requestCall(request: autopilot.api.requestCall.post.Request): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(`${BASE_URL}/request-call`, request, HEADERS_CONFIG);
    }
}
