import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BrochureActivityRequestDto } from '../../../common/models/dto/brochure/brochure-activity-request.dto';
import { BrochureListingResponseDto } from '../../../common/models/dto/brochure/brochure-listing-response.dto';

@Injectable({ providedIn: 'root' })
export class BrochureResource {
    public BASE_URL = `/api/v2`;

    constructor(private http: HttpClient) {}

    public get(token: string) {
        return this.http.get<BrochureListingResponseDto>(`${this.BASE_URL}/brochure/${token}`);
    }

    public getByPropertyId(propertyId: string): Observable<BrochureListingResponseDto> {
        return this.http.get<BrochureListingResponseDto>(`${this.BASE_URL}/property/${propertyId}/brochure`);
    }

    public trackActivity(token: string, request: BrochureActivityRequestDto): Observable<void> {
        return this.http.post<void>(`${this.BASE_URL}/brochure/${token}/activity`, request);
    }
}
