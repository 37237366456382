import { Injectable } from '@angular/core';
import { TransitionService } from '@uirouter/angular';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { dashboardRoot, internalRoot, vendorRoot } from '../../../common/constants/state-names.constants';
import { AppPlatform } from '../../../common/models/app-platform.model';

@Injectable()
export class AppPlatformService {
    private _platform$ = new ReplaySubject<AppPlatform>(1);
    private _platform: AppPlatform;
    private listening: boolean;
    public platform$ = this._platform$.asObservable().pipe(distinctUntilChanged());

    public get platform(): AppPlatform {
        return this._platform;
    }

    public get isInternal(): boolean {
        return this.platform === AppPlatform.Internal;
    }

    constructor(private transitionService: TransitionService) {}

    // Begins listening for platform changes
    public listen(): void {
        if (this.listening) return;

        this.listening = true;
        this.transitionService.onSuccess({}, transition => {
            const states = transition.$to().includes;

            if (states[vendorRoot]) {
                if (states[dashboardRoot]) {
                    this._platform = AppPlatform.VendorDashboard;
                } else {
                    this._platform = AppPlatform.Marketing;
                }
            } else if (states[internalRoot]) {
                this._platform = AppPlatform.Internal;
            }

            this._platform$.next(this._platform);
        });
    }
}
