import { Pipe, PipeTransform } from '@angular/core';

import { GeoLocationDto } from '../../../../common/models/dto/location/geo-location.dto';
import { location } from '../../../../common/models/location.model';

@Pipe({
    name: 'ncGeoLocationToLatLng',
})
export class GeoLocationToLatLngPipe implements PipeTransform {
    public transform(value?: GeoLocationDto): location.LatLong | undefined {
        if (!value || typeof value.latitude !== 'number' || typeof value.longitude !== 'number') return;

        return {
            lat: value.latitude,
            lng: value.longitude,
        };
    }
}
