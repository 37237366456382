<span
    class="inner"
    [ngClass]="{
        'is-x-small': size === 'x-small',
        'is-small': size === 'small',
        'is-medium': size === 'medium',
        'is-large': size === 'large',
        'is-small-at-xs': sizeAtXs === 'small',
        'is-medium-at-xs': sizeAtXs === 'medium',
        'is-large-at-xs': sizeAtXs === 'large',
        'is-small-at-sm': sizeAtSm === 'small',
        'is-medium-at-sm': sizeAtSm === 'medium',
        'is-large-at-sm': sizeAtSm === 'large',
        'has-border': border,
        'appearance-color-background': appearance === 'color-background',
        'appearance-neutral-background': appearance === 'neutral-background'
    }"
>
    <span aria-hidden="true" class="u-display--block u-text-weight--semi-bold">{{ name | ncInitialsOfName }}</span>
    <ng-container *ngIf="upLazyLoad; else noLazyLoad">
        <div [lazyLoad]="encodeIfRequired(imgSrc)" class="u-display--block image"></div>
    </ng-container>
    <ng-template #noLazyLoad>
        <div [backgroundImage]="encodeIfRequired(imgSrc)" class="u-display--block image"></div>
    </ng-template>
</span>
