import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MediaTagName } from '../../../common/models/domain/media/media-tag-name.model';
import { AttachableFilesDto } from '../../../common/models/dto/file-management/attachable-files.dto';
import { FileStorageDataDto } from '../../../common/models/dto/file-management/file-storage-data.dto';
import { UploadableFilesDto } from '../../../common/models/dto/file-management/uploadable-files.dto';
import { media } from '../../../common/models/media.model';
import { Property } from '../../../common/models/property.model';
import { storageApi } from '../../../common/models/storage-api.model';
import { CustomQueryEncoder } from '../../../encoders/custom-query.encoder';

const BASE_URL = '/api/v2';

@Injectable()
export class StorageResource {
    constructor(private httpClient: HttpClient, private customQueryEncoder: CustomQueryEncoder) {}

    // Note: while the upload endpoints are different for buyer and property files, creating the read
    // URL uses the same endpoint
    public getPropertyFileReadUrl(
        propertyId: string,
        url: string,
    ): Observable<storageApi.getPropertyFileReadUrl.get.Response> {
        const params = new HttpParams({
            encoder: this.customQueryEncoder,
            fromObject: { url },
        });

        return this.httpClient.get<storageApi.getPropertyFileReadUrl.get.Response>(
            `${BASE_URL}/property/${propertyId}/media/read`,
            {
                params,
            },
        );
    }

    public getServingUrl(
        body: storageApi.getServingUrl.post.Request,
    ): Observable<storageApi.getServingUrl.post.Response> {
        return this.httpClient.post<storageApi.getServingUrl.post.Response>(
            `${BASE_URL}/images/createServingUrl`,
            body,
        );
    }

    public propertyAddMultipleFilesToTag(
        propertyId: string,
        files: media.File[],
        tag: MediaTagName,
    ): Observable<storageApi.propertyAddMultipleFilesToTag.post.Response> {
        return this.httpClient.post<storageApi.propertyAddMultipleFilesToTag.post.Response>(
            `${BASE_URL}/property/${propertyId}/files/${tag}/add-upload`,
            { files },
        );
    }

    public propertyAddSingleFileToTag(
        propertyId: string,
        files: media.File[],
        tag: MediaTagName,
    ): Observable<storageApi.propertyAddSingleFileTag.post.Response> {
        return this.httpClient.post<storageApi.propertyAddSingleFileTag.post.Response>(
            `${BASE_URL}/property/${propertyId}/files/${tag}/upload`,
            { files },
        );
    }

    public propertyArchiveFiles(
        propertyId: string,
        urls: string[],
    ): Observable<storageApi.propertyArchiveFiles.post.Response> {
        return this.httpClient.post<storageApi.propertyArchiveFiles.post.Response>(
            `${BASE_URL}/property/${propertyId}/media/archive`,
            { urls },
        );
    }

    public getPropertyFileUploadUrl(
        propertyId: string,
        mediaTag: MediaTagName,
        body: storageApi.getPropertyFileUploadUrl.post.Request,
    ): Observable<storageApi.getPropertyFileUploadUrl.post.Response> {
        return this.httpClient.post<storageApi.getPropertyFileUploadUrl.post.Response>(
            `${BASE_URL}/property/${propertyId}/media/${mediaTag}/upload`,
            body,
        );
    }

    public getBuyerFileUploadUrl(
        propertyId: string,
        buyerId: string,
        mediaTag: MediaTagName,
        body: storageApi.getBuyerFileUploadUrl.post.Request,
    ): Observable<storageApi.getBuyerFileUploadUrl.post.Response> {
        return this.httpClient.post<storageApi.getBuyerFileUploadUrl.post.Response>(
            `${BASE_URL}/property/${propertyId}/buyers/${buyerId}/media/${mediaTag}/upload-url`,
            body,
        );
    }

    public getUserFileUploadUrl(
        userId: string,
        mediaTag: MediaTagName,
        body: storageApi.getUserFileUploadUrl.post.Request,
    ): Observable<storageApi.getUserFileUploadUrl.post.Response> {
        return this.httpClient.post<storageApi.getUserFileUploadUrl.post.Response>(
            `${BASE_URL}/user/${userId}/media/${mediaTag}/upload`,
            body,
        );
    }

    public uploadFileToGcs(
        uploadUrl: string,
        body: storageApi.uploadFileToGcs.post.Request,
    ): Observable<storageApi.uploadFileToGcs.post.Response> {
        return this.httpClient.post<storageApi.uploadFileToGcs.post.Response>(uploadUrl, body);
    }

    public createBulkPropertyFilesUploadUrls(
        propertyId: string,
        body: UploadableFilesDto,
    ): Observable<FileStorageDataDto> {
        return this.httpClient.post<FileStorageDataDto>(`${BASE_URL}/property/${propertyId}/media/bulk-upload`, body);
    }

    public bulkAttachUploadedFilesToProperty(propertyId: string, body: AttachableFilesDto): Observable<Property> {
        return this.httpClient.post<Property>(`${BASE_URL}/property/${propertyId}/files/bulk-upload`, body);
    }
}
