import { InjectionToken } from '@angular/core';

interface RolesConfigT {
    User: string;
    Staff: string;
    Admin: string;
    SalesManager: string;
    Super: string;
    Agent: string;
}

const ROLES_DI_CONFIG = {
    User: 'user',
    Staff: 'staff',
    Admin: 'admin',
    SalesManager: 'salesManager',
    Super: 'super',
    Agent: 'agent',
};

let ROLES_CONFIG = new InjectionToken<RolesConfigT>('app.constants.roles');

export { RolesConfigT, ROLES_DI_CONFIG, ROLES_CONFIG };
