import { ModuleWithProviders, NgModule } from '@angular/core';

import { IntercomService } from './service/intercom/intercom.service';

@NgModule({ providers: [IntercomService] })
export class IntercomModule {
    public static forRoot(): ModuleWithProviders<IntercomModule> {
        return {
            ngModule: IntercomModule,
            providers: [IntercomService],
        };
    }
}
