export namespace modal {
    export enum CloseType {
        Dismiss,
        Confirm,
    }

    export enum Lifecycle {
        OnBeforeShow,
        OnShow,
        OnAfterShow,
        OnBeforeClose,
        OnClose,
        OnAfterClose,
    }

    export interface CloseEvent<Data = any> {
        type: CloseType;
        data?: Data;
    }

    export interface Controls {
        defaultDismissOverrideWithFn: (fn: Function) => void;
        close: <T = any>(type?: modal.CloseType, data?: T) => void;
    }
}
