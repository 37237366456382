<div class="outer u-padding-horizontal--xl u-text-align--center" [ngClass]="{ 'c-empty-state': border }">
    <div class="inner">
        <div class="image"><ng-content select="[image], [slot='image']'"></ng-content></div>
        <div class="default-image-wrapper u-text-align--center">
            <img
                *ngIf="isLarge"
                class="default-image"
                [src]="'/static/images/generic-empty-state.svg' | upCdnUrl"
                width="160"
                height="148"
                alt=""
            />
        </div>
        <div class="body">
            <div
                class="heading"
                [ngClass]="{
                    'u-text-heading--rg': isLarge || isMedium,
                    'u-text-heading--sm': isSmall
                }"
                >{{ heading }}</div
            >
            <div
                [ngClass]="{
                    'u-text-body--lg': isLarge,
                    'u-text-body--rg': isMedium,
                    'u-text-body--sm': isSmall
                }"
                class="content"
            >
                <ng-content select="[content], [slot='content']"></ng-content>
            </div>
        </div>
    </div>
</div>
