import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { AutofillMonitorService } from '../../../services/autofill-monitor.service';
import { IconName } from '../../icon/icon.component';

type Size = 'regular' | 'large';
type Type = 'textarea' | 'number' | 'text' | 'email' | 'password' | 'tel';
type Resize = 'none' | 'both' | 'horizontal' | 'vertical';
export type InputStyle = 'minimal' | 'full' | 'borderless';

let inputCount = 0;

@Component({
    selector: 'up-input, nc-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // Needed for Control Value Accessor
            // eslint-disable-next-line @angular-eslint/no-forward-ref
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
})
export class InputComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
    @Input() public type: Type = 'text';
    @HostBinding('class.full-width')
    @Input()
    public fullWidth: boolean;
    @Input() public autofocus: boolean;
    @Input() public placeholder = '';
    @Input() public label: string;
    @Input() public hideLabel: boolean;
    @Input() public name = '';
    @Input() public size: Size = 'regular';
    @Input() public internalLabel: boolean;
    @Input() public maxlength: number;
    @Input() public resize: Resize = 'both';
    @Input() public error: boolean;
    @Input() public success: boolean;
    @Input() public rows = 4;
    @Input() public cols: number;
    @Input() public id: string;
    @Input() public style: InputStyle = 'full';
    @Input() public icon: IconName;
    @Input() public iconColor = 'currentColor';
    // whether to show the clear input button
    @Input() public clearable: boolean;
    @Input() public autocomplete: string;
    @Input() public passiveLoading: boolean;
    @Output() public blur: EventEmitter<void> = new EventEmitter();
    @Output() public focus: EventEmitter<void> = new EventEmitter();
    @ViewChild('element', { static: false }) public elementRef: ElementRef;

    public uid = '';
    public focused = false;
    public elementId: string;
    public isEmpty = true;
    public isDirty = false;
    public value = '';
    public disabled = false;
    public onChange: Function = () => {};
    public onTouched: Function = () => {};

    constructor(private autofillMonitorService: AutofillMonitorService, private changeDetectorRef: ChangeDetectorRef) {
        inputCount++;

        this.uid = `input-${inputCount}`;
    }

    public ngOnInit(): void {
        // override automatically generated id if custom one is provided
        this.uid = this.id || this.uid;

        // Note: a label is required. If you want to hide it,
        // use the hideLabel property
        if (!this.label) {
            throw new Error('Labels are required with inputs');
        }
    }

    public onInputFocus(): void {
        this.focused = true;
        this.focus.emit();
    }

    public onInputBlur(): void {
        this.focused = false;
        this.onTouched();
        this.blur.emit();
    }

    public onInput(event): void {
        const value = event.target.value;
        this.isDirty = true;
        this.isEmpty = !value.toString().length;
        this.value = value;

        this.onChange(value);
    }

    public ngAfterViewInit() {
        // Angular does not detect autofill values, so we need to listen for the autofill animation event
        // If it is detected, we set the isEmpty property to false, which will fix the input styling
        // so that the label isn't sitting on top of the autofill data
        this.autofillMonitorService.monitor(this.elementRef.nativeElement).subscribe(e => {
            if (e.isAutofilled) {
                this.isEmpty = false;
            }
        });
    }

    // value accessor methods
    public writeValue(value): void {
        if (value) {
            this.isEmpty = false;
        }

        this.value = value;
    }

    public registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.changeDetectorRef.detectChanges();
    }

    public ngOnDestroy(): void {
        // we need to check if element ref exists first as it's possible for the
        // destroy hook to be called befor the ViewChild has been assigned
        if (this.elementRef) {
            this.autofillMonitorService.stopMonitoring(this.elementRef.nativeElement);
        }
    }

    public clear(): void {
        this.onChange('');
        this.elementRef.nativeElement.value = '';
        this.isEmpty = true;
        (<HTMLElement>this.elementRef.nativeElement).focus();
    }
}
