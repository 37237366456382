import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, map } from 'rxjs/operators';

import { UP_COLOR_CONSTANTS, UpColorConstants } from '../../../../common/constants/colors.constants';
import { UpValidators } from '../../../../common/form-validators/validators';
import { userApi } from '../../../../common/models/user-api.model';
import { TwillioService } from '../../../../common/services/twillio.service';
import { UserResource } from '../../../../modules/core/resources/user.resource';

@Component({
    selector: 'up-update-verification-phone-number',
    templateUrl: 'update-verification-phone-number.component.html',
    styleUrls: ['update-verification-phone-number.component.scss'],
})
export class UpdateVerificationPhoneNumberComponent implements OnInit {
    public form: FormGroup;
    public hasAttemptedSubmit: boolean;
    public errorUpdatingPhoneNumber: string;
    public isSubmitting: boolean;
    @Input() public userId: string;
    @Output() public update = new EventEmitter<any>();

    constructor(
        @Inject(UP_COLOR_CONSTANTS) public Colors: UpColorConstants,
        private userResource: UserResource,
        private formBuilder: FormBuilder,
        private twillioService: TwillioService,
    ) {}

    public ngOnInit(): void {
        this.form = this.formBuilder.group({
            phoneNumber: ['', [Validators.required, UpValidators.phoneNumberValidator]],
        });
    }

    public submit(): void {
        this.errorUpdatingPhoneNumber = undefined;
        this.hasAttemptedSubmit = true;
        if (this.form.invalid) return;
        this.isSubmitting = true;
        const updatedPhoneNumber = this.form.get('phoneNumber').value;
        this.userResource
            .smsVerify(this.userId, updatedPhoneNumber)
            .pipe(
                map(r => this.twillioService.errorResponseHandler(r)),
                finalize(() => (this.isSubmitting = false)),
            )
            .subscribe(
                () => this.update.emit(updatedPhoneNumber),
                (response: userApi.smsVerify.post.Response | HttpErrorResponse) => {
                    this.errorUpdatingPhoneNumber = response.message;
                },
            );
    }
}
