import * as TasksActions from './task.actions';
import { TaskAction } from './task.actions';

export interface TaskState {}

export function taskReducer(state: TaskState[] = [], action: TaskAction) {
    switch (action.type) {
        case TasksActions.GET_TASKS:
            return state;
        case TasksActions.TASKS_LOADED:
            return action.payload;
        default:
            return state;
    }
}
