import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import dayjs from 'dayjs';

import { guessLocalTimezone } from '../../../../common/utilities/date/guess-local-timezone.util';
import { CalendarTimeFormatPipe } from '../../pipes/calendar-time-format/calendar-time-format.pipe';
import { DateFormatPipe } from '../../pipes/date-format/date-format.pipe';

// We should try to keep this as curated as possible and limit new formats, and any new formats should be consulted with
// the front end team and design.
type Format =
    | 'long'
    | 'short'
    | 'relative'
    // @deprecated: See Date & Time formats documentation for replacement
    | 'calendar'
    // @deprecated: See Date & Time formats documentation for replacement
    | 'dateShort'
    // @deprecated: See Date & Time formats documentation for replacement
    | 'dateLongTimeShort'
    // @deprecated: See Date & Time formats documentation for replacement
    | 'dayOfWeekLong'
    // @deprecated: See Date & Time formats documentation for replacement
    | 'dayOfWeekShort'
    | 'mediumDate'
    | 'longDate'
    // @deprecated: See Date & Time formats documentation for replacement
    | 'timeShort'
    | 'shortTime'
    | 'shortYear'
    | 'longYear'
    | 'medium';

@Component({
    selector: 'nc-date-time',
    templateUrl: 'date-time.component.html',
    styleUrls: ['date-time.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimeComponent {
    @Input() public displayInTimeZone: string;
    @Input() public dateTime: string;
    @Input() public format: Format = 'dateLongTimeShort';
    @Input() public hideTimeZoneIndicator = false;
    @Input() public displayTransform: (output: string) => string = output => output;
    public readonly formatToDayjsFormatMap: Record<Exclude<Format, 'relative' | 'calendar'>, string> = {
        long: 'ddd, D MMMM YYYY | h:mma',
        short: 'DD/MM/YY | h:mma',
        dateShort: 'DD/MM/YY',
        dateLongTimeShort: 'Do MMM YYYY h:mma',
        dayOfWeekLong: 'dddd',
        dayOfWeekShort: 'ddd',
        mediumDate: 'D MMM YYYY',
        longDate: 'dddd, D MMMM YYYY',
        timeShort: 'h:mma',
        shortTime: 'h:mma',
        longYear: 'YYYY',
        shortYear: 'YY',
        medium: 'ddd, D MMM YYYY | h:mma',
    };
    public readonly now = dayjs();

    public get dateTimeConvertedToDisplayTimeZone(): dayjs.Dayjs {
        const asDayjs = dayjs(this.dateTime);

        return asDayjs.tz(this.displayInTimeZone || this.browserTimeZone);
    }

    public get browserTimeZone(): string {
        return guessLocalTimezone();
    }

    public get formattedConvertedDateTime(): string {
        return this.dateFormatPipe.transform(
            this.dateTimeConvertedToDisplayTimeZone,
            this.formatToDayjsFormatMap[this.format],
        );
    }

    private get formattedRawDateTime(): string {
        return this.dateFormatPipe.transform(this.dateTime, this.formatToDayjsFormatMap[this.format]);
    }

    public get showTimeZoneDifference(): boolean {
        return !!(
            !this.hideTimeZoneIndicator &&
            this.displayInTimeZone &&
            this.format !== 'relative' &&
            this.format !== 'calendar' &&
            this.formattedRawDateTime !== this.formattedConvertedDateTime
        );
    }

    public get timeZoneDifferenceTooltipDescription(): string {
        return `Displayed in ${this.displayInTimeZone} ${this.dateFormatPipe.transform(
            this.dateTimeConvertedToDisplayTimeZone,
            'z',
        )} time. In your local time zone (${this.browserTimeZone} ${this.dateFormatPipe.transform(
            this.now,
            'z',
        )}), this is ${
            this.format === 'calendar'
                ? this.calendarTimeFormatPipe.transform(this.dateTime)
                : this.formattedRawDateTime
        }`;
    }

    constructor(private dateFormatPipe: DateFormatPipe, private calendarTimeFormatPipe: CalendarTimeFormatPipe) {}
}
