import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Suppliers } from '../../common/models/suppliers.model';
import { AppState } from '../apps-state.model';

import { InternalSuppliersState } from './internal-suppliers.reducer';

const selectInternalSuppliersState = createFeatureSelector<AppState, InternalSuppliersState>('internalSuppliers');

const selectUiState = createSelector(selectInternalSuppliersState, s => s.ui);
const selectDataState = createSelector(selectInternalSuppliersState, s => s.data);

export const selectIsLoadingAllSuppliers = createSelector(selectUiState, s => s.isLoadingAllSuppliers);
export const selectIsLoadingPropertySuppliers = createSelector(selectUiState, s => s.isLoadingPropertySuppliers);
export const selectIsLoadingAgentSuppliers = createSelector(selectUiState, s => s.isLoadingAgentSuppliers);
export const selectIsUpdatingPropertySuppliers = createSelector(selectUiState, s => s.isUpdatingPropertySuppliers);
export const selectIsUpdatingAgentSuppliers = createSelector(selectUiState, s => s.isUpdatingAgentSuppliers);
export const selectUpdatePropertySuppliersSuccess = createSelector(
    selectUiState,
    s => s.updatePropertySuppliersSuccess,
);
export const selectUpdateAgentSuppliersSuccess = createSelector(selectUiState, s => s.updateAgentSuppliersSuccess);

export const selectAllSuppliers = createSelector(selectDataState, s => s.allSuppliers);

// Use generics as selectors with props currently do not infer the projector function types:
// https://github.com/ngrx/platform/issues/2205
export const selectPropertySuppliers = createSelector<
    AppState,
    { propertyId: string },
    InternalSuppliersState['data'],
    Suppliers.EntitySuppliers | undefined
>(selectDataState, (s, { propertyId }) => s.propertySuppliers && s.propertySuppliers[propertyId]);
export const selectAgentSuppliers = createSelector<
    AppState,
    { agentId: string },
    InternalSuppliersState['data'],
    Suppliers.EntitySuppliers | undefined
>(selectDataState, (s, { agentId }) => s.agentSuppliers && s.agentSuppliers[agentId]);
export const selectPropertySupplierByType = createSelector<
    AppState,
    { propertyId: string; type: keyof Suppliers.EntitySuppliers },
    Suppliers.EntitySuppliers | undefined,
    Suppliers.Supplier | undefined
>(selectPropertySuppliers, (s, { type }) => s && s[type]);
