<div id="conversion-questionnaire" class="u-text-body--rg">
    <div *ngIf="isLoading" class="u-text-align--center u-padding-vertical--2xl">
        <up-spinner [size]="'large'"></up-spinner>
    </div>
    <up-conversion-step *ngIf="!isLoading" [step]="step + answeredQuestionCount" [heading]="currentQuestion.question">
        <ng-container helpText>
            Help us get you the best service by telling us a bit more about you and your property.
        </ng-container>
        <ng-container body>
            <up-radio-group
                class="u-display--block u-margin-bottom--xl"
                [formControl]="optionInput"
                [name]="'question'"
            >
                <div class="answers">
                    <div *ngFor="let option of currentQuestion.options" class="answer">
                        <up-radio [style]="'boxed-borderless'" [value]="option">{{ option }}</up-radio>
                    </div>
                </div>
            </up-radio-group>
            <up-alert
                class="u-margin-bottom--md"
                [type]="'negative'"
                [size]="'small'"
                *ngIf="hasAttemptedSubmittingAnswer && optionInput.invalid"
                >Please select an answer</up-alert
            >
            <up-conversion-step-cta-wrapper>
                <up-button
                    [ncTestId]="'conversion-questionnaire-submit-button'"
                    [style]="'primary'"
                    [loading]="isSubmittingAnswer"
                    [arrowIcon]="true"
                    (onClick)="submitAnswer()"
                >
                    Next
                </up-button>
            </up-conversion-step-cta-wrapper>
        </ng-container>
    </up-conversion-step>
</div>
