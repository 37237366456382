import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';

import { IconModule } from '../../common/components/icon/icon.module';
import { ButtonModule } from '../button/button.module';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { ThemeModule } from '../theme/theme.module';

import { ModalComponent } from './components/modal/modal.component';
import { ModalContentComponent } from './components/modal-content/modal-content.component';
import { ModalControlsComponent } from './components/modal-controls/modal-controls.component';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { ModalDialogStepperComponent } from './components/modal-dialog-stepper/modal-dialog-stepper.component';
import { ModalDialogV2Component } from './components/modal-dialog-v2/modal-dialog-v2.component';
import { ModalFooterComponent } from './components/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { ModalHeadingComponent } from './components/modal-heading/modal-heading.component';
import { ModalDialogStepperNextButtonDirective } from './directives/modal-dialog-stepper-next-button/modal-dialog-stepper-next-button.directive';
import { ModalDialogStepperStepDirective } from './directives/modal-dialog-stepper-step/modal-dialog-stepper-step.directive';
import { ModalHostDirective } from './directives/modal-host/modal-host.directive';

@NgModule({
    imports: [IconModule, CommonModule, MatStepperModule, ProgressBarModule, ButtonModule, ThemeModule, SpinnerModule],
    declarations: [
        ModalDialogComponent,
        ModalComponent,
        ModalHostDirective,
        ModalDialogV2Component,
        ModalDialogStepperComponent,
        ModalDialogStepperStepDirective,
        ModalDialogStepperNextButtonDirective,
        ModalControlsComponent,
        ModalContentComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalHeadingComponent,
    ],
    exports: [
        ModalDialogComponent,
        ModalComponent,
        ModalDialogV2Component,
        ModalDialogStepperComponent,
        ModalDialogStepperStepDirective,
        ModalDialogStepperNextButtonDirective,
        ModalControlsComponent,
        ModalContentComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalHeadingComponent,
    ],
})
export class ModalModule {}
