import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { propertyReferralApi } from '../../../common/models/property-referral-api.model';

const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

const BASE_URL = '/api/v2/property';

@Injectable()
export class PropertyReferralResource {
    constructor(private httpClient: HttpClient) {}

    public getReferral(propertyId: string) {
        return this.httpClient.get<propertyReferralApi.referral.get.Response>(
            `${BASE_URL}/${propertyId}/referral/`,
            HEADERS_CONFIG,
        );
    }

    public setReferral(propertyId: string, referral: propertyReferralApi.referral.set.Request) {
        return this.httpClient.post<propertyReferralApi.referral.set.Response>(
            `${BASE_URL}/${propertyId}/referral/`,
            referral,
            HEADERS_CONFIG,
        );
    }
}
