import { DOCUMENT, isPlatformServer } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    Renderer2,
    ViewChild,
} from '@angular/core';
import pickBy from 'lodash-es/pickBy';
import throttle from 'lodash-es/throttle';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { UP_COLOR_CONSTANTS, UpColorConstants } from '../../../../common/constants/colors.constants';
import { nav } from '../../../../common/models/nav.model';
import { NavService } from '../../../../common/services/nav.service';
import { isItemVisible } from '../../../../common/utilities/menu-items.utilities';
import { ThemeName, ThemeService } from '../../../../modules/core/services/theme.service';

@Component({
    selector: 'up-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() public config: nav.BarConfig;
    @Input() public authData?: any;
    @ViewChild('paneToggle', { static: false }) public paneToggle: ElementRef;
    @ViewChild('navBarBg', { static: false }) private navBarBg: ElementRef;
    public isScrolled: boolean;
    public isItemVisible: Function = isItemVisible;
    public overrideCta: nav.BarCta;
    public BackgroundWidth = nav.BackgroundWidth;
    // True by default to ensure it always remains hidden on pre-render
    public isPrerendering = true;
    public themeName: ThemeName;
    private destroy$ = new Subject<void>();
    private throttledOnWindowScroll: Function;
    private throttledOnWindowResize: Function;
    private scrollThreshold = 10;
    private defaultStyle: nav.BarStyle = {
        noTransparentShadow: false,
        noPane: false,
        sticky: true,
        alwaysShowHamburger: false,
        alwaysShowShadow: false,
        backgroundWidth: nav.BackgroundWidth.Full,
        backgroundColor: this.Colors.white,
        invert: false,
    };

    constructor(
        private navService: NavService,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(UP_COLOR_CONSTANTS) private Colors: UpColorConstants,
        private themeService: ThemeService,
    ) {
        this.throttledOnWindowScroll = throttle(() => this.calcIsScrolled(), 200);
        this.throttledOnWindowResize = throttle(() => this.setPaneVisibilityOnResize(), 200);
    }

    public ngOnInit(): void {
        this.themeName = this.themeService.themeName;
        this.isPrerendering = isPlatformServer(this.platformId);
        this.calcIsScrolled();
        this.setupOverrideCta();
    }

    public ngAfterViewInit(): void {
        this.setStyle();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    public showPane(): void {
        this.navService.setPaneVisibility(true);
    }

    @HostListener('window:resize')
    public onWindowResize() {
        this.throttledOnWindowResize();
    }

    @HostListener('window:scroll')
    public onWindowScroll() {
        this.throttledOnWindowScroll();
    }

    private setupOverrideCta(): void {
        this.navService._overrideCta$.pipe(takeUntil(this.destroy$)).subscribe(config => (this.overrideCta = config));
    }

    private setStyle(): void {
        // Strip out any undefined values from config
        const styleFromConfig = pickBy(this.config.style || {}, x => typeof x !== 'undefined');
        const style = {
            ...this.defaultStyle,
            ...styleFromConfig,
        };
        this.navService.setStickyState(style.sticky);
        this.renderer.setStyle(this.navBarBg.nativeElement, 'background-color', style.backgroundColor);
    }

    private calcIsScrolled(): void {
        if (isPlatformServer(this.platformId)) return;
        this.navService.isSticky$.pipe(first()).subscribe(isSticky => {
            if (!isSticky) return;
            // Handles normal scrolling behaviour calculation
            const normalScroll =
                this.document.body.scrollTop +
                    ((this.document.documentElement && this.document.documentElement.scrollTop) || 0) >
                this.scrollThreshold;
            // Handles faux scroll positioning used by angular CDK overlay block scroll strategy
            const fauxScroll = parseInt(this.document.documentElement.style.top) * -1 > this.scrollThreshold;
            this.isScrolled = normalScroll || fauxScroll;
        });
    }

    private setPaneVisibilityOnResize(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        if (this.paneToggle && !this.paneToggle.nativeElement.offsetParent) {
            this.navService.setPaneVisibility(false);
        }
    }
}
