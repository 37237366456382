<up-collapser-panel [disabled]="isDisabled" class="panel" (stateChange)="onStateChange($event)">
    <div header class="header">
        <h3
            class="header-content"
            [class.u-text-heading--sm]="!isAppearanceGreyHeader"
            [class.u-text-color--brand-primary-light]="!isAppearanceGreyHeader"
        >
            <ng-content select="[headerContent], [slot='header']"></ng-content>
        </h3>
        <div *ngIf="!isDisabled" class="header-icon">
            <nc-hidden-content-indicator
                [open]="state !== 'collapsed'"
                *ngIf="isAppearanceGreyHeader; else otherAppearance"
            ></nc-hidden-content-indicator>
            <ng-template #otherAppearance>
                <nc-icon
                    class="u-text-color--brand-secondary"
                    [name]="state === 'collapsed' ? 'plusBoldLarge' : 'minus'"
                    [size]="'small'"
                    [color]="'currentColor'"
                >
                </nc-icon>
            </ng-template>
        </div>
    </div>
    <div content class="u-text-body--sm"> <ng-content select="[bodyContent], [slot='body']"></ng-content> </div>
</up-collapser-panel>
