import { FirebaseRemoteConfigFeatureFlags } from '../../modules/core/services/firebase.service';

import { AssociatedOrganisationDto } from './dto/organisation/associated-organisation.dto';
import { CountryDto } from './dto/organisation/country.dto';

export namespace environment {
    export enum OrganisationName {
        Upside = 'upside',
        UpsideDevelopment = 'upsidedevelopment',
        UpsideMobileDevelopment = 'upsidemobiledevelopment',
        UpsideUAT = 'upsideuat',
        UpsideUATDemo = 'upsideuatdemo',
        LocalDev = 'localdev',
        LJHooker = 'ljhooker',
        LJHookerTesting = 'ljhookertesting',
        NurtureCloud = 'nurturecloud',
        RayWhite = 'raywhite',
        RayWhiteNZ = 'raywhitenz',
        RayWhiteTesting = 'raywhitetesting',
        RayWhiteDevelopment = 'raywhitedevelopment',
        RayWhiteDevelopmentNZ = 'raywhitedevelopmentnz',
        RayWhiteTestingNZ = 'raywhitetestingnz',
        RayWhiteDemo = 'raywhitedemo',
        RayWhiteNZDemo = 'raywhitenzdemo',
    }

    export enum GroupedOrganisationName {
        Upside = OrganisationName.Upside,
        RayWhite = OrganisationName.RayWhite,
        LJHooker = OrganisationName.LJHooker,
        NurtureCloud = OrganisationName.NurtureCloud,
    }

    export interface UrlConfiguration {
        homePage: string;
        termsAndConditionsPage: string;
        privacyPolicyPage: string;
    }

    /** TODO: Should be renamed to signify that these are from the API **/
    export interface FeatureConfiguration {
        taskListEnabled: boolean;
        leadPoolEnabled: boolean;
        buyerDatabaseEnabled: boolean;
        contactExportEnabled: boolean;
        feedbackEnabled: boolean;
        automatedWorkflowCommunicationEnabled: boolean;
        recentNearbySalesEnabled: boolean;
        suppliersEnabled: boolean;
        helpEnabled: boolean;
        appointmentsEnabled: boolean;
        remindersEnabled: boolean;
        appraisalsEnabled: boolean;
        appraisalReportsEnabled: boolean;
        pipelineEnabled: boolean;
        ownersCorporationEnabled: boolean;
        propertyFeaturesEnabled: boolean;
        authorityEnabled: boolean;
        paymentsEnabled: boolean;
        photographyEnabled: boolean;
        listingCopyEnabled: boolean;
        pricingPageEnabled: boolean;
        conveyancingEnabled: boolean;
        portalsPageEnabled: boolean;
        offersEnabled: boolean;
        listingPerformanceEnabled: boolean;
        opensEnabled: boolean;
        auctionsEnabled: boolean;
        agentManagementEnabled: boolean;
        propertySupportAssignmentEnabled: boolean;
        phaseEditingEnabled: boolean;
        callbacksEnabled: boolean;
        upsideMarketingPagesEnabled: boolean;
        wootricSurveyEnabled: boolean;
        zendeskChatEnabled: boolean;
        vendorWebsiteEnabled: boolean;
        weeklySuburbNotificationsEnabled: boolean;
        reaListingUploadEnabled: boolean;
        domainListingUploadEnabled: boolean;
        stripePaymentEnabled: boolean;
        contentfulEnabled: boolean;
        tapfiliateEnabled: boolean;
        contactListEnabled: boolean;
        organisationStructureEditingEnabled: boolean;
        agentLeaveEnabled: boolean;
        agentResendReportEnabled: boolean;
        eBookEnabled: boolean;
        vendorAppraisalRequestsEnabled: boolean;
        addRequestAppraisalButtonEnabled: boolean;
        'propertyReportConfiguration.showAgent': boolean;
        'propertyReportConfiguration.showAgency': boolean;
        'propertyReportConfiguration.preferAgent': boolean;
        'propertyReportConfiguration.showComparables': boolean;
        'propertyReportConfiguration.showMarketPerformanceAuctionClearance': boolean;
        'vendorPortalConfiguration.myPropertiesListEnabled': boolean;
        'vendorPortalConfiguration.campaignProgressEnabled': boolean;
        'vendorPortalConfiguration.campaignPageEnabled': boolean;
        'vendorPortalConfiguration.appraisalPageEnabled': boolean;
        'vendorPortalConfiguration.settlementPageEnabled': boolean;
        taskNotificationsEnabled: boolean;
        newListingAlertEnabled: boolean;
        justSoldNotificationsEnabled: boolean;
        salesManagerSearchWithinAgency: boolean;
        delegatingCallListEnabled: boolean;
        manualMoveToAppraisalEnabled: boolean;
        sendCustomPropertyReportButtonEnabled: boolean;
        postAppraisalNotificationEnabled: boolean;
        vaultNotesEnabled: boolean;
        smartCallListEnabled: boolean;
        contactMergeEnabled: boolean;
        inProductReportingEnabled: boolean;
        ofiEditEnabled: boolean;
        domainLeadMinerEnabled: boolean;
        contactPhoneNumberVerificationEnabled: boolean;
        enableVaultSync: boolean;
        addStaffEnabled: boolean;
        addStaffRemindersEnabled: boolean;
        bulkSmsButtonEnabled: boolean;
        conciergeEnabled: boolean;
        canSwitchOrgs: boolean;
        conciergeAutoDelegationEnabled: boolean;
        canMovePropertyFromCampaignToWithdrawnEnabled: boolean;
        allowDevelopmentApplications: boolean;
        manualCampaignReportsEnabled: boolean;
        propertyPhotographyEditingEnabled: boolean;
        listingCopywritingBriefEnabled: boolean;
        withdrawnWorkflowEnabled: boolean;
        sendingSmsViaNurtureCloudEnabled: boolean;
    }

    interface ComputedFeatureConfiguration {
        canSendSms: boolean;
        buyerModuleEnabled: boolean;
        isBusinessSmsFeatureEnabled: boolean;
        isActivePipeBulkEmailEnabled: boolean;
        isOpenBuyerDatabaseEnabled: boolean;
    }

    // TODO: Once FeatureConfiguration above is renamed, this should be renamed to remove "V2"
    export type FeatureConfigurationV2 = FeatureConfiguration &
        FirebaseRemoteConfigFeatureFlags &
        ComputedFeatureConfiguration;

    export interface Organisation {
        displayName: string;
        googleOAuthClientId: string;
        googleTagManagerId?: string;
        gsuiteDomain: string;
        host: string;
        name: OrganisationName;
        featureConfiguration: FeatureConfiguration;
        urlConfiguration: UrlConfiguration;
        reportingUrl?: string;
        agentAssignmentStrategy: 'ByServiceSuburb' | 'None';
        androidAppUrl?: string;
        iOSAppUrl?: string;
        associatedOrganisations?: AssociatedOrganisationDto[];
        country: CountryDto;
        activePipeConfiguration?: {
            oauthUrl: string;
        };
        cloudflareImagesRootUrl?: string;
    }

    export interface config {
        version: string;
        organisation: Organisation;
    }
}
