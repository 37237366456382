import { ModuleWithProviders, NgModule } from '@angular/core';

import { FacebookPixelService } from './services/facebook-pixel/facebook-pixel.service';

@NgModule({ providers: [FacebookPixelService] })
export class FacebookPixelModule {
    public static forRoot(): ModuleWithProviders<FacebookPixelModule> {
        return {
            ngModule: FacebookPixelModule,
            providers: [FacebookPixelService],
        };
    }
}
