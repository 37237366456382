import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../../common/components/icon/icon.module';
import { MediaObjectModule } from '../media-object/media-object.module';
import { SpinnerModule } from '../spinner/spinner.module';

import { MenuComponent } from './components/menu/menu.component';
import { MenuContainerComponent } from './components/menu-container/menu-container.component';
import { MenuListComponent } from './components/menu-list/menu-list.component';
import { MenuListItemContentComponent } from './components/menu-list-item-content/menu-list-item-content.component';
import { MenuTriggerDirective } from './directives/menu-trigger/menu-trigger.directive';

@NgModule({
    imports: [CommonModule, A11yModule, SpinnerModule, PortalModule, MediaObjectModule, IconModule],
    declarations: [
        MenuComponent,
        MenuTriggerDirective,
        MenuContainerComponent,
        MenuListComponent,
        MenuListItemContentComponent,
    ],
    exports: [MenuComponent, MenuTriggerDirective, MenuListComponent, MenuListItemContentComponent],
})
export class MenuModule {}
