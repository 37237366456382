import { Component, HostBinding, Input, OnInit } from '@angular/core';

type BackgroundColor = 'neutral' | 'brand-secondary' | 'brand-primary' | 'off-white';

@Component({
    selector: 'up-outer-container',
    templateUrl: './outer-container.component.html',
    styleUrls: ['./outer-container.component.scss'],
})
export class OuterContainerComponent implements OnInit {
    @Input() public background: BackgroundColor;
    @HostBinding('class.neutral-background') public neutralBackground: boolean;
    @HostBinding('class.brand-primary-background') public brandPrimaryBackground: boolean;
    @HostBinding('class.brand-secondary-background') public brandSecondaryBackground: boolean;
    @HostBinding('class.off-white-background')
    public get offWhiteBackground(): boolean {
        return this.background === 'off-white';
    }

    public ngOnInit() {
        this.neutralBackground = this.background === 'neutral';
        this.brandPrimaryBackground = this.background === 'brand-primary';
        this.brandSecondaryBackground = this.background === 'brand-secondary';
    }
}
