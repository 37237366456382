import { Pipe, PipeTransform } from '@angular/core';

/**
 * @Deprecated - use Angular's built-in keyvalue pipe instead:
 * https://angular.io/api/common/KeyValuePipe
 *
 * This pipe returns an array of an object's keys making it possible to use
 * ngFor for any objects.
 */
@Pipe({ name: 'keys', pure: false })
export class ObjectKeysPipe implements PipeTransform {
    public transform(value: object): string[] {
        return Object.keys(value) || [];
    }
}
