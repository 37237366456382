<a class="inner" *ngIf="href" target="_blank" [href]="href">
    <ng-container *ngTemplateOutlet="ratingTemplate"></ng-container>
</a>
<div class="inner" *ngIf="!href"> <ng-container *ngTemplateOutlet="ratingTemplate"></ng-container> </div>
<ng-template #ratingTemplate>
    <up-ratemyagent-rating-pill
        *ngIf="type === 'pill'"
        class="u-margin-right--sm"
        [size]="size === 'small' ? 'regular' : 'large'"
        [rating]="rating"
    >
    </up-ratemyagent-rating-pill>
    <up-rating class="u-margin-right--sm" *ngIf="type === 'stars'" [value]="rating" color="ratemyagent"></up-rating>
    <img
        alt="Rate My Agent logo"
        [src]="'/static/images/logo/logo_RateMyAgent.svg' | upCdnUrl"
        [width]="logoWidth"
        [height]="logoHeight"
    />
</ng-template>
