import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { Observable } from 'rxjs';
import { filter, first, map, take } from 'rxjs/operators';

import { MediaTagName } from '../../../../common/models/domain/media/media-tag-name.model';
import { ImageResizePipe } from '../../../../common/pipes/image-resize/image-resize.pipe';
import { replay } from '../../../../operators/replay/replay.operator';
import { GalleryService } from '../../../core/services/gallery.service';
import { NotificationService } from '../../../core/services/notification.service';
import { TitleService } from '../../../core/services/title.service';
import { BrochureActivityTrackingUseCase } from '../../use-cases/brochure-activity-tracking.use-case';
import { GetBrochureUseCase, Result as BrochureResult } from '../../use-cases/get-brochure.use-case';

@Component({
    selector: 'nc-brochure',
    templateUrl: 'brochure.component.html',
    styleUrls: ['brochure.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ImageResizePipe],
})
export class BrochureComponent implements OnInit {
    public brochure$: Observable<BrochureResult>;
    public heroImage$: Observable<string>;

    public get hasFloorPlan$(): Observable<boolean> {
        return this.brochure$.pipe(
            map(brochure => !!brochure.floorPlan),
            first(),
        );
    }

    private get token(): string {
        return this.uiRouterGlobals.params['token'];
    }

    private get propertyId(): string {
        return this.uiRouterGlobals.params['propertyId'];
    }

    constructor(
        private getBrochureUseCase: GetBrochureUseCase,
        private uiRouterGlobals: UIRouterGlobals,
        private galleryService: GalleryService,
        private imageResizePipe: ImageResizePipe,
        private notificationService: NotificationService,
        private titleService: TitleService,
        private brochureActivityTrackingUseCase: BrochureActivityTrackingUseCase,
    ) {}

    public ngOnInit(): void {
        if (this.token) {
            this.brochureActivityTrackingUseCase
                .execute({
                    token: this.token,
                    type: 'BrochureView',
                })
                .subscribe();
        }

        this.brochure$ = this.getBrochureUseCase
            .execute(this.token ? { token: this.token } : { propertyId: this.propertyId })
            .pipe(
                this.notificationService.withNotification({
                    errorMessage: 'Error loading brochure, please try again later.',
                }),
                replay({ resetOnRefCountZero: false }),
            );
        this.heroImage$ = this.brochure$.pipe(
            map(({ mainImage, images }) => mainImage?.servingUrl || images?.[0]?.servingUrl),
        );

        this.brochure$
            .pipe(
                map(brochure => brochure.address.formattedAddress),
                filter(address => !!address),
                take(1),
            )
            .subscribe(address => {
                this.titleService.setTitleWithTrailingOrgName(`Brochure for ${address}`);
            });
    }

    public openGallery(scrollToFloorPlan = false): void {
        this.brochure$.pipe(first()).subscribe(listing => {
            const allImages = [...(listing.images ?? []), listing.floorPlan].filter(image => !!image);
            const floorPlanIndex = allImages.findIndex(image => image.tags?.includes('Floorplan'));
            const index = scrollToFloorPlan && floorPlanIndex >= 0 ? floorPlanIndex : 0;

            this.galleryService.show(
                allImages.map(image => ({
                    thumb: this.imageResizePipe.transform(image.servingUrl, 200, 200),
                    src: this.imageResizePipe.transform(image.servingUrl, 1240, 1240),
                })),
                { index },
            );
        });
    }

    public onDownload([tag, mediaId]: [MediaTagName, string]) {
        if (!this.token) return;

        this.brochureActivityTrackingUseCase
            .execute({
                token: this.token,
                type: tag === 'ContractOfSale' ? 'ContractView' : 'DocumentView',
                mediaId,
            })
            .subscribe();
    }
}
