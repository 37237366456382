import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Moment } from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, first, map, publishReplay, refCount, switchMap } from 'rxjs/operators';

import { AddressT } from '../../../../common/models/address.model';
import { agent } from '../../../../common/models/agent.model';
import { conversion } from '../../../../common/models/conversion.model';
import { agentProfiles } from '../../../../common/models/domain/agent/agent-profiles.model';
import { UserService } from '../../../../common/services/user.service';
import { AgentResource } from '../../../../modules/core/resources/agent.resource';
import { ConversionService } from '../../../../modules/core/services/conversion/conversion.service';
import { ConversionFacade } from '../../../../store/conversion/conversion.facade';

@Component({
    selector: 'up-appraisal-requested',
    templateUrl: './appraisal-requested.component.html',
    styleUrls: ['./appraisal-requested.component.scss'],
})
export class AppraisalRequestedComponent implements OnInit, OnDestroy {
    @Input() public propertyId: string;
    @Input() public funnelName: conversion.Funnel;
    @Input() public address: AddressT;
    public authData: any;
    public bookedTime$: Observable<Moment>;
    public agent$: Observable<agent.CalendarAgent>;
    public agentDisplayName$: Observable<string>;
    public agentProfile$: Observable<agentProfiles.Profile>;
    public errorLoadingAgentProfile: boolean;
    private address$: Observable<AddressT>;
    private destroy$ = new Subject<void>();

    constructor(
        private userService: UserService,
        private conversionFacade: ConversionFacade,
        private agentResource: AgentResource,
        private conversionService: ConversionService,
    ) {}

    public ngOnInit(): void {
        this.address$ = this.conversionFacade.conversionState$.pipe(
            first(),
            map(({ address }) => address),
        );
        this.address$.subscribe(address => (this.address = this.address || address));

        if (!this.address) {
            this.conversionService.goToFunnelEntry(this.funnelName);
            return;
        }
        this.setupData();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private setupData(): void {
        const appraisalState$ = this.conversionFacade.appraisalState$.pipe(first());

        this.agent$ = appraisalState$.pipe(map(({ agent }) => agent));
        this.bookedTime$ = appraisalState$.pipe(map(s => s.bookedTime));
        this.agentDisplayName$ = this.agent$.pipe(map(agent => (agent ? agent.firstName : 'Your Upside agent')));
        this.userService.userAuthDetailsUpdated$.subscribe(authData => (this.authData = authData));

        this.agentProfile$ = this.agent$.pipe(
            filter(agent => !!agent),
            switchMap(agent => this.agentResource.profile(agent.slug)),
            publishReplay(1),
            refCount(),
            catchError(() => {
                this.errorLoadingAgentProfile = true;
                return of(undefined);
            }),
        );
    }
}
