import { createSelector } from '@ngrx/store';

import { PropertyAttributesMapper } from '../../common/mappers/property-attributes/property-attributes.mapper';
import { AppraisalQuestionnaire } from '../../common/models/domain/property/appraisal-questionnaire.model';
import { media } from '../../common/models/media.model';
import { AppState } from '../apps-state.model';

import { InternalPropertyState } from './internal-property.reducer';

const internalPropertyState = (state: AppState) => state.internalProperty;
export const propertySelector = createSelector(internalPropertyState, (state: InternalPropertyState) => state.property);
export const todoRemindersSelector = createSelector(
    internalPropertyState,
    (state: InternalPropertyState) => state.todo.reminders,
);
export const todoMeetingsSelector = createSelector(
    internalPropertyState,
    (state: InternalPropertyState) => state.todo.meetings,
);
export const todoTasksSelector = createSelector(
    internalPropertyState,
    (state: InternalPropertyState) => state.todo.tasks,
);
export const selectLoadingProperty = createSelector(internalPropertyState, ({ loadingProperty }) => loadingProperty);
export const loadingTodoSelector = createSelector(internalPropertyState, ({ loadingTodo }) => loadingTodo);
export const selectIsUpdatingHideFullAddress = createSelector(
    internalPropertyState,
    ({ isUpdatingHideFullAddress }) => isUpdatingHideFullAddress,
);
export const selectIsUpdatingHideUnderOfferStatus = createSelector(
    internalPropertyState,
    ({ isUpdatingHideUnderOfferStatus }) => isUpdatingHideUnderOfferStatus,
);
export const selectIsUpdatingHideSoldPrice = createSelector(
    internalPropertyState,
    ({ isUpdatingHideSoldPrice }) => isUpdatingHideSoldPrice,
);
export const selectErrorLoadingProperty = createSelector(
    internalPropertyState,
    ({ loadingPropertyError }) => loadingPropertyError,
);
export const selectIsUpdatingJustSoldNotificationEnabled = createSelector(
    internalPropertyState,
    ({ isUpdatingJustSoldNotificationEnabled }) => isUpdatingJustSoldNotificationEnabled,
);
export const selectIsUpdatingJustListedSmsNotificationEnabled = createSelector(
    internalPropertyState,
    ({ isUpdatingJustListedSmsNotificationEnabled }) => isUpdatingJustListedSmsNotificationEnabled,
);
export const selectIsUpdatingJustListedEmailNotificationEnabled = createSelector(
    internalPropertyState,
    ({ isUpdatingJustListedEmailNotificationEnabled }) => isUpdatingJustListedEmailNotificationEnabled,
);
export const selectIsUpdatingVendorPortalEnabled = createSelector(
    internalPropertyState,
    ({ isUpdatingVendorPortalEnabled }) => isUpdatingVendorPortalEnabled,
);
export const selectRemoveStatus = createSelector(internalPropertyState, ({ removeStatus }) => removeStatus);
export const selectUnarchiveStatus = createSelector(internalPropertyState, ({ unarchiveStatus }) => unarchiveStatus);

export const selectAppraisalQuestionnaire = createSelector(propertySelector, property => {
    if (!property) return undefined;

    const { appraisalNote, agentPriceOpinionMax, agentPriceOpinionMin, agentPriceOpinion, ...rest } = property;
    const { externalLinks, ...restPropertyAttributes } = PropertyAttributesMapper.fromDto(rest);
    const appraisalQuestionnaire: AppraisalQuestionnaire = {
        appraisalNote,
        priceOpinion: {
            high: agentPriceOpinionMax,
            low: agentPriceOpinionMin,
            value: agentPriceOpinion,
        },
        listingExpectations: {
            expectedSellingDate: property.expectedSellingDate,
            expectedSellingPrice: property.attributes?.intAttributes?.PRICE_EXPECTATION,
            likelihoodOfSelling: property.attributes?.stringAttributes?.LIKELIHOOD_OF_SELLING,
        },
        ...restPropertyAttributes,
    };

    return appraisalQuestionnaire;
});
export const selectAppraisalQuestionnaireStatus = createSelector(
    internalPropertyState,
    ({ appraisalQuestionnaireStatus }) => appraisalQuestionnaireStatus,
);
export const selectUnarchivedDocuments = createSelector(propertySelector, property => {
    if (!property) return [];

    return filterOutImageFiles(property.media);
});
export const selectArchivedDocuments = createSelector(propertySelector, property => {
    if (!property) return [];

    return filterOutImageFiles(property.archivedMedia);
});

function filterOutImageFiles(files: media.File[]): media.File[] {
    return files.filter(({ tags }) => !tags.includes('Images'));
}

export const selectAttributes = createSelector(propertySelector, property =>
    property ? PropertyAttributesMapper.fromDto(property) : undefined,
);
export const selectAttributesStatus = createSelector(internalPropertyState, ({ attributesStatus }) => attributesStatus);
export const selectIsLoadingAttributes = createSelector(
    selectAttributesStatus,
    selectLoadingProperty,
    (attributesStatus, isLoadingProperties) => attributesStatus === 'loading' || isLoadingProperties,
);

export const selectPropertyId = createSelector(propertySelector, p => p?.id);

export const selectIsContractOfSaleAlwaysVisible = createSelector(
    propertySelector,
    property => !!property?.contractOfSaleAlwaysVisible,
);
export const selectIsUpdatingIsContractOfSaleAlwaysVisible = createSelector(
    internalPropertyState,
    ({ isUpdatingIsContractOfSaleAlwaysVisible }) => isUpdatingIsContractOfSaleAlwaysVisible,
);

export const selectHasBrochureContractOfSale = createSelector(
    propertySelector,
    property =>
        !!property?.media?.find(mediaFile => mediaFile.tags?.includes('ContractOfSale') && mediaFile.isBrochure),
);
