import { Injectable } from '@angular/core';
import { oneLineTrim } from 'common-tags';

import { location } from '../../../common/models/location.model';

import { EnvironmentService } from './environment.service';

export interface StaticMapUrlConfig {
    center: location.LatLong;
    markers?: {
        // Raw hex value i.e. no #
        color: string;
        coordinates: location.LatLong;
    }[];
    zoom: number;
    size: {
        width: number;
        height: number;
    };
    scale?: 1 | 2;
}

@Injectable({
    providedIn: 'root',
})
export class GoogleMapService {
    constructor(private environmentService: EnvironmentService) {}

    public getStaticMapUrl(config: StaticMapUrlConfig): string {
        const googleMapsApiKey = this.environmentService.environmentFromGlobal('googleMapsApiKey');
        const markers = config.markers
            ?.map(
                ({ color, coordinates }) =>
                    // Strip out hash as Google needs the raw hex value
                    `&markers=color:0x${color.replace('#', '')}%7C${coordinates.lat},${coordinates.lng}`,
            )
            .join('');
        return oneLineTrim`https://maps.googleapis.com/maps/api/staticmap
            ?key=${googleMapsApiKey}
            &center=${config.center.lat},${config.center.lng}
            ${markers || ''}
            &zoom=${config.zoom}
            &format=png
            &maptype=roadmap
            &scale=${config.scale || 1}
            &style=element:geometry%7Ccolor:0xf5f5f5
            &style=element:labels.icon%7Cvisibility:off
            &style=element:labels.text.fill%7Ccolor:0x616161
            &style=element:labels.text.stroke%7Ccolor:0xf5f5f5
            &style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd
            &style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee
            &style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575
            &style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5
            &style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e
            &style=feature:road%7Celement:geometry%7Ccolor:0xffffff
            &style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575
            &style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada
            &style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161
            &style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e
            &style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5
            &style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee
            &style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9
            &style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e
            &size=${config.size.width}x${config.size.height}`;
    }
}
