import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';

import { MenuTriggerDirective } from '../../menu/directives/menu-trigger/menu-trigger.directive';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[up-nav-bar-button], a[up-nav-bar-button]',
    templateUrl: 'nav-bar-button.component.html',
    styleUrls: ['../styles/nav-bar-button-base.component.scss', 'nav-bar-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarButtonComponent {
    @Input() public hideIndicator: boolean;

    public get hasIndicator(): boolean {
        return !!(!this.hideIndicator && this.menuTriggerDirective);
    }

    constructor(@Optional() public menuTriggerDirective: MenuTriggerDirective) {}
}
