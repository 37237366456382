import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { modal } from '../../../../common/models/modal.model';

export interface InternalModalControls {
    close: (type: modal.CloseType, data?: any) => void;
}

interface ModalShowConfig<Data = any> {
    component: any;
    data?: Data;
    internalModalControls: InternalModalControls;
}

@Injectable({ providedIn: 'root' })
export class ModalService {
    public _modalShow$: Observable<ModalShowConfig>;
    public _modalClose$: Observable<modal.CloseEvent>;
    private _modalShow: Subject<ModalShowConfig>;
    private _modalClose: Subject<modal.CloseEvent>;
    private activeModalSubject: Subject<modal.CloseEvent>;

    constructor() {
        this._modalShow = new Subject<ModalShowConfig>();
        this._modalClose = new Subject<modal.CloseEvent>();
        this._modalShow$ = this._modalShow.asObservable();
        this._modalClose$ = this._modalClose.asObservable();
    }

    // TODO: these generics should be inferred according to the method parameters; the consumer shouldn't need to
    //  handle the types manually. The Menu component and service is a good example of how to do this.
    public show<InputData = any, OutputData = any>(
        component: any,
        data?: InputData,
    ): Observable<modal.CloseEvent<OutputData>> {
        const newModalSubject = new Subject<modal.CloseEvent>();
        const createModal = () => {
            this.activeModalSubject = newModalSubject;
            const internalModalControls = this.createModalControl(newModalSubject);
            this._modalShow.next({ component, data, internalModalControls });
        };
        // If there's no active modal or if the current active modal is completed
        if (!this.activeModalSubject || (this.activeModalSubject && this.activeModalSubject.isStopped)) {
            createModal();
        } else {
            // If there's an active modal, close it first by dismissing then open the new modal
            this.activeModalSubject.pipe(first()).subscribe(() => createModal());
            this.close(modal.CloseType.Dismiss);
        }
        return newModalSubject.asObservable();
    }

    public close(type: modal.CloseType = modal.CloseType.Dismiss, data?: any): void {
        this._modalClose.next({ type, data });
    }

    private createModalControl(newModalSubject: Subject<modal.CloseEvent>): InternalModalControls {
        return {
            close: (type: modal.CloseType = modal.CloseType.Dismiss, data?: any) => {
                newModalSubject.next({ type, data });
                newModalSubject.complete();
            },
        };
    }
}
