import { user } from '../models/user.model';
import { UserT } from '../services/user.service';

export const isStaff = function(auth: UserT): boolean {
    return auth && auth.authenticated && auth.roles.includes(user.Role.Staff);
};

export const isFinance = function(auth: UserT): boolean {
    return auth && auth.authenticated && auth.roles.includes(user.Role.Finance);
};

export const isAgent = function(auth: UserT): boolean {
    return auth && auth.authenticated && auth.roles.includes(user.Role.Agent);
};

export const isSalesManager = function(auth: UserT): boolean {
    return auth && auth.authenticated && auth.roles.includes(user.Role.SalesManager);
};

export const isSalesAdmin = function(auth: UserT): boolean {
    return auth && auth.authenticated && auth.roles.includes(user.Role.SalesAdmin);
};

export const isAdmin = function(auth: UserT): boolean {
    return auth && auth.authenticated && auth.roles.includes(user.Role.Admin);
};

export const isSalesAssociate = function(auth: UserT): boolean {
    return auth?.authenticated && auth.roles.includes(user.Role.SalesAssociate);
};

export const isConcierge = function(auth: UserT): boolean {
    return auth?.authenticated && auth.roles.includes(user.Role.Concierge);
};

export const isConciergeAdmin = function(auth: UserT): boolean {
    return auth?.authenticated && auth.roles.includes(user.Role.ConciergeAdmin);
};

export const isDemo = function(auth: UserT): boolean {
    return auth?.authenticated && auth.roles.includes(user.Role.Demo);
};

export const isOrgAdmin = function(auth: UserT): boolean {
    return auth?.authenticated && auth.roles.includes(user.Role.OrgAdmin);
};

export const isAgencyAdmin = function(auth: UserT): boolean {
    return auth?.authenticated && auth.roles.includes(user.Role.AgencyAdmin);
};

/** @Deprecated - use {@link hasSomeInternalRole} instead **/
export const isInternal = function(auth: UserT): boolean {
    return (
        isStaff(auth) ||
        isFinance(auth) ||
        isAgent(auth) ||
        isSalesAssociate(auth) ||
        isSalesManager(auth) ||
        isAdmin(auth) ||
        isSalesAdmin(auth) ||
        isConcierge(auth) ||
        isConciergeAdmin(auth) ||
        isOrgAdmin(auth) ||
        isAgencyAdmin(auth)
    );
};

export const isConciergeUser = function(auth: UserT): boolean {
    return isConcierge(auth) || isConciergeAdmin(auth);
};
