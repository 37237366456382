<nc-choose-your-own-adventure [callCtaTrackingName]="'getStarted'" [heading]="'How can we assist you?'">
    <up-grid slot="options" [align]="'center'" [gutters]="'small'">
        <up-grid-col [col]="12" [col@md]="3">
            <nc-media-action-panel
                [heading]="'Get an agent appraisal'"
                [body]="'Arrange a time for an agent to visit your property'"
                [iconName]="'calendarFill'"
            >
                <a
                    slot="action"
                    nc-button
                    [dataEvent]="{
                        name: 'getStarted',
                        subname: 'serviceBookAppraisal'
                    }"
                    class="u-display--block"
                    [style]="'secondary'"
                    [uiSref]="'conversion.appraisal'"
                >
                    Book Appraisal
                </a>
            </nc-media-action-panel>
        </up-grid-col>

        <up-grid-col [col]="12" [col@md]="3">
            <nc-media-action-panel
                [heading]="'Request a call back'"
                [body]="'Tell us when you\'d like to speak over the phone'"
                [iconName]="'phoneWavesFill'"
            >
                <a
                    slot="action"
                    nc-button
                    class="u-display--block"
                    [dataEvent]="{ name: 'getStarted', subname: 'serviceRequestCall' }"
                    [style]="'secondary'"
                    [uiSref]="'conversion.get-started.callback'"
                >
                    Book a call
                </a>
            </nc-media-action-panel>
        </up-grid-col>

        <up-grid-col [col]="12" [col@md]="3">
            <nc-media-action-panel
                [heading]="'Get an online estimate'"
                [body]="'Find out the online value of your property'"
                [iconName]="'documentDollarFill'"
            >
                <a
                    slot="action"
                    nc-button
                    [dataEvent]="{ name: 'getStarted', subname: 'servicePropertyReport' }"
                    class="u-display--block"
                    [style]="'secondary'"
                    [uiSref]="'conversion.property-report'"
                >
                    Get an estimate
                </a>
            </nc-media-action-panel>
        </up-grid-col>
    </up-grid>
</nc-choose-your-own-adventure>
