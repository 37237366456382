import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount, retry, tap } from 'rxjs/operators';
import urlJoin from 'url-join';

import { environmentApi } from '../../../common/models/environment-api.model';
import { environment } from '../../../common/models/environment.model';
import { getTimeZonesForCountry } from '../../../common/utilities/get-time-zones-for-country/get-time-zones-for-country.util';
import { getEnvironmentVariable } from '../../../utilities/get-environment-variable';
import { EnvironmentResource } from '../resources/environment.resource';

declare const COMMIT_HASH: string;

@Injectable()
export class EnvironmentService {
    public config: environment.config;
    public orgTimeZones$: Observable<string[]>;
    private readonly env$: Observable<environmentApi.env.get.Response>;

    constructor(private environmentResource: EnvironmentResource) {
        this.env$ = this.environmentResource.env().pipe(
            retry(1),
            tap(res => (this.config = res)),
            publishReplay(1),
            refCount(),
        );

        this.orgTimeZones$ = this.env$.pipe(
            map(({ organisation }) => getTimeZonesForCountry(organisation.country.isoAlpha2)),
        );
    }

    public get config$(): Observable<environmentApi.env.get.Response> {
        return this.env$;
    }

    public get currentOrgGrouping(): environment.GroupedOrganisationName {
        return this.orgNameAsGrouping(this.config.organisation.name);
    }

    public get currentOrgAlpha2(): string {
        return this.config.organisation.country.isoAlpha2;
    }

    public get currentOrgCurrencyCode(): string {
        return this.config.organisation.country.currencyCode;
    }

    // Todo: needs to be dynamic at some point, and probably not located in this service as it
    //  shouldn't be tied to the environment but rather to the user. Just keeping it here for
    //  the time being until we figure out what we want to do.
    public get icuLocaleBcp47(): string {
        return 'en-AU';
    }

    public isProduction(orgName: environment.OrganisationName): boolean {
        return ![
            environment.OrganisationName.UpsideDevelopment,
            environment.OrganisationName.UpsideMobileDevelopment,
            environment.OrganisationName.UpsideUAT,
            environment.OrganisationName.UpsideUATDemo,
            environment.OrganisationName.LocalDev,
            environment.OrganisationName.RayWhiteTesting,
            environment.OrganisationName.RayWhiteDevelopment,
            environment.OrganisationName.RayWhiteDevelopmentNZ,
            environment.OrganisationName.RayWhiteTestingNZ,
            environment.OrganisationName.RayWhiteDemo,
            environment.OrganisationName.RayWhiteNZDemo,
        ].includes(orgName);
    }

    public orgNameAsGrouping(orgName: environment.OrganisationName): environment.GroupedOrganisationName {
        switch (orgName) {
            case environment.OrganisationName.Upside:
            case environment.OrganisationName.UpsideDevelopment:
            case environment.OrganisationName.UpsideMobileDevelopment:
            case environment.OrganisationName.UpsideUAT:
            case environment.OrganisationName.UpsideUATDemo:
            case environment.OrganisationName.LocalDev: {
                return environment.GroupedOrganisationName.Upside;
            }

            case environment.OrganisationName.LJHooker:
            case environment.OrganisationName.LJHookerTesting: {
                return environment.GroupedOrganisationName.LJHooker;
            }

            case environment.OrganisationName.RayWhite:
            case environment.OrganisationName.RayWhiteNZ:
            case environment.OrganisationName.RayWhiteTesting:
            case environment.OrganisationName.RayWhiteDevelopment:
            case environment.OrganisationName.RayWhiteDevelopmentNZ:
            case environment.OrganisationName.RayWhiteTestingNZ:
            case environment.OrganisationName.RayWhiteDemo:
            case environment.OrganisationName.RayWhiteNZDemo: {
                return environment.GroupedOrganisationName.RayWhite;
            }

            default: {
                return environment.GroupedOrganisationName.NurtureCloud;
            }
        }
    }

    public environmentFromGlobal<T extends keyof NcEnv>(key: T) {
        return getEnvironmentVariable(key);
    }

    public getMyDesktopUrlFromId(myDesktopId: string, forContext: 'property' | 'contact' | 'contact-notes'): string {
        // Todo: currently this is a hard-coded link specific to RayWhite's MyDesktop platform. If other organisations
        //  require MyDesktop links then we will need to reconsider how we handle this.
        const sharedUrlPart = 'https://secure-raywhite.mydesktop.com.au/cgi-bin/clients/agents/version6';

        switch (forContext) {
            case 'property':
                return `${sharedUrlPart}/property/newpublish1.cgi?propertyid=${myDesktopId}`;
            case 'contact':
                return `${sharedUrlPart}/addressbook/newaddeditcontactform.cgi?contactid=${myDesktopId}`;
            case 'contact-notes':
                return `${sharedUrlPart}/addressbook/newaddnote.cgi?contactid=${myDesktopId}`;
        }
    }

    public getVaultPropertyUrlFromId(vaultPropertyId: string): string {
        return `https://login.vaultre.com.au/cgi-bin/clientvault/property/addproperty.cgi?propertyid=${vaultPropertyId}`;
    }

    public getVaultContactUrlFromId(vaultContactId: string): string {
        return `https://login.vaultre.com.au/cgi-bin/clientvault/client/addclient.cgi?contactid=${vaultContactId}`;
    }

    public getVaultContactNotesUrlFromId(vaultContactId: string): string {
        return `https://login.vaultre.com.au/cgi-bin/clientvault/client/notes.cgi?context=contact&contactid=${vaultContactId}`;
    }

    public isCurrentOrgGroup(orgGroupName: environment.GroupedOrganisationName): boolean {
        return this.currentOrgGrouping === orgGroupName;
    }

    public prefixCdnUrl(url: string): string {
        const cdnUrl = this.environmentFromGlobal('cdnUrl');
        return cdnUrl ? urlJoin(cdnUrl, COMMIT_HASH, url) : url;
    }
}
