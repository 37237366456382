import { Injectable } from '@angular/core';

import { Property } from '../models/property.model';

export interface MarketPerformanceSummary {
    latestQuarterMonth: number;
    percentageChange: number;
    medianSalePrice: number;
    numberSold: number;
    averageDaysOnMarket: number;
    auctionClearanceRate: number;
}

@Injectable()
export class MarketPerformanceService {
    public calculateMarketPerformanceSummary(performance: Property.report.Performance): MarketPerformanceSummary {
        if (!performance) return;
        const latestQStatsIndex = performance.seriesInfo.length - 1;
        const latestQStats = performance.seriesInfo[latestQStatsIndex];
        if (!latestQStats) return;
        const latestQStatsValues = latestQStats.values;
        const prevQStats = performance.seriesInfo[latestQStatsIndex - 1];
        let percentageChange;
        if (prevQStats) {
            const prevQStatsValues = prevQStats.values;
            const prevQMedianSoldPrice = parseInt(prevQStatsValues.medianSoldPrice);
            const percentage =
                ((parseInt(latestQStatsValues.medianSoldPrice) - prevQMedianSoldPrice) / prevQMedianSoldPrice) * 100;
            // round to 1 decimal place
            percentageChange = Math.round(percentage * 10) / 10;
        }

        return {
            percentageChange,
            latestQuarterMonth: latestQStats.month,
            medianSalePrice: parseInt(latestQStatsValues.medianSoldPrice),
            numberSold: parseInt(latestQStatsValues.numberSold),
            averageDaysOnMarket: parseInt(latestQStatsValues.daysOnMarket),
            auctionClearanceRate: Math.round(
                (parseInt(latestQStatsValues.auctionNumberSold) / parseInt(latestQStatsValues.auctionNumberAuctioned)) *
                    100,
            ),
        };
    }
}
