import { Component, Input } from '@angular/core';

export type Size = 'x-small' | 'small' | 'regular' | 'medium' | 'large';
type Appearance = 'color-background' | 'neutral-background';

@Component({
    selector: 'nc-avatar, up-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
    @Input() public upLazyLoad: boolean;
    @Input() public name?: string;
    @Input() public imgSrc?: string;
    @Input() public size: Size = 'regular';
    // Todo: consider how to remove the need to use these inputs on all responsive components,
    //  perhaps by using a directive or composition via Typescript mixins
    @Input('size@xs') public sizeAtXs: Size = 'regular';
    @Input('size@sm') public sizeAtSm: Size = 'regular';
    @Input() public border: boolean;
    @Input() public appearance: Appearance = 'color-background';

    public encodeIfRequired(src: string): string {
        return this.isUrlEncoded(src) ? src : encodeURI(src);
    }

    private isUrlEncoded(url: string): boolean {
        return url !== decodeURI(url);
    }
}
