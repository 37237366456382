import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import moment from 'moment';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AgentResource } from '../../modules/core/resources/agent.resource';
import { NotificationService } from '../../modules/core/services/notification.service';

import { LOAD_CALENDAR, LoadCalendar, LoadCalendarError, LoadCalendarSuccess } from './internal-user.actions';

@Injectable()
export class InternalUserEffects {
    constructor(
        private actions: Actions,
        private agentResource: AgentResource,
        private notificationService: NotificationService,
    ) {}

    public loadCalendar = createEffect(() =>
        this.actions
            .pipe(
                ofType<LoadCalendar>(LOAD_CALENDAR),
                map(action => action.payload),
                switchMap(payload => {
                    const startDate = payload.options
                        ? payload.options.from
                        : moment().subtract(3, 'month').utc().format();
                    const endDate = payload.options ? payload.options.to : moment().add(3, 'month').utc().format();

                    return this.agentResource.getCalendar(payload.agentId, startDate, endDate).pipe(
                        map(({ meetings, blockedTimes, leaves, timeZone }) => ({
                            meetings,
                            blockedTimes,
                            leaves,
                            timeZone,
                        })),
                    );
                }),
                map(calendar => new LoadCalendarSuccess(calendar)),
                this.notificationService.withNotification({ errorMessage: 'Error loading calendar' }),
            )
            .pipe(catchError(error => of(new LoadCalendarError(error)))),
    );
}
