import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';

@Pipe({
    name: 'ncDateFormat',
})
export class DateFormatPipe implements PipeTransform {
    public transform(value?: dayjs.ConfigType, format?: string): string | undefined {
        if (isNil(value)) return;
        // See: https://day.js.org/docs/en/display/format
        // We have also added the AdvancedFormat plugin, so you can also use the
        // formatting values from here: https://day.js.org/docs/en/plugin/advanced-format
        return dayjs(value).format(format);
    }
}
