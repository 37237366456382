import { NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';

import { ZendeskPlaceholderComponent } from './components/zendesk-placeholder/zendesk-placeholder.component';
import { ZendeskChatVisibilityService } from './services/zendesk-chat-visibility.service';

@NgModule({
    imports: [SharedModule],
    exports: [ZendeskPlaceholderComponent],
    declarations: [ZendeskPlaceholderComponent],
    providers: [ZendeskChatVisibilityService],
})
export class ZendeskModule {}
