import { createSelector } from '@ngrx/store';

import { AppState } from '../apps-state.model';

export const suburbState = createSelector(
    (state: AppState) => state.suburbs,
    suburbState => suburbState,
);

export const allSuburbsSelector = createSelector(suburbState, s => s.all);

export const isLoadingSuburbsSelector = createSelector(suburbState, s => s.loading);
