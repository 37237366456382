<up-outer-container>
    <up-container class="inner" [size]="'small'">
        <div class="content">
            <div class="u-text-align--center u-margin-top--xl" *ngIf="sendingInitialSms; else sendingInitialSmsElse">
                <up-spinner [size]="'large'"></up-spinner>
            </div>
            <ng-template #sendingInitialSmsElse>
                <h1 class="u-text-heading--lg u-margin-bottom--sm"> Verification code </h1>
                <ng-container *ngIf="!errorSendingInitialSms; else errorSendingSmsElse">
                    <div class="u-margin-bottom--lg">
                        A verification code has been sent to the mobile number you provided. Your property report will
                        be available after the phone number verification.
                    </div>
                    <div
                        class="phone-number-toggle u-margin-bottom--md"
                        *ngIf="!showUpdatePhoneNumberControls && updatedPhoneNumber"
                    >
                        <div class="u-margin-right--md">
                            <up-icon [name]="'phone'"></up-icon> {{ updatedPhoneNumber }}
                        </div>
                    </div>
                    <div class="update" *ngIf="showUpdatePhoneNumberControls; else submitControls">
                        <div class="update-header u-margin-bottom--sm">
                            <div> Update my number </div>
                            <button class="cancel-update-button" (click)="updatePhoneNumberVisibility(false)">
                                <up-icon aria-label="Cancel" [name]="'crossBold'" [size]="'small'"></up-icon>
                            </button>
                        </div>
                        <up-update-verification-phone-number
                            [userId]="userId"
                            (update)="onUpdatePhoneNumber($event)"
                        ></up-update-verification-phone-number>
                    </div>
                    <ng-template #submitControls>
                        <form [formGroup]="verificationForm" (ngSubmit)="verifyCode()">
                            <div class="u-margin-bottom--lg">
                                <up-input
                                    [autocomplete]="'one-time-code'"
                                    [formControlName]="'code'"
                                    [error]="hasAttemptedSubmitCode && verificationForm.get('code').invalid"
                                    [type]="'text'"
                                    [label]="'Enter your 6 digit code'"
                                    [size]="'large'"
                                    [internalLabel]="true"
                                    [icon]="'lock'"
                                    [iconColor]="Colors.brand2Regular"
                                    [fullWidth]="true"
                                >
                                </up-input>
                                <up-help-text
                                    *ngIf="hasAttemptedSubmitCode && verificationForm.get('code').invalid"
                                    [type]="'error'"
                                >
                                    Please enter your 6 digit code
                                </up-help-text>
                                <up-alert
                                    class="u-margin-top--md"
                                    *ngIf="errorVerifyingCode"
                                    [size]="'small'"
                                    [type]="'negative'"
                                >
                                    Your verification code was incorrect, please try again.
                                </up-alert>
                            </div>
                            <div class="u-margin-bottom--lg">
                                <div *ngIf="!successResendingSms">
                                    Did not receive verification code?
                                    <up-link (onClick)="resendSms()">Resend code</up-link> or
                                    <up-link (onClick)="updatePhoneNumberVisibility(true)"> update my number </up-link>.
                                </div>
                                <up-alert *ngIf="successResendingSms" [size]="'small'" [type]="'positive'">
                                    A new code has been sent
                                </up-alert>
                                <up-alert
                                    class="u-margin-top--xs"
                                    *ngIf="errorResendingSms"
                                    [size]="'small'"
                                    [type]="'negative'"
                                >
                                    There was a problem resending the code, please try again.
                                </up-alert>
                            </div>
                            <button
                                class="submit-button"
                                up-button
                                type="'submit'"
                                [style]="'primary'"
                                [size]="'large'"
                                [loading]="verifyingCode"
                            >
                                Submit
                            </button>
                        </form>
                    </ng-template>
                </ng-container>
                <ng-template #errorSendingSmsElse>
                    <div class="u-margin-bottom--lg u-text-body--lg"> {{ errorSendingInitialSms }} </div>
                    <up-update-verification-phone-number
                        [userId]="userId"
                        (update)="onUpdatePhoneNumber($event)"
                    ></up-update-verification-phone-number>
                </ng-template>
            </ng-template>
            <ng-container *upFeature="'upsideMarketingPagesEnabled'">
                <div *ngIf="!sendingInitialSms" class="u-text-body--sm u-text-color--light u-margin-top--lg">
                    Having issues verifying? Get in contact with us on
                    <up-link [href]="'tel:1800877433'">1800 877 433</up-link> or
                    <up-link [href]="'mailto:support@upside.com.au'">support@upside.com.au</up-link>.
                </div>
            </ng-container>
        </div>
        <img
            class="example"
            width="374"
            height="628"
            alt="Phone verification example"
            [src]="'/static/images/screens/phone-verification-#themeName#.png' | upThemeNameStringTemplate | upCdnUrl"
        />
    </up-container>
</up-outer-container>
