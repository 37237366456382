import { InjectionToken } from '@angular/core';

type AustralianStates = string[];

export const AUSTRALIAN_STATES_DI_CONFIG = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];

/** @Deprecated
 * Use enums in countryStates.model.ts instead.
 * **/
export const AUSTRALIAN_STATES_CONFIG = new InjectionToken<AustralianStates>('app.constants.australia-states');
