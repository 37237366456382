import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[nc-link], a[nc-link], button[up-link], a[up-link]',
    templateUrl: 'link-2.component.html',
    styleUrls: ['link-2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    /**
     * `preserveWhitespaces: false` prevents angular form wrapping host element around the template with line breaks causing
     * a space to appear when it's placed next to an inline element.
     *
     * Example:
     * preserveWhitespaces: true
     *
     * <up-link>
     *     Hello World
     * </up-link>.
     *
     * Hello World .
     *
     * ---------------------------
     *
     * preserveWhitespaces: false
     *
     * <up-link>Hello World</up-link>.
     *
     * Hello World.
     **/
    preserveWhitespaces: false,
})
export class Link2Component {
    @HostBinding('class.c-link')
    private isClassLink = true;
}
