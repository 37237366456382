import { BuyerCategoryDto } from '../../dto/buyer/buyer-category.dto';
export namespace BuyerCategory {
    export enum Enum {
        Uncategorized = 'buyerCategory.uncategorized',
        FirstHomeBuyer = 'buyerCategory.firstHomeBuyer',
        Downsize = 'buyerCategory.downsize',
        Upsize = 'buyerCategory.upsize',
        Investor = 'buyerCategory.investor',
        BuyThenSell = 'buyerCategory.buyThenSell',
    }

    export const toArray = () => [
        BuyerCategory.Enum.Uncategorized,
        BuyerCategory.Enum.FirstHomeBuyer,
        BuyerCategory.Enum.Downsize,
        BuyerCategory.Enum.Upsize,
        BuyerCategory.Enum.Investor,
        BuyerCategory.Enum.BuyThenSell,
    ];

    export function fromDto(dto: BuyerCategoryDto): BuyerCategory.Enum | undefined {
        switch (dto) {
            case 'BuyThenSell':
                return BuyerCategory.Enum.BuyThenSell;
            case 'Downsizer':
                return BuyerCategory.Enum.Downsize;
            case 'FirstHomeBuyer':
                return BuyerCategory.Enum.FirstHomeBuyer;
            case 'Investor':
                return BuyerCategory.Enum.Investor;
            case 'Upsizer':
                return BuyerCategory.Enum.Upsize;
            case 'Uncategorized':
                return BuyerCategory.Enum.Uncategorized;
            default:
                return undefined;
        }
    }

    export function toDto(buyerCategory?: BuyerCategory.Enum): BuyerCategoryDto | undefined {
        switch (buyerCategory) {
            case BuyerCategory.Enum.BuyThenSell:
                return 'BuyThenSell';
            case BuyerCategory.Enum.Downsize:
                return 'Downsizer';
            case BuyerCategory.Enum.FirstHomeBuyer:
                return 'FirstHomeBuyer';
            case BuyerCategory.Enum.Investor:
                return 'Investor';
            case BuyerCategory.Enum.Upsize:
                return 'Upsizer';
            case BuyerCategory.Enum.Uncategorized:
                return 'Uncategorized';
            default:
                return undefined;
        }
    }
}
