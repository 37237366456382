import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IconModule } from '../icon/icon.module';

import { DropdownItemDirective } from './dropdown-item.directive';
import { DropdownItemsComponent } from './dropdown-items/dropdown-items.component';
import { DropdownComponent } from './dropdown.component';

@NgModule({
    imports: [CommonModule, IconModule, ReactiveFormsModule],
    exports: [DropdownComponent, DropdownItemDirective],
    declarations: [DropdownComponent, DropdownItemDirective, DropdownItemsComponent],
    bootstrap: [DropdownItemsComponent],
})
export class DropdownModule {}
