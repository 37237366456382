import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

type Style = 'dark' | 'regular' | 'light' | 'white';
type Display = 'block' | 'inline-block' | 'inline';

/** @Deprecated - requires new implementation that doesn't bind styles with inputs. **/
@Component({
    selector: 'up-placeholder',
    templateUrl: './placeholder.component.html',
    styleUrls: ['./placeholder.component.scss'],
})
export class PlaceholderComponent implements AfterViewInit {
    @ViewChild('shine', { static: false }) private shineEl: ElementRef<HTMLElement>;
    @Input() public width = '64px';
    @Input() public height = '12px';
    @Input() public radius = '0';
    @Input() public backgroundStyle: Style = 'regular';
    @Input() public borderWidth = '0';
    @Input() public borderStyle: Style = 'regular';
    @Input() public display: Display = 'block';

    constructor(private renderer2: Renderer2, private elementRef: ElementRef) {}

    public ngAfterViewInit(): void {
        this.initElementStyles();
    }

    private initElementStyles(): void {
        this.renderer2.setStyle(this.elementRef.nativeElement, 'width', this.width);
        this.renderer2.setStyle(this.elementRef.nativeElement, 'height', this.height);
        this.renderer2.setStyle(this.elementRef.nativeElement, 'border-radius', this.radius);
        this.renderer2.setStyle(this.shineEl.nativeElement, 'border-radius', this.radius);
        this.renderer2.setStyle(this.elementRef.nativeElement, 'display', this.display);
        this.setPropertyStyle('background-color', this.backgroundStyle);

        if (this.borderWidth) {
            this.renderer2.setStyle(this.elementRef.nativeElement, 'border-style', 'solid');
            this.renderer2.setStyle(this.elementRef.nativeElement, 'border-width', this.borderWidth);
            this.setPropertyStyle('border-color', this.borderStyle);
        }
    }

    private setPropertyStyle(property: string, style: string): void {
        let value;

        switch (style) {
            case 'white':
                value = '#fff';
                break;
            case 'light':
                value = '#f1f1f1';
                break;
            case 'dark':
                value = '#B5B5B5';
                break;
            case 'regular':
            default:
                value = '#eee';
                break;
        }

        this.renderer2.setStyle(this.elementRef.nativeElement, property, value);
    }
}
