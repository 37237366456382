import { NgModule } from '@angular/core';

import { SharedModule } from '../../../common/shared.module';

import { UnsubscribeDialogComponent } from './unsubscribe-dialog/unsubscribe-dialog.component';

@NgModule({
    imports: [SharedModule],
    exports: [],
    declarations: [UnsubscribeDialogComponent],
    providers: [],
    entryComponents: [UnsubscribeDialogComponent],
})
export class UserDialogsModule {}
