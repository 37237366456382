<div
    class="c-alert u-text-body--rg"
    [ngClass]="{
        'c-alert--positive': type === 'positive',
        'c-alert--negative': type === 'negative',
        'c-alert--warning': type === 'warning',
        'c-alert--info': type === 'info',
        'c-alert--loading': type === 'loading',
        'c-alert--small': size === 'small'
    }"
    role="alert"
>
    <div class="c-alert__icon">
        <nc-spinner *ngIf="type === 'loading'; else notLoadingType"></nc-spinner>
        <ng-template #notLoadingType>
            <ng-container *ngTemplateOutlet="customIcon"></ng-container>
            <nc-icon
                *ngIf="!customIcon"
                [ngClass]="{
                    'u-text-color--negative': type === 'negative',
                    'u-text-color--warning': type === 'warning',
                    'u-text-color--positive': type === 'positive',
                    'u-text-color--brand-primary-light': type === 'info'
                }"
                [size]="'small'"
                [name]="iconName"
                [color]="'currentColor'"
            >
            </nc-icon>
        </ng-template>
    </div>
    <div class="content"> <ng-content></ng-content> </div>
</div>
