<up-media-object [stacked]="showDesktopVersion">
    <div figure>
        <div class="icon" [class.is-desktop-version]="showDesktopVersion">
            <ng-content select="[icon]"></ng-content>
        </div>
    </div>
    <div body>
        <div class="heading u-margin-bottom--xs"> <ng-content select="[heading]"></ng-content> </div>
        <div class="body u-margin-bottom--xl u-text-body--sm"> <ng-content select="[body]"></ng-content> </div>
        <div class="button"> <ng-content select="[button]"></ng-content> </div>
    </div>
</up-media-object>
