import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'nc-split-formatted-address',
    templateUrl: 'split-formatted-address.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplitFormattedAddressComponent {
    @Input() public formattedAddress: string;

    public get splitFormattedAddress(): [string] | [string, string] | undefined {
        const address = this.formattedAddress;
        if (!address) {
            return undefined;
        }

        const splitValue = ',';
        const splitAt = address.lastIndexOf(splitValue);

        if (splitAt === -1) {
            return [address];
        }

        return [address.slice(0, splitAt).trim(), address.slice(splitAt + splitValue.length).trim()];
    }

}
