import { HttpErrorResponse } from '@angular/common/http';
import { Moment } from 'moment';

import { AddressT } from '../../common/models/address.model';
import { agent } from '../../common/models/agent.model';
import { Suburb } from '../../common/models/suburb.model';
import { UserBase } from '../../common/models/user.model';
import { propertyReport } from '../../modules/property-report/property-report.model';

import { ConversionActions, ConversionActionTypes } from './conversion.actions';

export interface AppraisalState {
    isBookable: boolean;
    isLoadingAgent: boolean;
    loadingAgentError?: HttpErrorResponse;
    agent?: agent.CalendarAgent;
    calendar?: agent.Calendar;
    bookedTime?: Moment;
}

export interface ReportState {
    isLoadingSuburb: boolean;
    loadingSuburbError?: HttpErrorResponse;
    suburb?: Suburb;
    avmPropertyDetails?: propertyReport.CoreLogicLiveAvmPropertyDetails;
    propertyToken?: string;
}

export interface ConversionState {
    appraisal: AppraisalState;
    user?: UserBase;
    address?: AddressT;
    report: ReportState;
}

export const initialConversionState: ConversionState = {
    appraisal: {
        isBookable: false,
        isLoadingAgent: false,
    },
    report: {
        isLoadingSuburb: false,
    },
};

export function conversionReducer(
    state: ConversionState = initialConversionState,
    action: ConversionActions,
): ConversionState {
    switch (action.type) {
        case ConversionActionTypes.RESET_STATE: {
            return initialConversionState;
        }

        case ConversionActionTypes.GET_SUBURB_AGENT: {
            const appraisal = {
                ...state.appraisal,
                isLoadingAgent: true,
            };

            return {
                ...state,
                appraisal,
            };
        }

        case ConversionActionTypes.GET_SUBURB_AGENT_SUCCESS: {
            const agent = action.payload.agent;
            const calendar = action.payload.calendar;
            const isBookable = !!(calendar && calendar.availableTimes.length);
            const appraisal = {
                ...state.appraisal,
                isBookable,
                isLoadingAgent: false,
                agent,
                calendar,
            };

            return {
                ...state,
                appraisal,
            };
        }

        case ConversionActionTypes.GET_SUBURB_AGENT_ERROR: {
            const appraisal = {
                ...state.appraisal,
                isLoadingAgent: false,
            };

            return {
                ...state,
                appraisal,
            };
        }

        case ConversionActionTypes.GET_REPORT_SUBURB: {
            const report = {
                ...state.report,
                suburb: undefined,
                loadingSuburbError: undefined,
                isLoadingSuburb: true,
            };

            return {
                ...state,
                report,
            };
        }

        case ConversionActionTypes.GET_REPORT_SUBURB_SUCCESS: {
            const report = {
                ...state.report,
                isLoadingSuburb: false,
                suburb: action.payload,
            };

            return {
                ...state,
                report,
            };
        }

        case ConversionActionTypes.GET_REPORT_SUBURB_ERROR: {
            const report = {
                ...state.report,
                isLoadingSuburb: false,
                loadingSuburbError: action.payload,
            };

            return {
                ...state,
                report,
            };
        }

        case ConversionActionTypes.SET_APPRAISAL_BOOKED_TIME: {
            const appraisal = {
                ...state.appraisal,
                bookedTime: action.payload,
            };
            return {
                ...state,
                appraisal,
            };
        }

        case ConversionActionTypes.SET_USER: {
            return {
                ...state,
                user: action.payload,
            };
        }

        case ConversionActionTypes.SET_ADDRESS: {
            return {
                ...state,
                address: action.payload,
            };
        }

        case ConversionActionTypes.SET_REPORT_AVM_PROPERTY_DETAILS: {
            return {
                ...state,
                report: {
                    ...state.report,
                    avmPropertyDetails: action.payload,
                },
            };
        }

        case ConversionActionTypes.SET_REPORT_PROPERTY_TOKEN: {
            return {
                ...state,
                report: {
                    ...state.report,
                    propertyToken: action.payload,
                },
            };
        }

        case ConversionActionTypes.GET_AGENT_CALENDAR_BY_SLUG: {
            return {
                ...state,
                appraisal: {
                    ...state.appraisal,
                    isLoadingAgent: true,
                    loadingAgentError: undefined,
                },
            };
        }

        case ConversionActionTypes.GET_AGENT_CALENDAR_BY_SLUG_SUCCESS: {
            const { agent, calendar } = action.payload;
            return {
                ...state,
                appraisal: {
                    ...state.appraisal,
                    isLoadingAgent: false,
                    agent,
                    calendar,
                },
            };
        }

        case ConversionActionTypes.GET_AGENT_CALENDAR_BY_SLUG_ERROR: {
            return {
                ...state,
                appraisal: {
                    ...state.appraisal,
                    isLoadingAgent: false,
                    loadingAgentError: action.payload,
                },
            };
        }

        case ConversionActionTypes.RESET_USER_ENTERED_STATE: {
            const { address, user } = initialConversionState;
            return {
                ...state,
                user,
                address,
            };
        }

        default: {
            return state;
        }
    }
}
