import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { error as errorModel } from '../../../common/models/error.model';
import {
    ErrorCodeMessageMap,
    ErrorHandlerService,
    ValidationErrorObject,
} from '../../../common/services/error-handler.service';
import { doTranslation } from '../../../common/utilities/i18n/do-translation.util';

interface WithNotificationConfig {
    successMessage?: string;
    errorMessage?: string;
    errorMap?: ErrorCodeMessageMap;
    disable?: boolean;
    // Todo: consider proxying other notification functionality such as
    //  customisable notification timeout and custom HTML
}

interface NotificationConfig {
    disableTimeOut?: boolean;
    enableHtml?: boolean;
    timeOut?: number;
}

@Injectable()
export class NotificationService {
    private readonly defaultErrorNotificationConfig: NotificationConfig = {
        timeOut: 10000,
    };

    constructor(private errorHandlerService: ErrorHandlerService, private toastrService: ToastrService) {}

    public success(message: string): void {
        this.toastrService.success(message, doTranslation('generic.success'));
    }

    public httpError(
        httpErrorResponse: HttpErrorResponse,
        errorCodeMessageMap: ErrorCodeMessageMap,
        fallbackMessage: string,
    ): void {
        const code = this.errorHandlerService.getErrorCode(httpErrorResponse);
        // If the caller is already manually handling validation errors then don't override it
        const handlesValidationError = errorCodeMessageMap && errorCodeMessageMap[errorModel.Codes.ValidationFailed];

        // Todo: this could be improved by permitting the validation error codes and values to be optionally prettified
        //  with a config by the consumer. Currently the front end just renders what it gets from the API.
        if (code === 'VALIDATION_FAILED' && !handlesValidationError) {
            const error = (<errorModel.HttpResponse<errorModel.Codes.ValidationFailed>>httpErrorResponse).error;
            const errors = error.errors || [];
            const errorObject = this.errorHandlerService.convertValidationListToObject(errors);
            const formattedError =
                (errors.length && this.convertErrorObjectToHtmlList(errorObject)) ||
                error.message ||
                '<br>Unknown validation error occurred';

            this.error(`Please fix the following validation error${errors.length > 1 ? 's' : ''}: ${formattedError}`, {
                enableHtml: true,
            });
        } else {
            const errorMessage = this.errorHandlerService.getFormattedHttpErrorMessage(
                httpErrorResponse,
                errorCodeMessageMap,
                fallbackMessage,
            );

            this.error(errorMessage);
        }
    }

    public error(message: string, configOverrides?: NotificationConfig): void {
        this.toastrService.error(
            message,
            doTranslation('generic.error'),
            merge(this.defaultErrorNotificationConfig, configOverrides),
        );
    }

    public info(message: string, config?: NotificationConfig): void {
        this.toastrService.info(message, doTranslation('generic.info'), config);
    }

    public warning(message: string, config?: NotificationConfig): void {
        this.toastrService.warning(message, doTranslation('generic.warning'), config);
    }

    public withNotification<T>(config: WithNotificationConfig = {}): (source$: Observable<T>) => Observable<T> {
        const { errorMessage, errorMap, disable, successMessage } = config;

        return (source$: Observable<T>) =>
            disable
                ? source$
                : source$.pipe(
                    tap({
                        complete: () => {
                            if (!successMessage) return;
                            this.success(successMessage);
                        },
                        error: error => {
                            if (error instanceof HttpErrorResponse) {
                                this.httpError(error, errorMap, errorMessage);
                            } else {
                                this.error(`${errorMessage}: ${error}`);
                            }
                        },
                    }),
                );
    }

    private convertErrorObjectToHtmlList(errorObject: ValidationErrorObject): string {
        const list = Object.entries(errorObject).reduce((acc, [key, value]) => {
            const content = typeof value === 'object' ? this.convertErrorObjectToHtmlList(value) : value;

            return acc + this.createListItem(`${key}: ${content}`);
        }, '');

        return this.createList(list);
    }

    private createListItem(content: string): string {
        return `<li class="o-list__item">${content}</li>`;
    }

    private createList(content: string): string {
        return `<ul class="o-list">${content}</ul>`;
    }
}
