import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

import { GetStartedFacade } from '../store/get-started.facade';

@Component({
    selector: 'up-get-started-services',
    templateUrl: './get-started-services.component.html',
    styleUrls: ['./get-started-services.component.scss'],
})
export class GetStartedServicesComponent implements OnInit {
    constructor(@Inject(PLATFORM_ID) private platformId: object, private getStartedFacade: GetStartedFacade) {}

    public ngOnInit(): void {
        this.getStartedFacade.setHasStartedFlow(true);
        this.getStartedFacade.setHasRequestedCallback(false);
    }
}
