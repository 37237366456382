import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import clone from 'lodash-es/clone';

import { UpValidators } from '../../form-validators/validators';
import { agent } from '../../models/agent.model';

export interface RequestCallBackFormData {
    name: string;
    phone: string;
    preferredTime?: RequestCallPreferredTime;
    agentFirst?: string;
    agentLast?: string;
}

export enum RequestCallPreferredTime {
    Morning = 'Morning',
    Afternoon = 'Afternoon',
    Evening = 'Evening',
    AnyTime = 'Any time',
}

@Component({
    selector: 'up-request-call-back-form',
    templateUrl: './request-call-back-form.component.html',
})
export class RequestCallBackFormComponent implements OnInit {
    @Input() public agent: agent.SimpleAgent;
    @Input() public loading: boolean;
    @Input() public error: string;
    @Input() public showLogo: boolean;
    @Output() public formSubmit = new EventEmitter<RequestCallBackFormData>();
    public callbackForm: FormGroup;
    public preferredTimeEnum = RequestCallPreferredTime;
    public hasAttemptedFormSubmit: boolean;

    constructor(private formBuilder: FormBuilder) {}

    public ngOnInit(): void {
        this.createForm();
    }

    public onSubmit(): void {
        this.hasAttemptedFormSubmit = true;

        if (this.callbackForm.invalid) return;

        // strip out preferred time if it is falsey or AnyTime
        const result: RequestCallBackFormData = clone(this.callbackForm.value);
        if (!result.preferredTime || result.preferredTime === RequestCallPreferredTime.AnyTime) {
            delete result.preferredTime;
        }

        // append agent to result if we have one
        if (this.agent) {
            result.agentFirst = this.agent.firstName;
            result.agentLast = this.agent.lastName;
        }

        this.formSubmit.emit(result);
    }

    private createForm(): void {
        this.callbackForm = this.formBuilder.group({
            name: ['', [Validators.required, UpValidators.noWhiteSpacesOnlyValidator]],
            phone: ['', [Validators.required, UpValidators.phoneNumberValidator]],
            preferredTime: '',
        });
    }
}
