<div
    ncResizeObserver
    (observeResize)="onObserveResize($event)"
    *transloco="let t"
    class="wrapper"
    [class.scrim]="useScrim"
>
    <ng-container *ngIf="rawImageUrl; else noImage">
        <img
            *ngIf="renderImageWidth && renderImageHeight"
            class="property-image u-print-hide"
            [alt]="photoImageAlt"
            [src]="
                rawImageUrl | imageResize : renderImageWidth * renderImageScale : renderImageHeight * renderImageScale
            "
        />

        <img
            class="property-image u-display--none u-print-display--block"
            [alt]="photoImageAlt"
            [src]="rawImageUrl | imageResize : printImageSizePx : printImageSizePx"
        />
    </ng-container>
    <ng-template #noImage>
        <ng-container *ngIf="staticMapCoordinates; else noStaticMap">
            <img
                class="property-image u-print-hide"
                *ngIf="renderImageWidth && renderImageHeight"
                nc-static-map
                [alt]="mapImageAlt"
                [coordinates]="mapCoordinates"
                [scale]="renderImageScale"
                [size]="{
                    width: renderImageWidth,
                    height: renderImageHeight
                }"
            />

            <img
                class="property-image u-display--none u-print-display--block"
                nc-static-map
                [alt]="mapImageAlt"
                [coordinates]="mapCoordinates"
                [scale]="renderImageScale"
                [size]="{
                    width: printImageSizePx,
                    height: printImageSizePx
                }"
            />
        </ng-container>
    </ng-template>
    <ng-template #noStaticMap>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            class="property-image placeholder-illustration"
            preserveAspectRatio="xMidYMid slice"
            viewBox="0 0 375 238"
        >
            <g fill-rule="evenodd" clip-rule="evenodd" opacity=".251">
                <path
                    fill="#DFDFE0"
                    d="M368.269 238.178c0-39.889-14.172-46.994-32.345-107.31s-70.961-55.375-103.785-69.174c-20.962-8.812-39.217-11.584-63.221-11.584-42.828 0-59.928 48.38-110.636 61.327-37.547 9.586-62.563 71.547-52.106 126.741"
                />
                <path
                    fill="#595959"
                    fill-opacity=".5"
                    d="M80.482 121.273s1.004-10.339 9.737-12.176c8.734-1.837-2.569 2.077 2.319-5.496 11.377-17.631 24.142-3.14 31.491-7.674 6.947-4.286 6.026-10.262 18.531-11.64 5.947-.655 8.438-2.878 14.551-5.47 5.293-2.243 14.057 7.854 14.057 7.854v47.683l-90.686-13.081z"
                />
                <path
                    fill="#333"
                    fill-opacity=".4"
                    d="M289.168 125.262c0-3.464-2.639-6.272-5.893-6.272-.211 0-.419.013-.625.036a9.55 9.55 0 0 0 .035-.741c0-4.893-3.726-8.859-8.324-8.859a7.859 7.859 0 0 0-3.339.747c-.243-3.227-2.775-5.766-5.869-5.766-3.102 0-5.638 2.552-5.87 5.79a8.905 8.905 0 0 0-2.822-.458c-5.208 0-9.43 4.493-9.43 10.035 0 .18.017.355.026.533a5.573 5.573 0 0 0-2.677-.69c-3.255 0-5.894 2.808-5.894 6.272 0 3.464 2.639 6.272 5.894 6.272a5.57 5.57 0 0 0 1.039-.097c2.675 2.334 10.233 4.018 19.145 4.018 9.697 0 17.793-1.993 19.737-4.649 2.764-.518 4.867-3.08 4.867-6.171zm-118-27.19h32.617v55.862l-9.793-1.493h-22.824V98.072z"
                />
                <path fill="#595959" d="m193.992 126.291 9.794-28.219 9.793 28.219h-19.587z" />
                <path fill="#595959" d="m213.579 126.291-9.794-28.219h55.917l9.793 28.219h-55.916z" />
                <path
                    fill="#333"
                    fill-opacity=".4"
                    d="m208.021 98.072 9.794 28.219h3.441l-9.794-28.219h-3.441zm8.838 0 9.794 28.219h3.44L220.3 98.072h-3.441zm8.84 0 9.794 28.219h3.44l-9.793-28.219h-3.441zm8.842 0 9.794 28.219h3.44l-9.793-28.219h-3.441z"
                />
                <path fill="#595959" d="M193.992 182.154h19.587v-55.862h-19.587v55.862z" />
                <path fill="#fff" d="M213.58 182.154h55.917v-55.862H213.58v55.862z" />
                <path fill="#333" d="m104.592 116.502-12.055-12.67h37.967l12.055 12.67h-37.967z" />
                <path fill="#333" fill-opacity=".2" d="M104.594 152.784h37.967v-36.282h-37.967v36.282z" />
                <path fill="#fff" d="M228.789 190.216h38.43V137.81h-38.43v52.406z" />
                <path fill="#333" d="m257.272 126.292 12.113-13.246h-28.483l-12.113 13.246h28.483z" />
                <path fill="#fff" d="M228.789 137.81h28.483v-11.518h-28.483v11.518z" />
                <path
                    fill="currentColor"
                    d="M232.049 135.506h3.843v-5.183h-3.843v5.183zm9.355 0h3.843v-5.183h-3.843v5.183zm-9.355 15.549h3.843v-5.183h-3.843v5.183zm9.355 0h3.843v-5.183h-3.843v5.183zm-9.355 14.974h3.843v-5.183h-3.843v5.183zm9.355 0h3.843v-5.183h-3.843v5.183z"
                />
                <path
                    fill="#333"
                    d="M101.381 190.217h41.181v-37.434h-41.181v37.434zm0-37.434h41.181l-20.59-19.581-20.591 19.581z"
                />
                <path
                    fill="#D8E4F4"
                    d="M133.508 162.573h2.023v-1.727h-2.023v1.727zm0 6.911h2.023v-2.303h-2.023v2.303zm0 6.335h2.023v-2.303h-2.023v2.303zm0 6.334h2.023v-2.303h-2.023v2.303z"
                />
                <path fill="#FFFFFE" d="M142.561 152.783h28.608v-54.71h-28.608v54.71z" />
                <path
                    fill="#333"
                    d="M146.58 107.286h1.582v-1.727h-1.582v1.727zm0 5.184h1.582v-1.728h-1.582v1.728zm0 5.183h1.582v-1.727h-1.582v1.727zm4.748-10.367h1.582v-1.727h-1.582v1.727zm0 5.184h1.582v-1.728h-1.582v1.728zm0 5.183h1.582v-1.727h-1.582v1.727zm4.746-10.367h1.583v-1.727h-1.583v1.727zm0 5.184h1.583v-1.728h-1.583v1.728zm0 5.183h1.583v-1.727h-1.583v1.727zm4.746-10.367h1.583v-1.727h-1.583v1.727zm0 5.184h1.583v-1.728h-1.583v1.728zm0 5.183h1.583v-1.727h-1.583v1.727zm4.746-10.367h1.583v-1.727h-1.583v1.727zm0 5.184h1.583v-1.728h-1.583v1.728zm0 5.183h1.583v-1.727h-1.583v1.727zm-18.986 4.607h1.582v-1.728h-1.582v1.728zm4.748 0h1.582v-1.728h-1.582v1.728zm4.746 0h1.583v-1.728h-1.583v1.728zm4.746 0h1.583v-1.728h-1.583v1.728zm4.746 0h1.583v-1.728h-1.583v1.728z"
                />
                <path fill="#333" fill-opacity=".2" d="M229.186 190.217h-86.625v-37.434h51.431l35.194 37.434z" />
                <path
                    fill="currentColor"
                    d="M197.338 159.118c0 11.45-7.939 20.732-7.939 20.732s-7.938-9.282-7.938-20.732 7.938-20.732 7.938-20.732 7.939 9.282 7.939 20.732z"
                />
                <path
                    fill="#fff"
                    d="M189.399 183.305a.409.409 0 0 1-.401-.415v-27.389c0-.229.18-.415.401-.415.222 0 .402.186.402.415v27.389c0 .229-.18.415-.402.415z"
                />
                <path
                    fill="#fff"
                    d="M189.401 163.725a.392.392 0 0 1-.287-.129l-2.927-3.178a.445.445 0 0 1 .006-.603.385.385 0 0 1 .568.007l2.64 2.867 2.641-2.867a.384.384 0 0 1 .567-.007.445.445 0 0 1 .007.603l-2.928 3.178a.392.392 0 0 1-.287.129z"
                />
                <path
                    fill="#333"
                    fill-opacity=".4"
                    d="M172.46 159.693c0 10.178-7.012 18.429-7.012 18.429s-7.012-8.251-7.012-18.429c0-10.178 7.012-18.428 7.012-18.428s7.012 8.25 7.012 18.428z"
                />
                <path
                    fill="#333"
                    d="M184.899 151.343c0 11.291-7.939 20.444-7.939 20.444s-7.939-9.153-7.939-20.444c0-11.291 7.939-20.445 7.939-20.445s7.939 9.154 7.939 20.445z"
                />
                <path
                    fill="#fff"
                    d="M176.96 183.881a.408.408 0 0 1-.401-.417v-34.871a.41.41 0 0 1 .401-.417.41.41 0 0 1 .401.417v34.871a.408.408 0 0 1-.401.417zm-11.512-.576a.405.405 0 0 1-.401-.409v-26.248c0-.226.18-.41.401-.41.222 0 .402.184.402.41v26.248c0 .226-.18.409-.402.409z"
                />
                <path
                    fill="#fff"
                    d="M142.561 190.216v-22.744s8.596-3.742 20.108 4.316c15.842 11.09 20.206 2.969 37.713 2.969 12.307 0 28.406 15.459 28.406 15.459h-86.227z"
                />
                <path
                    fill="#333"
                    d="m149.036 182.33-6.809 28.827 18.658 2.095-7.229-30.916a2.353 2.353 0 0 0-4.62-.006z"
                />
                <path
                    fill="currentColor"
                    d="m160.149 181.179-6.809 28.826 18.658 2.096-7.229-30.916a2.352 2.352 0 0 0-4.62-.006zm44.992 9.037 12.055-12.67 12.055 12.67h-24.11z"
                />
                <path fill="currentColor" d="M217.195 177.546h37.967l12.056 12.67h-37.967l-12.056-12.67z" />
                <path
                    fill="#595959"
                    d="m253.925 190.216-12.055-12.67h-1.204l12.055 12.67h1.204zm-5.089 0-12.056-12.67h-1.204l12.056 12.67h1.204zm-5.09 0-12.056-12.67h-1.204l12.056 12.67h1.204zm-5.09 0-12.055-12.67h-1.205l12.056 12.67h1.204zm-5.088 0-12.055-12.67h-1.204l12.055 12.67h1.204z"
                />
                <path
                    fill="#333"
                    fill-opacity=".4"
                    d="M365.427 200.87c0 13.836-9.626 25.051-9.626 25.051s-9.625-11.215-9.625-25.051c0-13.836 9.625-25.052 9.625-25.052s9.626 11.216 9.626 25.052z"
                />
                <path
                    fill="#595959"
                    d="M308.361 130.497c-1.443-2.759-4.221-4.629-7.413-4.629-.883 0-1.734.144-2.535.409-.208-2.89-2.486-5.169-5.272-5.169-2.779 0-5.053 2.267-5.272 5.147a7.1 7.1 0 0 0-2.999-.667 7.115 7.115 0 0 0-3.374.858v29.792h26.865v-25.741z"
                />
                <path
                    fill="#D8E4F4"
                    d="M333.089 155.837c-1.443-2.759-4.22-4.629-7.412-4.629-.883 0-1.735.144-2.535.409-.209-2.891-2.487-5.169-5.273-5.169-2.779 0-5.053 2.267-5.271 5.147a7.106 7.106 0 0 0-2.999-.667c-1.216 0-2.36.314-3.374.858v29.792h26.864v-25.741z"
                />
                <path
                    fill="#333"
                    fill-opacity=".4"
                    d="m252.219 98.072 9.793 28.219h3.441l-9.794-28.219h-3.44zm-8.84 0 9.793 28.219h3.441l-9.794-28.219h-3.44z"
                />
                <path fill="#333" d="M267.217 190.216h27.58V137.81h-27.58v52.406z" />
                <path fill="#333" d="M292.988 190.216h15.374v-33.978h-15.374v33.978z" />
                <path
                    fill="currentColor"
                    d="M257.271 137.81h24.226v-11.518h-24.226v11.518zm24.226-11.518-12.113-13.246-12.113 13.246h24.226zm-30.737 9.214h3.843v-5.183h-3.843v5.183zm0 15.549h3.843v-5.183h-3.843v5.183zm9.355 0h3.843v-5.183h-3.843v5.183zm-9.355 14.974h3.843v-5.183h-3.843v5.183zm9.355 0h3.843v-5.183h-3.843v5.183z"
                />
                <path
                    fill="#D8E4F4"
                    d="M271.834 151.055h3.843v-5.183h-3.843v5.183zm9.355 0h3.843v-5.183h-3.843v5.183zm-9.355 14.974h3.843v-5.183h-3.843v5.183zm9.355 0h3.843v-5.183h-3.843v5.183zm9.356 0h3.843v-5.183h-3.843v5.183zm9.357 0h3.843v-5.183h-3.843v5.183zm-28.068 15.548h3.843v-5.759h-3.843v5.759zm9.355 0h3.843v-5.759h-3.843v5.759zm9.356 0h3.843v-5.759h-3.843v5.759zm9.357 0h3.843v-5.759h-3.843v5.759z"
                />
                <path
                    fill="#152B6F"
                    d="M271.834 145.872h3.843v-.576h-3.843v.576zm9.355 0h3.843v-.576h-3.843v.576zm-9.355 15.549h3.843v-1.151h-3.843v1.151zm9.355 0h3.843v-1.151h-3.843v1.151z"
                />
                <path fill="#1E3758" d="M290.545 161.421h3.843v-1.151h-3.843v1.151z" />
                <path
                    fill="#152B6F"
                    d="M299.902 161.421h3.843v-1.151h-3.843v1.151zm-28.068 14.973h3.843v-.576h-3.843v.576zm9.355 0h3.843v-.576h-3.843v.576zm9.356 0h3.843v-.576h-3.843v.576zm9.357 0h3.843v-.576h-3.843v.576z"
                />
                <path fill="#333" fill-opacity=".2" d="m327.124 188.489-18.763-32.251h24.729l18.762 32.251h-24.728z" />
                <path fill="currentColor" d="m327.123 188.489-18.762-32.251v32.251h18.762z" />
                <path
                    fill="#595959"
                    d="m264.106 190.216-12.056-12.67h-1.204l12.055 12.67h1.205zm-5.088 0-12.056-12.67h-1.204l12.056 12.67h1.204z"
                />
                <path
                    fill="#333"
                    fill-opacity=".4"
                    d="M315.009 129.105c0 9.578-6.647 17.343-6.647 17.343s-6.647-7.765-6.647-17.343c0-.266.005-.531.015-.794.335-8.835 12.929-8.835 13.263 0 .01.263.016.528.016.794z"
                />
                <path
                    fill="currentColor"
                    d="M308.36 156.238a.407.407 0 0 1-.401-.413v-21.634c0-.228.18-.413.401-.413.222 0 .402.185.402.413v21.634c0 .229-.18.413-.402.413z"
                />
                <path
                    fill="#333"
                    d="M331.647 129.105c0 9.578-6.647 17.343-6.647 17.343s-6.646-7.765-6.646-17.343c0-.266.005-.531.015-.794.334-8.835 12.928-8.835 13.263 0 .01.263.015.528.015.794z"
                />
                <path
                    fill="currentColor"
                    d="M324.999 156.238a.406.406 0 0 1-.401-.413v-21.634c0-.228.179-.413.401-.413.222 0 .401.185.401.413v21.634a.406.406 0 0 1-.401.413z"
                />
                <path fill="#2D376E" d="M355.4 238.859h.803v-38.853h-.803v38.853z" />
                <path
                    fill="#595959"
                    d="M115.48 233.408H46.273l-16.181-43.043 2.838-17.714 18.614-.864-5.27 18.578h69.206v43.043z"
                />
                <path
                    fill="#333"
                    d="M48.21 105.559h33.016c-.025-2.281-1.734-4.122-3.84-4.122-.138 0-.273.009-.407.024.012-.163.023-.327.023-.493 0-3.254-2.43-5.893-5.427-5.893-.775 0-1.51.18-2.177.497-.159-2.146-1.81-3.835-3.827-3.835-2.022 0-3.676 1.698-3.827 3.851a5.701 5.701 0 0 0-1.84-.305c-3.395 0-6.148 2.989-6.148 6.676 0 .119.011.236.017.354a3.581 3.581 0 0 0-1.745-.459c-1.977 0-3.603 1.621-3.817 3.705z"
                />
                <path
                    fill="#595959"
                    d="M34.181 173.515c0 9.86-6.814 17.853-6.814 17.853s-6.814-7.993-6.814-17.853c0-9.86 6.814-17.853 6.814-17.853s6.814 7.993 6.814 17.853z"
                    opacity=".5"
                />
                <path
                    fill="#fff"
                    d="M27.366 204.038a.409.409 0 0 1-.401-.416v-30.267a.41.41 0 0 1 .401-.416.41.41 0 0 1 .402.416v30.267c0 .23-.18.416-.402.416z"
                />
                <path
                    fill="#333"
                    fill-opacity=".4"
                    d="M13.628 210.372c0 9.86-6.814 17.853-6.814 17.853S0 220.232 0 210.372c0-9.859 6.814-17.852 6.814-17.852s6.814 7.993 6.814 17.852z"
                />
                <path fill="currentColor" d="M6.412 233.409h.803v-23.612h-.803v23.612z" />
                <path
                    fill="#333"
                    d="M374.999 210.372c0 9.86-6.814 17.853-6.814 17.853s-6.814-7.993-6.814-17.853c0-9.859 6.814-17.852 6.814-17.852s6.814 7.993 6.814 17.852z"
                />
                <path fill="#2D376E" d="M367.787 233.409h.803v-23.612h-.803v23.612z" />
                <path
                    fill="#333"
                    d="M50.72 166.892c0 12.882-9.745 23.324-9.745 23.324s-9.745-10.442-9.745-23.324c0-12.881 9.745-23.324 9.745-23.324s9.744 10.443 9.744 23.324z"
                />
                <path
                    fill="#fff"
                    d="M40.976 200.007a.41.41 0 0 1-.402-.416v-36.602c0-.23.18-.416.402-.416.221 0 .4.186.4.416v36.602a.41.41 0 0 1-.4.416z"
                />
                <path fill="currentColor" d="m26.835 204.614-14.747-21.308h19.437l14.747 21.308H26.835z" />
                <path fill="#333" fill-opacity=".4" d="M26.834 237.661h19.437v-33.048H26.834v33.048z" />
                <path
                    fill="#333"
                    d="M12.088 237.818h14.747v-31.329H12.088v31.329zm14.747-31.329-14.747-23.183v23.183h14.747z"
                />
                <path fill="#fff" d="M18.318 219.587h2.289v-9.214h-2.289v9.214z" />
                <path
                    fill="#595959"
                    d="M125.669 152.783c0-4.721-3.607-8.549-8.057-8.549-2.369 0-4.493 1.093-5.967 2.821-.139-4.592-3.682-8.272-8.044-8.272a7.62 7.62 0 0 0-3.394.804c.001-.062.008-.122.008-.185 0-4.721-3.607-8.548-8.056-8.548-1.815 0-3.483.644-4.83 1.718-.417-4.306-3.845-7.665-8.014-7.665-2.06 0-3.934.828-5.359 2.178-1.182-3.162-4.085-5.4-7.485-5.4-4.243 0-7.713 3.483-8.026 7.9a7.668 7.668 0 0 0-4.117-1.21c-4.45 0-8.056 3.827-8.056 8.549v15.859h79.397zm141.548 80.625h41.144v-43.192h-41.144v43.192z"
                />
                <path fill="currentColor" d="m80.482 116.502 12.056-12.67 12.055 12.67h-24.11z" />
                <path fill="currentColor" d="m80.482 152.784 24.111-4.032v-32.25h-24.11v36.282z" />
                <path fill="#FFFFFE" d="M46.272 190.217h55.106v-37.434H46.272v37.434z" />
                <path fill="#333" fill-opacity=".4" d="m66.862 133.202-20.59 19.581h55.106l20.591-19.581H66.862z" />
                <path fill="#333" d="m84.012 152.783-10.188-9.79H94.2l-10.187 9.79z" />
                <path fill="#FFFFFE" d="m63.637 152.515 10.187-9.522 10.187 9.522-9.21 6.027-11.164-6.027z" />
                <path
                    fill="#D8E4F4"
                    d="M109.229 162.573h2.023v-1.727h-2.023v1.727zm0 6.911h2.023v-2.303h-2.023v2.303zm0 6.335h2.023v-2.303h-2.023v2.303zm0 6.334h2.023v-2.303h-2.023v2.303zm6.07-19.58h2.023v-1.727h-2.023v1.727zm0 6.911h2.023v-2.303h-2.023v2.303zm0 6.335h2.023v-2.303h-2.023v2.303zm0 6.334h2.023v-2.303h-2.023v2.303zm6.068-19.58h2.024v-1.727h-2.024v1.727zm0 6.911h2.024v-2.303h-2.024v2.303zm0 6.335h2.024v-2.303h-2.024v2.303zm0 6.334h2.024v-2.303h-2.024v2.303zm6.071-19.58h2.023v-1.727h-2.023v1.727zm0 6.911h2.023v-2.303h-2.023v2.303zm0 6.335h2.023v-2.303h-2.023v2.303zm0 6.334h2.023v-2.303h-2.023v2.303z"
                />
                <path fill="#fff" d="M327.125 237.89h24.728v-49.402h-24.728v49.402z" />
                <path fill="currentColor" d="M308.361 237.89h18.762v-49.402h-18.762v49.402z" />
                <path
                    fill="#fff"
                    d="M316.287 202.31h2.911v-13.822h-2.911v13.822zm0 21.885h2.911v-13.822h-2.911v13.822z"
                />
                <path
                    fill="#595959"
                    d="M319.599 202.311h-.801v-13.546h-2.511v-.852h3.312v14.398zm0 21.883h-.801v-13.546h-2.511v-.851h3.312v14.397z"
                />
                <path
                    fill="currentColor"
                    d="M51.514 164.301h2.589v-7.487h-2.59v7.487zm10.549 0h2.588v-7.487h-2.588v7.487zm10.55 0h2.59v-7.487h-2.59v7.487zm10.553 0h2.59v-7.487h-2.59v7.487zm10.549 0h2.589v-7.487h-2.59v7.487zm-42.201 13.245h2.589v-7.486h-2.59v7.486zm10.549 0h2.588v-7.486h-2.588v7.486zm10.55 0h2.59v-7.486h-2.59v7.486zm10.553 0h2.59v-7.486h-2.59v7.486zm10.549 0h2.589v-7.486h-2.59v7.486z"
                />
                <path
                    fill="#595959"
                    d="M51.514 157.39h2.589v-.576h-2.59v.576zm10.549 0h2.588v-.576h-2.588v.576zm10.55 0h2.59v-.576h-2.59v.576zm10.553 0h2.59v-.576h-2.59v.576zm10.549 0h2.589v-.576h-2.59v.576z"
                />
                <path fill="#F48F87" d="M140.535 228.225h5.823v-1.152h-5.823v1.152z" />
                <path fill="#F48F87" d="M140.535 228.225h5.823v-1.152h-5.823v1.152z" />
                <path fill="#fff" d="M140.535 228.225h5.823v-1.152h-5.823v1.152z" />
                <path
                    fill="#595959"
                    d="M51.514 170.636h2.589v-1.152h-2.59v1.152zm10.549 0h2.588v-1.152h-2.588v1.152zm10.55 0h2.59v-1.152h-2.59v1.152zm10.553 0h2.59v-1.152h-2.59v1.152zm10.549 0h2.589v-1.152h-2.59v1.152z"
                />
                <path fill="#FFFFFE" d="m142.561 98.072 14.304-12.094 14.304 12.094h-28.608z" />
                <path fill="#333" fill-opacity=".4" d="m171.169 98.073-14.304-12.17V72.158l14.304 12.17v13.746z" />
                <path
                    fill="#333"
                    d="m142.561 98.073 14.304-12.17V72.158l-14.304 12.17v13.746zm8.767 4.031h1.582v-1.728h-1.582v1.728zm4.746-4.608h1.583V95.77h-1.583v1.727zm0 4.608h1.583v-1.728h-1.583v1.728zm4.746 0h1.583v-1.728h-1.583v1.728z"
                />
                <path
                    fill="#333"
                    fill-opacity=".2"
                    d="M280.076 212.676c0 7.316-5.061 13.246-5.061 13.246s-5.06-5.93-5.06-13.246c0-7.315 5.06-13.245 5.06-13.245s5.061 5.93 5.061 13.245z"
                />
                <path fill="#fff" d="M274.613 233.409h.803v-21.885h-.803v21.885z" />
                <path
                    fill="#333"
                    fill-opacity=".4"
                    d="M292.744 212.676c0 7.316-5.061 13.246-5.061 13.246s-5.06-5.93-5.06-13.246c0-7.315 5.06-13.245 5.06-13.245s5.061 5.93 5.061 13.245z"
                />
                <path fill="#fff" d="M287.283 233.409h.803v-21.885h-.803v21.885z" />
                <path
                    fill="#333"
                    d="M305.412 212.676c0 7.316-5.061 13.246-5.061 13.246s-5.06-5.93-5.06-13.246c0-7.315 5.06-13.245 5.06-13.245s5.061 5.93 5.061 13.245z"
                />
                <path fill="#fff" d="M299.949 233.409h.803v-21.885h-.803v21.885z" />
                <path
                    fill="#333"
                    d="M136.6 198.487c7.537-10.516 13.991-.195 19.581.352 6.264.612 5.221-6.97 14.926-6.197 5.891.469 7.32 3.697 12.854-.14 5.401-3.745 11.724-10.179 23.169-8.276 12.556 2.088 31.759 42.848 31.759 42.848l-20.379-2.52-75.353-17.991-6.557-8.076z"
                />
                <path
                    fill="currentColor"
                    d="M151.356 206.917c-.221 0-.401-.19-.401-.426v-18.728c0-.235.18-.426.401-.426.222 0 .402.191.402.426v18.728c0 .236-.18.426-.402.426z"
                />
                <path
                    fill="currentColor"
                    d="M151.355 200.583a.383.383 0 0 1-.151-.032.42.42 0 0 1-.25-.389v-4.065l-2.47-4.084a.432.432 0 0 1 .125-.58.39.39 0 0 1 .553.131l2.532 4.187a.43.43 0 0 1 .063.224v3.157l2.878-3.081a.39.39 0 0 1 .568-.007.436.436 0 0 1 .006.595l-3.567 3.817a.391.391 0 0 1-.287.127z"
                />
                <path
                    fill="#fff"
                    d="M162.47 213.828a.414.414 0 0 1-.402-.424v-26.795c0-.234.18-.424.402-.424.221 0 .401.19.401.424v26.795c0 .234-.18.424-.401.424z"
                />
                <path
                    fill="#fff"
                    d="M162.47 199.43a.382.382 0 0 1-.151-.031.42.42 0 0 1-.25-.389v-4.065l-2.47-4.084a.432.432 0 0 1 .125-.58.391.391 0 0 1 .554.13l2.532 4.187a.44.44 0 0 1 .062.225v3.156l2.878-3.08a.39.39 0 0 1 .568-.007.434.434 0 0 1 .006.594l-3.567 3.818a.394.394 0 0 1-.287.126z"
                />
                <path
                    fill="#FFFFFE"
                    d="M140.834 196.989c6.484-2.104 16.012 3.787 19.717 8.837 3.705 5.05 6.087.28 10.718 2.244 6.1 2.587 10.984 16.412 18.129 15.009 7.146-1.402 11.513-2.665 11.513-2.665l2.249 7.575-54.386 1.964-7.94-32.964z"
                />
                <path
                    fill="#595959"
                    d="M148.113 228.146c3.705-12.674 13.094-5.251 18.526-6.76 6.087-1.689 2.668-8.482 12.042-11.265 5.69-1.69 8.072.844 12.042-4.788 3.874-5.498 3.043-12.956 14.418-15.302 12.477-2.573 15.33 8.421 15.33 8.421l-3.275 13.268-8.993 21.689h-51.327l-8.763-5.263zm-18.319-21.229-14.315-16.701h27.082l14.315 16.701h-27.082z"
                />
                <path fill="#333" fill-opacity=".4" d="M129.793 237.921h27.082v-31.004h-27.082v31.004z" />
                <path
                    fill="#FFFFFE"
                    d="M115.479 237.921h14.315v-31.004h-14.315v31.004zm14.315-31.004-14.315-16.701v16.701h14.315z"
                />
                <path
                    fill="currentColor"
                    d="M140.535 217.859h5.823v-4.031h-5.823v4.031zm0 9.79h5.823v-4.031h-5.823v4.031z"
                />
                <path fill="#fff" d="M140.535 218.435h5.823v-1.152h-5.823v1.152zm0 9.79h5.823v-1.152h-5.823v1.152z" />
                <path fill="currentColor" d="M205.141 237.914h24.11v-47.698h-24.11v47.698z" />
                <path fill="#333" fill-opacity=".4" d="M229.252 237.914h37.967v-47.698h-37.967v47.698z" />
                <path
                    fill="#fff"
                    d="M233.203 196.551h1.582v-1.728h-1.582v1.728zm0 5.183h1.582v-1.728h-1.582v1.728zm0 4.607h1.582v-1.728h-1.582v1.728zm0 5.184h1.582v-1.728h-1.582v1.728zm0 5.183h1.582v-1.728h-1.582v1.728zm4.748-20.157h1.582v-1.728h-1.582v1.728zm0 5.183h1.582v-1.728h-1.582v1.728zm0 4.607h1.582v-1.728h-1.582v1.728zm0 5.184h1.582v-1.728h-1.582v1.728zm0 5.183h1.582v-1.728h-1.582v1.728zm4.746-20.157h1.583v-1.728h-1.583v1.728zm0 5.183h1.583v-1.728h-1.583v1.728zm0 4.607h1.583v-1.728h-1.583v1.728zm0 5.184h1.583v-1.728h-1.583v1.728zm0 5.183h1.583v-1.728h-1.583v1.728zm4.746-20.157h1.583v-1.728h-1.583v1.728zm0 5.183h1.583v-1.728h-1.583v1.728zm0 4.607h1.583v-1.728h-1.583v1.728zm0 5.184h1.583v-1.728h-1.583v1.728zm0 5.183h1.583v-1.728h-1.583v1.728zm4.748-20.157h1.583v-1.728h-1.583v1.728zm0 5.183h1.583v-1.728h-1.583v1.728zm0 4.607h1.583v-1.728h-1.583v1.728zm0 5.184h1.583v-1.728h-1.583v1.728zm0 5.183h1.583v-1.728h-1.583v1.728zm4.747-20.157h1.582v-1.728h-1.582v1.728zm0 5.183h1.582v-1.728h-1.582v1.728zm0 4.607h1.582v-1.728h-1.582v1.728zm0 5.184h1.582v-1.728h-1.582v1.728zm0 5.183h1.582v-1.728h-1.582v1.728zm4.746-20.157h1.582v-1.728h-1.582v1.728zm0 5.183h1.582v-1.728h-1.582v1.728zm0 4.607h1.582v-1.728h-1.582v1.728zm0 5.184h1.582v-1.728h-1.582v1.728zm0 5.183h1.582v-1.728h-1.582v1.728zm-28.481 5.183h1.582v-1.728h-1.582v1.728zm4.748 0h1.582v-1.728h-1.582v1.728zm4.746 0h1.583v-1.728h-1.583v1.728zm4.746 0h1.583v-1.728h-1.583v1.728zm4.748 0h1.583v-1.728h-1.583v1.728zm4.747 0h1.582v-1.728h-1.582v1.728zm4.746 0h1.582v-1.728h-1.582v1.728z"
                />
                <path
                    fill="currentColor"
                    d="M64.665 196.551c0 9.86-6.814 17.853-6.814 17.853s-6.814-7.993-6.814-17.853c0-9.86 6.814-17.853 6.814-17.853s6.814 7.993 6.814 17.853z"
                />
                <path
                    fill="#fff"
                    d="M57.85 217.283a.406.406 0 0 1-.4-.412v-23.363c0-.228.179-.412.4-.412.222 0 .402.184.402.412v23.363c0 .228-.18.412-.401.412z"
                />
                <path
                    fill="#333"
                    d="M94.24 198.567c0 9.701-6.814 17.565-6.814 17.565s-6.815-7.864-6.815-17.565 6.814-17.565 6.814-17.565 6.815 7.864 6.815 17.565z"
                />
                <path
                    fill="#fff"
                    d="M87.427 222.467a.412.412 0 0 1-.402-.422v-23.92c0-.233.18-.422.402-.422.221 0 .401.189.401.422v23.92c0 .233-.18.422-.401.422z"
                />
                <path
                    fill="#333"
                    fill-opacity=".4"
                    d="M111.018 197.415c0 12.881-9.744 23.324-9.744 23.324s-9.745-10.443-9.745-23.324c0-12.882 9.745-23.324 9.745-23.324s9.744 10.442 9.744 23.324z"
                />
                <path
                    fill="#fff"
                    d="M101.274 224.77a.412.412 0 0 1-.401-.422v-31.407c0-.233.18-.421.401-.421.222 0 .402.188.402.421v31.407c0 .233-.18.422-.402.422z"
                />
                <path
                    fill="#333"
                    fill-opacity=".2"
                    d="M106.72 231.459c-6.087-1.433-2.668-7.194-12.042-9.556-5.69-1.433-9.96 3.827-14.88.897-6.691-3.984-9.155-7.244-20.632-7.244-7.25 0-25.651.724-32.332 22.145h88.643v-6.864c-3.136.26-6.339 1.192-8.757.622z"
                />
                <path
                    fill="currentColor"
                    d="M238.656 98.072c0-4.022-3.066-7.282-6.848-7.282-2.014 0-3.82.93-5.073 2.403-.118-3.912-3.129-7.047-6.837-7.047-1.032 0-2.007.25-2.885.685.001-.052.007-.104.007-.157 0-4.022-3.066-7.282-6.848-7.282-1.542 0-2.96.549-4.105 1.464-.354-3.669-3.269-6.53-6.812-6.53-1.751 0-3.344.705-4.555 1.856-1.005-2.694-3.472-4.6-6.363-4.6-3.606 0-6.555 2.966-6.821 6.73a6.502 6.502 0 0 0-3.5-1.032c-3.782 0-6.848 3.26-6.848 7.282v13.51h67.488z"
                />
                <path
                    fill="#333"
                    d="M328.772 104.408c-.778-3.12-3.439-5.421-6.611-5.421-1.543 0-2.961.55-4.106 1.466-.354-3.675-3.268-6.541-6.811-6.541-1.752 0-3.345.706-4.556 1.859-1.005-2.698-3.472-4.609-6.362-4.609-3.607 0-6.556 2.972-6.822 6.742a6.494 6.494 0 0 0-3.5-1.033c-3.782 0-6.848 3.266-6.848 7.295v.242h45.616zM82.047 73.597c0 4.294-3.3 7.775-7.371 7.775-4.071 0-7.371-3.481-7.371-7.775 0-4.294 3.3-7.775 7.371-7.775 4.071 0 7.371 3.481 7.371 7.775z"
                />
                <path
                    fill="currentColor"
                    d="M77.654 70.542c1.644 1.687 1.644 4.422 0 6.109a4.135 4.135 0 0 1-5.954 0c-1.644-1.687-1.644-4.422 0-6.109a4.135 4.135 0 0 1 5.954 0z"
                />
                <path
                    fill="#FFFFFE"
                    d="M75.667 72.579a1.466 1.466 0 0 1 0 2.036 1.378 1.378 0 0 1-1.984 0 1.466 1.466 0 0 1 0-2.036 1.378 1.378 0 0 1 1.984 0z"
                />
                <path
                    fill="currentColor"
                    d="M211.043 56.608a.423.423 0 0 1-.287-.11l-1.075-.998a.342.342 0 0 1 .006-.516.431.431 0 0 1 .568.005l.788.733.788-.733a.431.431 0 0 1 .568-.005c.158.14.161.372.006.515l-1.075.999a.423.423 0 0 1-.287.11z"
                />
                <path
                    fill="#595959"
                    d="M217.678 59.487a.37.37 0 0 1-.287-.146l-1.075-1.331a.56.56 0 0 1 .006-.688c.158-.187.412-.184.567.008l.789.976.788-.976c.155-.192.409-.195.567-.008a.56.56 0 0 1 .007.688l-1.075 1.33a.371.371 0 0 1-.287.147zm-19.85 1.728a.423.423 0 0 1-.287-.11l-1.075-.998a.342.342 0 0 1 .006-.516.431.431 0 0 1 .568.006l.788.732.788-.732a.431.431 0 0 1 .568-.006.342.342 0 0 1 .006.516l-1.075.998a.423.423 0 0 1-.287.11z"
                />
                <path
                    fill="currentColor"
                    d="M155.916 57.184a.371.371 0 0 1-.287-.147l-1.075-1.331a.562.562 0 0 1 .006-.688.354.354 0 0 1 .568.008l.788.976.788-.976a.354.354 0 0 1 .568-.008.562.562 0 0 1 .006.688l-1.075 1.331a.371.371 0 0 1-.287.147z"
                />
                <path
                    fill="#333"
                    d="M152.285 62.367a.371.371 0 0 1-.287-.146l-1.075-1.331a.562.562 0 0 1 .006-.688.354.354 0 0 1 .568.008l.788.976.788-.976a.354.354 0 0 1 .568-.008.562.562 0 0 1 .006.688l-1.075 1.33a.371.371 0 0 1-.287.147z"
                />
                <path
                    fill="currentColor"
                    d="M143.232 71.581a.423.423 0 0 1-.287-.11l-1.075-.998a.341.341 0 0 1 .007-.515.43.43 0 0 1 .567.005l.788.732.789-.732a.43.43 0 0 1 .567-.005.34.34 0 0 1 .006.515l-1.075.999a.422.422 0 0 1-.287.11zm79.407-2.879a.37.37 0 0 1-.287-.146l-1.075-1.331a.56.56 0 0 1 .006-.688c.158-.188.412-.184.567.008l.789.976.788-.976c.155-.192.409-.196.567-.008a.56.56 0 0 1 .007.688l-1.075 1.33a.372.372 0 0 1-.287.147z"
                />
                <path
                    fill="#333"
                    d="M118.297 72.487a7.262 7.262 0 0 0-3 .65c-.218-2.804-2.492-5.011-5.271-5.011-2.786 0-5.064 2.218-5.273 5.032a8.239 8.239 0 0 0-2.535-.399c-4.064 0-7.457 2.951-8.277 6.885h31.805c-.271-3.998-3.498-7.157-7.449-7.157zm156.617 4.032a7.254 7.254 0 0 0-2.999.649c-.219-2.804-2.493-5.01-5.272-5.01-2.786 0-5.064 2.217-5.272 5.03a8.248 8.248 0 0 0-2.535-.397c-4.065 0-7.458 2.95-8.277 6.884h31.805c-.272-3.998-3.499-7.156-7.45-7.156z"
                />
            </g>
        </svg>
    </ng-template>
</div>
