<div
    class="media-object"
    [ngClass]="{
        'media-object--stacked': stacked === true,
        'media-object--stacked-responsive': stacked === 'responsive',
        'media-object--stacked-responsive-reverse': stacked === 'responsive-reverse',
        'media-object--centered': centered,
        'media-object--gutter-small': gutterSize === 'small',
        'media-object--gutter-none': gutterSize === 'none',
        'media-object--gutter-x-large': gutterSize === 'x-large'
    }"
>
    <div class="media-object__figure"><ng-content select="[figure], [slot='figure']"></ng-content></div>
    <div class="media-object__body"> <ng-content select="[body], [slot='body']"></ng-content> </div>
</div>
