import { NgModule } from '@angular/core';
import { NguiMapModule } from '@ngui/map';
import { UIRouterModule } from '@uirouter/angular';

import { PropertyReportLandingModule } from '../../modules/property-report-landing/property-report-landing.module';

import { AppraisalModule } from './appraisal/appraisal.module';
import { BookingFunnelModule } from './booking-funnel/booking-funnel.module';
import { CalendarFunnelModule } from './calendar-funnel/calendar-funnel.module';
import { ConversionComponent } from './conversion.component';
import { ConversionParentState } from './conversion.states';
import { PropertyReportModule } from './property-report/property-report.module';
import { RequestCallModule } from './request-call/request-call.module';

export const ConversionStates = [ConversionParentState];

@NgModule({
    imports: [
        PropertyReportModule,
        AppraisalModule,
        NguiMapModule.forRoot({ apiUrl: 'https://maps.google.com/maps/api/js?key=MY_GOOGLE_API_KEY' }),
        UIRouterModule.forChild({ states: ConversionStates }),
        RequestCallModule,
        BookingFunnelModule,
        CalendarFunnelModule,
        PropertyReportLandingModule,
    ],
    declarations: [ConversionComponent],
    entryComponents: [ConversionComponent],
    providers: [],
    exports: [],
})
export class ConversionModule {}
