import { Injectable } from '@angular/core';

@Injectable()
class DecapitaliseService {
    public capitaliseFirstLetterOfWords(string) {
        if (string) {
            let stringParts = string.split(' ');
            stringParts = stringParts.map(part => {
                return part[0].toUpperCase() + part.slice(1).toLowerCase();
            });
            return stringParts.join(' ');
        }
    }

    public address(address) {
        address.streetName = this.capitaliseFirstLetterOfWords(address.streetName);
        address.suburb = this.capitaliseFirstLetterOfWords(address.suburb);
        return address;
    }

    public any(string) {
        return this.capitaliseFirstLetterOfWords(string);
    }
}

export { DecapitaliseService };
