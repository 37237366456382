<up-container class="container u-padding-top--4xl-r">
    <div class="service-wrapper">
        <up-get-started-service class="service" [showDesktopVersion]="true">
            <div icon>
                <svg width="100" height="76" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                        <path
                            d="M70.567 11.433a4.1 4.1 0 1 1 0-8.2 4.1 4.1 0 0 1 0 8.2zm0-1.2a2.9 2.9 0 1 0 0-5.8 2.9 2.9 0 0 0 0 5.8zM59.816 48h1.2v19a5.6 5.6 0 0 1-5.6 5.6H31.75a5.6 5.6 0 0 1-5.6-5.6V9a5.6 5.6 0 0 1 5.6-5.6h23.665a5.6 5.6 0 0 1 5.6 5.6v20h-1.2V9a4.4 4.4 0 0 0-4.4-4.4H31.75a4.4 4.4 0 0 0-4.4 4.4v58a4.4 4.4 0 0 0 4.4 4.4h23.665a4.4 4.4 0 0 0 4.4-4.4V48z"
                            fill="#6274C6"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M43.79 69.83a2.539 2.539 0 1 1 0-5.076 2.539 2.539 0 0 1 0 5.077zm0-1.2a1.339 1.339 0 1 0 0-2.676 1.339 1.339 0 0 0 0 2.677zM27.316 14.199v-1.2H60.05v1.2z"
                            fill="#6274C6"
                            fill-rule="nonzero"
                        />
                        <path d="M27.79 62.897h31.535" stroke="#6274C6" stroke-width="1.2" stroke-linecap="square" />
                        <path
                            d="M37.325 9.268a.6.6 0 0 1 0-1.2h12a.6.6 0 1 1 0 1.2h-12zM11.026 40.177a.6.6 0 1 1 0-1.2h12.396a.6.6 0 0 1 0 1.2H11.026zM82.167 40.162v-1.2c8.506 0 15.4-6.895 15.4-15.4 0-8.506-6.894-15.4-15.4-15.4v-1.2c9.168 0 16.6 7.432 16.6 16.6 0 9.167-7.432 16.6-16.6 16.6z"
                            fill="#6274C6"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M69.426 40.162a.6.6 0 1 1 0-1.2h13.487v1.2H69.426zM82.267 6.963v1.2h-8.554v-1.2zM5.922 45.177a5.6 5.6 0 1 1 0-11.2 5.6 5.6 0 0 1 0 11.2zm0-1.2a4.4 4.4 0 1 0 0-8.8 4.4 4.4 0 0 0 0 8.8zM54.79 50.01c-6.406 0-11.6-5.193-11.6-11.6 0-6.406 5.194-11.6 11.6-11.6 6.407 0 11.6 5.194 11.6 11.6 0 6.407-5.193 11.6-11.6 11.6zm0-1.2c5.744 0 10.4-4.656 10.4-10.4 0-5.744-4.656-10.4-10.4-10.4-5.743 0-10.4 4.656-10.4 10.4 0 5.744 4.657 10.4 10.4 10.4z"
                            fill="#6274C6"
                            fill-rule="nonzero"
                        />
                        <path
                            #iconTick
                            class="icon-tick"
                            stroke="#FF9187"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M50 38l3 4 7-7"
                        />
                    </g>
                </svg>
            </div>

            <h2 heading class="u-text-heading--rg">Call back booked</h2>
            <div body>Thanks, we'll call you back within 1 business day.</div>
            <up-button button [fullWidth]="true" [style]="'secondary'" (onClick)="continue()"> Continue </up-button>
        </up-get-started-service>
    </div>
</up-container>
