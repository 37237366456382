import { Component, Input } from '@angular/core';

import { nav } from '../../../../common/models/nav.model';
import { isItemVisible } from '../../../../common/utilities/menu-items.utilities';

@Component({
    selector: 'up-nav-bar-item',
    templateUrl: './nav-bar-item.component.html',
    styleUrls: ['./nav-bar-item.component.scss'],
})
export class NavBarItemComponent {
    @Input() public item: nav.BarTopLevelItem;
    public isActive: boolean;
    public isItemVisible = isItemVisible;
    public isDropdownVisible: boolean;
    public focusOnFirstItemInDropdown: boolean;

    public showDropdown(focusOnFirstItem?: boolean): void {
        this.focusOnFirstItemInDropdown = focusOnFirstItem;
        this.isDropdownVisible = true;
    }

    public hideDropdown(): void {
        this.isDropdownVisible = false;
        this.focusOnFirstItemInDropdown = false;
    }

    public onItemKeyup(event: KeyboardEvent): void {
        if (event.code === 'Enter' || event.code === 'Space') {
            this.showDropdown(true);
        }
    }

    public onDropdownActiveStateChange(event: boolean): void {
        this.isActive = event;
    }
}
