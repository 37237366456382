import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { AppState } from '../../store/apps-state.model';
import { GetProperty } from '../../store/property/property.actions';
import { PropertyState } from '../../store/property/property.reducer';

@Injectable()
class PropertyService {
    //Temporary mechanism to Auto-update task list
    public internalPropertyTaskListEventEmitter = new Subject<void>();

    constructor(private store: Store<AppState>) {}

    // 'Current property' methods and state should be removed from this service and integrated into the store

    public getPropertyFromStore(): Observable<PropertyState> {
        return this.store.select('property');
    }

    public loadPropertyIntoStore(propertyId: string) {
        this.store.dispatch(new GetProperty(propertyId));
    }
}

export { PropertyService };
