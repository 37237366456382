<time class="date-time" [attr.datetime]="dateTime"
    ><span class="date-time" [title]="dateTime" [ngSwitch]="format">
        <ng-container *ngSwitchCase="'relative'">
            {{ displayTransform(dateTime | ncTimeFromNow) }}
        </ng-container>
        <ng-container *ngSwitchCase="'calendar'">{{
            displayTransform(dateTimeConvertedToDisplayTimeZone | ncCalendarTimeFormat)
        }}</ng-container>
        <ng-container *ngSwitchDefault>
            {{ displayTransform(formattedConvertedDateTime) }}
        </ng-container> </span
    ><nc-icon
        class="u-margin-left--2xs"
        *ngIf="showTimeZoneDifference"
        [size]="'small'"
        [name]="'globeFill'"
        [matTooltip]="timeZoneDifferenceTooltipDescription"
    >
    </nc-icon
></time>
