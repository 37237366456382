import { Component, HostBinding, Input } from '@angular/core';

import { ThemeService } from '../../../modules/core/services/theme.service';

type Style = 'line' | 'heartbeat' | 'none';

@Component({
    selector: 'up-content-accent',
    templateUrl: 'content-accent.component.html',
    styleUrls: ['content-accent.component.scss'],
})
export class ContentAccentComponent {
    @Input() public color: string = this.themeService.colors.brand2Regular;
    @Input() public style: Style = 'line';

    @HostBinding('style.color')
    public get textColor(): string {
        return this.color;
    }

    constructor(private themeService: ThemeService) {}
}
