<span [id]="propertyAttributeAriaDescribeById" class="u-hide-visually">Property attributes</span>
<ul
    class="attributes"
    aria-describedby="propertyAttributeAriaDescribeById"
    [class]="{
        small: size === 'small',
        'x-small': size === 'x-small'
    }"
>
    <li *ngIf="propertyType" class="attribute property-type" [class.light]="style === 'light'"> {{ propertyType }} </li>
    <li class="attribute bedrooms" [class.light]="style === 'light'">
        {{ bedrooms || '-' }}
        <span *ngIf="labelStyle === 'icon'; else bedTextLabel">
            <span class="u-hide-visually">&nbsp;bedrooms</span>
            <nc-icon
                aria-hidden="true"
                class="icon"
                [size]="(largerScreen$ | async) && size !== 'small' ? 'regular' : 'small'"
                [name]="'bed'"
                [color]="'currentColor'"
            >
            </nc-icon>
        </span>
        <ng-template #bedTextLabel> Beds </ng-template>
    </li>
    <li class="attribute" [class.light]="style === 'light'">
        {{ bathrooms || '-' }}
        <span *ngIf="labelStyle === 'icon'; else bathTextLabel">
            <span class="u-hide-visually">&nbsp;bathrooms</span>
            <nc-icon
                aria-hidden="true"
                class="icon"
                [size]="(largerScreen$ | async) && size !== 'small' ? 'regular' : 'small'"
                [name]="'bath'"
                [color]="'currentColor'"
            >
            </nc-icon>
        </span>
        <ng-template #bathTextLabel> Baths </ng-template>
    </li>
    <li class="attribute" [class.light]="style === 'light'">
        {{ parking || '-' }}
        <span *ngIf="labelStyle === 'icon'; else parkingTextLabel">
            <span class="u-hide-visually">&nbsp;parking</span>
            <nc-icon
                aria-hidden="true"
                class="icon"
                [size]="(largerScreen$ | async) && size !== 'small' ? 'regular' : 'small'"
                [name]="'car'"
                [color]="'currentColor'"
            >
            </nc-icon>
        </span>
        <ng-template #parkingTextLabel> Parking </ng-template>
    </li>
    <li *ngIf="showLandSize" class="attribute land-size" [class.light]="style === 'light'">
        <ng-container *ngIf="formattedLandSize; else oldLandSize">
            {{ formattedLandSize }}
        </ng-container>
        <ng-template #oldLandSize>
            {{ landSize }}m<sup>2</sup><span class="u-hide-visually">&nbsp;land size</span>
        </ng-template>
    </li>
</ul>
