import { ComponentType, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, DomPortal, TemplatePortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';

import { MenuContainerComponent } from '../../components/menu-container/menu-container.component';
import { NC_MENU_DATA } from '../../injection-tokens/menu-data.injection-token';
import { MenuRef } from '../../menu-ref';
import {
    MenuOptions,
    MenuOptionsWithComponentNoData,
    MenuOptionsWithComponentWithData,
    MenuOptionsWithInstance,
    MenuOptionsWithTemplate,
} from '../../models/menu-options.model';

@Injectable({ providedIn: 'root' })
export class MenuService {
    private _openedMenuRef: MenuRef;

    public get openedMenuRef(): MenuRef {
        return this._openedMenuRef;
    }

    constructor(private readonly overlay: Overlay, private readonly injector: Injector) {}

    public open<T = any>(
        options:
            | MenuOptionsWithTemplate
            | MenuOptionsWithComponentNoData
            | MenuOptionsWithComponentWithData<T>
            | MenuOptionsWithInstance,
    ): MenuRef {
        const overlayRef = this.createOverlayRef(options);
        const containerComponent = this.attachMenuContainer(overlayRef);
        const menuRef = new MenuRef(containerComponent, overlayRef);
        const portalInjector = Injector.create({
            parent: this.injector,
            providers: [
                { provide: MenuRef, useValue: menuRef },
                ...('data' in options ? [{ provide: NC_MENU_DATA, useValue: options.data }] : []),
                { provide: MenuContainerComponent, useValue: containerComponent },
            ],
        });
        const portal = (() => {
            if ('component' in options) {
                return new ComponentPortal(<ComponentType<any>>options.component, null, portalInjector);
            } else if ('instance' in options) {
                options.instance.setIsOpen(true);
                return new DomPortal(options.instance.elementRef.nativeElement);
            } else {
                return new TemplatePortal(options.template, options.viewContainerRef, undefined, portalInjector);
            }
        })();

        containerComponent.attachPortal(portal, menuRef);

        menuRef.afterClosed$.subscribe(() => {
            if (this.openedMenuRef !== menuRef) return;

            this._openedMenuRef = undefined;
        });

        if (this.openedMenuRef) {
            this.openedMenuRef.afterClosed$.subscribe(() => menuRef.containerInstance.triggerEnter());

            this.openedMenuRef.close();
        } else {
            menuRef.containerInstance.triggerEnter();
        }

        this._openedMenuRef = menuRef;

        return menuRef;
    }

    public close(): void {
        this.openedMenuRef?.close();
    }

    private createOverlayRef(options: MenuOptions): OverlayRef {
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(options.position)
                .withPositions([
                    {
                        originX: options.align || 'start',
                        originY: 'bottom',
                        overlayX: options.align || 'start',
                        overlayY: 'top',
                        offsetY: options.offsetY || 0,
                    },
                ])
                .withFlexibleDimensions(false)
                .withPush(true),
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
            hasBackdrop: true,
            backdropClass: !options.backdropVisible ? 'cdk-overlay-transparent-backdrop' : undefined,
            disposeOnNavigation: true,
        });

        overlayRef.backdropClick().subscribe(() => this.close());

        return overlayRef;
    }

    private attachMenuContainer(overlayRef: OverlayRef): MenuContainerComponent {
        const containerComponentPortal = new ComponentPortal(MenuContainerComponent);
        const containerRef = overlayRef.attach(containerComponentPortal);

        return containerRef.instance;
    }
}
