<nc-card>
    <ng-container *ngIf="loading === false; else loadingPlaceholder">
        <ng-container *ngIf="!!files?.length; else empty">
            <ng-container *ngFor="let file of files; let i = index">
                <div class="file u-text-body--sm">
                    <a
                        [href]="file.servingUrl"
                        (click)="onDownload(file.tags[0], file.id)"
                        download
                        target="_blank"
                        class="file-link"
                    >
                        <div class="u-margin-right--md">
                            <div *ngIf="file.tags[0] as tag">
                                Download {{ tag === 'Unspecified' ? 'Document' : (tag | ncPrettyMediaTag$ | async) }}
                            </div>
                            <div class="u-text-color--light"> {{ file.filename | upFilename }} </div>
                        </div>
                        <nc-icon
                            class="u-text-color--brand-primary-lighter"
                            [color]="'currentColor'"
                            [name]="'squareArrowDown'"
                        ></nc-icon>
                    </a>
                </div>
                <hr nc-hr *ngIf="i !== files.length - 1" />
            </ng-container>
        </ng-container>
        <ng-template #empty>
            <div class="file u-text-body--sm">
                <div>
                    <nc-icon
                        class="u-margin-right--xs u-text-color--x-light"
                        [color]="'currentColor'"
                        [name]="'folderFill'"
                    ></nc-icon>
                    There are no documents or reports available
                </div>
            </div>
        </ng-template>
    </ng-container>
</nc-card>

<ng-template #loadingPlaceholder>
    <div *ngFor="let _ of 3 | sequentialArray">
        <div class="file">
            <div class="u-margin-right--md">
                <up-placeholder class="u-margin-bottom--sm" [width]="'250px'" [height]="'18px'"></up-placeholder>
                <up-placeholder [width]="'300px'" [height]="'18px'"></up-placeholder>
            </div>
            <div>
                <up-placeholder [width]="'24px'" [height]="'24px'"></up-placeholder>
            </div>
        </div>
        <hr nc-hr />
    </div>
</ng-template>
