import { Component, Input, OnInit } from '@angular/core';

import { nav } from '../../../common/models/nav.model';

@Component({
    selector: 'up-nav-minimal',
    templateUrl: './nav-minimal.component.html',
})
/** @deprecated Use NavBasicComponent instead */
export class NavMinimalComponent implements OnInit {
    @Input() public backgroundColor: string;
    @Input() public sticky: boolean;
    public config: nav.Config;

    public ngOnInit(): void {
        this.config = {
            bar: {
                style: {
                    noPane: true,
                    backgroundColor: this.backgroundColor,
                    sticky: this.sticky,
                },
                home: {
                    uiSref: 'home',
                },
            },
        };
    }
}
