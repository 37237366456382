import { Transition } from '@uirouter/angular';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AddressT } from '../../../common/models/address.model';
import { AddressService } from '../../../common/services/address.service';
import { PropertyResource } from '../../../modules/core/resources/property.resource';
import { SuburbResource } from '../../../modules/core/resources/suburb.resource';

export function userResolveFn(transition: Transition) {
    return transition.params().user;
}

export function submissionErrorResolveFn(transition: Transition): string {
    return transition.params().submissionError;
}

export function serviceStatusResolveFn(transition: Transition) {
    return transition.params().serviceStatus;
}

export function addressResolveFn(transition: Transition) {
    return transition.params().address;
}

export function propertyAnswerTokenResolveFn(transition: Transition): string {
    return transition.params().propertyAnswerToken;
}

export function propertyIdResolveFn(transition: Transition): string {
    return transition.params().propertyId;
}

export function propertyReportDailyRequestLimitExceededResolveFn(trans): string {
    return trans.params().propertyReportDailyRequestLimitExceeded;
}

export function stepResolveFn(trans: Transition): string {
    return trans.params().step;
}

export function onEnterAddFacebookTrackingParams(transition: Transition): Promise<any> | true {
    const params = transition.params();
    const hasTrackingParams = !!(params.suburb && params.agent);
    const address = <AddressT>params.address;
    const hasInvalidAddress: boolean = !address || !(address && address.majorMunicipality && address.postalArea);
    // Prevent transition loops by checking if there's already has tracking params of if has invalid address
    if (hasTrackingParams || hasInvalidAddress) return true;

    const stateService = transition.router.stateService;
    const injector = transition.injector();
    const addressService: AddressService = injector.get(AddressService);
    const suburbResource: SuburbResource = injector.get(SuburbResource);
    const suburb = addressService.createSuburbIdFromAddress(params.address);

    return suburbResource
        .getSuburbAgent(suburb, address.geoLocation)
        .pipe(
            // If there's an agent then add to route, otherwise just proceed as usual
            map(response =>
                response.agent
                    ? stateService.target(transition.$to(), {
                        ...params,
                        suburb,
                        agent: `${response.agent.id.slice(0, 8)}`,
                    })
                    : true,
            ),
            // Ensure to proceed as usual in the case that the api call fails
            catchError(() => of(true)),
        )
        .toPromise();
}

export function BuyerListResolveFn(propertyResource: PropertyResource, propertyId: string) {
    return propertyResource.listBuyers(propertyId).toPromise();
}

export function BuyerIdResolveFn(trans) {
    return trans.params().buyerId;
}
