<div class="u-text-body--lg u-text-weight--semi-bold u-margin-bottom--sm">Or give us a call</div>
<div class="u-margin-bottom--lg">We're available 24/7 for all your enquiries</div>
<div class="flip-button-wrapper">
    <button
        nc-button
        class="flip-button show-number"
        [@flipHide]="isShowingNumber"
        (click)="showNumber()"
        [dataEvent]="trackingName && { name: trackingName, subname: 'serviceShowNumber' }"
        [style]="'secondary-dark'"
    >
        Show number
    </button>
    <a
        nc-button
        class="flip-button"
        [@flipShow]="isShowingNumber"
        [href]="'tel:1800877433'"
        [dataEvent]="trackingName && { name: trackingName, subname: 'serviceCall' }"
        [style]="'secondary-dark'"
    >
        Call 1800 877 433
    </a>
</div>
