import { HttpParameterCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * Due to an existing bug in Angular where plus symbols are stripped out of HttpParams objects, this class
 * implements a custom HTTP param encoder using the native browser functions. This encoder should be used
 * in all places where URL params are passed through HttpClient.
 *
 * See these GitHub issues for more information:
 * https://github.com/angular/angular/issues/11058
 * https://github.com/angular/angular/issues/18261
 */
@Injectable()
export class CustomQueryEncoder implements HttpParameterCodec {
    public encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    public encodeValue(value: string): string {
        return encodeURIComponent(value);
    }

    public decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    public decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}
