import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { AddressT } from '../../../../common/models/address.model';
import { agent } from '../../../../common/models/agent.model';
import { ConversionFacade } from '../../../../store/conversion/conversion.facade';
import { AppraisalState } from '../../../../store/conversion/conversion.reducer';

@Component({
    selector: 'up-calendar-enter-address',
    templateUrl: 'calendar-funnel-enter-address.component.html',
})
export class CalendarFunnelEnterAddressComponent implements OnInit, OnDestroy {
    @Input() public step: number;
    public appraisalState$: Observable<AppraisalState>;
    public agent$: Observable<agent.CalendarAgent>;
    public showHint: boolean;
    private address: AddressT;
    private destroy$ = new Subject<void>();

    constructor(private stateService: StateService, private conversionFacade: ConversionFacade) {}

    public ngOnInit(): void {
        this.initObservables();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public next(): void {
        if (!this.address) {
            this.showHint = true;
            return;
        }

        this.conversionFacade.setAddress(this.address);
        this.stateService.go('^.address-found', { address: this.address });
    }

    public onAddressUpdated(address: AddressT | undefined): void {
        this.address = address;
        this.showHint = false;
    }

    private initObservables(): void {
        this.appraisalState$ = this.conversionFacade.appraisalState$.pipe(takeUntil(this.destroy$));

        this.appraisalState$.subscribe(({ bookedTime }) => {
            if (!bookedTime) {
                this.stateService.go('^.book-time');
            }
        });

        this.agent$ = this.appraisalState$.pipe(map(({ agent }) => agent));
    }
}
