<up-pill
    class="rating u-text-body--sm"
    [color]="upColorConstants.ratemyagentStar"
    [size]="size === 'regular' ? 'small' : 'large'"
>
    <up-icon
        class="star u-text-color--inverse u-margin-right--xs"
        [name]="'starFill'"
        [color]="'current-color'"
        [size]="'small'"
    ></up-icon>
    <span class="u-text-color--inverse">{{ rating | number : '1.1-1' }}</span>
</up-pill>
