import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, BrowserTransferStateModule, Meta, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NguiMapModule } from '@ngui/map';
import { NgxsModule } from '@ngxs/store';
import { Ng2StateDeclaration, UIRouterModule, UrlService } from '@uirouter/angular';
import { NgxCurrencyModule } from 'ngx-currency';
import { LottieModule } from 'ngx-lottie';
import { ToastrModule } from 'ngx-toastr';

import { UpsideCommonModule } from './common/common';
import { CUSTOM_CURRENCY_MASK_CONFIG } from './common/constants/custom-currency-mask-config';
import { XhrGetSvgCacheBustHttpInterceptor } from './common/http-interceptors/xhr-get-svg-cache-bust/xhr-get-svg-cache-bust.http-interceptor';
import { ErrorHandlerService } from './common/services/error-handler.service';
import { PropertyService } from './common/services/property.service';
import { UtmService } from './common/services/utm.service';
import { WindowRef } from './common/services/window.service';
import { SharedModule } from './common/shared.module';
import { isProduction } from './common/utilities/is-production.util';
import { FooterModule } from './components/footer/footer.module';
import { NavModule } from './components/nav/nav.module';
import { futureStates } from './future-states';
import { intercomInitializerFactory } from './initializers/intercom.initializer';
import { sharedInitializerFactory } from './initializers/shared.initializer';
import { utmTrackerInitializerFactory } from './initializers/utm-tracking.initializer';
import { activePipeOauthSuccessFutureStates } from './modules/active-pipe-oauth-success/active-pipe-oauth-success.future-states';
import { CoreModule } from './modules/core/core.module';
import { CoalescingComponentFactoryResolver } from './modules/core/services/coalescing-component-factory-resolver.service';
import { DemonstrationModule } from './modules/demonstration/demonstration.module';
import { FacebookPixelModule } from './modules/facebook-pixel/facebook-pixel.module';
import { IntercomModule } from './modules/intercom/intercom.module';
import { IntercomService } from './modules/intercom/service/intercom/intercom.service';
import { NavigationModule } from './modules/navigation/navigation.module';
import { PermissionsModule } from './modules/permissions/permissions.module';
import { PrintModule } from './modules/print/print.module';
import { ScriptInjectorModule } from './modules/script-injector/script-injector.module';
import { SharedVendorModule } from './modules/shared-vendor/shared-vendor.module';
import { TranslocoRootModule } from './modules/transloco-root/transloco-root.module';
import { UserModule } from './modules/user/user.module';
import { VendorAppModule } from './modules/vendor-app/vendor-app.module';
import { RootComponent } from './root.component';
import { ConversionEffects } from './store/conversion/conversion.effects';
import { conversionReducer } from './store/conversion/conversion.reducer';
import { InternalPropertyEffects } from './store/internal-property/internal-property.effects';
import { internalPropertyReducer } from './store/internal-property/internal-property.reducer';
import { InternalSuppliersEffects } from './store/internal-suppliers/internal-suppliers.effects';
import { internalSuppliersReducer } from './store/internal-suppliers/internal-suppliers.reducer';
import { InternalUserEffects } from './store/internal-user/internal-user.effects';
import { internalUserReducer } from './store/internal-user/internal-user.reducer';
import { PropertyEffects } from './store/property/property.effects';
import { propertyReducer } from './store/property/property.reducer';
import { SuburbsEffects } from './store/suburbs/suburbs.effects';
import { suburbsReducer } from './store/suburbs/suburbs.reducer';
import { TaskEffects } from './store/task/task.effects';
import { taskReducer } from './store/task/task.reducer';
import { sidenavReducer } from './store/ui/sidenav/sidenav.reducer';
import { UserEffects } from './store/user/user.effects';
import { userReducer } from './store/user/user.reducer';
import { RootUIRouterConfigFn } from './ui-router/root-ui-router.config';

export const states: Ng2StateDeclaration[] = [...futureStates, ...activePipeOauthSuccessFutureStates];

// Allows lazy loading of lottie player
export function lottiePlayerFactory() {
    return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
    imports: [
        CommonModule,
        CoreModule.forRoot(),
        BrowserModule.withServerTransition({ appId: 'nc-app' }),
        BrowserTransferStateModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgxCurrencyModule.forRoot(CUSTOM_CURRENCY_MASK_CONFIG),
        NguiMapModule.forRoot({ apiUrl: 'https://maps.google.com/maps/api/js?key=MY_GOOGLE_API_KEY' }),
        UIRouterModule.forRoot({
            states,
            useHash: false,
            deferInitialRender: true,
            config: RootUIRouterConfigFn,
        }),
        ToastrModule.forRoot({
            closeButton: true,
            progressBar: true,
            autoDismiss: true,
            positionClass: 'toast-bottom-right',
            maxOpened: 10,
        }),
        FormsModule,
        UserModule,
        UpsideCommonModule,
        NavModule,
        FooterModule,
        StoreModule.forRoot({
            sidenav: sidenavReducer,
            property: propertyReducer,
            tasks: taskReducer,
            suburbs: suburbsReducer,
            internalUser: internalUserReducer,
            conversion: conversionReducer,
            internalProperty: internalPropertyReducer,
            user: userReducer,
            internalSuppliers: internalSuppliersReducer,
        }),
        EffectsModule.forRoot([
            PropertyEffects,
            TaskEffects,
            SuburbsEffects,
            InternalUserEffects,
            ConversionEffects,
            InternalPropertyEffects,
            UserEffects,
            InternalSuppliersEffects,
        ]),
        SharedModule,
        SharedVendorModule,
        NavigationModule.forRoot(),
        VendorAppModule,
        DemonstrationModule,
        ScriptInjectorModule.forRoot(),
        FacebookPixelModule.forRoot(),
        IntercomModule.forRoot(),
        PermissionsModule.forRoot(),
        NgxsModule.forRoot([], {
            developmentMode: !isProduction(),
            selectorOptions: {
                // This recommended to be disabled as it will be disabled by default in the next version of NGXS
                injectContainerState: false,
            },
        }),
        TranslocoRootModule,
        LottieModule.forRoot({ player: lottiePlayerFactory }),
        PrintModule.forRoot(),
    ],
    bootstrap: [RootComponent],
    declarations: [RootComponent],
    providers: [
        WindowRef,
        ErrorHandlerService,
        PropertyService,
        UtmService,
        Meta,
        Title,
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        {
            provide: APP_INITIALIZER,
            useFactory: utmTrackerInitializerFactory,
            deps: [UtmService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: sharedInitializerFactory,
            deps: [UrlService, Injector],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: intercomInitializerFactory,
            deps: [IntercomService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: XhrGetSvgCacheBustHttpInterceptor,
            multi: true,
        },
    ],
    exports: [],
})
export class RootModule {
    constructor(coalescingResolver: CoalescingComponentFactoryResolver) {
        coalescingResolver.init();
    }
}
