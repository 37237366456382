<a [dataEvent]="ncDataEvent" class="anchor" *ngIf="isInternal; else notInternal" [uiSref]="'internal'">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
<ng-template #notInternal>
    <a [dataEvent]="ncDataEvent" class="anchor" [uiSref]="homeRoute" *ngIf="homeRoute; else noHomeRoute">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
    <ng-template #noHomeRoute>
        <a [dataEvent]="ncDataEvent" class="anchor" [href]="homeUrl">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </a>
    </ng-template>
</ng-template>
<ng-template #content>
    <img
        class="logo"
        [src]="'/static/images/organisations/logos/#themeName#-regular.svg' | upThemeNameStringTemplate | upCdnUrl"
        alt="{{ orgName }} Home"
    />
</ng-template>
