import { createAction, props, union } from '@ngrx/store';

import { Suppliers } from '../../common/models/suppliers.model';

export const getAllSuppliers = createAction('[Internal Suppliers] Get All Suppliers');
export const getAllSuppliersSuccess = createAction(
    '[Internal Suppliers] Get All Suppliers Success',
    props<{ suppliers: Suppliers.Supplier[] }>(),
);
export const getAllSuppliersError = createAction('[Internal Suppliers] Get All Suppliers Error');

export const getPropertySuppliers = createAction(
    '[Internal Suppliers] Get Property Suppliers',
    props<{ propertyId: string }>(),
);
export const getPropertySuppliersSuccess = createAction(
    '[Internal Suppliers] Get Property Suppliers Success',
    props<{ suppliers: Suppliers.EntitySuppliers; propertyId: string }>(),
);
export const getPropertySuppliersError = createAction('[Internal Suppliers] Get Property Suppliers Error');

export const getAgentSuppliers = createAction('[Internal Suppliers] Get Agent Suppliers', props<{ agentId: string }>());
export const getAgentSuppliersSuccess = createAction(
    '[Internal Suppliers] Get Agent Suppliers Success',
    props<{ suppliers: Suppliers.EntitySuppliers; agentId: string }>(),
);
export const getAgentSuppliersError = createAction('[Internal Suppliers] Get Agent Suppliers Error');

export const updatePropertySuppliers = createAction(
    '[Internal Suppliers] Update Property Suppliers',
    props<{ propertyId: string; suppliers: Suppliers.EntitySupplierIds }>(),
);
export const updatePropertySuppliersSuccess = createAction(
    '[Internal Suppliers] Update Property Suppliers Success',
    props<{ propertyId: string; suppliers: Suppliers.EntitySuppliers }>(),
);
export const updatePropertySuppliersError = createAction('[Internal Suppliers] Update Property Suppliers Error');

export const updateAgentSuppliers = createAction(
    '[Internal Suppliers] Update Agent Suppliers',
    props<{ agentId: string; suppliers: Suppliers.EntitySupplierIds }>(),
);
export const updateAgentSuppliersSuccess = createAction(
    '[Internal Suppliers] Update Agent Suppliers Success',
    props<{ agentId: string; suppliers: Suppliers.EntitySuppliers }>(),
);
export const updateAgentSuppliersError = createAction('[Internal Suppliers] Update Agent Suppliers Error');

export const resetCachedSuppliers = createAction('[Internal Suppliers] Reset Cached Suppliers');

const actions = union({
    getAllSuppliers,
    getAllSuppliersSuccess,
    getAllSuppliersError,
    getPropertySuppliers,
    getPropertySuppliersSuccess,
    getPropertySuppliersError,
    updatePropertySuppliers,
    updatePropertySuppliersSuccess,
    updatePropertySuppliersError,
    updateAgentSuppliers,
    updateAgentSuppliersSuccess,
    updateAgentSuppliersError,
    getAgentSuppliers,
    getAgentSuppliersSuccess,
    getAgentSuppliersError,
    resetCachedSuppliers,
});

export type ActionsUnion = typeof actions;
