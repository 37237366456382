import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';

type RadioInputStyle = 'inline' | 'boxed' | 'boxed-borderless' | 'tab' | 'card' | 'fullWidth' | 'pill';
enum Alignment {
    Center = 'center',
    Top = 'top',
}

let idCounter = 0;

@Component({
    selector: 'up-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
})
export class RadioComponent implements OnInit, OnDestroy {
    @Input() public id: string;
    @Input() public value: any;
    @Input() public name: string;
    @Input() public style: RadioInputStyle = 'inline';
    @Input() public disabled: boolean;
    @Input() public alignment: Alignment = Alignment.Center;
    @HostBinding('style.vertical-align') public verticalAlign: string;
    @HostBinding('class.is-full-width') public isFullWidth: boolean;
    public isFocused: boolean;
    public alignmentEnum = Alignment;
    // To be used by radio-group
    public _onInputChange = new EventEmitter<any>();
    public checked: boolean;

    constructor(private changeDetector: ChangeDetectorRef) {
        this.id = this.id || `up-radio-${idCounter}`;
        // Keep track of how many instances have been created to prevent ID clashes
        idCounter++;
    }

    public ngOnInit(): void {
        if (this.style === 'boxed' || this.style === 'boxed-borderless' || this.style === 'fullWidth') {
            this.isFullWidth = true;
        }

        this.verticalAlign = this.style === 'card' ? 'bottom' : undefined;
    }

    public onInputChange(event: Event): void {
        event.stopPropagation();
        this.checked = true;
        this._onInputChange.emit(this.value);
    }

    // Stops a click on the hidden input as we are wrapping it in an associated label element which will also trigger
    // a click event to a child input element, causing multiple clicks form being fired.
    public onInputClick(event: Event): void {
        event.stopPropagation();
    }

    public onFocus(): void {
        this.isFocused = true;
    }

    public onBlur(): void {
        this.isFocused = false;
    }

    public markForCheck(): void {
        this.changeDetector.markForCheck();
    }

    public ngOnDestroy(): void {
        this._onInputChange.complete();
    }

    public setValue(value: boolean): void {
        this.checked = value;
    }
}
