import { NgModule } from '@angular/core';

import { ForceExternalThemeComponent } from './components/force-external-theme/force-external-theme.component';
import { ColorPipe } from './pipes/color/color.pipe';

@NgModule({
    exports: [ColorPipe, ForceExternalThemeComponent],
    declarations: [ColorPipe, ForceExternalThemeComponent],
    providers: [ColorPipe],
})
export class ThemeModule {}
