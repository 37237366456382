import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { HistoryState } from '../../../../common/services/history.service';
import { GetStartedFacade } from '../store/get-started.facade';

@Component({
    selector: 'up-nav-get-started',
    templateUrl: 'nav-get-started.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavGetStartedComponent implements OnInit, OnDestroy {
    public returnTo: HistoryState = { name: 'home' };
    private destroy$: Subject<void> = new Subject<void>();

    constructor(private getStartedFacade: GetStartedFacade, private changeDetectorRef: ChangeDetectorRef) {}

    public ngOnInit(): void {
        this.setReturnTo();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private setReturnTo(): void {
        this.getStartedFacade.returnTo$
            .pipe(
                filter(returnTo => !!(returnTo && returnTo.name)),
                takeUntil(this.destroy$),
            )
            .subscribe(returnTo => {
                this.returnTo = returnTo;
                this.changeDetectorRef.detectChanges();
            });
    }
}
