import { NgModule } from '@angular/core';

import { SharedVendorModule } from '../shared-vendor/shared-vendor.module';

import { ChooseYourOwnAdventureComponent } from './components/choose-your-own-adventure/choose-your-own-adventure.component';
import { MediaActionPanelComponent } from './components/media-action-panel/media-action-panel.component';
import { RevealCallCtaComponent } from './components/reveal-call-cta/reveal-call-cta.component';

@NgModule({
    imports: [SharedVendorModule],
    exports: [ChooseYourOwnAdventureComponent, MediaActionPanelComponent],
    declarations: [RevealCallCtaComponent, ChooseYourOwnAdventureComponent, MediaActionPanelComponent],
})
export class MarketingChooseYourOwnAdventureModule {}
