<button
    class="button"
    (click)="click()"
    [ngClass]="{
        'is-active': active,
        'is-disabled': disabled
    }"
    [attr.aria-label]="ariaLabel"
>
    <div class="content">
        <div class="day"> {{ date.format('ddd') }} </div> <div class="date"> {{ date.format('D') }} </div>
        <div class="month"> {{ date.format('MMM') }} </div>
    </div>
    <div class="frame"> <div class="notch"></div> </div>
</button>
