import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

import { UP_COLOR_CONSTANTS, UpColorConstants } from '../../../../common/constants/colors.constants';

@Component({
    selector: 'up-address-pin',
    templateUrl: './address-pin.component.html',
    styleUrls: ['./address-pin.component.scss'],
})
export class AddressPinComponent implements OnInit {
    @Input() public address: string;
    @Output() public clear = new EventEmitter<void>();
    public shouldShowClearButton: boolean;

    constructor(@Inject(UP_COLOR_CONSTANTS) public colors: UpColorConstants) {}

    public ngOnInit(): void {
        this.shouldShowClearButton = !!this.clear.observers.length;
    }

    public onClear(): void {
        this.clear.emit();
    }
}
