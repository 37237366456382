import { Injectable } from '@angular/core';
import { iif, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { user } from '../../../common/models/user.model';
import { UserService } from '../../../common/services/user.service';

import { FeatureService } from './feature.service';

@Injectable()
export class SearchService {
    constructor(private featureService: FeatureService, private userService: UserService) {}

    /**
     * Returns an Observable of the current user's ID if they are, for the purposes of the internal search
     * functionality, considered a sales manager user.
     * */
    public getSearchRestrictedSalesManagerId(): Observable<string | undefined> {
        const salesManagerCanSearchAcrossAgencyTeams$ = of(
            this.featureService.hasFeature('salesManagerSearchWithinAgency'),
        );
        const salesManagerUserId$ = this.userService.userAuthDetailsUpdated$.pipe(
            map(({ roles, id }) => {
                const isElevatedUser = roles.some(userRole =>
                    [user.Role.Staff, user.Role.SalesAdmin, user.Role.AgencyAdmin, user.Role.Admin].includes(userRole),
                );
                const isSalesManager = roles.includes(user.Role.SalesManager);

                return !isElevatedUser && isSalesManager ? id : undefined;
            }),
            first(),
        );

        return salesManagerCanSearchAcrossAgencyTeams$.pipe(
            switchMap(canSearchAcrossTeams => iif(() => canSearchAcrossTeams, of(undefined), salesManagerUserId$)),
        );
    }
}
