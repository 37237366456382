import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { UseCase } from '../../../models/core/use-case.model';

export interface RatemyagentStats {
    rating: number;
    ratingsCount: number;
}

@Injectable({ providedIn: 'root' })
export class GetRatemyagentStatsUseCase implements UseCase<RatemyagentStats> {
    public execute(): Observable<RatemyagentStats> {
        // We currently have 2 problems with pulling this data dynamically from RMA:
        //   1. RMA are aggressively rate-limiting us.
        //   2. RMA have notified us that we are no longer permitted to use their public API, and that we must switch
        //      to their official authenticated developer API.
        // Until we can address these by handling fetching and caching the data via our own API, we've decided to
        // temporarily hard-code things in the client for the time being.
        return of({
            rating: 4.9,
            ratingsCount: 1000,
        });
    }
}
