import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SparkDelegationStatusDto } from '../../../common/models/dto/spark/spark-delegation-status.dto';
import { SparkDto } from '../../../common/models/dto/spark/spark.dto';
import { SparksDailyMetricsDto } from '../../../common/models/dto/spark/sparks-daily-metrics.dto';
import { SparkApi } from '../../../common/models/spark-api.model';

@Injectable()
export class SparkResource {
    private readonly baseUrl = '/api/v2/spark';

    constructor(private httpClient: HttpClient) {}

    public getAll() {
        return this.httpClient.get<SparkApi.All.GET.Response>(`${this.baseUrl}`);
    }

    public getForAgent(agentId: string) {
        return this.httpClient.get<SparkApi.All.GET.Response>(`${this.baseUrl}/agent/${agentId}`);
    }

    public getById(sparkId: string) {
        return this.httpClient.get<SparkApi.ById.GET.Response>(`${this.baseUrl}/${sparkId}`);
    }

    public triggerAdditionalForAgent(agentId: string, body: SparkApi.Additional.POST.Request) {
        return this.httpClient.post<SparkApi.Additional.POST.Response>(
            `${this.baseUrl}/agent/${agentId}/additional`,
            body,
        );
    }

    public bulkUpdate(body: SparkApi.BulkUpdate.POST.Request) {
        return this.httpClient.patch<SparkApi.BulkUpdate.POST.Response>(`${this.baseUrl}`, body);
    }

    public getDelegatedForAgent(agentId: string, params?: SparkApi.DelegatedForAgent.GET.Request) {
        let httpParams = new HttpParams();

        if (params) {
            const { suburbIds, claimed, claimedBy, leadScores, hasFailedCallAttempts } = params;

            if (suburbIds) {
                suburbIds.forEach(item => (httpParams = httpParams.append('suburbIds', item)));
            }

            if (claimed?.toString()) {
                httpParams = httpParams.set('claimed', claimed.toString());
            }

            if (claimedBy) {
                claimedBy.forEach(item => (httpParams = httpParams.append('claimedBy', item)));
            }

            if (leadScores) {
                leadScores.forEach(item => (httpParams = httpParams.append('leadScores', item)));
            }

            if (hasFailedCallAttempts?.toString()) {
                httpParams = httpParams.set('hasFailedCallAttempts', hasFailedCallAttempts.toString());
            }
        }

        return this.httpClient.get<SparkApi.DelegatedForAgent.GET.Response>(`${this.baseUrl}/delegated/${agentId}`, {
            params: httpParams,
        });
    }

    public getDelegatedToConciergeForAgent(agentId: string, params?: SparkApi.DelegatedForAgent.GET.Request) {
        let httpParams = new HttpParams();

        if (params) {
            const { suburbIds, claimed, claimedBy, leadScores, hasFailedCallAttempts } = params;

            if (suburbIds) {
                suburbIds.forEach(item => (httpParams = httpParams.append('suburbIds', item)));
            }

            if (claimed?.toString()) {
                httpParams = httpParams.set('claimed', claimed.toString());
            }

            if (claimedBy) {
                claimedBy.forEach(item => (httpParams = httpParams.append('claimedBy', item)));
            }

            if (leadScores) {
                leadScores.forEach(item => (httpParams = httpParams.append('leadScores', item)));
            }

            if (hasFailedCallAttempts?.toString()) {
                httpParams = httpParams.set('hasFailedCallAttempts', hasFailedCallAttempts.toString());
            }
        }

        return this.httpClient.get<SparkApi.DelegatedForAgent.GET.Response>(
            `${this.baseUrl}/agent/${agentId}/concierge`,
            {
                params: httpParams,
            },
        );
    }

    public getByProperty(propertyId: string): Observable<SparkDto> {
        return this.httpClient.get<SparkDto>(`${this.baseUrl}/property/${propertyId}`);
    }

    public getDailyMetricsForAgent(
        agentId: string,
        delegationStatus: SparkDelegationStatusDto,
        startUnix: number,
        endUnix: number,
    ): Observable<SparksDailyMetricsDto> {
        return this.httpClient.get<SparksDailyMetricsDto>(`${this.baseUrl}/agent/${agentId}/metrics`, {
            params: {
                delegation: delegationStatus,
                start: startUnix,
                end: endUnix,
            },
        });
    }
}
