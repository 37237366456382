<div role="region">
    <div
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="1"
        tabindex="0"
        [attr.aria-live]="ariaLive"
        [attr.aria-valuenow]="value"
    >
        <span class="u-hide-visually">{{ value * 100 }}% completed</span> <div #bar class="bar"></div>
    </div>
</div>
