import { CurrencyMaskConfig } from 'ngx-currency/ngx-currency';

export const CUSTOM_CURRENCY_MASK_CONFIG: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: true,
    allowZero: true,
    decimal: '.',
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    nullable: false,
};
