<div class="inner">
    <svg class="decoration" width="53" height="41" [class.spacious-layout]="displayDensity === 'spacious'">
        <path
            d="M40.55 40.05c-3.2 0-5.725-1.125-7.575-3.375-1.85-2.25-2.775-5.275-2.775-9.075 0-5.4 1.5-10.325 4.5-14.775C37.7 8.375 42.4 4.1 48.8 0l3.3 3.75c-4.3 3.5-7.65 6.8-10.05 9.9-2.4 3.1-3.7 5.85-3.9 8.25l.6.45c.2-.4.675-.725 1.425-.975A7.304 7.304 0 0 1 42.5 21c2.3 0 4.175.875 5.625 2.625 1.45 1.75 2.175 3.975 2.175 6.675 0 2.9-.9 5.25-2.7 7.05-1.8 1.8-4.15 2.7-7.05 2.7zm-30 0c-3.2 0-5.725-1.125-7.575-3.375C1.125 34.425.2 31.4.2 27.6c0-5.4 1.5-10.325 4.5-14.775C7.7 8.375 12.4 4.1 18.8 0l3.3 3.75c-4.3 3.5-7.65 6.8-10.05 9.9-2.4 3.1-3.7 5.85-3.9 8.25l.6.45c.2-.4.675-.725 1.425-.975A7.304 7.304 0 0 1 12.5 21c2.3 0 4.175.875 5.625 2.625 1.45 1.75 2.175 3.975 2.175 6.675 0 2.9-.9 5.25-2.7 7.05-1.8 1.8-4.15 2.7-7.05 2.7z"
        />
    </svg>
    <div class="layer">
        <div
            [class.u-text-quote--xs]="displayDensity === 'extra-compact'"
            [class.u-text-quote--sm]="displayDensity !== 'extra-compact'"
        >
            <div
                class="u-text-weight--bold u-margin-bottom--md"
                *ngIf="review.title && displayDensity === 'spacious'"
                >{{ review.title }}</div
            >
            <p class="u-margin-bottom--sm">
                <ng-container
                    *ngIf="review.title && (displayDensity === 'compact' || displayDensity === 'extra-compact')"
                    ><span class="u-text-weight--bold">{{ review.title }}</span> - </ng-container
                >{{ review.text }}
            </p>
        </div>
        <div
            [class.u-text-body--sm]="displayDensity === 'extra-compact'"
            [class.u-text-body--rg]="displayDensity !== 'extra-compact'"
            *ngIf="review.reviewer && review.reviewer !== noReviewKey"
        >
            <div class="u-display--inline-block u-text-color--brand-primary u-text-weight--semi-bold">
                {{ review.reviewer }}
            </div>
        </div>
    </div>
</div>
