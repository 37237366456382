import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { buyerApi } from '../../../common/models/buyer-api.model';
import { PropertiesDto } from '../../../common/models/dto/property/properties.dto';
import { OkDto } from '../../../common/models/dto/response/ok.dto';
import { SimpleUpdateUserRequestDto } from '../../../common/models/dto/user/simple-update-user-request.dto';

const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

const BUYER_BASE_URL = '/api/v2/buyer';
const BUYERS_BASE_URL = '/api/v2/buyers';

@Injectable()
export class BuyerResource {
    constructor(private http: HttpClient) {}

    public register(buyer: buyerApi.register.post.Request) {
        return this.http.post<buyerApi.register.post.Response>(`${BUYERS_BASE_URL}/`, buyer, HEADERS_CONFIG);
    }

    public registerAndUpdateBuyer(userId: string, body: SimpleUpdateUserRequestDto): Observable<OkDto> {
        return this.http.post<OkDto>(`${BUYERS_BASE_URL}/${userId}`, body);
    }

    public buyerProperties(buyerId: string): Observable<PropertiesDto> {
        return this.http.get<PropertiesDto>(`${BUYER_BASE_URL}/${buyerId}/properties`, HEADERS_CONFIG);
    }

    public preference(buyerId: string) {
        return this.http.get<buyerApi.preference.get.Response>(
            `${BUYER_BASE_URL}/${buyerId}/preference`,
            HEADERS_CONFIG,
        );
    }

    public updatePreference(buyerId: string, preference: buyerApi.preference.post.Request) {
        return this.http.put<buyerApi.preference.post.Response>(
            `${BUYER_BASE_URL}/${buyerId}/preference`,
            preference,
            HEADERS_CONFIG,
        );
    }

    public search(params: buyerApi.search.post.Request) {
        return this.http.post<buyerApi.search.post.Response>(`${BUYER_BASE_URL}/search`, params, HEADERS_CONFIG);
    }
}
