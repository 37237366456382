<up-vertically-centered-layout>
    <up-request-call-back-form
        (formSubmit)="onFormSubmit($event)"
        [loading]="loading"
        [error]="error ? 'Whoops, your call could not be requested, please try again.' : undefined"
        [showLogo]="true"
        [agent]="agent"
    >
    </up-request-call-back-form>
</up-vertically-centered-layout>
