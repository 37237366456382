import { Pipe, PipeTransform } from '@angular/core';

import { ThemeName, ThemeService } from '../../modules/core/services/theme.service';

@Pipe({ name: 'upIsTheme' })
export class IsThemePipe implements PipeTransform {
    constructor(private themeService: ThemeService) {}

    public transform(themeName: ThemeName): boolean {
        return themeName === this.themeService.themeName;
    }
}
