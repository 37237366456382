import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import moment from 'moment';

namespace upValidators {
    export interface PhoneNumberValidator {
        invalidPhoneNumber: boolean;
    }
    export interface MomentDateValidator {
        invalidDate: boolean;
    }
    export interface StrictEmailValidator {
        invalidStrictEmail?: boolean;
    }
    export interface NoWhiteSpacesOnly {
        whiteSpacesOnly?: boolean;
    }
    export interface UrlValidator {
        invalidUrl?: boolean;
    }
    export interface ProtocolValidator {
        missingProtocol?: boolean;
    }
    export interface RatingValidator {
        invalidRating?: boolean;
    }
    export interface IntegerValidator {
        invalidInteger?: boolean;
    }
    export interface PasswordVerifyValidator {
        passwordDoesNotMatch: boolean;
    }
    export interface PasswordLengthValidator {
        passwordTooShort: boolean;
    }
    export interface DigitsOnlyValidator {
        notOnlyDigits: boolean;
    }
    export interface AllOrNothingValidator {
        allOrNothing: boolean;
    }
    export interface OneOrMoreRequiredValidator {
        oneOrMoreRequired: boolean;
    }
    export interface HighLowValidator {
        highLow: boolean;
    }
    export interface DependentFieldValidator {
        dependantFieldRequired: boolean;
    }
    export interface EitherThisOrThatRequiredValidator {
        eitherThisOrThatRequired: boolean;
    }
}

export const minPasswordLength = 6;
const minPhoneNumberLength = 8;
export class UpValidators {
    public static phoneNumberValidator(control: AbstractControl): upValidators.PhoneNumberValidator | null {
        if (!control.value) {
            return null;
        }
        // Can begin with a '+', must have at least 'minPhoneNumberLength' numbers, no other characters
        const validPhoneNumberRegex = new RegExp(`^\\+?\\d{${minPhoneNumberLength},}$`);
        const isValidPhoneNumber = control.value.match(validPhoneNumberRegex);
        return !isValidPhoneNumber ? { invalidPhoneNumber: true } : null;
    }

    public static momentDateValidator(format?: string): ValidatorFn {
        return (control: FormControl): upValidators.MomentDateValidator | null => {
            const dateValue = control.value;
            const dateFormat = format;
            if (dateFormat) {
                return moment(dateValue, dateFormat, true).isValid() ? null : { invalidDate: true };
            } else {
                return moment(dateValue).isValid() ? null : { invalidDate: true };
            }
        };
    }

    public static strictEmailValidator(control: AbstractControl): upValidators.StrictEmailValidator {
        if (!control.value) {
            return null;
        }

        // Email RegEx from: http://emailregex.com/, wraped in \s* to account for accidental spaces
        const strictEmailRegEx = new RegExp(
            /^\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
        );
        let isInvalidStrictEmail = !strictEmailRegEx.test(control.value);
        return isInvalidStrictEmail ? { invalidStrictEmail: true } : null;
    }

    public static noWhiteSpacesOnlyValidator(control: AbstractControl): upValidators.NoWhiteSpacesOnly {
        const initialLength = control.value && control.value.length;
        const isBlankString = !(control.value && control.value.trim().length);
        // initialLength check ensures this validator doesn't become Validators.required
        return initialLength && isBlankString ? { whiteSpacesOnly: true } : null;
    }

    public static urlValidator(control: AbstractControl): upValidators.UrlValidator {
        if (!control.value) {
            return null;
        }

        // URL RegEx from: https://www.regextester.com/94502
        const urlRegEx = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm);
        const isInvalidUrl = !urlRegEx.test(control.value);
        return isInvalidUrl ? { invalidUrl: true } : null;
    }

    public static protocolValidator(control: AbstractControl): upValidators.ProtocolValidator {
        const { value } = control;
        if (!value) {
            return null;
        }

        const protocolRegex = new RegExp(/^https?:\/\//gi);
        const isMissingProtocol = !protocolRegex.test(value);

        return isMissingProtocol ? { missingProtocol: true } : null;
    }

    public static ratingValidator(control: AbstractControl): upValidators.RatingValidator {
        if (!control.value) {
            return null;
        }
        const isValidRating = control.value >= 0 && control.value <= 5.0;
        return !isValidRating ? { invalidRating: true } : null;
    }

    public static integerValidator(control: AbstractControl): upValidators.IntegerValidator {
        const value = control.value;
        if (!value) return null;
        const valueAsNumber = Number(value);
        const isInteger = Number.isInteger(valueAsNumber);
        return !isInteger ? { invalidInteger: true } : null;
    }

    public static passwordVerifyValidator(group: FormGroup): upValidators.PasswordVerifyValidator {
        const fieldValues = Object.values(group.value);
        return fieldValues.every(f => f === fieldValues[0]) ? null : { passwordDoesNotMatch: true };
    }

    public static passwordLengthValidator(control: AbstractControl): upValidators.PasswordLengthValidator {
        if (!control.value) return null;
        return control.value.length >= minPasswordLength ? null : { passwordTooShort: true };
    }

    public static digitsOnlyValidator(control: AbstractControl): upValidators.DigitsOnlyValidator {
        if (!control.value) return null;

        const digitsOnlyRegEx = new RegExp(/^[0-9]*$/g);
        const notOnlyDigits = !digitsOnlyRegEx.test(control.value);

        return notOnlyDigits ? { notOnlyDigits: true } : null;
    }

    public static allOrNothingValidator(group: FormGroup): upValidators.AllOrNothingValidator {
        const fieldValues = Object.values(group.value);
        const some = fieldValues.some(isValidValue);

        return !some || (some && fieldValues.every(isValidValue)) ? null : { allOrNothing: true };
    }

    public static oneOrMoreRequiredValidator(
        ...formControlNames: string[]
    ): (formGroup: FormGroup) => upValidators.OneOrMoreRequiredValidator | null {
        return (formGroup: FormGroup) => {
            if (!formControlNames.length) return null;

            const relevantValues = Object.entries(formGroup.value)
                .filter(([controlName]) => formControlNames.includes(controlName))
                .map(([, value]) => value);
            const hasSome = relevantValues.some(isValidValue);

            return hasSome ? null : { oneOrMoreRequired: true };
        };
    }

    public static highLowValidator(
        type: 'high' | 'low',
        otherControlName: string,
        isEqualValueAllowed = false,
    ): (formControl: FormControl) => null | upValidators.HighLowValidator {
        let previousValue: unknown;

        return (formControl: FormControl) => {
            const parentFormGroup = formControl.parent;
            const otherControl = parentFormGroup instanceof FormGroup && parentFormGroup.get(otherControlName);

            if (!otherControl) return null;

            // Controls don't have their validity checked again if a sibling control value is changed. Typically, this
            // isn't an issue since control validity is usually isolated, but in this case we need to trigger validation
            // on the other control if the target control value has changed, since this will impact the validity of
            // the other control.
            if (previousValue !== formControl.value) {
                previousValue = formControl.value;
                otherControl.updateValueAndValidity();
            }

            const myValue = formControl.value;
            const otherValue = otherControl.value;

            if (!myValue || !otherValue) return null;

            const highValue = type === 'high' ? myValue : otherValue;
            const lowValue = type === 'low' ? myValue : otherValue;
            const isInvalid = highValue < lowValue || (!isEqualValueAllowed && highValue === lowValue);

            return isInvalid ? { highLow: true } : null;
        };
    }

    public static dependentFieldValidator(
        otherControlName: string,
    ): (formControl: FormControl) => null | upValidators.DependentFieldValidator {
        let previousValue: unknown;

        return (formControl: FormControl) => {
            const parentFormGroup = formControl.parent;
            const otherControl = parentFormGroup instanceof FormGroup && parentFormGroup.get(otherControlName);

            if (!otherControl) return null;

            // Controls don't have their validity checked again if a sibling control value is changed. Typically, this
            // isn't an issue since control validity is usually isolated, but in this case we need to trigger validation
            // on the other control if the target control value has changed, since this will impact the validity of
            // the other control.
            if (previousValue !== formControl.value) {
                previousValue = formControl.value;
                otherControl.updateValueAndValidity();
            }

            const myValue = formControl.value;
            const otherValue = otherControl.value;

            if (isValidValue(myValue)) return null;

            return isValidValue(otherValue) ? { dependantFieldRequired: true } : null;
        };
    }

    public static eitherThisOrThatRequiredValidator(
        otherControlName: string,
    ): (formControl: FormControl) => upValidators.EitherThisOrThatRequiredValidator {
        let previousValue: unknown;

        return formControl => {
            const parentFormGroup = formControl.parent;
            const otherControl = parentFormGroup instanceof FormGroup && parentFormGroup.get(otherControlName);

            if (!otherControl) return null;

            if (previousValue !== formControl.value) {
                previousValue = formControl.value;
                otherControl.updateValueAndValidity();
            }

            const myValue = formControl.value;
            const otherValue = otherControl.value;

            if (!isValidValue(myValue) && !isValidValue(otherValue)) return { eitherThisOrThatRequired: true };

            return null;
        };
    }
}

function isValidValue(value: unknown): boolean {
    return !!value || (typeof value !== 'undefined' && value !== null && value !== '');
}
