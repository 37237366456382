import { Activity } from './activity.model';
import { EventContext } from './event-context.model';
import { lead } from './lead.model';
import { task } from './task.model';

export namespace reminder {
    export enum Type {
        Email = 'Email',
        Call = 'Call',
        Other = 'Other',
        Text = 'Text',
        /** @Deprecated - use WorkflowDatabase instead */
        WorkflowProspect = 'WorkflowProspect',
        /** @Deprecated - use WorkflowDatabase instead */
        WorkflowPipeline = 'WorkflowPipeline',
        CallPropertyReportLead = 'CallPropertyReportLead',
        /** @Deprecated - use FollowUpOnAppraisal instead */
        FollowUpOnAppraisalReport = 'FollowUpOnAppraisalReport',
        FollowUpOnAppraisal = 'FollowUpOnAppraisal',
        WeeklyCampaignReport = 'WeeklyCampaignReport',
        ReviewAppraisalBooking = 'ReviewAppraisalBooking',
        WorkflowDatabase = 'WorkflowDatabase',
        EbookFollowUpCall = 'EbookFollowUpCall',
        OrganiseAppraisalVisit = 'OrganiseAppraisalVisit',
        LogAppraisal = 'LogAppraisal',
        Concierge = 'Concierge',
    }

    interface BaseReminder {
        due: string;
        group: task.Group;
        id: string;
        note: string;
        type: Type;
        leadScore?: lead.Score;
        context?: EventContext.EnrichedContext;
    }

    export interface SimpleReminder extends BaseReminder {
        property: string;
        vendor?: {
            firstName?: string;
            lastName?: string;
            email?: string;
            phoneNumber?: string;
        };
    }

    export interface ExtendedReminder extends BaseReminder {
        cancelled: boolean;
        complete: boolean;
        history: Activity.SchedulableDisplayActivity[];
        rescheduleCount: number;
    }
}
