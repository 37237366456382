import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UrlService } from '@uirouter/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AssociatedOrganisationDto } from '../../../../common/models/dto/organisation/associated-organisation.dto';
import { environment } from '../../../../common/models/environment.model';
import { EnvironmentService } from '../../../core/services/environment.service';

interface SelectableCountry {
    displayName: string;
    shortDisplayName: string;
    targetUrl: string;
    isoAlpha2: string;
}

@Component({
    selector: 'nc-country-selector',
    templateUrl: 'country-selector.component.html',
    styleUrls: ['country-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountrySelectorComponent implements OnInit {
    public currentCountry$: Observable<SelectableCountry>;
    public selectableCountries$: Observable<SelectableCountry[]>;

    constructor(private readonly environmentService: EnvironmentService, private readonly urlService: UrlService) {}

    public ngOnInit(): void {
        this.setUpCountries();
    }

    private setUpCountries(): void {
        this.selectableCountries$ = this.environmentService.config$.pipe(
            map(
                ({ organisation }) =>
                    organisation.associatedOrganisations?.map(associatedOrg =>
                        this.mapOrganisationToSelectableCountry(associatedOrg),
                    ) || [],
            ),
        );
        this.currentCountry$ = this.environmentService.config$.pipe(
            map(({ organisation }) => this.mapOrganisationToSelectableCountry(organisation)),
        );
    }

    private mapOrganisationToSelectableCountry(
        org: AssociatedOrganisationDto | environment.Organisation,
    ): SelectableCountry {
        const targetUrl = (() => {
            const hostWithoutTrailingSlash = org.host.endsWith('/') ? org.host.slice(0, -1) : org.host;

            return `${hostWithoutTrailingSlash}${this.urlService.path()}`;
        })();

        return {
            displayName: org.country.englishName,
            shortDisplayName: org.country.isoAlpha2,
            targetUrl,
            isoAlpha2: org.country.isoAlpha2,
        };
    }
}
