<up-isolated-container [showLogo]="true">
    <ng-container heading *ngIf="passwordMode === 'set'">
        <ng-container [ngSwitch]="decodedRedirectUrl">
            <ng-container *ngSwitchCase="'my-properties'"> Track property </ng-container>
            <ng-container *ngSwitchDefault> Welcome back </ng-container>
        </ng-container>
    </ng-container>
    <ng-container heading *ngIf="passwordMode === 'reset'"> Reset your password </ng-container>

    <div pre-content class="u-margin-bottom--lg" *ngIf="passwordMode === 'set'">
        <ng-container [ngSwitch]="decodedRedirectUrl">
            <ng-container *ngSwitchCase="'dashboard'">
                Please set a password so that you can access your progress tracker.
            </ng-container>
            <ng-container *ngSwitchCase="'buyer-advantage'">
                Please set a password so that you can edit your notification settings.
            </ng-container>
            <ng-container *ngSwitchCase="'my-properties'">
                Complete your account setup to easily come back and track your property's value over time and stay on
                top of local market performance and activity.
            </ng-container>
            <ng-container *ngSwitchDefault> Set a password to access your account. </ng-container>
        </ng-container>
    </div>

    <form content class="u-padding-bottom--3xl" (ngSubmit)="submit()">
        <up-alert class="u-margin-bottom--xl" [size]="'small'" [type]="'warning'" *ngIf="tokenExpired">
            Whoops, looks like this link has expired. We have just sent you an email with a new link to set your
            password and complete your account setup.
        </up-alert>

        <up-alert class="u-margin-bottom--xl" [size]="'small'" [type]="'warning'" *ngIf="setPasswordFailed">
            We were unable to set your password.
            <ng-container *ngIf="isUpside; else notUpside"
                >Please call us on <up-link [href]="'tel:1800877433'">1800 877 433.</up-link></ng-container
            >
            <ng-template #notUpside>Please get in touch with us.</ng-template>
        </up-alert>

        <div class="u-margin-bottom--sm">
            <up-password-visibility-toggle>
                <up-input
                    [error]="shouldShowError()"
                    [type]="'password'"
                    [size]="'large'"
                    [fullWidth]="true"
                    [internalLabel]="true"
                    [label]="passwordMode === 'set' ? 'Create password' : 'New password'"
                    [formControl]="passwordControl"
                >
                </up-input>
            </up-password-visibility-toggle>
            <up-help-text
                [type]="'error'"
                *ngIf="shouldShowError()"
                class="form-message-error u-margin-top--sm"
                role="alert"
            >
                Your password must be at least 6 characters long.
            </up-help-text>
        </div>

        <up-button
            class="u-margin-top--lg"
            [style]="'primary'"
            [loading]="loading"
            [fullWidth]="true"
            [type]="'submit'"
        >
            <ng-container *ngIf="passwordMode === 'set'"> Set Password </ng-container>
            <ng-container *ngIf="passwordMode === 'reset'"> Reset Password </ng-container>
        </up-button>
    </form>
    <ng-container footer> <up-auth-footer></up-auth-footer> </ng-container>
</up-isolated-container>
