import { ChangeDetectionStrategy, Component, Host, HostBinding } from '@angular/core';

import { SelectListComponent } from '../select-list/select-list.component';

@Component({
    selector: 'button[nc-select-list-button], a[nc-select-list-button]',
    templateUrl: 'select-list-button.component.html',
    styleUrls: ['select-list-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectListButtonComponent {
    @HostBinding('class.is-appearance-minimal')
    public get isAppearanceMinimal(): boolean {
        return this.selectListComponent.isAppearanceMinimal;
    }

    @HostBinding('class.is-appearance-emphasised')
    public get isAppearanceEmphasised(): boolean {
        return this.selectListComponent.isAppearanceEmphasised;
    }

    constructor(@Host() private readonly selectListComponent: SelectListComponent) {}
}
