import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';

import { GlobalProgressBarService } from '../../../modules/core/services/global-progress-bar.service';

@Component({
    selector: 'up-global-progress-bar',
    templateUrl: './global-progress-bar.component.html',
    styleUrls: ['./global-progress-bar.component.scss'],
})
export class GlobalProgressBarComponent implements AfterViewInit {
    @ViewChild('bar', { static: false }) private bar: ElementRef;
    public value: number;
    public ariaLive: string;

    constructor(private renderer: Renderer2, private globalProgressBarService: GlobalProgressBarService) {}

    public ngAfterViewInit(): void {
        this.globalProgressBarService.progressValue.subscribe(value => {
            // Set aria-live to off if the value changes to 0, this happens when user navigates away from a page
            // with progress. We don't need to tell screen readers that the user is back at 0%.
            this.ariaLive = !value ? 'off' : 'polite';
            this.value = value;
            this.updateBar(value);
        });
    }

    private updateBar(value: number): void {
        const scaleXValue = `scaleX(${value})`;
        this.renderer.setStyle(this.bar.nativeElement, 'transform', scaleXValue);
    }
}
