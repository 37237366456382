import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { PropertyType } from '../../../../common/models/property.model';

@Component({
    selector: 'nc-header',
    templateUrl: 'header.component.html',
    styleUrls: ['header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    @Input() public loading = false;
    @Input() public propertyType: PropertyType;
    @Input() public formattedAddress?: string;
    @Input() public bedrooms: number;
    @Input() public bathrooms: number;
    @Input() public parking: number;
    @Input() public landSize: number;
    @Input() public price: string;
    @Input() public auctionDateTime: string;
    @Input() public hasFloorPlan: boolean;
    @Input() public isForSale: boolean;
    @Output() public openGallery = new EventEmitter<void>();
    @Output() public openFloorPlan = new EventEmitter<void>();

    public splitAddress(address?: string): string[] | undefined {
        return address?.split(', ');
    }
    public onOpenGallery(): void {
        this.openGallery.emit();
    }

    public onOpenFloorPlan(): void {
        this.openFloorPlan.emit();
    }
}
