import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
class CarouselService {
    //observables
    private nextSlideClickSource = new Subject<number>();
    private previousSlideClickSource = new Subject<number>();
    private skipToSlideClickSource = new Subject<number>();

    public nextSlideClicked$ = this.nextSlideClickSource.asObservable();
    public previousSlideClicked$ = this.previousSlideClickSource.asObservable();
    public skipToSlideClicked$ = this.skipToSlideClickSource.asObservable();

    public moveToNextSlide(currentSlide: number) {
        this.nextSlideClickSource.next(currentSlide);
    }

    public moveToPreviousSlide(currentSlide: number) {
        this.previousSlideClickSource.next(currentSlide);
    }

    public skipToSlide(slide: number) {
        this.skipToSlideClickSource.next(slide);
    }
}

export { CarouselService };
