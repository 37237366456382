import { UIRouter } from '@uirouter/angular';

export const reportSecretTokenUiRouterHooks = (router: UIRouter) => {
    //save secretToken to localStorage and remove it from url params
    router.transitionService.onBefore({ to: 'loggedOutDashboard' }, $transition$ => {
        if ($transition$.params().secretToken) {
            try {
                localStorage.setItem('reportSecretToken', $transition$.params().secretToken);
            } catch (err) {}
            return $transition$.router.stateService.target(
                'loggedOutDashboard',
                {
                    sharedToken: $transition$.params().sharedToken,
                    secretToken: '',
                },
                { inherit: false, notify: true },
            );
        }
    });
};
