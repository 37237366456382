import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';

import { UpsideCommonModule } from '../../../common/common';
import { SharedModule } from '../../../common/shared.module';
import { AppraisalBookerModule } from '../common/appraisal-booker/appraisal-booker.module';
import { ConversionCommonModule } from '../common/conversion-common.module';

import { BookingFunnelComponent } from './booking-funnel.component';
import { bookingFunnelStates } from './booking-funnel.states';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ConversionCommonModule,
        UpsideCommonModule,
        UIRouterModule.forChild({ states: bookingFunnelStates }),
        SharedModule,
        AppraisalBookerModule,
    ],
    exports: [],
    declarations: [BookingFunnelComponent],
    providers: [],
    entryComponents: [BookingFunnelComponent],
})
export class BookingFunnelModule {}
