import {
    ChangeDetectorRef,
    Directive,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash-es';
import { BehaviorSubject, combineLatest } from 'rxjs';

import { environment } from '../../../../common/models/environment.model';
import { EnvironmentService } from '../../../core/services/environment.service';

type EnvironmentConditions = RequireAtLeastOne<{
    country: 'AU' | 'NZ';
    orgGroup: environment.GroupedOrganisationName;
}>;

/**
 * This directive conditionally renders the host if it matches the provided conditions. At some point we may want to
 * support fallbacks, e.g. show X for Aus, and Y for everyone else. In that case, we might want to create a new
 * directive inspired by Angular's switch case directive, with an API similar to this:
 *
 * <div [ncEnvironmentSwitch]>
 *     <div *ncEnvironmentCase="{ country: 'AU' }">
 *         Only for Aus
 *     </div>
 *     <div *ncEnvironmentDefault>
 *         Everyone else
 *     </div>
 * </div>
 *
 * It's recommended to keep this as a separate directive since it's more hassle to use when only needing a single case.
 */
@UntilDestroy()
@Directive({ selector: '[ncEnvironment]' })
export class EnvironmentDirective implements OnInit, OnChanges {
    @Input() public ncEnvironment: EnvironmentConditions;
    private readonly environmentConditions$ = new BehaviorSubject<EnvironmentConditions | undefined>(undefined);

    constructor(
        private readonly viewContainerRef: ViewContainerRef,
        private readonly templateRef: TemplateRef<any>,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly environmentService: EnvironmentService,
    ) {}

    public ngOnInit(): void {
        combineLatest([this.environmentService.config$, this.environmentConditions$])
            .pipe(untilDestroyed(this))
            .subscribe(([{ organisation }, environmentConditions]) => {
                const nextTemplateRef = (() => {
                    if (!environmentConditions) {
                        throw new Error('Missing environment conditions');
                    }

                    const { country, orgGroup } = environmentConditions;

                    return (isNil(country) || organisation.country.isoAlpha2 === country) &&
                        (isNil(orgGroup) || this.environmentService.orgNameAsGrouping(organisation.name) === orgGroup)
                        ? this.templateRef
                        : undefined;
                })();

                this.updateViewRef(nextTemplateRef);
            });
    }

    public ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges['ncEnvironment']) {
            this.environmentConditions$.next(simpleChanges['ncEnvironment'].currentValue);
        }
    }

    private updateViewRef(templateRef?: TemplateRef<unknown>): void {
        this.viewContainerRef.clear();

        if (templateRef) {
            this.viewContainerRef.createEmbeddedView(templateRef);
        }

        this.changeDetectorRef.markForCheck();
    }
}
