import { ChangeDetectionStrategy, Component } from '@angular/core';

import { EnvironmentService } from '../../core/services/environment.service';

@Component({
    selector: 'nc-brand-logo',
    templateUrl: 'brand-logo.component.html',
    styleUrls: ['brand-logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandLogoComponent {
    public get orgName(): string {
        return this.environmentService.config.organisation.displayName;
    }
    constructor(private readonly environmentService: EnvironmentService) {}
}
