import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';

import { UpsideCommonModule } from '../../../common/common';
import { SharedModule } from '../../../common/shared.module';
import { AppraisalBookerModule } from '../common/appraisal-booker/appraisal-booker.module';
import { ConversionCommonModule } from '../common/conversion-common.module';

import { CalendarFunnelEnterAddressComponent } from './calendar-funnel-enter-address/calendar-funnel-enter-address.component';
import { CalendarFunnelComponent } from './calendar-funnel.component';
import { calendarFunnelStates } from './calendar-funnel.states';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ConversionCommonModule,
        UpsideCommonModule,
        UIRouterModule.forChild({ states: calendarFunnelStates }),
        SharedModule,
        AppraisalBookerModule,
    ],
    declarations: [CalendarFunnelComponent, CalendarFunnelEnterAddressComponent],
})
export class CalendarFunnelModule {}
