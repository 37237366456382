import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import dayjs from 'dayjs';

import { open } from '../../../../common/models/open.model';

export enum EventType {
    Open,
    Auction,
}

interface Event {
    start: string;
    end?: string;
    type: EventType;
    id?: string;
}

@Component({
    selector: 'nc-events-list',
    templateUrl: 'events-list.component.html',
    styleUrls: ['events-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsListComponent {
    @Input() public loading = false;
    @Input() public opens: open.Open[];
    @Input() public auctionDateTime: string;
    @Input() public agentFirstName: string;
    @Input() public agentLastName: string;
    @Input() public propertyToken: string;

    public EventType = EventType;

    public get events(): Event[] {
        const events: Event[] =
            this.opens?.map(open => ({
                start: open.dateTime,
                end: this.calculateTimeWithAddedDuration(open.dateTime, open.duration),
                type: EventType.Open,
                id: open.id,
            })) ?? [];

        if (!!this.auctionDateTime) {
            events.push({
                start: this.auctionDateTime,
                type: EventType.Auction,
            });
        }

        return events.sort((a, b) => (dayjs(a.start).isAfter(b.start) ? 1 : -1));
    }

    private calculateTimeWithAddedDuration(dateTime: string, duration: string): string {
        return dayjs(dateTime).add(dayjs.duration(duration)).toISOString();
    }
}
