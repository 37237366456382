import { Component, Input } from '@angular/core';

type GutterSize = 'none' | 'small' | 'large' | 'x-large';
type Stacked = 'responsive' | 'responsive-reverse';

@Component({
    selector: 'nc-media-object, up-media-object',
    templateUrl: './media-object.component.html',
    styleUrls: ['./media-object.component.scss'],
})
export class MediaObjectComponent {
    @Input() public centered = false;
    @Input() public stacked: boolean | Stacked = false;
    @Input() public gutterSize: GutterSize = 'large';
}
