<up-conversion-step
    id="report-property-data"
    [step]="step"
    [backRoute]="'^.address-found'"
    [heading]="'Let us know your property details'"
>
    <ng-container helpText>
        These details will help us generate a more accurate report for your property.
    </ng-container>
    <ng-container body>
        <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="u-padding-bottom--md">
                <up-input
                    [ncTestId]="'report-property-data-bedrooms-input'"
                    [formControlName]="'bedrooms'"
                    [size]="'large'"
                    [type]="'number'"
                    [fullWidth]="true"
                    [internalLabel]="true"
                    [error]="!!getFieldError('bedrooms')"
                    [label]="'Number of bedrooms'"
                >
                </up-input>
                <up-help-text [type]="'error'" *ngIf="getFieldError('bedrooms')">
                    <ng-container [ngSwitch]="getFieldError('bedrooms')">
                        <ng-container *ngSwitchCase="'max'">
                            Sorry, we can only provide estimates for properties with 20 bedrooms or less
                        </ng-container>
                        <ng-container *ngSwitchCase="'min'"> Please provide a valid number of bedrooms </ng-container>
                        <ng-container *ngSwitchCase="'required'"> Please provide the number of bedrooms </ng-container>
                        <ng-container *ngSwitchCase="'invalidInteger'">
                            Please provide only whole numbers
                        </ng-container>
                    </ng-container>
                </up-help-text>
            </div>

            <div class="u-padding-bottom--md">
                <up-input
                    [ncTestId]="'report-property-data-bathrooms-input'"
                    [formControlName]="'bathrooms'"
                    [size]="'large'"
                    [type]="'number'"
                    [fullWidth]="true"
                    [internalLabel]="true"
                    [error]="!!getFieldError('bathrooms')"
                    [label]="'Number of bathrooms'"
                >
                </up-input>
                <up-help-text [type]="'error'" *ngIf="getFieldError('bathrooms')">
                    <ng-container [ngSwitch]="getFieldError('bathrooms')">
                        <ng-container *ngSwitchCase="'max'">
                            Sorry, we can only provide estimates for properties with 20 bathrooms or less
                        </ng-container>
                        <ng-container *ngSwitchCase="'min'"> Please provide a valid number of bathrooms </ng-container>
                        <ng-container *ngSwitchCase="'required'"> Please provide the number of bathrooms </ng-container>
                        <ng-container *ngSwitchCase="'invalidInteger'">
                            Please provide only whole numbers
                        </ng-container>
                    </ng-container>
                </up-help-text>
            </div>

            <div class="u-margin-bottom--xl">
                <up-input
                    [ncTestId]="'report-property-data-car-spaces-input'"
                    [formControlName]="'carSpaces'"
                    [size]="'large'"
                    [type]="'number'"
                    [fullWidth]="true"
                    [internalLabel]="true"
                    [error]="!!getFieldError('carSpaces')"
                    [label]="'Parking'"
                >
                </up-input>
                <up-help-text [type]="'error'" *ngIf="getFieldError('carSpaces')">
                    <ng-container [ngSwitch]="getFieldError('carSpaces')">
                        <ng-container *ngSwitchCase="'max'">
                            Sorry, we can only provide estimates for properties with 20 parking spaces or less
                        </ng-container>
                        <ng-container *ngSwitchCase="'min'">
                            Please provide a valid number of parking spaces
                        </ng-container>
                        <ng-container *ngSwitchCase="'required'">
                            Please provide the number of parking spaces
                        </ng-container>
                        <ng-container *ngSwitchCase="'invalidInteger'">
                            Please provide only whole numbers
                        </ng-container>
                    </ng-container>
                </up-help-text>
            </div>
            <up-conversion-step-cta-wrapper class="u-margin-bottom--sm">
                <up-button
                    [ncTestId]="'report-property-data-submit-button'"
                    (onClick)="submit()"
                    [style]="'primary'"
                    [arrowIcon]="true"
                >
                    Next
                </up-button>
            </up-conversion-step-cta-wrapper>
            <up-conversion-step-cta-wrapper [alignment@mn]="'centered'">
                <up-button [ncTestId]="'report-property-data-skip-button'" (onClick)="skip()" [style]="'plain'">
                    Skip
                </up-button>
            </up-conversion-step-cta-wrapper>
        </form>
    </ng-container>
</up-conversion-step>
