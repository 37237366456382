import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { UIRouterModule } from '@uirouter/angular';

import { UpsideCommonModule } from '../../common/common';
import { SharedModule } from '../../common/shared.module';
import { SharedVendorModule } from '../../modules/shared-vendor/shared-vendor.module';

import { landingStates } from './landing.states';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

@NgModule({
    imports: [
        CommonModule,
        UIRouterModule.forChild({ states: landingStates }),
        UpsideCommonModule,
        SharedModule,
        SharedVendorModule,
        TranslocoModule,
    ],
    declarations: [TermsAndConditionsComponent, PrivacyPolicyComponent, UnsubscribeComponent],
    exports: [TermsAndConditionsComponent, PrivacyPolicyComponent],
})
export class LandingModule {}
