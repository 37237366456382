import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'hr[up-hr], hr[nc-hr]',
    template: '',
    styleUrls: ['horizontal-rule.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// Class name is temporary until the old up-hr and up-hr-mini components have been completely replaced
export class HorizontalRuleComponent {
    @Input() public size: 'medium' | 'full' = 'full';

    @HostBinding('class.size-full') public get isSizeFull(): boolean {
        return this.size === 'full';
    }

    @HostBinding('class.size-medium') public get isSizeMedium(): boolean {
        return this.size === 'medium';
    }
}
