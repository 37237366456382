import { Injector } from '@angular/core';
import { UIRouter } from '@uirouter/angular';

import { ErrorHandlerService } from '../common/services/error-handler.service';

import { experimentsRouterHooks } from './hooks/experiments.ui-router-hooks';
import { featureGuardUiRouterHooks } from './hooks/feature-guard.ui-router-hooks';
import { fragmentScrollHooks } from './hooks/fragment-scroll.hooks';
import { globalProgressBarUiRouterHooks } from './hooks/global-progress-bar.ui-router-hooks';
import { historyServiceUiRouterHooks } from './hooks/history-service.ui-router-hooks';
import { metaUiRouterHooks } from './hooks/meta.ui-router-hooks';
import { modalUiRouteHooks } from './hooks/modal.ui-router-hooks';
import { navigationServiceUiRouterHooks } from './hooks/navigation-service.ui-router-hooks';
import { persistFutureStateQueryParams } from './hooks/persist-future-state-query-params.ui-router-hooks';
import { prerenderCanonicalLinkUiRouterHooks } from './hooks/prerender-canonical-link.ui-router-hooks';
import { reportSecretTokenUiRouterHooks } from './hooks/report-secret-token.ui-router-hooks';
import { routeLoaderUiRouterHooks } from './hooks/route-loader.ui-router-hooks';
import { scrollTopUiRouterHooks } from './hooks/scroll-top.ui-router-hooks';
import { vendorAppThemeUiRouterHook } from './hooks/theme.ui-router-hooks';
import { vendorDashboardLegacyUrlHandler } from './hooks/vendor-dashboard-legacy-url-handler.ui-router-hooks';
import { vendorRouteAuthRestrictionUiRouterHooks } from './hooks/vendor-route-auth-restriction.ui-router-hooks';
import { zendeskVisibilityUiRouterHooks } from './hooks/zendesk-visibility-ui-router.hooks';

export function UIRouterConfigFn(router: UIRouter, injector: Injector) {
    router.stateService.defaultErrorHandler = () => {
        const errorHandlerService: ErrorHandlerService = injector.get(ErrorHandlerService);

        return error => errorHandlerService.handleTransitionError(error);
    };

    vendorRouteAuthRestrictionUiRouterHooks(router, injector);
    prerenderCanonicalLinkUiRouterHooks(router, injector);
    reportSecretTokenUiRouterHooks(router);
    zendeskVisibilityUiRouterHooks(router, injector);
    globalProgressBarUiRouterHooks(router, injector);
    historyServiceUiRouterHooks(router, injector);
    modalUiRouteHooks(router, injector);
    experimentsRouterHooks(router, injector);
    fragmentScrollHooks(router, injector);
    navigationServiceUiRouterHooks(router, injector);
    routeLoaderUiRouterHooks(router, injector);
    scrollTopUiRouterHooks(router, injector);
    vendorDashboardLegacyUrlHandler(router);
    metaUiRouterHooks(router, injector);
    persistFutureStateQueryParams(router);
    featureGuardUiRouterHooks(router);
    vendorAppThemeUiRouterHook(router, injector);
}
