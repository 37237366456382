import { UIRouter } from '@uirouter/core';

// UI Router treats future states -> lazy loaded states as a redirect, even if to the user it is indistinguishable from
// landing on a non-lazy loaded page. The problem with this is that redirects inside of UI-Router automatically strip
// non-declared query params from the URL. This can lead to a number of issues, primarily related to tracking (eg. UTM
// codes are dropped).

// These hooks will ensure that the query params persist when landing on a future state and redirecting to a lazy-loaded
// state.
export const persistFutureStateQueryParams = (router: UIRouter) => {
    let isFromFutureState: boolean;
    // Boolean that prevents the URL change from being stopped if the lazy-loaded state uses an immediate redirect.
    // Note: The transition will not be altered (and therefore the query params will be dropped) in this case, however
    // this maintains existing app behavior. Eg. landing on a non-lazy loaded state that redirects to another state
    // will also drop the query params.
    let isRedirecting: boolean;

    // Called when entering a future state
    router.transitionService.onBefore(
        { entering: state => !!state.lazyLoad },
        transition => {
            // Do nothing for dashboard and internal as it breaks sign in redirect
            const isDashboard = transition.to().name.startsWith('my-properties');
            const isInternal = transition.to().name.startsWith('internal');
            if (isDashboard || isInternal) return;

            // This flag is set in this hook because, by increasing the priority above default, we have access to check
            // whether the lazyload function is present on the state. At any point after this we don't because it will
            // have begun the transition to the lazy-loaded state, causing the future state to be deregistered.
            isFromFutureState = true;
        },
        {
            invokeLimit: 1,
            // Needs priority 1 to hook into the lazy-loading state
            // https://github.com/ui-router/angular/issues/173
            priority: 1,
        },
    );

    // Called when entering a non-future state (ie. after the future state has redirected and deregistered itself)
    router.transitionService.onBefore(
        {},
        transition => {
            if (!isFromFutureState) return;

            isRedirecting = !!transition.to().redirectTo;
        },
        {
            invokeLimit: 1,
            // This requires a lower priority as it needs to fire after the lazy-loaded state transition has begun.
            priority: 0,
        },
    );

    // Called at the end of the transition to the lazy-loaded state
    router.transitionService.onFinish(
        {},
        transition => {
            if (!isFromFutureState || isRedirecting) return;

            // If coming from a future state (and the target state is not redirecting), prevent the URL from being changed.
            // Note: if the future state and the corresponding lazy-loaded state have different URLs, the URL will not be
            // updated to reflect the lazy-loaded URL configuration.
            return transition.targetState().withOptions({
                location: false,
            });
        },
        { invokeLimit: 1 },
    );
};
