import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../../common/components/icon/icon.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { ThemeModule } from '../theme/theme.module';

import { BorderedButtonComponent } from './components/bordered-button/bordered-button.component';
import { Button2Component } from './components/button-2/button-2.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { VerticalButtonComponent } from './components/vertical-button/vertical-button.component';

@NgModule({
    imports: [SpinnerModule, CommonModule, IconModule, ThemeModule],
    declarations: [Button2Component, VerticalButtonComponent, IconButtonComponent, BorderedButtonComponent],
    exports: [Button2Component, VerticalButtonComponent, IconButtonComponent, BorderedButtonComponent],
})
export class ButtonModule {}
