import { animate, style, transition, trigger } from '@angular/animations';
import { FocusMonitor, FocusTrapFactory, ListKeyManager } from '@angular/cdk/a11y';
import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Inject,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren,
} from '@angular/core';

import { DROPDOWN_ITEMS_DATA, DropdownItemData, DropdownItemsData } from '../dropdown-items-data.token';

@Component({
    selector: 'up-dropdown-items',
    templateUrl: 'dropdown-items.component.html',
    styleUrls: ['dropdown-items.component.scss'],
    animations: [
        trigger('popIn', [
            transition(':enter', [
                style({
                    opacity: 0,
                }),
                animate(
                    '0.2s ease-in',
                    style({
                        opacity: 1,
                    }),
                ),
            ]),
            transition(':leave', [
                animate(
                    '0.2s ease-out',
                    style({
                        opacity: 0,
                    }),
                ),
            ]),
        ]),
    ],
})
export class DropdownItemsComponent implements AfterViewInit {
    @HostBinding('@popIn') public popIn;
    @ViewChild('focusables', { static: false }) public focusablesRef: ElementRef;
    @ViewChildren('focusable', { read: ElementRef }) public focusablesQL: QueryList<any>;

    public selectedOptionIndex: number;
    private keyManager: ListKeyManager<any>;

    constructor(
        @Inject(DROPDOWN_ITEMS_DATA) public dropdownItemsData: DropdownItemsData,
        private focusTrapFactory: FocusTrapFactory,
        private focusMonitor: FocusMonitor,
        public elementRef: ElementRef,
        private renderer2: Renderer2,
    ) {}

    /* Enables keyboard arrows navigation */
    @HostListener('window:keyup', ['$event'])
    public onKeyUp(keyboardEvent: KeyboardEvent) {
        if (keyboardEvent.code !== 'Tab') {
            this.keyManager.onKeydown(keyboardEvent);
            this.focusMonitor.focusVia(this.keyManager.activeItem.nativeElement, 'keyboard');
        } else {
            // 'artificially' updates the active element in case the user uses Tab instead of arrows
            this.keyManager.onKeydown(keyboardEvent);
            this.keyManager.setNextItemActive();
        }
    }

    public ngAfterViewInit(): void {
        this.setDropdownMinimumWidth();
        this.initKeyManager();
        this.selectedOptionIndex = this.dropdownItemsData.options.indexOf(this.dropdownItemsData.selected);
        this.setFocusTrap();
    }

    public onOptionSelected(option): void {
        this.dropdownItemsData.selectOption(option);
    }

    public onKeyDown(keyboardEvent: KeyboardEvent, option: DropdownItemData): void {
        switch (keyboardEvent.key) {
            case 'Enter':
                this.onOptionSelected(option);
                break;
            case 'Escape':
                this.dropdownItemsData.cancel();
                break;
            case 'ArrowDown':
            case 'ArrowUp':
                // prevent default of up / down navigation to stop page scrolling while dropdown is open
                keyboardEvent.preventDefault();
                break;
            default:
                break;
        }
    }

    private initKeyManager(): void {
        this.keyManager = new ListKeyManager(this.focusablesQL).withWrap().withVerticalOrientation(true);
    }

    private setFocusTrap(): void {
        let focusTrap = this.focusTrapFactory.create(this.focusablesRef.nativeElement);
        focusTrap.focusInitialElement();
        this.keyManager.setActiveItem(this.selectedOptionIndex);

        if (this.selectedOptionIndex !== -1) {
            // focusTrap doesn't provide an interface to focusing on any element that's not the first or last
            // so here since we know the option index, we can use our querylist and force the correct element to
            // be focused
            this.focusablesQL.toArray()[this.selectedOptionIndex].nativeElement.focus();
        }
    }

    private setDropdownMinimumWidth(): void {
        this.renderer2.setStyle(this.elementRef.nativeElement, 'min-width', this.dropdownItemsData.minWidth + 'px');
    }
}
