import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconName, Size } from '../../../../common/components/icon/icon.component';

@Component({
    selector: 'nc-hidden-content-indicator, up-hidden-content-indicator',
    templateUrl: 'hidden-content-indicator.component.html',
    styleUrls: ['hidden-content-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HiddenContentIndicatorComponent implements AfterViewInit {
    @Input() public open: boolean;
    @Input() public style: 'chevron' | 'arrow' | 'arrow-filled' = 'chevron';
    @Input() public size: Size;
    public shouldAnimate = false;

    public get iconName(): IconName {
        switch (this.style) {
            case 'chevron':
                return 'chevronDownBold';
            case 'arrow':
                return 'arrowDown';
            case 'arrow-filled':
                return 'triangleArrowDownFill';
        }
    }

    public ngAfterViewInit(): void {
        // Wait until after the component has finished its initial render before enabling animation of the indicator.
        // Otherwise, if the initial state is open, the component will animate on load which is a visually undesirable
        // effect.
        this.shouldAnimate = true;
    }
}
