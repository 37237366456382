import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export type ButtonStyle =
    | 'plain'
    | 'plain-dark'
    | 'plain-light'
    | 'primary'
    | 'primary-dark'
    | 'primary-positive'
    | 'secondary'
    | 'secondary-dark'
    | 'secondary-negative'
    | 'neutral';

@Component({
    // [up-button] selectors are deprecated, use nc-button instead
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[up-button], a[up-button], button[nc-button], a[nc-button]',
    templateUrl: 'button-2.component.html',
    styleUrls: ['button-2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Button2Component {
    @Input() public style: ButtonStyle = 'plain';
    @Input() public size: 'small' | 'medium' | 'large' = 'medium';
    @HostBinding('class.is-loading') @Input() public loading: boolean;
    private _disabled = false;

    @Input()
    public get disabled() {
        return this._disabled;
    }
    public set disabled(value: any) {
        this._disabled = coerceBooleanProperty(value);
    }

    @HostBinding('class.c-button') public isButton = true;
    @HostBinding('class.c-button--plain-dark') public get isStylePlainDark(): boolean {
        return this.style === 'plain-dark';
    }
    @HostBinding('class.c-button--plain-light') public get isStylePlainLight(): boolean {
        return this.style === 'plain-light';
    }
    @HostBinding('class.c-button--primary') public get isStylePrimary(): boolean {
        return this.style === 'primary';
    }
    @HostBinding('class.c-button--primary-dark') public get isStylePrimaryDark(): boolean {
        return this.style === 'primary-dark';
    }
    @HostBinding('class.c-button--primary-positive') public get isStylePositive(): boolean {
        return this.style === 'primary-positive';
    }
    @HostBinding('class.c-button--secondary') public get isStyleSecondary(): boolean {
        return this.style === 'secondary';
    }
    @HostBinding('class.c-button--secondary-dark') public get isStyleSecondaryDark(): boolean {
        return this.style === 'secondary-dark';
    }
    @HostBinding('class.c-button--secondary-negative') public get isStyleSecondaryNegative(): boolean {
        return this.style === 'secondary-negative';
    }
    @HostBinding('class.c-button--neutral') public get isStyleNeutral(): boolean {
        return this.style === 'neutral';
    }
    @HostBinding('class.c-button--large') public get isSizeLarge(): boolean {
        return this.size === 'large';
    }
    @HostBinding('class.c-button--small') public get isSizeSmall(): boolean {
        return this.size === 'small';
    }
    @HostBinding('attr.disabled') public get isLoading(): boolean {
        return this.loading || this.disabled || null;
    }
}
