import { Component, Input, OnChanges } from '@angular/core';

import { Size } from '../icon/icon.component';

@Component({
    selector: 'up-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnChanges {
    @Input() public value: number;
    @Input() public color?: string;
    @Input() public iconName = 'starFill';
    @Input() public size: Size;
    public totalRating = 5;
    public backgroundRatings: number[];
    public foregroundRatings: number[];

    constructor() {
        this.backgroundRatings = Array.from(Array(this.totalRating));
    }

    public ngOnChanges() {
        if (!this.value) {
            return;
        }

        const flooredValue = Math.floor(this.value);

        this.foregroundRatings = Array.from(Array(flooredValue)).map(() => 1);

        if (flooredValue < this.value) {
            this.foregroundRatings.push(this.isHalf(this.roundToHalf(this.value)) ? 0.5 : 1);
        }
    }

    public roundToHalf(number) {
        return Math.round(number * 2) / 2;
    }

    public isHalf(number) {
        return number % 1 === 0.5;
    }
}
