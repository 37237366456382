import { UpStateDeclaration } from '../../common/models/router.model';
import { featureGuard } from '../../ui-router/feature-guard';

import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

const TermsAndConditionsState: UpStateDeclaration = {
    parent: 'vendor',
    url: '/terms-and-conditions',
    name: 'terms-and-conditions',
    views: {
        'content@vendor-app': {
            component: TermsAndConditionsComponent,
        },
    },
    data: {
        meta: {
            title: 'Read our Terms & Conditions Policy',
            description: `Terms & Conditions. Sell your home with an experienced local Upside agent. Total transparency, award-winning service and great results.`,
        },
        featureGuard: featureGuard({ featureFlag: 'upsideMarketingPagesEnabled' }),
    },
};

const PrivacyPolicyState: UpStateDeclaration = {
    parent: 'vendor',
    url: '/privacy-policy',
    name: 'privacy-policy',
    views: {
        'content@vendor-app': {
            component: PrivacyPolicyComponent,
        },
    },
    data: {
        meta: {
            title: 'Upside Real Estate | Privacy Policy',
            description: `Privacy Policy. Sell your home with an experienced local Upside agent. Total transparency, award-winning service and great results.`,
        },
        featureGuard: featureGuard({ featureFlag: 'upsideMarketingPagesEnabled' }),
    },
};

const UnsubscribeState: UpStateDeclaration = {
    parent: 'vendor',
    url: '/unsubscribe',
    name: 'unsubscribe',
    views: {
        'content@vendor-app': {
            component: UnsubscribeComponent,
        },
    },
};

export const landingStates = [TermsAndConditionsState, PrivacyPolicyState, UnsubscribeState];
