import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UseCase } from '../../../../common/models/core/use-case.model';
import { LocalitySearchResource } from '../../../core/resources/locality-search.resource';
import { LocalitySearchResults } from '../../models/locality-search-results.model';

interface Input {
    query: string;
}

@Injectable({ providedIn: 'root' })
export class GetLocalitySearchResultsUseCase implements UseCase<LocalitySearchResults, Input> {
    constructor(private readonly localitySearchResource: LocalitySearchResource) {}

    public execute(input: Input): Observable<LocalitySearchResults> {
        return this.localitySearchResource.searchSuburbAndAddress(input.query).pipe(
            map(({ addresses, suburbs }) => ({
                addresses: addresses.map(({ fullAddress, id }) => ({
                    type: 'address',
                    id,
                    displayValue: fullAddress,
                })),
                suburbs: suburbs.map(({ id, name }) => ({
                    type: 'suburb',
                    id,
                    displayValue: name,
                })),
            })),
        );
    }
}
