export namespace conversion {
    export enum ValuationReason {
        Selling = 'selling',
        Buying = 'buying',
        Refinancing = 'refinancing',
        Curious = 'curious',
        Other = 'other',
    }

    export enum Funnel {
        Appraisal = 'appraisal',
        PropertyReport = 'property-report',
        Booking = 'booking',
        Calendar = 'calendar',
    }
}
