import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UseCase } from '../../../../common/models/core/use-case.model';
import { BuyerCategory } from '../../../../common/models/domain/appointment/buyer-category.model';
import { BuyerLocality } from '../../../../common/models/domain/buyer/buyer-locality.model';
import { CheckInRequestDto } from '../../../../common/models/dto/appointment/check-in-request.dto';
import { UpdateFinanceDetailsDto } from '../../../../common/models/dto/buyer/update-finance-details.dto';
import { FinanceApprovedStatusDto } from '../../../../common/models/finance-approved-status.dto';
import { AppointmentResource } from '../../../core/resources/appointment.resource';
import { CheckInUser } from '../../models/check-in-user.model';

export interface Result {
    brochureToken: string;
}

interface Finance {
    approved: boolean;
    amount?: number;
}

export interface Input {
    appointmentId: string;
    form: {
        user: CheckInUser;
        finance?: Finance;
        category: BuyerCategory.Enum;
        locality?: BuyerLocality.Model;
    };
}

@Injectable({ providedIn: 'root' })
export class CheckInUseCase implements UseCase<Result, Input> {
    constructor(private appointmentResource: AppointmentResource) {}

    public execute(input: Input): Observable<Result> {
        const { approved, amount: formAmount } = input.form.finance;
        let approvedStatus: FinanceApprovedStatusDto = 'Unspecified';
        if (typeof approved === 'boolean') {
            approvedStatus = approved ? 'Approved' : 'NotApproved';
        }
        let amount: number;
        if (typeof formAmount === 'number' && formAmount > 0 && approved) {
            amount = formAmount;
        }
        const finance: UpdateFinanceDetailsDto = {
            approvedStatus,
            amount,
        };

        const request: CheckInRequestDto = {
            ...input.form.user,
            finance,
            brochureRequested: false,
            buyerCategory: BuyerCategory.toDto(input.form.category),
            locality: input.form.locality,
        };

        return this.appointmentResource
            .checkInToOfi(input.appointmentId, request)
            .pipe(map(({ brochureToken }) => ({ brochureToken })));
    }
}
