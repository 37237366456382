import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';

import { NavHideOnBreakpointDirective } from './directives/nav-hide-on-breakpoint.directive';
import { NavComponent } from './nav/nav.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NavBarButtonComponent } from './nav-bar-button/nav-bar-button.component';
import { NavBarButtonMarketingComponent } from './nav-bar-button-marketing/nav-bar-button-marketing.component';
import { NavBarContainerComponent } from './nav-bar-container/nav-bar-container.component';
import { NavBarDividerComponent } from './nav-bar-divider/nav-bar-divider.component';
import { NavBarDropdownButtonComponent } from './nav-bar-dropdown-button/nav-bar-dropdown-button.component';
import { NavBarDropdownMarketingComponent } from './nav-bar-dropdown-marketing/nav-bar-dropdown-marketing.component';
import { NavBarDynamicCtaComponent } from './nav-bar-dynamic-cta/nav-bar-dynamic-cta.component';
import { NavBarHamburgerComponent } from './nav-bar-hamburger/nav-bar-hamburger.component';
import { NavBarLogoLinkComponent } from './nav-bar-logo-link/nav-bar-logo-link.component';
import { NavBarMarketingComponent } from './nav-bar-marketing/nav-bar-marketing.component';
import { NavPaneButtonComponent } from './nav-pane-button/nav-pane-button.component';
import { NavPaneContainerComponent } from './nav-pane-container/nav-pane-container.component';
import { NavPaneDividerComponent } from './nav-pane-divider/nav-pane-divider.component';
import { NavPaneMarketingComponent } from './nav-pane-marketing/nav-pane-marketing.component';
import { NavPanePrimaryComponent } from './nav-pane-primary/nav-pane-primary.component';
import { NavPaneSecondaryComponent } from './nav-pane-secondary/nav-pane-secondary.component';
import { NavPaneSecondaryAddressComponent } from './nav-pane-secondary-address/nav-pane-secondary-address.component';
import { NavPaneSecondaryHeaderComponent } from './nav-pane-secondary-header/nav-pane-secondary-header.component';
import { NavPaneSecondaryItemsComponent } from './nav-pane-secondary-items/nav-pane-secondary-items.component';
import { NavPaneSecondaryNestedItemsComponent } from './nav-pane-secondary-nested-items/nav-pane-secondary-nested-items.component';
import { NavigationService } from './navigation.service';

@NgModule({
    imports: [SharedModule, OverlayModule, A11yModule],
    exports: [
        NavBarComponent,
        NavBarContainerComponent,
        NavPaneContainerComponent,
        NavPanePrimaryComponent,
        NavBarButtonComponent,
        NavBarDividerComponent,
        NavPaneButtonComponent,
        NavHideOnBreakpointDirective,
        NavBarDropdownButtonComponent,
        NavPaneDividerComponent,
        NavBarButtonMarketingComponent,
        NavBarDropdownMarketingComponent,
        NavPaneMarketingComponent,
        NavBarMarketingComponent,
        NavPaneSecondaryComponent,
        NavPaneSecondaryItemsComponent,
        NavComponent,
        NavBarLogoLinkComponent,
        NavPaneSecondaryNestedItemsComponent,
        NavPaneSecondaryAddressComponent,
        NavPaneSecondaryHeaderComponent,
        NavBarDynamicCtaComponent,
    ],
    declarations: [
        NavBarComponent,
        NavBarContainerComponent,
        NavPaneContainerComponent,
        NavPanePrimaryComponent,
        NavBarButtonComponent,
        NavBarDividerComponent,
        NavPaneButtonComponent,
        NavHideOnBreakpointDirective,
        NavBarDropdownButtonComponent,
        NavBarHamburgerComponent,
        NavPaneDividerComponent,
        NavBarButtonMarketingComponent,
        NavBarDropdownMarketingComponent,
        NavBarDropdownMarketingComponent,
        NavPaneMarketingComponent,
        NavBarMarketingComponent,
        NavPaneSecondaryComponent,
        NavPaneSecondaryItemsComponent,
        NavComponent,
        NavBarLogoLinkComponent,
        NavPaneSecondaryNestedItemsComponent,
        NavPaneSecondaryAddressComponent,
        NavPaneSecondaryHeaderComponent,
        NavBarDynamicCtaComponent,
    ],
})
export class NavigationModule {
    public static forRoot(): ModuleWithProviders<NavigationModule> {
        return {
            ngModule: NavigationModule,
            providers: [NavigationService],
        };
    }
}
