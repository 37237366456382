import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from '../../store/apps-state.model';
import { LoadCalendar } from '../../store/internal-user/internal-user.actions';
import { InternalUserState } from '../../store/internal-user/internal-user.reducer';

export interface GetCalendarOptions {
    to: string;
    from: string;
}

@Injectable()
export class InternalUserService {
    constructor(private store: Store<AppState>) {}

    public getCalendar(agentId: string, options?: GetCalendarOptions): void {
        this.store.dispatch(new LoadCalendar({ agentId, options }));
    }

    public getInternalUserState(): Observable<InternalUserState> {
        return this.store.select('internalUser');
    }
}
