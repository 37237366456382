import { Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { AutocompleteComponent } from '../../../common/components/autocomplete/autocomplete.component';
import { SuburbDetails } from '../../../common/models/suburb-details.model';
import { SuburbService } from '../../../common/services/suburb.service';
import { SuburbsFacade } from '../../../store/suburbs/suburbs.facade';
import { suburbDetailsDisplayNameDisplayWith } from '../../../utilities/suburb-details-display-name-display-with.util';

type OnChangeFunction = (value: string[]) => void;

@Component({
    selector: 'up-suburbs-selector',
    templateUrl: 'suburbs-selector.component.html',
    styleUrls: ['suburbs-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // Needed for Control Value Accessor
            // eslint-disable-next-line @angular-eslint/no-forward-ref
            useExisting: forwardRef(() => SuburbsSelectorComponent),
            multi: true,
        },
    ],
})
export class SuburbsSelectorComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() public error: boolean;
    public selectedSuburbs: SuburbDetails[];
    public isLoadingSuburbList: boolean;
    public filteredSuburbs: SuburbDetails[] = [];
    public readonly maxSelectedSuburbs: number = 30;
    public suburbDetailsDisplayNameDisplayWith = suburbDetailsDisplayNameDisplayWith;
    @ViewChild('autocomplete', { static: false }) private autoComplete: AutocompleteComponent;
    private allSuburbs: SuburbDetails[];
    private suburbsLoaded$: Observable<void>;
    private onChange: OnChangeFunction;
    private destroy$ = new Subject<void>();
    private readonly maxSuburbsShown: number = 20;

    constructor(private suburbService: SuburbService, private suburbFacade: SuburbsFacade) {}

    public ngOnInit(): void {
        this.handleSuburbList();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public writeValue(selectedSuburbs: string[] = []): void {
        // Queue writing of values until suburbs has loaded
        this.suburbsLoaded$.subscribe(() => {
            this.selectedSuburbs = selectedSuburbs.map(selectedSuburbId =>
                this.allSuburbs.find(({ id }) => id === selectedSuburbId),
            );

            // If suburbs have been filtered out (for being invalid / not found in the suburb lists),
            // make sure to let the controlling form element know that the list has changed.
            if (selectedSuburbs.length !== this.selectedSuburbs.length) {
                this.triggerOnChangeWithSuburbIds();
            }
        });
    }

    public registerOnChange(fn: OnChangeFunction): void {
        this.onChange = fn;
    }

    public registerOnTouched(): void {}

    public onDelete(deletedSuburb: SuburbDetails): void {
        this.selectedSuburbs = this.selectedSuburbs.filter(selectedSuburb => deletedSuburb.id !== selectedSuburb.id);
        this.triggerOnChangeWithSuburbIds();
    }

    public onTermChange(term: string): void {
        this.filteredSuburbs = this.suburbService.searchSuburbs(term, this.allSuburbs, this.maxSuburbsShown);
    }

    public onSelect(suburb: SuburbDetails): void {
        this.autoComplete.resetInput();
        if (this.selectedSuburbs.map(({ id }) => id).includes(suburb.id)) {
            return;
        }
        this.selectedSuburbs.push(suburb);
        this.triggerOnChangeWithSuburbIds();
    }

    private triggerOnChangeWithSuburbIds(): void {
        this.onChange(this.selectedSuburbs.length ? this.selectedSuburbs.map(({ id }) => id) : undefined);
    }

    private handleSuburbList(): void {
        this.suburbFacade.loadSuburbsIfNeeded();
        const suburbState$ = this.suburbFacade.suburbState$.pipe(takeUntil(this.destroy$));

        suburbState$.subscribe(suburbState => {
            this.isLoadingSuburbList = suburbState.loading;
            this.allSuburbs = suburbState.all;
        });

        this.suburbsLoaded$ = suburbState$.pipe(
            filter(state => !!state.all.length),
            map(() => undefined),
        );
    }
}
