import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { UseCase } from '../../../common/models/core/use-case.model';
import { BrochureListingResponseDto } from '../../../common/models/dto/brochure/brochure-listing-response.dto';
import { listings } from '../../../common/models/listings.model';
import { BrochureResource } from '../../core/resources/brochure.resource';

export interface Result extends BrochureListingResponseDto {
    isForSale: boolean;
}

interface GetBrochureByTokenInput {
    token: string;
}

interface GetBrochureByPropertyIdInput {
    propertyId: string;
}

type Input = OneOf<[GetBrochureByTokenInput, GetBrochureByPropertyIdInput]>;

@Injectable({ providedIn: 'root' })
export class GetBrochureUseCase implements UseCase<Result, Input> {
    constructor(private brochureResource: BrochureResource) {}

    public execute(input: Input): Observable<Result> {
        return (
            'propertyId' in input
                ? this.brochureResource.getByPropertyId(input.propertyId)
                : this.brochureResource.get(input.token)
        ).pipe(
            map(dto => ({
                ...dto,
                isForSale: dto.state !== listings.State.Complete,
            })),
        );
    }
}
