import { Pipe, PipeTransform } from '@angular/core';

import { reminder } from '../../models/reminder.model';
import { SentenceCasePipe } from '../sentence-case.pipe';

@Pipe({
    name: 'ncPrettyReminderType',
})
export class PrettyReminderTypePipe implements PipeTransform {
    constructor(private sentenceCasePipe: SentenceCasePipe) {}

    public transform(name: reminder.Type): string {
        switch (name) {
            case reminder.Type.WorkflowDatabase:
                return 'Database Workflow';
            case reminder.Type.CallPropertyReportLead:
                return 'Property Report Workflow';
            case reminder.Type.WeeklyCampaignReport:
                return 'Campaign Report';
            default:
                return this.sentenceCasePipe.transform(name);
        }
    }
}
