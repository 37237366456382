import { isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    OnChanges,
    OnInit,
    PLATFORM_ID,
} from '@angular/core';

export type Columns = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

// This is using globally imported grid.scss, use styleUrls once all instances of grid classes has been replaced
// with component
@Component({
    selector: 'up-grid-col',
    templateUrl: './grid-col.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridColComponent implements OnInit, OnChanges {
    @Input() public col: Columns | 'auto' = 12;
    @Input('col@xs') public colAtXSmall?: Columns;
    @Input('col@sm') public colAtSmall?: Columns;
    @Input('col@md') public colAtMedium?: Columns;
    @Input('col@lg') public colAtLarge?: Columns;
    @Input('order') public order?: number;
    @Input('order@xs') public orderAtXSmall?: number;
    @Input('order@sm') public orderAtSmall?: number;
    @Input('order@md') public orderAtMedium?: number;
    @Input('order@lg') public orderAtLarge?: number;

    @HostBinding('class') public hostCssClasses: string;

    constructor(
        private elementRef: ElementRef,
        @Inject(PLATFORM_ID) private platformId,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        this.generateGridClasses();
    }

    public ngOnChanges(): void {
        this.generateGridClasses();
    }

    private generateGridClasses(): void {
        let existingHostClasses: string[];

        // classList only exists on nativeElement in the browser
        if (isPlatformBrowser(this.platformId)) {
            existingHostClasses = Array.from(this.elementRef.nativeElement.classList);
        } else {
            const elementClassName = this.elementRef.nativeElement.className;
            existingHostClasses = (elementClassName && elementClassName.split(' ')) || [];
        }

        // Strip out all existing grid classes so that they don't conflict with any new grid classes
        const existingNonGridClasses = existingHostClasses.filter(c => !c.startsWith('u-grid'));
        const classes = [
            `u-grid__col`,
            this.col === 'auto' ? '' : `u-grid__col--${this.col}`,
            this.colAtXSmall ? `u-grid__col--${this.colAtXSmall}@xs` : '',
            this.colAtSmall ? `u-grid__col--${this.colAtSmall}@sm` : '',
            this.colAtMedium ? `u-grid__col--${this.colAtMedium}@md` : '',
            this.colAtLarge ? `u-grid__col--${this.colAtLarge}@lg` : '',
            this.order ? `u-grid__col--order-${this.order}` : '',
            this.orderAtXSmall ? `u-grid__col--order-${this.orderAtXSmall}@xs` : '',
            this.orderAtSmall ? `u-grid__col--order-${this.orderAtSmall}@sm` : '',
            this.orderAtMedium ? `u-grid__col--order-${this.orderAtMedium}@md` : '',
            this.orderAtLarge ? `u-grid__col--order-${this.orderAtLarge}@lg` : '',
        ].concat(existingNonGridClasses);
        this.hostCssClasses = classes.filter(x => x).join(' ');
        this.changeDetectorRef.detectChanges();
    }
}
