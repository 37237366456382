import { HttpErrorResponse } from '@angular/common/http';
import { Action, createAction, props, union } from '@ngrx/store';

import { LikelihoodOfSelling } from '../../common/constants/pipline.constant';
import { AppraisalQuestionnaire } from '../../common/models/domain/property/appraisal-questionnaire.model';
import { SetPhaseLostAndSoldDto } from '../../common/models/dto/property-phase/set-phase-lost-and-sold.dto';
import { lead } from '../../common/models/lead.model';
import { phaseApi } from '../../common/models/phase-api.model';
import { PropertyAttributes } from '../../common/models/property-attributes.model';
import { Property } from '../../common/models/property.model';
import { Utm } from '../../common/models/utm.model';

export enum InternalPropertyActionType {
    GET = '[Internal Property] Get',
    GET_SUCCESS = '[Internal Property] Get Success',
    GET_ERROR = '[Internal Property] Get Error',

    UPDATE = '[Internal Property] Update',
    UPDATE_SUCCESS = '[Internal Property] Update Success',
    UPDATE_ERROR = '[Internal Property] Update Error',

    GET_TODO = '[Internal Property] Get Todo',
    GET_TODO_SUCCESS = '[Internal Property] Get Todo Success',
    GET_TODO_ERROR = '[Internal Property] Get Todo Error',

    RESET = '[Internal Property] Reset',

    REFRESH = '[Internal Property] Refresh',

    UPDATE_ACQUISITION_DATA = '[Internal Property] Update Acquisition Data',
    UPDATE_ACQUISITION_DATA_SUCCESS = '[Internal Property] Update Acquisition Data Success',
    UPDATE_ACQUISITION_DATA_ERROR = '[Internal Property] Update Acquisition Data Error',

    UPDATE_LISTING_EXPECTATION_DATA = '[Internal Property] Update Listing Expectation Data',
    UPDATE_LISTING_EXPECTATION_DATA_SUCCESS = '[Internal Property] Update Listing Expectation Data Success',
    UPDATE_LISTING_EXPECTATION_DATA_ERROR = '[Internal Property] Update Listing Expectation Data Error',

    UPDATE_PROSPECT_DATA = '[Internal Property] Update Prospect Data',
    UPDATE_PROSPECT_DATA_SUCCESS = '[Internal Property] Update Prospect Data Success',
    UPDATE_PROSPECT_DATA_ERROR = '[Internal Property] Update Prospect Data Error',

    SET_PHASE_INVALID = '[Internal Property] Set Phase Invalid',
    SET_PHASE_INVALID_SUCCESS = '[Internal Property] Set Phase Invalid Success',
    SET_PHASE_INVALID_ERROR = '[Internal Property] Set Phase Invalid Error',

    SET_PHASE_LOST_AND_SOLD = '[Internal Property] Set Phase Lost And Sold',
    SET_PHASE_LOST_AND_SOLD_SUCCESS = '[Internal Property] Set Phase Lost And Sold Success',
    SET_PHASE_LOST_AND_SOLD_ERROR = '[Internal Property] Set Phase Lost And Sold Error',

    DELETE_SALES_ASSISTANT = '[Internal Property] Delete Sales Assistant',
    DELETE_SALES_ASSISTANT_SUCCESS = '[Internal Property] Delete Sales Assistant Success',
    DELETE_SALES_ASSISTANT_ERROR = '[Internal Property] Delete Sales Assistant Error',

    UPDATE_PRICING_DATA = '[Internal Property] Update Pricing Data',
    UPDATE_PRICING_DATA_SUCCESS = '[Internal Property] Update Pricing Data Success',
    UPDATE_PRICING_DATA_ERROR = '[Internal Property] Update Pricing Data Error',
}

interface UpdatePayload {
    propertyId: string;
    property: Partial<Property>;
}

export interface VendorSources {
    vendorMedium?: lead.Medium[];
    attributes?: {
        stringAttributes?: {
            VENDOR_SOURCE?: string;
        };
    };
}

export interface AcquisitionData {
    utm?: Utm;
}

export interface ListingExpectationData {
    expectedSellingDate?: string;
    attributes?: {
        intAttributes?: {
            PRICE_EXPECTATION?: number;
        };
        stringAttributes?: {
            LIKELIHOOD_OF_SELLING?: LikelihoodOfSelling;
        };
    };
}

export interface ProspectData {
    leadScore: lead.Score;
    workflowReminderCadence?: number;
}

export interface PricingData {
    advertisedPrice: string;
    customAdvertisedPrice: boolean;
    salePrice: number;
    salePriceMax: number;
    salePriceMin: number;
}

export type RemoveData = Exclude<SetPhaseLostAndSoldDto, 'phase'> | { archiveReason: Property.SettableArchiveReasons };

export namespace internalProperty {
    export class Get implements Action {
        public readonly type = InternalPropertyActionType.GET;

        constructor(public payload: string) {}
    }

    export class GetSuccess implements Action {
        public readonly type = InternalPropertyActionType.GET_SUCCESS;

        constructor(public payload: Property) {}
    }

    export class GetError implements Action {
        public readonly type = InternalPropertyActionType.GET_ERROR;

        constructor(public payload: HttpErrorResponse) {}
    }

    export class Refresh implements Action {
        public readonly type = InternalPropertyActionType.REFRESH;
    }

    export class Update implements Action {
        public readonly type = InternalPropertyActionType.UPDATE;

        constructor(public payload: UpdatePayload) {}
    }

    export class UpdateSuccess implements Action {
        public readonly type = InternalPropertyActionType.UPDATE_SUCCESS;

        constructor(public payload: Property) {}
    }

    export class UpdateError implements Action {
        public readonly type = InternalPropertyActionType.UPDATE_ERROR;

        constructor(public payload: HttpErrorResponse) {}
    }

    export class GetTodo implements Action {
        public readonly type = InternalPropertyActionType.GET_TODO;

        constructor(public payload: string) {}
    }

    export class GetTodoSuccess implements Action {
        public readonly type = InternalPropertyActionType.GET_TODO_SUCCESS;

        constructor(public payload: any) {}
    }

    export class GetTodoError implements Action {
        public readonly type = InternalPropertyActionType.GET_TODO_ERROR;

        constructor(public payload: HttpErrorResponse) {}
    }

    export class Reset implements Action {
        public readonly type = InternalPropertyActionType.RESET;
    }

    export class UpdateAcquisitionData implements Action {
        public readonly type = InternalPropertyActionType.UPDATE_ACQUISITION_DATA;

        constructor(public payload: { propertyId: string; property: AcquisitionData }) {}
    }

    export class UpdateAcquisitionDataSuccess implements Action {
        public readonly type = InternalPropertyActionType.UPDATE_ACQUISITION_DATA_SUCCESS;

        constructor(public payload: Property) {}
    }

    export class UpdateAcquisitionDataError implements Action {
        public readonly type = InternalPropertyActionType.UPDATE_ACQUISITION_DATA_ERROR;
    }

    export class UpdateListingExpectationData implements Action {
        public readonly type = InternalPropertyActionType.UPDATE_LISTING_EXPECTATION_DATA;

        constructor(public payload: { propertyId: string; property: ListingExpectationData }) {}
    }

    export class UpdateListingExpectationDataSuccess implements Action {
        public readonly type = InternalPropertyActionType.UPDATE_LISTING_EXPECTATION_DATA_SUCCESS;

        constructor(public payload: Property) {}
    }

    export class UpdateListingExpectationDataError implements Action {
        public readonly type = InternalPropertyActionType.UPDATE_LISTING_EXPECTATION_DATA_ERROR;
    }

    export class UpdateProspectData implements Action {
        public readonly type = InternalPropertyActionType.UPDATE_PROSPECT_DATA;

        constructor(public payload: { propertyId: string; property: ProspectData }) {}
    }

    export class UpdateProspectDataSuccess implements Action {
        public readonly type = InternalPropertyActionType.UPDATE_PROSPECT_DATA_SUCCESS;

        constructor(public payload: ProspectData) {}
    }

    export class UpdateProspectDataError implements Action {
        public readonly type = InternalPropertyActionType.UPDATE_PROSPECT_DATA_ERROR;

        constructor(public payload: HttpErrorResponse) {}
    }

    export class SetPhaseInvalid implements Action {
        public readonly type = InternalPropertyActionType.SET_PHASE_INVALID;

        constructor(public payload: { propertyId: string; request: phaseApi.invalid.post.Request }) {}
    }

    export class SetPhaseInvalidSuccess implements Action {
        public readonly type = InternalPropertyActionType.SET_PHASE_INVALID_SUCCESS;
    }

    export class SetPhaseInvalidError implements Action {
        public readonly type = InternalPropertyActionType.SET_PHASE_INVALID_ERROR;
    }

    export class SetPhaseLostAndSold implements Action {
        public readonly type = InternalPropertyActionType.SET_PHASE_LOST_AND_SOLD;

        constructor(public payload: { propertyId: string; request: phaseApi.lostAndSold.post.Request }) {}
    }

    export class SetPhaseLostAndSoldSuccess implements Action {
        public readonly type = InternalPropertyActionType.SET_PHASE_LOST_AND_SOLD_SUCCESS;
    }

    export class SetPhaseLostAndSoldError implements Action {
        public readonly type = InternalPropertyActionType.SET_PHASE_LOST_AND_SOLD_ERROR;
    }

    export class DeleteSalesAssistant implements Action {
        public readonly type = InternalPropertyActionType.DELETE_SALES_ASSISTANT;

        constructor(public payload: string) {}
    }

    export class DeleteSalesAssistantSuccess implements Action {
        public readonly type = InternalPropertyActionType.DELETE_SALES_ASSISTANT_SUCCESS;
    }

    export class DeleteSalesAssistantError implements Action {
        public readonly type = InternalPropertyActionType.DELETE_SALES_ASSISTANT_ERROR;

        constructor(public payload: HttpErrorResponse) {}
    }

    export class UpdatePricingData implements Action {
        public readonly type = InternalPropertyActionType.UPDATE_PRICING_DATA;

        constructor(public payload: { propertyId: string; pricingData: PricingData }) {}
    }

    export class UpdatePricingDataSuccess implements Action {
        public readonly type = InternalPropertyActionType.UPDATE_PRICING_DATA_SUCCESS;

        constructor(public payload: Property) {}
    }

    export class UpdatePricingDataError implements Action {
        public readonly type = InternalPropertyActionType.UPDATE_PRICING_DATA_ERROR;

        constructor(public payload: HttpErrorResponse) {}
    }
}

export const updateHideFullAddress = createAction(
    '[Internal Property] Update Hide Full Address',
    props<{ propertyId: string; hideFullAddress: boolean }>(),
);
export const updateHideFullAddressSuccess = createAction(
    '[Internal Property] Update Hide Full Address Success',
    props<{ property: Property }>(),
);
export const updateHideFullAddressError = createAction('[Internal Property] Update Hide Full Address Error');

export const updateHideUnderOfferStatus = createAction(
    '[Internal Property] Update Hide Under Offer Status',
    props<{ propertyId: string; hideUnderOffer: boolean }>(),
);
export const updateHideUnderOfferStatusSuccess = createAction(
    '[Internal Property] Update Hide Under Offer Status Success',
    props<{ property: Property }>(),
);
export const updateHideUnderOfferStatusError = createAction('[Internal Property] Update Hide Under Offer Status Error');

export const updateHideSoldPrice = createAction(
    '[Internal Property] Update Hide Sold Price',
    props<{ propertyId: string; hideSoldPrice: boolean }>(),
);
export const updateHideSoldPriceSuccess = createAction(
    '[Internal Property] Update Hide Sold Price Success',
    props<{ property: Property }>(),
);
export const updateHideSoldPriceError = createAction('[Internal Property] Update Hide Sold Price Error');
export const updateVendorSources = createAction(
    '[Internal Property] Update Vendor Sources',
    props<{
        propertyId: string;
        vendorSources: VendorSources;
    }>(),
);
export const updateVendorSourcesSuccess = createAction(
    '[Internal Property] Update Vendor Sources Success',
    props<{ property: Property }>(),
);
export const updateVendorSourcesError = createAction('[Internal Property] Update Vendor Sources Error');

export const updateJustSoldNotificationEnabled = createAction(
    '[Internal Property] Update Just Sold Notification Enabled',
    props<{ propertyId: string; enabled: boolean }>(),
);
export const updateJustSoldNotificationEnabledSuccess = createAction(
    '[Internal Property] Update Just Sold Notification Enabled Success',
    props<{ property: Property }>(),
);
export const updateJustSoldNotificationEnabledError = createAction(
    '[Internal Property] Update Just Sold Notification Enabled Error',
);

export const updateJustListedSmsNotificationEnabled = createAction(
    '[Internal Property] Update Just Listed SMS Notification Enabled',
    props<{ propertyId: string; enabled: boolean }>(),
);
export const updateJustListedSmsNotificationEnabledSuccess = createAction(
    '[Internal Property] Update Just Listed SMS Notification Enabled Success',
    props<{ property: Property }>(),
);
export const updateJustListedSmsNotificationEnabledError = createAction(
    '[Internal Property] Update Just Listed SMS Notification Enabled Error',
);

export const updateJustListedEmailNotificationEnabled = createAction(
    '[Internal Property] Update Just Listed Email Notification Enabled',
    props<{ propertyId: string; enabled: boolean }>(),
);
export const updateJustListedEmailNotificationEnabledSuccess = createAction(
    '[Internal Property] Update Just Listed Email Notification Enabled Success',
    props<{ property: Property }>(),
);
export const updateJustListedEmailNotificationEnabledError = createAction(
    '[Internal Property] Update Just Listed Email Notification Enabled Error',
);

export const updateVendorPortalEnabled = createAction(
    '[Internal Property] Update Property Portal Enabled',
    props<{ propertyId: string; enabled: boolean }>(),
);
export const updateVendorPortalEnabledSuccess = createAction(
    '[Internal Property] Update Property Portal Enabled Success',
    props<{ property: Property }>(),
);
export const updateVendorPortalEnabledError = createAction('[Internal Property] Update Property Portal Enabled Error');

export const remove = createAction('[Internal Property] Remove', props<{ data: RemoveData }>());
export const removeSuccess = createAction('[Internal Property] Remove Success', props<{ property: Property }>());
export const removeError = createAction('[Internal Property] Remove Error');

export const unarchive = createAction('[Internal Property] Unarchive');
export const unarchiveSuccess = createAction('[Internal Property] Unarchive Success', props<{ property: Property }>());
export const unarchiveError = createAction('[Internal Property] Unarchive Error');

export const updateAppraisalQuestionnaire = createAction(
    '[Internal Property] Update Appraisal Questionnaire',
    props<{ payload: AppraisalQuestionnaire }>(),
);
export const updateAppraisalQuestionnaireSuccess = createAction(
    '[Internal Property] Update Appraisal Questionnaire Success',
    props<{ property: Property }>(),
);
export const updateAppraisalQuestionnaireError = createAction(
    '[Internal Property] Update Appraisal Questionnaire Error',
);

export const updateAttributes = createAction(
    '[Internal Property] Update Attributes',
    props<{ payload: PropertyAttributes }>(),
);
export const updateAttributesSuccess = createAction(
    '[Internal Property] Update Attributes Success',
    props<{ payload: Property }>(),
);
export const updateAttributesError = createAction('[Internal Property] Update Attributes Error');

export const updateIsContractOfSaleAlwaysVisible = createAction(
    '[Internal Property] Update Contract of Sales Always Visible',
    props<{ propertyId: string; isAlwaysVisible: boolean }>(),
);
export const updateIsContractOfSaleAlwaysVisibleSuccess = createAction(
    '[Internal Property] Update Contract of Sales Always Visible Success',
    props<{ property: Property }>(),
);
export const updateIsContractOfSaleAlwaysVisibleError = createAction(
    '[Internal Property] Update Contract of Sales Always Visible Error',
);

const actions = union({
    updateHideFullAddress,
    updateHideFullAddressSuccess,
    updateHideFullAddressError,
    updateHideUnderOfferStatus,
    updateHideUnderOfferStatusSuccess,
    updateHideUnderOfferStatusError,
    updateHideSoldPrice,
    updateHideSoldPriceSuccess,
    updateHideSoldPriceError,
    updateVendorSources,
    updateVendorSourcesSuccess,
    updateVendorSourcesError,
    updateJustSoldNotificationEnabled,
    updateJustSoldNotificationEnabledSuccess,
    updateJustSoldNotificationEnabledError,
    updateJustListedSmsNotificationEnabled,
    updateJustListedSmsNotificationEnabledSuccess,
    updateJustListedSmsNotificationEnabledError,
    updateJustListedEmailNotificationEnabled,
    updateJustListedEmailNotificationEnabledSuccess,
    updateJustListedEmailNotificationEnabledError,
    updateVendorPortalEnabled,
    updateVendorPortalEnabledSuccess,
    updateVendorPortalEnabledError,
    remove,
    removeSuccess,
    removeError,
    unarchive,
    unarchiveSuccess,
    unarchiveError,
    updateAppraisalQuestionnaire,
    updateAppraisalQuestionnaireSuccess,
    updateAppraisalQuestionnaireError,
    updateAttributes,
    updateAttributesSuccess,
    updateAttributesError,
    updateIsContractOfSaleAlwaysVisible,
    updateIsContractOfSaleAlwaysVisibleSuccess,
    updateIsContractOfSaleAlwaysVisibleError,
});

export type InternalPropertyActionsUnion =
    | internalProperty.Get
    | internalProperty.GetSuccess
    | internalProperty.GetError
    | internalProperty.Update
    | internalProperty.UpdateSuccess
    | internalProperty.UpdateError
    | internalProperty.GetTodo
    | internalProperty.GetTodoSuccess
    | internalProperty.GetTodoError
    | internalProperty.Reset
    | internalProperty.UpdateAcquisitionData
    | internalProperty.UpdateAcquisitionDataSuccess
    | internalProperty.UpdateAcquisitionDataError
    | internalProperty.UpdateListingExpectationData
    | internalProperty.UpdateListingExpectationDataSuccess
    | internalProperty.UpdateListingExpectationDataError
    | internalProperty.Refresh
    | internalProperty.UpdateProspectData
    | internalProperty.UpdateProspectDataSuccess
    | internalProperty.UpdateProspectDataError
    | internalProperty.SetPhaseInvalid
    | internalProperty.SetPhaseInvalidSuccess
    | internalProperty.SetPhaseInvalidError
    | internalProperty.SetPhaseLostAndSold
    | internalProperty.SetPhaseLostAndSoldSuccess
    | internalProperty.SetPhaseLostAndSoldError
    | internalProperty.DeleteSalesAssistant
    | internalProperty.DeleteSalesAssistantSuccess
    | internalProperty.DeleteSalesAssistantError
    | internalProperty.UpdatePricingData
    | internalProperty.UpdatePricingDataSuccess
    | internalProperty.UpdatePricingDataError
    | typeof actions;
