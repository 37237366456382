import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { WindowRef } from '../../../common/services/window.service';

@Injectable()
export class ZendeskChatVisibilityService {
    public isVisible$: Observable<boolean>;
    public zopim$: Observable<Zopim>;
    private zopimSubject = new ReplaySubject<Zopim>(1);
    private isVisibleSubject = new BehaviorSubject(true);

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(DOCUMENT) private document: Document,
        private windowRef: WindowRef,
    ) {
        this.isVisible$ = this.isVisibleSubject.asObservable();
        this.zopim$ = this.zopimSubject.asObservable();

        if (!isPlatformBrowser(this.platformId)) return;
        const zopimIntervalId = this.windowRef.nativeWindow.setInterval(() => {
            const zopim = this.windowRef.nativeWindow.$zopim;
            if (!zopim) return;

            this.zopimSubject.next(zopim);
            this.windowRef.nativeWindow.clearInterval(zopimIntervalId);
        }, 1000);
    }

    public show(): void {
        this.isVisibleSubject.next(true);
        if (!isPlatformBrowser(this.platformId)) return;
        this.zopim$.pipe(take(1)).subscribe(zopim => zopim.livechat?.button.show());
    }

    public hide(): void {
        this.isVisibleSubject.next(false);
        if (!isPlatformBrowser(this.platformId)) return;
        this.zopim$.pipe(take(1)).subscribe(zopim => zopim.livechat?.button.hide());
    }
}
