import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { pricingPlanApi } from '../../../common/models/pricing-plan-api.model';

const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

const BASE_URL = '/api/v2/property';

@Injectable()
export class PropertyPricingPlanResource {
    constructor(private httpClient: HttpClient) {}

    public getAvailablePlans(propertyId: string) {
        return this.httpClient.get<pricingPlanApi.availablePlans.get.Response>(
            `${BASE_URL}/${propertyId}/possible-plans/`,
            HEADERS_CONFIG,
        );
    }

    public setPricingPlan(propertyId: string, setPricing: pricingPlanApi.pricingPlan.post.Request) {
        return this.httpClient.post<pricingPlanApi.pricingPlan.post.Response>(
            `${BASE_URL}/${propertyId}/plan/`,
            setPricing,
            HEADERS_CONFIG,
        );
    }

    public getPricingPlan(propertyId: string) {
        return this.httpClient.get<pricingPlanApi.pricingPlan.get.Response>(
            `${BASE_URL}/${propertyId}/plan/`,
            HEADERS_CONFIG,
        );
    }
}
