import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BusinessContactApi } from '../../../common/models/business-contact-api.model';

@Injectable()
export class BusinessContactResource {
    private readonly baseUrl = '/api/v2/business-contact';

    constructor(private httpClient: HttpClient) {}

    public createBusinessContact(body: BusinessContactApi.Create.PUT.Request) {
        return this.httpClient.put<BusinessContactApi.Create.PUT.Response>(this.baseUrl, body);
    }
}
