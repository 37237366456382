import { ChangeDetectionStrategy, Component } from '@angular/core';

import { GlideOptions } from '../../../../common/injection-tokens/glide.injection-token';

interface VendorReview {
    imgSrc: string;
    vendorName: string;
    vendorLocation: string;
    rating: number;
    ratingPlatform: 'ratemyagent' | 'google';
    reviewTitle: string;
    reviewBody: string;
    reviewUrl: string;
}

@Component({
    selector: 'nc-vendor-reviews-section',
    templateUrl: 'vendor-reviews-section.component.html',
    styleUrls: ['vendor-reviews-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorReviewsSectionComponent {
    public readonly carouselOptions: GlideOptions = {
        perView: 3,
        autoplay: false,
        type: 'carousel',
        peek: 100,
        animationTimingFunc: 'cubic-bezier(0.17, 0.67, 0.34, 1)',
        breakpoints: {
            1500: {
                peek: 80,
                perView: 3,
            },
            1280: {
                perView: 2,
                peek: 80,
            },
            900: {
                peek: 60,
                perView: 2,
            },
            830: {
                perView: 1,
                peek: 150,
            },
            700: {
                peek: 100,
                perView: 1,
            },
            575: {
                peek: 50,
                perView: 1,
                gap: 8,
            },
            450: {
                peek: 25,
                perView: 1,
                gap: 8,
            },
        },
    };
    public readonly reviews: VendorReview[] = [
        {
            imgSrc: '/static/images/photography/vendor-review-property-garth.jpeg',
            vendorName: 'Garth W.',
            vendorLocation: 'Beaudesert, QLD',
            rating: 5,
            ratingPlatform: 'ratemyagent',
            reviewTitle: 'Best agent I\'ve ever engaged',
            reviewBody: `Possibly the smoothest sale and settlement I've ever had. All things that would normally be a stress for a seller were handled. Besides Upside seller portal which essentially maps out the progress and process, Rocky kept me in the loop at every step.`,
            reviewUrl:
                'https://www.ratemyagent.com.au/real-estate-agent/rocky-zhong/reviews/54-meridian-way-beaudesert-ab1vjt',
        },
        {
            imgSrc: '/static/images/photography/vendor-review-property-hilde.jpeg',
            vendorName: 'Hilde S.',
            vendorLocation: 'Taigum, QLD',
            rating: 5,
            ratingPlatform: 'ratemyagent',
            reviewBody:
                'Selling a property for a price well above above my expectations. When I said I was happy to receive X just to get it done quickly, he persuaded me to go for more, and he got it well within my time limit.',
            reviewTitle: 'Oops!... He Did It Again',
            reviewUrl:
                'https://www.ratemyagent.com.au/real-estate-agent/max-ramsey/reviews/25-13-quarrion-street-taigum-ab1l4j',
        },
        {
            imgSrc: '/static/images/photography/vendor-review-property-stacy.jpeg',
            vendorName: 'Stacy',
            vendorLocation: 'Dunlop, ACT',
            rating: 5,
            ratingPlatform: 'ratemyagent',
            reviewBody:
                'The service and engagement was phenomenal by Catherine and the Upside support team. We were kept informed every step of the way and any questions were answered quickly. The Upside magic dust meant we sold our house 11 days after launching the campaign!',
            reviewTitle: 'The secret weapon you need on your team!',
            reviewUrl:
                'https://www.ratemyagent.com.au/real-estate-agent/catherine-halloran/reviews/34-courtneidge-street-dunlop-ab26h4',
        },
        {
            imgSrc: '/static/images/photography/vendor-review-property-ian.jpeg',
            vendorName: 'Ian L.',
            vendorLocation: 'Macquarie Hills, NSW',
            rating: 5,
            ratingPlatform: 'ratemyagent',
            reviewBody:
                'Our property was on the market for 10 days before we had an offer that we accepted. Upside gave us a link to view the campaign progress and arranged photos, a 3D walk through and aerial photos by a drone which were excellent.',
            reviewTitle: 'Donna is a really great agent',
            reviewUrl:
                'https://www.ratemyagent.com.au/real-estate-agent/donna-catt/reviews/35-blaxland-road-macquarie-hills-ab2dm9',
        },
        {
            imgSrc: '/static/images/photography/vendor-review-property-matt.jpeg',
            vendorName: 'Matt A.',
            vendorLocation: 'Kangaroo Point, QLD',
            rating: 5,
            ratingPlatform: 'ratemyagent',
            reviewBody:
                'We sold at a tough time - but Max and team were still able to get people coming through the doors and obtained multiple offers for us. We recommend Upside\'s approach to working with their clients. Communication and service has been fantastic!',
            reviewTitle: 'Excellent agent with fantastic communication',
            reviewUrl:
                'https://www.ratemyagent.com.au/real-estate-agent/max-ramsey/reviews/44-55-princess-st-kangaroo-point-ab2k7v',
        },
        {
            imgSrc: '/static/images/photography/vendor-review-property-maureen.jpeg',
            vendorName: 'Maureen',
            vendorLocation: 'Macgregor, ACT',
            rating: 5,
            ratingPlatform: 'ratemyagent',
            reviewBody:
                'Looking back on the selling process, I realise there was a lack of stress & anxiety during it - most unusual. Upside gave me a seamless operation from the beginning of the process through to the end.',
            reviewTitle: 'Beyond my expectations',
            reviewUrl:
                'https://www.ratemyagent.com.au/real-estate-agent/catherine-halloran/reviews/22-85-macfarlane-burnet-avenue-macgregor-ab0slt',
        },
        {
            imgSrc: '/static/images/photography/vendor-review-property-rohini.jpeg',
            vendorName: 'Rohini M.',
            vendorLocation: 'Robina, QLD',
            rating: 5,
            ratingPlatform: 'google',
            reviewBody: `The sales strategy and campaign John recommended resulted in the property going under offer two days after listing at a price that exceeded our expectations. Upside's support team were sending and responding to communications 24/7.`,
            reviewTitle: 'Exceptional͏ service and highly recommended',
            reviewUrl: 'https://goo.gl/maps/i3kV9tya7UfmeHj86',
        },
    ];
}
