import { DOCUMENT, isPlatformBrowser, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Breadcrumb } from '@sentry/browser';
import { Severity } from '@sentry/types';
import { Rejection, RejectType } from '@uirouter/angular';
import { merge } from 'lodash-es';

import { EventResource } from '../../modules/core/resources/event.resource';
import { EnvironmentService } from '../../modules/core/services/environment.service';
import { Sentry, SentryToken } from '../injection-tokens/sentry.injection-token';
import { ErrorCodes } from '../models/dto/error-codes.dto';
import { error as errorModel, error } from '../models/error.model';

import { UserService } from './user.service';

interface JSError {
    message: any;
    stackTrace: string;
    url: string;
    userID?: string;
}

export interface ErrorCodeMessageMap {
    [key: string]: string;
}

interface SentryEventContext {
    tags?: { [key: string]: string };
    extras?: { [key: string]: any };
}

export interface ValidationErrorObject {
    [code: string]: string | ValidationErrorObject;
}

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
    private readonly MAX_STACK_TRACE_BYTE_LENGTH: number = 1200;
    private readonly HTTP_ERROR: string = 'xhr error';
    private userID = '';

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(DOCUMENT) private document: Document,
        @Inject(SentryToken) private sentry: Sentry,
        private injector: Injector,
    ) {}

    // Avoid injecting services within the constructor due to "Cannot instantiate cyclic dependency! ApplicationRef"
    // error in Angular 9
    // https://github.com/angular/angular/issues/35225
    public get environmentService(): EnvironmentService {
        return this.injector.get(EnvironmentService);
    }

    public handleError(error: any): void {
        this.setupUserSession();
        this.getUserID();

        if (error instanceof HttpErrorResponse) {
            this.warning(error, this.HTTP_ERROR);
        } else if (this.isStreetViewGetPanoramaError(error)) {
            this.sentry.captureException(error, {
                level: Severity.Info,
                user: { id: this.userID },
            });
        } else {
            this.sendError(error);
            this.getStackTrace(error);
        }
    }

    /**
     * Log transition errors.
     * info: transition superseded by another transition.
     * warning: All other transition errors.
     * @param error {any}
     */
    public handleTransitionError(error: Rejection): void {
        switch (error && error.type) {
            case RejectType.SUPERSEDED: {
                this.info(error, 'Transition superseded');
                break;
            }

            case RejectType.ERROR: {
                const { type, message, detail, redirected } = error;
                this.sendErrorWithContext(new Error(`Transition Error: ${error.message}`), {
                    extras: { type, message, detail, redirected },
                });
                break;
            }

            default: {
                this.warning(error, 'Other transition rejections');
                break;
            }
        }
    }

    public sendErrorWithContext(error: Error, context?: SentryEventContext): void {
        this.sentry.withScope(scope => {
            if (context.tags) scope.setTags(context.tags);
            if (context.extras) scope.setExtras(context.extras);
            this.sentry.captureException(error);
        });
    }

    public sendError(error): void {
        this.sentry.captureException(error);
    }

    public sendInfo(message: string, severity = Severity.Info): void {
        this.sentry.captureMessage(message, severity);
    }

    public info(data: any, message?: string) {
        this.captureBreadCrumb(data, Severity.Info, message);
    }

    public warning(data: any, message?: string) {
        this.captureBreadCrumb(data, Severity.Warning, message);
    }

    public debug(data: any, message?: string) {
        this.captureBreadCrumb(data, Severity.Debug, message);
    }

    private sendErrorEvent(error: JSError) {
        const eventResource = this.injector.get(EventResource);
        error.stackTrace = error.stackTrace.substring(0, this.MAX_STACK_TRACE_BYTE_LENGTH);
        error.message = error.message.substring(0, this.MAX_STACK_TRACE_BYTE_LENGTH);
        eventResource.trackError(error).subscribe();
    }

    private setupUserSession() {
        if (!isPlatformBrowser(this.platformId)) return;
        const userService = this.injector.get(UserService);
        const authData = userService.tempAuthData;
        if (authData.authenticated) {
            this.sentry.configureScope(scope => {
                scope.setUser({
                    id: authData.id,
                    email: authData.email,
                    extra: {
                        isAnonymous: false,
                        firstName: authData.firstName,
                        fullName: `${authData.firstName} ${authData.lastName}`,
                        cookie: this.document.cookie,
                    },
                });
            });
        } else {
            this.sentry.configureScope(scope => {
                scope.setUser({
                    extra: {
                        cookie: this.document.cookie,
                    },
                });
            });
        }
    }

    private logErrorToConsole(error) {
        // eslint-disable-next-line
        console.error(`${error.message}
        ${error.stackTrace}
        `);
    }

    private get url() {
        const location = this.injector.get(LocationStrategy);
        return location instanceof PathLocationStrategy ? location.path() : '';
    }

    private getUserID() {
        const User = this.injector.get(UserService);
        User.userAuthDetailsUpdated$.subscribe(authDetails => (this.userID = authDetails.id));
    }

    private getStackTrace(error) {
        let errorMessage = error.message ? error.message : error.toString();
        let stackTrace = error.stack || error.stacktrace || 'no stacktrace';

        // eslint-disable-next-line
        console.error(error);
        let jsError: JSError = {
            stackTrace: stackTrace,
            message: errorMessage,
            url: this.url,
            userID: this.userID,
        };

        this.sendErrorEvent(jsError);
        this.logErrorToConsole(jsError);
    }

    private captureBreadCrumb(data: any, level: Severity, message?: string): void {
        const crumb: Breadcrumb = {
            message,
            category: 'action',
            level,
            data,
        };
        this.sentry.addBreadcrumb(crumb);
    }

    public getFormattedError<T>(
        response: HttpErrorResponse,
        fallbackMessage = 'Unknown error occurred',
    ): error.Response<T> {
        if (!response || !response.error) {
            return {
                code: undefined,
                message: '',
            };
        } else if (response.error) {
            return {
                code: response.error.code || undefined,
                message: response.error.message || response.error.error || fallbackMessage,
            };
        }
    }

    public getMessageFromError(
        httpErrorResponse: HttpErrorResponse,
        errorCodeMessageMap?: ErrorCodeMessageMap,
        fallbackMessage = 'Unknown error.',
    ): string {
        const errorCode = this.getErrorCode(httpErrorResponse);
        const codeAsMessage = errorCode && ((errorCodeMessageMap && errorCodeMessageMap[errorCode]) || errorCode);

        return codeAsMessage || fallbackMessage;
    }

    public getFormattedHttpErrorMessage(
        httpErrorResponse: HttpErrorResponse,
        errorCodeMessageMap?: ErrorCodeMessageMap,
        fallbackMessage = 'Unknown error',
    ): string {
        const errorCode = this.getErrorCode(httpErrorResponse);
        const errorMessage = this.getErrorMessage(httpErrorResponse);

        return errorCode
            ? (errorCodeMessageMap && errorCodeMessageMap[errorCode]) ||
                  `${fallbackMessage} (${errorCode}${errorMessage ? ` - ${errorMessage}` : ''})`
            : fallbackMessage;
    }

    public getErrorCode(httpErrorResponse: HttpErrorResponse): ErrorCodes | undefined {
        const { error } = httpErrorResponse;
        return error && error.code;
    }

    public convertValidationListToObject(errorList: error.Error[]): ValidationErrorObject {
        return errorList.reduce((acc, error) => merge(acc, this.getErrorAsObject(error)), {});
    }

    private getErrorAsObject(error: errorModel.Error) {
        /** Nested errors coming from the API are split by periods. For example,
         * callDetails.firstName: 'required',
         * callDetails.lastName: 'required'
         */
        if (error.code.includes('.')) {
            const split = error.code.split('.');
            const group = split.shift();
            const newError = {
                code: split.join('.'),
                message: error.message,
            };

            return { [group]: this.getErrorAsObject(newError) };
        } else {
            return { [error.code]: error.message };
        }
    }

    private getErrorMessage(httpErrorResponse: HttpErrorResponse): string | undefined {
        const { error } = httpErrorResponse;
        return error && error.message;
    }

    private isStreetViewGetPanoramaError(error: any): boolean {
        return error?.rejection?.endpoint === 'STREETVIEW_GET_PANORAMA';
    }
}
