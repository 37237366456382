import { InjectionToken } from '@angular/core';

interface InternalPasswordConfig {
    minLength: number;
}

const INTERNAL_PASSWORD_DI_CONFIG = {
    minLength: 6,
};

let INTERNAL_PASSWORD_CONFIG = new InjectionToken<InternalPasswordConfig>('app.constants.internal-password');

export { InternalPasswordConfig, INTERNAL_PASSWORD_DI_CONFIG, INTERNAL_PASSWORD_CONFIG };
