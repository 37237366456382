import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { Property, PropertyFeaturesT } from '../../../../common/models/property.model';
import { CapitaliseStringPipe } from '../../../../common/pipes/capitalise.pipe';
import { doTranslation } from '../../../../common/utilities/i18n/do-translation.util';
import { FormatPropertyAreaAttributePipe } from '../../../property-attributes/pipes/format-property-area-attribute/format-property-area-attribute.pipe';

interface Detail {
    name: string;
    value: any;
}

@Component({
    selector: 'up-listing-key-details',
    templateUrl: 'listing-key-details.component.html',
    styleUrls: ['listing-key-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CapitaliseStringPipe, FormatPropertyAreaAttributePipe],
})
export class ListingKeyDetailsComponent {
    @Input() public features: PropertyFeaturesT;
    @HostBinding('class.u-display--none')
    private get hasNoDetails(): boolean {
        return !this.keyDetails.length;
    }

    constructor(
        private currencyPipe: CurrencyPipe,
        private capitaliseStringPipe: CapitaliseStringPipe,
        private formatPropertyAreaAttributePipe: FormatPropertyAreaAttributePipe,
    ) {}

    public get keyDetails(): Detail[] {
        if (!this.features) return [];

        return (<Entries<PropertyFeaturesT>>Object.entries(this.features))
            .filter(([, value]) => typeof value !== 'boolean')
            .map(([featureName, value]) => {
                // These are only the features we actually want to display
                switch (featureName) {
                    case 'councilRatesPerQuarter':
                        return !!value
                            ? {
                                name: 'Council rates per quarter',
                                value: this.currencyPipe.transform(value, 'AUD', 'symbol-narrow', '1.0-2'),
                            }
                            : undefined;

                    case 'strataRates':
                    case 'waterRates': {
                        if (!value) return undefined;
                        // For some unknown reason, TypeScript doesn't want to allow destructuring here.
                        const rateAmount = value.value;
                        const frequency = value.frequency;

                        if (!rateAmount || !frequency) return undefined;

                        const translation = (() => {
                            switch (frequency) {
                                case 'MONTHLY':
                                    return 'propertyRatePaymentFrequencyType.monthly';
                                case 'QUARTERLY':
                                    return 'propertyRatePaymentFrequencyType.quarterly';
                                case 'YEARLY':
                                    return 'propertyRatePaymentFrequencyType.yearly';
                            }
                        })();
                        const name = doTranslation(translation, {
                            type: doTranslation(
                                featureName === 'strataRates' ? 'generic.strataRates' : 'generic.waterRates',
                            ),
                        });

                        return {
                            name,
                            value: this.currencyPipe.transform(value.value, 'AUD', 'symbol-narrow', '1.0-2'),
                        };
                    }

                    case 'energyRating':
                        return {
                            name: 'Energy rating',
                            value: value,
                        };

                    case 'hotWater':
                        return {
                            name: 'Hot water',
                            value: this.capitaliseStringPipe.transform(value),
                        };

                    case 'structuredInternalSize':
                        return this.getFormattedAreaDetail('Internal size', value);

                    case 'structuredLandSize':
                        return this.getFormattedAreaDetail('Land size', value);

                    default:
                        return undefined;
                }
            })
            .filter(detail => typeof detail !== 'undefined');
    }

    private getFormattedAreaDetail(name: string, areaAttribute: Property.AreaAttribute): Detail {
        const formatted = this.formatPropertyAreaAttributePipe.transform(areaAttribute);

        if (!formatted) return;

        return { name, value: formatted };
    }
}
