<nav
    [ngClass]="{
        'nav-bar': true,
        'is-inverse': config.style.invert,
        'is-no-transparent-shadow': config.style?.noTransparentShadow,
        'is-scrolled': isScrolled,
        'is-always-showing-shadow': config.style?.alwaysShowShadow
    }"
>
    <div class="nav-bar-bg">
        <div
            #navBarBg
            [class.outer-container-width]="config.style.backgroundWidth === BackgroundWidth.OuterContainer"
            class="nav-bar-bg-inner"
        ></div>
    </div>
    <up-outer-container class="nav-container">
        <up-container>
            <div class="nav-bar-inner">
                <div class="nav-bar-left">
                    <button
                        class="nav-bar-hamburger"
                        *ngIf="!config.style?.noPane"
                        [ngClass]="{ 'is-always-shown': config.style?.alwaysShowHamburger }"
                        #paneToggle
                        (click)="showPane()"
                    >
                        <up-icon aria-label="Open menu" [name]="'menu'" [color]="'current-color'"></up-icon>
                    </button>
                    <up-nav-bar-logo-link [ncDataEvent]="config.home.trackingEvent"></up-nav-bar-logo-link>
                </div>
                <div class="nav-bar-custom-content"> <ng-content></ng-content> </div>
                <div class="nav-bar-right" [class.show]="!isPrerendering">
                    <div class="items-container">
                        <div class="nav-bar-items" *ngIf="config.items?.length">
                            <up-nav-bar-item *ngFor="let item of config.items" [item]="item"></up-nav-bar-item>
                        </div>
                    </div>
                    <div class="cta-container">
                        <div
                            class="nav-bar-cta"
                            *ngIf="
                                overrideCta
                                    ? isItemVisible(overrideCta.visibility)
                                    : config.cta && isItemVisible(config.cta.visibility)
                            "
                        >
                            <up-nav-bar-cta [item]="overrideCta || config.cta"></up-nav-bar-cta>
                        </div>
                    </div>
                    <up-nav-bar-user
                        class="nav-bar-user"
                        *ngIf="authData?.authenticated && config.userItems?.length"
                        [authData]="authData"
                        [items]="config.userItems"
                    ></up-nav-bar-user>
                    <div *ngIf="config.actionIconItem" class="u-margin-left--lg">
                        <up-button
                            (onClick)="config.actionIconItem.click ? config.actionIconItem.click() : null"
                            [upSrefParams]="config.actionIconItem.uiParams"
                            [upSref]="config.actionIconItem.uiSref"
                            [href]="config.actionIconItem.href"
                        >
                            <up-icon
                                [attr.aria-label]="config.actionIconItem.title"
                                [name]="config.actionIconItem.icon"
                            >
                            </up-icon>
                        </up-button>
                    </div>
                </div>
            </div>
        </up-container>
    </up-outer-container>
</nav>
