<div class="background">
    <div class="container">
        <div class="header">
            <div class="u-margin-bottom--sm logo-container">
                <nc-brand-logo class="logo"></nc-brand-logo>
            </div>
            <ng-content select="[slot='header']"></ng-content>
        </div>
        <div class="body">
            <div *ngIf="loading" class="u-margin-vertical--4xl u-text-align--center">
                <nc-spinner [size]="'large'"></nc-spinner>
            </div>
            <div [hidden]="loading">
                <ng-content select="[slot='body']"></ng-content>
            </div>
        </div>
    </div>
</div>
