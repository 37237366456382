import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { agentApi } from '../../../common/models/agent-api.model';
import { CallDelegatesDto } from '../../../common/models/dto/agent/call-delegates.dto';
import { FoundAgentDto } from '../../../common/models/dto/agent/found-agent.dto';
import { SimplifiedAgentDto } from '../../../common/models/dto/agent/simplified-agent.dto';
import { CustomQueryEncoder } from '../../../encoders/custom-query.encoder';

const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

let BASE_URL = `/api/v2/agent`;

export type AgentStatus = 'Any' | 'Active' | 'Inactive';

export interface AgentSearchQuery {
    textualSearch?: string;
    teamIds?: string[];
    agentStatus?: AgentStatus;
    businessId?: string;
}

@Injectable()
export class AgentResource {
    constructor(private http: HttpClient, private customQueryEncoder: CustomQueryEncoder) {}

    public allSimplifiedByIds(ids: string[]) {
        return this.http.get<SimplifiedAgentDto[]>(`${BASE_URL}/details/simplified/`, {
            params: { ids },
        });
    }

    public getAll(query?: AgentSearchQuery): Observable<FoundAgentDto[]> {
        return this.http.get<FoundAgentDto[]>(`${BASE_URL}/query`, {
            params: new HttpParams({
                encoder: this.customQueryEncoder,
                fromObject: { ...query },
            }),
        });
    }

    public create(agent: agentApi.create.post.Request): Observable<agentApi.create.post.Response> {
        return this.http.post<agentApi.create.post.Response>(`${BASE_URL}/create`, agent, HEADERS_CONFIG);
    }

    public getAgentById(id: string): Observable<agentApi.agentById.get.Response> {
        return this.http.get<agentApi.agentById.get.Response>(`${BASE_URL}/${id}/`, HEADERS_CONFIG);
    }

    public updateAgentById(
        id: string,
        agentDetails: agentApi.agentById.post.Request,
    ): Observable<agentApi.agentById.post.Response> {
        return this.http.post<agentApi.agentById.post.Response>(`${BASE_URL}/${id}/`, agentDetails, HEADERS_CONFIG);
    }

    public createBlockedTime(
        id: string,
        blockTime: agentApi.createBlockedTime.post.Request,
    ): Observable<agentApi.createBlockedTime.post.Response> {
        return this.http.post<agentApi.createBlockedTime.post.Response>(
            `${BASE_URL}/${id}/block/`,
            blockTime,
            HEADERS_CONFIG,
        );
    }

    public getCalendar(id: string, from?: string, to?: string): Observable<agentApi.calendar.get.Response> {
        let params = new HttpParams();
        if (from) {
            params = params.append('from', from);
        }
        if (to) {
            params = params.append('to', to);
        }
        return this.http.get<agentApi.calendar.get.Response>(`${BASE_URL}/${id}/calendar/`, {
            ...HEADERS_CONFIG,
            params,
        });
    }

    public deleteBlockedTime(
        agentId: string,
        blockedTimeId: string,
    ): Observable<agentApi.deleteBlockedTime.post.Response> {
        const body: agentApi.deleteBlockedTime.post.Request = {};
        return this.http.post<agentApi.deleteBlockedTime.post.Response>(
            `${BASE_URL}/${agentId}/block/${blockedTimeId}/cancel`,
            body,
            HEADERS_CONFIG,
        );
    }

    public bulkUpload(
        agentId: string,
        leads: agentApi.bulkUploadLeads.post.Request,
    ): Observable<agentApi.bulkUploadLeads.post.Response> {
        return this.http.post<agentApi.bulkUploadLeads.post.Response>(
            `${BASE_URL}/${agentId}/lead/bulk-upload/`,
            leads,
            {
                ...HEADERS_CONFIG,
                headers: new HttpHeaders().set('Content-Type', 'text/csv'),
            },
        );
    }

    public addLeave(
        agentId: string,
        leave: agentApi.addLeave.post.Request,
    ): Observable<agentApi.addLeave.post.Response> {
        return this.http.post<agentApi.addLeave.post.Response>(`${BASE_URL}/${agentId}/leave/`, leave, HEADERS_CONFIG);
    }

    public cancelLeave(agentId: string, leaveId: string): Observable<any> {
        return this.http.post<any>(`${BASE_URL}/${agentId}/leave/${leaveId}/cancel`, {}, HEADERS_CONFIG);
    }

    public getOnLeave(): Observable<agentApi.getOnLeave.get.Response> {
        return this.http.get<agentApi.getOnLeave.get.Response>(`${BASE_URL}/leave/`, HEADERS_CONFIG);
    }

    public bulkReassignTo(agentId: string, propertyIds: string[]): Observable<agentApi.bulkReassignTo.post.Response> {
        return this.http.post<agentApi.bulkReassignTo.post.Response>(
            `${BASE_URL}/${agentId}/reassign-properties-to/`,
            <agentApi.bulkReassignTo.post.Request>{ properties: propertyIds },
            HEADERS_CONFIG,
        );
    }

    public profile(slug: string): Observable<agentApi.profile.get.Response> {
        return this.http.get<agentApi.profile.get.Response>(`${BASE_URL}/profiles/${slug}`);
    }

    public profileById(id: string): Observable<agentApi.profileById.get.Response> {
        return this.http.get<agentApi.profileById.get.Response>(`${BASE_URL}/profiles-by-id/${id}`);
    }

    public calendarBySlug(slug: string): Observable<agentApi.calendarBySlug.get.Response> {
        return this.http.get<agentApi.calendarBySlug.get.Response>(
            `${BASE_URL}/slug/${slug}/calendar/`,
            HEADERS_CONFIG,
        );
    }

    public updateSuburbsAgentData(agentId: string): Observable<agentApi.updateSuburbsAgentData.get.Response> {
        return this.http.post<agentApi.updateSuburbsAgentData.get.Response>(
            `${BASE_URL}/${agentId}/update-suburbs`,
            undefined,
            HEADERS_CONFIG,
        );
    }

    public calculateSuburbTravelTimes(agentId: string): Observable<agentApi.calculateSuburbTravelTimes.get.Response> {
        return this.http.post<agentApi.calculateSuburbTravelTimes.get.Response>(
            `${BASE_URL}/${agentId}/calculate-suburb-travel-times`,
            undefined,
            HEADERS_CONFIG,
        );
    }

    public getDelegatedAgents() {
        return this.http.get<agentApi.MyAgentsWithDelegatedCallList.GET.Response>(`${BASE_URL}/delegated`);
    }

    public getCallDelegates(agentId: string): Observable<CallDelegatesDto> {
        return this.http.get<CallDelegatesDto>(`${BASE_URL}/${agentId}/call-delegates`);
    }
}
