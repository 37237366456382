<section>
    <up-outer-container class="u-padding-vertical--6xl-r" [background]="'neutral'">
        <up-container>
            <up-content-accent>Reviews</up-content-accent>
            <h2 class="u-text-heading--section u-margin-top--lg"
                >See why 1000+ home sellers <span class="heading-no-break">recommend Upside</span></h2
            >

            <div class="u-margin-top--lg">
                <up-reviews-showcase></up-reviews-showcase>
                <a
                    class="u-margin-top--sm"
                    nc-button
                    href="https://upside.com.au/lp/customer-reviews"
                    [style]="'plain-dark'"
                >
                    Read our reviews
                </a>
            </div>
        </up-container>

        <div class="u-margin-top--xl">
            <up-carousel #carousel [options]="carouselOptions">
                <ng-template upCarouselSlide *ngFor="let review of reviews">
                    <nc-card class="review-card">
                        <div class="review-wrapper">
                            <img
                                role="presentation"
                                alt=""
                                class="quotemark-image"
                                [src]="'/static/images/icons/quotemark.svg' | upCdnUrl"
                            />
                            <img
                                [alt]="
                                    'Photo of vendor ' +
                                    review.vendorName +
                                    '\'s sold property located in ' +
                                    review.vendorLocation +
                                    '.'
                                "
                                class="review-image"
                                [src]="review.imgSrc | upCdnUrl"
                            />
                            <div class="vendor-name u-text-heading--lg u-margin-top--2xs">
                                {{ review.vendorName }}
                            </div>
                            <div class="vendor-location">
                                {{ review.vendorLocation }}
                            </div>
                            <div class="rating">
                                <up-rating [value]="review.rating"></up-rating>
                                <div class="u-margin-left--sm" [ngSwitch]="review.ratingPlatform">
                                    <img
                                        height="25"
                                        width="29"
                                        alt="Google Reviews"
                                        *ngSwitchCase="'google'"
                                        [src]="'/static/images/logo/logo-google-review.svg' | upCdnUrl"
                                    />
                                    <img
                                        height="25"
                                        width="87"
                                        alt="Ratemyagent Reviews"
                                        *ngSwitchCase="'ratemyagent'"
                                        [src]="'/static/images/logo/logo_RateMyAgent.svg' | upCdnUrl"
                                    />
                                </div>
                            </div>
                            <blockquote class="review-copy u-text-line-height--large">
                                <div class="u-text-weight--semi-bold">{{ review.reviewTitle }}</div>
                                <div>{{ review.reviewBody }}</div>
                                <cite class="u-hide-visually"
                                    ><a target="_blank" [href]="review.reviewUrl"
                                        >Read full review by {{ review.vendorName }}</a
                                    ></cite
                                >
                            </blockquote>
                        </div>
                    </nc-card>
                </ng-template>
            </up-carousel>
            <div class="carousel-indicators-wrapper u-padding-top--lg">
                <up-carousel-indicators [carousel]="carousel"></up-carousel-indicators>
            </div>
        </div>
    </up-outer-container>
</section>
