import { animate, sequence, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RawParams, StateService } from '@uirouter/angular';
import { first } from 'rxjs/operators';

import { loginFeatureDisabled } from '../../../common/constants/error-messages.constants';
import { UpValidators } from '../../../common/form-validators/validators';
import { AppPlatform } from '../../../common/models/app-platform.model';
import { userApi } from '../../../common/models/user-api.model';
import { ErrorHandlerService } from '../../../common/services/error-handler.service';
import { TrackingService } from '../../../common/services/tracking.service';
import { UserService, UserT } from '../../../common/services/user.service';
import { WindowRef } from '../../../common/services/window.service';
import { isInternal } from '../../../common/utilities/roles.utilities';
import { UserResource } from '../../core/resources/user.resource';
import { AppPlatformService } from '../../core/services/app-platform.service';
import { ThemeName, ThemeService } from '../../core/services/theme.service';

export enum LoginState {
    EmailUnconfirmed,
    EmailConfirmed,
    PasswordInvalid,
    LogInDisabled,
    NoEmailMatch,
    ForgotPasswordError,
    ConfirmEmailError,
}

@Component({
    selector: 'up-sign-in',
    templateUrl: 'sign-in.component.html',
    styleUrls: ['sign-in.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                // initial styles when element enters DOM
                style({ opacity: 0, height: 0 }),

                // sequentially applied styles
                sequence([
                    animate('0.15s ease-out', style({ height: '*', opacity: 0 })),
                    animate('0.1s ease-in', style({ opacity: 1 })),
                ]),
            ]),
        ]),
    ],
})
export class SignInComponent implements OnInit {
    @Input() public email: string;
    /** @deprecated Deprecated in favor of redirectParams. */
    @Input() public id: string;
    @Input('redirect_to') public redirectTo: string;
    @Input() public next: string;
    @Input() public redirectParams: string;

    public loading: boolean;
    public loginForm: FormGroup;
    public formSubmissionAttempted: boolean;
    public loginState: LoginState = LoginState.EmailUnconfirmed;
    public loginStateEnum = LoginState;
    public themeName: ThemeName;
    public Theme = ThemeName;
    public loginDisabled = loginFeatureDisabled;
    private isInternalApp: boolean;

    constructor(
        private userResource: UserResource,
        private windowRef: WindowRef,
        private trackingService: TrackingService,
        private userService: UserService,
        private formBuilder: FormBuilder,
        private stateService: StateService,
        private errorHandlerService: ErrorHandlerService,
        private themeService: ThemeService,
        private appPlatformService: AppPlatformService,
    ) {}

    public ngOnInit(): void {
        this.themeName = this.themeService.themeName;
        this.isInternalApp = this.appPlatformService.platform === AppPlatform.Internal;

        this.createForm();
        this.redirectIfLoggedIn();
    }

    public onSubmit(): void {
        this.formSubmissionAttempted = true;

        if (this.loginForm.invalid) return;

        let { email, password } = this.loginForm.value;
        email = email.trim();

        this.loading = true;
        this.formSubmissionAttempted = false;

        // depending on the user's login state, we either try to login in or validate their email
        switch (this.loginState) {
            case LoginState.PasswordInvalid:
            case LoginState.LogInDisabled:
            case LoginState.EmailConfirmed:
                this.userResource.signIn({ email, password }).subscribe(
                    response => this.handleSignInResponse(response),
                    error => this.handleSignInFailure(error),
                );
                break;

            case LoginState.ConfirmEmailError:
            case LoginState.ForgotPasswordError:
            case LoginState.NoEmailMatch:
            case LoginState.EmailUnconfirmed:
                this.userResource.confirmRegisteredEmail({ email }).subscribe(
                    response => this.handleConfirmEmailResponse(response),
                    () => {
                        this.loading = false;
                        this.loginState = LoginState.ConfirmEmailError;
                    },
                );
                break;
            default:
                break;
        }
    }

    public shouldShowControlError(formControlName: string): boolean {
        return this.formSubmissionAttempted && this.loginForm.get(formControlName).invalid;
    }

    private handleConfirmEmailResponse(response: userApi.confirmRegisteredEmail.get.Response): void {
        this.loading = false;
        if (response.requireAuth) {
            this.loginState = LoginState.EmailConfirmed;
            this.loginForm.addControl('password', new FormControl('', Validators.required));
        } else {
            if (response.exists) {
                // user is shadow, so send a set password email
                this.loading = true;
                const email = this.loginForm.get('email').value;
                this.userResource.forgotPassword({ email }).subscribe(
                    () => this.stateService.go('user.check-email', { email, mode: 'shadow-sign-up' }),
                    () => {
                        this.loading = false;
                        this.loginState = LoginState.ForgotPasswordError;
                    },
                );
            } else {
                // user not found in our db
                this.loginState = LoginState.NoEmailMatch;
            }
        }
    }

    private handleSignInFailure(error: HttpErrorResponse): void {
        const errorCode = this.errorHandlerService.getErrorCode(error);

        this.loginState =
            errorCode === 'LOGIN_FEATURE_DISABLED' ? LoginState.LogInDisabled : LoginState.PasswordInvalid;

        this.loading = false;
    }

    private handleSignInResponse(response: userApi.signIn.post.Response): void {
        this.trackingService.trackUser();
        this.handleRedirect(response);
    }

    private handleRedirect(authData: UserT): void {
        this.loading = true;
        // Default state. Any non-internal users will fallback to being redirected here if no other
        // redirect criteria is met.
        let state = 'my-properties';
        let params: RawParams;

        if (this.redirectTo && !this.isInternalApp) {
            state = this.redirectTo;

            if (this.redirectParams) {
                try {
                    const parsedParams = JSON.parse(decodeURIComponent(this.redirectParams));
                    params = { ...parsedParams };
                } catch (e) {
                    this.errorHandlerService.sendError(e);
                }
            }

            if (this.id) {
                params = { ...params, id: this.id };
            }
        } else if (this.next) {
            this.windowRef.nativeWindow.location.href = this.next;
            return;
        } else if (isInternal(authData)) {
            state = 'internal';
            if (!this.isInternalApp && this.windowRef.nativeWindow.location) {
                this.stateService.go('internal');
                return;
            }
        } else if (this.isInternalApp) {
            state = 'internal';
        }

        this.stateService.go(state, params).then(
            () => {
                /* It works! Do nothing... */
            },
            error => {
                this.errorHandlerService.sendError(error);
                this.errorHandlerService.warning({ state, params }, error.message);
                this.stateService.go('home');
            },
        );
    }

    private createForm(): void {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, UpValidators.strictEmailValidator]],
        });
    }

    private redirectIfLoggedIn(): void {
        this.userService.userAuthDetailsUpdated$.pipe(first()).subscribe(authData => {
            if (authData.authenticated) {
                this.handleRedirect(authData);
            }
        });
    }
}
