import { Injectable } from '@angular/core';

import { AddressT } from '../../../common/models/address.model';
import { countryStates } from '../../../common/models/country-states.model';

@Injectable()
export class PortalsService {
    public canHaveAllhomesPortal(address: AddressT): boolean {
        return (
            address.governingDistrict === countryStates.Australia.ACT ||
            ['2619', '2620', '2536', '2537', '2539', '2545', '2622', '2257'].includes(address.postalArea)
        );
    }
}
