import { animate, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ErrorHandlerService } from '../../../../common/services/error-handler.service';
import { WindowRef } from '../../../../common/services/window.service';
import { ScriptLoaderService } from '../../../core/services/script-loader.service';
import { ZendeskChatVisibilityService } from '../../services/zendesk-chat-visibility.service';

@Component({
    selector: 'up-zendesk-placeholder',
    templateUrl: 'zendesk-placeholder.component.html',
    styleUrls: ['zendesk-placeholder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('visibility', [
            transition(':enter', [style({ opacity: 0 }), animate('0.1s ease-in', style({ opacity: 1 }))]),
            transition(':leave', [animate('0.1s ease-out', style({ transform: 'translateY(20px)', opacity: 0 }))]),
        ]),
    ],
})
export class ZendeskPlaceholderComponent implements OnInit {
    public isLoading: boolean;
    public loaded: boolean;
    public isVisible$: Observable<boolean>;
    private readonly silentPreloadTimeoutMs = 10000;
    // There are two separate return ty pe definitions for setTimeout, one for browser and one for node, Typescript
    // doesn't know which one to use so using ReturnType allows it to work in both environments
    private silentPreloadTimerRef: ReturnType<typeof setTimeout>;
    private silentPreloadTimedOut: boolean;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private windowRef: WindowRef,
        private scriptLoaderService: ScriptLoaderService,
        private changeDetectorRef: ChangeDetectorRef,
        private zendeskChatVisibilityService: ZendeskChatVisibilityService,
        private errorHandlerService: ErrorHandlerService,
    ) {}

    public ngOnInit(): void {
        this.silentPreload();
        this.bindCallbacks();
        this.isVisible$ = this.zendeskChatVisibilityService.isVisible$;
    }

    public onClick(): void {
        clearTimeout(this.silentPreloadTimerRef);
        this.load();
    }

    private load(): void {
        this.isLoading = true;
        this.changeDetectorRef.detectChanges();
        this.scriptLoaderService
            .load('https://static.zdassets.com/ekr/snippet.js?key=92c38a63-6bc0-4dad-a1a4-43652b6ccab1', {
                id: 'ze-snippet',
            })
            .subscribe(
                () => {},
                error =>
                    this.errorHandlerService.sendErrorWithContext(
                        new Error(
                            'Error loading https://static.zdassets.com/ekr/snippet.js?key=92c38a63-6bc0-4dad-a1a4-43652b6ccab1',
                        ),
                        { extras: error },
                    ),
            );
    }

    // Silently preloads zendesk widget after some timeout to allow CX to utilise automated triggers feature
    private silentPreload(): void {
        if (!isPlatformBrowser(this.platformId)) return;
        this.silentPreloadTimerRef = setTimeout(() => {
            this.silentPreloadTimedOut = true;
            this.load();
        }, this.silentPreloadTimeoutMs);
    }

    private bindCallbacks(): void {
        if (!isPlatformBrowser(this.platformId)) return;
        this.zendeskChatVisibilityService.zopim$.pipe(take(1)).subscribe(() => {
            this.loaded = true;
            this.changeDetectorRef.detectChanges();

            this.windowRef.nativeWindow.zE('webWidget:on', 'open', () => {
                this.isLoading = false;
                this.changeDetectorRef.detectChanges();
            });
            // If widget is launched by silent preload timeout don't open the chat window
            if (!this.silentPreloadTimedOut) {
                // Open widget immediately, as the user would have had to click on the placeholder button to trigger
                // the load so one would assume they intend on chatting
                this.windowRef.nativeWindow.zE('webWidget', 'open');
            }
        });
    }
}
