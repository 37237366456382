import { animate, state, style, transition, trigger } from '@angular/animations';
import { FocusMonitor, ListKeyManager } from '@angular/cdk/a11y';
import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { TransitionService } from '@uirouter/angular';

import { nav } from '../../../../common/models/nav.model';
import { isItemVisible } from '../../../../common/utilities/menu-items.utilities';

@Component({
    selector: 'up-nav-bar-dropdown',
    templateUrl: './nav-bar-dropdown.component.html',
    styleUrls: ['./nav-bar-dropdown.component.scss'],
    animations: [
        trigger('dropdown', [
            state(
                'true',
                style({
                    transform: 'translateY(0)',
                    opacity: '1',
                }),
            ),
            state(
                'false, null',
                style({
                    transform: 'translateY(-10px)',
                    opacity: '0',
                }),
            ),
            transition('* <=> *', animate('0.2s ease-in')),
        ]),
    ],
})
export class NavBarDropdownComponent implements AfterViewInit, OnDestroy, AfterViewChecked {
    @Input() public items: nav.DropdownItem[];
    @Input() public isVisible: boolean;
    @Input() public focusFirstItem: boolean;
    @Output() public onHide: EventEmitter<void> = new EventEmitter<void>();
    @Output() public onActiveStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChildren('focusable') public focusableElements: QueryList<any>;
    @ViewChild('container', { static: false }) public containerElement: ElementRef;
    @HostBinding('@dropdown') public get dropdownAnimation() {
        return this.isVisible;
    }
    @HostBinding('class.is-interactable')
    public get isClassInteractable(): boolean {
        return this.isVisible;
    }
    public isItemVisible = isItemVisible;
    public isActiveCSSClass = 'is-active';
    public decorationEnum = nav.Decoration;
    public styleEnum = nav.DropdownItemStyle;
    private keyManager: any;
    private readonly transitionHookDestroy: Function;
    private previousIsActiveChildState = false;

    constructor(private focusMonitor: FocusMonitor, private transitionService: TransitionService) {
        this.transitionHookDestroy = this.transitionService.onStart({}, () => {
            this.hideDropdown();
        });
    }

    public ngOnDestroy(): void {
        this.transitionHookDestroy();
    }

    public ngAfterViewInit(): void {
        this.keyManager = new ListKeyManager(this.focusableElements);
    }

    public ngAfterViewChecked(): void {
        // emit an event to onActiveStateChange to notify when children of the dropdown is active or not by watching
        // for the is-active class added by the [uiSrefActiveEq] directive, but only fire if the state has actually
        // changed as ngAfterViewChecked can get called multiple times for various reasons
        const isActiveChildState =
            this.containerElement.nativeElement.className.split(' ').indexOf(this.isActiveCSSClass) !== -1;
        if (this.previousIsActiveChildState !== isActiveChildState) {
            this.previousIsActiveChildState = isActiveChildState;
            this.onActiveStateChange.emit(isActiveChildState);
        }
    }

    @HostListener('@dropdown.done')
    public focusOnFirstItem(): void {
        if (!this.keyManager || !this.focusFirstItem) {
            return;
        }
        this.keyManager.setFirstItemActive();
        if (this.keyManager.activeItem && this.keyManager.activeItem.nativeElement) {
            this.focusMonitor.focusVia(this.keyManager.activeItem.nativeElement, 'keyboard');
        }
    }

    @HostListener('keyup', ['$event'])
    public keyupEventListener(event: KeyboardEvent) {
        if (event.code !== 'Escape' && event.code !== 'Tab') {
            this.keyManager.onKeydown(event);
            if (this.keyManager.activeItem && this.keyManager.activeItem.nativeElement) {
                this.focusMonitor.focusVia(this.keyManager.activeItem.nativeElement, 'keyboard');
            }
        } else {
            this.hideDropdown();
        }
    }

    private hideDropdown(): void {
        this.isVisible = false;
        this.focusFirstItem = false;
        this.onHide.emit();
    }
}
