import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { EnvironmentService } from '../../modules/core/services/environment.service';
import { SuburbsFacade } from '../../store/suburbs/suburbs.facade';
import { SuburbDetails } from '../models/suburb-details.model';
import { simpleStringSearch } from '../utilities/search/search.utilities';

export type FormattedPrettySuburbComponents = ('name' | 'postcode' | 'state')[];

@Injectable()
export class SuburbService {
    constructor(
        private injector: Injector,
        private suburbFacade: SuburbsFacade,
        private environmentService: EnvironmentService,
    ) {}

    public getPropertyStreetViewImage(location, useCoordinates: boolean): string {
        if (!location) return '';

        const baseUrl = 'https://maps.googleapis.com/maps/api/streetview?size=420x300&location=';
        const mapsApiKey = this.environmentService.environmentFromGlobal('googleMapsApiKey');

        if (useCoordinates) {
            // coordinates
            return `${baseUrl}${location.latitude},${location.longitude}&key=${mapsApiKey}`;
        } else {
            // address
            const formattedAddress = location.split(' ').join('+');
            return `${baseUrl}${formattedAddress}&key=${mapsApiKey}`;
        }
    }

    // 'mount-kuring-gai-2080-nsw' -> Mount Kuring-gai 2080 NSW
    public convertSuburbSlugToFormattedPrettySuburb(
        slug: string,
        format: FormattedPrettySuburbComponents = ['name', 'postcode', 'state'],
    ): Observable<string> {
        this.suburbFacade.loadSuburbsIfNeeded();
        return this.suburbFacade.allSuburbsWhenLoaded$.pipe(
            first(),
            map(suburbs => suburbs.find(suburb => suburb.slug === slug)),
            map(suburb => (suburb ? this.reformatSuburbForDisplay(suburb, format.join(' ')) : slug)),
        );
    }

    // '2080_mountkuring-gai' -> Mount Kuring-gai 2080 NSW
    public convertSuburbIdToFormattedPrettySuburb(
        suburbId: string,
        format: FormattedPrettySuburbComponents = ['name', 'postcode', 'state'],
    ): Observable<string> {
        this.suburbFacade.loadSuburbsIfNeeded();
        return this.suburbFacade.allSuburbsWhenLoaded$.pipe(
            first(),
            map(suburbs => suburbs.find(suburb => suburb.id === suburbId)),
            map(suburb => (suburb ? this.reformatSuburbForDisplay(suburb, format.join(' ')) : suburbId)),
        );
    }

    public searchSuburbs(term = '', suburbDetails: SuburbDetails[] = [], limit = 10): SuburbDetails[] {
        if (!term) return [];

        return (
            suburbDetails
                .filter(suburb => simpleStringSearch(suburb.displayName, term))
                // show highest percentage matches first (ie. shortest)
                .sort((a, b) => (a.displayName.length > b.displayName.length ? 1 : -1))
                .slice(0, limit)
        );
    }

    public findSuburbWithSuburbId(suburbId: string): Observable<SuburbDetails> {
        this.suburbFacade.loadSuburbsIfNeeded();
        return this.suburbFacade.allSuburbsWhenLoaded$.pipe(
            first(),
            map(allSuburbs => allSuburbs.find(suburb => suburb.id === suburbId)),
        );
    }

    private reformatSuburbForDisplay(suburb: SuburbDetails, format: string): string {
        const name = suburb.name;
        const state = suburb.governingDistrict;
        const postcode = suburb.postalArea;
        return format.replace('name', name).replace('postcode', postcode).replace('state', state);
    }
}
