import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TranslocoModule } from '@ngneat/transloco';
import { UIRouterModule } from '@uirouter/angular';
import { NgxCurrencyModule } from 'ngx-currency';

import { BrandLogoModule } from '../brand-logo/brand-logo.module';
import { CardModule } from '../card/card.module';
import { LocalisationModule } from '../localisation/localisation.module';
import { LocalityModule } from '../locality/locality.module';
import { SelectListModule } from '../select-list/select-list.module';
import { SharedVendorModule } from '../shared-vendor/shared-vendor.module';

import { CheckInComponent } from './components/check-in/check-in.component';
import { CheckInCardComponent } from './components/check-in-card/check-in-card.component';
import { CheckInConfirmedComponent } from './components/check-in-confirmed/check-in-confirmed.component';
import { FinancePreApprovalDialogComponent } from './components/finance-pre-approval-dialog/finance-pre-approval-dialog.component';
import { NoOpenAvailableComponent } from './components/no-open-available/no-open-available.component';
import { ReturningUserComponent } from './components/returning-user/returning-user.component';
import { selfCheckInStates } from './self-check-in.states';
import { SavedSessionService } from './services/saved-session/saved-session.service';

@NgModule({
    imports: [
        ReactiveFormsModule,
        CommonModule,
        UIRouterModule.forChild({ states: selfCheckInStates }),
        CardModule,
        SharedVendorModule,
        NgxCurrencyModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        LocalisationModule,
        BrandLogoModule,
        TranslocoModule,
        LocalityModule,
        SelectListModule,
    ],
    exports: [CheckInComponent],
    declarations: [
        CheckInComponent,
        FinancePreApprovalDialogComponent,
        CheckInCardComponent,
        CheckInConfirmedComponent,
        NoOpenAvailableComponent,
        ReturningUserComponent,
    ],
    providers: [SavedSessionService],
})
export class SelfCheckInModule {}
