<div class="agent-card u-padding--md u-margin-bottom--xl" *ngIf="agent">
    <up-avatar
        class="agent-card-avatar u-margin-right--md u-display--inline-block"
        [name]="agent.firstName + ' ' + agent.lastName"
        [imgSrc]="agent.photoUrl"
        [size]="'medium'"
    >
    </up-avatar>
    <div>
        <div class="agent-card-header">
            <span class="u-text-heading--rg u-display--inline-block u-margin-right--sm"
                >{{ agent.firstName }} {{ agent.lastName }}</span
            >
            <up-ratemyagent-rating-pill *ngIf="agent.rmaRating" [rating]="agent.rmaRating"></up-ratemyagent-rating-pill>
        </div>
        <div class="u-text-body--sm"> {{ agent.position }} </div>
    </div>
</div>
