<up-container>
    <div class="content-wrapper">
        <up-conversion-step
            class="conversion-step"
            [step]="step"
            [heading]="
                funnelName !== conversionFunnelEnum.Calendar
                    ? 'Pick a date and time'
                    : 'Book your appraisal with ' + (agent$ | async)?.firstName
            "
        >
            <ng-container helpText>
                <ng-container *ngIf="isAppraisalFunnel; else notInAppraisalFunnel">
                    {{ (agent$ | async)?.firstName }}, your local Upside agent, will visit your property to provide you
                    with a professional appraisal.
                </ng-container>
                <ng-template #notInAppraisalFunnel>
                    {{
                        funnelName !== conversionFunnelEnum.Calendar ? 'Your local agent' : (agent$ | async)?.firstName
                    }}
                    will visit your property to provide you with a professional appraisal.
                </ng-template>

                <up-alert class="u-margin-top--md" *ngIf="hasError" [size]="'small'" [type]="'negative'">
                    <ng-container *ngIf="appraisalAlreadyRequested">
                        We noticed that you have already requested an appraisal for this property, we have notified the
                        agent that you are still interested in an appraisal and they should get in contact with you
                        soon.
                        <up-link [upSref]="'my-properties'">Take me to my properties</up-link>
                    </ng-container>
                    <ng-container *ngIf="calendarTimeUnavailable">
                        Sorry, that booking time is unavailable. Please select a different one.
                    </ng-container>
                    <ng-container *ngIf="submissionError"> {{ submissionError }} </ng-container>
                </up-alert>
            </ng-container>
            <ng-container body>
                <div
                    class="u-text-align--center u-margin-top--xl"
                    *ngIf="(dateConfigs$ | async) === undefined || isLoadingAgentProfile"
                >
                    <up-spinner [size]="'large'"></up-spinner>
                </div>
                <div>
                    <div
                        *ngIf="
                            isAppraisalFunnel
                                ? !isLoadingAgentProfile && (dateConfigs$ | async)
                                : (dateConfigs$ | async)
                        "
                    >
                        <div class="u-margin-bottom--xl">
                            <div class="virtual-appraisal u-margin-bottom--md">
                                <nc-media-object>
                                    <div slot="figure">
                                        <nc-icon
                                            class="u-text-color--brand-primary-lighter"
                                            [name]="'cameraFill'"
                                            [color]="'currentColor'"
                                        ></nc-icon>
                                    </div>
                                    <div slot="body" class="u-text-body--sm">
                                        Ask your agent about a virtual appraisal if that's your preferred option
                                    </div>
                                </nc-media-object>
                            </div>

                            <up-appraisal-booker-calendar
                                [dateConfigs]="dateConfigs$ | async"
                                (change)="onDateChange($event)"
                            ></up-appraisal-booker-calendar>
                            <div class="u-hide-visually" role="region" aria-live="polite" *ngIf="activeDate"
                                >Date selected: {{ activeDate.format('dddd DD/MM/YYYY') }}</div
                            >
                            <ng-container *ngIf="activeDate; else loader">
                                <up-appraisal-booker-times
                                    aria-label="Times available"
                                    *ngIf="timeSlots$ | async as timeSlot"
                                    (select)="onSelectTime($event)"
                                    [disabled]="isLoading"
                                    [timezone]="timezone$ | async"
                                    [timeSlots]="timeSlot[activeDateKey]"
                                ></up-appraisal-booker-times>
                            </ng-container>
                            <ng-template #loader>
                                <div class="u-text-align--center u-padding-top--md">
                                    <up-spinner [size]="'large'"></up-spinner>
                                </div>
                            </ng-template>
                        </div>

                        <up-alert
                            class="u-margin-bottom--md"
                            *ngIf="hasAttemptedSubmit && !selectedTime"
                            [size]="'small'"
                            [type]="'negative'"
                        >
                            Please select a time to continue.
                        </up-alert>
                        <up-alert class="u-margin-bottom--md" *ngIf="isError" [size]="'small'" [type]="'negative'">
                            Whoops, an error occurred trying to book your appraisal. Please try again.
                        </up-alert>
                        <up-conversion-step-cta-wrapper>
                            <up-button
                                id="book-appraisal-time-button"
                                (onClick)="next()"
                                [style]="'primary'"
                                [arrowIcon]="true"
                                [loading]="isLoading"
                            >
                                Next
                            </up-button>
                        </up-conversion-step-cta-wrapper>

                        <div class="agent mobile">
                            <ng-container *ngTemplateOutlet="agentProfileCard"></ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </up-conversion-step>
        <div *ngIf="isAppraisalFunnel && (agentProfile || isLoadingAgentProfile)" class="agent desktop">
            <ng-container *ngTemplateOutlet="agentProfileCard"></ng-container>
        </div>
    </div>
</up-container>

<ng-template #agentProfileCard>
    <up-agent-review-card *ngIf="isAppraisalFunnel && agentProfile" [agent]="agentProfile"> </up-agent-review-card>
</ng-template>
