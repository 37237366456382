<up-marketing-hero-section
    class="u-margin-bottom--4xl"
    [imgSrcDesktop]="'/static/images/photography/appraisal-landing-hero-desktop.jpg' | upCdnUrl"
    [imgSrcMobile]="'/static/images/photography/appraisal-landing-hero-mobile.jpg' | upCdnUrl"
>
    <up-content-accent tagline [style]="'heartbeat'">Your Property Appraisal</up-content-accent>
    <h1 heading class="u-text-heading--xl">
        Let's arrange your <span class="hero-heading-break">free appraisal</span>
    </h1>
    <div body>
        <nc-address-finder
            id="hero-address-finder"
            class="u-margin-bottom--lg"
            (addressSelect)="onAddressUpdated($event)"
        ></nc-address-finder>
        <up-alert
            [type]="'negative'"
            [size]="'small'"
            *ngIf="submissionError || appraisalAlreadyRequested"
            class="u-margin-bottom--md"
        >
            <ng-container *ngIf="submissionError"> {{ submissionError }} </ng-container>
            <ng-container *ngIf="appraisalAlreadyRequested">
                We noticed that you have already requested an appraisal for this property, we have notified the agent
                that you are still interested in an appraisal and they should get in contact with you soon.
                <up-link [upSref]="'my-properties'">Take me to my properties.</up-link>
            </ng-container>
        </up-alert>
        <div class="u-text-body--lg hero-content">
            Your local agent will visit your property to give you <br class="hero-tagline-break" />
            a professional, <strong>no-obligation appraisal</strong>.
        </div>

        <div class="virtual-appraisal u-margin-top--xl">
            <nc-icon-container [color]="'brand1Lighter' | upColor : 'cssVar'">
                <nc-icon [name]="'cameraFill'" [color]="'currentColor'"> </nc-icon>
            </nc-icon-container>
            <div class="u-text-weight--semi-bold u-text-body--lg u-margin-left--sm"
                >Virtual Appraisals now available</div
            >
        </div>
    </div>
</up-marketing-hero-section>

<up-container>
    <section class="u-margin-bottom--6xl-r">
        <up-grid>
            <up-grid-col [col]="12" [col@sm]="5">
                <div class="intro-wrapper">
                    <h2 class="u-text-heading--section u-margin-bottom--lg intro-heading"
                        >What do you get from an Upside appraisal?</h2
                    >
                    <div class="u-text-body--rg u-margin-bottom--lg">
                        One of the first steps to selling a house is finding out what your property is worth through a
                        professional property appraisal. One of our experienced local agents will visit your property
                        and using recent market activity will provide their view of a possible selling price. They'll
                        also walk you through the Upside way of selling.
                    </div>
                    <up-button
                        [arrowIcon]="true"
                        [href]="
                            'https://upside.com.au/articles/selling-your-property/preparing-to-sell/prepare-for-property-appraisal'
                        "
                        [target]="'_blank'"
                    >
                        <div class="cta-text tablet"> How to prepare for property appraisal </div>
                        <div class="cta-text mobile"> How to prepare </div>
                    </up-button>
                </div>
            </up-grid-col>
            <up-grid-col [col]="12" [col@sm]="7">
                <up-grid>
                    <up-grid-col class="appraisal-feature" [col]="12" [col@xs]="6">
                        <up-media-object class="feature" [stacked]="'responsive'">
                            <img
                                figure
                                width="84"
                                height="56"
                                alt=""
                                [lazyLoad]="'/static/images/conversion/appraisal-landing/market.svg' | upCdnUrl"
                            />
                            <div body>
                                <h3 class="u-text-heading--xs u-margin-bottom--md">Market activity</h3> Our agent will
                                walk you through some recent comparable sales for a full understanding of the local
                                market.
                            </div>
                        </up-media-object>
                    </up-grid-col>
                    <up-grid-col class="appraisal-feature" [col]="12" [col@xs]="6">
                        <up-media-object class="feature" [stacked]="'responsive'">
                            <img
                                figure
                                width="84"
                                height="56"
                                alt=""
                                [lazyLoad]="'/static/images/conversion/appraisal-landing/appraisal.svg' | upCdnUrl"
                            />
                            <div body>
                                <h3 class="u-text-heading--xs u-margin-bottom--md">Professional appraisal</h3> Using
                                their knowledge of the local market, our agent will provide you with a detailed
                                appraisal of your property.
                            </div>
                        </up-media-object>
                    </up-grid-col>
                    <up-grid-col class="appraisal-feature" [col]="12" [col@xs]="6">
                        <up-media-object class="feature" [stacked]="'responsive'">
                            <img
                                figure
                                width="84"
                                height="56"
                                alt=""
                                [lazyLoad]="'/static/images/conversion/appraisal-landing/upside-agent.svg' | upCdnUrl"
                            />
                            <div body>
                                <h3 class="u-text-heading--xs u-margin-bottom--md">The Upside way</h3> Every property is
                                unique, so your local Upside agent will use their experience and expertise in the local
                                market to provide you with the best strategy to achieve the results you’re looking for.
                            </div>
                        </up-media-object>
                    </up-grid-col>
                    <up-grid-col class="appraisal-feature" [col]="12" [col@xs]="6">
                        <up-media-object class="feature" [stacked]="'responsive'">
                            <img
                                figure
                                width="84"
                                height="56"
                                alt=""
                                [lazyLoad]="'/static/images/conversion/appraisal-landing/hidden-costs.svg' | upCdnUrl"
                            />
                            <div body>
                                <h3 class="u-text-heading--xs u-margin-bottom--md">No obligation</h3> This is all
                                provided for free, there's no obligation to sell your property with Upside.
                            </div>
                        </up-media-object>
                    </up-grid-col>
                </up-grid>
            </up-grid-col>
        </up-grid>
    </section>
</up-container>
