import { isPlatformServer } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    PLATFORM_ID,
    QueryList,
} from '@angular/core';
import { Autoplay, Breakpoints, Controls, Keyboard, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import { v4 as uuid } from 'uuid';

import {
    Glide,
    GlideFactory,
    GlideFactoryToken,
    GlideModule,
    GlideOptions,
} from '../../injection-tokens/glide.injection-token';

import { CarouselSlideDirective } from './carousel-slide/carousel-slide.directive';

type SlideVerticalAlignment = 'top' | 'center' | 'bottom';

@Component({
    selector: 'up-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit, AfterViewInit, OnDestroy {
    @ContentChildren(CarouselSlideDirective) public slides: QueryList<CarouselSlideDirective>;
    @Input() public indicators = false;
    @Input() public arrows = false;
    @Input() public slideVerticalAlignment: SlideVerticalAlignment = 'top';
    @Input() public options: GlideOptions = {};
    @Input() public modules: { [key: string]: GlideModule } = {
        Controls,
        Breakpoints,
        Swipe,
        Keyboard,
        Autoplay,
    };
    @Output() public change: EventEmitter<number> = new EventEmitter<number>();
    @Output() public beforeChange = new EventEmitter<number>();
    @Output() public transitioning = new EventEmitter<boolean>();
    @Output() public initialised = new EventEmitter<number>();
    public id = `glide-${uuid().split('-')[0]}`;
    private carousel: Glide;
    private readonly defaultOptions: GlideOptions = {
        rewind: true,
        gap: 16,
        animationDuration: 500,
        autoplay: 5000,
        animationTimingFunc: 'ease-out',
    };

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        @Inject(GlideFactoryToken) private glideFactory: GlideFactory,
    ) {}

    public ngOnInit(): void {
        if (isPlatformServer(this.platformId)) return;
        const options = {
            ...this.defaultOptions,
            ...this.options,
        };
        this.carousel = this.glideFactory(`#${this.id}`, options);
        this.carousel.on(['build.after', 'run.after'], () => this.change.emit(this.carousel.index));
        this.carousel.on(['build.after', 'run'], () => this.beforeChange.emit(this.carousel.index));
        this.carousel.on(['run.before'], () => this.transitioning.emit(true));
        this.carousel.on(['run.after'], () => this.transitioning.emit(false));
        this.carousel.on(['build.after'], () => this.initialised.emit(this.carousel.index));
    }

    public ngAfterViewInit(): void {
        if (!this.carousel) return;
        this.carousel.mount(this.modules);
    }

    public goTo(index: number): void {
        if (!this.carousel) return;
        this.carousel.go(`=${index}`);
    }

    public ngOnDestroy(): void {
        if (!this.carousel) return;
        this.carousel.destroy();
    }
}
