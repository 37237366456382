<nc-modal-dialog-v2 [heading]="'Finance pre-approval'" [size]="'x-small'">
    <nc-modal-content>
        <p>
            Having finance pre approval means you’ve gotten the thumbs up from your bank and you’re ready to make an
            offer.
        </p>

        <h3 class="u-text-body--rg u-text-weight--bold"> Why do we ask this question? </h3>
        <p>
            This information is used for registering your interest in this property and helping you with future property
            matches based on your financial requirements.
        </p>
    </nc-modal-content>
</nc-modal-dialog-v2>
