import { Component, HostBinding, Input } from '@angular/core';

type Size = 'small' | 'medium' | 'large';

/** @Deprecated - use EmptyStateV2Component **/
@Component({
    selector: 'nc-empty-state, up-empty-state',
    templateUrl: 'empty-state.component.html',
    styleUrls: ['empty-state.component.scss'],
})
export class EmptyStateComponent {
    @Input() public border: boolean;
    @Input() public size: Size = 'medium';
    @Input() public heading: string;

    @HostBinding('class.is-large')
    public get isLarge(): boolean {
        return this.size === 'large';
    }

    @HostBinding('class.is-medium')
    public get isMedium(): boolean {
        return this.size === 'medium';
    }

    @HostBinding('class.is-small')
    public get isSmall(): boolean {
        return this.size === 'small';
    }

    @HostBinding('class.is-bordered')
    public get isBordered(): boolean {
        return this.border;
    }
}
