import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { MediaTagResource } from '../../../../modules/core/resources/media-tag.resource';
import { UseCase } from '../../../models/core/use-case.model';
import { MediaTag } from '../../../models/domain/media/media-tag.model';

type Output = MediaTag.Model[];

@Injectable({ providedIn: 'root' })
export class GetMediaTagsUseCase implements UseCase<Output> {
    constructor(private readonly mediaTagResource: MediaTagResource) {}

    public execute(): Observable<Output> {
        return this.mediaTagResource.getAll().pipe(map(({ mediaTags }) => mediaTags.map(MediaTag.fromDto)));
    }
}
