import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiSuccessResponse } from '../../../common/models/common.model';
import { experimentsApi } from '../../../common/models/experiments-api.model';

const EXPERIMENTS_BASE_URL = `/api/v2/experiments`;
const EXPERIMENT_BASE_URL = `/api/v2/experiment`;
const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

@Injectable()
export class ExperimentsResource {
    constructor(private http: HttpClient) {}

    public getExperiments(): Observable<experimentsApi.experiments.get.Response> {
        return this.http.get<experimentsApi.experiments.get.Response>(EXPERIMENTS_BASE_URL, {});
    }

    public participate(data: experimentsApi.experiments.post.Request): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(`${EXPERIMENT_BASE_URL}/participate`, data);
    }

    public userParticipated(userToken: string): Observable<experimentsApi.userParticipated.post.Response> {
        return this.http.get<experimentsApi.userParticipated.post.Response>(
            `${EXPERIMENTS_BASE_URL}/user-participated?token=${userToken}`,
            HEADERS_CONFIG,
        );
    }
}
