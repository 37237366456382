import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { NavigationService } from '../navigation.service';

@Component({
    selector: 'up-nav',
    templateUrl: 'nav.component.html',
    styleUrls: ['nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent implements OnInit, OnDestroy {
    constructor(public elementRef: ElementRef<HTMLElement>, private navigationService: NavigationService) {}

    public ngOnInit(): void {
        this.navigationService.setActiveNavComponent(this);
    }

    public ngOnDestroy(): void {
        this.navigationService.setActiveNavComponent(undefined);
    }
}
