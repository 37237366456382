import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { LinkComponent } from './link.component';

@NgModule({
    imports: [CommonModule, UIRouterModule],
    exports: [LinkComponent],
    declarations: [LinkComponent],
})
export class LinkModule {}
