import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

/* @Deprecated - use an <img> tag with `object-fit` instead */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[backImg], [backgroundImage]',
})
export class BackgroundImageDirective implements OnChanges {
    // Deprecated input, use backgroundImage instead
    @Input() public backImg: string;
    @Input() public backgroundImage: string;
    @Input('backgroundImageSize') public backgroundSize: string;
    @Input('backgroundImagePosition') public backgroundPosition: string;

    constructor(private el: ElementRef, private renderer: Renderer2) {}

    public ngOnChanges() {
        // Don't try to add any styles if there is no image
        if (!this.backgroundImage && !this.backImg) {
            return;
        }

        const backgroundCss = 'url(\'' + (this.backgroundImage || this.backImg) + '\')';
        this.renderer.setStyle(this.el.nativeElement, 'background-image', backgroundCss);
        if (this.backgroundPosition !== 'none') {
            this.renderer.setStyle(this.el.nativeElement, 'background-position', this.backgroundPosition || 'center');
        }
        if (this.backgroundSize !== 'none') {
            this.renderer.setStyle(this.el.nativeElement, 'background-size', this.backgroundSize || 'cover');
        }
    }
}
