import { Component, Input } from '@angular/core';

import { nav } from '../../../../common/models/nav.model';

@Component({
    selector: 'up-nav-bar-user',
    templateUrl: './nav-bar-user.component.html',
    styleUrls: ['./nav-bar-user.component.scss'],
})
export class NavBarUserComponent {
    @Input() public items: nav.Item[];
    @Input() public authData: any;
    @Input() public currentState: string;
    public focusOnFirstItemInDropdown: boolean;
    public isDropdownVisible: boolean;

    public showUserDropdown(focusOnFirstItem?: boolean): void {
        this.focusOnFirstItemInDropdown = focusOnFirstItem;
        this.isDropdownVisible = true;
    }

    public hideUserDropdown(): void {
        this.focusOnFirstItemInDropdown = false;
        this.isDropdownVisible = false;
    }
}
