<div *ngIf="ratemyagentRating$ | async as ratemyagentRating" class="item u-margin-bottom--sm">
    <up-rma-rating class="graphic" [rating]="ratemyagentRating" [size]="'small'"></up-rma-rating>
    <div>
        <span class="u-display--inline-block"
            ><strong>{{ ratemyagentCount }}+ ratemyagent reviews</strong></span
        >
    </div>
</div>
<div class="item">
    <div class="graphic">
        <up-ratemyagent-rating-pill class="u-margin-right--sm" [rating]="googleReviewsRating">
        </up-ratemyagent-rating-pill>
        <img
            class="google-review-icon"
            alt="Google Reviews logo"
            [src]="'/static/images/logo/logo-google-review.svg' | upCdnUrl"
        />
    </div>
    <div>
        <span class="u-display--inline-block"
            ><strong>{{ googleReviewsCount }}+ Google reviews</strong></span
        >
    </div>
</div>
