<div class="inner u-padding--md">
    <nc-card class="card u-shadow--depth-1">
        <div class="logo-container u-margin-bottom--xl">
            <img
                class="logo"
                [src]="'/static/images/organisations/logos/' + themeName + '-regular.svg' | upCdnUrl"
                [alt]="orgName"
            />
        </div>
        <h1 class="u-text-heading--lg u-margin-bottom--xl">
            <ng-content select="[slot='headingText']"></ng-content>
        </h1>
        <ng-content></ng-content>
    </nc-card>
</div>
