import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { appointmentApi } from '../../../common/models/appointment-api.model';
import { auctionApi } from '../../../common/models/auction-api.model';
import { buyer } from '../../../common/models/buyer.model';
import { ApiSuccessResponse } from '../../../common/models/common.model';
import { conveyancer } from '../../../common/models/conveyancer.model';
import { Domain } from '../../../common/models/domain.model';
import { AppointmentAttendeeResponseDto } from '../../../common/models/dto/appointment/appointment-attendee-response.dto';
import { AppointmentTypeDto } from '../../../common/models/dto/appointment/appointment-type.dto';
import { AppraisalReportDto } from '../../../common/models/dto/appraisal-report/appraisal-report.dto';
import { AddBuyerByUserIdDto } from '../../../common/models/dto/buyer/add-buyer-by-user-id.dto';
import { AddBuyerDto } from '../../../common/models/dto/buyer/add-buyer.dto';
import { BrochureActivityDto } from '../../../common/models/dto/buyer/brochure-activity.dto';
import { BuyerInterestClassificationDto } from '../../../common/models/dto/buyer/buyer-interest-classification.dto';
import { GetBuyersResponseDto } from '../../../common/models/dto/buyer/get-buyers-response.dto';
import { InterestedBuyerWithAggregatedDetailsDto } from '../../../common/models/dto/buyer/interested-buyer-with-aggregated-details.dto';
import { InterestedBuyerDto } from '../../../common/models/dto/buyer/interested-buyer.dto';
import { ListBuyerFiltersQueryDto } from '../../../common/models/dto/buyer/list-buyer-filter-query.dto';
import { NewAttendeeCheckInInspectionResponseDto } from '../../../common/models/dto/buyer/new-attendee-check-in-inspection-response.dto';
import { UpdateBuyerDetailsDto } from '../../../common/models/dto/buyer/update-buyer-details.dto';
import { UpdateContractOfSaleRequestDto } from '../../../common/models/dto/buyer/update-contract-of-sale-request.dto';
import { CampaignReportMetasResponseDto } from '../../../common/models/dto/campaign-report/campaign-report-metas-response.dto';
import { CampaignReportTemplatePreviewResponseDto } from '../../../common/models/dto/campaign-report/campaign-report-template-preview-response.dto';
import { CampaignReportTemplatesResponseDto } from '../../../common/models/dto/campaign-report/campaign-report-templates-response.dto';
import { CampaignReportResponse } from '../../../common/models/dto/campaign-report/campaign-report.dto';
import { UpdateCampaignReportDto } from '../../../common/models/dto/campaign-report/update-campaign-report.dto';
import { ListingCopyWithBriefDto } from '../../../common/models/dto/listing/listing-copy-with-brief.dto';
import { ListingCopyDto } from '../../../common/models/dto/listing/listing-copy.dto';
import { AppraisalExternalUserDto } from '../../../common/models/dto/property-appraisals/appraisal-external-user.dto';
import { AttachMediaDto } from '../../../common/models/dto/property-media/attach-media.dto';
import { UpdateMediaAccessibilityInBrochureDto } from '../../../common/models/dto/property-media/update-media-accessibility-in-brochure.dto';
import { CheckInUserDto } from '../../../common/models/dto/property-ofi/check-in-user.dto';
import { GetPropertyInspectionQueryDto } from '../../../common/models/dto/property-ofi/get-property-inspection-query.dto';
import { PropertyOfiDto } from '../../../common/models/dto/property-ofi/property-ofi.dto';
import { OkDto } from '../../../common/models/dto/response/ok.dto';
import { SimpleUpdateUserRequestDto } from '../../../common/models/dto/user/simple-update-user-request.dto';
import { lead } from '../../../common/models/lead.model';
import { media } from '../../../common/models/media.model';
import { phaseApi } from '../../../common/models/phase-api.model';
import { phase as phaseModel } from '../../../common/models/phase.model';
import { portal as portalModel } from '../../../common/models/portal.model';
import { propertyApi as cleanedPropertyApi } from '../../../common/models/property-api.model';
import { OwnersCorporation, Property, propertyApi } from '../../../common/models/property.model';
import { AppointmentApi } from '../../../common/models/todo.model';
import { stripNilValues } from '../../../common/utilities/data/data.utilities';

const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

let header = new HttpHeaders();
header.set('Content-Type', 'application/json');
header.set('Access-Control-Allow-Origin', '*');

export const BASE_URL = `/api/v2/property`;

@Injectable()
export class PropertyResource {
    constructor(private http: HttpClient) {}

    public get(propertyId: string): Observable<propertyApi.property.get.Response> {
        return this.http.get<propertyApi.property.get.Response>(`${BASE_URL}/${propertyId}`, HEADERS_CONFIG);
    }

    public campaign(propertyId: string): Observable<propertyApi.campaign.get.Response> {
        return this.http.get<propertyApi.campaign.get.Response>(`${BASE_URL}/${propertyId}/campaign`, HEADERS_CONFIG);
    }

    public getCampaignReportTemplates(propertyId: string): Observable<CampaignReportTemplatesResponseDto> {
        return this.http.get<CampaignReportTemplatesResponseDto>(
            `${BASE_URL}/${propertyId}/campaign-report-template`,
            HEADERS_CONFIG,
        );
    }

    public getCampaignReportTemplatePreview(
        propertyId: string,
        reportId: string,
        templateId: string,
    ): Observable<CampaignReportTemplatePreviewResponseDto> {
        return this.http.get<CampaignReportTemplatePreviewResponseDto>(
            `${BASE_URL}/${propertyId}/campaign-report/${reportId}/campaign-report-template/${templateId}`,
            HEADERS_CONFIG,
        );
    }

    public campaignVendorRequestPhotographer(propertyId) {
        return this.http.post(`${BASE_URL}/${propertyId}/campaign-vendor-request-photographer`, null, HEADERS_CONFIG);
    }

    public campaignSupportOrderPhotographer(propertyId: string): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(
            `${BASE_URL}/${propertyId}/campaign-support-order-photographer`,
            null,
            HEADERS_CONFIG,
        );
    }

    public electConveyancerChoice(propertyId, conveyancerDetails) {
        return this.http.post(
            `${BASE_URL}/${propertyId}/contract-of-sale/elect-vendor-choice`,
            conveyancerDetails,
            HEADERS_CONFIG,
        );
    }

    public vendorApproveListing(propertyId) {
        return this.http.post(`${BASE_URL}/${propertyId}/approve-listing`, null, HEADERS_CONFIG);
    }

    public getListing(propertyId) {
        return this.http.get<cleanedPropertyApi.listing.get.Response>(
            `${BASE_URL}/${propertyId}/listing`,
            HEADERS_CONFIG,
        );
    }

    public updateOffer({
        id,
        userId,
        body,
    }: {
        id: string;
        userId: string;
        body: cleanedPropertyApi.updateOffer.post.Request;
    }) {
        return this.http.post<cleanedPropertyApi.updateOffer.post.Response>(
            `${BASE_URL}/${id}/offer/${userId}`,
            body,
            HEADERS_CONFIG,
        );
    }

    public getOffers({ propertyId }): Observable<propertyApi.getOffers.get.Response> {
        return this.http.get<propertyApi.getOffers.get.Response>(`${BASE_URL}/${propertyId}/offers`, HEADERS_CONFIG);
    }

    public getOffer({ propertyId, userId }) {
        return this.http.get<cleanedPropertyApi.getOffer.get.Response>(
            `${BASE_URL}/${propertyId}/offer/${userId}`,
            HEADERS_CONFIG,
        );
    }

    public getForCopywriter(token) {
        return this.http.get<cleanedPropertyApi.Copywriter.Post.Response>(
            `${BASE_URL}/copywriter/?token=${token}`,
            HEADERS_CONFIG,
        );
    }

    public copywriterSubmitListingCopy(token: string, listingCopy: ListingCopyDto): Observable<Property> {
        return this.http.post<Property>(`${BASE_URL}/copywriter/submit-listing-copy?token=${token}`, listingCopy);
    }

    public copywriterSubmitListingCopyByPropertyId(
        propertyId: string,
        listingCopy: ListingCopyDto,
    ): Observable<Property> {
        return this.http.post<Property>(
            `${BASE_URL}/${propertyId}/copywriter/submit-listing-copy-internal`,
            listingCopy,
        );
    }

    public getCopywriterBrief(token: string) {
        return this.http.get<cleanedPropertyApi.copywritingBrief.get.Response>(`${BASE_URL}/copywriter/agent-brief`, {
            ...HEADERS_CONFIG,
            params: { token },
        });
    }

    public attachMedia(propertyId: string, body: AttachMediaDto): Observable<Property> {
        return this.http.post<Property>(`${BASE_URL}/${propertyId}/media/attach`, body);
    }

    public getAllPropertyTodo(
        propertyId: string,
        excludeBuyerReminders = false,
    ): Observable<propertyApi.getAllPropertyTodo.get.Response> {
        return this.http.get<propertyApi.getAllPropertyTodo.get.Response>(`${BASE_URL}/${propertyId}/todo/`, {
            params: {
                excludeBuyer: `${excludeBuyerReminders}`,
            },
        });
    }

    public setAgent({ id, userId }) {
        return this.http.post(`${BASE_URL}/${id}/agent/${userId}/`, {}, HEADERS_CONFIG);
    }

    public getAssignableAgents({ id }): Observable<propertyApi.agents.get.Response> {
        return this.http.get<propertyApi.agents.get.Response>(`${BASE_URL}/${id}/agents`, HEADERS_CONFIG);
    }

    public setAppointment(propertyId: string, appointment: appointmentApi.add.post.Request) {
        return this.http.post<appointmentApi.add.post.Response>(`${BASE_URL}/${propertyId}/appointment/`, appointment);
    }

    public getMyAppointments(from?: string, to?: string): Observable<AppointmentApi.My.GET.Response> {
        let params = new HttpParams();
        if (from) {
            params = params.append('from', from);
        }
        if (to) {
            params = params.append('to', to);
        }
        return this.http.get<AppointmentApi.My.GET.Response>(`/api/v2/appointment/my`, { ...HEADERS_CONFIG, params });
    }

    public getAppointmentsForProperty(propertyId: string): Observable<propertyApi.getAppointments.get.Response> {
        return this.http.get<propertyApi.getAppointments.get.Response>(
            `${BASE_URL}/${propertyId}/appointment/`,
            HEADERS_CONFIG,
        );
    }

    public getAppointmentById(propertyId: string, appointmentId: string) {
        return this.http.get<cleanedPropertyApi.getAppointmentById.get.Response>(
            `${BASE_URL}/${propertyId}/appointment/${appointmentId}/`,
            HEADERS_CONFIG,
        );
    }

    public updateAppointment(
        propertyId: string,
        appointmentId: string,
        body: cleanedPropertyApi.updateAppointment.post.Request,
    ) {
        return this.http.post<cleanedPropertyApi.updateAppointment.post.Response>(
            `${BASE_URL}/${propertyId}/appointment/${appointmentId}/`,
            body,
            HEADERS_CONFIG,
        );
    }

    public cancelAppointment(
        propertyId: string,
        appointmentId: string,
        body: cleanedPropertyApi.cancelAppointment.post.Request,
    ) {
        return this.http.post<cleanedPropertyApi.cancelAppointment.post.Response>(
            `${BASE_URL}/${propertyId}/appointment/${appointmentId}/cancel/`,
            body,
            HEADERS_CONFIG,
        );
    }

    public uploadFilesWithTag(
        propertyId: string,
        tag: string,
        files: media.File[],
    ): Observable<propertyApi.upload.post.Response> {
        return this.http.post<propertyApi.upload.post.Response>(
            `${BASE_URL}/${propertyId}/files/${tag}/upload`,
            { files },
            HEADERS_CONFIG,
        );
    }

    public addFilesWithTag(
        propertyId: string,
        tag: string,
        files: media.File[],
    ): Observable<propertyApi.addFilesWithTag.post.Response> {
        return this.http.post<propertyApi.addFilesWithTag.post.Response>(
            `${BASE_URL}/${propertyId}/files/${tag}/add-upload`,
            { files },
            HEADERS_CONFIG,
        );
    }

    public manualMarketingPayment({ id }) {
        return this.http.post(`${BASE_URL}/${id}/manual-marketing-payment`, HEADERS_CONFIG);
    }

    public setBuyerClassification(
        id: string,
        classification: buyer.Classification | BuyerInterestClassificationDto,
        buyerId: string,
    ) {
        return this.http.post(`${BASE_URL}/${id}/buyers/${buyerId}/category`, { classification }, HEADERS_CONFIG);
    }

    public getBuyerActivity(id: string, userId: string) {
        return this.http.get(`${BASE_URL}/${id}/buyers/${userId}/activity`, HEADERS_CONFIG);
    }

    public setBuyerDetails(id: string, userId: string, body: UpdateBuyerDetailsDto): Observable<OkDto> {
        return this.http.post<OkDto>(`${BASE_URL}/${id}/buyers/${userId}/details`, body, HEADERS_CONFIG);
    }

    public registerBuyer(propertyId: string, body: AddBuyerDto): Observable<InterestedBuyerDto> {
        return this.http.post<InterestedBuyerDto>(`${BASE_URL}/${propertyId}/buyers/`, body, HEADERS_CONFIG);
    }

    public addBuyerByUserId(propertyId: string, body: AddBuyerByUserIdDto): Observable<InterestedBuyerDto> {
        return this.http.post<InterestedBuyerDto>(`${BASE_URL}/${propertyId}/buyers/by-user`, body);
    }

    public listBuyers(propertyId: string, filtersQuery?: ListBuyerFiltersQueryDto): Observable<GetBuyersResponseDto> {
        return this.http
            .get<GetBuyersResponseDto>(`${BASE_URL}/${propertyId}/buyers`, {
                params: stripNilValues({
                    ...filtersQuery,
                    includeOffers: true,
                }),
            })
            .pipe(
                // TODO: Temporary patch to ensure bestOfferAmount is never undefined to retain existing behaviour, should
                // be moved into a use case.
                map(({ buyers }) => {
                    const safeOfferBuyers: InterestedBuyerWithAggregatedDetailsDto[] = buyers.map(buyer => ({
                        ...buyer,
                        bestOfferAmount: buyer.bestOfferAmount || 0,
                    }));

                    return <GetBuyersResponseDto>{ buyers: safeOfferBuyers };
                }),
            );
    }

    public getEnquiry(propertyId: string, enquiryId: string): Observable<InterestedBuyerDto> {
        return this.http.get<InterestedBuyerDto>(`${BASE_URL}/${propertyId}/buyers/${enquiryId}`);
    }

    public getEnquiryBrochureActivity(propertyId: string, enquiryId: string): Observable<BrochureActivityDto> {
        return this.http.get<BrochureActivityDto>(`${BASE_URL}/${propertyId}/buyers/${enquiryId}/brochure-activity`);
    }

    public setPropertyAuthority(id: string, expirationDate: string, signedDate: string, daysExclusive: number) {
        return this.http.post(
            `${BASE_URL}/${id}/authority`,
            {
                expirationDate,
                signedDate,
                daysExclusive,
            },
            HEADERS_CONFIG,
        );
    }

    public setListingAgent(id: string, agentId: string) {
        return this.http.post(`${BASE_URL}/${id}/set-listing-agent/${agentId}`, {}, HEADERS_CONFIG);
    }

    public setManagingAgent(id: string, agentId: string) {
        return this.http.post(`${BASE_URL}/${id}/set-managing-agent/${agentId}`, {}, HEADERS_CONFIG);
    }

    public setSellingAgent(id: string, agentId: string) {
        return this.http.post(`${BASE_URL}/${id}/set-selling-agent/${agentId}`, {}, HEADERS_CONFIG);
    }

    public setSecondaryAgent(propertyId: string, agentId: string): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(
            `${BASE_URL}/${propertyId}/secondary-agent/${agentId}`,
            {},
            HEADERS_CONFIG,
        );
    }

    public deleteSecondaryAgent(propertyId: string): Observable<ApiSuccessResponse> {
        return this.http.delete<ApiSuccessResponse>(`${BASE_URL}/${propertyId}/secondary-agent`, HEADERS_CONFIG);
    }

    public updateProperty(
        id: string,
        body: propertyApi.update.put.Request,
    ): Observable<propertyApi.update.put.Response> {
        return this.http.put<propertyApi.update.put.Response>(`${BASE_URL}/${id}/`, body, HEADERS_CONFIG);
    }

    public updateListing(id: string, listingCopy: ListingCopyWithBriefDto) {
        return this.http.put(`${BASE_URL}/${id}/listing`, listingCopy, HEADERS_CONFIG);
    }

    public getAppraisalReportList(propertyId: string) {
        return this.http.get(`${BASE_URL}/${propertyId}/appraisal-report/`, HEADERS_CONFIG);
    }

    public getLatestAppraisalReport(propertyId: string) {
        return this.http.get<cleanedPropertyApi.latestAppraisalReport.get.Response>(
            `${BASE_URL}/${propertyId}/appraisal-report/latest`,
            HEADERS_CONFIG,
        );
    }

    public createAppraisalReport(propertyId: string): Observable<AppraisalReportDto> {
        return this.http.post<AppraisalReportDto>(`${BASE_URL}/${propertyId}/appraisal-report/create`, HEADERS_CONFIG);
    }

    public getAppraisalReport(propertyId: string, reportId: string) {
        return this.http.get<cleanedPropertyApi.appraisalReport.get.Response>(
            `${BASE_URL}/${propertyId}/appraisal-report/${reportId}/`,
            HEADERS_CONFIG,
        );
    }

    public updateAppraisalReport(propertyId: string, reportId: string, body) {
        return this.http.post(`${BASE_URL}/${propertyId}/appraisal-report/${reportId}/`, body, HEADERS_CONFIG);
    }

    public sendAppraisalReport(propertyId: string, reportId: string, body = {}) {
        return this.http.post(`${BASE_URL}/${propertyId}/appraisal-report/${reportId}/send`, body, HEADERS_CONFIG);
    }

    public viewCurrentAppraisalReport(propertyId: string) {
        return this.http.post(`${BASE_URL}/${propertyId}/appraisal-report/view`, HEADERS_CONFIG);
    }

    public getOpens(propertyId: string, excludePrivate = false, excludePublic = false) {
        return this.http.get<propertyApi.opens.get.Response>(`${BASE_URL}/${propertyId}/opens`, {
            params: {
                excludePrivate: `${excludePrivate}`,
                excludePublic: `${excludePublic}`,
            },
        });
    }

    public getOpen(
        propertyId: string,
        openId: string,
        query?: GetPropertyInspectionQueryDto,
    ): Observable<PropertyOfiDto> {
        return this.http.get<PropertyOfiDto>(`${BASE_URL}/${propertyId}/opens/${openId}`, {
            params: stripNilValues({ ...query }),
        });
    }

    public getAuction(id: string) {
        // returns the full http response, as the status code is needed in the component
        return this.http.get<auctionApi.auction.get.Response>(`${BASE_URL}/${id}/auction`, {
            ...HEADERS_CONFIG,
            observe: 'response',
        });
    }

    /** @Deprecated - use setPhase instead **/
    public setPropertyPhaseInvalid(
        propertyId: string,
        body: phaseApi.invalid.post.Request,
    ): Observable<phaseApi.invalid.post.Response> {
        return this.http.post<phaseApi.invalid.post.Response>(
            `${BASE_URL}/${propertyId}/phase/invalid`,
            body,
            HEADERS_CONFIG,
        );
    }

    /** @Deprecated - use setPhase instead **/
    public setPropertyPhaseAppraisal(propertyId: string) {
        return this.http.post<phaseApi.appraisal.post.Response>(
            `${BASE_URL}/${propertyId}/phase/appraisal`,
            undefined,
            HEADERS_CONFIG,
        );
    }

    /** @Deprecated - use setPhase instead **/
    public setPropertyPhasePipeline(
        propertyId: string,
        body: phaseApi.pipeline.post.Request,
    ): Observable<phaseApi.pipeline.post.Response> {
        return this.http.post<phaseApi.pipeline.post.Response>(
            `${BASE_URL}/${propertyId}/phase/pipeline`,
            body,
            HEADERS_CONFIG,
        );
    }

    /** @Deprecated - use setPhase instead **/
    public setPropertyPhaseLost(
        propertyId: string,
        body: phaseApi.lostAndSold.post.Request,
    ): Observable<phaseApi.lostAndSold.post.Response> {
        return this.http.post<phaseApi.lostAndSold.post.Response>(
            `${BASE_URL}/${[propertyId]}/phase/lostAndSold`,
            body,
            HEADERS_CONFIG,
        );
    }

    /** @Deprecated - use setPhase instead **/
    public setPropertyPhaseWithdrawn(
        propertyId: string,
        body: phaseApi.withdrawn.post.Request,
    ): Observable<phaseApi.withdrawn.post.Response> {
        return this.http.post<phaseApi.withdrawn.post.Response>(
            `${BASE_URL}/${[propertyId]}/phase/withdrawn`,
            body,
            HEADERS_CONFIG,
        );
    }

    /** @Deprecated - use setPhase instead **/
    public setPropertyPhaseProspect(
        propertyId: string,
        body: phaseApi.prospect.post.Request,
    ): Observable<phaseApi.prospect.post.Response> {
        return this.http.post<phaseApi.prospect.post.Response>(
            `${BASE_URL}/${propertyId}/phase/prospect`,
            body,
            HEADERS_CONFIG,
        );
    }

    public setPhase(propertyId: string, body: phaseApi.SetPhase.POST.Request) {
        return this.http.post<phaseApi.SetPhase.POST.Response>(`${BASE_URL}/${propertyId}/phase/set`, body);
    }

    public getOwnersCorporation(id: string): Observable<propertyApi.corporation.get.Response> {
        return this.http.get<propertyApi.corporation.get.Response>(`${BASE_URL}/${id}/corporation`, HEADERS_CONFIG);
    }

    public setOwnersCorporation(
        id: string,
        corporation: OwnersCorporation,
    ): Observable<propertyApi.corporation.post.Response> {
        const request: propertyApi.corporation.post.Request = {
            ...corporation,
            id,
        };

        return this.http.post<propertyApi.corporation.post.Response>(
            `${BASE_URL}/${id}/corporation`,
            request,
            HEADERS_CONFIG,
        );
    }

    public getTenant(id: string): Observable<propertyApi.tenant.get.Response> {
        return this.http.get<propertyApi.tenant.get.Response>(`${BASE_URL}/${id}/tenant`, HEADERS_CONFIG);
    }

    public updateTenant(
        id: string,
        body: propertyApi.tenant.post.Request,
    ): Observable<propertyApi.tenant.post.Response> {
        return this.http.post<propertyApi.tenant.post.Response>(`${BASE_URL}/${id}/tenant`, body, HEADERS_CONFIG);
    }

    public updateDomainData(
        id: string,
        body: Domain.api.domainListingStatus.PUT.Request,
    ): Observable<Domain.api.domainListingStatus.PUT.Response> {
        return this.http.put<Domain.api.domainListingStatus.PUT.Response>(
            `${BASE_URL}/${id}/domain-listing-status`,
            body,
            HEADERS_CONFIG,
        );
    }

    public syncDomainListing(id: string): Observable<Domain.api.syncListing.POST.Response> {
        return this.http.post<Domain.api.syncListing.POST.Response>(
            `${BASE_URL}/${id}/domain/sync-listing`,
            HEADERS_CONFIG,
        );
    }

    public completeSellSupportWelcomeCall(
        propertyId: string,
        body: propertyApi.sellSupportWelcomeCall.post.Request,
    ): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(`${BASE_URL}/${propertyId}/sell-support-welcome-call`, body);
    }

    public campaignSupportOrderSignboard(
        propertyId: string,
    ): Observable<propertyApi.campaignSupportOrderSignboard.post.Response> {
        return this.http.post<propertyApi.campaignSupportOrderSignboard.post.Response>(
            `${BASE_URL}/${propertyId}/campaign-support-order-signboard`,
            <propertyApi.campaignSupportOrderSignboard.post.Request>{ id: propertyId },
        );
    }

    public providedConveyancerNotified(
        propertyId: string,
        body: propertyApi.providedConveyancerNotified.post.Request,
    ): Observable<propertyApi.providedConveyancerNotified.post.Response> {
        return this.http.post<propertyApi.providedConveyancerNotified.post.Response>(
            `${BASE_URL}/${propertyId}/contract-of-sale/provider-notified`,
            body,
            HEADERS_CONFIG,
        );
    }

    public campaignAgentPlanOpensSchedule(
        propertyId: string,
    ): Observable<propertyApi.campaignAgentPlanOpensSchedule.post.Response> {
        return this.http.post<propertyApi.campaignAgentPlanOpensSchedule.post.Response>(
            `${BASE_URL}/${propertyId}/campaign-agent-plan-opens-schedule`,
            {},
            HEADERS_CONFIG,
        );
    }

    public assignAgent(propertyId: string, agentId: string): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(
            `${BASE_URL}/${propertyId}/assign-agent`,
            { agent: agentId },
            HEADERS_CONFIG,
        );
    }

    public rejectAppraisalRequest(
        propertyId: string,
        phase: phaseModel.Phases,
        reason: string,
        invalidReason?: string, // required only if reason is of type Other
    ) {
        const request = { phase, reason, invalidReason };

        return this.http.post(`${BASE_URL}/${propertyId}/reject-appraisal-request`, request, HEADERS_CONFIG);
    }

    public sellVendorProceeding(
        propertyId: string,
        body: propertyApi.sellVendorProceeding.post.SelectCampaign,
    ): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(
            `${BASE_URL}/${propertyId}/sell-vendor-proceeding`,
            body,
            HEADERS_CONFIG,
        );
    }

    public campaignAgentApprovePhotos(propertyId: string): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(
            `${BASE_URL}/${propertyId}/campaign-agent-approve-photos`,
            {},
            HEADERS_CONFIG,
        );
    }

    public campaignSupportUploadPhotos(propertyId: string): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(
            `${BASE_URL}/${propertyId}/campaign-support-upload-photographs`,
            {},
            HEADERS_CONFIG,
        );
    }

    public uploadContractOfSale(propertyId: string): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(
            `${BASE_URL}/${propertyId}/contract-of-sale`,
            { id: propertyId },
            HEADERS_CONFIG,
        );
    }

    public agentAcknowledgeContract(propertyId: string): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(
            `${BASE_URL}/${propertyId}/sell-agent-acknowledge-contract`,
            { id: propertyId },
            HEADERS_CONFIG,
        );
    }

    public campaignAgentRequestCopywriting(propertyId: string) {
        return this.http.post<cleanedPropertyApi.campaignAgentRequestCopywriting.post.Response>(
            `${BASE_URL}/${propertyId}/campaign-agent-request-copywriting`,
            undefined,
            HEADERS_CONFIG,
        );
    }

    public salesManagerReviewAgencyAgreement(
        propertyId: string,
    ): Observable<propertyApi.reviewAgencyAgreement.post.Response> {
        return this.http.post<propertyApi.reviewAgencyAgreement.post.Response>(
            `${BASE_URL}/${propertyId}/sales-manager-review-agency-agreement`,
            {},
            HEADERS_CONFIG,
        );
    }

    public campaignAgentCreateListing(
        propertyId: string,
    ): Observable<propertyApi.campaignAgentCreateListing.post.Response> {
        return this.http.post<propertyApi.campaignAgentCreateListing.post.Response>(
            `${BASE_URL}/${propertyId}/campaign-agent-create-listing`,
            {},
            HEADERS_CONFIG,
        );
    }

    public campaignSupportUploadListing(
        propertyId: string,
    ): Observable<propertyApi.campaignSupportUploadListing.post.Response> {
        return this.http.post<propertyApi.campaignSupportUploadListing.post.Response>(
            `${BASE_URL}/${propertyId}/campaign-support-upload-listing`,
            {},
            HEADERS_CONFIG,
        );
    }

    public putUpSoldStickers(propertyId: string): Observable<propertyApi.putUpSoldStickers.post.Response> {
        return this.http.post<propertyApi.putUpSoldStickers.post.Response>(
            `${BASE_URL}/${propertyId}/put-up-sold-stickers`,
            {},
            HEADERS_CONFIG,
        );
    }

    public settlementFinalInspection(
        propertyId: string,
    ): Observable<propertyApi.completeFinalInspection.post.Response> {
        return this.http.post<propertyApi.completeFinalInspection.post.Response>(
            `${BASE_URL}/${propertyId}/settlement-final-inspection`,
            {},
            HEADERS_CONFIG,
        );
    }

    public settlementExchangeSettled(propertyId: string): Observable<propertyApi.completeSettlement.post.Response> {
        return this.http.post<propertyApi.completeSettlement.post.Response>(
            `${BASE_URL}/${propertyId}/settlement-exchange-settled`,
            {},
            HEADERS_CONFIG,
        );
    }

    public customConveyancerNotified(
        propertyId: string,
        conveyancer: propertyApi.customConveyancerNotified.post.Request,
    ): Observable<propertyApi.customConveyancerNotified.post.Response> {
        return this.http.post<propertyApi.customConveyancerNotified.post.Response>(
            `${BASE_URL}/${propertyId}/contract-of-sale/custom-notified`,
            conveyancer,
            HEADERS_CONFIG,
        );
    }

    public bookAuctioneer(
        propertyId: string,
        body: auctionApi.bookAuctioneer.post.Request,
    ): Observable<auctionApi.bookAuctioneer.post.Response> {
        return this.http.post<auctionApi.bookAuctioneer.post.Response>(
            `${BASE_URL}/${propertyId}/book-auctioneer`,
            body,
            HEADERS_CONFIG,
        );
    }

    public recordAuctionResults(
        propertyId: string,
        body: auctionApi.auctionResult.post.Request,
    ): Observable<auctionApi.auctionResult.post.Response> {
        return this.http.post<auctionApi.auctionResult.post.Response>(
            `${BASE_URL}/${propertyId}/campaign-auction-complete`,
            body,
            HEADERS_CONFIG,
        );
    }

    public convertAuctionListing(propertyId: string): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(
            `${BASE_URL}/${propertyId}/convert-auction-listing`,
            { id: propertyId },
            HEADERS_CONFIG,
        );
    }

    public sellAgentAppraisalVisit(propertyId: string): Observable<propertyApi.sellAgentAppraisalVisit.post.Response> {
        return this.http.post<propertyApi.sellAgentAppraisalVisit.post.Response>(
            `${BASE_URL}/${propertyId}/sell-agent-appraisal-visit`,
            {},
            HEADERS_CONFIG,
        );
    }

    public sellAgentAppraisalAppointment(
        propertyId: string,
        data?: propertyApi.sellAgentAppraisalVisit.post.Request,
    ): Observable<propertyApi.sellAgentAppraisalVisit.post.Response> {
        return this.http.post<propertyApi.sellAgentAppraisalVisit.post.Response>(
            `${BASE_URL}/${propertyId}/sell-agent-appraisal-appointment`,
            data,
            HEADERS_CONFIG,
        );
    }

    public reviewAppraisalRequest(propertyId: string): Observable<propertyApi.reviewAppraisalRequest.post.Response> {
        return this.http.post<propertyApi.reviewAppraisalRequest.post.Response>(
            `${BASE_URL}/${propertyId}/review-appraisal-request`,
            {},
            HEADERS_CONFIG,
        );
    }

    public suburbPromptVendor(
        propertyId: string,
        suburbPrompt: cleanedPropertyApi.suburbPrompt.post.Request,
    ): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(
            `${BASE_URL}/${propertyId}/suburb-support-prompt-vendor`,
            suburbPrompt,
            HEADERS_CONFIG,
        );
    }

    public changeCampaignType(
        propertyId: string,
        campaignType: propertyApi.changeCampaignType.post.Request,
    ): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(
            `${BASE_URL}/${propertyId}/campaign-type/${campaignType}/`,
            {},
            HEADERS_CONFIG,
        );
    }

    public getConveyancerDetails(propertyId: string): Observable<conveyancer.api.conveyancerDetails.get.Response> {
        return this.http.get<conveyancer.api.conveyancerDetails.get.Response>(
            `${BASE_URL}/${propertyId}/conveyancer`,
            HEADERS_CONFIG,
        );
    }

    public updateConveyancerDetails(
        propertyId: string,
        body: conveyancer.api.conveyancerDetails.post.Request,
    ): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(`${BASE_URL}/${propertyId}/conveyancer`, body, HEADERS_CONFIG);
    }

    public setSalesAssistant(propertyId: string, agentId: string): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(
            `${BASE_URL}/${propertyId}/sales-assistant/${agentId}`,
            {},
            HEADERS_CONFIG,
        );
    }

    public reveal(propertyToken: string): Observable<cleanedPropertyApi.reveal.get.Response> {
        return this.http.get<cleanedPropertyApi.reveal.get.Response>(
            `${BASE_URL}/reveal/${propertyToken}`,
            HEADERS_CONFIG,
        );
    }

    /** @Deprecated - use updateLeadScore instead */
    public setLeadScore(propertyId: string, leadScore: lead.Score): Observable<propertyApi.setLeadScore.put.Response> {
        return this.http.put<propertyApi.setLeadScore.put.Response>(
            `${BASE_URL}/${propertyId}/lead-score/${leadScore}/`,
            {},
        );
    }

    public updateLeadScore(propertyId: string, body: cleanedPropertyApi.UpdateLeadScore.POST.Request) {
        return this.http.post<cleanedPropertyApi.UpdateLeadScore.POST.Response>(
            `${BASE_URL}/${propertyId}/lead-score/`,
            body,
        );
    }

    public batchUpdateLeadScore(body: cleanedPropertyApi.BatchUpdateLeadScore.POST.Request) {
        return this.http.post<cleanedPropertyApi.BatchUpdateLeadScore.POST.Response>(`${BASE_URL}/lead-score/`, body);
    }

    public manualCall(propertyId: string): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(`${BASE_URL}/${propertyId}/report/manual-call/`, {});
    }

    public deleteSalesAssistant(propertyId: string): Observable<ApiSuccessResponse> {
        return this.http.delete<ApiSuccessResponse>(`${BASE_URL}/${propertyId}/sales-assistant`);
    }

    // TODO: move to PropertyCampaignReportResource
    public listCampaignReports(propertyId: string): Observable<CampaignReportMetasResponseDto> {
        return this.http.get<CampaignReportMetasResponseDto>(
            `${BASE_URL}/${propertyId}/campaign-report/`,
            HEADERS_CONFIG,
        );
    }

    // TODO: move to PropertyCampaignReportResource
    public getCampaignReport(propertyId: string, reportId: string): Observable<CampaignReportResponse> {
        return this.http.get<CampaignReportResponse>(`${BASE_URL}/${propertyId}/campaign-report/${reportId}/`);
    }

    // TODO: move to PropertyCampaignReportResource
    public updateCampaignReport(propertyId: string, reportId: string, body: UpdateCampaignReportDto) {
        return this.http.post<CampaignReportResponse>(
            `${BASE_URL}/${propertyId}/campaign-report/${reportId}/`,
            body,
            HEADERS_CONFIG,
        );
    }

    // TODO: move to PropertyCampaignReportResource
    public sendCampaignReport(
        propertyId: string,
        reportId: string,
    ): Observable<cleanedPropertyApi.campaignReport.report.send.post.Response> {
        return this.http.post<cleanedPropertyApi.campaignReport.report.send.post.Response>(
            `${BASE_URL}/${propertyId}/campaign-report/${reportId}/send`,
            <cleanedPropertyApi.campaignReport.report.send.post.Request>{},
            HEADERS_CONFIG,
        );
    }

    // TODO: move to PropertyCampaignReportResource
    public viewCampaignReport(
        propertyId: string,
        reportId: string,
    ): Observable<cleanedPropertyApi.campaignReport.report.view.post.Response> {
        return this.http.post<cleanedPropertyApi.campaignReport.report.view.post.Response>(
            `${BASE_URL}/${propertyId}/campaign-report/${reportId}/view`,
            <cleanedPropertyApi.campaignReport.report.view.post.Request>{},
            HEADERS_CONFIG,
        );
    }

    public auctionCancellationReason(
        propertyId: string,
        body: cleanedPropertyApi.auctionCancellationReason.post.Request,
    ): Observable<cleanedPropertyApi.auctionCancellationReason.post.Response> {
        return this.http.post<cleanedPropertyApi.auctionCancellationReason.post.Response>(
            `${BASE_URL}/${propertyId}/auction-cancellation-reason`,
            body,
            HEADERS_CONFIG,
        );
    }

    public auctionCancelled(propertyId: string): Observable<cleanedPropertyApi.auctionCancelled.post.Response> {
        return this.http.post<cleanedPropertyApi.auctionCancelled.post.Response>(
            `${BASE_URL}/${propertyId}/auction-cancelled`,
            null,
            HEADERS_CONFIG,
        );
    }

    public getVendorOpens(propertyId: string): Observable<propertyApi.getVendorOpens.get.Response> {
        return this.http.get<propertyApi.getVendorOpens.get.Response>(
            `${BASE_URL}/${propertyId}/vendor-opens`,
            HEADERS_CONFIG,
        );
    }

    public resetExternalId(propertyId: string): Observable<cleanedPropertyApi.resetExternalId.post.Response> {
        return this.http.post<cleanedPropertyApi.resetExternalId.post.Response>(
            `${BASE_URL}/${propertyId}/reset-external-id`,
            undefined,
            HEADERS_CONFIG,
        );
    }

    public moveAttendee(
        propertyId: string,
        fromOpenId: string,
        toOpenId: string,
        buyerId: string,
    ): Observable<cleanedPropertyApi.moveAttendee.post.Response> {
        return this.http.post<cleanedPropertyApi.moveAttendee.post.Response>(
            `${BASE_URL}/${propertyId}/open/${fromOpenId}/to/${toOpenId}/buyer/${buyerId}/`,
            undefined,
            HEADERS_CONFIG,
        );
    }

    public removeInspectionAttendee(propertyId: string, inspectionId: string, attendeeId: string): Observable<OkDto> {
        return this.http.delete<OkDto>(`${BASE_URL}/${propertyId}/open/${inspectionId}/buyer/${attendeeId}/`);
    }

    public manualAuctionPayment(propertyId: string) {
        return this.http.post<cleanedPropertyApi.manualAuctionPayment.post.Response>(
            `${BASE_URL}/${propertyId}/manual-auction-payment`,
            HEADERS_CONFIG,
        );
    }

    public changeVendor(propertyId: string, toVendorId: string, body?: SimpleUpdateUserRequestDto) {
        return this.http.post<cleanedPropertyApi.changeVendor.post.Response>(
            `${BASE_URL}/${propertyId}/vendor/${toVendorId}/reassign/`,
            body,
            HEADERS_CONFIG,
        );
    }

    public settlementNewVendorAddress(
        propertyId: string,
    ): Observable<cleanedPropertyApi.settlementNewVendorAddress.post.Response> {
        return this.http.post<cleanedPropertyApi.settlementNewVendorAddress.post.Response>(
            `${BASE_URL}/${propertyId}/settlement-new-vendor-address`,
            undefined,
            HEADERS_CONFIG,
        );
    }

    public setSupportAgent(propertyId: string, supportUserId: string) {
        return this.http.post<cleanedPropertyApi.supportAgent.post.Response>(
            `${BASE_URL}/${propertyId}/support-agent/${supportUserId}`,
            undefined,
            HEADERS_CONFIG,
        );
    }

    public suburbStatsForProperty(propertyId: string) {
        return this.http.get<cleanedPropertyApi.suburbStats.get.Response>(
            `${BASE_URL}/${propertyId}/suburb-stats`,
            HEADERS_CONFIG,
        );
    }

    public enablePortalSync(propertyId: string, portal: portalModel.Portal) {
        return this.http.post<cleanedPropertyApi.enablePortalSync.post.Response>(
            `${BASE_URL}/${propertyId}/portal/${portal}/enable`,
            undefined,
            HEADERS_CONFIG,
        );
    }

    public disablePortalSync(propertyId: string, portal: portalModel.Portal) {
        return this.http.post<cleanedPropertyApi.enablePortalSync.post.Response>(
            `${BASE_URL}/${propertyId}/portal/${portal}/disable`,
            undefined,
            HEADERS_CONFIG,
        );
    }

    public relaunchCampaign(propertyId: string) {
        return this.http.post<cleanedPropertyApi.RelaunchCampaign.POST.Response>(
            `${BASE_URL}/${propertyId}/phase/relaunch`,
            undefined,
            HEADERS_CONFIG,
        );
    }

    public interactions(propertyId: string, includeHistory = false) {
        return this.http.get<cleanedPropertyApi.Interactions.GET.Response>(`${BASE_URL}/${propertyId}/interactions`, {
            params: { includeHistory: String(!!includeHistory) },
        });
    }

    public associatedUser(propertyId: string) {
        return this.http.get<cleanedPropertyApi.AssociatedUsers.GET.Response>(
            `${BASE_URL}/${propertyId}/associated-users`,
        );
    }

    public appraisalExternalUser(propertyId: string): Observable<AppraisalExternalUserDto> {
        return this.http.get<AppraisalExternalUserDto>(`${BASE_URL}/${propertyId}/appraisal-external-user`);
    }

    public confirmOFIAttendee(propertyId: string, appointmentId: string, attendeeUserId: string, body: CheckInUserDto) {
        return this.http.post<cleanedPropertyApi.AssociatedUsers.GET.Response>(
            `${BASE_URL}/${propertyId}/appointment/${appointmentId}/attendee/${attendeeUserId}/confirm`,
            body,
        );
    }

    public addOpenNewContactCheckIn(
        propertyId: string,
        openId: string,
        dto: AddBuyerDto,
    ): Observable<NewAttendeeCheckInInspectionResponseDto> {
        return this.http.post<NewAttendeeCheckInInspectionResponseDto>(
            `${BASE_URL}/${propertyId}/open/${openId}/attended`,
            dto,
        );
    }

    public addOpenExistingContactCheckIn(
        propertyId: string,
        openId: string,
        dto: AddBuyerByUserIdDto,
    ): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(`${BASE_URL}/${propertyId}/open/${openId}/attended/user`, dto);
    }

    public updatePropertyMediaAccessibilityInBrochure(
        propertyId: string,
        body: UpdateMediaAccessibilityInBrochureDto,
    ): Observable<Property> {
        return this.http.post<Property>(`${BASE_URL}/${propertyId}/media/brochure`, body);
    }

    public getEnquiryAppointmentAttendance(
        propertyId: string,
        enquiryId: string,
        appointmentTypes?: AppointmentTypeDto[],
    ): Observable<AppointmentAttendeeResponseDto> {
        return this.http.get<AppointmentAttendeeResponseDto>(
            `${BASE_URL}/${propertyId}/buyers/${enquiryId}/attendance`,
            {
                params: stripNilValues({ appointmentTypes }),
            },
        );
    }

    public triggerVaultImport(propertyId: string): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(`${BASE_URL}/${propertyId}/vault/trigger-import`, {});
    }

    public deletePropertyEnquiry(propertyId: string, enquiryId: string): Observable<OkDto> {
        return this.http.delete<OkDto>(`${BASE_URL}/${propertyId}/buyers/${enquiryId}`);
    }

    public updatePropertyEnquiryContractOfSaleRequest(
        propertyId: string,
        enquiryId: string,
        body: UpdateContractOfSaleRequestDto,
    ): Observable<OkDto> {
        return this.http.post<OkDto>(`${BASE_URL}/${propertyId}/buyers/${enquiryId}/contract-of-sale-request`, body);
    }
}
