import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { DataEvent } from '../../../common/directive/tracking.directive';
import { environment } from '../../../common/models/environment.model';
import { AppPlatformService } from '../../core/services/app-platform.service';
import { EnvironmentService } from '../../core/services/environment.service';
import { FeatureService } from '../../core/services/feature.service';
import { ThemeName, ThemeService } from '../../core/services/theme.service';

@Component({
    selector: 'up-nav-bar-logo-link',
    templateUrl: 'nav-bar-logo-link.component.html',
    styleUrls: ['nav-bar-logo-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarLogoLinkComponent {
    @Input() public ncDataEvent: DataEvent;

    public get homeRoute(): string {
        return this.featureService.homeRouteName;
    }

    public get homeUrl(): string {
        return this.featureService.urlConfiguration.homePage;
    }

    public get orgName(): string {
        return this.environmentService.config.organisation.displayName;
    }

    public get themeName(): ThemeName {
        return this.themeService.themeName;
    }

    public get isInternal(): boolean {
        return this.appPlatformService.isInternal;
    }

    // RayWhite requires styling specific to their logo due to its unusual shape
    @HostBinding('class.is-rw')
    public get isRayWhite(): boolean {
        return this.environmentService.currentOrgGrouping === environment.GroupedOrganisationName.RayWhite;
    }

    constructor(
        private featureService: FeatureService,
        private environmentService: EnvironmentService,
        private themeService: ThemeService,
        private appPlatformService: AppPlatformService,
    ) {}
}
