import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

import { CollapserPanelModule } from '../collapser-panel/collapser-panel.module';
import { HiddenContentIndicatorModule } from '../hidden-content-indicator/hidden-content-indicator.module';

import { CardComponent } from './components/card/card.component';
import { CardBodyComponent } from './components/card-body/card-body.component';
import { CardDividerComponent } from './components/card-divider/card-divider.component';
import { CardHeaderComponent } from './components/card-header/card-header.component';
import { CardTabsComponent } from './components/card-tabs/card-tabs.component';
import { CollapsibleCardComponent } from './components/collapsible-card/collapsible-card.component';
import { CardTabDirective } from './directives/card-tab/card-tab.directive';

@NgModule({
    imports: [CommonModule, CollapserPanelModule, HiddenContentIndicatorModule, MatTabsModule],
    declarations: [
        CardComponent,
        CardHeaderComponent,
        CardBodyComponent,
        CardDividerComponent,
        CollapsibleCardComponent,
        CardTabDirective,
        CardTabsComponent,
    ],
    exports: [
        CardComponent,
        CardHeaderComponent,
        CardBodyComponent,
        CardDividerComponent,
        CollapsibleCardComponent,
        CardTabsComponent,
        CardTabDirective,
    ],
})
export class CardModule {}
