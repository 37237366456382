import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, pairwise, takeUntil } from 'rxjs/operators';

import { UP_MODAL_CONTROLS, UP_MODAL_DATA } from '../../../../common/constants/modal-data.constant';
import { modal } from '../../../../common/models/modal.model';
import { UserFacade } from '../../../../store/user/user.facade';
import { NotificationService } from '../../../core/services/notification.service';

export interface UnsubscribeDialogData {
    token?: string;
}

@Component({
    selector: 'up-unsubscribe-dialog',
    templateUrl: 'unsubscribe-dialog.component.html',
    styleUrls: ['unsubscribe-dialog.component.scss'],
})
export class UnsubscribeDialogComponent implements OnInit, OnDestroy {
    public loading$: Observable<boolean>;
    private destroy$ = new Subject<void>();

    constructor(
        @Inject(UP_MODAL_CONTROLS) private modalControls: modal.Controls,
        @Inject(UP_MODAL_DATA) public modalData: UnsubscribeDialogData,
        private userFacade: UserFacade,
        private notificationService: NotificationService,
    ) {}

    public ngOnInit(): void {
        this.loading$ = this.userFacade.notificationsPreferencesState$.pipe(map(s => s.unsubscribingNotifications));
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public unsubscribe(): void {
        this.userFacade.notificationsPreferencesState$
            .pipe(pairwise(), takeUntil(this.destroy$))
            .subscribe(([first, second]) => {
                if (!first.successUnsubscribingNotifications && second.successUnsubscribingNotifications) {
                    this.notificationService.success('Successfully unsubscribed');
                    this.modalControls.close(modal.CloseType.Confirm);
                }
            });

        if (!!this.modalData?.token) {
            this.userFacade.notificationsUnSubscribeWithToken(this.modalData?.token);
        } else {
            this.userFacade.notificationsUnsubscribe();
        }
    }
}
