<up-conversion-step [backRoute]="'^.book-time'" [step]="step" [heading]="'Enter your address'">
    <ng-container helpText> Book your free appraisal with {{ (agent$ | async)?.firstName }} </ng-container>
    <ng-container body>
        <form (ngSubmit)="next()">
            <div>
                <nc-address-finder [hideCta]="true" (addressSelect)="onAddressUpdated($event)"></nc-address-finder>
                <up-alert *ngIf="showHint" class="u-margin-top--sm" [type]="'negative'" [size]="'small'">
                    Please select an address from the list
                </up-alert>
            </div>
            <up-conversion-step-cta-wrapper class="u-margin-top--xl">
                <up-button
                    id="confirm-address-button"
                    (onClick)="next()"
                    [style]="'primary'"
                    [loading]=""
                    [type]="'submit'"
                    [arrowIcon]="true"
                >
                    Next
                </up-button>
            </up-conversion-step-cta-wrapper>
        </form>
    </ng-container>
</up-conversion-step>
