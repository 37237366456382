import { Pipe, PipeTransform } from '@angular/core';

import { Property } from '../../../../common/models/property.model';
import { prettyAreaUnitOfMeasurement } from '../../../../common/utilities/internal-property/internal-property.utilities';

@Pipe({
    name: 'ncFormatPropertyAreaAttribute',
})
export class FormatPropertyAreaAttributePipe implements PipeTransform {
    public transform(areaAttribute?: Property.AreaAttribute): string {
        if (!areaAttribute) return;

        const { value, uom } = areaAttribute;

        // Can safely do a falsy check on the value as we want to treat areas with a value of 0 the same as having no
        // value at all.
        if (!value || !uom) return;

        return `${value}${prettyAreaUnitOfMeasurement(uom)}`;
    }
}
