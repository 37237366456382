import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { WindowRef } from '../../../common/services/window.service';

@Injectable()
export class PerformanceService {
    constructor(@Inject(PLATFORM_ID) private platformId: Object, private windowRef: WindowRef) {}

    public mark(name: string): void {
        if (isPlatformServer(this.platformId) || !this.performance) return;
        this.safeClearMarks(name);
        this.safeMark(name);
    }

    public clearMarks(name?: string): void {
        if (isPlatformServer(this.platformId) || !this.performance) return;
        this.safeClearMarks(name);
    }

    public measure(name: string, startMark?: string, endMark?: string): void {
        if (isPlatformServer(this.platformId) || !this.performance) return;
        // Can fail if start or end mark isn't available
        try {
            this.safeClearMeasures(name);
            this.safeMeasure(name, startMark, endMark);
        } catch (e) {
            // Do nothing, don't break the app just because this wasn't able to measure a time
        }
    }

    public clearMeasure(name?: string): void {
        if (isPlatformServer(this.platformId) || !this.performance) return;
        this.safeClearMeasures(name);
    }

    private get performance(): Performance {
        return this.windowRef.nativeWindow.performance;
    }

    // Methods within the Performance API can be undefined, so add safe methods for them
    private safeMeasure(measureName: string, startMark?: string, endMark?: string): void {
        this.performance.measure && this.performance.measure(measureName, startMark, endMark);
    }

    private safeClearMeasures(measureName?: string): void {
        this.performance.clearMeasures && this.performance.clearMeasures(measureName);
    }

    private safeClearMarks(markName: string): void {
        this.performance.clearMarks && this.performance.clearMarks(markName);
    }

    private safeMark(markName: string): void {
        this.performance.mark && this.performance.mark(markName);
    }
}
