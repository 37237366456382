import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Severity } from '@sentry/types';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ErrorHandlerService } from '../common/services/error-handler.service';
import { UserService, UserT } from '../common/services/user.service';
import { impersonateBaseUrl } from '../modules/core/resources/demonstration.resource';
import {
    FORGOT_PASSWORD_URL,
    RESET_PASSWORD_URL,
    SIGN_IN_GOOGLE_URL,
    SIGN_IN_URL,
    SIGN_OUT_URL,
    SIGN_UP_URL,
    SIGN_UP_WITH_ROLES,
    signInAsConciergeUrlSegment,
    USER_AUTH_URL,
    signInAsUserUrl,
} from '../modules/core/resources/user.resource';
import { AppPlatformService } from '../modules/core/services/app-platform.service';

@Injectable()
export class UserInterceptor implements HttpInterceptor {
    constructor(
        private userService: UserService,
        private stateService: StateService,
        private appPlatformService: AppPlatformService,
        private uiRouterGlobals: UIRouterGlobals,
        private errorHandlerService: ErrorHandlerService,
    ) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((ev: HttpEvent<any>) => {
                if (ev instanceof HttpResponse && ev.url) {
                    const user: UserT = ev.clone().body;

                    if (
                        (ev.url.includes(SIGN_IN_GOOGLE_URL) ||
                            ev.url.includes(SIGN_IN_URL) ||
                            ev.url.includes(USER_AUTH_URL) ||
                            ev.url.includes(SIGN_UP_URL) ||
                            ev.url.includes(RESET_PASSWORD_URL) ||
                            ev.url.includes(impersonateBaseUrl) ||
                            ev.url.includes(signInAsConciergeUrlSegment) ||
                            ev.url.includes(signInAsUserUrl(user?.id))) &&
                        // user endpoint exceptions
                        // Todo: make this better
                        !ev.url.includes(SIGN_UP_WITH_ROLES) &&
                        !ev.url.includes('/api/v2/user/merge')
                    ) {
                        const user: UserT = ev.clone().body;
                        this.userService.setUserAuthDetails(user);
                    } else if (ev.url.includes(SIGN_OUT_URL)) {
                        this.userService.clearCurrentUser();
                    }
                }
            }),
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    if (
                        error.url?.includes(SIGN_IN_GOOGLE_URL) ||
                        error.url?.includes(USER_AUTH_URL) ||
                        error.url?.includes(SIGN_IN_URL) ||
                        error.url?.includes(SIGN_OUT_URL)
                    ) {
                        this.userService.clearCurrentUser();
                    } else if (error.status === 401 && !this.urlIncludesIgnoredRoute(error.url)) {
                        const { name: redirect_to } = this.uiRouterGlobals.current;
                        const stringifiedParams = JSON.stringify(this.uiRouterGlobals.params);
                        const isAlreadyInSignInState = redirect_to.startsWith('user.signin');

                        // If the user is already in a sign in state when this interceptor is triggered then we can just
                        // ignore it. This can happen if multiple requests all fired simultaneously and each resulted
                        // in a separate 401 error response. The first error will handle the redirect, the subsequent
                        // ones can therefore be ignored.
                        if (!isAlreadyInSignInState) {
                            this.errorHandlerService.sendInfo(
                                `Unhandled 401 - sending user to login page: state: ${redirect_to}, URL: ${error.url}, params: ${stringifiedParams}`,
                                Severity.Warning,
                            );

                            if (this.appPlatformService.isInternal) {
                                this.stateService.go('user.signin-internal', {
                                    redirect_to,
                                    redirect_to_params: stringifiedParams,
                                });
                            } else {
                                this.stateService.go('user.signin', {
                                    redirect_to,
                                    redirectParams: stringifiedParams,
                                });
                            }

                            this.userService.clearCurrentUser();
                        }
                    }
                }

                return throwError(error);
            }),
        );
    }

    private urlIncludesIgnoredRoute(url?: string): boolean {
        if (!url) return false;

        const ignoredRoutes = [
            '/user/sms-verify',
            '/user/shadow',
            '/user/notifications',
            '/set-password',
            SIGN_UP_URL,
            RESET_PASSWORD_URL,
            FORGOT_PASSWORD_URL,
        ];

        return ignoredRoutes.some(route => url.includes(route));
    }
}
