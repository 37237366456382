import { Injectable } from '@angular/core';

@Injectable()
export class ListingService {
    public getListingLink(listing: { slug?: string; listingUrl?: string }): string {
        if (!listing) return;

        if (listing.slug) {
            return `/property/${listing.slug}`;
        } else if (listing.listingUrl) {
            return listing.listingUrl;
        }
    }
}
