<div
    class="glide"
    [id]="id"
    [ngClass]="{
        'vertical-align-slide-center': slideVerticalAlignment === 'center',
        'vertical-align-slide-bottom': slideVerticalAlignment === 'bottom'
    }"
>
    <div data-glide-el="controls" *ngIf="arrows">
        <button data-glide-dir="<" class="arrow arrow--left">
            <up-icon
                aria-label="Next image"
                [name]="'chevronLeftBold'"
                [color]="'current-color'"
                [size]="'x-large'"
            ></up-icon>
        </button>
        <button data-glide-dir=">" class="arrow arrow--right">
            <up-icon
                aria-label="Previous image"
                [name]="'chevronRightBold'"
                [color]="'current-color'"
                [size]="'x-large'"
            ></up-icon>
        </button>
    </div>
    <div data-glide-el="track" class="glide__track">
        <ul class="glide__slides">
            <li class="glide__slide" *ngFor="let slide of slides">
                <ng-template [ngTemplateOutlet]="slide.templateRef"></ng-template>
            </li>
        </ul>
    </div>
    <div class="indicators" *ngIf="indicators">
        <up-carousel-indicators
            [activeSlide]="change | async"
            [totalSlides]="slides.length"
            (select)="goTo($event)"
        ></up-carousel-indicators>
    </div>
</div>
