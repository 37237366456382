<div
    class="c-rating"
    [ngClass]="{
        'c-rating--ratemyagent': color === 'ratemyagent',
        'is-small': size === 'small'
    }"
>
    <span class="u-hide-visually">Rated {{ value }} out of {{ totalRating }}</span>
    <div class="c-rating__background" aria-hidden="true">
        <div *ngFor="let rating of backgroundRatings" class="c-rating__icon">
            <up-icon aria-hidden="true" [name]="'starFill'" [size]="size" [color]="'current-color'"></up-icon>
        </div>
    </div>
    <div class="c-rating__foreground" aria-hidden="true">
        <div *ngFor="let rating of foregroundRatings" class="c-rating__icon">
            <up-icon
                *ngIf="rating === 1"
                class="c-rating__icon"
                [name]="'starFill'"
                [size]="size"
                [color]="'current-color'"
            ></up-icon>
            <up-icon
                *ngIf="rating === 0.5"
                class="c-rating__icon"
                [name]="'starHalf'"
                [size]="size"
                [color]="'current-color'"
            ></up-icon>
        </div>
    </div>
</div>
