<div class="calendar">
    <div class="glide">
        <div class="header">
            <div class="range"> {{ monthRangeDisplay }} </div>
            <div class="controls">
                <button class="arrows u-margin-right--sm" aria-hidden="true" (click)="paginate('prev')">
                    <up-icon [name]="'chevronLeftBold'" aria-hidden="true" aria-label="Previous date"></up-icon>
                </button>
                <button class="arrows" aria-hidden="true" (click)="paginate('next')">
                    <up-icon [name]="'chevronRightBold'" aria-hidden="true" aria-label="Next date"></up-icon>
                </button>
            </div>
        </div>
        <div data-glide-el="track" class="u-padding-vertical--xl glide__track">
            <ul class="glide__slides dates" [attr.aria-label]="selectedDate?.format('ddd DD/MM/YY') + ' selected'">
                <li class="glide__slide" *ngFor="let dateConfig of dateConfigs">
                    <up-appraisal-booker-day
                        class="day"
                        (upClick)="selectDate(dateConfig.date)"
                        [date]="dateConfig.date"
                        [active]="isDateActive(dateConfig.date)"
                        [disabled]="dateConfig.isDisabled"
                    >
                    </up-appraisal-booker-day>
                </li>
            </ul>
        </div>
    </div>
</div>
