import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import some from 'lodash-es/some';

import { ErrorHandlerService } from './error-handler.service';
import { WindowRef } from './window.service';

interface BakeCookieConfig {
    name: string;
    value: any;
    stringify?: boolean;
    maxAge?: number;
}

@Injectable()
export class UtmService {
    //TODO: With the addition on gclid, this service is no longer a UtmService and could be merged with TrackingService

    public readonly utmTags: string[] = [
        'utm_medium',
        'utm_campaign',
        'utm_source',
        'utm_content',
        'utm_term',
        'utm_keyword',
        'matchtype',
        'network',
        'device',
        'placement',
    ];

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: Object,
        private ErrorHandler: ErrorHandlerService,
        private windowRef: WindowRef,
    ) {}

    public storeUtmCodes() {
        if (isPlatformBrowser(this.platformId)) {
            var urlParams;
            (this.windowRef.nativeWindow.onpopstate = () => {
                var match,
                    pl = /\+/g, // Regex for replacing addition symbol with a space
                    search = /([^&=]+)=?([^&]*)/g,
                    decode = s => {
                        return decodeURIComponent(s.replace(pl, ' '));
                    },
                    query = this.windowRef.nativeWindow.location.search.substring(1);

                urlParams = {};
                while ((match = search.exec(query))) urlParams[decode(match[1])] = decode(match[2]);

                const hasUtmParams = some(this.utmTags, utmParam => utmParam in urlParams);
                if (hasUtmParams) {
                    this.bakeCookie({
                        name: 'utm_codes',
                        value: {
                            medium: urlParams['utm_medium'],
                            campaign: urlParams['utm_campaign'],
                            source: urlParams['utm_source'],
                            content: urlParams['utm_content'],
                            term: urlParams['utm_term'],
                            keyword: urlParams['utm_keyword'],
                            matchtype: urlParams['matchtype'],
                            network: urlParams['network'],
                            device: urlParams['device'],
                            placement: urlParams['placement'],
                        },
                        stringify: true,
                    });
                }

                if (urlParams['ref']) {
                    // we only want to store the ref cookie for 14 days
                    const refMaxAge = 60 * 60 * 24 * 14;
                    this.bakeCookie({
                        name: 'ref',
                        value: urlParams['ref'],
                        maxAge: refMaxAge,
                    });
                }

                if (urlParams['gclid']) {
                    this.bakeCookie({
                        name: 'gclid',
                        value: urlParams['gclid'],
                    });
                }
            })();
        }
    }

    public getStoredUtmCodes() {
        if (isPlatformBrowser(this.platformId)) {
            const utms = this.readJSONCookie('utm_codes');
            const ref = this.readCookie('ref');
            const gclid = this.readCookie('gclid');

            return { ...utms, ref, gclid };
        }
    }

    /** @deprecated Deprecated in favor of js-cookie (https://www.npmjs.com/package/js-cookie) set,
     *  do not abstract these implementations
     **/
    private bakeCookie(config: BakeCookieConfig): void {
        if (isPlatformBrowser(this.platformId)) {
            const cookie =
                `${config.name}=${config.stringify ? JSON.stringify(config.value) : config.value}` +
                `;domain=${this.windowRef.nativeWindow.location.hostname.toString()}` +
                `${config.maxAge ? `;max-age=${config.maxAge}` : ``}` +
                `;path=/;`;

            this.document.cookie = cookie;
        }
    }

    private getCookieRegExp(name: string): RegExp {
        const escapedName: string = name.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/gi, '\\$1');

        return new RegExp('(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g');
    }

    /** @deprecated Deprecated in favor of js-cookie (https://www.npmjs.com/package/js-cookie) getJSON,
     *  do not abstract these implementations
     **/
    private readJSONCookie(name) {
        if (isPlatformBrowser(this.platformId)) {
            let utm_cookies = '{}';
            //decode and try to get utm_cookies, report error if any.
            try {
                const decodedCookie = decodeURIComponent(this.document.cookie);
                const result = decodedCookie.match(new RegExp(name + '=({.+?})'));

                if (result) utm_cookies = decodeURIComponent(result[1]) || utm_cookies;

                return JSON.parse(utm_cookies);
            } catch (error) {
                this.ErrorHandler.handleError({
                    error,
                    data: utm_cookies,
                });
                return {};
            }
        }
    }

    /** @deprecated Deprecated in favor of js-cookie (https://www.npmjs.com/package/js-cookie) get,
     *  do not abstract these implementations
     **/
    private readCookie(name: string): string {
        if (isPlatformBrowser(this.platformId)) {
            try {
                const regExp: RegExp = this.getCookieRegExp(name);
                const result: RegExpExecArray = regExp.exec(this.document.cookie);

                if (result && result[1]) {
                    return decodeURIComponent(result[1]);
                } else {
                    return '';
                }
            } catch (e) {
                this.ErrorHandler.handleError(e);
                return '';
            }
        }
    }
}
