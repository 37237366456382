import { AfterViewInit, Component, ElementRef, HostBinding, Input, Renderer2, ViewChild } from '@angular/core';

@Component({
    selector: 'nc-card, up-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class CardComponent implements AfterViewInit {
    @Input() public leadingLineColor: string;
    @Input() public appearance: 'neutral' | 'inverse' = 'inverse';
    @ViewChild('leadingLine', { static: false }) public leadingLine: ElementRef;

    @HostBinding('class.is-appearance-neutral')
    public get isAppearanceNeutral(): boolean {
        return this.appearance === 'neutral';
    }

    @HostBinding('class.has-leading-line')
    private get hasLeadingLine(): boolean {
        return !!this.leadingLineColor;
    }

    constructor(private renderer: Renderer2) {}

    public ngAfterViewInit(): void {
        if (this.leadingLineColor) {
            this.renderer.setStyle(this.leadingLine.nativeElement, 'background-color', this.leadingLineColor);
        }
    }
}
