import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiSuccessResponse } from '../../../common/models/common.model';
import { listingsApi } from '../../../common/models/listings-api.model';

const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

@Injectable()
export class ListingResource {
    public BASE_URL = `/api/v2/listing`;

    constructor(private http: HttpClient) {}

    public get(propertySharedToken: string) {
        return this.http.get<listingsApi.listing.get.Response>(
            `${this.BASE_URL}/${propertySharedToken}`,
            HEADERS_CONFIG,
        );
    }

    public getLatestListings() {
        return this.http.get<listingsApi.latest.get.Response>(`${this.BASE_URL}/latest`, HEADERS_CONFIG);
    }

    public pauseListing(listingSharedId: string): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(`${this.BASE_URL}/${listingSharedId}/pause`, {}, HEADERS_CONFIG);
    }
}
