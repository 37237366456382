import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { StateKey, TransferState } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class TransferStateHelperService {
    constructor(@Inject(PLATFORM_ID) private platformId, private transferState: TransferState) {}

    public getFromTransferState<T>(
        stateKey: StateKey<T>,
        fallbackSource: Observable<T>,
        defaultValue?: T,
    ): Observable<T> {
        if (isPlatformBrowser(this.platformId)) {
            const existingTransferState = this.transferState.get<T>(stateKey, defaultValue);
            this.transferState.remove(stateKey);

            return existingTransferState ? of(existingTransferState) : fallbackSource;
        } else {
            return fallbackSource.pipe(tap(value => this.transferState.set(stateKey, value)));
        }
    }
}
