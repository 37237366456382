import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import 'lightgallery.js';

// lightgallery modules must be loaded after the lightgallery
import 'lg-thumbnail.js';

import { ScrollBlockService } from './scroll-block.service';

@Injectable()
export class GalleryService {
    private defaultConfig: LightGallery.Config = {
        addClass: 'lg-theme',
        thumbnail: true,
        dynamic: true,
        showThumbByDefault: true,
        currentPagerPosition: 'middle',
        toggleThumb: false,
        download: false,
    };
    private renderer: Renderer2;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: Object,
        private scrollBlockService: ScrollBlockService,
        private rendererFactory: RendererFactory2,
    ) {
        this.renderer = this.rendererFactory.createRenderer(this.document, null);
    }

    public show(elements: LightGallery.GalleryElement[], config?: LightGallery.Config): void {
        if (!isPlatformBrowser(this.platformId)) return;

        this.blockScroll();

        const container = this.renderer.createElement('div');
        const mergedConfig = { ...this.defaultConfig, ...config };
        // Prevents esc key from closing the thumbnail without any way of opening it again due to toggle button
        // being disabled
        const forceThumbnailVisible = !mergedConfig.toggleThumb && mergedConfig.thumbnail;
        const mergedAddClass = `${this.defaultConfig.addClass} ${forceThumbnailVisible ? 'lg-force-thumb-open' : ''} ${
            config && config.addClass
        }`;

        this.renderer.appendChild(this.document.body, container);
        window.lightGallery(container, {
            ...mergedConfig,
            addClass: mergedAddClass,
            showThumbByDefault: !forceThumbnailVisible,
            dynamicEl: elements,
        });

        this.renderer.listen(container, 'onCloseAfter', () => {
            window.lgData[container.getAttribute('lg-uid')].destroy(true);
            container.remove();
            this.releaseScroll();
        });
    }

    private blockScroll(): void {
        this.scrollBlockService.block();
    }

    private releaseScroll(): void {
        this.scrollBlockService.unblock();
    }
}
