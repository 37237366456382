<nc-card>
    <ng-container *ngIf="loading === false; else loadingPlaceholder">
        <div class="agent">
            <div class="agent-content">
                <div class="u-margin-bottom--xs">
                    <div class="u-text-heading--sm"> {{ firstName }} {{ lastName }} </div>
                    <div *ngIf="agency" class="u-text-body--xs"> {{ agency }} </div>
                </div>
                <div class="u-text-body--xs">
                    <div *ngIf="phoneNumber as phoneNumber">
                        <nc-icon
                            class="contact-detail-icon"
                            [size]="'x-small'"
                            [name]="'phoneFill'"
                            [color]="'currentColor'"
                        ></nc-icon>
                        <a nc-link [href]="'tel:' + phoneNumber">{{ phoneNumber }}</a>
                    </div>
                    <div *ngIf="email as email">
                        <nc-icon
                            class="contact-detail-icon"
                            [size]="'x-small'"
                            [name]="'envelopeFill'"
                            [color]="'currentColor'"
                        ></nc-icon>
                        <a nc-link class="agent-email" [href]="'mailto:' + email">{{ email }}</a>
                    </div>
                </div>
            </div>
            <div class="agent-avatar">
                <img [alt]="firstName + ' ' + lastName" [src]="avatarUrl | imageResize : 120 : 120" />
            </div>
        </div>
    </ng-container>
</nc-card>

<ng-template #loadingPlaceholder>
    <div class="agent">
        <div class="agent-content">
            <up-placeholder class="u-margin-bottom--md" [width]="'120px'" [height]="'24px'"></up-placeholder>
            <up-placeholder class="u-margin-bottom--xs" [width]="'70px'" [height]="'12px'"></up-placeholder>
            <up-placeholder class="u-margin-bottom--xs" [width]="'70px'" [height]="'12px'"></up-placeholder>
        </div>
        <div class="agent-avatar">
            <up-placeholder [width]="'100%'" [height]="'100%'"></up-placeholder>
        </div>
    </div>
</ng-template>
