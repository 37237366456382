import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconName } from '../../../../common/components/icon/icon.component';

interface SocialLink {
    name: string;
    href: string;
    iconName: IconName;
    trackingPrefix?: string;
}

@Component({
    selector: 'nc-upside-social-links',
    templateUrl: 'upside-social-links.component.html',
    styleUrls: ['upside-social-links.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsideSocialLinksComponent {
    @Input() public trackingPrefix: string;

    public get socialLinks(): SocialLink[] {
        return [
            {
                name: 'Facebook',
                href: 'https://www.facebook.com/upsiderealty',
                iconName: 'facebook',
                trackingPrefix: this.trackingPrefix,
            },
            {
                name: 'Instagram',
                href: 'https://www.instagram.com/upsiderealty/',
                iconName: 'instagram',
                trackingPrefix: this.trackingPrefix,
            },
            {
                name: 'LinkedIn',
                href: 'https://au.linkedin.com/company/upside-au',
                iconName: 'linkedin',
                trackingPrefix: this.trackingPrefix,
            },
            {
                name: 'YouTube',
                href: 'https://www.youtube.com/channel/UC5thYGq-_haYBjINqJY5mkA',
                iconName: 'youtube',
                trackingPrefix: this.trackingPrefix,
            },
        ];
    }
}
