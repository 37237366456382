import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RawParams, StateService } from '@uirouter/angular';
import { Observable, of } from 'rxjs';
import { catchError, first, map, mergeAll } from 'rxjs/operators';

import { ApiSuccessResponse } from '../../../../common/models/common.model';
import { conversion } from '../../../../common/models/conversion.model';
import { GeoLocationDto } from '../../../../common/models/dto/location/geo-location.dto';
import { userApi } from '../../../../common/models/user-api.model';
import { User, user as userModel } from '../../../../common/models/user.model';
import { AddressService } from '../../../../common/services/address.service';
import { ErrorHandlerService } from '../../../../common/services/error-handler.service';
import { LocalStorageService } from '../../../../common/services/local-storage.service';
import { TrackingService } from '../../../../common/services/tracking.service';
import { UserService, UserT } from '../../../../common/services/user.service';
import { UtmService } from '../../../../common/services/utm.service';
import { AppState } from '../../../../store/apps-state.model';
import { GetSuburbAgent } from '../../../../store/conversion/conversion.actions';
import { ConversionFacade } from '../../../../store/conversion/conversion.facade';
import { AppraisalState } from '../../../../store/conversion/conversion.reducer';
import { appraisalStateSelector } from '../../../../store/conversion/conversion.selector';
import { propertyReport } from '../../../property-report/property-report.model';
import { PropertyReportResource } from '../../resources/property-report.resource';
import { PropertyResource } from '../../resources/property.resource';
import { UserResource } from '../../resources/user.resource';
import { VendorResource } from '../../resources/vendor.resource';

export enum ConversionRequestStatus {
    ConversionRequestSuccess,
    ConversionRequestFailure,
    ConversionReportDailyRequestLimitExceeded,
    ConversionStaffNotAllowed,
}

export enum ConversionNextState {
    MyProperties,
    Complete,
    AlreadyRequested,
    NoUser,
    SubmissionFailed,
}

interface PropertyReportStateConfig {
    status: ConversionRequestStatus;
    nextState: ConversionNextState;
    propertyId?: string;
    propertyAnswerToken?: string;
    propertyToken?: string;
}

interface ConversionState {
    address?: any;
    ownership?: boolean;
}

@Injectable()
class ConversionService {
    private authData: UserT;
    private storedAddress: any;
    private storedOwnership: boolean;

    constructor(
        private userResource: UserResource,
        private propertyResource: PropertyResource,
        private propertyReportResource: PropertyReportResource,
        private addressService: AddressService,
        private userService: UserService,
        private utmService: UtmService,
        private trackingService: TrackingService,
        private stateService: StateService,
        private store: Store<AppState>,
        private conversionFacade: ConversionFacade,
        private errorHandlerService: ErrorHandlerService,
        private vendorResource: VendorResource,
        private readonly localStorageService: LocalStorageService,
    ) {
        this.userService.userAuthDetailsUpdated$.subscribe(authData => (this.authData = authData));
    }

    public manualCall(propertyAnswerToken: string, propertyId?: string): Observable<ApiSuccessResponse> {
        return propertyId
            ? this.propertyResource.manualCall(propertyId)
            : this.propertyReportResource.manualCallWithPropertyAnswerToken(propertyAnswerToken);
    }

    public propertyReport(
        user: User,
        propertyDetails?: propertyReport.CoreLogicLiveAvmPropertyDetails,
    ): Observable<PropertyReportStateConfig> {
        this.resetConversionState();
        if (!user) {
            return of({
                status: ConversionRequestStatus.ConversionRequestFailure,
                nextState: ConversionNextState.NoUser,
            });
        } else if (this.authData.authenticated && this.authData.roles.includes(userModel.Role.Vendor)) {
            return this.vendorResource.myProperties().pipe(
                map(({ properties }) => {
                    const propertyReportAlreadyRequested = properties.some(property =>
                        this.addressService.equal(property.address, user.address),
                    );
                    if (propertyReportAlreadyRequested) {
                        return of({
                            status: ConversionRequestStatus.ConversionRequestFailure,
                            nextState: ConversionNextState.AlreadyRequested,
                        });
                    } else {
                        return this.requestPropertyReport(user, propertyDetails);
                    }
                }),
                mergeAll(),
                catchError(() => {
                    return of({
                        status: ConversionRequestStatus.ConversionRequestFailure,
                        nextState: ConversionNextState.SubmissionFailed,
                    });
                }),
            );
        } else {
            return this.requestPropertyReport(user, propertyDetails);
        }
    }

    private requestPropertyReport(
        user,
        propertyDetails?: propertyReport.CoreLogicLiveAvmPropertyDetails,
    ): Observable<PropertyReportStateConfig> {
        user.utm = this.utmService.getStoredUtmCodes();
        return this.userResource.requestShadowPropertyReport({ ...user, propertyDetails }).pipe(
            map(response => {
                this.trackingService.trackEvent('propertyReportRequested');
                if (this.authData.authenticated) {
                    const { id } = <userApi.VendorUserReportCreatedResponse>response.body;
                    return {
                        status: ConversionRequestStatus.ConversionRequestSuccess,
                        nextState: ConversionNextState.MyProperties,
                        propertyId: id,
                    };
                } else {
                    const { propertyAnswerToken, propertyToken } = <userApi.ShadowUserReportCreatedResponse>(
                        response.body
                    );
                    return {
                        status: ConversionRequestStatus.ConversionRequestSuccess,
                        nextState: ConversionNextState.Complete,
                        propertyAnswerToken,
                        propertyToken,
                    };
                }
            }),
            catchError(response => {
                let config;
                if (response.status === 503) {
                    config = {
                        status: ConversionRequestStatus.ConversionReportDailyRequestLimitExceeded,
                        nextState: ConversionNextState.SubmissionFailed,
                    };
                } else {
                    const errorCode = this.errorHandlerService.getErrorCode(response);
                    const status =
                        errorCode === 'STAFF_NOT_ALLOWED'
                            ? ConversionRequestStatus.ConversionStaffNotAllowed
                            : ConversionRequestStatus.ConversionRequestFailure;

                    config = {
                        status,
                        nextState: ConversionNextState.SubmissionFailed,
                    };
                }
                return of(config);
            }),
        );
    }

    public storeConversionState(address, ownership?: boolean) {
        this.storedAddress = address;
        this.storedOwnership = ownership;
    }

    public resetConversionState() {
        this.storedAddress = null;
    }

    public getConversionState(): ConversionState {
        return {
            address: this.storedAddress,
            ownership: this.storedOwnership,
        };
    }

    public hasStoredAddress() {
        return this.storedAddress != null;
    }

    // Note: Ideally, this method shouldn't need to take the funnel name as an argument when being called.
    // The current funnel should be stored in the store state, and this method should use that value instead.
    // However, until we move fully towards incorporating the store in the conversion funnel, this method will
    // need to accept the current funnelName to determine where to send the user.
    public goToFunnelEntry(funnelName: conversion.Funnel, params?: RawParams): void {
        this.stateService.go(this.getFunnelEntryRelativeStateName(funnelName), params);
    }

    public routeGuard(guard: boolean) {
        if (!guard) return;
        this.stateService.go('^.address');
    }

    public getAppraisalStoreState(): Observable<AppraisalState> {
        return this.store.select(appraisalStateSelector);
    }

    public getSuburbAgent(suburbId: string, coords: GeoLocationDto): void {
        this.store.dispatch(new GetSuburbAgent({ id: suburbId, coords }));
    }

    public goToReport(propertyId?: string): void {
        if (this.authData.authenticated && propertyId) {
            this.stateService.go('dashboard.property-report', { id: propertyId });
        } else {
            // Make sure to clear the report secret token from local storage to prevent a stale one unintentionally
            // being used. The reportSecretTokenUiRouterHooks hook will handle setting the report secret token in
            // local storage before the navigation if necessary.
            this.localStorageService.removeItem('reportSecretToken');

            this.conversionFacade.reportState$.pipe(first()).subscribe(({ propertyToken }) =>
                this.stateService.go('loggedOutDashboard', {
                    sharedToken: propertyToken,
                }),
            );
        }
    }

    private getFunnelEntryRelativeStateName(funnelName: conversion.Funnel): string {
        return funnelName === conversion.Funnel.Calendar ? '^.book-time' : '^.address';
    }
}

export { ConversionService };
