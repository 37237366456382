import { Injectable } from '@angular/core';

import { HistoryService, HistoryState } from '../../../common/services/history.service';

@Injectable()
export class GetStartedService {
    constructor(private historyService: HistoryService) {}

    public getValidReturnToState(): HistoryState {
        const storedFromState = this.historyService.getFromState();
        const invalidReturnToStates = ['get-started', 'post-ofi'];
        let fromState = { name: 'home' };

        if (
            storedFromState &&
            storedFromState.name &&
            !invalidReturnToStates.some(name => storedFromState.name.includes(name))
        ) {
            fromState = storedFromState;
        }

        return fromState;
    }
}
