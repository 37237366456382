import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { StateService } from '@uirouter/angular';

import { AddressT } from '../../../../common/models/address.model';
import { conversion } from '../../../../common/models/conversion.model';
import { questionnaire } from '../../../../common/models/questionnaire.model';
import { AddressService } from '../../../../common/services/address.service';
import { QuestionnaireResource } from '../../../../modules/core/resources/questionnaire.resource';
import { ConversionService } from '../../../../modules/core/services/conversion/conversion.service';
import { GlobalProgressBarService } from '../../../../modules/core/services/global-progress-bar.service';
import { ConversionFacade } from '../../../../store/conversion/conversion.facade';

@Component({
    selector: 'up-conversion-questionnaire',
    templateUrl: './conversion-questionnaire.component.html',
    styleUrls: ['./conversion-questionnaire.component.scss'],
})
export class ConversionQuestionnaireComponent implements OnInit {
    @Input() public address: AddressT;
    @Input() public propertyAnswerToken: string;
    @Input() public propertyId: string;
    @Input() public funnelName: conversion.Funnel;
    @Input() public step: number;
    public currentQuestion: questionnaire.Question;
    public isLoading = true;
    public isSubmittingAnswer: boolean;
    public hasAttemptedSubmittingAnswer: boolean;
    public optionInput = new FormControl(undefined, [Validators.required]);
    public answeredQuestionCount = 0;
    private questionnaireContext: questionnaire.Context;
    private questionnaireId = 'appraisal-funnel-questionnaire';
    private questionnaireContextId: string;
    private allQuestions: questionnaire.Question[];
    private questions: questionnaire.Question[];

    constructor(
        private stateService: StateService,
        private conversionService: ConversionService,
        private addressService: AddressService,
        private conversionFacade: ConversionFacade,
        private questionnaireResource: QuestionnaireResource,
        private globalProgressBarService: GlobalProgressBarService,
    ) {}

    public ngOnInit(): void {
        if (!this.address || !(this.propertyAnswerToken || this.propertyId)) {
            this.conversionService.goToFunnelEntry(this.funnelName);
            return;
        }
        this.questionnaireContext = this.propertyAnswerToken
            ? questionnaire.Context.propertyShadow
            : questionnaire.Context.property;
        this.questionnaireContextId = this.propertyAnswerToken || this.propertyId;
        const suburbId = this.addressService.createSuburbIdFromAddress(this.address);
        this.conversionFacade.getReportSuburb(suburbId);

        this.questionnaireResource.getQuestionnaire(this.questionnaireId).subscribe(response => {
            this.allQuestions = response.questions;
            this.questions = this.allQuestions;
            this.showNextQuestion();
            this.isLoading = false;
        });
    }

    public submitAnswer(): void {
        this.hasAttemptedSubmittingAnswer = true;
        if (this.optionInput.invalid) return;
        this.isSubmittingAnswer = true;
        this.optionInput.disable();
        this.questionnaireResource
            .saveQuestionnaireAnswerForContext({
                questionnaireId: this.questionnaireId,
                context: this.questionnaireContext,
                contextId: this.questionnaireContextId,
                questionId: this.currentQuestion.id,
                answer: this.optionInput.value,
            })
            .subscribe(
                () => {
                    this.answeredQuestionCount++;
                    this.hasAttemptedSubmittingAnswer = false;
                    this.handleUpcomingQuestion();

                    if (!this.questions.length) {
                        // If on last answer, do not set isSubmittingAnswer to false so the user cannot continue to
                        // interact with the questionnaire in case onComplete performs some async tasks
                        this.onComplete();
                    } else {
                        this.isSubmittingAnswer = false;
                        this.optionInput.enable();
                        this.showNextQuestion();
                        // Fake the progress bar due to questions being dynamic, increase the bar by 10% of its current value
                        // so it never gets to 100% just from the questionnaire.
                        const currentProgress = this.globalProgressBarService.currentValue;
                        this.globalProgressBarService.updateProgress(currentProgress + currentProgress * 0.1);
                    }
                },
                () => {
                    this.isSubmittingAnswer = false;
                    this.optionInput.enable();
                },
            );
    }

    private showNextQuestion(): void {
        this.currentQuestion = this.questions[0];
        // Remove current question from stack
        this.questions = this.questions.slice(1);
        this.optionInput.reset('', { emitEvent: false });
    }

    private handleUpcomingQuestion(): void {
        const answer = this.optionInput.value;

        if (this.currentQuestion.id === 'property-relationship-question') {
            if (answer === 'Buyer' || answer === 'Tenant') {
                this.questions = this.questions.filter(q => q.id !== 'selling-timeline-question');
            }

            if (answer !== 'Buyer') {
                this.questions = this.questions.filter(q => q.id !== 'selling-before-purchasing-question');
            }
        }
    }

    private onComplete(): void {
        if (this.funnelName === conversion.Funnel.PropertyReport) {
            this.conversionService.goToReport(this.propertyId);
        } else {
            this.stateService.go('^.requested', {
                address: this.address,
                propertyId: this.propertyId,
            });
        }
    }
}
