import { Transition } from '@uirouter/angular';

import { getStandardUpsideMetaDescription } from '../../../common/utilities/meta.utilities';
import { FooterMegaComponent } from '../../footer/footer-mega/footer-mega.component';
import { NavMinimalComponent } from '../../nav/nav-minimal/nav-minimal.component';
import { NoopComponent } from '../../noop/noop.component';
import { AppraisalRequestedComponent } from '../appraisal/appraisal-requested/appraisal-requested.component';
import { AppraisalBookerComponent } from '../common/appraisal-booker/appraisal-booker.component';
import { AppraisalLandingComponent } from '../common/appraisal-landing/appraisal-landing.component';
import { ConversionAddressFoundComponent } from '../common/conversion-address-found/conversion-address-found.component';
import { ConversionCaptureDetailsComponent } from '../common/conversion-capture-details/conversion-capture-details.component';
import { ConversionCapturePhoneNumberComponent } from '../common/conversion-capture-phone-number/conversion-capture-phone-number.component';
import { ConversionCompleteComponent } from '../common/conversion-complete/conversion-complete.component';
import { ConversionQuestionnaireComponent } from '../common/conversion-questionnaire/conversion-questionnaire.component';
import {
    addressResolveFn,
    propertyAnswerTokenResolveFn,
    propertyIdResolveFn,
    serviceStatusResolveFn,
    stepResolveFn,
    submissionErrorResolveFn,
    userResolveFn,
} from '../common/resolvers';
import { NcConversionFunnelStateDeclaration } from '../conversion.states';

import { BookingFunnelComponent } from './booking-funnel.component';

const totalSteps = 5;

export function funnelNameResolveFn() {
    return 'booking';
}

export function appraisalRequestedResolveFn(trans) {
    return trans.params().appraisalAlreadyRequested;
}

export function calendarTimeUnavailableResolveFn(transition: Transition): boolean {
    return transition.params().calendarTimeUnavailable;
}

export const bookingFunnelStates: NcConversionFunnelStateDeclaration[] = [
    {
        name: 'conversion.booking',
        url: '/booking',
        views: {
            conversion: {
                component: BookingFunnelComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
        redirectTo: 'conversion.booking.address',
        data: {
            meta: {
                title: 'Free Property Market Appraisal',
                description: getStandardUpsideMetaDescription(),
            },
        },
    },
    {
        name: 'conversion.booking.address',
        url: '/address',
        views: {
            booking: {
                component: AppraisalLandingComponent,
            },
        },
        params: {
            submissionError: '',
            appraisalAlreadyRequested: false,
        },
        resolve: [
            {
                token: 'submissionError',
                deps: [Transition],
                resolveFn: submissionErrorResolveFn,
            },
            {
                token: 'appraisalAlreadyRequested',
                deps: [Transition],
                resolveFn: appraisalRequestedResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
        ],
        data: {
            navConfig: {
                hideCta: true,
            },
        },
    },
    {
        name: 'conversion.booking.address-found',
        url: '/address-found',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            booking: {
                component: ConversionAddressFoundComponent,
            },
        },
        params: {
            address: undefined,
            step: 1,
        },
        resolve: [
            {
                token: 'address',
                deps: [Transition],
                resolveFn: addressResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
        ],
        data: {
            globalProgress: 1 / totalSteps,
        },
    },
    {
        name: 'conversion.booking.book-time',
        url: '/book-time',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            booking: {
                component: AppraisalBookerComponent,
            },
        },
        params: {
            address: undefined,
            step: 2,
            calendarTimeUnavailable: false,
        },
        resolve: [
            {
                token: 'address',
                deps: [Transition],
                resolveFn: addressResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
            {
                token: 'calendarTimeUnavailable',
                deps: [Transition],
                resolveFn: calendarTimeUnavailableResolveFn,
            },
        ],
        data: {
            globalProgress: 2 / totalSteps,
        },
    },
    {
        name: 'conversion.booking.capture-details',
        url: '/details',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            booking: {
                component: ConversionCaptureDetailsComponent,
            },
        },
        params: {
            user: undefined,
            address: undefined,
            step: 3,
        },
        resolve: [
            {
                token: 'user',
                deps: [Transition],
                resolveFn: userResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
            {
                token: 'address',
                deps: [Transition],
                resolveFn: addressResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
        ],
        data: {
            globalProgress: 3 / totalSteps,
        },
    },
    {
        name: 'conversion.booking.phone',
        url: '/contact',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            booking: {
                component: ConversionCapturePhoneNumberComponent,
            },
        },
        params: {
            user: undefined,
            step: 3,
        },
        resolve: [
            {
                token: 'user',
                deps: [Transition],
                resolveFn: userResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
        ],
        data: {
            globalProgress: 3 / totalSteps,
        },
    },
    {
        name: 'conversion.booking.complete',
        url: '/complete',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            booking: {
                component: ConversionCompleteComponent,
            },
        },
        params: {
            user: undefined,
            showCompleteState: false,
            serviceStatus: undefined,
        },
        resolve: [
            {
                token: 'user',
                deps: [Transition],
                resolveFn: userResolveFn,
            },
            {
                token: 'serviceStatus',
                deps: [Transition],
                resolveFn: serviceStatusResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
        ],
        data: {
            globalProgress: 4 / totalSteps,
        },
    },
    {
        name: 'conversion.booking.questionnaire',
        url: '/questionnaire',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            booking: {
                component: ConversionQuestionnaireComponent,
            },
        },
        params: {
            address: undefined,
            propertyAnswerToken: undefined,
            propertyId: undefined,
            step: 4,
        },
        resolve: [
            {
                token: 'address',
                deps: [Transition],
                resolveFn: addressResolveFn,
            },
            {
                token: 'propertyAnswerToken',
                deps: [Transition],
                resolveFn: propertyAnswerTokenResolveFn,
            },
            {
                token: 'propertyId',
                deps: [Transition],
                resolveFn: propertyIdResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
        ],
        data: {
            globalProgress: 4 / totalSteps,
        },
    },
    {
        name: 'conversion.booking.requested',
        url: '/requested',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            booking: {
                component: AppraisalRequestedComponent,
            },
            'footer@vendor-app': {
                component: FooterMegaComponent,
            },
        },
        params: {
            propertyId: undefined,
            address: undefined,
        },
        resolve: [
            {
                token: 'propertyId',
                deps: [Transition],
                resolveFn: propertyIdResolveFn,
            },
            {
                token: 'address',
                deps: [Transition],
                resolveFn: addressResolveFn,
            },
        ],
        data: {
            globalProgress: 5 / totalSteps,
        },
    },
];
