import { Component, ElementRef, HostBinding, Input, OnChanges, OnInit, Renderer2 } from '@angular/core';
import contrast from 'contrast';

export type Size = 'x-small' | 'small' | 'large';

enum Color {
    Neutral = 'neutral',
    BrandPrimary = 'brand-primary',
    BrandAccent = 'brand-accent',
    BrandSecondary = 'brand-secondary',
    Positive = 'positive',
    Negative = 'negative',
    RateMyAgent = 'rate-my-agent',
    LeadScoreBlue = 'lead-score-blue',
    LeadScoreRed = 'lead-score-red',
    LeadScoreOrange = 'lead-score-orange',
    LeadScoreUnknown = 'lead-score-unknown',
}

@Component({
    selector: 'up-pill',
    templateUrl: './pill.component.html',
    styleUrls: ['./pill.component.scss'],
})
export class PillComponent implements OnInit, OnChanges {
    @Input() public size: Size;
    @Input() public color: Color | string = Color.BrandPrimary;
    @HostBinding('class.size-x-small') public classSizeXSmall: boolean;
    @HostBinding('class.size-small') public classSizeSmall: boolean;
    @HostBinding('class.size-large') public classSizeLarge: boolean;
    @HostBinding('class.color-neutral') public classColorNeutral: boolean;
    @HostBinding('class.color-brand-primary') public classColorBrandPrimary: boolean;
    @HostBinding('class.color-brand-secondary') public classColorBrandSecondary: boolean;
    @HostBinding('class.color-brand-accent') public classColorBrandAccent: boolean;
    @HostBinding('class.color-positive') public classColorPositive: boolean;
    @HostBinding('class.color-negative') public classColorNegative: boolean;
    @HostBinding('class.color-rate-my-agent') public classColorRateMyAgent: boolean;
    @HostBinding('class.color-lead-score-red') public classColorLeadScoreRed: boolean;
    @HostBinding('class.color-lead-score-blue') public classColorLeadScoreBlue: boolean;
    @HostBinding('class.color-lead-score-orange') public classColorLeadScoreOrange: boolean;
    @HostBinding('class.color-lead-score-unknown') public classColorLeadScoreUnknown: boolean;
    @HostBinding('class.color-text-inverse') public classColorTextInverse: boolean;

    constructor(public renderer: Renderer2, private hostRef: ElementRef) {}

    public ngOnInit(): void {
        this.setPillTheme();
    }

    public ngOnChanges(): void {
        this.setPillTheme();
    }

    private setPillTheme(): void {
        this.classSizeXSmall = this.size === 'x-small';
        this.classSizeSmall = this.size === 'small';
        this.classSizeLarge = this.size === 'large';
        this.classColorNeutral = this.color === Color.Neutral;
        this.classColorBrandPrimary = this.color === Color.BrandPrimary;
        this.classColorBrandSecondary = this.color === Color.BrandSecondary;
        this.classColorBrandAccent = this.color === Color.BrandAccent;
        this.classColorPositive = this.color === Color.Positive;
        this.classColorNegative = this.color === Color.Negative;
        this.classColorRateMyAgent = this.color === Color.RateMyAgent;
        this.classColorLeadScoreRed = this.color === Color.LeadScoreRed;
        this.classColorLeadScoreOrange = this.color === Color.LeadScoreOrange;
        this.classColorLeadScoreBlue = this.color === Color.LeadScoreBlue;
        this.classColorLeadScoreUnknown = this.color === Color.LeadScoreUnknown;

        if (this.color && (this.color[0] === '#' || this.color.startsWith('var(--color-'))) {
            this.renderer.setStyle(this.hostRef.nativeElement, 'background-color', this.color);
            if (contrast(this.color) === 'dark') {
                this.classColorTextInverse = true;
            }
        }
    }
}
