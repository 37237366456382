import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { nav } from '../models/nav.model';

import { UserService } from './user.service';

@Injectable()
/** @deprecated - Do not use, this is part of the old navigation system **/
export class NavService {
    public paneVisibility$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isSticky$: Observable<boolean>;
    public authData: any;
    public _overrideCta$: Observable<nav.BarCta>;
    private _isSticky$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private overrideCtaSubject: Subject<nav.BarCta> = new Subject<nav.BarCta>();

    constructor(private userService: UserService) {
        this._overrideCta$ = this.overrideCtaSubject.asObservable();
        this.userService.userAuthDetailsUpdated$.subscribe(authData => (this.authData = authData));
        this.isSticky$ = this._isSticky$.asObservable();
    }

    public setStickyState(isSticky: boolean): void {
        this._isSticky$.next(isSticky);
    }

    public setPaneVisibility(visibility: boolean): void {
        this.paneVisibility$.next(visibility);
    }
}
