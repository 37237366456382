<up-collapser-panel #panel [initialState]="'expanded'" [disabled]="collapserDisabled">
    <div class="toggle" header>
        <div up-nav-pane-button
            ><up-icon slot="leading" *ngIf="activeIcon" [name]="activeIcon"></up-icon> {{ activeText }}</div
        >
        <up-hidden-content-indicator
            class="indicator"
            [open]="panel.state === 'expanded'"
        ></up-hidden-content-indicator>
    </div>
    <div class="items" content>
        <ng-content></ng-content>
    </div>
</up-collapser-panel>
