import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Moment } from 'moment';

import { AddressT } from '../../common/models/address.model';
import { agentApi } from '../../common/models/agent-api.model';
import { GeoLocationDto } from '../../common/models/dto/location/geo-location.dto';
import { suburbApi } from '../../common/models/suburb-api.model';
import { UserBase } from '../../common/models/user.model';
import { propertyReport } from '../../modules/property-report/property-report.model';

export enum ConversionActionTypes {
    RESET_STATE = 'CONVERSION:RESET_STATE',
    GET_SUBURB_AGENT = 'CONVERSION:GET_SUBURB_AGENT',
    GET_SUBURB_AGENT_SUCCESS = 'CONVERSION:GET_SUBURB_AGENT_SUCCESS',
    GET_SUBURB_AGENT_ERROR = 'CONVERSION:GET_SUBURB_AGENT_ERROR',
    GET_REPORT_SUBURB = '[CONVERSION] GET REPORT SUBURB',
    GET_REPORT_SUBURB_SUCCESS = '[CONVERSION] GET REPORT SUBURB SUCCESS',
    GET_REPORT_SUBURB_ERROR = '[CONVERSION] GET REPORT SUBURB ERROR',
    SET_APPRAISAL_BOOKED_TIME = '[Conversion] Set Appraisal Booked Time',
    SET_USER = 'CONVERSION:SET_USER',
    SET_ADDRESS = '[Conversion] Set Address',
    SET_REPORT_AVM_PROPERTY_DETAILS = '[Conversion] Set AVM Property Details',
    SET_REPORT_PROPERTY_TOKEN = '[Conversion] Set Report Property Token',
    GET_AGENT_CALENDAR_BY_SLUG = '[Conversion] Get Agent Calendar By Slug',
    GET_AGENT_CALENDAR_BY_SLUG_SUCCESS = '[Conversion] Get Agent Calendar By Slug Success',
    GET_AGENT_CALENDAR_BY_SLUG_ERROR = '[Conversion] Get Agent Calendar By Slug Error',
    RESET_USER_ENTERED_STATE = '[Conversion] Reset User Entered State',
}

export class ResetState implements Action {
    public readonly type = ConversionActionTypes.RESET_STATE;
}

export class GetSuburbAgent implements Action {
    public readonly type = ConversionActionTypes.GET_SUBURB_AGENT;

    constructor(public payload: { id: string; coords: GeoLocationDto }) {}
}

export class GetSuburbAgentSuccess implements Action {
    public readonly type = ConversionActionTypes.GET_SUBURB_AGENT_SUCCESS;

    constructor(public payload: suburbApi.suburbAgent.get.Response) {}
}

export class GetSuburbAgentError implements Action {
    public readonly type = ConversionActionTypes.GET_SUBURB_AGENT_ERROR;

    constructor(public payload: HttpErrorResponse) {}
}

export class GetReportSuburb implements Action {
    public readonly type = ConversionActionTypes.GET_REPORT_SUBURB;

    constructor(public payload: string) {}
}

export class GetReportSuburbSuccess implements Action {
    public readonly type = ConversionActionTypes.GET_REPORT_SUBURB_SUCCESS;

    constructor(public payload: suburbApi.suburbById.get.Response) {}
}

export class GetReportSuburbError implements Action {
    public readonly type = ConversionActionTypes.GET_REPORT_SUBURB_ERROR;

    constructor(public payload: HttpErrorResponse) {}
}

export class SetAppraisalBookedTime implements Action {
    public readonly type = ConversionActionTypes.SET_APPRAISAL_BOOKED_TIME;

    constructor(public payload: Moment) {}
}

export class SetUser implements Action {
    public readonly type = ConversionActionTypes.SET_USER;

    constructor(public payload: UserBase) {}
}

export class SetAddress implements Action {
    public readonly type = ConversionActionTypes.SET_ADDRESS;

    constructor(public payload: AddressT) {}
}

export class SetReportAvmPropertyDetails implements Action {
    public readonly type = ConversionActionTypes.SET_REPORT_AVM_PROPERTY_DETAILS;

    constructor(public payload: propertyReport.CoreLogicLiveAvmPropertyDetails) {}
}

export class SetReportPropertyToken implements Action {
    public readonly type = ConversionActionTypes.SET_REPORT_PROPERTY_TOKEN;

    constructor(public payload: string) {}
}

export class GetAgentCalendarBySlug implements Action {
    public readonly type = ConversionActionTypes.GET_AGENT_CALENDAR_BY_SLUG;

    constructor(public payload: string) {}
}

export class GetAgentCalendarBySlugSuccess implements Action {
    public readonly type = ConversionActionTypes.GET_AGENT_CALENDAR_BY_SLUG_SUCCESS;

    constructor(public payload: agentApi.calendarBySlug.get.Response) {}
}

export class GetAgentCalendarBySlugError implements Action {
    public readonly type = ConversionActionTypes.GET_AGENT_CALENDAR_BY_SLUG_ERROR;

    constructor(public payload: HttpErrorResponse) {}
}

export class ResetUserEnteredState implements Action {
    public readonly type = ConversionActionTypes.RESET_USER_ENTERED_STATE;
}

export type ConversionActions =
    | ResetState
    | GetSuburbAgent
    | GetSuburbAgentSuccess
    | GetSuburbAgentError
    | GetReportSuburb
    | GetReportSuburbSuccess
    | GetReportSuburbError
    | SetAppraisalBookedTime
    | SetAddress
    | SetUser
    | SetReportAvmPropertyDetails
    | SetReportPropertyToken
    | GetAgentCalendarBySlug
    | GetAgentCalendarBySlugSuccess
    | GetAgentCalendarBySlugError
    | ResetUserEnteredState;
