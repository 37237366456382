import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BrochureActivityType } from '../../../common/models/brochure-activity-type.model';
import { UseCase } from '../../../common/models/core/use-case.model';
import { BrochureResource } from '../../core/resources/brochure.resource';

export type Result = void;

interface Input {
    token: string;
    type: BrochureActivityType;
    mediaId?: string;
}

@Injectable({ providedIn: 'root' })
export class BrochureActivityTrackingUseCase implements UseCase<Result, Input> {
    constructor(private brochureResource: BrochureResource) {}

    public execute(input: Input): Observable<Result> {
        return this.brochureResource.trackActivity(input.token, {
            type: input.type,
            mediaId: input.mediaId,
        });
    }
}
