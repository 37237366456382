import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { TaskService } from '../../common/services/task.service';
import { AppState } from '../apps-state.model';

import * as PropertyActions from './property.actions';
import { GetProperty, resetStore } from './property.actions';
import { PropertyState } from './property.reducer';
import { propertyStateSelector } from './property.selectors';

@Injectable()
export class PropertyFacade {
    public propertyState$: Observable<PropertyState> = this.store.select(propertyStateSelector);

    constructor(private store: Store<AppState>, private taskService: TaskService) {}

    public resetStore(): void {
        this.store.dispatch(resetStore());
    }

    public loadProperty(propertyId: string): void {
        this.store.dispatch(new PropertyActions.GetAppraisalReport(propertyId));
        this.store.dispatch(new PropertyActions.GetPropertyAppointments(propertyId));
        this.store.dispatch(new GetProperty(propertyId));
        this.taskService.loadTasksIntoStore(propertyId);
    }
}
