import { Injector } from '@angular/core';
import { UIRouter } from '@uirouter/angular';

import { ZendeskChatVisibilityService } from '../../modules/zendesk/services/zendesk-chat-visibility.service';

export const zendeskVisibilityUiRouterHooks = (router: UIRouter, injector: Injector) => {
    // Handles selectively showing and hiding Zendesk Chat on various routes using the ZendeskChatVisibilityService
    router.transitionService.onFinish({ to: '**' }, $transition$ => {
        const zendeskChatVisibilityService: ZendeskChatVisibilityService = injector.get(ZendeskChatVisibilityService);
        const currentRouteId = $transition$.$to().name;
        const idsToHide = ['conversion', 'dashboard', 'user.signin-internal', 'internal'];
        const matchingRoutesToHideZendesk = idsToHide.filter(routeId => currentRouteId.startsWith(routeId));

        if (matchingRoutesToHideZendesk.length) {
            zendeskChatVisibilityService.hide();
        } else {
            zendeskChatVisibilityService.show();
        }
    });
};
