import { ChangeDetectionStrategy, Component, ContentChild, Input } from '@angular/core';

import { ContentAccentComponent } from '../../../../common/components/content-accent/content-accent.component';

@Component({
    selector: 'up-marketing-hero-section',
    templateUrl: 'marketing-hero-section.component.html',
    styleUrls: ['marketing-hero-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketingHeroSectionComponent {
    @Input() public imgSrcDesktop: string;
    @Input() public imgSrcMobile: string;
    @Input() public imgPosDesktop = 'center center';
    @Input() public imgPosMobile = 'center center';
    @ContentChild(ContentAccentComponent) private contentAccentComponentRef: ContentAccentComponent;

    public get hasContentAccent(): boolean {
        return !!this.contentAccentComponentRef;
    }
}
