import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TeamsApi } from '../../../common/models/teams-api.model';
import { stripNilValues } from '../../../common/utilities/data/data.utilities';

@Injectable()
export class TeamsResource {
    private readonly baseUrl = '/api/v2/teams';

    constructor(private httpClient: HttpClient) {}

    public getAll(query?: TeamsApi.GetAll.GET.Request) {
        return this.httpClient.get<TeamsApi.GetAll.GET.Response>(`${this.baseUrl}`, {
            params: {
                ...query,
                ...(query?.excludeInactive && { excludeInactive: 'true' }),
            },
        });
    }

    public create(body: TeamsApi.Create.POST.Request) {
        return this.httpClient.post<TeamsApi.Create.POST.Response>(`${this.baseUrl}`, body);
    }

    public getById(id: string) {
        return this.httpClient.get<TeamsApi.GetById.GET.Response>(`${this.baseUrl}/${id}`);
    }

    public update(id: string, body: TeamsApi.Update.PUT.Request) {
        return this.httpClient.put<TeamsApi.Update.PUT.Response>(`${this.baseUrl}/${id}`, body);
    }

    public setConciergeIds(teamId: string, conciergeIds: string[]) {
        return this.httpClient.post<TeamsApi.SetConciergeIds.POST.Response>(
            // Manually set the params here as Angular will strip the query param if providing an empty list,
            // and the API expects the param to always be there
            `${this.baseUrl}/${teamId}/set-concierge-ids?conciergeIds=${(conciergeIds || []).join(',')}`,
            undefined,
        );
    }

    public getConciergeEnabledTeams(includeCounts?: boolean) {
        return this.httpClient.get<TeamsApi.ConciergeTeams.GET.Response>(`${this.baseUrl}/concierge-enabled`, {
            params: stripNilValues({ includeCounts }),
        });
    }
}
