import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CarouselComponent } from '../carousel.component';

@Component({
    selector: 'up-carousel-indicators',
    templateUrl: 'carousel-indicators.component.html',
    styleUrls: ['carousel-indicators.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselIndicatorsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public carousel: CarouselComponent;
    @Input() public totalSlides: number;
    @Input() public activeSlide: number;
    @Output() public select: EventEmitter<number> = new EventEmitter<number>();
    public _activeSlide: number;
    private destroy$ = new Subject<void>();

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    public ngOnInit(): void {
        if (this.carousel) {
            this.bindToCarousel();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.activeSlide) {
            this._activeSlide = changes.activeSlide.currentValue;
        }
    }

    public indicatorArray(length: number): number[] {
        return Array.from({ length }, (value, index) => index);
    }

    public onSelect(slideIndex: number): void {
        this.select.emit(slideIndex);
        if (this.carousel) {
            this.carousel.goTo(slideIndex);
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private bindToCarousel(): void {
        this.carousel.beforeChange.pipe(takeUntil(this.destroy$)).subscribe(index => {
            this._activeSlide = index;
            this.changeDetectorRef.detectChanges();
        });
    }
}
