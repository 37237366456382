import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AgentResource } from '../../modules/core/resources/agent.resource';
import { SuburbResource } from '../../modules/core/resources/suburb.resource';

import {
    ConversionActionTypes,
    GetAgentCalendarBySlug,
    GetAgentCalendarBySlugError,
    GetAgentCalendarBySlugSuccess,
    GetReportSuburb,
    GetReportSuburbError,
    GetReportSuburbSuccess,
    GetSuburbAgent,
    GetSuburbAgentError,
    GetSuburbAgentSuccess,
} from './conversion.actions';

@Injectable()
export class ConversionEffects {
    constructor(
        private suburbResource: SuburbResource,
        private actions: Actions,
        private agentResource: AgentResource,
    ) {}

    public getSuburbAgent: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType<GetSuburbAgent>(ConversionActionTypes.GET_SUBURB_AGENT),
            switchMap(action => {
                const { id, coords } = action.payload;
                return this.suburbResource.getSuburbAgent(id, coords).pipe(
                    map(response => new GetSuburbAgentSuccess(response)),
                    catchError(error => of(new GetSuburbAgentError(error))),
                );
            }),
        ),
    );

    public getReportSuburb: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType<GetReportSuburb>(ConversionActionTypes.GET_REPORT_SUBURB),
            switchMap(action =>
                this.suburbResource.get(action.payload).pipe(
                    map(response => new GetReportSuburbSuccess(response)),
                    catchError(error => of(new GetReportSuburbError(error))),
                ),
            ),
        ),
    );

    public getAgentCalendarBySlug: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType<GetAgentCalendarBySlug>(ConversionActionTypes.GET_AGENT_CALENDAR_BY_SLUG),
            switchMap(({ payload }) =>
                this.agentResource.calendarBySlug(payload).pipe(
                    map(response => new GetAgentCalendarBySlugSuccess(response)),
                    catchError(error => of(new GetAgentCalendarBySlugError(error))),
                ),
            ),
        ),
    );
}
