import { Pipe, PipeTransform } from '@angular/core';

import { Appointment } from '../models/appointment.model';

@Pipe({
    name: 'upPrettyAppointmentType',
})
/** @Deprecated - use AppointmentType.Enum + the translation API instead **/
export class PrettyAppointmentTypePipe implements PipeTransform {
    public transform(value: string): string {
        const prettyMap: { [key in Appointment.Type]: string } = {
            [Appointment.Type.AGENT_PRE_APPRAISAL]: 'Pre-Appraisal',
            [Appointment.Type.AGENT_APPRAISAL_VISIT]: 'Appraisal',
            [Appointment.Type.AGENT_FOLLOW_UP]: 'Appraisal Follow Up',
            [Appointment.Type.AGENT_LISTING_VISIT]: 'Listing',
            [Appointment.Type.AGENT_CAMPAIGN_PROGRESS]: 'Campaign Progress',
            [Appointment.Type.AGENT_PRE_AUCTION_VISIT]: 'Pre-Auction',
            [Appointment.Type.AGENT_PUBLIC_OPEN]: 'Public Inspection',
            [Appointment.Type.AGENT_PRIVATE_OPEN]: 'Private Inspection',
            [Appointment.Type.AGENT_POST_SETTLEMENT]: 'Post Settlement',
            [Appointment.Type.AGENT_FINAL_INSPECTION]: 'Final Inspection',
            [Appointment.Type.BUILDING_INSPECTION]: 'Building Inspection',
            [Appointment.Type.BANK_VALUATION]: 'Bank Valuation',
            [Appointment.Type.MID_SETTLEMENT_INSPECTION]: 'Mid Settlement Inspection',
            [Appointment.Type.AGENT_OTHER]: 'Other',
            [Appointment.Type.AUCTIONEER_AUCTION]: 'Auction',
            [Appointment.Type.PHOTOGRAPHER_VISIT]: 'Photographer Visit',
            [Appointment.Type.SET_TO_SELL]: 'Set to Sell',
            [Appointment.Type.PRICE_ALIGNMENT]: 'Price Alignment',
        };

        return prettyMap[value] || value;
    }
}
