import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { PropertyFeaturesT } from '../../../../common/models/property.model';

@Component({
    selector: 'nc-more-details',
    templateUrl: 'more-details.component.html',
    styleUrls: ['more-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreDetailsComponent {
    @Input() public loading = false;
    @Input() public title: string;
    @Input() public bedrooms: number;
    @Input() public bathrooms: number;
    @Input() public parking: number;
    @Input() public landSize: number;
    @Input() public body: string;
    @Input() public features: PropertyFeaturesT;
    @Input() public hasFloorPlan: boolean;
    @Output() public openGallery = new EventEmitter<void>();
    @Output() public openFloorPlan = new EventEmitter<void>();

    public onOpenGallery(): void {
        this.openGallery.emit();
    }

    public onOpenFloorPlan(): void {
        this.openFloorPlan.emit();
    }
}
