import { NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';

import { VendorReviewsSectionComponent } from './components/vendor-reviews-section/vendor-reviews-section.component';

@NgModule({
    imports: [SharedModule],
    exports: [VendorReviewsSectionComponent],
    declarations: [VendorReviewsSectionComponent],
})
export class VendorReviewsModule {}
