import { FocusableOption } from '@angular/cdk/a11y';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { TransitionService, UISref } from '@uirouter/angular';

import { IconComponent } from '../../../common/components/icon/icon.component';
import { NavigationService } from '../navigation.service';

@Component({
    selector:
        // eslint-disable-next-line @angular-eslint/component-selector
        'div[up-nav-pane-button], button[up-nav-pane-button], a[up-nav-pane-button], button[up-nav-pane-button-minimal], a[up-nav-pane-button-minimal], div[nc-nav-pane-button], button[nc-nav-pane-button], a[nc-nav-pane-button], button[nc-nav-pane-button-minimal], a[nc-nav-pane-button-minimal]',
    templateUrl: 'nav-pane-button.component.html',
    styleUrls: ['nav-pane-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavPaneButtonComponent implements FocusableOption, OnDestroy, OnInit {
    @ContentChild(IconComponent, { static: false }) public icon;
    // This selects an `ng-container` inside of the actual trailing element because `createEmbeddedView` creates the new
    // view as a sibling as opposed to a child. i.e. When you do `this.trailing.createEmbeddedView(someTemplateRef)`,
    // it does this:
    // <ng-container #trailing><ng-container> <someTemplate></someTemplate>
    // not this:
    // <ng-container #trailing> <someTemplate></someTemplate> <ng-container>
    @ViewChild('trailing', { read: ViewContainerRef }) public trailing: ViewContainerRef;
    @HostBinding('class.is-active-state') private active: boolean;
    @Input() public relatedMustMatchExactly: boolean;
    private readonly destroyOnFinish: Function;

    @HostBinding('class.is-related-state')
    public isRelatedState = false;

    public get label(): string {
        return this.elementRef.nativeElement.innerText;
    }

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        @Optional() private uiSref: UISref,
        private navigationService: NavigationService,
        private changeDetectorRef: ChangeDetectorRef,
        private transitionService: TransitionService,
    ) {
        this.destroyOnFinish = this.transitionService.onSuccess({}, () => this.setIsRelatedState());
    }

    public ngOnInit(): void {
        this.setIsRelatedState();
    }

    public ngOnDestroy(): void {
        if (this.destroyOnFinish) {
            this.destroyOnFinish();
        }
    }

    public focus(): void {
        this.elementRef.nativeElement.focus();
    }

    public setActive(state: boolean): void {
        this.active = state;
        this.changeDetectorRef.detectChanges();
    }

    public setIsRelatedState(): void {
        this.isRelatedState = this.navigationService.isUiSrefRelatedState(this.uiSref, this.relatedMustMatchExactly);
        this.changeDetectorRef.markForCheck();
    }
}
