import { Component, Input } from '@angular/core';

type Size = 'default' | 'small' | 'large';

@Component({
    selector: 'nc-spinner, up-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
    @Input() public size: Size = 'default';
}
