<div class="nav-bar-user" (mouseenter)="showUserDropdown()" (mouseleave)="hideUserDropdown()">
    <button class="nav-bar-user-avatar" (click)="showUserDropdown(true)">
        <up-avatar [size]="'small'" [name]="authData.firstName + ' ' + authData.lastName"></up-avatar>
    </button>
    <up-nav-bar-dropdown
        class="nav-bar-user-dropdown"
        *ngIf="items?.length"
        [items]="items"
        [isVisible]="isDropdownVisible"
        [focusFirstItem]="focusOnFirstItemInDropdown"
    ></up-nav-bar-dropdown>
</div>
