import { ButtonStyle } from '../components/button/button.component';
import { IconName } from '../components/icon/icon.component';
import { DataEvent } from '../directive/tracking.directive';

export namespace nav {
    export enum Decoration {
        Divider,
    }

    export enum DropdownItemStyle {
        Default,
        Link,
    }

    export enum BackgroundWidth {
        Full,
        OuterContainer,
    }

    export interface BaseItem {
        title?: string;
        uiSref?: string;
        uiParams?: {
            [key: string]: string;
        };
        href?: string;
        click?: Function;
        visibility?: Function;
        icon?: IconName;
        trackingEvent?: string;
    }

    export interface Item extends BaseItem {
        new?: boolean;
    }

    export interface DropdownItem extends Item {
        decoration?: Decoration;
        style?: DropdownItemStyle;
    }

    export interface ActionIconItem extends BaseItem {
        icon: IconName;
    }

    export interface BarCta {
        title: string;
        uiSref?: string;
        click?: Function;
        style?: ButtonStyle;
        trackingEvent?: DataEvent;
        visibility?: Function;
        loading?: Function;
    }

    export interface BarTopLevelItem extends Item {
        children?: DropdownItem[];
        important?: Function | boolean;
    }

    export interface PanePrimaryItem extends Item {
        counter?: number;
    }

    export interface BarStyle {
        noTransparentShadow?: boolean;
        alwaysTransparent?: boolean;
        noPane?: boolean;
        sticky?: boolean;
        alwaysShowHamburger?: boolean;
        alwaysShowShadow?: boolean;
        backgroundWidth?: BackgroundWidth;
        backgroundColor?: string;
        invert?: boolean;
    }

    export interface BarConfig {
        style?: BarStyle;
        home: {
            uiSref: string;
            trackingEvent?: string;
        };
        items?: BarTopLevelItem[];
        cta?: BarCta;
        actionIconItem?: ActionIconItem;
        signInItem?: BarTopLevelItem;
        userItems?: DropdownItem[];
    }

    export interface PaneConfig {
        primaryItems: nav.PanePrimaryItem[];
        secondaryItems: nav.Item[];
    }

    export interface Config {
        bar: nav.BarConfig;
        pane?: nav.PaneConfig;
    }
}
