import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { UpsideCommonModule } from '../../../common/common';
import { SharedModule } from '../../../common/shared.module';

import { RequestCallCompleteComponent } from './complete/request-call-complete.component';
import { RequestCallComponent } from './details/request-call.component';
import { RequestCallStates } from './request-call.states';

@NgModule({
    imports: [UIRouterModule.forChild({ states: RequestCallStates }), UpsideCommonModule, SharedModule],
    declarations: [RequestCallCompleteComponent, RequestCallComponent],
    entryComponents: [RequestCallCompleteComponent, RequestCallComponent],
})
export class RequestCallModule {}
