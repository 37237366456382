import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Property } from '../../../common/models/property.model';
import { taskApi } from '../../../common/models/task-api.model';

const baseUrl = '/api/v2';
const headersConfig = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

@Injectable()
export class TaskResource {
    constructor(private http: HttpClient) {}

    public forProperty(id: string): Observable<taskApi.getProperty.GET.Response> {
        return this.http.get<taskApi.getProperty.GET.Response>(`${baseUrl}/task/property/${id}`, headersConfig);
    }

    public reschedule(propertyId: string, taskState: string, rescheduleTask: taskApi.reschedule.post.Request) {
        return this.http.post<taskApi.reschedule.post.Response>(
            `${baseUrl}/property/${propertyId}/task/${taskState}`,
            rescheduleTask,
            headersConfig,
        );
    }

    public cancel(propertyId: string, taskState: Property.States) {
        return this.http.post<taskApi.cancel.POST.Response>(
            `${baseUrl}/property/${propertyId}/task/${taskState}/cancel`,
            undefined,
        );
    }
}
