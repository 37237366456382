import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AddressFinderAutocompleteResultDto } from '../../../common/models/dto/address-finder/address-finder-autocomplete-result.dto';
import { AddressFinderMetadataResultDto } from '../../../common/models/dto/address-finder/address-finder-metadata-result.dto';

interface AddressFinderAutocompleteResponse {
    data: AddressFinderAutocompleteResultDto[];
}

@Injectable()
export class AddressFinderResource {
    private readonly baseUrl = '/api/v2/address';

    constructor(private httpClient: HttpClient) {}

    public fetchResults(query: string): Observable<AddressFinderAutocompleteResponse> {
        return this.httpClient.get<AddressFinderAutocompleteResponse>(`${this.baseUrl}/search`, {
            params: { q: query },
        });
    }

    public fetchResultMetadata(autocompleteResultAddressId: string): Observable<AddressFinderMetadataResultDto> {
        return this.httpClient.get<AddressFinderMetadataResultDto>(`${this.baseUrl}/${autocompleteResultAddressId}`);
    }
}
