import { createSelector } from '@ngrx/store';

import { AppState } from '../apps-state.model';

import { NotificationPreferencesState } from './user.reducer';

const userState = (state: AppState) => state.user;
export const notificationsPreferencesState = createSelector(
    userState,
    ({
        loadingNotificationsPreferences,
        updatingNotificationsPreferences,
        subscribingNotifications,
        unsubscribingNotifications,
        successSubscribingNotifications,
        successUpdatingNotifications,
        successUnsubscribingNotifications,
        errorLoadingNotificationPreferences,
        errorUpdatingNotificationPreferences,
        errorSubscribeNotifications,
        errorUnsubscribingNotifications,
        notificationsPreferences,
    }) =>
        <NotificationPreferencesState>{
            loadingNotificationsPreferences,
            updatingNotificationsPreferences,
            subscribingNotifications,
            unsubscribingNotifications,
            successSubscribingNotifications,
            successUpdatingNotifications,
            successUnsubscribingNotifications,
            errorLoadingNotificationPreferences,
            errorUpdatingNotificationPreferences,
            errorSubscribeNotifications,
            errorUnsubscribingNotifications,
            notificationsPreferences,
        },
);
export const notificationsPreference = createSelector(
    notificationsPreferencesState,
    state => state.notificationsPreferences,
);
