<nc-card>
    <div class="upsell">
        <div class="u-margin-right--md">
            <nc-icon-container [size]="'large'" [color]="'accent2' | upColor : 'cssVar'">
                <nc-icon [color]="'currentColor'" [name]="'documentHouseFill'"></nc-icon>
            </nc-icon-container>
        </div>
        <div>
            <p> Find out what your home is worth in 60 seconds. Click below for an instant price estimate </p>
            <a nc-link [uiSref]="'conversion.property-report.address'" target="_blank">Request property report</a>
        </div>
    </div>
</nc-card>
