import moment from 'moment-timezone';

import { auTimeZoneRegEx, nzTimeZoneRegEx } from '../../constants/time-zone-regex.contants';

export function getTimeZonesForCountry(isoAlpha2: string): string[] {
    // TODO: Switch this to Intl.supportedValuesOf('timeZone')
    const allTimeZones = moment.tz.names();
    const filterTimeZonesWith = (matcher: RegExp) => allTimeZones.filter(timeZone => timeZone.match(matcher));

    switch (isoAlpha2) {
        case 'AU':
            return filterTimeZonesWith(auTimeZoneRegEx);
        case 'NZ':
            return filterTimeZonesWith(nzTimeZoneRegEx);
        default:
            return [];
    }
}
