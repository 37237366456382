import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { HistoryState } from '../../../../common/services/history.service';
import { GetStartedFacade } from '../store/get-started.facade';

@Component({
    selector: 'up-get-started-callback-complete',
    templateUrl: './get-started-callback-complete.component.html',
    styleUrls: ['./get-started-callback-complete.component.scss'],
})
export class GetStartedCallbackCompleteComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('iconTick', { static: false }) public iconTickRef: ElementRef;
    public returnTo: HistoryState;
    public destroy$: Subject<void> = new Subject<void>();

    constructor(private getStartedFacade: GetStartedFacade, private stateService: StateService) {}

    public ngOnInit(): void {
        this.handleHasStartedFlow();
        this.setReturnTo();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    public ngAfterViewInit(): void {
        this.animateTick();
    }

    public continue(): void {
        this.stateService.go(this.returnTo.name, this.returnTo.params);
    }

    private handleHasStartedFlow(): void {
        this.getStartedFacade.hasStartedFlow$
            .pipe(
                takeUntil(this.destroy$),
                filter(hasStartedFlow => !hasStartedFlow),
            )
            .subscribe(() => this.stateService.go('conversion.get-started'));
    }

    private setReturnTo(): void {
        this.getStartedFacade.returnTo$
            .pipe(takeUntil(this.destroy$))
            .subscribe(returnTo => (this.returnTo = returnTo));
    }

    private animateTick(): void {
        const path = this.iconTickRef.nativeElement;
        const length = path.getTotalLength();

        // Animation based on: https://jakearchibald.com/2013/animated-line-drawing-svg/
        path.style.transition = path.style.WebkitTransition = 'none';
        path.style.strokeDasharray = `${length} ${length}`;
        path.style.strokeDashoffset = length;
        path.getBoundingClientRect();
        path.style.transition = path.style.WebkitTransition = 'stroke-dashoffset 500ms ease-in 500ms';
        path.style.strokeDashoffset = '0';
    }
}
