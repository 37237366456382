import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[up-nav-bar-button-marketing], a[up-nav-bar-button-marketing]',
    templateUrl: 'nav-bar-button-marketing.component.html',
    styleUrls: ['nav-bar-button-marketing.component.scss', '../styles/nav-bar-button-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarButtonMarketingComponent {
    @HostBinding('class.is-active')
    @Input()
    public isActive: boolean;
}
