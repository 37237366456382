<div class="print-header">
    <div class="logo-container">
        <img
            class="logo"
            alt=""
            height="50"
            width="180"
            [src]="'/static/images/organisations/logos/' + themeName + '-regular.svg' | upCdnUrl"
        />
    </div>
</div>
<nav
    class="app-nav"
    ui-view="navbar"
    [ngClass]="{
        'is-not-sticky': isNotStickyNav$ | async
    }"
>
</nav>
<div class="app-nav-pane-secondary" ui-view="nav-pane-secondary"></div>
<up-global-progress-bar></up-global-progress-bar>
<up-route-loading-bar></up-route-loading-bar>
<main data-prerendered class="app-main" ui-view="content"></main>
<div class="app-footer"> <div ui-view="prefooter"></div> <div ui-view="footer"></div> </div>
<up-modal></up-modal>
<up-env-ribbon></up-env-ribbon>
<up-zendesk-placeholder *upFeature="'zendeskChatEnabled'"></up-zendesk-placeholder>
