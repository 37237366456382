import { Observable, combineLatest, timer } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Delays the source's first Observable emission by at least the delay specified.
 *
 * @param delayMs The minimum amount in milliseconds to delay the source Observable emission by
 */
export function minimumDelay<T>(delayMs: number): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>) => combineLatest([timer(delayMs), source]).pipe(map(([, s]) => s));
}
