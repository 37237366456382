import { Component, Input } from '@angular/core';

@Component({
    selector: 'up-conversion-step',
    templateUrl: './conversion-step.component.html',
    styleUrls: ['./conversion-step.component.scss'],
})
export class ConversionStepComponent {
    @Input() public step: number;
    @Input() public heading: string;
    @Input() public helpText: string;
    @Input() public backRoute: string;
}
