<nav class="nav" [class.is-scrolled]="isScrolled">
    <up-outer-container>
        <up-container>
            <div class="nav-container-offset">
                <div class="nav-content">
                    <up-nav-bar-container *ngIf="navPane" class="hamburger-container">
                        <button (click)="onClickHamburger()" up-nav-bar-button>
                            <up-icon [attr.aria-label]="'Open menu'" [name]="'menu'"></up-icon>
                        </button>
                    </up-nav-bar-container>
                    <ng-content select="up-nav-bar-container, up-nav-bar-divider"></ng-content>
                </div>
            </div>
        </up-container>
    </up-outer-container>
</nav>
