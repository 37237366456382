import { isPlatformServer } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    Output,
    PLATFORM_ID,
    ViewChild,
} from '@angular/core';
import contrast from 'contrast';

import { WindowRef } from '../../../../common/services/window.service';
import { rgbaToHex } from '../../../../common/utilities/rgb-to-hex.utilities';

enum SolidThemeTextColor {
    Light,
    Dark,
    NotApplicable,
}

export type Size = 'small' | 'medium' | 'large' | 'x-large';

@Component({
    selector: 'nc-tag, up-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss'],
})
export class TagComponent implements AfterViewInit, DoCheck, OnDestroy {
    @Input() public size: Size = 'medium';
    @Input() public style: 'round' | 'square' = 'square';
    @Input() public theme: 'translucent' | 'solid' = 'translucent';
    @Output() public delete = new EventEmitter<void>();
    public activeSolidThemeTextColor = SolidThemeTextColor.NotApplicable;
    public SolidThemeTextColor = SolidThemeTextColor;
    @ViewChild('background', { read: ElementRef }) private backgroundElement: ElementRef<HTMLElement>;
    // We have cases where the background element is not rendered on the first change detection cycle (e.g. sometimes
    // inside modals), so instead we listen for when the element actually becomes visible before trying to compute
    // the background styling.
    private readonly backgroundIntersectionObserver = new IntersectionObserver(() => {
        this.setupColor();
        this.backgroundIntersectionObserver.disconnect();
    });

    constructor(
        private windowRef: WindowRef,
        @Inject(PLATFORM_ID) private platformId,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    public ngAfterViewInit(): void {
        this.backgroundIntersectionObserver.observe(this.backgroundElement.nativeElement);
    }

    public ngOnDestroy(): void {
        this.backgroundIntersectionObserver.disconnect();
    }

    public ngDoCheck(): void {
        // Todo: temporary non-performant solution to fix the color not updating
        //  when the color style binding changes
        this.setupColor();
    }

    public get isDeletable(): boolean {
        return !!this.delete.observers.length;
    }

    public onDelete(): void {
        this.delete.emit();
    }

    private setupColor(): void {
        if (isPlatformServer(this.platformId) || !this.backgroundElement?.nativeElement) return;

        const backgroundColor = this.windowRef.nativeWindow.getComputedStyle(
            this.backgroundElement.nativeElement,
        ).backgroundColor;

        if (!backgroundColor) return;

        const color = rgbaToHex(backgroundColor);

        this.activeSolidThemeTextColor =
            contrast(color) === 'dark' ? SolidThemeTextColor.Light : SolidThemeTextColor.Dark;

        this.changeDetectorRef.detectChanges();
    }
}
