<up-modal-dialog [size]="'large'">
    <div class="inner">
        <div class="content">
            <h2 class="u-text-heading--md">Unsubscribe</h2>
            <div class="u-text-heading--sm u-margin-bottom--lg">Weekly market activity</div>
            <p> Are you sure you want to delete this alert? </p>
            <p class="u-margin-bottom--xl">
                You will no longer receive weekly market activity emails or property alerts.
            </p>
            <div class="controls">
                <button
                    up-button
                    class="action"
                    [loading]="loading$ | async"
                    (click)="unsubscribe()"
                    [style]="'primary'"
                    >Unsubscribe</button
                >
            </div>
        </div>
        <div class="image"></div>
    </div>
</up-modal-dialog>
