import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UseCase } from '../../../../common/models/core/use-case.model';
import { ListingResource } from '../../../core/resources/listing.resource';

export interface Result {
    property: Result.Property;
    agent: Result.Agent;
    allOpens: Result.Open[];
    selectedOpen?: Result.Open;
}

export namespace Result {
    export interface Property {
        address: string;
        timezone: string;
    }

    export interface Open {
        startDateTime: string;
        endDateTime: string;
        duration: string;
        appointmentId: string;
    }

    export interface Agent {
        firstName: string;
        lastName: string;
        fullName: string;
        phoneNumber?: string;
        email?: string;
        avatarUrl?: string;
    }
}

interface Input {
    propertyToken: string;
    inspectionId?: string;
}

@Injectable({ providedIn: 'root' })
export class GetInspectionCheckInDataUseCase implements UseCase<Result, Input> {
    private readonly inspectionStartTimeBuffer = dayjs.duration(30, 'minutes');

    constructor(private readonly listingResource: ListingResource) {}

    public execute(input: Input): Observable<Result> {
        return this.listingResource.get(input.propertyToken).pipe(
            map(listing => {
                const allOpens = listing.opens.map(({ dateTime, duration, id }) => ({
                    startDateTime: dateTime && dayjs(dateTime).toISOString(),
                    endDateTime: dateTime && dayjs(dateTime).add(dayjs.duration(duration)).toISOString(),
                    duration,
                    appointmentId: id,
                }));
                const selectedOpen = (() => {
                    if (input.inspectionId) {
                        const selectedOpen = allOpens.find(({ appointmentId }) => appointmentId === input.inspectionId);

                        if (!selectedOpen) throw new Error('Inspection not found');

                        return selectedOpen;
                    } else {
                        return allOpens.find(({ startDateTime, endDateTime }) => {
                            const startTimeWithBuffer = dayjs(startDateTime).subtract(this.inspectionStartTimeBuffer);

                            return dayjs().isBetween(startTimeWithBuffer, endDateTime);
                        });
                    }
                })();

                const agent = listing.agent;

                return {
                    property: {
                        address: listing.address.formattedAddress,
                        timezone: listing.timeZone,
                    },
                    allOpens,
                    selectedOpen,
                    agent: {
                        firstName: agent?.firstName,
                        lastName: agent?.lastName,
                        fullName: `${agent?.firstName} ${agent?.lastName}`,
                        phoneNumber: agent?.phoneNumber,
                        email: agent?.email,
                        avatarUrl: agent?.avatar?.servingUrl,
                    },
                };
            }),
        );
    }
}
