import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    map,
    publishReplay,
    refCount,
    switchMap,
    tap,
} from 'rxjs/operators';

import { AddressFinderResource } from '../../modules/core/resources/address-finder.resource';
import { NotificationService } from '../../modules/core/services/notification.service';
import { AddressT } from '../models/address.model';
import { AddressFinderAutocompleteResultDto } from '../models/dto/address-finder/address-finder-autocomplete-result.dto';
import { AddressFinderMetadataResultDto } from '../models/dto/address-finder/address-finder-metadata-result.dto';

interface Autocomplete {
    results$: Observable<AddressFinderAutocompleteResultDto[]>;
    search(query: string): void;
    loading$: Observable<boolean>;
}

@Injectable()
export class AddressFinderService {
    private readonly searchDebounceTimeMs = 300;

    constructor(
        private addressFinderResource: AddressFinderResource,
        private notificationService: NotificationService,
    ) {}

    public isAddressFinderMetadata(
        address?: AddressFinderMetadataResultDto | AddressT,
    ): address is AddressFinderMetadataResultDto {
        return typeof (address as AddressFinderMetadataResultDto)?.fullAddress !== 'undefined';
    }

    public fetchAddress(autocompleteResultId: string): Observable<AddressT> {
        return this.addressFinderResource.fetchResultMetadata(autocompleteResultId).pipe(map(({ address }) => address));
    }

    public autocompleteFactory(errorTap?: (error: any) => void): Autocomplete {
        const defaultErrorMessage = 'Error searching addresses. Please try again later';
        const loadingSubject = new BehaviorSubject(false);

        errorTap =
            errorTap ||
            (error => {
                this.notificationService.httpError(error, {}, defaultErrorMessage);
            });

        const errorTapObs = (source$: Observable<any>) => source$.pipe(tap({ error: errorTap }));
        const query$ = new Subject<string>();
        const results$ = query$.pipe(
            distinctUntilChanged(),
            tap(query => loadingSubject.next(this.validQuery(query))),
            debounceTime(this.searchDebounceTimeMs),
            switchMap(value =>
                this.validQuery(value)
                    ? this.addressFinderResource.fetchResults(value).pipe(
                          // Todo: this will fire every single time an error is triggered, so we should look at merging
                          //  them somehow to prevent notification spam as the user is typing.
                        errorTapObs,
                        map(({ data }) => data),
                        catchError(() => of([])),
                    )
                    : of([]),
            ),
            tap(() => loadingSubject.next(false)),
            publishReplay(1),
            refCount(),
        );

        return {
            results$,
            search: (query: string) => query$.next(query),
            loading$: loadingSubject.asObservable(),
        };
    }

    private validQuery(query: string): boolean {
        return !!(query && typeof query === 'string' && query.trim());
    }
}
