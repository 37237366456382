<ng-container *transloco="let t" [ngSwitch]="currentState">
    <ng-container *ngSwitchCase="State.ExistingUser">
        <nc-returning-user
            [address]="property.address"
            [date]="open?.startDateTime"
            [firstName]="existingUser.firstName"
            [lastName]="existingUser.lastName"
            (currentUserClick)="onCheckInAsExistingUser()"
            (newUserClick)="onCheckInAsNewUser()"
        >
        </nc-returning-user>
    </ng-container>
    <ng-container *ngSwitchCase="State.NoOpen">
        <nc-no-open-available [agent]="agent"></nc-no-open-available>
    </ng-container>
    <ng-container *ngSwitchCase="State.CheckIn">
        <nc-check-in-card [loading]="loading" [fullScreen@xs]="true">
            <ng-container slot="header">
                <div class="date">
                    <nc-date-time
                        *ngIf="open?.startDateTime as startDateTime"
                        [dateTime]="startDateTime"
                        [format]="'mediumDate'"
                    ></nc-date-time>
                </div>
                <div class="u-text-heading--section u-text-font--secondary">
                    {{ t('selfCheckIn.checkIn.heading') }}
                </div>
                <div class="address">
                    <up-placeholder
                        class="u-margin-top--2xs"
                        *ngIf="loading; else addressTemplate"
                        [style]="'dark'"
                        [width]="'250px'"
                        [height]="'16px'"
                    ></up-placeholder>
                    <ng-template #addressTemplate>
                        <div class="u-text-body--sm"> {{ property.address }} </div>
                    </ng-template>
                </div>
            </ng-container>
            <ng-container slot="body">
                <ng-container *ngIf="open; else selectInspection">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="u-margin-bottom--sm">
                            <ng-container [formGroupName]="'user'">
                                <div class="user-detail-fields">
                                    <mat-form-field>
                                        <input
                                            [formControlName]="'firstName'"
                                            matInput
                                            placeholder="First name"
                                            [required]="isFieldRequired('user.firstName')"
                                        />
                                    </mat-form-field>
                                    <mat-form-field>
                                        <input
                                            [formControlName]="'lastName'"
                                            matInput
                                            placeholder="Last name"
                                            [required]="isFieldRequired('user.lastName')"
                                        />
                                    </mat-form-field>
                                    <mat-form-field>
                                        <input
                                            [formControlName]="'phoneNumber'"
                                            matInput
                                            placeholder="Mobile phone"
                                            type="tel"
                                            [required]="isFieldRequired('user.phoneNumber')"
                                        />
                                    </mat-form-field>
                                    <mat-form-field>
                                        <input
                                            [formControlName]="'email'"
                                            matInput
                                            placeholder="Email"
                                            type="email"
                                            [required]="isFieldRequired('user.email')"
                                        />
                                        <mat-error>Please enter a valid email</mat-error>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                            <mat-form-field class="u-mat-form-field--full-width">
                                <mat-label>{{ t('selfCheckIn.localityField.label') }}</mat-label>
                                <nc-mat-locality-autocomplete
                                    [formControlName]="'locality'"
                                ></nc-mat-locality-autocomplete>
                            </mat-form-field>
                        </div>
                        <div [formGroupName]="'finance'">
                            <fieldset class="u-margin-bottom--lg">
                                <legend class="u-text-body--sm u-margin-bottom--sm">
                                    Do you have finance pre-approval?
                                    <button (click)="showFinancePreApprovalModal()" type="button">
                                        <nc-icon
                                            class="u-text-color--brand-primary-lighter"
                                            [name]="'infoCircleFill'"
                                            [size]="'small'"
                                            [color]="'currentColor'"
                                        ></nc-icon>
                                    </button>
                                </legend>
                                <up-radio-group [formControlName]="'approved'">
                                    <up-radio class="pre-approval-radio" [style]="'pill'" [value]="true">Yes</up-radio>
                                    <up-radio class="pre-approval-radio" [style]="'pill'" [value]="false">No</up-radio>
                                </up-radio-group>
                            </fieldset>

                            <div *ngIf="form.get('finance.amount').enabled" class="u-margin-bottom--xs">
                                <mat-form-field>
                                    <input
                                        [formControlName]="'amount'"
                                        currencyMask
                                        [options]="{ precision: 0 }"
                                        matInput
                                        placeholder="Approximate approved amount"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                        <fieldset class="u-margin-bottom--lg">
                            <legend class="u-text-body--sm u-margin-bottom--sm"> We’d like to help you: </legend>
                            <up-radio-group [formControlName]="'category'">
                                <up-radio *ngFor="let category of BuyerCategories" [style]="'pill'" [value]="category">
                                    {{ t(category) }}
                                </up-radio>
                            </up-radio-group>
                        </fieldset>
                        <div class="u-text-body--sm u-margin-bottom--xl">
                            <up-checkbox [formControlName]="'termsAndPrivacy'">
                                <ng-container *ncEnvironment="{ orgGroup: GroupedOrganisation.Upside }">
                                    {{ t('selfCheckIn.dataCollectionAgreement.upside') }}
                                    <a nc-link [uiSref]="'terms-and-conditions'" target="_blank">{{
                                        t('generic.termsAndConditions')
                                    }}</a>
                                </ng-container>
                                <ng-container *ncEnvironment="{ orgGroup: GroupedOrganisation.RayWhite }">
                                    {{ t('selfCheckIn.dataCollectionAgreement.rayWhite') }}
                                </ng-container>
                            </up-checkbox>
                            <mat-error
                                *ngIf="formSubmitAttempted && form.get('termsAndPrivacy').errors"
                                class="u-padding-top--2xs u-text-color--negative"
                            >
                                You must agree to continue.
                            </mat-error>
                        </div>
                        <div class="u-text-body--sm u-margin-bottom--lg">
                            The agent and {{ orgName }} collect this information to contact you about this &amp; similar
                            properties.
                        </div>
                        <div class="submit-button-wrapper u-margin-bottom--sm">
                            <button
                                nc-button
                                class="submit-button"
                                [style]="'primary'"
                                [loading]="submitting"
                                [size]="'large'"
                                type="submit"
                            >
                                Complete Check-in
                            </button>
                        </div>
                    </form>
                </ng-container>

                <ng-template #selectInspection>
                    <div class="u-margin-top--lg u-margin-bottom--xl">
                        {{ t('selfCheckIn.checkIn.selectUpcomingInspection.heading') }}
                    </div>

                    <ol nc-select-list [appearance]="'minimal'">
                        <li *ngFor="let inspection of opens" nc-select-list-item>
                            <a
                                nc-select-list-button
                                [uiSref]="'.'"
                                [uiParams]="{ inspectionId: inspection.appointmentId }"
                            >
                                <nc-date-time
                                    [format]="'mediumDate'"
                                    [dateTime]="inspection.startDateTime"
                                ></nc-date-time
                                >,
                                <nc-date-time
                                    [format]="'shortTime'"
                                    [dateTime]="inspection.startDateTime"
                                ></nc-date-time
                                >-<nc-date-time
                                    [format]="'shortTime'"
                                    [dateTime]="inspection.endDateTime"
                                ></nc-date-time>
                            </a>
                        </li>
                    </ol>
                </ng-template>
            </ng-container>
        </nc-check-in-card>
    </ng-container>
</ng-container>
