import { ChangeDetectionStrategy, Component } from '@angular/core';

import { EnvironmentService } from '../../../modules/core/services/environment.service';

declare const COMMIT_HASH: string;

@Component({
    selector: 'up-env-ribbon',
    templateUrl: 'env-ribbon.component.html',
    styleUrls: ['env-ribbon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvRibbonComponent {
    public environmentMap: { [orgName: string]: string } = {
        upsidedevelopment: 'Dev',
        upsidemobiledevelopment: 'Mobile Dev',
        upsideuat: 'UAT',
        localdev: 'Local Dev',
    };
    public commitHash = COMMIT_HASH.slice(0, 8);

    constructor(private environmentService: EnvironmentService) {}

    public get env(): string {
        return this.environmentMap[this.environmentService.config.organisation.name];
    }
}
