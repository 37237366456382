import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { purchaseApi } from '../../../common/models/purchase-api.model';

const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

const BASE_URL = `/api/v2/property`;

@Injectable()
export class PurchaseResource {
    constructor(private http: HttpClient) {}

    public charge(
        propertyId: string,
        productIds: string[],
        amount: number,
        token: string,
        draftTransactionId?: string,
        productAmounts?: Record<string, number>,
    ) {
        return this.http.post<purchaseApi.chargeProducts.post.Response>(
            `${BASE_URL}/${propertyId}/purchase/`,
            { productIds, amount, token, draftTransactionId, productAmounts },
            HEADERS_CONFIG,
        );
    }

    public chargeProduct(propertyId: string, productId: string) {
        return this.http.post<purchaseApi.chargeProduct.post.Response>(
            `${BASE_URL}/${propertyId}/purchase/${productId}/`,
            undefined,
            HEADERS_CONFIG,
        );
    }
}
