import { Action } from '@ngrx/store';

export const TOGGLE_SIDENAV = 'TOGGLE_SIDENAV';

export class ToggleSidenav implements Action {
    public readonly type = TOGGLE_SIDENAV;

    constructor(public payload: boolean) {}
}

export type SidenavAction = ToggleSidenav;
