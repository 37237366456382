import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StateService } from '@uirouter/angular';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { user } from '../../../../common/models/user.model';
import { DemonstrationResource } from '../../../core/resources/demonstration.resource';
import { UserResource } from '../../../core/resources/user.resource';
import { NotificationService } from '../../../core/services/notification.service';

@UntilDestroy()
@Component({
    selector: 'nc-demo-splash-page',
    templateUrl: 'demo-splash-page.component.html',
    styleUrls: ['demo-splash-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoSplashPageComponent implements OnInit {
    public form: FormGroup;
    public availableStates: string[] = [];
    public availableRoles: user.Role[] = [];
    public formSubmitAttempted = false;
    public isLoading$ = new BehaviorSubject(false);
    public hideStateControl$ = new BehaviorSubject(false);

    constructor(
        private formBuilder: FormBuilder,
        private demonstrationResource: DemonstrationResource,
        private notificationService: NotificationService,
        private stateService: StateService,
        private userResource: UserResource,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        this.createForm();
        this.loadData();
    }

    private loadData(): void {
        this.isLoading$.next(true);

        this.demonstrationResource
            .getAvailableStatesAndRoles()
            .pipe(
                this.notificationService.withNotification({
                    errorMessage: 'Error loading available roles and states',
                }),
                finalize(() => this.isLoading$.next(false)),
            )
            .subscribe(({ roles, states }) => {
                this.availableStates = states;
                this.availableRoles = roles;
                this.handleStateControl();
            });
    }

    public submit(): void {
        this.formSubmitAttempted = true;

        if (this.form.invalid) return;

        this.isLoading$.next(true);
        this.changeDetectorRef.detectChanges();

        const { state, role } = this.form.value;

        this.demonstrationResource
            .impersonateBusinessUser(state, role)
            .pipe(
                this.notificationService.withNotification({
                    errorMessage: 'Error initialising demo environment',
                }),
            )
            .subscribe(
                () => this.stateService.go('internal'),
                () => this.isLoading$.next(false),
            );
    }

    public isControlError(controlName: string): boolean {
        return this.formSubmitAttempted && this.form.get(controlName).invalid;
    }

    public signOut(): void {
        this.userResource.signOut().subscribe(() => this.stateService.go('home', {}, { location: 'replace' }));
    }

    private createForm(): void {
        this.form = this.formBuilder.group({
            state: ['', Validators.required],
            role: ['', Validators.required],
        });

        this.isLoading$.pipe(untilDestroyed(this)).subscribe(is => {
            if (is) {
                this.form.disable();
            } else {
                this.form.enable();
            }
        });
    }

    private handleStateControl(): void {
        const stateControl = this.form.get('state');

        // When there's just a single state just preselect it since user has no other choice
        if (this.availableStates.length === 1) {
            stateControl.setValue(this.availableStates[0]);
        }

        // No need to show the states selector if there's nothing to choose from
        if (this.availableStates.length <= 1) {
            this.hideStateControl$.next(true);
        }
    }
}
