import { UIRouter } from '@uirouter/core';
import { of } from 'rxjs/internal/observable/of';
import { catchError, first, map } from 'rxjs/operators';

import { user } from '../../../common/models/user.model';
import { UserService } from '../../../common/services/user.service';

// Hook that always redirects users with the Demo role to the demonstration splash page.
export const demoRedirectUiRouterHook = (router: UIRouter) =>
    router.transitionService.onBefore({ to: state => !state.self.name.startsWith('demonstration') }, transition => {
        const injector = transition.injector();
        const userService: UserService = injector.get<UserService>(UserService);

        return userService.userAuthDetailsUpdated$
            .pipe(
                first(),
                map(authData =>
                    authData?.roles?.includes(user.Role.Demo)
                        ? transition.router.stateService.target('demonstration')
                        : true,
                ),
                catchError(() => of(true)),
            )
            .toPromise();
    });
