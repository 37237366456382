import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'nc-disclosure-indicator',
    templateUrl: 'disclosure-indicator.component.html',
    styleUrls: ['disclosure-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisclosureIndicatorComponent {
    @Input() public appearance: 'minimal' | 'bold' = 'minimal';

    @HostBinding('class.is-appearance-bold')
    public get isAppearanceBold(): boolean {
        return this.appearance === 'bold';
    }
}
