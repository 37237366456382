<up-agent-profile-card
    [firstName]="agent.firstName"
    [avatar]="agent.avatar"
    [transparentProfileImage]="agent.transparentProfileImage"
>
    <div class="u-text-body--lg u-text-color--brand-primary-light u-text-weight--semi-bold u-margin-bottom--2xs">
        {{ agent.firstName + ' ' + agent.lastName }}
    </div>
    <up-agent-experience-stats
        class="u-display--block u-margin-bottom--2xs"
        [size]="'small'"
        [totalSales]="agent.totalSales"
        [startingYear]="agent.startingYear"
    >
    </up-agent-experience-stats>
    <up-rma-rating [size]="'small'" [rating]="agent.rating"></up-rma-rating>
    <up-vendor-review
        class="u-margin-top--sm"
        *ngIf="agent.review?.text"
        [review]="agent.review"
        [displayDensity]="'extra-compact'"
    >
    </up-vendor-review>
</up-agent-profile-card>
