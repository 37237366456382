import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GridColComponent } from './grid-col/grid-col.component';
import { GridComponent } from './grid.component';

@NgModule({
    imports: [CommonModule],
    exports: [GridComponent, GridColComponent],
    declarations: [GridComponent, GridColComponent],
    providers: [],
})
export class GridModule {}
