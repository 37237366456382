import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class GlobalProgressBarService {
    private progressUpdateEvent = new EventEmitter<number>();
    public currentValue: number;
    public progressValue = this.progressUpdateEvent.asObservable();

    public updateProgress(value: number): void {
        this.currentValue = value;
        this.progressUpdateEvent.emit(value);
    }
}
