import { isPlatformBrowser } from '@angular/common';
import { Injector, PLATFORM_ID } from '@angular/core';
import { UIRouter } from '@uirouter/angular';

import { WindowRef } from '../../common/services/window.service';

export const scrollTopUiRouterHooks = (router: UIRouter, injector: Injector) => {
    let firstLoad = true;

    router.transitionService.onSuccess(
        {},
        transition => {
            if (transition.options().custom?.skipScrollToTop) return;

            const windowRef = injector.get(WindowRef);
            const platformId = injector.get(PLATFORM_ID);

            if (isPlatformBrowser(platformId)) {
                // On any transition after the initial one, scroll the page to the top.
                // This prevents the app from scrolling to the top once Angular bootstraps
                // on a pre-rendered page.
                if (!firstLoad) {
                    windowRef.nativeWindow.scrollTo(0, 0);
                } else {
                    firstLoad = false;
                }
            }
        },
        { priority: -1 },
    );
};
