import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { kebabCase, toPairs } from 'lodash-es';
import { from, iif, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../../common/models/environment.model';
import {
    fonts as baseThemeFonts,
    colors as baseThemeColors,
    texts as baseThemeTexts,
} from '../../../settings/base-theme';

import { EnvironmentService } from './environment.service';

export enum ThemeName {
    Upside = environment.GroupedOrganisationName.Upside,
    LJHooker = environment.GroupedOrganisationName.LJHooker,
    NurtureCloud = environment.GroupedOrganisationName.NurtureCloud,
    RayWhite = environment.GroupedOrganisationName.RayWhite,
}

export interface Theme {
    colors: ThemeColors;
    fonts: ThemeFonts;
    texts: ThemeTexts;
}

export interface ThemeFonts {
    primary: string;
    secondary: string;
}

export interface ThemeTexts {
    linkTextDecoration: string;
}

export interface ThemeColors {
    accent1: string;
    accent2: string;
    black: string;
    neutral1: string;
    neutral2: string;
    neutral3: string;
    neutral4: string;
    neutral5: string;
    neutral6: string;
    neutral7: string;
    neutral8: string;
    white: string;
    ratemyagentStar: string;
    corelogic: string;
    apm: string;
    brand1Regular: string;
    brand1Light: string;
    brand1Lighter: string;
    brand2Regular: string;
    brand2Light: string;
    positive: string;
    warning: string;
    negative: string;
    disabled: string;
    heading: string;
    text: string;
    textLight: string;
    textXLight: string;
    textInverse: string;
    textPositive: string;
    textWarning: string;
    textNegative: string;
    border: string;
    borderLight: string;
    standard1: string;
    standard2: string;
    standard3: string;
    appointment: string;
    reminder: string;
    task: string;
    interaction: string;
    trophy: string;
    callHighlightedBackground: string;
    campaignReportVendorPrice: string;
    tagLeadPool: string;
    workplanPropensity: string;
    funnelStageAppraised: string;
    funnelStateNotAppraised: string;
    funnelStageNotConnected: string;
    funnelStageNotContacted: string;
    pricePlanPremium: string;
    pricePlanSaver: string;
    pricePlanClassic: string;
    pricePlanPrestige: string;
    priceChangeBannerBackground: string;
    smsPreviewBackground: string;
    smsPreviewDots: string;
    vpaPackageByoGradient: string;
    vpaPackageSilverGradient: string;
    vpaPackageGoldGradient: string;
    vpaPackagePlatinumGradient: string;
    reminderActionAppraisal: string;
    reminderActionBookAppointment: string;
    reporting1: string;
    reporting2: string;
    reporting3: string;
    reporting4: string;
    reporting5: string;
    reporting6: string;
    reporting7: string;
    reporting8: string;
    reporting9: string;
    reporting10: string;
    reporting11: string;
    reporting12: string;
    reporting13: string;
    reporting14: string;
    reporting15: string;
    reporting16: string;
    reporting17: string;
    reporting18: string;
    reporting19: string;
    reporting20: string;
    seller: string;
    buyer: string;
    enquiry: string;
    priceEstimateGaugeLow: string;
    priceEstimateGaugeLowMedium: string;
    priceEstimateGaugeMedium: string;
    priceEstimateGaugeMediumHigh: string;
    priceEstimateGaugeHigh: string;
    offerStatusWithdrawn: string;
}

export type Color = keyof ThemeColors;

export type FaviconGroup = 'upside' | 'raywhite' | 'nurturecloud-demo' | 'nurturecloud';

interface SetThemeOptions {
    specificTheme?: ThemeName;
    element?: HTMLElement;
}

@Injectable()
export class ThemeService {
    public colors: ThemeColors;
    public fonts: ThemeFonts;
    public texts: ThemeTexts;
    public themeName: ThemeName;
    private orgThemeName$: Observable<ThemeName>;
    private renderer: Renderer2;
    private orgName$: Observable<environment.OrganisationName>;
    private readonly themedOrgs: ThemeName[] = [
        ThemeName.Upside,
        ThemeName.NurtureCloud,
        ThemeName.LJHooker,
        ThemeName.RayWhite,
    ];

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(DOCUMENT) private document: Document,
        private rendererFactory: RendererFactory2,
        private environmentService: EnvironmentService,
    ) {
        this.renderer = this.rendererFactory.createRenderer(this.document, null);
        this.orgName$ = this.environmentService.config$.pipe(map(({ organisation }) => organisation.name));
        this.orgThemeName$ = this.orgName$.pipe(
            map(orgName => this.orgEnvironmentThemeMap(orgName)),
            catchError(() => of(undefined)),
        );
        this.orgThemeName$.subscribe(themeName => (this.themeName = themeName));
    }

    // Todo: improvement - this should apply the style theme without needing to subscribe to the result (although it
    //  should still return the result so we can know when / if it succeeds.)
    public setStyleTheme(setThemeOptions?: SetThemeOptions): Observable<any> {
        return iif(() => !!setThemeOptions?.specificTheme, of(setThemeOptions?.specificTheme), this.orgThemeName$).pipe(
            switchMap(themeName =>
                this.themedOrgs.includes(themeName)
                    ? from(import(/*webpackChunkName: "[request]"*/ `../../../settings/themes/${themeName}.js`))
                          // Import returns an object where default contains the exported object's content
                        .pipe(map(({ colors, fonts, texts }) => ({ colors, fonts, texts })))
                    : of({}),
            ),
            catchError(() => of({})),
            tap((theme: Theme) => {
                const existingColors = this.colors && <(keyof ThemeColors)[]>Object.keys(this.colors);
                const existingFonts = this.fonts && <(keyof ThemeFonts)[]>Object.keys(this.fonts);
                const existingTexts = this.fonts && <(keyof ThemeTexts)[]>Object.keys(this.texts);

                // Spread orgTheme on top of the base theme so it inherits the defaults
                this.colors = { ...baseThemeColors, ...theme.colors };
                this.fonts = { ...baseThemeFonts, ...theme.fonts };
                this.texts = { ...baseThemeTexts, ...theme.texts };

                // Cleans up previous theme custom variables
                this.cleanUpCustomProperties(existingColors, existingFonts, existingTexts, setThemeOptions?.element);
                this.themeConfigToStyleCustomPropertiesPairs(this.colors, this.fonts, this.texts).forEach(
                    ([propName, value]) => this.styleSetProperty(propName, value, setThemeOptions?.element),
                );
            }),
        );
    }

    public setupFavicon(): Observable<any> {
        const faviconGroup$ = this.orgName$.pipe(map(name => this.mapOrgNameToFaviconGroup(name)));

        return faviconGroup$.pipe(
            tap(faviconGroup => {
                this.renderer.appendChild(
                    this.document.head,
                    this.createLinkElement({
                        rel: 'icon',
                        type: 'image/png',
                        sizes: '16x16',
                        href: `/static/images/organisations/favicon/${faviconGroup}/favicon-16x16.png`,
                    }),
                );
                this.renderer.appendChild(
                    this.document.head,
                    this.createLinkElement({
                        rel: 'icon',
                        type: 'image/png',
                        sizes: '32x32',
                        href: `/static/images/organisations/favicon/${faviconGroup}/favicon-32x32.png`,
                    }),
                );
                this.renderer.appendChild(
                    this.document.head,
                    this.createLinkElement({
                        rel: 'apple-touch-icon',
                        sizes: '180x180',
                        href: `/static/images/organisations/favicon/${faviconGroup}/apple-touch-icon.png`,
                    }),
                );

                const defaultFaviconEl = this.document.getElementById('default-favicon');
                if (defaultFaviconEl) {
                    this.renderer.setAttribute(
                        defaultFaviconEl,
                        'href',
                        `/static/images/organisations/favicon/${faviconGroup}/favicon.ico`,
                    );
                }
            }),
        );
    }

    public themeColorAsCssVariable(themeColor: keyof ThemeColors, wrapVar = false): string {
        return this.themeValueAsCssVariable({
            reference: this.colors,
            key: themeColor,
            group: 'color',
            wrapVar: wrapVar,
        });
    }

    public getThemeForOrgGroup(group: environment.GroupedOrganisationName): ThemeName {
        switch (group) {
            case environment.GroupedOrganisationName.Upside: {
                return ThemeName.Upside;
            }

            case environment.GroupedOrganisationName.LJHooker: {
                return ThemeName.LJHooker;
            }

            case environment.GroupedOrganisationName.RayWhite: {
                return ThemeName.RayWhite;
            }

            default: {
                return ThemeName.NurtureCloud;
            }
        }
    }

    private themeFontsAsCssVariable(themeFont: keyof ThemeFonts): string {
        return this.themeValueAsCssVariable({
            reference: this.fonts,
            key: themeFont,
            group: 'font',
            wrapVar: false,
        });
    }

    private themeTextsAsCssVariable(themeText: keyof ThemeTexts): string {
        return this.themeValueAsCssVariable({
            reference: this.texts,
            key: themeText,
            group: 'text',
            wrapVar: false,
        });
    }

    private themeValueAsCssVariable<T>(opts: { reference: T; key: keyof T; group: string; wrapVar: boolean }): string {
        const validKeys = Object.keys(opts.reference);
        const keyAsString = `${<string>opts.key}`;
        if (validKeys.includes(keyAsString)) {
            const rawVar = `--${opts.group}-${kebabCase(keyAsString)}`;
            return opts.wrapVar ? `var(${rawVar})` : rawVar;
        } else {
            return undefined;
        }
    }

    private createLinkElement(config: { [attr: string]: string }): HTMLElement {
        const configAsPairs = toPairs(config);
        const element = this.renderer.createElement('link');
        configAsPairs.forEach(([attrName, attrValue]) => this.renderer.setAttribute(element, attrName, attrValue));
        return element;
    }

    private orgEnvironmentThemeMap(orgName: environment.OrganisationName): ThemeName {
        const groupedOrgName = this.environmentService.orgNameAsGrouping(orgName);
        return this.getThemeForOrgGroup(groupedOrgName);
    }

    private mapOrgNameToFaviconGroup(orgName: environment.OrganisationName): FaviconGroup {
        switch (orgName) {
            case environment.OrganisationName.Upside:
            case environment.OrganisationName.UpsideMobileDevelopment:
            case environment.OrganisationName.UpsideDevelopment:
            case environment.OrganisationName.UpsideUAT:
            case environment.OrganisationName.UpsideUATDemo:
            case environment.OrganisationName.LocalDev:
                return 'upside';
            case environment.OrganisationName.RayWhite:
            case environment.OrganisationName.RayWhiteNZ:
            case environment.OrganisationName.RayWhiteTesting:
            case environment.OrganisationName.RayWhiteDevelopment:
            case environment.OrganisationName.RayWhiteDevelopmentNZ:
            case environment.OrganisationName.RayWhiteTestingNZ:
                return 'raywhite';
            case environment.OrganisationName.RayWhiteDemo:
            case environment.OrganisationName.RayWhiteNZDemo:
                return 'nurturecloud-demo';
            default:
                return 'nurturecloud';
        }
    }

    private themeConfigToStyleCustomPropertiesPairs(
        colors: ThemeColors,
        fonts: ThemeFonts,
        texts: ThemeTexts,
    ): [string, string][] {
        const colorsAsCssVariables: [string, string][] = toPairs(colors)
            // Prepend namespace and kebabify the keys
            .map(([key, value]) => [this.themeColorAsCssVariable(<keyof ThemeColors>key), value]);
        const fontsAsCssVariables: [string, string][] = toPairs(fonts).map(([key, value]) => [
            this.themeFontsAsCssVariable(<keyof ThemeFonts>key),
            value,
        ]);
        const textsAsCssVariables: [string, string][] = toPairs(texts).map(([key, value]) => [
            this.themeTextsAsCssVariable(<keyof ThemeTexts>key),
            value,
        ]);
        return [...colorsAsCssVariables, ...fontsAsCssVariables, ...textsAsCssVariables];
    }

    private styleSetProperty(propName: string, value: string, element?: HTMLElement): void {
        const targetElement = element || this.document.documentElement;

        if (isPlatformBrowser(this.platformId)) {
            // Doesn't work on the server
            targetElement.style.setProperty(propName, `${value}`);
        } else {
            // Doesn't work in the browser
            this.renderer.setStyle(targetElement, propName, value);
        }
    }

    private cleanUpCustomProperties(
        colors: (keyof ThemeColors)[],
        fonts: (keyof ThemeFonts)[],
        texts: (keyof ThemeTexts)[],
        element?: HTMLElement,
    ): void {
        const targetElement = element || this.document.documentElement;

        if (isPlatformBrowser(this.platformId)) {
            colors?.forEach(c => targetElement.style.removeProperty(this.themeColorAsCssVariable(c)));
            fonts?.forEach(f => targetElement.style.removeProperty(this.themeFontsAsCssVariable(f)));
            texts?.forEach(t => targetElement.style.removeProperty(this.themeTextsAsCssVariable(t)));
        } else {
            colors?.forEach(c => this.renderer.removeStyle(targetElement, this.themeColorAsCssVariable(c)));
            fonts?.forEach(c => this.renderer.removeStyle(targetElement, this.themeFontsAsCssVariable(c)));
            texts?.forEach(t => this.renderer.removeStyle(targetElement, this.themeTextsAsCssVariable(t)));
        }
    }
}
