export namespace questionnaire {
    export class Questionnaire {
        public id: string;
        public title: string;
        public questions: Question[];
    }

    export enum Context {
        property = 'property',
        propertyShadow = 'property-shadow',
    }

    export interface Question {
        options: string[];
        id: string;
        title: string;
        type: 'MultipleChoice';
        question: string;
        answerType: string;
    }

    export interface Answer {
        answer: string;
        question: string;
        questionnaire: string;
    }

    export interface AnsweredQuestion {
        id: string;
        answer: string[];
    }
}
