import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { GetCalendarOptions } from '../../common/services/internal-user.service';

import { InternalUserState } from './internal-user.reducer';

export const LOAD_CALENDAR = 'LOAD_CALENDAR';
export const LOAD_CALENDAR_SUCCESS = 'LOAD_CALENDAR_SUCCESS';
export const LOAD_CALENDAR_ERROR = 'LOAD_CALENDAR_ERROR';

export class LoadCalendar implements Action {
    public readonly type = LOAD_CALENDAR;
    constructor(public payload: { agentId: string; options?: GetCalendarOptions }) {}
}

export class LoadCalendarSuccess implements Action {
    public readonly type = LOAD_CALENDAR_SUCCESS;
    constructor(public payload: Partial<InternalUserState.Calendar>) {}
}

export class LoadCalendarError implements Action {
    public readonly type = LOAD_CALENDAR_ERROR;
    constructor(public payload: HttpErrorResponse) {}
}

export type InternalUserActions = LoadCalendar | LoadCalendarSuccess | LoadCalendarError;
