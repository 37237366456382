import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RawParams, StateService } from '@uirouter/angular';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { UP_COLOR_CONSTANTS, UpColorConstants } from '../../../../common/constants/colors.constants';
import { twillio } from '../../../../common/models/twillio.model';
import { userApi } from '../../../../common/models/user-api.model';
import { ErrorHandlerService } from '../../../../common/services/error-handler.service';
import { TrackingService } from '../../../../common/services/tracking.service';
import { TwillioService } from '../../../../common/services/twillio.service';
import { UserResource } from '../../../../modules/core/resources/user.resource';

@Component({
    selector: 'up-phone-verification',
    templateUrl: 'phone-verification.component.html',
    styleUrls: ['phone-verification.component.scss'],
})
export class PhoneVerificationComponent implements OnInit {
    @Input() public userId: string;
    @Input() public redirectTo: string;
    @Input() public redirectParams: string;
    public showUpdatePhoneNumberControls: boolean;
    public verificationForm: FormGroup;
    public sendingInitialSms: boolean;
    public errorSendingInitialSms: string;
    public successResendingSms: boolean;
    public errorResendingSms: boolean;
    public verifyingCode: boolean;
    public errorVerifyingCode: boolean;
    public hasAttemptedSubmitCode: boolean;
    public updatedPhoneNumber: string;

    constructor(
        @Inject(UP_COLOR_CONSTANTS) public Colors: UpColorConstants,
        private errorHandlerService: ErrorHandlerService,
        private stateService: StateService,
        private userResource: UserResource,
        private formBuilder: FormBuilder,
        private twillioService: TwillioService,
        private trackingService: TrackingService,
    ) {}

    public ngOnInit(): void {
        this.sendInitialSms();
        this.setupForms();
    }

    public updatePhoneNumberVisibility(visible: boolean): void {
        this.showUpdatePhoneNumberControls = visible;
    }

    public verifyCode(): void {
        this.errorVerifyingCode = false;
        this.hasAttemptedSubmitCode = true;
        if (this.verificationForm.invalid) return;
        this.verifyingCode = true;
        const code = this.verificationForm.get('code').value;
        this.userResource
            .smsVerifyCheck(this.userId, code, this.updatedPhoneNumber)
            .pipe(map(r => this.twillioService.errorResponseHandler(r)))
            .subscribe(
                () => this.doRedirect(),
                () => {
                    this.verifyingCode = false;
                    this.errorVerifyingCode = true;
                },
            );
    }

    public resendSms(): void {
        this.successResendingSms = false;
        this.errorResendingSms = false;
        this.sendSms(this.updatedPhoneNumber).subscribe(
            () => {
                this.successResendingSms = true;
                // Hide message after 3s
                setTimeout(() => (this.successResendingSms = false), 3000);
            },
            () => (this.errorResendingSms = true),
        );
    }

    public onUpdatePhoneNumber(phoneNumber: string): void {
        this.updatedPhoneNumber = phoneNumber;
        this.errorSendingInitialSms = undefined;
        this.updatePhoneNumberVisibility(false);
        this.trackingService.trackDataEvent('verifyPhoneUpdate', { subname: this.userId });
    }

    private sendInitialSms(): void {
        this.errorSendingInitialSms = undefined;
        this.sendingInitialSms = true;

        this.sendSms(this.updatedPhoneNumber)
            .pipe(finalize(() => (this.sendingInitialSms = false)))
            .subscribe(
                () => {},
                (response: twillio.ResponseBase | HttpErrorResponse) => {
                    this.errorSendingInitialSms = response.message;
                },
            );
    }

    private sendSms(phoneNumber?: string): Observable<userApi.smsVerify.post.Response> {
        return this.userResource
            .smsVerify(this.userId, phoneNumber)
            .pipe(map(r => this.twillioService.errorResponseHandler(r)));
    }

    private doRedirect(): void {
        const state = this.redirectTo;
        let params: RawParams;

        try {
            const parsedParams = JSON.parse(decodeURIComponent(this.redirectParams));
            params = { ...parsedParams };
        } catch (e) {
            this.errorHandlerService.sendError(e);
        }

        this.stateService.go(state, params).then(
            () => {
                /* It works! Do nothing... */
            },
            error => {
                this.errorHandlerService.sendError(error);
                this.errorHandlerService.warning({ state, params }, error.message);
            },
        );
    }

    private setupForms(): void {
        this.verificationForm = this.formBuilder.group({
            code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
        });
    }
}
