import { Ng2StateDeclaration } from '@uirouter/angular';

import { dynamicPixelUrlQueryParam } from '../../common/constants/facebook-pixel.constants';
import { vendorRoot } from '../../common/constants/state-names.constants';
import { FooterMegaComponent } from '../../components/footer/footer-mega/footer-mega.component';
import { FeatureService } from '../core/services/feature.service';
import { NavLandingComponent } from '../shared-vendor/components/nav-landing/nav-landing.component';

import { VendorAppComponent } from './vendor-app.component';

export const vendorAppState: Ng2StateDeclaration[] = [
    {
        name: vendorRoot,
        component: VendorAppComponent,
        url: `?${dynamicPixelUrlQueryParam}`,
        abstract: true,
    },
    {
        name: 'vendor',
        parent: vendorRoot,
        views: {
            navbar: {
                component: NavLandingComponent,
            },
            footer: {
                component: FooterMegaComponent,
            },
        },
        abstract: true,
    },
    {
        name: 'home',
        parent: 'vendor',
        url: '/',
        redirectTo: transition => {
            const featureService: FeatureService = transition.injector().get(FeatureService);

            if (featureService.homeRouteName && featureService.homeRouteName !== 'home') {
                return transition.router.stateService.go(featureService.homeRouteName, transition.params());
            }
        },
    },
];
