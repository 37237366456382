import { isNil } from 'lodash-es';

type Output<T extends {}> = {
    [Key in keyof T]: T[Key];
};

// Todo: consider making this recursive. The current implementation will only strip nil top-level values.
export function stripNilValues<T extends {}>(obj: T): Output<T> {
    return Object.entries(obj)
        .filter(([, value]) => !isNil(value))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), <T>{});
}
