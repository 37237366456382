<div class="u-text-body--sm" [class.u-text-weight--semi-bold]="size === 'small'">
    <ng-container *ngIf="totalSales">
        <div [class.u-margin-right--lg]="size === 'medium'" class="u-display--inline-block">
            <strong
                [ngClass]="{
                    'u-text-heading--md': size === 'medium',
                    'u-margin-right--2xs': size === 'medium'
                }"
            >
                {{ totalSales }}
            </strong>
            {{
                totalSales
                    | i18nPlural
                        : {
                              '=1': size === 'small' ? 'Sale' : 'Total sale',
                              other: size === 'small' ? 'Sales' : 'Total sales'
                          }
            }}
        </div>

        <span *ngIf="size === 'small'"> | </span>
    </ng-container>

    <div class="u-display--inline-block">
        <strong
            [ngClass]="{
                'u-text-heading--md': size === 'medium',
                'u-margin-right--2xs': size === 'medium'
            }"
        >
            {{ yearsExperience }}
        </strong>
        {{ yearsExperience | i18nPlural: { '=1': 'Year', other: 'Years' } }} of experience
    </div>
</div>
