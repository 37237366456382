import { hasModifierKey } from '@angular/cdk/keycodes';
import { OverlayRef } from '@angular/cdk/overlay';
import { merge, Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { MenuContainerComponent } from './components/menu-container/menu-container.component';
import { MenuCloseOrigin } from './models/menu-close-origin.model';

export class MenuRef {
    public readonly afterClosed$: Observable<MenuCloseOrigin>;
    private readonly _afterClosed$ = new Subject<MenuCloseOrigin>();
    private readonly done$ = new Subject<void>();
    private closeOrigin: MenuCloseOrigin;

    constructor(public readonly containerInstance: MenuContainerComponent, private readonly overlayRef: OverlayRef) {
        this.afterClosed$ = this._afterClosed$.asObservable().pipe(takeUntil(this.done$));

        this.handleDisposeOverlay();
        this.handleCloseObservables();
        this.handleOverlayInputEvents();
    }

    public close(closeOrigin?: MenuCloseOrigin): void {
        this.closeOrigin = closeOrigin;

        // When the animation has started, detach the backdrop so that the sheet and the backdrop both
        // animate out at the same time.
        this.containerInstance.animationStateChanged$
            .pipe(
                filter(event => event.phaseName === 'start'),
                take(1),
            )
            .subscribe(() => this.overlayRef.detachBackdrop());

        // Begin the sheet closing animation. When this is done, the cached result data will be
        // passed down to any ref listeners
        this.containerInstance.triggerExit(closeOrigin);
    }

    // Listens for the sheet container close animation state and then disposes the overlay
    private handleDisposeOverlay(): void {
        this.containerInstance.animationStateChanged$
            .pipe(
                filter(event => event.phaseName === 'done' && event.toState === 'hidden'),
                take(1),
            )
            .subscribe(() => this.overlayRef.dispose());
    }

    // Listens for the overlay to detach and then appropriately emits the corresponding close event observables
    private handleCloseObservables(): void {
        this.overlayRef
            .detachments()
            .pipe(take(1))
            .subscribe(() => {
                this._afterClosed$.next(this.closeOrigin);
                // Done with this ref, so make sure all dependent observables get completed
                this.done$.next();
                this.done$.complete();
            });
    }

    // Listens for inputs on the overlay and handles them appropriately
    private handleOverlayInputEvents(): void {
        const keydownEvents$ = this.overlayRef
            .keydownEvents()
            .pipe(filter(event => event.key === 'Escape' && !hasModifierKey(event)));

        merge(this.overlayRef.backdropClick(), keydownEvents$)
            .pipe(takeUntil(this.done$))
            .subscribe(event => {
                event.preventDefault();
                this.close();
            });
    }
}
