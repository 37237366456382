import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'nc-choose-your-own-adventure',
    templateUrl: 'choose-your-own-adventure.component.html',
    styleUrls: ['choose-your-own-adventure.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseYourOwnAdventureComponent {
    @Input() public heading: string;
    @Input() public callCtaTrackingName: string;
}
