<div class="tabs">
    <up-tab
        *ngFor="let tab of tabs"
        [dataEvent]="{
            name: 'tabsAction',
            subname: 'selected',
            data: tab.label
        }"
        class="tab"
        (selected)="onTabSelected($event)"
        [disabled]="disabled"
        [active]="isTabActive(tab)"
        [class.bottom-border]="showBottomBorder && isTabActive(tab)"
        [tab]="tab"
    >
    </up-tab>
</div>
