import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { WindowRef } from './window.service';

@Injectable()
export class LocalStorageService {
    constructor(@Inject(PLATFORM_ID) private platformId, private windowRef: WindowRef) {}

    // Todo: We should implement more type-safe and robust ways of interacting
    //  with Local Storage, such as `setItemAsBool`.
    public setItem(keyName: string, keyValue: string): void {
        if (!this.isLocalStorageUsable()) return;
        this.windowRef.nativeWindow.localStorage.setItem(keyName, keyValue);
    }

    public setItemAsJSON(keyName: string, keyValue: any): void {
        this.setItem(keyName, JSON.stringify(keyValue));
    }

    public getItem(keyName: string): string {
        if (!this.isLocalStorageUsable()) return null;
        return this.windowRef.nativeWindow.localStorage.getItem(keyName);
    }

    public getItemAsJSON<T>(keyName: string): T {
        // returns null to replicate native behaviour
        if (!this.isLocalStorageUsable()) return null;
        const storeValue = this.getItem(keyName);
        let asJSON: T = null;
        try {
            asJSON = JSON.parse(storeValue) || null;
        } catch (e) {
            // do nothing
        }
        return asJSON;
    }

    public removeItem(keyName: string): void {
        if (!this.isLocalStorageUsable()) return;
        this.windowRef.nativeWindow.localStorage.removeItem(keyName);
    }

    public clear(): void {
        if (!this.isLocalStorageUsable()) return;
        this.windowRef.nativeWindow.localStorage.clear();
    }

    private isLocalStorageUsable(): boolean {
        if (isPlatformServer(this.platformId)) return false;
        // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
        try {
            const storage = this.windowRef.nativeWindow.localStorage;
            const x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            return false;
        }
    }
}
