<div class="wrapper">
    <div *ngIf="isServer; else browser" class="server-placeholder">
        <up-placeholder [display]="'block'" [height]="'100%'" [width]="'100%'"> </up-placeholder>
    </div>

    <ng-template #browser>
        <nc-card
            *ngIf="(display$ | async) === 'desktop'; else notDesktop"
            class="card u-text-align--center u-shadow--card full-height u-padding-horizontal--sm u-padding-vertical--xl"
        >
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </nc-card>

        <ng-template #notDesktop>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </ng-template>

        <ng-template #content>
            <nc-media-object
                [gutterSize]="(display$ | async) === 'mobile' ? 'large' : 'x-large'"
                [stacked]="(display$ | async) === 'desktop'"
            >
                <nc-icon-container
                    slot="figure"
                    [color]="'brand1Lighter' | upColor"
                    [size]="(display$ | async) === 'mobile' ? 'large' : 'x-large'"
                >
                    <nc-icon
                        [name]="iconName"
                        [size]="(display$ | async) === 'mobile' ? 'regular' : 'large'"
                        [color]="'currentColor'"
                    >
                    </nc-icon>
                </nc-icon-container>
                <div class="body full-height" slot="body">
                    <h2 class="heading u-text-heading--rg u-margin-bottom--xs">{{ heading }}</h2>
                    <div class="body-copy">{{ body }}</div>
                    <div class="action-wrapper">
                        <ng-content select="[slot='action']"></ng-content>
                    </div>
                </div>
            </nc-media-object>
        </ng-template>
    </ng-template>
</div>
