import { UpStateDeclaration } from '../../common/models/router.model';

import { Four04Component } from './four04.component';

export const four04State: UpStateDeclaration = {
    name: '404',
    component: Four04Component,
    data: {
        meta: {
            robots: 'noindex',
        },
    },
};
