import { Component, HostBinding, Input, OnInit } from '@angular/core';

type MarginSize = 'regular' | 'large';

type Size = 'x-large' | 'large' | 'medium' | 'small';

@Component({
    selector: 'up-container, nc-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss'],
})
export class ContainerComponent implements OnInit {
    @Input('fullWidth@mn') public fullWidthAtMn: boolean;
    @Input('fullWidth@xs') public fullWidthAtXs: boolean;
    @Input() public marginSize: MarginSize;
    @Input() public size: Size = 'large';
    @HostBinding('class.is-full-width-@mn') public fullWidthAtMnClass;
    @HostBinding('class.is-full-width-@xs') public fullWidthAtXsClass;
    @HostBinding('class.is-margin-size-large') public marginSizeLargeClass;
    @HostBinding('class.is-x-large') public get isXLargeClass(): boolean {
        return this.size === 'x-large';
    }
    @HostBinding('class.is-large') public get isLargeClass(): boolean {
        return this.size === 'large';
    }
    @HostBinding('class.is-medium') public get isMediumClass(): boolean {
        return this.size === 'medium';
    }
    @HostBinding('class.is-small') public get isSmallClass(): boolean {
        return this.size === 'small';
    }

    public ngOnInit(): void {
        this.fullWidthAtMnClass = this.fullWidthAtMn;
        this.fullWidthAtXsClass = this.fullWidthAtXs;
        this.marginSizeLargeClass = this.marginSize === 'large';
    }
}
