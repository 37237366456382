export namespace countryStates {
    export enum Australia {
        NSW = 'NSW',
        VIC = 'VIC',
        QLD = 'QLD',
        ACT = 'ACT',
        SA = 'SA',
        NT = 'NT',
        TAS = 'TAS',
        WA = 'WA',
        UNKNOWN = 'UNKNOWN',
    }
}
