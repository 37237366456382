import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
    selector: '[ncResizeObserver]',
    standalone: true,
})
export class ResizeObserverDirective implements OnInit, OnDestroy {
    @Output()
    public readonly observeResize = new EventEmitter<ResizeObserverEntry>();
    private observer: ResizeObserver;

    constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

    public ngOnInit(): void {
        // Disabled as we don't care about the unsupported browsers here
        // eslint-disable-next-line compat/compat
        this.observer = new ResizeObserver(entries => this.observeResize.emit(entries[0]));

        this.observer.observe(this.elementRef.nativeElement);
    }

    public ngOnDestroy(): void {
        this.observer?.disconnect();
    }
}
