import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

import { getEnvironmentVariable } from './utilities/get-environment-variable';

declare const COMMIT_HASH: string;

export const initSentry = () => {
    Sentry.init({
        dsn: getEnvironmentVariable('sentryConfigUrl'),
        environment: getEnvironmentVariable('sentryEnvironment'),
        release: COMMIT_HASH,
        beforeSend(event) {
            // Filter out annoying and useless "undefined is not an object (evaluating 't.map.setCenter')" errors
            if (event?.exception?.values?.some(v => v.value?.includes('setCenter'))) {
                return null;
            }
            return event;
        },
        ignoreErrors: ['Can\'t find variable: IntersectionObserver', 'zamant.ru/', 'maralo.ru/'],
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.25,
    });
};
