import { ModuleWithProviders, NgModule } from '@angular/core';

import { PrintTargetDirective } from './directives/print-target/print-target.directive';
import { PrintService } from './services/print/print.service';

@NgModule({
    declarations: [PrintTargetDirective],
    exports: [PrintTargetDirective],
})
export class PrintModule {
    public static forRoot(): ModuleWithProviders<PrintModule> {
        return {
            ngModule: PrintModule,
            providers: [PrintService],
        };
    }
}
