import { BreakpointObserver } from '@angular/cdk/layout';
import { isPlatformServer } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map, publishReplay, refCount } from 'rxjs/operators';

import { IconName } from '../../../../common/components/icon/icon.component';
import { MediaQueries } from '../../../../common/models/media-queries.model';
import mediaQueries from '../../../../settings/media-queries';

type Display = 'mobile' | 'tablet' | 'desktop';

@Component({
    selector: 'nc-media-action-panel',
    templateUrl: 'media-action-panel.component.html',
    styleUrls: ['media-action-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaActionPanelComponent implements OnInit {
    @Input() public heading: string;
    @Input() public body: string;
    @Input() public iconName: IconName;
    // Todo: this should be handled via media queries only. However, since not all of the sub-components used in this
    //  component have media responsive configuration APIs, for now we just handle it manually with BreakpointObserver.
    public display$: Observable<Display>;
    public readonly isServer: boolean;

    constructor(private breakpointObserver: BreakpointObserver, @Inject(PLATFORM_ID) platformId: object) {
        this.isServer = isPlatformServer(platformId);
    }

    public ngOnInit(): void {
        const tabletOrGreater = `(min-width: ${mediaQueries[MediaQueries.Small]}px)`;
        const desktopOrGreater = `(min-width: ${mediaQueries[MediaQueries.Medium]}px)`;

        this.display$ = this.breakpointObserver.observe([tabletOrGreater, desktopOrGreater]).pipe(
            map(({ matches, breakpoints }) => {
                if (!matches) return 'mobile';

                return breakpoints[desktopOrGreater] ? 'desktop' : 'tablet';
            }),
            publishReplay<Display>(),
            refCount(),
        );
    }
}
