<div class="outer">
    <div class="inner">
        <div class="row">
            <div class="image">
                <svg role="presentation" class="illus" #illus viewBox="0 0 570 285" xmlns="http://www.w3.org/2000/svg">
                    <g data-depth="0.1">
                        <path
                            d="m553.463629 276c0-58.539177-21.520694-68.966897-49.116142-157.483448-27.595448-88.516552-107.75352-81.2666329-157.595448-101.516552-31.830347-12.9321232-59.551144-17-96-17-65.033742 0-91 71-167.9999997 90-57.0134774 14.068261-95 105-79.12087151 186"
                            fill="#d8e4f4"
                            transform="translate(5.747961 .5)"
                        />
                    </g>
                    <g data-depth="0.15">
                        <g transform="translate(72.747961 14.5)">
                            <path
                                d="m366.348187 103.289586c0-5.0837319-4.006463-9.204871-8.948706-9.204871-.3205 0-.636449.0190665-.94827.0528324.029002-.3594946.053452-.7204804.053452-1.0877507 0-7.1803045-5.659028-13.0012404-12.639749-13.0012404-1.804665 0-3.517457.3952843-5.070527 1.0963785-.369084-4.7348895-4.214132-8.4610638-8.912295-8.4610638-4.709806 0-8.561312 3.7450278-8.913883 8.496534-1.35334-.4359737-2.792415-.6724413-4.285577-.6724413-7.907504 0-14.31793 6.5936095-14.31793 14.7274534 0 .2639489.025086.5211872.038422.7817276-1.220716-.6422969-2.599988-1.0118041-4.065313-1.0118041-4.942243 0-8.948706 4.1210326-8.948706 9.2046584 0 5.083626 4.006463 9.204658 8.948706 9.204658.538754 0 1.066393-.04921 1.578897-.143052 4.061714 3.425584 15.537798 5.896137 29.070566 5.896137 14.724693 0 27.019281-2.924102 29.970676-6.821982 4.197831-.759785 7.390237-4.520471 7.390237-9.056174"
                                fill="#8bb0dd"
                            />
                            <path
                                d="m.45741676 74.3741935h50.13233384c-.0378927-3.3469571-2.6321653-6.0490607-5.8306039-6.0490607-.2090449 0-.4150202.0128231-.6182435.0352093.0188405-.2391848.0348232-.4793476.0348232-.7235313 0-4.7759804-3.6894558-8.647927-8.2407054-8.647927-1.1765783 0-2.2932481.2629838-3.3058718.7293996-.2405867-3.149502-2.747431-5.6279608-5.8104932-5.6279608-3.0705773 0-5.5816554 2.4909559-5.8115517 5.6515424-.8823279-.2899342-1.8205422-.4473984-2.7940031-.4473984-5.1555214 0-9.33483005 4.3858516-9.33483005 9.7961445 0 .1756122.0163002.3466603.02497953.5199905-.79585213-.4274029-1.69500945-.6731079-2.65037072-.6731079-3.00103679 0-5.4703059 2.3786988-5.7954632 5.4366998"
                                fill="#f27c73"
                            />
                            <path
                                d="m426.48874 72.683871c-1.180707-4.5778655-5.221569-7.9558777-10.039338-7.9558777-2.342043 0-4.495892.8067611-6.234193 2.1519315-.537906-5.3926227-4.963094-9.5994654-10.343114-9.5994654-2.659791 0-5.078466 1.037051-6.917319 2.7279762-1.525869-3.9594943-5.271952-6.7632743-9.661258-6.7632743-5.476868 0-9.954661 4.3619686-10.358779 9.8943645-1.556563-.9564495-3.369802-1.5158622-5.31429-1.5158622-5.742963 0-10.398577 4.7932292-10.398577 10.7059234v.354284z"
                                fill="#f27c73"
                            />
                            <path
                                d="m51.8397856 27.4677419c0 6.3013788-5.0113599 11.4096775-11.1932649 11.4096775-6.1817992 0-11.1931591-5.1082987-11.1931591-11.4096775 0-6.3013787 5.0113599-11.4096774 11.1931591-11.4096774 6.181905 0 11.1932649 5.1082987 11.1932649 11.4096774"
                                fill="#f27c73"
                            />
                            <path
                                d="m45.1668634 22.9855737c2.4964714 2.4754935 2.4964714 6.4889479 0 8.9643365s-6.5441082 2.4753886-9.0405796 0c-2.4965773-2.4753886-2.4965773-6.488843 0-8.9643365 2.4964714-2.4753886 6.5441082-2.4753886 9.0405796 0"
                                fill="#fcdfdc"
                            />
                            <path
                                d="m42.1533369 25.9737582c.8321571.825016.8321571 2.1628203 0 2.9880462-.8321572.825121-2.1813694.825121-3.0135266 0-.8321571-.8252259-.8321571-2.1630302 0-2.9880462.8321572-.825226 2.1813694-.825226 3.0135266 0"
                                fill="#fffffe"
                            />
                            <path
                                d="m247.718059 2.53548387c-.163955 0-.320924-.05799302-.435554-.16087487l-1.632667-1.46525318c-.235506-.21124702-.231272-.55000434.009421-.75669742.240375-.20660014.626287-.20306851.861688.00827144l1.197112 1.07426497 1.196795-1.07426497c.235506-.21133995.621419-.21496452.861899-.00827144.240693.20660014.244821.5454504.009527.75669742l-1.632561 1.46525318c-.114737.10288185-.271705.16087487-.43566.16087487"
                                fill="#f27c73"
                            />
                            <path
                                d="m257.789997 6.76129032c-.163955 0-.320924-.07732372-.435554-.21449897l-1.632667-1.95366308c-.2354-.28166157-.231166-.73333618.009421-1.00892585.240375-.27546575.626287-.27088085.861688.01090463l1.197112 1.43234756 1.196901-1.43222364c.2354-.28178548.621418-.28661822.861793-.01102855.240693.27546575.244927.72726428.009527 1.00892585l-1.632561 1.95353916c-.114737.13729917-.271705.21462289-.43566.21462289"
                                fill="#fcdfdc"
                            />
                            <path
                                d="m227.649333 9.29677419c-.163849 0-.320923-.05799279-.435554-.16087423l-1.632666-1.46524731c-.235401-.21124617-.231167-.55000213.00942-.75669438.240481-.20659932.626288-.20316064.861794.00817847l1.197006 1.07426067 1.196901-1.07416773c.235506-.21133911.621419-.21496366.8619-.00827141.240586.20659931.24482.54544821.00942.75669438l-1.632455 1.46515438c-.114736.10297437-.271811.16096716-.435766.16096716"
                                fill="#8bb0dd"
                            />
                            <g fill="#f27c73">
                                <path
                                    d="m164.010905 3.38064516c-.163955 0-.321029-.07732508-.43566-.21450274l-1.632561-1.95369737c-.2354-.28166651-.231166-.73347296.009421-1.00894355.240692-.27559451.626499-.27063777.861899.01102874l1.196901 1.43237269 1.197007-1.43237269c.235294-.28166651.621101-.28662325.861793-.01102874.240587.27547059.244821.72727704.009421 1.00894355l-1.632561 1.95369737c-.114631.13717766-.271705.21450274-.43566.21450274"
                                />
                                <path
                                    d="m158.494133 10.9870968c-.163849 0-.320924-.077324-.435554-.2144998l-1.632561-1.95367011c-.2354-.28166258-.231272-.73346274.009315-1.00892949.240798-.27559066.626605-.27063399.861899.01102859l1.196901 1.43235273 1.197006-1.43235273c.235401-.28166258.621208-.28674317.8619-.01102859.240481.27546675.244715.72726691.009315 1.00892949l-1.632561 1.95367011c-.114631.1371758-.271705.2144998-.43566.2144998"
                                />
                                <path
                                    d="m265.325136 20.283871c-.163848 0-.320923-.0773251-.435554-.2145029l-1.632666-1.9535742c-.235401-.2816666-.231167-.7334732.00942-1.0090678.240587-.2753468.626182-.2707618.861794.0110287l1.197006 1.4323733 1.196901-1.4323733c.235612-.2817905.621313-.2864994.8619-.0110287.240586.2755946.24482.7272773.009526 1.0089439l-1.632561 1.9536981c-.114736.1371778-.271811.2145029-.435766.2145029"
                                />
                                <path
                                    d="m106.883985 25.8391824c-1.620918 0-3.159276.343626-4.554214.9529592-.331508-4.1151638-3.7850339-7.3534319-8.0047755-7.3534319-4.2302203 0-7.689568 3.2547589-8.0062577 7.384249-1.2154236-.378875-2.5080085-.584494-3.8490707-.584494-6.1724848 0-11.32409 4.330162-12.5686211 10.1034708h48.295174c-.412162-5.8669969-5.31249-10.5027531-11.312235-10.5027531"
                                />
                                <path
                                    d="m344.705645 31.7552724c-1.620918 0-3.159276.343727-4.554319.9530565-.331508-4.1151387-3.784929-7.3534902-8.00467-7.3534902-4.230221 0-7.689568 3.2547391-8.006258 7.384204-1.215529-.3787696-2.508009-.5843874-3.849071-.5843874-6.172485 0-11.32409 4.3301356-12.568727 10.1034092h48.29528c-.412268-5.8670642-5.31249-10.5027921-11.312235-10.5027921"
                                />
                            </g>
                            <path
                                d="m144.747028 24.5096774c-.163954 0-.321029-.0579919-.43566-.1608719l-1.63256-1.4652263c-.235401-.2112432-.231167-.5500872.00942-.7566836.240693-.2067822.626499-.2030648.8619.0081784l1.1969 1.0742453 1.197007-1.0742453c.235294-.2112432.621101-.2149606.861794-.0081784.240586.2065964.24482.5454404.00942.7566836l-1.632561 1.4652263c-.11463.10288-.271705.1608719-.43566.1608719"
                                fill="#fcdfdc"
                            />
                        </g>
                    </g>
                    <g data-depth="0.2">
                        <g transform="translate(30.263932 39.009677)">
                            <path
                                d="m91.9482188 72.9251875s1.5232222-15.173673 14.7851312-17.8694117c13.261909-2.6958448-3.900512 3.0488417 3.520738-8.0656842 17.27631-25.8740002 36.659687-4.6073107 47.818763-11.261113 10.549302-6.2900923 9.150871-15.0606036 28.139444-17.0823811 9.030524-.9616198 12.813335-4.2242963 22.095031-8.0271812 8.037482-3.29301214 21.346069 11.5257547 21.346069 11.5257547v69.9774096z"
                                fill="#d8e4f4"
                            />
                            <path
                                d="m229.653395 38.8774194h49.528909v81.9806456l-14.871396-2.19169h-34.657513z"
                                fill="#8bb0dd"
                            />
                            <path
                                d="m279.182304 38.8774194 14.871395 41.4129032v81.9806454h-29.742791v-81.9806454z"
                                fill="#d8e4f4"
                            />
                            <path
                                d="m294.053699 80.2903226-14.871395-41.4129032h84.908394l14.871289 41.4129032z"
                                fill="#d8e4f4"
                            />
                            <path
                                d="m285.61284 38.8774194 14.871501 41.4129032h5.224321l-14.871501-41.4129032z"
                                fill="#8bb0dd"
                            />
                            <path
                                d="m299.035528 38.8774194 14.871395 41.4129032h5.224321l-14.871395-41.4129032z"
                                fill="#8bb0dd"
                            />
                            <path
                                d="m312.45811 38.8774194 14.871396 41.4129032h5.224426l-14.871501-41.4129032z"
                                fill="#8bb0dd"
                            />
                            <path
                                d="m325.880693 38.8774194 14.871501 41.4129032h5.224321l-14.871395-41.4129032z"
                                fill="#8bb0dd"
                            />
                            <path d="m294.053699 162.270968h84.908288v-81.9806454h-84.908288z" fill="#fff" />
                            <path
                                d="m128.559957 65.9225806-18.305869-18.5935483h57.652337l18.30587 18.5935483z"
                                fill="#f48f87"
                            />
                            <path d="m128.559957 119.167742h57.652338v-53.2451614h-57.652338z" fill="#152b6f" />
                            <path d="m317.148018 174.103226h58.354728v-76.9096776h-58.354728z" fill="#fff" />
                            <path
                                d="m360.399125 80.2903226 18.39298-19.4387097h-43.251213l-18.392874 19.4387097z"
                                fill="#f27c73"
                            />
                            <path d="m322.096717 93.8129032h5.835473v-7.6064516h-5.835473z" fill="#fcdfdc" />
                            <path d="m336.303932 93.8129032h5.835473v-7.6064516h-5.835473z" fill="#fcdfdc" />
                            <path d="m322.096717 116.632258h5.835473v-7.606452h-5.835473z" fill="#fcdfdc" />
                            <path d="m336.303932 116.632258h5.835473v-7.606452h-5.835473z" fill="#fcdfdc" />
                            <path d="m322.096717 138.606452h5.835473v-7.606452h-5.835473z" fill="#fcdfdc" />
                            <path d="m336.303932 138.606452h5.835473v-7.606452h-5.835473z" fill="#fcdfdc" />
                            <path d="m123.678893 174.103226h62.533402v-54.935484h-62.533402z" fill="#f48f87" />
                            <path d="m123.678893 119.167742h62.533402l-31.266648-28.7354839z" fill="#f48f87" />
                            <path d="m172.465402 133.535484h3.072376v-2.535484h-3.072376z" fill="#d8e4f4" />
                            <path d="m172.465402 143.677419h3.072376v-3.380645h-3.072376z" fill="#d8e4f4" />
                            <path d="m172.465402 152.974194h3.072376v-3.380646h-3.072376z" fill="#d8e4f4" />
                            <path d="m172.465402 162.270968h3.072376v-3.380645h-3.072376z" fill="#d8e4f4" />
                            <path d="m186.212295 119.167742h43.4411v-80.2903226h-43.4411z" fill="#fffffe" />
                            <g fill="#fcdfdc">
                                <path d="m192.315027 52.4h2.402693v-2.5354839h-2.402693z" />
                                <path d="m192.315027 60.0064516h2.402693v-2.5354839h-2.402693z" />
                                <path d="m192.315027 67.6129032h2.402693v-2.5354838h-2.402693z" />
                                <path d="m199.523316 52.4h2.402798v-2.5354839h-2.402798z" />
                                <path d="m199.523316 60.0064516h2.402798v-2.5354839h-2.402798z" />
                                <path d="m199.523316 67.6129032h2.402798v-2.5354838h-2.402798z" />
                                <path d="m206.731499 52.4h2.402692v-2.5354839h-2.402692z" />
                                <path d="m206.731499 60.0064516h2.402692v-2.5354839h-2.402692z" />
                                <path d="m206.731499 67.6129032h2.402692v-2.5354838h-2.402692z" />
                                <path d="m213.939681 52.4h2.402693v-2.5354839h-2.402693z" />
                                <path d="m213.939681 60.0064516h2.402693v-2.5354839h-2.402693z" />
                                <path d="m213.939681 67.6129032h2.402693v-2.5354838h-2.402693z" />
                                <path d="m221.147864 52.4h2.402798v-2.5354839h-2.402798z" />
                                <path d="m221.147864 60.0064516h2.402798v-2.5354839h-2.402798z" />
                                <path d="m221.147864 67.6129032h2.402798v-2.5354838h-2.402798z" />
                                <path d="m192.315027 74.3741935h2.402693v-2.5354838h-2.402693z" />
                                <path d="m199.523316 74.3741935h2.402798v-2.5354838h-2.402798z" />
                                <path d="m206.731499 74.3741935h2.402692v-2.5354838h-2.402692z" />
                                <path d="m213.939681 74.3741935h2.402693v-2.5354838h-2.402693z" />
                                <path d="m221.147864 74.3741935h2.402798v-2.5354838h-2.402798z" />
                            </g>
                            <path d="m317.751337 174.103226h-131.539042v-54.935484h78.098613z" fill="#152b6f" />
                            <path
                                d="m186.212295 174.103226v-33.377791s13.052864-5.492819 30.534408 6.333814c24.055502 16.274012 30.681852 4.357238 57.267166 4.357238 18.687231 0 43.134149 22.686739 43.134149 22.686739z"
                                fill="#fff"
                            />
                            <path
                                d="m437.97899 86.4620924c-2.191742-4.0491735-6.408943-6.7931934-11.256137-6.7931934-1.340956 0-2.633541.2108912-3.84907.5999599-.31669-4.2417493-3.776038-7.5849879-8.006258-7.5849879-4.219742 0-7.673268 3.3264055-8.004564 7.553439-1.395149-.6255802-2.933508-.9788653-4.554425-.9788653-1.845734 0-3.58287.4604245-5.123451 1.2589948v43.7212705h40.793905z"
                                fill="#d8e4f4"
                            />
                            <path
                                d="m352.725963 38.8774194 14.871501 41.4129032h5.224321l-14.871395-41.4129032z"
                                fill="#8bb0dd"
                            />
                            <path
                                d="m339.303381 38.8774194 14.871395 41.4129032h5.224427l-14.871501-41.4129032z"
                                fill="#8bb0dd"
                            />
                            <path d="m375.50264 174.103226h41.880514v-76.9096776h-41.880514z" fill="#f27c73" />
                            <path d="m414.634559 174.103226h23.344431v-49.864516h-23.344431z" fill="#f27c73" />
                            <path
                                d="m378.792105 60.8516129 18.392875 19.4387097v16.9032258h-36.785855v-16.9032258z"
                                fill="#fcdfdc"
                            />
                            <path d="m350.511253 93.8129032h5.835472v-7.6064516h-5.835472z" fill="#fcdfdc" />
                            <path d="m350.511253 116.632258h5.835472v-7.606452h-5.835472z" fill="#fcdfdc" />
                            <path d="m364.718467 116.632258h5.835473v-7.606452h-5.835473z" fill="#fcdfdc" />
                            <path d="m350.511253 138.606452h5.835472v-7.606452h-5.835472z" fill="#fcdfdc" />
                            <path d="m364.718467 138.606452h5.835473v-7.606452h-5.835473z" fill="#fcdfdc" />
                            <path d="m382.51215 116.632258h5.835473v-7.606452h-5.835473z" fill="#d8e4f4" />
                            <path d="m396.719683 116.632258h5.835473v-7.606452h-5.835473z" fill="#d8e4f4" />
                            <path d="m382.51215 138.606452h5.835473v-7.606452h-5.835473z" fill="#d8e4f4" />
                            <path d="m396.719683 138.606452h5.835473v-7.606452h-5.835473z" fill="#d8e4f4" />
                            <path d="m410.926898 138.606452h5.835367v-7.606452h-5.835367z" fill="#d8e4f4" />
                            <path d="m425.134007 138.606452h5.835473v-7.606452h-5.835473z" fill="#d8e4f4" />
                            <path d="m382.51215 161.425806h5.835473v-8.451612h-5.835473z" fill="#d8e4f4" />
                            <path d="m396.719683 161.425806h5.835473v-8.451612h-5.835473z" fill="#d8e4f4" />
                            <path d="m410.926898 161.425806h5.835367v-8.451612h-5.835367z" fill="#d8e4f4" />
                            <path d="m425.134007 161.425806h5.835473v-8.451612h-5.835473z" fill="#d8e4f4" />
                            <path d="m382.51215 109.025806h5.835473v-.845161h-5.835473z" fill="#152b6f" />
                            <path d="m396.719683 109.025806h5.835473v-.845161h-5.835473z" fill="#152b6f" />
                            <path d="m382.51215 131.845161h5.835473v-1.690322h-5.835473z" fill="#152b6f" />
                            <path d="m396.719683 131.845161h5.835473v-1.690322h-5.835473z" fill="#152b6f" />
                            <path d="m410.926898 131.845161h5.835473v-1.690322h-5.835473z" fill="#1e3758" />
                            <path d="m425.134007 131.845161h5.835473v-1.690322h-5.835473z" fill="#152b6f" />
                            <path d="m382.51215 153.819355h5.835473v-.845161h-5.835473z" fill="#152b6f" />
                            <path d="m396.719683 153.819355h5.835473v-.845161h-5.835473z" fill="#152b6f" />
                            <path d="m410.926898 153.819355h5.835473v-.845161h-5.835473z" fill="#152b6f" />
                            <path d="m425.134007 153.819355h5.835473v-.845161h-5.835473z" fill="#152b6f" />
                            <path
                                d="m160.563713 119.167742c0-6.928736-5.477186-12.545617-12.233514-12.545617-3.598429 0-6.823752 1.602942-9.062172 4.139252-.21095-6.739174-5.590864-12.1394121-12.21351-12.1394121-1.844357 0-3.58668.4303114-5.154992 1.179958.001905-.0909048.013336-.179261.013336-.2709093 0-6.9287359-5.477185-12.5457229-12.233514-12.5457229-2.755369 0-5.28931.9453683-7.334456 2.5217609-.632745-6.3193759-5.8388602-11.2492665-12.1683138-11.2492665-3.1291098 0-5.9746596 1.2153218-8.1379294 3.196857-1.7952451-4.6399719-6.2024391-7.9256098-11.3662165-7.9256098-6.4433433 0-11.7112727 5.1115941-12.1867306 11.5947262-1.8313384-1.1208062-3.9645481-1.7763621-6.2521864-1.7763621-6.7563284 0-12.2335143 5.616987-12.2335143 12.5457229v23.2746227z"
                                fill="#d8e4f4"
                            />
                            <path
                                d="m110.254088 47.3290323 18.305869 18.5935483v47.3290324l-36.6117382 5.916129v-53.2451614z"
                                fill="#fcdfdc"
                            />
                            <path d="m40 174.103226h83.678893v-54.935484h-83.678893z" fill="#fffffe" />
                            <path
                                d="m71.2666478 90.4322581-31.2666478 28.7354839h83.678893l31.266754-28.7354839z"
                                fill="#8bb0dd"
                            />
                            <path d="m97.3087629 119.167742-15.4693163-14.367742h30.9387384z" fill="#f48f87" />
                            <path
                                d="m66.3701302 118.773145 15.4693164-13.973145 15.4693163 13.973145-13.9854686 8.84621z"
                                fill="#fffffe"
                            />
                            <path d="m135.5972 133.535484h3.072376v-2.535484h-3.072376z" fill="#d8e4f4" />
                            <path d="m135.5972 143.677419h3.072376v-3.380645h-3.072376z" fill="#d8e4f4" />
                            <path d="m135.5972 152.974194h3.072376v-3.380646h-3.072376z" fill="#d8e4f4" />
                            <path d="m135.5972 162.270968h3.072376v-3.380645h-3.072376z" fill="#d8e4f4" />
                            <path d="m144.814224 133.535484h3.072376v-2.535484h-3.072376z" fill="#d8e4f4" />
                            <path d="m144.814224 143.677419h3.072376v-3.380645h-3.072376z" fill="#d8e4f4" />
                            <path d="m144.814224 152.974194h3.072376v-3.380646h-3.072376z" fill="#d8e4f4" />
                            <path d="m144.814224 162.270968h3.072376v-3.380645h-3.072376z" fill="#d8e4f4" />
                            <path d="m154.031248 133.535484h3.072376v-2.535484h-3.072376z" fill="#d8e4f4" />
                            <path d="m154.031248 143.677419h3.072376v-3.380645h-3.072376z" fill="#d8e4f4" />
                            <path d="m154.031248 152.974194h3.072376v-3.380646h-3.072376z" fill="#d8e4f4" />
                            <path d="m154.031248 162.270968h3.072376v-3.380645h-3.072376z" fill="#d8e4f4" />
                            <path d="m163.248272 133.535484h3.072376v-2.535484h-3.072376z" fill="#d8e4f4" />
                            <path d="m163.248272 143.677419h3.072376v-3.380645h-3.072376z" fill="#d8e4f4" />
                            <path d="m163.248272 152.974194h3.072376v-3.380646h-3.072376z" fill="#d8e4f4" />
                            <path d="m163.248272 162.270968h3.072376v-3.380645h-3.072376z" fill="#d8e4f4" />
                            <path d="m47.9590506 136.070968h3.9314185v-10.987097h-3.9314185z" fill="#fcdfdc" />
                            <path d="m63.9795043 136.070968h3.9314185v-10.987097h-3.9314185z" fill="#fcdfdc" />
                            <path d="m79.9999581 136.070968h3.9314185v-10.987097h-3.9314185z" fill="#fcdfdc" />
                            <path d="m96.020306 136.070968h3.9314185v-10.987097h-3.9314185z" fill="#fcdfdc" />
                            <path d="m112.04076 136.070968h3.931418v-10.987097h-3.931418z" fill="#fcdfdc" />
                            <path d="m47.9590506 155.509677h3.9314185v-10.987096h-3.9314185z" fill="#fcdfdc" />
                            <path d="m63.9795043 155.509677h3.9314185v-10.987096h-3.9314185z" fill="#fcdfdc" />
                            <path d="m79.9999581 155.509677h3.9314185v-10.987096h-3.9314185z" fill="#fcdfdc" />
                            <path d="m96.020306 155.509677h3.9314185v-10.987096h-3.9314185z" fill="#fcdfdc" />
                            <path d="m112.04076 155.509677h3.931418v-10.987096h-3.931418z" fill="#fcdfdc" />
                            <path d="m47.9590506 125.929032h3.9314185v-.845161h-3.9314185z" fill="#f48f87" />
                            <path d="m63.9795043 125.929032h3.9314185v-.845161h-3.9314185z" fill="#f48f87" />
                            <path d="m79.9999581 125.929032h3.9314185v-.845161h-3.9314185z" fill="#f48f87" />
                            <path d="m96.020306 125.929032h3.9314185v-.845161h-3.9314185z" fill="#f48f87" />
                            <path d="m112.04076 125.929032h3.931418v-.845161h-3.931418z" fill="#f48f87" />
                            <path d="m47.9590506 145.367742h3.9314185v-1.690323h-3.9314185z" fill="#f48f87" />
                            <path d="m63.9795043 145.367742h3.9314185v-1.690323h-3.9314185z" fill="#f48f87" />
                            <path d="m79.9999581 145.367742h3.9314185v-1.690323h-3.9314185z" fill="#f48f87" />
                            <path d="m96.020306 145.367742h3.9314185v-1.690323h-3.9314185z" fill="#f48f87" />
                            <path d="m112.04076 145.367742h3.931418v-1.690323h-3.931418z" fill="#f48f87" />
                            <path d="m186.212295 38.8774194 21.72055-17.7483871 21.72055 17.7483871z" fill="#fffffe" />
                            <path
                                d="m229.653395 38.8774194-21.72055-17.8586573v-20.17360081l21.72055 17.85865721z"
                                fill="#8bb0dd"
                            />
                            <path
                                d="m186.212295 38.8774194 21.72055-17.8586573v-20.17360081l-21.72055 17.85865721z"
                                fill="#f27c73"
                            />
                            <path d="m199.523316 44.7935484h2.402798v-2.5354839h-2.402798z" fill="#fcdfdc" />
                            <path d="m206.731499 38.0322581h2.402692v-2.5354839h-2.402692z" fill="#fcdfdc" />
                            <path d="m206.731499 44.7935484h2.402692v-2.5354839h-2.402692z" fill="#fcdfdc" />
                            <path d="m213.939681 44.7935484h2.402693v-2.5354839h-2.402693z" fill="#fcdfdc" />
                            <path
                                d="m332.132773 38.8774194c0-5.9023026-4.655613-10.6871077-10.398471-10.6871077-3.058722 0-5.800226 1.3655819-7.702904 3.5260771-.179303-5.7407444-4.752251-10.3410027-10.381536-10.3410027-1.567678 0-3.048668.3666456-4.381686 1.0052156.001588-.0773734.011326-.1527246.011326-.2307366 0-5.9023026-4.655613-10.6871077-10.398577-10.6871077-2.342043 0-4.495787.8052368-6.234193 2.1481495-.537907-5.3832516-4.963094-9.58259442-10.343114-9.58259442-2.659791 0-5.078466 1.03512199-6.917319 2.72318181-1.525975-3.95264194-5.272058-6.75149429-9.661258-6.75149429-5.476868 0-9.954661 4.3544089-10.358779 9.8769752-1.556564-.95476859-3.369803-1.51319806-5.31429-1.51319806-5.742964 0-10.398577 4.78480506-10.398577 10.68710766v19.8265346z"
                                fill="#fcdfdc"
                            />
                            <path
                                d="m269.390645 128.464463c0 9.610856-3.943318 18.18092-7.319216 23.756813l-8.535132 1.494938c-3.533176-5.464866-8.254712-14.735212-8.254712-25.251751 0-16.803755 12.05453-30.4257533 12.05453-30.4257533s12.05453 13.6219983 12.05453 30.4257533l-10.654577 26.52586"
                                fill="#fcdfdc"
                            />
                            <path
                                d="m257.336115 163.96129c-.336589 0-.609458-.272468-.609458-.608562v-40.195778c0-.3362.272869-.608563.609458-.608563s.609458.272363.609458.608563v40.195778c0 .336094-.272869.608562-.609458.608562"
                                fill="#fff"
                            />
                            <path
                                d="m257.336115 135.225806c-.163955 0-.320923-.067806-.435554-.188205l-4.44551-4.664709c-.235295-.246884-.231167-.643071.00942-.88463.240693-.241885.626499-.23743.8619.009671l4.009744 4.207453 4.009638-4.207453c.235401-.247318.621313-.251339.861794-.00978.240693.241668.244927.637855.009526.884739l-4.445298 4.664709c-.114737.120399-.271705.188205-.43566.188205"
                                fill="#fff"
                            />
                            <path
                                d="m224.450245 151.55641c-1.618353-.525721-2.856412-1.04775-3.714177-1.566087-1.449809-.876104-3.673111-2.445736-6.669905-4.708898-2.044275-4.475589-3.747773-9.997472-3.747773-15.971801 0-14.936551 10.648055-27.045108 10.648055-27.045108s10.647949 12.108557 10.647949 27.045108c0 9.221427-4.058405 17.36494-7.164149 22.246786z"
                                fill="#8bb0dd"
                            />
                            <path
                                d="m250.502414 117.054891c0 16.570265-12.054424 30.003174-12.054424 30.003174s-12.054636-13.432909-12.054636-30.003174c0-16.570369 12.054636-30.0032781 12.054636-30.0032781s12.054424 13.4329091 12.054424 30.0032781"
                                fill="#f27c73"
                            />
                            <path
                                d="m238.447884 164.806452c-.336589 0-.609458-.274158-.609458-.61223v-51.175435c0-.338177.272869-.612335.609458-.612335.336694 0 .609458.274158.609458.612335v51.175435c0 .338072-.272764.61223-.609458.61223"
                                fill="#fff"
                            />
                            <path
                                d="m220.966339 163.96129c-.336588 0-.609458-.269454-.609458-.601597v-38.519386c0-.332247.27287-.601597.609458-.601597.336589 0 .609458.26935.609458.601597v38.519386c0 .332143-.272869.601597-.609458.601597"
                                fill="#fff"
                            />
                            <path
                                d="m448.072098 84.419007c0 14.0567696-10.093108 25.451961-10.093108 25.451961s-10.093107-11.3951914-10.093107-25.451961c0-.3904511.007727-.7788874.022863-1.1649907.508482-12.9666454 19.632007-12.9666454 20.140383 0 .015136.3861033.022969.7745396.022969 1.1649907"
                                fill="#8bb0dd"
                            />
                            <path
                                d="m437.97899 124.23871c-.336482 0-.609458-.271169-.609458-.605895v-31.749606c0-.3346207.272976-.6057896.609458-.6057896.336483 0 .609459.2711689.609459.6057896v31.749606c0 .334726-.272976.605895-.609459.605895"
                                fill="#2d376e"
                            />
                            <path
                                d="m473.338471 84.419007c0 14.0567696-10.093107 25.451961-10.093107 25.451961s-10.093107-11.3951914-10.093107-25.451961c0-.3904511.007727-.7788874.022863-1.1649907.508481-12.9666454 19.632007-12.9666454 20.140383 0 .015136.3861033.022968.7745396.022968 1.1649907"
                                fill="#f48f87"
                            />
                            <path
                                d="m463.245364 124.23871c-.336589 0-.609458-.271169-.609458-.605895v-31.749606c0-.3346207.272869-.6057896.609458-.6057896.336483 0 .609352.2711689.609352.6057896v31.749606c0 .334726-.272869.605895-.609352.605895"
                                fill="#2d376e"
                            />
                            <path
                                d="m18.1590425 164.711153-.4229742-.72083-13.63237-.016652c-1.76442328-4.128072-3.15813872-9.069606-3.15813872-14.380176 0-14.469822 10.34724202-26.199947 10.34724202-26.199947s10.3472421 11.730125 10.3472421 26.199947c0 5.630659-1.5667882 10.846455-3.4810012 15.117658z"
                                fill="#2d376e"
                            />
                            <path
                                d="m10.6833434 164.093369v-15.136993c0-.114867.2728696-.207989.6094582-.207989.3365887 0 .6094583.093122.6094583.207989v15.136993"
                                fill="#fff"
                            />
                            <path
                                d="m40.0152431 161.990323c-3.8368386 6.622525-7.7803245 12.112903-7.7803245 12.112903s-14.7967742-15.324836-14.7967742-34.229032c0-18.904197 14.7967742-34.229033 14.7967742-34.229033s3.6170752 5.41958 7.7803245 13.345162"
                                fill="#f48f87"
                            />
                            <path
                                d="m31.3462856 182.990323c0-10.866876 0-27.167188 0-48.900939 0-.305905.2728696-.5539.6094582-.5539s.6094583.247995.6094583.5539v50.756812"
                                fill="#fff"
                            />
                        </g>
                    </g>
                    <g data-depth="0.4">
                        <g transform="translate(-.643854 163.248387)">
                            <path d="m312.14635 49.8645161 18.305869-18.5935484 18.305869 18.5935484z" fill="#fcdfdc" />
                            <path
                                d="m216.609588 80.5959241 28.332188 3.0750436-10.9766-45.3705747c-.903285-3.7335589-6.103473-3.7398979-7.015544-.0085576z"
                                fill="#f27c73"
                            />
                            <path
                                d="m243.828387 36.6015128-10.340045 42.3040888 28.332188 3.0750436-10.976599-45.3705748c-.903285-3.7335589-6.103474-3.7398979-7.015544-.0085576"
                                fill="#fcdfdc"
                            />
                            <path
                                d="m208.069869 62.0011098c11.445177-15.4322122 21.245516-.2853052 29.734429.5164934 9.512015.8983828 7.928249-10.2283058 22.664797-9.093969 8.945107.6885873 11.115045 5.4256692 19.518859-.2053477 8.201225-5.4952481 17.801833-14.9379586 35.181554-12.1451665 19.065522 3.0636942 48.224998 62.881719 48.224998 62.881719l-30.944348-3.698906-114.423617-26.4018456z"
                                fill="#f48f87"
                            />
                            <path
                                d="m231.083745 62.9483505 4.370677-4.5212991c.235401-.2434537.621207-.247738.8619-.0096396.240587.2382055.24482.6287169.00942.8721707l-5.242831 5.4231272.000834 9.0362989c0 .3452255-.27287.6251849-.609458.6251849-.336589 0-.609459-.2799594-.609459-.6251849v-15.2540514l-3.750316-5.993482c-.180044-.2876888-.095473-.6686677.188934-.8508564.284406-.1822957.660793-.0966103.840942.1910786l2.719523 4.3460123.000917-9.9237065c0-.345334.27287-.6252935.609459-.6252935.336588 0 .609458.2799595.609458.6252935z"
                                fill="#2d376e"
                            />
                            <path
                                d="m348.758088 49.8645161-18.305869-18.5935484h57.652337l18.305869 18.5935484z"
                                fill="#fcdfdc"
                            />
                            <path
                                d="m386.227175 49.8645161-18.305869-18.5935484h-1.828375l18.305869 18.5935484z"
                                fill="#8bb0dd"
                            />
                            <path
                                d="m378.498338 49.8645161-18.305869-18.5935484h-1.828375l18.30587 18.5935484z"
                                fill="#8bb0dd"
                            />
                            <path
                                d="m370.769502 49.8645161-18.30587-18.5935484h-1.828374l18.305869 18.5935484z"
                                fill="#8bb0dd"
                            />
                            <path
                                d="m363.0413 49.8645161-18.306504-18.5935484h-1.828375l18.306504 18.5935484z"
                                fill="#8bb0dd"
                            />
                            <path
                                d="m355.312463 49.8645161-18.305869-18.5935484h-1.828269l18.30587 18.5935484z"
                                fill="#8bb0dd"
                            />
                            <path
                                d="m497.337613 47.3290323-28.450943-47.3290323h37.497981l28.450731 47.3290323z"
                                fill="#2d376e"
                            />
                            <path d="m497.37678 47.3290323-28.490004-47.3290323v47.3290323z" fill="#fcdfdc" />
                            <path
                                d="m401.684848 49.8645161-18.306398-18.5935484h-1.828481l18.306505 18.5935484z"
                                fill="#8bb0dd"
                            />
                            <path
                                d="m393.956012 49.8645161-18.306399-18.5935484h-1.82848l18.306504 18.5935484z"
                                fill="#8bb0dd"
                            />
                            <path
                                d="m70.9077858 113.251613-24.5717097-63.1687187 4.3104511 20.0049057 19.2636-1.2684452.9976586-18.7364605h105.0884692v63.1687187z"
                                fill="#d8e4f4"
                            />
                            <path
                                d="m41.3934099 70.9935484-22.3934099-31.2709678h29.514376l22.3934098 31.2709678z"
                                fill="#fcdfdc"
                            />
                            <path d="m41.3934099 119.493548h29.5143759v-48.4999996h-29.5143759z" fill="#8bb0dd" />
                            <path d="m19 39.7225806 22.3934099 31.2709678v48.7290326h-22.3934099z" fill="#f48f87" />
                            <path d="m28.4594102 92.9677419h3.4746953v-13.5225806h-3.4746953z" fill="#fff" />
                            <path d="m406.410425 113.251613h62.476351v-63.3870969h-62.476351z" fill="#d8e4f4" />
                            <path d="m497.376886 119.829032h37.29419v-72.4999997h-37.29419z" fill="#fff" />
                            <path d="m468.886776 119.829032h28.490004v-72.4999997h-28.490004z" fill="#fcdfdc" />
                            <path d="m480.921407 67.6129032h4.420742v-20.2838709h-4.420742z" fill="#fff" />
                            <path d="m480.921407 99.7290323h4.420742v-20.283871h-4.420742z" fill="#fff" />
                            <path
                                d="m485.950761 67.6129032h-1.217223v-19.879443h-3.812131v-1.2495892h5.029354z"
                                fill="#f17f77"
                            />
                            <path
                                d="m485.950761 99.7290323h-1.217223v-19.8794431h-3.812131v-1.2495892h5.029354z"
                                fill="#f17f77"
                            />
                            <path d="m197.734376 74.3741935-21.738121-24.5096774v24.5096774z" fill="#fffffe" />
                            <path d="m214.045481 91.2774194h8.841273v-1.6903226h-8.841273z" fill="#f48f87" />
                            <path d="m348.758088 119.864516h57.652337v-69.9999999h-57.652337z" fill="#8bb0dd" />
                            <g fill="#fff">
                                <path d="m354.758257 59.1612903h2.402692v-2.5354838h-2.402692z" />
                                <path d="m354.758257 66.7677419h2.402692v-2.5354838h-2.402692z" />
                                <path d="m354.758257 73.5290323h2.402692v-2.5354839h-2.402692z" />
                                <path d="m354.758257 81.1354839h2.402692v-2.5354839h-2.402692z" />
                                <path d="m354.758257 88.7419355h2.402692v-2.5354839h-2.402692z" />
                                <path d="m361.966439 59.1612903h2.402693v-2.5354838h-2.402693z" />
                                <path d="m361.966439 66.7677419h2.402693v-2.5354838h-2.402693z" />
                                <path d="m361.966439 73.5290323h2.402693v-2.5354839h-2.402693z" />
                                <path d="m361.966439 81.1354839h2.402693v-2.5354839h-2.402693z" />
                                <path d="m361.966439 88.7419355h2.402693v-2.5354839h-2.402693z" />
                                <path d="m369.174728 59.1612903h2.402798v-2.5354838h-2.402798z" />
                                <path d="m369.174728 66.7677419h2.402798v-2.5354838h-2.402798z" />
                                <path d="m369.174728 73.5290323h2.402798v-2.5354839h-2.402798z" />
                                <path d="m369.174728 81.1354839h2.402798v-2.5354839h-2.402798z" />
                                <path d="m369.174728 88.7419355h2.402798v-2.5354839h-2.402798z" />
                                <path d="m376.382911 59.1612903h2.402692v-2.5354838h-2.402692z" />
                                <path d="m376.382911 66.7677419h2.402692v-2.5354838h-2.402692z" />
                                <path d="m376.382911 73.5290323h2.402692v-2.5354839h-2.402692z" />
                                <path d="m376.382911 81.1354839h2.402692v-2.5354839h-2.402692z" />
                                <path d="m376.382911 88.7419355h2.402692v-2.5354839h-2.402692z" />
                                <path d="m383.591199 59.1612903h2.402693v-2.5354838h-2.402693z" />
                                <path d="m383.591199 66.7677419h2.402693v-2.5354838h-2.402693z" />
                                <path d="m383.591199 73.5290323h2.402693v-2.5354839h-2.402693z" />
                                <path d="m383.591199 81.1354839h2.402693v-2.5354839h-2.402693z" />
                                <path d="m383.591199 88.7419355h2.402693v-2.5354839h-2.402693z" />
                                <path d="m390.799276 59.1612903h2.402693v-2.5354838h-2.402693z" />
                                <path d="m390.799276 66.7677419h2.402693v-2.5354838h-2.402693z" />
                                <path d="m390.799276 73.5290323h2.402693v-2.5354839h-2.402693z" />
                                <path d="m390.799276 81.1354839h2.402693v-2.5354839h-2.402693z" />
                                <path d="m390.799276 88.7419355h2.402693v-2.5354839h-2.402693z" />
                                <path d="m398.007565 59.1612903h2.402798v-2.5354838h-2.402798z" />
                                <path d="m398.007565 66.7677419h2.402798v-2.5354838h-2.402798z" />
                                <path d="m398.007565 73.5290323h2.402798v-2.5354839h-2.402798z" />
                                <path d="m398.007565 81.1354839h2.402798v-2.5354839h-2.402798z" />
                                <path d="m398.007565 88.7419355h2.402798v-2.5354839h-2.402798z" />
                                <path d="m354.758257 96.3483871h2.402692v-2.5354839h-2.402692z" />
                                <path d="m361.966439 96.3483871h2.402693v-2.5354839h-2.402693z" />
                                <path d="m369.174728 96.3483871h2.402798v-2.5354839h-2.402798z" />
                                <path d="m376.382911 96.3483871h2.402692v-2.5354839h-2.402692z" />
                                <path d="m383.591199 96.3483871h2.402693v-2.5354839h-2.402693z" />
                                <path d="m390.799276 96.3483871h2.402693v-2.5354839h-2.402693z" />
                                <path d="m398.007565 96.3483871h2.402798v-2.5354839h-2.402798z" />
                            </g>
                            <path
                                d="m162.698888 110.39109c-9.243168-2.103672-4.05113-10.5587619-18.285335-14.024259-8.640272-2.1035393-15.123943 5.615817-22.594093 1.3167719-10.161166-5.8475945-13.902803-10.63199-31.3302611-10.63199-11.0079295 0-38.9511352 1.063199-49.095789 32.5000001h134.6028451v-10.074209c-4.762094.382618-9.624952 1.749494-13.297367.913686"
                                fill="#152b6f"
                            />
                            <path
                                d="m247.9625 61.2581298 4.370677-4.5212032c.235294-.2435614.621207-.2478457.861794-.0096396.240692.238099.244926.6286113.009526.8720657l-5.242751 5.4220344.000754 20.872402c0 .3437029-.27287.6223399-.609353.6223399-.336694 0-.609564-.278637-.609564-.6223399v-27.0890637l-3.750211-5.9934968c-.180043-.2876895-.095578-.6686694.188934-.8508585.284301-.1824033.660688-.0967176.840943.191079l2.719497 4.345938.000837-9.9266601c0-.3435947.27287-.6223399.609564-.6223399.336483 0 .609353.2787452.609353.6223399z"
                                fill="#2d376e"
                            />
                            <path
                                d="m214.49977 59.8040696c9.845958-3.0878665 24.313447 5.558181 29.939663 12.9690182 5.626322 7.4108372 9.243168.4117368 16.275965 3.293788 9.262749 3.7957015 16.67786 24.0852742 27.528503 22.0266963 10.850537-2.0585777 17.481545-3.911234 17.481545-3.911234l3.415951 11.1162559-82.585404 2.882051z"
                                fill="#fffffe"
                            />
                            <path
                                d="m225.551308 105.528031c5.626216-18.5998841 19.882332-7.7066478 28.131293-9.9199738 9.243062-2.4800201 4.05113-12.4477088 18.285335-16.5333248 8.640272-2.4799135 12.257224 1.2399568 18.285335-7.0265991 5.882892-8.0676004 4.621531-19.0133448 21.893079-22.4559768 18.946869-3.7766386 23.279019 12.3581362 23.279019 12.3581362l-4.97315 19.4716452-13.655654 31.8296751h-77.93847z"
                                fill="#d8e4f4"
                            />
                            <path
                                d="m197.734376 74.3741935-21.738121-24.5096774h41.123825l21.738015 24.5096774z"
                                fill="#d8e4f4"
                            />
                            <path d="m197.734376 119.874194h41.123825v-45.5000005h-41.123825z" fill="#8bb0dd" />
                            <path d="m175.996255 119.874194h21.738121v-45.5000005h-21.738121z" fill="#fffffe" />
                            <path d="m214.045481 90.4322581h8.841273v-5.9161291h-8.841273z" fill="#fcdfdc" />
                            <path d="m214.045481 104.8h8.841273v-5.916129h-8.841273z" fill="#fcdfdc" />
                            <path d="m214.045481 105.645161h8.841273v-1.690322h-8.841273z" fill="#f48f87" />
                            <path d="m312.14635 119.864516h36.611738v-69.9999999h-36.611738z" fill="#fcdfdc" />
                            <path
                                d="m555.540139 65.5000529c0 20.3045077-14.616413 36.7644631-14.616413 36.7644631s-3.176683-3.3756495-6.279872-8.5129031v-46l-2.632393-5.1458796c4.101605-8.4528304 8.912265-13.8702494 8.912265-13.8702494s14.616413 16.4599555 14.616413 36.764569l-20.896285-17.74844"
                                fill="#8bb0dd"
                            />
                            <path d="m540.314267 121.251613h1.218917v-57.0193549h-1.218917z" fill="#2d376e" />
                            <path
                                d="m18.9495433 91.9042299c-3.0559335 8.1832791-7.9583413 13.7409311-7.9583413 13.7409311s-10.34734786-11.7302305-10.34734786-26.2000529c0-14.4698225 10.34734786-26.1999468 10.34734786-26.1999468s4.9761511 5.6412008 8.0267223 13.925243"
                                fill="#8bb0dd"
                            />
                            <path d="m10.3817438 113.251613h1.2189165v-34.651613h-1.2189165z" fill="#2d376e" />
                            <path
                                d="m570.076957 79.4451081c0 14.4698224-10.347242 26.2000529-10.347242 26.2000529s-10.347242-11.7302305-10.347242-26.2000529c0-14.4698225 10.347242-26.1999468 10.347242-26.1999468s10.347242 11.7301243 10.347242 26.1999468"
                                fill="#f48f87"
                            />
                            <path d="m559.120256 113.251613h1.218917v-34.651613h-1.218917z" fill="#2d376e" />
                            <path
                                d="m425.934576 82.8258596c0 10.7356268-7.684064 19.4386564-7.684064 19.4386564s-7.68417-8.7030296-7.68417-19.4386564c0-10.7357331 7.68417-19.4387628 7.68417-19.4387628s7.684064 8.7030297 7.684064 19.4387628"
                                fill="#2d376e"
                            />
                            <path d="m417.641054 113.251613h1.218916v-32.1161291h-1.218916z" fill="#fff" />
                            <path
                                d="m445.170615 82.8258596c0 10.7356268-7.684169 19.4386564-7.684169 19.4386564s-7.68417-8.7030296-7.68417-19.4386564c0-10.7357331 7.68417-19.4387628 7.68417-19.4387628s7.684169 8.7030297 7.684169 19.4387628"
                                fill="#8bb0dd"
                            />
                            <path d="m436.876987 113.251613h1.218811v-32.1161291h-1.218811z" fill="#fff" />
                            <path
                                d="m464.406549 82.8258596c0 10.7356268-7.68417 19.4386564-7.68417 19.4386564s-7.684064-8.7030296-7.684064-19.4386564c0-10.7357331 7.684064-19.4387628 7.684064-19.4387628s7.68417 8.7030297 7.68417 19.4387628"
                                fill="#f27c73"
                            />
                            <path d="m456.113027 113.251613h1.21881v-32.1161291h-1.21881z" fill="#fff" />
                            <path
                                d="m98.8377608 59.1612903c0 14.4698518-10.347242 26.2-10.347242 26.2s-10.3472421-11.7301482-10.3472421-26.2 10.3472421-26.2 10.3472421-26.2 10.347242 11.7301482 10.347242 26.2"
                                fill="#fcdfdc"
                            />
                            <path
                                d="m87.8810605 87.0791276v-32.417033c0-.3157754.2727638-.571772.6094583-.571772.3365886 0 .6094582.2559966.6094582.571772v32.417033"
                                fill="#fff"
                            />
                            <path
                                d="m143.747361 62.1193025c0 14.2364381-10.347348 25.7774717-10.347348 25.7774717s-10.347242-11.5410336-10.347242-25.7774717c0-14.2364382 10.347242-25.777367 10.347242-25.777367s10.347348 11.5409288 10.347348 25.777367"
                                fill="#f48f87"
                            />
                            <path
                                d="m133.400013 97.1935484c-.336588 0-.609458-.2772125-.609458-.6191581v-35.1036193c0-.3418381.27287-.6191581.609458-.6191581.336589 0 .609459.27732.609459.6191581v35.1036193c0 .3419456-.27287.6191581-.609459.6191581"
                                fill="#fff"
                            />
                            <path
                                d="m169.223627 60.4289793c0 18.9042257-14.796775 34.2290852-14.796775 34.2290852s-14.796668-15.3248595-14.796668-34.2290852c0-18.9041198 14.796668-34.2289793 14.796668-34.2289793s14.796775 15.3248595 14.796775 34.2289793"
                                fill="#8bb0dd"
                            />
                            <path
                                d="m154.426852 100.574194c-.336588 0-.609458-.277109-.609458-.6189265v-46.0911802c0-.3418174.27287-.618926.609458-.618926.336589 0 .609459.2771086.609459.618926v46.0911802c0 .3418175-.27287.6189265-.609459.6189265"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </svg>
            </div>
            <div class="content u-text-align--center">
                <img
                    class="u-margin-bottom--3xl"
                    width="139"
                    height="38"
                    [src]="'/static/images/organisations/logos/' + themeName + '-regular.svg' | upCdnUrl"
                    alt="{{ orgName }}"
                />
                <h1 class="heading u-margin-bottom--2xl">You've stumbled across a vacant block.</h1>
                <div class="u-text-body--sm">
                    <div class="u-text-weight--semi-bold u-margin-bottom--sm">
                        <div> We couldn't find the page you're looking for. </div>
                    </div>
                    <nav *ngIf="!isInternal">
                        <span class="link">
                            <up-link [upSref]="'conversion.property-report.address'">Property Estimate</up-link>
                        </span>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
