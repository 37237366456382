<up-vertically-centered-layout>
    <up-isolated-container [showLogo]="true">
        <ng-container heading> Your request has been submitted. </ng-container>
        <div class="u-margin-bottom--lg">
            <div class="u-margin-bottom--sm"> Expect a call from us in the next 48 hours. </div>
            <div>
                In the meantime, did you know that at Upside you can instantly get a free online value estimate on any
                property?
            </div>
        </div>
        <up-button
            [size]="'large'"
            [fullWidth]="true"
            [style]="'primary'"
            [upSref]="'conversion.property-report.address'"
        >
            Get Free Property Report
        </up-button>
    </up-isolated-container>
</up-vertically-centered-layout>
