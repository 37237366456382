<up-container class="wrapper" [fullWidth@mn]="true">
    <div class="inner">
        <up-grid [gutters]="'none'">
            <up-grid-col [col]="12" [col@sm]="7" class="content-col">
                <div class="content">
                    <h2 class="u-text-heading--lg heading">
                        The market is changing. <br class="heading-break" />
                        Find out the value of your property now.
                    </h2>
                    <up-media-object [gutterSize]="'small'" class="u-margin-bottom--lg">
                        <up-icon [name]="'timer'" [color]="'currentColor'" figure></up-icon>
                        <ng-container body>
                            Get a free online property report from {{ orgDisplayName }}. <br class="caption-break" />
                            <strong>It takes seconds.</strong>
                        </ng-container>
                    </up-media-object>
                    <nc-address-finder class="address-finder" (addressSelect)="onAddressUpdated($event)">
                    </nc-address-finder>
                </div>
            </up-grid-col>
            <up-grid-col class="content-col" [col]="12" [col@sm]="5">
                <img
                    class="preview-image"
                    [lazyLoad]="
                        '/static/images/screens/property-report-preview-laptop.png'
                            | ncLocalisedAsset: ['orgGroup', 'country']
                            | upCdnUrl
                    "
                    alt="Property report preview"
                />
            </up-grid-col>
        </up-grid>
    </div>
</up-container>
