import { AspectDto } from '../../dto/property/aspect.dto';

export namespace Aspect {
    export enum Enum {
        North = 'aspect.north',
        East = 'aspect.east',
        South = 'aspect.south',
        West = 'aspect.west',
    }

    export function toArray(): Enum[] {
        return [Enum.North, Enum.East, Enum.South, Enum.West];
    }

    export function toDto(model: Enum): AspectDto | undefined {
        switch (model) {
            case Enum.North:
                return 'north';
            case Enum.East:
                return 'east';
            case Enum.South:
                return 'south';
            case Enum.West:
                return 'west';
        }
    }

    export function fromDto(dto: AspectDto): Enum | undefined {
        switch (dto) {
            case 'north':
                return Enum.North;
            case 'east':
                return Enum.East;
            case 'south':
                return Enum.South;
            case 'west':
                return Enum.West;
        }
    }
}
