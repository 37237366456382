import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import { RouteLoadingBarService } from '../../services/route-loading-bar.service';

export type RouteLoadingBarAnimationState = 'loading' | 'complete';

@Component({
    selector: 'up-route-loading-bar',
    templateUrl: './route-loading-bar.component.html',
    styleUrls: ['./route-loading-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('loader', [
            transition('* => loading', [
                animate(
                    '30s',
                    keyframes([
                        style({ transform: 'scaleX(0)', offset: 0 }),
                        style({ transform: 'scaleX(0.6)', offset: 0.02 }),
                        style({ transform: 'scaleX(0.65)', offset: 0.1 }),
                        style({ transform: 'scaleX(0.75)', offset: 0.12 }),
                        style({ transform: 'scaleX(0.9)', offset: 1 }),
                    ]),
                ),
            ]),
            transition('loading => complete', [
                animate(
                    '300ms',
                    keyframes([
                        style({ transform: 'scaleX(0.8)' }),
                        style({ transform: 'scaleX(1)' }),
                        style({ opacity: 0 }),
                    ]),
                ),
            ]),
        ]),
    ],
})
export class RouteLoadingBarComponent {
    public animationState$: Observable<RouteLoadingBarAnimationState>;

    constructor(routeLoadingBarService: RouteLoadingBarService) {
        this.animationState$ = routeLoadingBarService.progressConfigUpdate$;
    }
}
