import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AddressT } from '../../../../common/models/address.model';
import { UseCase } from '../../../../common/models/core/use-case.model';
import { AddressFinderResource } from '../../../../modules/core/resources/address-finder.resource';

interface Input {
    addressFinderResultId: string;
}

export type Output = AddressT;

@Injectable({ providedIn: 'root' })
export class GetAddressFromAddressFinderResultIdUseCase implements UseCase<Output, Input> {
    constructor(private readonly addressFinderResource: AddressFinderResource) {}

    public execute(input: Input): Observable<Output> {
        return this.addressFinderResource
            .fetchResultMetadata(input.addressFinderResultId)
            .pipe(map(({ address }) => address));
    }
}
