import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export type LineStyle = 'solid' | 'dashed';
export type DotAlignment = 'top' | 'center';
export type DotSize = 'small' | 'medium';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'ol[up-timeline], ol[nc-timeline]',
    templateUrl: 'timeline.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent implements OnChanges, OnDestroy {
    @Input() public dotFilled: boolean;
    @Input() public dotColor: string;
    @Input() public dotAlignment: DotAlignment = 'top';
    @Input() public dotSize: DotSize = 'medium';
    @Input() public lineColor: string;
    @Input() public lineStyle: LineStyle;
    @Input() public contentPadding = true;
    @Input() public showLineForSingleItem = false;
    public _changeNotifier: Observable<void>;
    private changeNotifierSubject = new Subject<void>();

    constructor() {
        this._changeNotifier = this.changeNotifierSubject.asObservable();
    }

    public ngOnChanges(): void {
        this.changeNotifierSubject.next();
    }

    public ngOnDestroy(): void {
        this.changeNotifierSubject.complete();
    }
}
