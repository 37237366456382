import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { activityApi } from '../../../common/models/activity-api.model';

const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

const BASE_URL = '/api/v2/activity';

@Injectable()
export class ActivityResource {
    constructor(private httpClient: HttpClient) {}

    public getPropertyActivity(
        propertyId: string,
        excludeBuyerActivity = false,
    ): Observable<activityApi.getPropertyActivity.get.Response> {
        return this.httpClient.get<activityApi.getPropertyActivity.get.Response>(`${BASE_URL}/property/${propertyId}`, {
            params: {
                excludeBuyer: `${excludeBuyerActivity}`,
            },
        });
    }

    public getInterestBuyerActivity(
        propertyId: string,
        buyerId: string,
    ): Observable<activityApi.getInterestedBuyerActivity.get.Response> {
        return this.httpClient.get<activityApi.getInterestedBuyerActivity.get.Response>(
            `${BASE_URL}/property/${propertyId}/buyers/${buyerId}`,
            HEADERS_CONFIG,
        );
    }

    public getUserActivity(userId: string) {
        return this.httpClient.get<activityApi.getUserActivity.get.Response>(
            `${BASE_URL}/user/${userId}`,
            HEADERS_CONFIG,
        );
    }

    public getPropertyPricingActivity(propertyId: string) {
        return this.httpClient.get<activityApi.getPropertyPricingActivity.get.Response>(
            `${BASE_URL}/property/${propertyId}/pricing/`,
            HEADERS_CONFIG,
        );
    }
}
