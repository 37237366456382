import { AddressT } from './address.model';
import { media } from './media.model';
import { open } from './open.model';
import { phase } from './phase.model';
import { AgentT, CampaignStatusT, Property, PropertyFeaturesT, PropertyType } from './property.model';

export namespace listings {
    export interface LostAndSoldListing {
        created: string;
        lostDate: string;
        otherReason?: string;
        reason: phase.LostAndSoldReasons;
        externalAgencyName?: string;
        externalAgentName?: string;
    }

    interface AgentRole {
        agent: AgentT;
        role: 'Primary' | 'Secondary';
    }

    export interface BaseListing {
        id: string;
        shared: string;
        address: AddressT;
        bedrooms: number;
        bathrooms: number;
        parking: number;
        parkingGarage?: number;
        parkingCarport?: number;
        parkingOther?: number;
        salePrice?: number;
        soldPrice?: number;
        soldDate?: string;
        amountSaved?: number;
        advertisedPrice?: string;
        agent?: AgentT;
        agentRoles?: AgentRole[];
        mainImage: media.File;
        campaignStatus: CampaignStatusT;
        propertyType: PropertyType;
        slug: string;
        timeZone: string;
        structuredLandSize?: Property.AreaAttribute;
        structuredInternalSize?: Property.AreaAttribute;
    }

    export interface ListingCard extends BaseListing {
        listingUrl?: string;
    }

    export interface PropertyListing extends BaseListing {
        floorPlan: media.File;
        opens: open.Open[];
        images: media.File[];
        virtualTourUrl?: string;
        auction?: { dateTime: string };
        statementOfInformation?: media.File;
        features: PropertyFeaturesT | {};
        councilRatesPerQuarter?: number;
        landSize?: number;
        markdown: string;
        title: string;
        description: string;
        ensuites?: number;
        energyRating?: number;
        state: State;
    }

    export interface PaginationMeta {
        page: number;
        pageSize: number;
        totalListings: number;
        totalPages: number;
    }

    export interface Listing {
        headline?: string;
        id: string;
        longCopy?: string;
        markdown?: string;
        shortCopy?: string;
        copywritingBrief?: string;
        state: State;
    }

    export enum State {
        Unapproved = 'Unapproved',
        Live = 'Live',
        Paused = 'Paused',
        Complete = 'Complete',
        Withdrawn = 'Withdrawn',
        Pending = 'Pending',
    }

    export type Sort = 'price-asc' | 'price-desc' | 'most-recent';
}
