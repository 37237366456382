import { DOCUMENT, isPlatformServer } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    Inject,
    Input,
    PLATFORM_ID,
} from '@angular/core';
import { throttle } from 'lodash-es';
import { Observable, of } from 'rxjs';

import { NavPaneMarketingComponent } from '../nav-pane-marketing/nav-pane-marketing.component';

@Component({
    selector: 'up-nav-bar-marketing',
    templateUrl: 'nav-bar-marketing.component.html',
    styleUrls: ['nav-bar-marketing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarMarketingComponent {
    @Input() public navPane: NavPaneMarketingComponent;
    public isScrolled: boolean;
    private readonly scrollThreshold = 10;
    private readonly throttledOnWindowScroll: Function;

    public get mobilePaneOpen$(): Observable<boolean> {
        if (!this.navPane) return of(false);
        return this.navPane.open$;
    }

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {
        this.throttledOnWindowScroll = throttle(() => this.calcIsScrolled(), 200);
    }

    @HostListener('window:scroll')
    public onWindowScroll(): void {
        this.throttledOnWindowScroll();
    }

    public calcIsScrolled(): void {
        if (isPlatformServer(this.platformId)) return;
        const normalScroll =
            this.document.body.scrollTop +
                ((this.document.documentElement && this.document.documentElement.scrollTop) || 0) >
            this.scrollThreshold;
        // Handles faux scroll positioning used by angular CDK overlay block scroll strategy
        const fauxScroll = parseInt(this.document.documentElement.style.top) * -1 > this.scrollThreshold;
        this.isScrolled = normalScroll || fauxScroll;
        this.changeDetectorRef.detectChanges();
    }

    public onClickHamburger(): void {
        if (!this.navPane) return;
        this.navPane.toggle();
    }
}
