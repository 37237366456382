import { InjectionToken } from '@angular/core';

type AppointmentTypeT =
    | 'AGENT_PRE_APPRAISAL'
    | 'AGENT_APPRAISAL_VISIT'
    | 'AGENT_FOLLOW_UP'
    | 'AGENT_LISTING_VISIT'
    | 'AGENT_CAMPAIGN_PROGRESS'
    | 'AGENT_PRE_AUCTION_VISIT'
    | 'AGENT_PUBLIC_OPEN'
    | 'AGENT_PRIVATE_OPEN'
    | 'AGENT_POST_SETTLEMENT'
    | 'AGENT_FINAL_INSPECTION'
    | 'BUILDING_INSPECTION'
    | 'BANK_VALUATION'
    | 'MID_SETTLEMENT_INSPECTION'
    | 'AGENT_OTHER'
    | 'AUCTIONEER_AUCTION'
    | 'PHOTOGRAPHER_VISIT'
    | 'SET_TO_SELL'
    | 'PRICE_ALIGNMENT';

interface AppointmentsTypeT {
    AGENT_PRE_APPRAISAL: AppointmentTypeT;
    AGENT_APPRAISAL_VISIT: AppointmentTypeT;
    AGENT_FOLLOW_UP: AppointmentTypeT;
    AGENT_LISTING_VISIT: AppointmentTypeT;
    AGENT_CAMPAIGN_PROGRESS: AppointmentTypeT;
    AGENT_PRE_AUCTION_VISIT: AppointmentTypeT;
    AGENT_PUBLIC_OPEN: AppointmentTypeT;
    AGENT_PRIVATE_OPEN: AppointmentTypeT;
    AGENT_POST_SETTLEMENT: AppointmentTypeT;
    AGENT_FINAL_INSPECTION: AppointmentTypeT;
    BUILDING_INSPECTION: AppointmentTypeT;
    BANK_VALUATION: AppointmentTypeT;
    MID_SETTLEMENT_INSPECTION: AppointmentTypeT;
    AGENT_OTHER: AppointmentsTypeT;
    AUCTIONEER_AUCTION: AppointmentTypeT;
    PHOTOGRAPHER_VISIT: AppointmentsTypeT;
    SET_TO_SELL: AppointmentsTypeT;
    PRICE_ALIGNMENT: AppointmentsTypeT;
}

const APPOINTMENTS_TYPE_DI_CONFIG = {
    AGENT_PRE_APPRAISAL: 'Pre-Appraisal',
    AGENT_APPRAISAL_VISIT: 'Appraisal',
    AGENT_FOLLOW_UP: 'Appraisal Follow Up',
    AGENT_LISTING_VISIT: 'Listing',
    AGENT_CAMPAIGN_PROGRESS: 'Campaign Progress',
    AGENT_PRE_AUCTION_VISIT: 'Pre-Auction',
    AGENT_PUBLIC_OPEN: 'Public Inspection',
    AGENT_PRIVATE_OPEN: 'Private Inspection',
    AGENT_POST_SETTLEMENT: 'Post Settlement',
    AGENT_FINAL_INSPECTION: 'Final Inspection',
    BUILDING_INSPECTION: 'Building Inspection',
    BANK_VALUATION: 'Bank Valuation',
    MID_SETTLEMENT_INSPECTION: 'Mid Settlement Inspection',
    AGENT_OTHER: 'Other',
    AUCTIONEER_AUCTION: 'Auction',
    PHOTOGRAPHER_VISIT: 'Photographer Visit',
    SET_TO_SELL: 'Set to Sell',
    PRICE_ALIGNMENT: 'Price Alignment',
};

/** @Deprecated - use enum in appointment.model instead */
let APPOINTMENTS_TYPE_CONFIG = new InjectionToken<AppointmentsTypeT>('app.constants.appointments');

export { AppointmentTypeT, AppointmentsTypeT, APPOINTMENTS_TYPE_DI_CONFIG, APPOINTMENTS_TYPE_CONFIG };
