import { NgModule } from '@angular/core';

import {
    QrcodeGeneratorFactory,
    QrcodeGeneratorFactoryToken,
} from '../../common/injection-tokens/qrcode-generator.injection-token';

import { QrCodeGeneratorService } from './services/qr-code-generator.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [{ provide: QrcodeGeneratorFactoryToken, useValue: QrcodeGeneratorFactory }, QrCodeGeneratorService],
})
export class QrCodeGeneratorModule {}
