import { Pipe, PipeTransform } from '@angular/core';

import { environment } from '../../../../common/models/environment.model';
import { EnvironmentService } from '../../../core/services/environment.service';

type Appendable = 'country' | 'orgGroup';

@Pipe({ name: 'ncLocalisedAsset' })
export class LocalisedAssetPipe implements PipeTransform {
    constructor(private environmentService: EnvironmentService) {}

    public transform(src: string, appendables: Appendable[]): string {
        if (typeof src !== 'string' || !appendables?.length) return src;

        const filetypeStartIndex = src.lastIndexOf('.');

        if (filetypeStartIndex <= 0) {
            throw new Error('Asset must have a filetype');
        }

        const appendableOrgValuesMap = new Map<Appendable, string | environment.GroupedOrganisationName>([
            ['orgGroup', this.environmentService.currentOrgGrouping],
            ['country', this.environmentService.currentOrgAlpha2],
        ]);
        const filename = src.substring(0, filetypeStartIndex);
        const filetype = src.substring(filetypeStartIndex);
        const toAppend = Array.from(appendableOrgValuesMap.entries())
            .filter(([appendable]) => appendables.includes(appendable))
            .map(([, value]) => value)
            .join('-');

        return `${filename}-${toAppend}${filetype}`;
    }
}
