import { agent } from '../../common/models/agent.model';
import { todo } from '../../common/models/todo.model';

import {
    InternalUserActions,
    LOAD_CALENDAR,
    LOAD_CALENDAR_ERROR,
    LOAD_CALENDAR_SUCCESS,
} from './internal-user.actions';

export namespace InternalUserState {
    export interface Calendar {
        meetings: todo.Appointment[];
        blockedTimes: agent.BlockedTime[];
        leaves: agent.Leave[];
        timeZone: string;
        loading: boolean;
    }
}

export interface InternalUserState {
    calendar: InternalUserState.Calendar;
}

export const initialInternalUserState: InternalUserState = {
    calendar: {
        meetings: [],
        blockedTimes: [],
        leaves: [],
        timeZone: 'Australia/Sydney',
        loading: false,
    },
};

export function internalUserReducer(state: InternalUserState = initialInternalUserState, action: InternalUserActions) {
    switch (action.type) {
        case LOAD_CALENDAR: {
            const calendar = {
                ...state.calendar,
                loading: true,
            };
            return {
                ...state,
                calendar,
            };
        }
        case LOAD_CALENDAR_SUCCESS: {
            const calendar = {
                ...state.calendar,
                ...action.payload,
                loading: false,
            };
            return {
                ...state,
                calendar,
            };
        }
        case LOAD_CALENDAR_ERROR: {
            const calendar = {
                ...state.calendar,
                loading: false,
            };
            return {
                ...state,
                calendar,
            };
        }
        default: {
            return state;
        }
    }
}
