import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Moment } from 'moment';

@Component({
    selector: 'up-appraisal-booker-day',
    templateUrl: './appraisal-booker-day.component.html',
    styleUrls: ['./appraisal-booker-day.component.scss'],
})
export class AppraisalBookerDayComponent {
    @Input() public active: boolean;
    @Input() public disabled: boolean;
    @Input() public date: Moment;
    @Output() public upClick: EventEmitter<void> = new EventEmitter<void>();

    public click(): void {
        this.upClick.emit();
    }

    public get ariaLabel(): string {
        return this.date
            ? `${this.date.format('ddd DD/MM/YY')}${this.disabled ? ', no times available' : ''}${
                  this.active ? ', selected' : ''
            }`
            : '';
    }
}
