import { HttpErrorResponse } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AddressT } from '../../models/address.model';
import { AddressFinderAutocompleteResultDto } from '../../models/dto/address-finder/address-finder-autocomplete-result.dto';
import { AddressFinderMetadataResultDto } from '../../models/dto/address-finder/address-finder-metadata-result.dto';
import { AddressFinderService } from '../../services/address-finder.service';
import { AddressService } from '../../services/address.service';
import { ErrorHandlerService } from '../../services/error-handler.service';

let addressFinderCount = 0;

@Component({
    selector: 'nc-address-finder',
    templateUrl: 'address-finder.component.html',
    styleUrls: ['address-finder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // Needed for Control Value Accessor
            // eslint-disable-next-line @angular-eslint/no-forward-ref
            useExisting: forwardRef(() => AddressFinderComponent),
            multi: true,
        },
    ],
})
export class AddressFinderComponent implements OnInit, ControlValueAccessor {
    @Output() public addressSelect = new EventEmitter<AddressT | undefined>();
    @Input() public value: AddressT;
    @Input() public placeholder = 'Property address';
    @Input() public id: string;
    @Input() public hideCta = false;
    @Input() public ctaLabel: string;
    public filteredAutocompleteResults$: Observable<AddressFinderAutocompleteResultDto[]>;
    public isLoadingMetadata = false;
    public displayFn = (address?: AddressFinderMetadataResultDto | AddressT) =>
        this.addressFinderService.isAddressFinderMetadata(address)
            ? address?.fullAddress
            : this.getDisplayAddress(address);
    public searchForTerm: (query: string) => void;
    public error: string;
    public loading$: Observable<boolean>;
    private onChange: Function;
    private onTouched: Function;
    private readonly addressFinderId = `nc-address-finder-${++addressFinderCount}`;

    @HostBinding('id')
    public get componentId(): string {
        return this.id || this.addressFinderId;
    }

    constructor(
        private addressFinderService: AddressFinderService,
        private changeDetectorRef: ChangeDetectorRef,
        private addressService: AddressService,
        private errorHandlerService: ErrorHandlerService,
    ) {}

    public ngOnInit(): void {
        this.setUpAddressFinderAutocomplete();
    }

    public writeValue(address?: AddressT): void {
        this.value = address;
        this.addressSelect.emit(address);
    }

    public registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    public onBlur(): void {
        this.onTouched?.();
    }

    public onSelectAddress(selectedAutocompleteResult: AddressFinderAutocompleteResultDto): void {
        this.isLoadingMetadata = true;
        this.error = undefined;
        this.changeDetectorRef.detectChanges();

        this.addressFinderService
            .fetchAddress(selectedAutocompleteResult.id)
            .pipe(
                finalize(() => {
                    this.isLoadingMetadata = false;
                    this.changeDetectorRef.detectChanges();
                }),
            )
            .subscribe(
                address => {
                    this.writeValue(address);
                    this.onChange?.(this.value);
                },
                (error: HttpErrorResponse) => {
                    this.errorHandlerService.sendError(
                        `Error looking up address: ${selectedAutocompleteResult.fullAddress}, Error: ${JSON.stringify(
                            error,
                        )}`,
                    );
                    this.error = 'We\'re having some trouble finding details for that address. Please try again later.';
                },
            );
    }

    private setUpAddressFinderAutocomplete(): void {
        const errorTap = (error: unknown) => {
            this.errorHandlerService.sendError(`Error querying autocomplete. Error: ${error}`);
        };
        const { search, results$, loading$ } = this.addressFinderService.autocompleteFactory(errorTap);

        this.filteredAutocompleteResults$ = results$;
        this.searchForTerm = (query: string) => {
            if (!query) {
                // Input has been cleared
                this.writeValue(undefined);
                this.onChange?.(undefined);
            }

            search(query);
        };
        this.loading$ = loading$;
    }

    private getDisplayAddress(address?: AddressT): string {
        if (!address) return;

        const shortFormatted = this.addressService.getShortFormattedAddress(address);
        const { majorMunicipality, postalArea, governingDistrict } = address;

        return `${shortFormatted}, ${majorMunicipality?.toUpperCase()} ${governingDistrict} ${postalArea}`;
    }
}
