import { Component, Input } from '@angular/core';

import { EnvironmentService } from '../../../modules/core/services/environment.service';
import { ThemeName, ThemeService } from '../../../modules/core/services/theme.service';

@Component({
    selector: 'up-isolated-container',
    templateUrl: './isolated-container.component.html',
    styleUrls: ['./isolated-container.component.scss'],
})
export class IsolatedContainerComponent {
    @Input() public showLogo: boolean;
    public themeName: ThemeName;
    public orgName: string;

    constructor(private themeService: ThemeService, private environmentService: EnvironmentService) {
        this.orgName = this.environmentService.config.organisation.displayName;
        this.themeName = this.themeService.themeName;
    }
}
