import { Injectable } from '@angular/core';

import { AddressT } from '../models/address.model';

@Injectable()
export class AddressService {
    public equal(address1, address2) {
        return (
            address1.unitNumber === address2.unitNumber &&
            address1.streetNumber === address2.streetNumber &&
            address1.streetName === address2.streetName &&
            address1.suburb === address2.suburb &&
            address1.postcode === address2.postcode &&
            address1.state === address2.state
        );
    }

    // Note: avoid using this if possible. We should always try to rely on the API for formatted addresses.
    public getShortFormattedAddress(address?: AddressT): string {
        if (!address) return;

        const { unitNumber, streetNumber, streetName } = address;

        return `${unitNumber ? `${unitNumber}/` : ''}${streetNumber} ${streetName}`;
    }

    // Note: avoid using this if possible. We should always try to rely on the API for formatted addresses.
    public getFormattedAddress(address?: AddressT): string {
        if (!address) return;

        const formattedGreaterArea = `${address.majorMunicipality} ${address.postalArea}`;

        return `${this.getShortFormattedAddress(address)}, ${formattedGreaterArea}`;
    }

    public createSuburbIdFromAddress(address: AddressT): string {
        const { postalArea, majorMunicipality } = address;

        if (!postalArea || !majorMunicipality) return;

        return `${postalArea}_${majorMunicipality.replace(/\s+/g, '').toLowerCase()}`;
    }
}
