import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'button[nc-icon-button], a[nc-icon-button]',
    templateUrl: 'icon-button.component.html',
    styleUrls: ['icon-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
    @Input() public size: 'small' | 'medium' = 'medium';

    @HostBinding('class.is-size-small')
    private get isSizeSmall(): boolean {
        return this.size === 'small';
    }

    @HostBinding('class.is-size-medium')
    private get isSizeMedium(): boolean {
        return this.size === 'medium';
    }
}
