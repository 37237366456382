import { Injectable, Injector } from '@angular/core';
import { Observable, combineLatest, of, isObservable } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import { UserService } from '../../../../common/services/user.service';
import { FeatureService } from '../../../core/services/feature.service';
import { Permission } from '../../models/permission.model';

@Injectable()
export class PermissionsService {
    constructor(private userService: UserService, private featureService: FeatureService, private injector: Injector) {}

    // Todo: consider allowing a list of permissions to be passed here
    public has(permission: Permission): Observable<boolean> {
        return combineLatest([
            this.userService.currentUserRoles$,
            this.featureService.featureConfiguration$,
            of(this.injector),
        ]).pipe(
            switchMap(([currentUserRoles, featureFlags, injector]) => {
                const isPermitted = permission({ roles: currentUserRoles, featureFlags, injector });

                return isObservable(isPermitted) ? isPermitted : of(!!isPermitted);
            }),
            distinctUntilChanged(),
        );
    }
}
