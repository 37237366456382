import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UpsideCommonModule } from '../../common/common';
import { SharedModule } from '../../common/shared.module';
import { UpsideSocialLinksModule } from '../../modules/upside-social-links/upside-social-links.module';

import { FooterCopyrightComponent } from './footer-copyright/footer-copyright.component';
import { FooterMegaComponent } from './footer-mega/footer-mega.component';
import { FooterMinimalComponent } from './footer-minimal/footer-minimal.component';

@NgModule({
    imports: [CommonModule, UpsideCommonModule, SharedModule, UpsideSocialLinksModule],
    declarations: [FooterMegaComponent, FooterCopyrightComponent, FooterMinimalComponent],
    exports: [FooterMegaComponent, FooterCopyrightComponent, FooterMinimalComponent],
})
export class FooterModule {}
