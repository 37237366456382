<div
    [ngClass]="{
        wrapper: true,
        'is-size-small': size === 'small',
        'is-focused': isFocused,
        'is-disabled': isDisabled,
        'is-error': error,
        'is-success': success,
        'is-full-width': fullWidth,
        'has-value': !!element.value
    }"
>
    <label class="form-label u-text-body--rg" [class.u-hide-visually]="size === 'small' && !!element.value" [for]="uid">
        {{ label }}
    </label>
    <div class="select-wrapper">
        <div class="input-focus-glow"></div>
        <select
            #element
            [value]="value"
            [id]="uid"
            [autofocus]="autofocus"
            [multiple]="multiple"
            [attr.name]="name"
            [disabled]="isDisabled"
            (focus)="onInputFocus()"
            (input)="onInput($event)"
            (blur)="onInputBlur()"
            class="select-box"
        >
            <option value="" disabled hidden></option>
            <option *ngFor="let option of options" [value]="option.value"> {{ option.content }} </option>
        </select>
        <div class="slide-icon"> <up-icon [name]="'chevronDownBold'" [color]="'currentColor'"></up-icon> </div>
    </div>
</div>
