import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ExperimentsResource } from '../../modules/core/resources/experiments.resource';
import { experimentsApi } from '../models/experiments-api.model';
import { experiments as experimentsModel, experiments } from '../models/experiments.model';

import { ServerExperimentsServiceDIToken } from './server-experiments.service';

@Injectable()
export class ExperimentService {
    public experimentId: string;
    public experiments: experiments.Experiment[] = [];

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private injector: Injector,
        private experimentResource: ExperimentsResource,
    ) {}

    public getVariation(experimentId: string): number {
        if (!isPlatformBrowser(this.platformId)) {
            const serverExperimentsService = this.injector.get(ServerExperimentsServiceDIToken);
            const experimentsFromServer = serverExperimentsService.getExperiments();
            return experimentsFromServer[experimentId] || 0;
        }
        const foundExperiment = this.findExperiment(this.experiments, experimentId);
        return (foundExperiment && foundExperiment.var) || 0;
    }

    public getVariationFromUserToken(userToken: string, experimentId: string): Observable<number> {
        return this.experimentResource.userParticipated(userToken).pipe(
            map(response => {
                const participatedExperiment = response
                    .reduce<experimentsModel.Participation[]>(
                        (acc, curr) => [...acc, ...(curr.participation || [])],
                        [],
                    )
                    .find(par => par.name === experimentId);
                return participatedExperiment ? participatedExperiment.variation : 0;
            }),
            catchError(() => of(0)),
        );
    }

    // 50/50 split using UUID
    public getVariationFromUUID(uuid: string): boolean {
        // Use first 3 characters of UUID, convert to Int from Hex and modulo 2 to determine split
        return !!(parseInt(uuid.substr(0, 3), 16) % 2);
    }

    public fetchExperiments(): Observable<experimentsApi.experiments.get.Response> {
        return this.experimentResource.getExperiments().pipe(
            tap(response => {
                this.experimentId = response.experimentId;
                this.experiments = response.experiments;
            }),
        );
    }

    private findExperiment(experiments: experiments.Experiment[] = [], experimentId: string): experiments.Experiment {
        return experiments.find(experiment => experiment.id === experimentId);
    }
}
