import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';

@Component({
    selector: 'nc-check-in-confirmed',
    templateUrl: 'check-in-confirmed.component.html',
    styleUrls: ['check-in-confirmed.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckInConfirmedComponent implements OnInit {
    public name: string;
    public phoneNumber: string;
    public email: string;
    public brochureToken: string;

    constructor(private uiRouterGlobals: UIRouterGlobals) {}

    public ngOnInit(): void {
        this.name = this.uiRouterGlobals.params['name'];
        this.phoneNumber = this.uiRouterGlobals.params['phoneNumber'];
        this.email = this.uiRouterGlobals.params['email'];
        this.brochureToken = this.uiRouterGlobals.params['brochureToken'];
    }
}
