import { Component, Input } from '@angular/core';

type DisplayDensity = 'spacious' | 'compact' | 'extra-compact';

export interface VendorReview {
    title: string;
    text: string;
    reviewer?: string;
    salePrice?: number;
    savings?: number;
}

@Component({
    selector: 'up-vendor-review',
    templateUrl: 'vendor-review.component.html',
    styleUrls: ['vendor-review.component.scss'],
})
export class VendorReviewComponent {
    @Input() public review: VendorReview;
    @Input() public displayDensity: DisplayDensity = 'spacious';
    public readonly noReviewKey = 'no_review';
}
