<nc-check-in-card>
    <ng-container slot="header">
        <div class="u-text-heading--lg">
            {{ name }}
            <nc-icon class="u-text-color--positive" [name]="'checkCircleFill'" [color]="'currentColor'"></nc-icon>
        </div>
        <div class="u-text-body--sm">
            <div>
                <nc-icon class="u-margin-right--2xs" [size]="'x-small'" [name]="'phoneFill'"></nc-icon>
                {{ phoneNumber }}
            </div>
            <div class="email">
                <nc-icon class="u-margin-right--2xs" [size]="'x-small'" [name]="'envelopeFill'"></nc-icon>
                {{ email }}
            </div>
        </div>
    </ng-container>
    <ng-container slot="body">
        <p> Thank you for checking in {{ name }}, please show an agent onsite this confirmation message. </p>
        <p>
            <a nc-link [uiSref]="'brochure.view'" [uiParams]="{ token: brochureToken }"
                >View property mobile brochure</a
            >
        </p>
    </ng-container>
</nc-check-in-card>
