import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TransitionOptions } from '@uirouter/core';

/**
 * `preserveWhitespaces: false` prevents angular form wrapping host element around the template with line breaks causing
 * a space to appear when it's placed next to an inline element.
 *
 * Example:
 * preserveWhitespaces: true
 *
 * <up-link>
 *     Hello World
 * </up-link>.
 *
 * Hello World .
 *
 * ---------------------------
 *
 * preserveWhitespaces: false
 *
 * <up-link>Hello World</up-link>.
 *
 * Hello World.
 **/

/**
 * @Deprecated - Use <button> or <a> with the [up-link] directive instead
 * */
@Component({
    selector: 'up-link',
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.scss'],
    preserveWhitespaces: false,
})
export class LinkComponent {
    @Input() public upSref: string;
    @Input() public href: string;
    @Input() public upSrefParams: object;
    @Input() public upSrefOptions: TransitionOptions;
    @Input() public target: string;
    @Output() public onClick: EventEmitter<void> = new EventEmitter<void>();

    public click(): void {
        this.onClick.emit();
    }
}
