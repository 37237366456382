import { PropertyRatesPaymentFrequencyDto } from '../../dto/property/property-rates-payment-frequency.dto';

export namespace PropertyRatePaymentFrequency {
    export enum Enum {
        Monthly = 'propertyRatePaymentFrequency.monthly',
        Quarterly = 'propertyRatePaymentFrequency.quarterly',
        Yearly = 'propertyRatePaymentFrequency.yearly',
    }

    export function toDto(model: PropertyRatePaymentFrequency.Enum): PropertyRatesPaymentFrequencyDto {
        switch (model) {
            case PropertyRatePaymentFrequency.Enum.Monthly:
                return 'MONTHLY';
            case PropertyRatePaymentFrequency.Enum.Quarterly:
                return 'QUARTERLY';
            case PropertyRatePaymentFrequency.Enum.Yearly:
                return 'YEARLY';
        }
    }

    export function fromDto(dto: PropertyRatesPaymentFrequencyDto): PropertyRatePaymentFrequency.Enum {
        switch (dto) {
            case 'MONTHLY':
                return PropertyRatePaymentFrequency.Enum.Monthly;
            case 'QUARTERLY':
                return PropertyRatePaymentFrequency.Enum.Quarterly;
            case 'YEARLY':
                return PropertyRatePaymentFrequency.Enum.Yearly;
        }
    }
}
