import { Component, HostBinding, Input, OnInit } from '@angular/core';

type Gutters = 'none' | 'x-small' | 'small' | 'medium';
type Align = 'center' | 'end' | 'space-around' | 'space-between';
type VerticalAlign = 'center' | 'start' | 'end';

// This is using globally imported grid.scss, use styleUrls once all instances of grid classes has been replaced
// with component
@Component({
    selector: 'up-grid',
    templateUrl: './grid.component.html',
})
export class GridComponent implements OnInit {
    @Input() public align?: Align;
    @Input() public gutters: Gutters = 'medium';
    @Input() public verticalAlign?: VerticalAlign;
    @Input() public wrap = true;

    @HostBinding('class.u-grid') public baseClass = true;
    @HostBinding('class.u-grid--align-center') public alignCenterClass: boolean;
    @HostBinding('class.u-grid--align-end') public alignEndClass: boolean;
    @HostBinding('class.u-grid--align-space-around') public alignSpaceAroundClass: boolean;
    @HostBinding('class.u-grid--align-space-between') public alignSpaceBetweenClass: boolean;
    @HostBinding('class.u-grid--gutters-md') public guttersMediumClass: boolean;
    @HostBinding('class.u-grid--gutters-sm') public guttersSmallClass: boolean;
    @HostBinding('class.u-grid--gutters-lg') public guttersLargeClass: boolean;
    @HostBinding('class.u-grid--gutters-xs') public guttersXSmallClass: boolean;
    @HostBinding('class.u-grid--vertical-align-center') public verticalAlignCenterClass: boolean;
    @HostBinding('class.u-grid--vertical-align-end') public verticalAlignEndClass: boolean;
    @HostBinding('class.u-grid--vertical-align-start') public verticalAlignStartClass: boolean;
    @HostBinding('class.u-grid--wrap') public wrapClass: boolean;

    public ngOnInit() {
        this.alignCenterClass = this.align === 'center';
        this.alignEndClass = this.align === 'end';
        this.alignSpaceAroundClass = this.align === 'space-around';
        this.alignSpaceBetweenClass = this.align === 'space-between';
        this.guttersMediumClass = this.gutters === 'medium';
        this.guttersSmallClass = this.gutters === 'small';
        this.guttersXSmallClass = this.gutters === 'x-small';
        this.verticalAlignCenterClass = this.verticalAlign === 'center';
        this.verticalAlignEndClass = this.verticalAlign === 'end';
        this.verticalAlignStartClass = this.verticalAlign === 'start';
        this.wrapClass = this.wrap;
    }
}
