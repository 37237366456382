import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TranslocoModule } from '@ngneat/transloco';

import { SpinnerModule } from '../spinner/spinner.module';

import { MatLocalityAutocompleteComponent } from './components/mat-locality-autocomplete/mat-locality-autocomplete.component';

@NgModule({
    imports: [MatInputModule, ReactiveFormsModule, MatAutocompleteModule, CommonModule, SpinnerModule, TranslocoModule],
    declarations: [MatLocalityAutocompleteComponent],
    exports: [MatLocalityAutocompleteComponent],
})
export class LocalityModule {}
