import { ChangeDetectionStrategy, Component, Host, HostBinding } from '@angular/core';

import { ModalDialogV2Component } from '../modal-dialog-v2/modal-dialog-v2.component';

@Component({
    selector: 'nc-modal-header',
    templateUrl: 'modal-header.component.html',
    styleUrls: ['modal-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalHeaderComponent {
    @HostBinding('class.is-modal-size-x-small')
    public get isModalSizeXSmall(): boolean {
        return this.modalDialogV2Component.size === 'x-small';
    }

    @HostBinding('class.is-modal-size-small')
    public get isModalSizeSmall(): boolean {
        return this.modalDialogV2Component.size === 'small';
    }

    @HostBinding('class.is-modal-size-medium')
    public get isModalSizeMedium(): boolean {
        return this.modalDialogV2Component.size === 'medium';
    }

    @HostBinding('class.is-modal-size-large')
    public get isModalSizeLarge(): boolean {
        return this.modalDialogV2Component.size === 'large';
    }

    constructor(@Host() public readonly modalDialogV2Component: ModalDialogV2Component) {}
}
