import { user } from '../../models/user.model';

export function hasMatchingRoles(userRoles: user.Role[], targetRoles: user.Role[], matchAll?: boolean): boolean {
    const isTargetRole = role => targetRoles.includes(role);

    return matchAll ? userRoles.every(isTargetRole) : userRoles.some(isTargetRole);
}

export function getPrimaryRole(userRoles: user.Role[]): user.Role | undefined {
    if (userRoles.includes(user.Role.Admin)) return user.Role.Admin;
    if (userRoles.includes(user.Role.OrgAdmin)) return user.Role.OrgAdmin;
    if (userRoles.includes(user.Role.ConciergeAdmin)) return user.Role.ConciergeAdmin;
    if (userRoles.includes(user.Role.Concierge)) return user.Role.Concierge;
    if (userRoles.includes(user.Role.SalesAdmin)) return user.Role.SalesAdmin;
    if (userRoles.includes(user.Role.Staff)) return user.Role.Staff;
    if (userRoles.includes(user.Role.AgencyAdmin)) return user.Role.AgencyAdmin;
    if (userRoles.includes(user.Role.SalesManager)) return user.Role.SalesManager;
    if (userRoles.includes(user.Role.SalesAssociate)) return user.Role.SalesAssociate;
    if (userRoles.includes(user.Role.Agent)) return user.Role.Agent;
    if (userRoles.includes(user.Role.Vendor)) return user.Role.Vendor;
    if (userRoles.includes(user.Role.Buyer)) return user.Role.Buyer;

    return undefined;
}

export function hasSomeInternalRole(roles?: user.Role[]): boolean {
    const internalRoles = [
        user.Role.Admin,
        user.Role.OrgAdmin,
        user.Role.ConciergeAdmin,
        user.Role.Concierge,
        user.Role.SalesAdmin,
        user.Role.Staff,
        user.Role.AgencyAdmin,
        user.Role.SalesManager,
        user.Role.SalesAssociate,
        user.Role.Agent,
    ];

    return hasMatchingRoles(roles || [], internalRoles);
}
