<div *ngIf="(hideGetStartedCta$ | async) !== true" [@fadeInOut]>
    <div *ngIf="overrideCta$ | async as overrideCta; else defaultCta">
        <ng-container *ngIf="overrideCta.uiSref; else hasClick">
            <button up-button [uiSref]="overrideCta.uiSref" [dataEvent]="overrideCta.dataEvent" [style]="'primary'">
                {{ overrideCta.label }}
            </button>
        </ng-container>
        <ng-template #hasClick>
            <button up-button (click)="overrideCta.onClick()" [dataEvent]="overrideCta.dataEvent" [style]="'primary'">
                {{ overrideCta.label }}
            </button>
        </ng-template>
    </div>
    <ng-template #defaultCta>
        <button
            *upFeature="'upsideMarketingPagesEnabled'"
            up-button
            [uiSref]="'conversion.get-started'"
            [dataEvent]="'navGetStarted'"
            [style]="'primary'"
        >
            Get Started
        </button>
    </ng-template>
</div>
