export namespace phase {
    export enum ProspectReason {
        NotReadyForAppraisal = 'NotReadyForAppraisal',
        ListedWithAnotherAgent = 'ListedWithAnotherAgent',
        ChangeOfCircumstances = 'ChangeOfCircumstances',
        Other = 'Other',
    }

    export enum InvalidReasons {
        Spam = 'Spam',
        Buyer = 'Buyer',
        Tenant = 'Tenant',
        UnContactable = 'UnContactable',
        OnlineReport = 'OnlineReport',
        CommercialProperty = 'CommercialProperty',
        Valuation = 'Valuation',
        DoNotContact = 'DoNotContact',
        ListedWithAnotherAgent = 'ListedWithAnotherAgent',
        Other = 'Other',
    }

    export enum LostAndSoldReasons {
        Agent = 'Agent',
        Price = 'Price',
        Fees = 'Fees',
        Credibility = 'Credibility',
        LocalPerformance = 'LocalPerformance',
        Other = 'Other',
    }

    export enum NurtureReasons {
        NoAppraisal = 'Vendor doesn\'t want an appraisal',
        NotSelling = 'Vendor isn\'t selling',
    }

    export enum Phases {
        Appraisal = 'Appraisal',
        Pipeline = 'Pipeline',
        Nurture = 'Nurture',
        Invalid = 'Invalid',
        LostAndSold = 'LostAndSold',
        Campaign = 'Campaign',
        CampaignPrep = 'CampaignPrep',
        Withdrawn = 'Withdrawn',
        Prospect = 'Prospect',
        Settlement = 'Settlement',
        AgentLead = 'AgentLead',
        Complete = 'Complete',
        Research = 'Research',
    }

    export enum ToolsPhases {
        Appraisal = 'Appraisal',
        Campaign = 'Campaign',
        CampaignPrep = 'CampaignPrep',
    }

    export enum BaseFilterPhases {
        Appraisal = 'Appraisal',
        Pipeline = 'Pipeline',
        Prospect = 'Prospect',
        AgentLead = 'AgentLead',
        Nurture = 'Nurture',
        Invalid = 'Invalid',
        LostAndSold = 'LostAndSold',
        Active = 'Active',
        CampaignPrep = 'CampaignPrep',
        Campaign = 'Campaign',
        Settlement = 'Settlement',
        Complete = 'Complete',
        Research = 'Research',
        Withdrawn = 'Withdrawn',
    }

    export enum WithdrawnReasons {
        Service = 'UnhappyWithService',
        Financial = 'Financial',
        Personal = 'PersonalMatter',
        Other = 'Other',
    }

    export enum EditablePhases {
        Appraisal = 'Appraisal',
        Pipeline = 'Pipeline',
        Nurture = 'Nurture',
        Invalid = 'Invalid',
        LostAndSold = 'LostAndSolid',
        Campaign = 'Campaign',
        CampaignPrep = 'CampaignPrep',
        Prospect = 'Prospect',
        AgentLead = 'AgentLead',
        Research = 'Research',
    }
}
