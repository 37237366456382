import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiSuccessResponse } from '../../../common/models/common.model';
import { EmptyDto } from '../../../common/models/dto/response/empty.dto';
import { propertyApi as cleanedPropertyApi } from '../../../common/models/property-api.model';

const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

const headers = new HttpHeaders();
headers.set('Content-Type', 'application/json');
headers.set('Access-Control-Allow-Origin', '*');
const PROPERTY_REPORT_BASE_URL = '/api/v2/property-report';
const PROPERTY_BASE_URL = '/api/v2/property';

@Injectable()
export class PropertyReportResource {
    private readonly basePropertyReportUrl = '/api/v2/nct-property-report';

    constructor(private http: HttpClient) {}

    public manualCallWithPropertyAnswerToken(answerToken: string): Observable<ApiSuccessResponse> {
        let params = new HttpParams();
        params = params.append('token', answerToken);

        return this.http.post<ApiSuccessResponse>(
            `${PROPERTY_REPORT_BASE_URL}/questionnaire/manual-call/`,
            {},
            { ...HEADERS_CONFIG, params },
        );
    }

    public requestReportForProperty(propertyId: string) {
        return this.http.post<ApiSuccessResponse>(
            `/api/v2/property/${propertyId}/request-report/`,
            undefined,
            HEADERS_CONFIG,
        );
    }

    //nct prefixes are temporary and to allow for migration
    public getPrivatePropertyReport(propertyId: string) {
        return this.http.get<cleanedPropertyApi.NctReport.get.Response>(
            `${PROPERTY_BASE_URL}/${propertyId}/nct-report`,
            HEADERS_CONFIG,
        );
    }

    public getPublicPropertyReport(propertyToken: string) {
        return this.http.get<cleanedPropertyApi.NctReport.get.Response>(
            `${this.basePropertyReportUrl}/${propertyToken}`,
            { ...HEADERS_CONFIG },
        );
    }

    public triggerConfirmEmailByPropertyToken(token: string): Observable<EmptyDto> {
        return this.http.post<EmptyDto>(`${this.basePropertyReportUrl}/${token}/confirm-email`, undefined);
    }
}
