import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MediaObjectComponent } from './components/media-object/media-object.component';

@NgModule({
    imports: [CommonModule],
    exports: [MediaObjectComponent],
    declarations: [MediaObjectComponent],
})
export class MediaObjectModule {}
