import { AddressT } from './address.model';
import { Property, VendorInfoT } from './property.model';
import { InternalUser } from './user.model';

export namespace task {
    export type Status = 'Open' | 'Closed' | 'Canceled';

    export enum Group {
        System = 'System',
        Support = 'Support',
        SalesManager = 'SalesManager',
        Vendor = 'Vendor',
        Buyer = 'Buyer',
        Agent = 'Agent',
        /** @deprecated **/
        Finance = 'Finance',
        /** @deprecated **/
        Stylist = 'Stylist',
        /** @deprecated **/
        Photographer = 'Photographer',
        /** @deprecated **/
        Auctioneer = 'Auctioneer',
        Copywriter = 'Copywriter',
    }

    interface TaskBase {
        created: string;
        due: string;
        group: Group;
        name: string;
        state: Property.States;
    }

    export interface Task {
        country?: string;
        address: AddressT;
        assignedTo: InternalUser;
        completed: string;
        completedAgo?: string;
        created: string;
        createdAgo?: string;
        due: string;
        group: Group;
        id: string;
        name: string;
        opened: string;
        property: Property;
        state: Property.States;
        status: Status;
        updated: string;
        vendor: InternalUser;
    }

    export interface TaskDecorated extends TaskBase {
        property: Property;
        vendor: VendorInfoT;
    }

    export interface TaskMinimal extends TaskBase {
        property: string; // property id
    }

    export enum PhaseBlockingTasks {
        OrganiseAppraisal = 'sell-agent-organise-appraisal-visit',
        AssignAgent = 'assign-agent',
        ReviewAppraisal = 'review-appraisal-request',
    }
}
