import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { user } from '../../common/models/user.model';
import { AppState } from '../apps-state.model';

import { getNotificationPreferencesForUser, updateNotificationPreferencesForUser, userActions } from './user.actions';
import { notificationsPreferencesState, notificationsPreference } from './user.selector';

@Injectable()
export class UserFacade {
    public notificationsPreferencesState$ = this.store.select(notificationsPreferencesState);
    public notificationsPreferences$ = this.store.select(notificationsPreference);

    constructor(private store: Store<AppState>) {}

    public resetNotificationPreferencesState(): void {
        this.store.dispatch(new userActions.ResetNotificationsPreferencesState());
    }

    public getNotificationsPreferences(): void {
        this.store.dispatch(new userActions.GetNotificationsPreferences());
    }

    public getNotificationsPreferencesWithToken(token: string): void {
        this.store.dispatch(new userActions.GetNotificationsPreferencesWithToken(token));
    }

    public getNotificationPreferencesForUser(userId: string): void {
        this.store.dispatch(getNotificationPreferencesForUser({ userId }));
    }

    public updateNotificationsPreferences(preferences: user.Notifications): void {
        this.store.dispatch(new userActions.UpdateNotificationsPreferences(preferences));
    }

    public updateNotificationsPreferencesWithToken(token: string, preferences: user.Notifications): void {
        this.store.dispatch(new userActions.UpdateNotificationsPreferencesWithToken({ token, preferences }));
    }

    public updateNotificationPreferencesForUser(userId: string, preferences: user.Notifications): void {
        this.store.dispatch(updateNotificationPreferencesForUser({ userId, preferences }));
    }

    public notificationsSubscribe(preferences: user.Notifications): void {
        this.store.dispatch(new userActions.NotificationsSubscribe(preferences));
    }

    public notificationsUnsubscribe(): void {
        this.store.dispatch(new userActions.NotificationsUnsubscribe());
    }

    public notificationsUnSubscribeWithToken(token): void {
        this.store.dispatch(new userActions.NotificationsUnsubscribeWithToken(token));
    }
}
