import { Directive, ElementRef, HostBinding, HostListener, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import throttle from 'lodash-es/throttle';
import { BehaviorSubject } from 'rxjs';

import { MenuComponent } from '../../components/menu/menu.component';
import { MenuRef } from '../../menu-ref';
import { CommonMenuOptions } from '../../models/menu-options.model';
import { MenuService } from '../../services/menu/menu.service';

@UntilDestroy()
@Directive({
    selector: '[ncMenuTriggerFor]',
    exportAs: 'ncMenuTriggerFor',
})
export class MenuTriggerDirective {
    @Input() public menuConfig: Omit<CommonMenuOptions, 'position'> = {
        align: 'start',
        offsetY: 0,
        backdropVisible: false,
    };
    @Input('ncMenuTriggerFor') public menu: TemplateRef<any> | MenuComponent;
    @HostBinding('attr.aria-haspopup') public ariaHaspopup = true;
    public readonly menuIsOpen$ = new BehaviorSubject(false);
    private menuRef: MenuRef;
    private readonly throttledOnWindowResize: Function;

    constructor(
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly menuService: MenuService,
    ) {
        this.throttledOnWindowResize = throttle(() => this.hideMenuIfTriggerHidden(), 200);
    }

    @HostListener('window:resize')
    public onWindowResize(): void {
        this.throttledOnWindowResize();
    }

    @HostListener('click')
    public onClick(): void {
        if (!this.menuRef) {
            this.openMenu();
        } else {
            this.closeMenu();
        }
    }

    public openMenu(): void {
        if (this.menuRef) return;

        this.menuIsOpen$.next(true);

        if (this.menu instanceof MenuComponent) {
            this.menuRef = this.menuService.open({
                instance: this.menu,
                position: this.elementRef,
                ...this.menuConfig,
            });
        } else {
            this.menuRef = this.menuService.open({
                template: this.menu,
                viewContainerRef: this.viewContainerRef,
                position: this.elementRef,
                ...this.menuConfig,
            });
        }

        this.menuRef.afterClosed$.pipe(untilDestroyed(this)).subscribe(() => {
            this.menuRef = undefined;
            this.menuIsOpen$.next(false);
        });
    }

    public closeMenu(): void {
        this.menuRef?.close();
    }

    private hideMenuIfTriggerHidden(): void {
        if (this.elementRef.nativeElement.offsetParent) return;

        this.closeMenu();
    }
}
