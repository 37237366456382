import { NgModule } from '@angular/core';

import { GeoLocationToLatLngPipe } from './pipes/geo-location-to-lat-lng/geo-location-to-lat-lng.pipe';

@NgModule({
    exports: [GeoLocationToLatLngPipe],
    declarations: [GeoLocationToLatLngPipe],
    providers: [GeoLocationToLatLngPipe],
})
export class LocationModule {}
