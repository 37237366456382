<div class="wrapper">
    <div class="content">
        <ng-content></ng-content>
    </div>
    <div class="indicator">
        <nc-icon
            class="arrow"
            [name]="'chevronRightBold'"
            [size]="appearance === 'minimal' ? 'small' : 'regular'"
            [color]="'currentColor'"
        >
        </nc-icon>
    </div>
</div>
