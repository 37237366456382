import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { every, some } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../common/models/environment.model';
import { UserService } from '../../../common/services/user.service';
import { replay } from '../../../operators/replay/replay.operator';

import { EnvironmentService } from './environment.service';
import { FirebaseService } from './firebase.service';

type BooleanPropertyKeys<T> = {
    [P in keyof T]: boolean extends T[P] ? P : never;
}[keyof T];

export type FeatureKeys = BooleanPropertyKeys<environment.FeatureConfiguration>;
export type FeatureListOperator = 'and' | 'or';

@Injectable()
export class FeatureService {
    public homeRouteName: string | undefined;
    public readonly featureConfiguration$: Observable<environment.FeatureConfigurationV2>;

    constructor(
        private environmentService: EnvironmentService,
        private stateService: StateService,
        private userService: UserService,
        private firebaseService: FirebaseService,
    ) {
        this.setHomeRouteName();

        this.featureConfiguration$ = combineLatest([
            this.environmentService.config$,
            this.userService.userAuthDetailsUpdated$,
            this.firebaseService.remoteConfigValues$,
        ]).pipe(
            map(([config, user, firebaseRemoteConfigValues]) => ({
                ...config.organisation.featureConfiguration,
                ...firebaseRemoteConfigValues,
                canSendSms: !!user.canSeeSmsFeature,
                buyerModuleEnabled: !!user.buyerModuleEnabled,
                isBusinessSmsFeatureEnabled: !!user.businessSmsEnabled,
                isActivePipeBulkEmailEnabled: !!user.activePipeBulkEmailEnabled,
                isOpenBuyerDatabaseEnabled: !!user.openBuyerDatabaseEnabled,
            })),
            replay({ resetOnRefCountZero: false }),
        );
    }

    public get urlConfiguration(): environment.UrlConfiguration {
        return this.environmentService.config.organisation.urlConfiguration;
    }

    public get featureConfiguration(): environment.FeatureConfiguration {
        return this.environmentService.config.organisation.featureConfiguration;
    }

    /** @Deprecated - use has() instead **/
    public hasFeature(key: FeatureKeys | FeatureKeys[], operator?: FeatureListOperator): boolean {
        if (Array.isArray(key)) {
            if (operator === 'or' || operator === undefined) {
                return some(key, k => this.featureConfiguration[k]);
            } else {
                return every(key, k => this.featureConfiguration[k]);
            }
        } else {
            return this.featureConfiguration[key];
        }
    }

    // This is a rather naive and "best effort" implementation of finding the route name from a url, it's not always
    // possible especially with lazy loaded routes and uses the internal property $$state, but it is recommended by
    // the maintainer, though we should probably taking that with a grain of salt:
    // https://github.com/angular-ui/ui-router/issues/3174#issuecomment-267193443
    private setHomeRouteName(): void {
        const homeUrl = this.urlConfiguration.homePage;
        if (!homeUrl) return;

        const host = this.environmentService.config.organisation.host;
        const homeUrlWithoutHost = homeUrl.split(host)[1];

        const matchingRoute = this.stateService
            .get()
            // Not all states have url so we filter it out
            .filter(s => !!s.$$state().url)
            .find(s => s.$$state().url.exec(homeUrlWithoutHost));
        this.homeRouteName = matchingRoute?.name;
    }
}
