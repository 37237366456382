import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';

import { IconSpriteService } from './services/icon-sprite.service';

export type Size = '2x-small' | 'x-small' | 'small' | 'regular' | 'large' | 'x-large';
type Color = 'light' | 'negative' | 'positive' | 'inverse' | 'accent' | 'current-color' | 'currentColor' | string;

export type IconName =
    | 'REA'
    | 'REAFill'
    | 'aboveGroundPool'
    | 'airCondition'
    | 'airConditionBounceArrow'
    | 'airConditionSplitArrow'
    | 'alarm'
    | 'alarmClock'
    | 'android'
    | 'apple'
    | 'area'
    | 'arrowBoldUpRight'
    | 'arrowClockwise'
    | 'arrowClockwiseCircleFill'
    | 'arrowDown'
    | 'arrowDownBoldSmall'
    | 'arrowDownCircleFill'
    | 'arrowDownStackedCards'
    | 'arrowLeft'
    | 'arrowLeftBoldSmall'
    | 'arrowLeftHalfRectangle'
    | 'arrowLeftCircleFill'
    | 'arrowRight'
    | 'arrowRightBold'
    | 'arrowRightBoldSmall'
    | 'arrowRightHalfRectangle'
    | 'arrowRightCircleFill'
    | 'arrowUp'
    | 'arrowUpBold'
    | 'arrowUpBoldSmall'
    | 'arrowUpCloud'
    | 'arrowUpTrayFill'
    | 'arrowUturnLeft'
    | 'arrowshapeDownBase'
    | 'arrowshapeDownFill'
    | 'arrowshapeDownBaseFill'
    | 'arrowshapeUpBaseFill'
    | 'arrowshapeUpRight'
    | 'arrowshapeUpRightFill'
    | 'arrowshapeUpRightSlashFill'
    | 'arrowUpCircleFill'
    | 'balcony'
    | 'bankNote'
    | 'barGraph'
    | 'barGraphFill'
    | 'barbecue'
    | 'bath'
    | 'bed'
    | 'bell'
    | 'bellCircleFill'
    | 'bellClockwiseArrowFill'
    | 'bellCrossFill'
    | 'bellFill'
    | 'bellPlusFill'
    | 'bellRightArrowFill'
    | 'bellSnoozeFill'
    | 'boardFill'
    | 'boltFill'
    | 'bookFill'
    | 'bookmark'
    | 'books'
    | 'box'
    | 'boxClosedFill'
    | 'boxClosedUpArrowFill'
    | 'boxOpen'
    | 'boxText'
    | 'briefcase'
    | 'briefcaseFill'
    | 'building'
    | 'building2Fill'
    | 'calculatorFill'
    | 'calendar'
    | 'calendarCheck'
    | 'calendarFill'
    | 'calendarMagnifyingGlass'
    | 'calendarOneDay'
    | 'calendarPerson'
    | 'calendarPlusFill'
    | 'calendarSelected'
    | 'calendarSelectedCheck'
    | 'calendarSelectedFill'
    | 'camera'
    | 'cameraFill'
    | 'car'
    | 'carDoorBed'
    | 'cards2Fill'
    | 'carport'
    | 'cart'
    | 'ceilingReverseArrows'
    | 'check'
    | 'checkBubble'
    | 'checkCircle'
    | 'checkCircleFill'
    | 'chefHat'
    | 'chevronBoldLeftRight'
    | 'chevronDownBold'
    | 'chevronLeftBold'
    | 'chevronLeftLight'
    | 'chevronLightLeftRight'
    | 'chevronRightBold'
    | 'chevronRightLight'
    | 'chevronUpBold'
    | 'cigarette'
    | 'circle'
    | 'circleFill'
    | 'circleHalfShaded'
    | 'circleLoad'
    | 'circleSelected'
    | 'class'
    | 'clipboardCheck'
    | 'clipboardDollar'
    | 'clipboardHouse'
    | 'clipboardQuestionmark'
    | 'clock'
    | 'clockFill'
    | 'clockPlusFill'
    | 'closedDoorFill'
    | 'cloudFill'
    | 'coilHeatWaves'
    | 'coilReverseArrow'
    | 'coins'
    | 'coinsFill'
    | 'compass'
    | 'computer'
    | 'contactsFill'
    | 'creditCard'
    | 'creditCardFill'
    | 'crop'
    | 'crossBold'
    | 'crossCircleFill'
    | 'crossForwardFill'
    | 'crossThin'
    | 'crown'
    | 'cursorArrow'
    | 'cursorArrowClick'
    | 'dash3'
    | 'databaseFill'
    | 'desktopScreenDollar'
    | 'dices'
    | 'dishwasher'
    | 'document'
    | 'documentArrowRightFill'
    | 'documentCheck'
    | 'documentCheck2'
    | 'documentDollarEllipsisFill'
    | 'documentDollarFill'
    | 'documentDollarPlusFill'
    | 'documentEllipsisFill'
    | 'documentFill'
    | 'documentHouse'
    | 'documentHouseFill'
    | 'documentHouseMagnifyingGlassFill'
    | 'documentHousePlusFill'
    | 'documentHouseQuestionmarkFill'
    | 'documentHouseRightArrowFill'
    | 'documentMagnifyingGlass'
    | 'documentMultiple'
    | 'documentPlus'
    | 'documentPlusFill'
    | 'dollarBubble'
    | 'dollarBubbleEllipsisFill'
    | 'dollarBubbleFill'
    | 'dollarCircle'
    | 'dollarCircleFill'
    | 'dollarSign'
    | 'domain'
    | 'domainFill'
    | 'downArrowUpArrow'
    | 'drag'
    | 'ductedCooling'
    | 'ductedHeating'
    | 'dumbbell'
    | 'ellipsisBubble'
    | 'ellipsisBubbleFill'
    | 'envelope'
    | 'envelopeFill'
    | 'errorFill'
    | 'exclamationSignCircleFill'
    | 'eye'
    | 'eyeClose'
    | 'eyeFill'
    | 'facebook'
    | 'fan'
    | 'faucet'
    | 'fence'
    | 'filter'
    | 'fireplace'
    | 'flag'
    | 'flameFill'
    | 'floorboards'
    | 'floorplan'
    | 'folderFill'
    | 'funnelFill'
    | 'garage'
    | 'gasHeater'
    | 'gavel'
    | 'gavelFill'
    | 'gearshape'
    | 'gearshapeFill'
    | 'giftFill'
    | 'globeFill'
    | 'handCoinFill'
    | 'headphone'
    | 'heart'
    | 'heartFill'
    | 'heartbeat'
    | 'heater'
    | 'horizontalEllipsis'
    | 'house'
    | 'houseCross'
    | 'houseCrossFill'
    | 'houseDollar'
    | 'houseDollarFill'
    | 'houseDownArrow'
    | 'houseDownArrowFill'
    | 'houseEllipsisFill'
    | 'houseFill'
    | 'houseLeftArrowFill'
    | 'houseMagnifyingGlass'
    | 'houseMagnifyingGlassFill'
    | 'housePerson'
    | 'housePlus'
    | 'housePlusFill'
    | 'houseRightArrow'
    | 'houseRightArrowFill'
    | 'houseSparkleFill'
    | 'houseUpArrow'
    | 'houseUpArrowFill'
    | 'inGroundPool'
    | 'infoCircle'
    | 'infoCircleFill'
    | 'instagram'
    | 'intercom'
    | 'key'
    | 'keyFill'
    | 'keyboardFill'
    | 'keyholeFill'
    | 'leftArrowRightArrow'
    | 'lightBulb'
    | 'lightBulbFill'
    | 'lineGraph'
    | 'lineGraphFill'
    | 'lines'
    | 'linesDownArrow'
    | 'link'
    | 'linkCircleFill'
    | 'linkedin'
    | 'list'
    | 'listFill'
    | 'locationPin'
    | 'locationPinFill'
    | 'lock'
    | 'lockDoorFill'
    | 'lockFill'
    | 'magnifyingGlass'
    | 'magnifyingGlassBold'
    | 'magnifyingGlassCheck'
    | 'magnifyingGlassCircleFill'
    | 'magnifyingGlassFill'
    | 'magnifyingGlassPlus'
    | 'man'
    | 'map'
    | 'mapFill'
    | 'megaphoneFill'
    | 'menu'
    | 'menu2'
    | 'mic'
    | 'minus'
    | 'minusCircleFill'
    | 'mobile'
    | 'mobileFill'
    | 'moon'
    | 'moonFill'
    | 'mouse'
    | 'newspaper'
    | 'notebook'
    | 'notebookEllipsisFill'
    | 'notebookFill'
    | 'notebookPlusFill'
    | 'oneLineDownArrowUpArrow'
    | 'openDoor'
    | 'openDoorFill'
    | 'openDoorPlusFill'
    | 'openDoorRightFill'
    | 'openEnvelopeDollar'
    | 'openEnvelopeText'
    | 'palmTreeFill'
    | 'paperplane'
    | 'paperplaneFill'
    | 'parking'
    | 'paw'
    | 'pencil'
    | 'pencilFill'
    | 'pencilPlusFill'
    | 'people2Fill'
    | 'people3'
    | 'percentage'
    | 'percentageArrowClockwise'
    | 'pergola'
    | 'person'
    | 'personAddFill'
    | 'personBubbleFill'
    | 'personCircle'
    | 'personCircleFill'
    | 'personCrossFill'
    | 'personEllipsisFill'
    | 'personExclamationMarkFill'
    | 'personFill'
    | 'personFillCircle'
    | 'personMinusFill'
    | 'personRightArrow'
    | 'personRightArrowFill'
    | 'personSlash'
    | 'personTimeFill'
    | 'personUpArrow'
    | 'phone'
    | 'phoneArrowBounceFill'
    | 'phoneArrowLeft'
    | 'phoneArrowLeftFill'
    | 'phoneArrowRight'
    | 'phoneArrowRightFill'
    | 'phoneArrowUp'
    | 'phoneArrowUpFill'
    | 'phoneCancelFill'
    | 'phoneCheck'
    | 'phoneCheckFill'
    | 'phoneCross'
    | 'phoneCrossFill'
    | 'phoneEllipsisFill'
    | 'phoneExclamationPointFill'
    | 'phoneFill'
    | 'phoneMinus'
    | 'phoneMinusFill'
    | 'phonePlus'
    | 'phonePlusFill'
    | 'phoneTimer'
    | 'phoneTimerFill'
    | 'phoneVoicemail'
    | 'phoneVoicemailFill'
    | 'phoneWaves'
    | 'phoneWavesFill'
    | 'photo'
    | 'photoFill'
    | 'photosStack'
    | 'photosStack2'
    | 'pieChartFill'
    | 'piggyBank'
    | 'plant'
    | 'plugCheckFill'
    | 'plugCrossFill'
    | 'plusBoldLarge'
    | 'plusBoldSmall'
    | 'plusCircleFill'
    | 'plusThinLarge'
    | 'plusThinSmall'
    | 'printer'
    | 'pushPin'
    | 'pushPinFill'
    | 'pushPinSlash'
    | 'qrcode'
    | 'qrcodeFill'
    | 'questionMarkCircle'
    | 'questionMarkCircleFill'
    | 'questionMarkFill'
    | 'questionmarkBubble'
    | 'questionmarkBubbleFill'
    | 'ratio'
    | 'receipt'
    | 'rightArrowCircle'
    | 'rocket'
    | 'rocketFill'
    | 'rocks'
    | 'router'
    | 'satellite'
    | 'screenGraph'
    | 'share'
    | 'shareFill'
    | 'shed'
    | 'signboard'
    | 'signboardCheckFill'
    | 'signboardFill'
    | 'sink'
    | 'slider2'
    | 'slider3'
    | 'slider3Fill'
    | 'smileyFace'
    | 'solarHotWaterPanel'
    | 'solarPanel'
    | 'spoonKnife'
    | 'square'
    | 'squareArrowBoldUpRight'
    | 'squareArrowDown'
    | 'squareArrowUpRight'
    | 'squareCheckFill'
    | 'squareMinusFill'
    | 'squarePencilFill'
    | 'squareShaded'
    | 'squares3SearchFill'
    | 'squares4'
    | 'squares4Fill'
    | 'squaresStacked3'
    | 'star'
    | 'starCircle'
    | 'starCircleFill'
    | 'starFill'
    | 'starHalf'
    | 'success'
    | 'successFill'
    | 'sun'
    | 'sunset'
    | 'tableRingBell'
    | 'tableRingBellFill'
    | 'tag'
    | 'tagEllipsisFill'
    | 'tagFill'
    | 'target'
    | 'targetFill'
    | 'taskTime'
    | 'taskTimeFill'
    | 'temple'
    | 'templePeople'
    | 'tennisBall'
    | 'textBox'
    | 'textBubble'
    | 'textBubbleEllipsisFill'
    | 'textBubbleFill'
    | 'thumbsDown'
    | 'thumbsDownFill'
    | 'thumbsUp'
    | 'thumbsUpFill'
    | 'tiles'
    | 'timeline'
    | 'timer'
    | 'toilet'
    | 'tools'
    | 'townhouse'
    | 'trash'
    | 'trashFill'
    | 'trayArrowshapeUpRightFill'
    | 'trayFill'
    | 'tree'
    | 'triangleArrowDownFill'
    | 'triangleArrowUpFill'
    | 'triangleForwardCircle'
    | 'triangleForwardCircleFill'
    | 'trianglesDownHalfDash'
    | 'trianglesUpHalfDash'
    | 'trophyFill'
    | 'tshirt'
    | 'twitter'
    | 'unlockFill'
    | 'upside'
    | 'vacuumCleaner'
    | 'vaultRECircleFill'
    | 'verticalEllipsis'
    | 'verticalLineCircle'
    | 'wallReverseArrows'
    | 'wallet'
    | 'wand'
    | 'wardrobe'
    | 'warningFill'
    | 'washerStar'
    | 'washerWaterDrop'
    | 'waterTank'
    | 'waterWavesDrops'
    | 'windows'
    | 'woman'
    | 'youtube'
    | 'legacyArrowThickUp'
    | 'legacyLogin'
    | 'legacyPlayFilled'
    | 'legacyTetrisArrow';

// deprecate icon selector, to be replaced with up-icon
@Component({
    selector: 'up-icon, nc-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit, AfterViewInit {
    @Input() public name: IconName;
    /** @deprecated: use currentColor instead **/
    @Input() public color: Color;
    @Input() public size: Size;
    @ViewChild('svg', { static: false }) public svg: ElementRef;

    public get href(): string {
        return `#icon-${this.name}`;
    }

    constructor(private renderer: Renderer2, private iconSpriteService: IconSpriteService) {}

    public ngOnInit(): void {
        this.iconSpriteService.load();
    }

    public ngAfterViewInit(): void {
        this.setFillColor();
    }

    private setFillColor(): void {
        if (this.color && this.color[0] === '#') {
            this.renderer.setStyle(this.svg.nativeElement, 'fill', this.color);
        }
    }
}
