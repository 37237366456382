import { Component, Input } from '@angular/core';
import { StateService } from '@uirouter/core';

import { AddressT } from '../../../../common/models/address.model';
import { conversion } from '../../../../common/models/conversion.model';
import { TrackingService } from '../../../../common/services/tracking.service';
import { ConversionService } from '../../../../modules/core/services/conversion/conversion.service';

@Component({
    selector: 'up-appraisal-landing',
    templateUrl: 'appraisal-landing.component.html',
    styleUrls: ['appraisal-landing.component.scss'],
})
export class AppraisalLandingComponent {
    @Input() public submissionError: string;
    @Input() public appraisalAlreadyRequested: boolean;
    @Input() public funnelName: conversion.Funnel;

    constructor(
        private stateService: StateService,
        private trackingService: TrackingService,
        private conversionService: ConversionService,
    ) {}

    public onAddressUpdated(address?: AddressT): void {
        if (!address) return;

        this.trackingService.trackDataEvent('conversion-capture-address', { address: address.formattedAddress });
        this.conversionService.storeConversionState(address);

        const nextState =
            this.funnelName === conversion.Funnel.Booking
                ? 'conversion.booking.address-found'
                : 'conversion.appraisal.address-found';

        this.stateService.go(nextState, { address });
    }
}
