import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';

import { SharedModule } from '../../common/shared.module';
import { InternalSignInComponent } from '../../components/internal/internal-sign-in/internal-sign-in.component';

import { AuthFooterComponent } from './auth-footer/auth-footer.component';
import { CheckEmailComponent } from './check-email/check-email.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { GoogleSignInComponent } from './google-sign-in/google-sign-in.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PropertyTypeSelectorComponent } from './property-type-selector/property-type-selector.component';
import { UserSetPasswordComponent } from './set-password/set-password.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { UserComponent } from './user.component';
import { UserStates } from './user.states';

@NgModule({
    imports: [FormsModule, SharedModule, UIRouterModule.forChild({ states: UserStates })],
    declarations: [
        UserComponent,
        SignInComponent,
        ForgotPasswordComponent,
        UserSetPasswordComponent,
        InternalSignInComponent,
        GoogleSignInComponent,
        CheckEmailComponent,
        AuthFooterComponent,
        PropertyTypeSelectorComponent,
        NotificationsComponent,
    ],
    entryComponents: [
        UserComponent,
        SignInComponent,
        ForgotPasswordComponent,
        UserSetPasswordComponent,
        InternalSignInComponent,
        CheckEmailComponent,
    ],
})
export class UserModule {}
