import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { FocusKeyManager, FocusMonitor } from '@angular/cdk/a11y';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    HostBinding,
    HostListener,
    OnDestroy,
    OnInit,
    QueryList,
    Renderer2,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { delay, filter, startWith, takeUntil } from 'rxjs/operators';

import { MediaQueries } from '../../../common/models/media-queries.model';
import { WindowRef } from '../../../common/services/window.service';
import mediaQueries from '../../../settings/media-queries';
import { KeyboardUserHelperService } from '../../core/services/keyboard-user-helper.service';
import { ScrollBlockService } from '../../core/services/scroll-block.service';
import { NavPaneButtonComponent } from '../nav-pane-button/nav-pane-button.component';
import { NavigationService } from '../navigation.service';

enum PaneBehaviorState {
    Mobile = 'mobile',
    Tablet = 'tablet',
    Desktop = 'desktop',
}

enum PaneMobileState {
    Open = 'open',
    Closed = 'closed',
}

enum PaneTabletState {
    Expanded = 'expanded',
    Collapsed = 'collapsed',
}

export const navPanePrimaryCollapseWidth = 70;

@Component({
    selector: 'up-nav-pane-primary',
    templateUrl: 'nav-pane-primary.component.html',
    styleUrls: ['nav-pane-primary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('paneTablet', [
            state('collapsed', style({ width: `${navPanePrimaryCollapseWidth}px` })),
            state('expanded', style({ width: '276px' })),
            transition(`collapsed <=> expanded`, animate('400ms ease-in-out')),
        ]),
        trigger('paneMobile', [
            state('closed', style({ transform: 'translateX(-100%)' })),
            state('open', style({ transform: 'translateX(0%)' })),
            transition('closed => open', animate('200ms ease-out')),
            transition('open => closed', animate('200ms ease-in')),
        ]),
    ],
})
export class NavPanePrimaryComponent implements OnDestroy, OnInit, AfterContentInit {
    @ContentChildren(NavPaneButtonComponent, { descendants: true })
    public navPaneButtonComponents: QueryList<NavPaneButtonComponent>;
    public PaneBehaviourState = PaneBehaviorState;
    public paneBehaviourState: PaneBehaviorState = PaneBehaviorState.Mobile;
    private _mobileNavPaneOpen$ = new BehaviorSubject<boolean>(false);
    public mobileNavPaneOpen$ = this._mobileNavPaneOpen$.asObservable();
    public PaneTabletState = PaneTabletState;
    public paneTabletState: PaneTabletState = PaneTabletState.Expanded;
    private readonly mouseOverDeadTimeMs = 200;
    private lastOrigin: string;
    private focusKeyManager: FocusKeyManager<NavPaneButtonComponent>;
    private destroy$ = new Subject<void>();
    private paneMobileState: PaneMobileState = PaneMobileState.Closed;
    private mousingOver: boolean;
    private isFromTouch: boolean;
    private mouseOverDeadTimeTimeout: number;

    public get openOnMobile(): boolean {
        return this.paneBehaviourState === PaneBehaviorState.Mobile && this.paneMobileState === PaneMobileState.Open;
    }

    @HostBinding('@paneMobile')
    public get paneMobileAnim(): PaneMobileState | 'noop' {
        return this.paneBehaviourState === PaneBehaviorState.Mobile ? this.paneMobileState : 'noop';
    }

    @HostBinding('@paneTablet')
    public get paneTabletAnim(): PaneTabletState | 'noop' {
        return this.paneBehaviourState === PaneBehaviorState.Tablet ? this.paneTabletState : 'noop';
    }

    @HostBinding('class.is-collapsed-on-tablet')
    public get isCollapsedOnTablet(): boolean {
        return this.paneTabletState === PaneTabletState.Collapsed;
    }

    public get isKeyboardUser$(): Observable<boolean> {
        return this.keyboardUserHelperService.isKeyboardUser$;
    }

    constructor(
        private breakpointObserver: BreakpointObserver,
        private focusMonitor: FocusMonitor,
        private navigationService: NavigationService,
        private keyboardUserHelperService: KeyboardUserHelperService,
        private elementRef: ElementRef<HTMLElement>,
        private renderer2: Renderer2,
        private changeDetectorRef: ChangeDetectorRef,
        private scrollBlockService: ScrollBlockService,
        private windowRef: WindowRef,
    ) {}

    @HostListener('touchstart')
    public onTouchStart(): void {
        this.isFromTouch = true;
    }

    @HostListener('mouseover')
    public onMouseOver(): void {
        // Don't expand the pane if the user tapped on the pane via a touchscreen.
        if (!this.isFromTouch) {
            this.setMousingOver(true);
            return;
        }

        this.isFromTouch = false;
    }

    @HostListener('mouseleave')
    public onMouseOut(): void {
        this.setMousingOver(false);
    }

    @HostListener('keydown', ['$event'])
    public onKeydown(keyboardEvent: KeyboardEvent): void {
        if (keyboardEvent.key === 'Escape') {
            this.setMobileNavOpen(false);
        }
        this.focusKeyManager.onKeydown(keyboardEvent);
    }

    @HostListener('@paneMobile.done', ['$event'])
    public onPaneAnimDone(animationEvent: AnimationEvent): void {
        if (<PaneMobileState>(<unknown>animationEvent.toState) === PaneMobileState.Closed) {
            this.setNavInteractionsEnabled(false);
        }
    }

    public ngOnInit(): void {
        this.handlePaneBehaviorState();
    }

    public ngAfterContentInit(): void {
        this.initFocusManagers();
        this.registerNavigationServiceEvents();
        this.setMousingOver(false);
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
        this.scrollBlockService.unblock();
    }

    public setMobileNavOpen(open: boolean): void {
        if (this.paneBehaviourState !== PaneBehaviorState.Mobile && open) return;
        this.paneMobileState = open ? PaneMobileState.Open : PaneMobileState.Closed;
        this.handleMobilePaneStateChange();
    }

    public toggleMobileNavOpen(): void {
        const current = this.paneMobileState;
        if (this.paneBehaviourState !== PaneBehaviorState.Mobile && current === PaneMobileState.Closed) return;
        this.paneMobileState = current === PaneMobileState.Open ? PaneMobileState.Closed : PaneMobileState.Open;
        this.handleMobilePaneStateChange();
    }

    public togglePaneTabletState(): void {
        this.paneTabletState =
            this.paneTabletState === PaneTabletState.Collapsed ? PaneTabletState.Expanded : PaneTabletState.Collapsed;
        this.changeDetectorRef.detectChanges();
    }

    private clearMouseOverDeadTimeTimeout(): void {
        if (this.mouseOverDeadTimeTimeout) {
            clearTimeout(this.mouseOverDeadTimeTimeout);
        }
    }

    private handlePaneBehaviorState(): void {
        const tabletBreakpoint = `(min-width: ${mediaQueries[MediaQueries.Small]}px)`;
        const desktopBreakpoint = `(min-width: ${mediaQueries[MediaQueries.NavSidebarExpanded]}px)`;

        combineLatest([
            this.breakpointObserver.observe([tabletBreakpoint, desktopBreakpoint]),
            this.navigationService.hasNavPaneSecondary$.pipe(startWith(false)),
        ])
            // delay(0) defers execution of subscription to the next frame to prevent `paneBehaviourState` and
            // `paneTabletState` from changing during component init caused by `hasNavPaneSecondary$` initially emitting
            // false and then changing again to some other value after it has been set by the navigationService causing
            // ExpressionChangedAfterItHasBeenCheckedError
            .pipe(delay(0), takeUntil(this.destroy$))
            .subscribe(([{ matches, breakpoints }, forceCollapse]) => {
                // If doing anything that should override the mouse over functionality, clear the timeout so that when
                // the timeout fires it doesn't incorrectly override the update state
                this.clearMouseOverDeadTimeTimeout();
                this.setMobileNavOpen(false);

                // if it doesn't match desktop or tablet, it must be mobile
                if (!matches) {
                    this.paneBehaviourState = PaneBehaviorState.Mobile;
                    this.paneTabletState = PaneTabletState.Collapsed;
                } else if (breakpoints[tabletBreakpoint] && !breakpoints[desktopBreakpoint]) {
                    this.paneBehaviourState = PaneBehaviorState.Tablet;
                    this.paneTabletState = PaneTabletState.Collapsed;
                } else {
                    this.paneBehaviourState = PaneBehaviorState.Desktop;
                    this.paneTabletState = PaneTabletState.Expanded;

                    if (forceCollapse) {
                        this.paneBehaviourState = PaneBehaviorState.Tablet;
                        this.paneTabletState = PaneTabletState.Collapsed;
                    }
                }

                // Always make sure the nav is interactable whenever the pane behaviour state changes
                this.setNavInteractionsEnabled(true);

                this.changeDetectorRef.detectChanges();
            });
    }

    private handleMobilePaneStateChange(): void {
        if (this.paneMobileState === PaneMobileState.Open) {
            this.setNavInteractionsEnabled(true);
            this.focusKeyManager.setFirstItemActive();
            this.scrollBlockService.block();
        } else {
            this.scrollBlockService.unblock();
        }

        this._mobileNavPaneOpen$.next(this.paneMobileState === PaneMobileState.Open);

        this.changeDetectorRef.detectChanges();
    }

    private initFocusManagers(): void {
        this.focusKeyManager = new FocusKeyManager<NavPaneButtonComponent>(this.navPaneButtonComponents)
            .withWrap(true)
            .withVerticalOrientation(true);

        this.focusMonitor
            .monitor(this.elementRef.nativeElement, true)
            .pipe(takeUntil(this.destroy$))
            .subscribe(origin => {
                if (origin === 'keyboard' || origin === 'program') {
                    this.lastOrigin = origin;
                    this.setMousingOver(true);
                } else if (!origin && (this.lastOrigin === 'keyboard' || this.lastOrigin === 'program')) {
                    this.setMousingOver(false);
                }
            });
    }

    private setMousingOver(mousingOver: boolean) {
        this.mousingOver = mousingOver;

        // we only care about mouse overs when in the tablet screen size
        if (this.paneBehaviourState !== PaneBehaviorState.Tablet) return;

        if (mousingOver) {
            this.clearMouseOverDeadTimeTimeout();
            this.mouseOverDeadTimeTimeout = this.windowRef.nativeWindow.setTimeout(() => {
                this.paneTabletState = this.mousingOver ? PaneTabletState.Expanded : PaneTabletState.Collapsed;
                this.changeDetectorRef.detectChanges();
            }, this.mouseOverDeadTimeMs);
        } else {
            this.paneTabletState = PaneTabletState.Collapsed;
        }

        this.changeDetectorRef.detectChanges();
    }

    private setNavInteractionsEnabled(enabled?: boolean): void {
        if (enabled) {
            this.renderer2.removeClass(this.elementRef.nativeElement, 'u-display--none');
        } else {
            this.renderer2.addClass(this.elementRef.nativeElement, 'u-display--none');
        }
    }

    private registerNavigationServiceEvents(): void {
        this.navigationService.closeMobileNavPane$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.setMobileNavOpen(false));
        this.navigationService.collapseTabletPane$
            .pipe(
                takeUntil(this.destroy$),
                // Should only try to collapse the tablet pane if not mousing over
                filter(
                    () =>
                        !this.isCollapsedOnTablet &&
                        !this.mousingOver &&
                        this.paneBehaviourState === PaneBehaviorState.Tablet,
                ),
            )
            .subscribe(() => this.togglePaneTabletState());
    }
}
