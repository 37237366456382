import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    OnDestroy,
    QueryList,
    ViewChild,
} from '@angular/core';
import { TransitionService } from '@uirouter/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IconName } from '../../../common/components/icon/icon.component';
import { CollapserPanelComponent } from '../../collapser-panel/components/collapser-panel/collapser-panel.component';
import { NavPaneButtonComponent } from '../nav-pane-button/nav-pane-button.component';
import { NavPaneSecondaryNestedItemsComponent } from '../nav-pane-secondary-nested-items/nav-pane-secondary-nested-items.component';

@Component({
    selector: 'nc-nav-pane-secondary-items, up-nav-pane-secondary-items',
    templateUrl: 'nav-pane-secondary-items.component.html',
    styleUrls: ['nav-pane-secondary-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavPaneSecondaryItemsComponent implements AfterViewInit, OnDestroy {
    public activeText: string;
    public activeIcon: IconName;
    public collapserDisabled: boolean;
    public navPaneSecondaryMobileView: boolean;
    @ContentChildren(NavPaneButtonComponent, { descendants: true })
    private navPaneButtons: QueryList<NavPaneButtonComponent>;
    @ContentChildren(NavPaneButtonComponent, { read: ElementRef, descendants: true })
    private navPaneButtonsElementRef: QueryList<ElementRef>;
    @ContentChildren(NavPaneSecondaryNestedItemsComponent)
    private navPaneSecondaryNestedItemsComponentsQL: QueryList<NavPaneSecondaryNestedItemsComponent>;
    @ViewChild(CollapserPanelComponent, { static: false }) private collapserPanelComponent: CollapserPanelComponent;
    private destroyTransitionHook: Function;
    private destroy$ = new Subject<void>();

    private get navPaneSecondaryNestedItemsComponents(): NavPaneSecondaryNestedItemsComponent[] {
        return this.navPaneSecondaryNestedItemsComponentsQL.toArray();
    }

    constructor(private transitionService: TransitionService, private changeDetectorRef: ChangeDetectorRef) {}

    public ngAfterViewInit(): void {
        this.updateView();
        this.handleNestedItemsInAccordionStyle();
        this.destroyTransitionHook = this.transitionService.onSuccess({}, () => this.updateView());
    }

    public ngOnDestroy(): void {
        if (this.destroyTransitionHook) {
            this.destroyTransitionHook();
        }

        this.destroy$.next();
        this.destroy$.complete();
    }

    public setIsNavPaneSecondaryMobileView(is: boolean): void {
        this.navPaneSecondaryMobileView = is;
        this.collapserDisabled = !is;
        if (is) {
            this.collapserPanelComponent.closePanel(true);
        } else {
            this.collapserPanelComponent.openPanel(true);
        }
    }

    private handleNestedItemsInAccordionStyle(): void {
        // Watch every nested element for a state change to notify the rest to close
        this.navPaneSecondaryNestedItemsComponents.forEach((c, i) =>
            c.stateChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.closeOtherNestedItems(i)),
        );
    }

    private closeOtherNestedItems(activeIndex: number): void {
        this.navPaneSecondaryNestedItemsComponents.forEach((c, i) => {
            // Do nothing with the active one, let it collapse or expand as it's needed
            if (i === activeIndex) return;
            c.close(false, false);
        });
    }

    private updateView(): void {
        // Grabs all the NavButtonComponents from within all the NavPaneSecondaryNestedItems to reduces it into a
        // single array
        const allNestedNavButtons = this.navPaneSecondaryNestedItemsComponents
            .map(c => c.navPaneButtonComponents?.toArray() || [])
            .reduce((prev, curr) => [...prev, ...curr], []);
        // Combine it with all the non-nested buttons
        const allNavButtonElements = [...this.navPaneButtons.toArray(), ...allNestedNavButtons];
        if (!allNavButtonElements?.length) return;

        const activeElement = allNavButtonElements.find(e => e.isRelatedState);
        if (!activeElement) return;

        this.activeText = activeElement.label;
        this.activeIcon = activeElement.icon && activeElement.icon.name;

        if (this.navPaneSecondaryMobileView) {
            this.collapserPanelComponent.closePanel();
        }
        this.changeDetectorRef.detectChanges();
    }
}
