import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { MediaTagName } from '../../../../common/models/domain/media/media-tag-name.model';
import { media } from '../../../../common/models/media.model';

@Component({
    selector: 'nc-files-list',
    templateUrl: 'files-list.component.html',
    styleUrls: ['files-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesListComponent {
    @Input() public loading = false;
    @Input() public files: media.File[];
    @Output() public download = new EventEmitter<[MediaTagName, string]>();

    public onDownload(tag: MediaTagName, mediaId: string): void {
        this.download.emit([tag, mediaId]);
    }
}
