import * as SidenavActions from './sidenav.actions';
import { SidenavAction } from './sidenav.actions';

export interface SidenavState {
    sidenavOpen: boolean;
}

export function sidenavReducer(state: SidenavState = { sidenavOpen: false }, action: SidenavAction) {
    switch (action.type) {
        case SidenavActions.TOGGLE_SIDENAV:
            return {
                ...state,
                sidenavOpen: action.payload,
            };
        default:
            return state;
    }
}
