<span class="wrapper">
    <span *ngIf="iconName" class="menu-list-item-icon u-margin-right--sm">
        <nc-icon slot="figure" [size]="'small'" [name]="iconName"></nc-icon>
    </span>
    <span class="content-wrapper">
        <div class="prefix"><ng-content select="[slot='prefix']"></ng-content></div>
        <div><ng-content></ng-content></div>
    </span>
    <span class="loader u-padding-left--2xs" *ngIf="isLoading">
        <nc-spinner [size]="'small'"></nc-spinner>
    </span>
</span>
