import { HttpErrorResponse } from '@angular/common/http';

export namespace error {
    export interface Error {
        code: string;
        message: string;
    }

    export interface Response<T> {
        code: T;
        message?: string;
        errors?: Error[];
    }

    export interface HttpResponse<T> extends HttpErrorResponse {
        error: Response<T>;
    }

    /** @deprecated: use ErrorCodes instead **/
    export enum Codes {
        TimeUnavailable = 'CALENDAR_TIME_UNAVAILABLE',
        VendorAlreadyExists = 'VENDOR_ALREADY_EXISTS',
        EmailInUse = 'EMAIL_IN_USE',
        ReservedEmail = 'RESERVED_EMAIL',
        UserAlreadyExists = 'USER_ALREADY_EXISTS',
        UnknownMeeting = 'UNKNOWN_MEETING',
        StaffNotAllowed = 'STAFF_NOT_ALLOWED',
        ValidationFailed = 'VALIDATION_FAILED',
        UnknownUser = 'UNKNOWN_USER',
    }

    export enum PropertyCodes {
        BadRequest = 'BAD_REQUEST',
    }
}
