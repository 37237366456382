import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import { TaskResource } from '../../modules/core/resources/task.resource';

import * as TaskActions from './task.actions';

@Injectable()
export class TaskEffects {
    public loadTasks = createEffect(() =>
        this.actions.pipe(
            ofType<TaskActions.GetTasks>(TaskActions.GET_TASKS),
            switchMap(action => this.taskResource.forProperty(action.payload)),
            map((tasks: any) => {
                return { type: TaskActions.TASKS_LOADED, payload: tasks };
            }),
        ),
    );

    constructor(private actions: Actions, private taskResource: TaskResource) {}
}
