import { NgModule } from '@angular/core';

import { PropertyFeatureToIconNameModule } from '../property-feature-to-icon-name/property-feature-to-icon-name.module';
import { SharedVendorModule } from '../shared-vendor/shared-vendor.module';
import { TagModule } from '../tag/tag.module';

import { ListingKeyFeaturesComponent } from './components/listing-key-features/listing-key-features.component';

@NgModule({
    imports: [TagModule, SharedVendorModule, PropertyFeatureToIconNameModule],
    exports: [ListingKeyFeaturesComponent],
    declarations: [ListingKeyFeaturesComponent],
    providers: [],
})
export class ListingKeyFeaturesModule {}
