import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { combineLatest, iif, Observable, of, Subject, zip } from 'rxjs';
import { catchError, filter, first, map, publishReplay, refCount, switchMap, takeUntil } from 'rxjs/operators';

import { UpValidators } from '../../../common/form-validators/validators';
import { PropertyType } from '../../../common/models/property.model';
import { userApi } from '../../../common/models/user-api.model';
import { user } from '../../../common/models/user.model';
import { UserService, UserT } from '../../../common/services/user.service';
import { UtmService } from '../../../common/services/utm.service';
import { BudgetPricingUtilities } from '../../../common/utilities/budget-pricing.utilities';
import { UserFacade } from '../../../store/user/user.facade';
import { NotificationPreferencesState } from '../../../store/user/user.reducer';
import { UserResource } from '../../core/resources/user.resource';
import { EnvironmentService } from '../../core/services/environment.service';
import { NotificationService } from '../../core/services/notification.service';
import { ModalService } from '../../modal/services/modal/modal.service';
import {
    UnsubscribeDialogComponent,
    UnsubscribeDialogData,
} from '../dialogs/unsubscribe-dialog/unsubscribe-dialog.component';

import BuyerTypes = user.BuyerTypes;

interface Form {
    sendListingNotification: boolean;
    sendJustSoldNotification: boolean;
    propertyPreference: {
        bathroomsMin: string | number;
        bedroomsMin: string | number;
        developmentPreference: 'All' | 'Existing' | 'New';
        parkingMin: string | number;
        priceMin: string | number;
        priceMax: string | number;
        propertyTypes: PropertyType[];
    };
    suburbs: string[];
    user?: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
    };
}

@Component({
    selector: 'up-notifications',
    templateUrl: 'notifications.component.html',
    styleUrls: ['notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
    public SubscribeErrorCodes = userApi.notifications.subscribe.post.ErrorCodes;
    public form: FormGroup;
    public hasAttemptedFormSubmit: boolean;
    public isPriceMinGreaterThanMaxValidationError: boolean;
    public isPriceMaxLesserThanMinValidationError: boolean;
    public isPriceMinEqualsMaxValidationError: boolean;
    public hideForm: boolean;
    public isSubscribed$: Observable<boolean>;
    public notificationPreferencesState: NotificationPreferencesState;
    public editingForAnotherUser$: Observable<string | undefined>;
    private notificationsPreferencesState$: Observable<NotificationPreferencesState>;
    private notificationsPreferences$: Observable<userApi.notifications.preferences.get.Response>;
    private destroy$ = new Subject<void>();
    private auth$: Observable<UserT>;

    constructor(
        private formBuilder: FormBuilder,
        private utmService: UtmService,
        private userService: UserService,
        private modalService: ModalService,
        private userFacade: UserFacade,
        private budgetPricingUtilities: BudgetPricingUtilities,
        private stateService: StateService,
        private userResource: UserResource,
        private notificationService: NotificationService,
        private environmentService: EnvironmentService,
    ) {}

    public get orgDisplayName(): string {
        return this.environmentService.config.organisation.displayName;
    }

    public get priceOptions(): number[] {
        return this.budgetPricingUtilities.generateBudgetPrices();
    }

    public get userIdParam(): string {
        return this.stateService.params['user'];
    }

    // No need to show unsubscribe when editing another user's param as the option is already present in internal
    public get hideUnsubscribe(): boolean {
        return !!this.userIdParam;
    }

    private get tokenParam(): string {
        return this.stateService.params['token'];
    }

    private get unsubscribeParam(): boolean {
        return this.stateService.params['unsubscribe'];
    }

    // if true, will immediately show the form without the user needing to click 'create alert'
    private get immediatelyShowFormParam(): boolean {
        return this.stateService.params['immediatelyShowForm'];
    }

    public ngOnInit(): void {
        this.setupData();
        this.setupForm();

        // Workaround due to issues with modal when triggering one from ngOnInit that causes a race condition
        // https://github.com/UpsideRealty/upside/pull/3518
        setTimeout(() => {
            if (this.unsubscribeParam) {
                this.confirmUnsubscribe();
            }
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public createAlert(): void {
        this.hideForm = false;
    }

    public confirmUnsubscribe(): void {
        this.modalService.show<UnsubscribeDialogData>(UnsubscribeDialogComponent, { token: this.tokenParam });
    }

    public onSubmit(): void {
        this.hasAttemptedFormSubmit = true;
        if (this.isFormInvalid) return;

        this.getFormValueAsNotificationPreferences()
            .pipe(
                switchMap(notificationPreferences => {
                    const isAuthenticated$ = this.auth$.pipe(map(authData => authData.authenticated));
                    return zip(isAuthenticated$, of(notificationPreferences));
                }),
            )
            .subscribe(
                ([isAuthenticated, request]) => {
                    if (this.userIdParam) {
                        this.userFacade.updateNotificationPreferencesForUser(this.userIdParam, request);
                    } else if (this.tokenParam) {
                        this.userFacade.updateNotificationsPreferencesWithToken(this.tokenParam, request);
                    } else if (isAuthenticated) {
                        this.userFacade.updateNotificationsPreferences(request);
                    } else {
                        this.userFacade.notificationsSubscribe(request);
                    }
                },
                error => this.notificationService.error(error),
            );
    }

    public isFormControlError(controlPath: string): boolean {
        const formControl = this.form.get(controlPath);
        return this.hasAttemptedFormSubmit && formControl && formControl.invalid;
    }

    public get isFormInvalid(): boolean {
        return (
            this.form.invalid ||
            this.isPriceMaxLesserThanMinValidationError ||
            this.isPriceMinGreaterThanMaxValidationError ||
            this.isPriceMinEqualsMaxValidationError
        );
    }

    private getFormValueAsNotificationPreferences(): Observable<user.Notifications> {
        return combineLatest([this.auth$, this.notificationsPreferences$]).pipe(
            first(),
            map(([{ authenticated }, notificationPreferences]) => {
                const formValue = <Form>this.form.value;
                const { propertyPreference, sendListingNotification, sendJustSoldNotification, suburbs } = formValue;
                const utm = this.utmService.getStoredUtmCodes();

                if ((authenticated || this.tokenParam) && !notificationPreferences.user) {
                    throw Error(
                        'Could not update notification preferences, was not able to load existing user preferences',
                    );
                }

                const { firstName, lastName, email, phoneNumber } =
                    authenticated || this.tokenParam ? notificationPreferences.user : formValue.user;

                return {
                    propertyPreference: {
                        bathroomsMin: this.parseIntOrUndefined(propertyPreference.bathroomsMin),
                        bedroomsMin: this.parseIntOrUndefined(propertyPreference.bedroomsMin),
                        developmentPreference: propertyPreference.developmentPreference,
                        parkingMin: this.parseIntOrUndefined(propertyPreference.parkingMin),
                        priceMin: this.parseIntOrUndefined(propertyPreference.priceMin),
                        priceMax: this.parseIntOrUndefined(propertyPreference.priceMax),
                        propertyTypes: propertyPreference.propertyTypes,
                    },
                    suburbs,
                    sendListingNotification,
                    sendJustSoldNotification,
                    sendWeeklyEmail: true,
                    userTypes: [BuyerTypes.Buyer],
                    utm,
                    user: { firstName, lastName, email, phoneNumber },
                };
            }),
        );
    }

    private parseIntOrUndefined(value: string | number): number {
        return value && (typeof value === 'string' ? parseInt(value) : value);
    }

    private loadNotificationsPreferences(): void {
        this.userFacade.resetNotificationPreferencesState();
        this.auth$.subscribe(authData => {
            if (this.userIdParam) {
                this.userFacade.getNotificationPreferencesForUser(this.userIdParam);
            } else if (this.tokenParam) {
                this.userFacade.getNotificationsPreferencesWithToken(this.tokenParam);
            } else if (authData.authenticated) {
                this.userFacade.getNotificationsPreferences();
            }
        });
    }

    private doExtraFormValidation(): void {
        const { priceMin, priceMax } = (<Form>this.form.value).propertyPreference;
        const parsedMin = typeof priceMin === 'string' ? parseInt(priceMin) : priceMin;
        const parsedMax = typeof priceMax === 'string' ? parseInt(priceMax) : priceMax;

        this.isPriceMinGreaterThanMaxValidationError = false;
        this.isPriceMaxLesserThanMinValidationError = false;
        if (!parsedMax || !parsedMin) return;
        this.isPriceMinEqualsMaxValidationError = parsedMax === priceMin;
        this.isPriceMinGreaterThanMaxValidationError = priceMin > parsedMax;
        this.isPriceMaxLesserThanMinValidationError = parsedMax < priceMin;
    }

    private setupData(): void {
        this.auth$ = this.userService.userAuthDetailsUpdated$.pipe(first());

        this.loadNotificationsPreferences();
        this.notificationsPreferencesState$ = this.userFacade.notificationsPreferencesState$.pipe(
            takeUntil(this.destroy$),
        );
        this.notificationsPreferences$ = this.userFacade.notificationsPreferences$.pipe(takeUntil(this.destroy$));
        this.isSubscribed$ = this.notificationsPreferencesState$.pipe(
            // Don't check subscribed state while it's being loaded or updated
            filter(
                state =>
                    !state.loadingNotificationsPreferences &&
                    !state.updatingNotificationsPreferences &&
                    !state.subscribingNotifications,
            ),
            map(state => state.notificationsPreferences),
            map(preferences => preferences.sendListingNotification || preferences.sendWeeklyEmail),
        );
        this.notificationsPreferencesState$.subscribe(state => (this.notificationPreferencesState = state));

        this.editingForAnotherUser$ = iif(
            () => !!this.userIdParam,
            this.userResource.getUser(this.userIdParam).pipe(
                map(user => user.displayName),
                catchError(error => {
                    this.notificationService.httpError(
                        error,
                        undefined,
                        'Error loading user\'s details, please refresh to try again',
                    );
                    return of('Unknown User');
                }),
                publishReplay(1),
                refCount(),
            ),
            of(undefined),
        );
    }

    private setupForm(): void {
        zip(this.isSubscribed$, this.auth$).subscribe(
            ([isSubscribed, authData]) =>
                (this.hideForm = authData.authenticated && !isSubscribed && !this.immediatelyShowFormParam),
        );

        combineLatest(this.notificationsPreferencesState$, this.auth$)
            .pipe(
                takeUntil(this.destroy$),
                map(([notificationPreferencesState, authData]) => [
                    notificationPreferencesState.successUnsubscribingNotifications,
                    authData.authenticated,
                ]),
                filter(
                    ([successUnsubscribingNotifications, authenticated]) =>
                        successUnsubscribingNotifications && authenticated,
                ),
            )
            .subscribe(() => (this.hideForm = true));

        this.form = this.formBuilder.group({
            sendListingNotification: '',
            sendJustSoldNotification: '',
            propertyPreference: this.formBuilder.group({
                bathroomsMin: '',
                bedroomsMin: '',
                developmentPreference: '',
                parkingMin: '',
                priceMin: '',
                priceMax: undefined,
                propertyTypes: ['', Validators.required],
            }),
            suburbs: ['', Validators.required],
            user: this.formBuilder.group({
                email: ['', [Validators.required, UpValidators.strictEmailValidator]],
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                phoneNumber: ['', [Validators.required, UpValidators.phoneNumberValidator]],
            }),
        });

        // Details form should only be visible when creating a user. ie. not authenticated and no token
        this.auth$
            .pipe(
                filter(auth => auth.authenticated || !!this.tokenParam),
                takeUntil(this.destroy$),
            )
            .subscribe(() => this.form.get('user').disable());

        // Only when the preferences actually change do we want to do a patch, not when one of the flags changes.
        // This is possible as the selector only emits if the values it contains has changed
        this.notificationsPreferences$
            .pipe(takeUntil(this.destroy$))
            .subscribe(state => this.form.patchValue(state, { emitEvent: false }));

        // Mark the form as pristine to help with showing and hiding of error messages. API error and success messages
        // gets hidden as the user makes changes to the form
        this.notificationsPreferencesState$.pipe(takeUntil(this.destroy$)).subscribe(() => this.form.markAsPristine());

        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.doExtraFormValidation());
    }
}
