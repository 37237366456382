import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AddressT } from '../../../../common/models/address.model';
import { UseCase } from '../../../../common/models/core/use-case.model';
import { AddressFinderResource } from '../../../core/resources/address-finder.resource';

interface Input {
    addressId: string;
}

@Injectable({ providedIn: 'root' })
export class GetAddressDetailsUseCase implements UseCase<AddressT, Input> {
    constructor(private readonly addressFinderResource: AddressFinderResource) {}

    public execute(input: Input): Observable<AddressT> {
        return this.addressFinderResource.fetchResultMetadata(input.addressId).pipe(map(({ address }) => address));
    }
}
