import { HttpErrorResponse } from '@angular/common/http';

import { error } from '../../common/models/error.model';
import { userApi } from '../../common/models/user-api.model';
import { user } from '../../common/models/user.model';

import {
    getNotificationPreferencesForUser,
    getNotificationPreferencesForUserError,
    getNotificationPreferencesForUserSuccess,
    updateNotificationPreferencesForUser,
    updateNotificationPreferencesForUserError,
    updateNotificationPreferencesForUserSuccess,
    UserAction,
    UserActions,
} from './user.actions';

export interface NotificationPreferencesState {
    loadingNotificationsPreferences: boolean;
    updatingNotificationsPreferences: boolean;
    subscribingNotifications: boolean;
    unsubscribingNotifications: boolean;
    successSubscribingNotifications: boolean;
    successUpdatingNotifications: boolean;
    successUnsubscribingNotifications: boolean;
    errorLoadingNotificationPreferences?: HttpErrorResponse;
    errorUpdatingNotificationPreferences?: HttpErrorResponse;
    errorSubscribeNotifications?: error.HttpResponse<userApi.notifications.subscribe.post.ErrorCodes>;
    errorUnsubscribingNotifications?: HttpErrorResponse;
    notificationsPreferences: user.Notifications;
}

export interface UserState extends NotificationPreferencesState {}

export const initialUserState: UserState = {
    loadingNotificationsPreferences: false,
    updatingNotificationsPreferences: false,
    subscribingNotifications: false,
    unsubscribingNotifications: false,
    successSubscribingNotifications: false,
    successUpdatingNotifications: false,
    successUnsubscribingNotifications: false,
    notificationsPreferences: {
        propertyPreference: {
            bathroomsMin: 0,
            bedroomsMin: 0,
            developmentPreference: user.DevelopmentPreference.All,
            parkingMin: 0,
            priceMin: 0,
            propertyTypes: [],
        },
        sendListingNotification: false,
        sendJustSoldNotification: false,
        sendWeeklyEmail: false,
        suburbs: [],
        userTypes: [user.BuyerTypes.Buyer],
    },
};

export function userReducer(state: UserState = initialUserState, action: UserActions) {
    switch (action.type) {
        case UserAction.RESET_NOTIFICATIONS_PREFERENCES_STATE: {
            return {
                ...state,
                loadingNotificationsPreferences: false,
                updatingNotificationsPreferences: false,
                subscribingNotifications: false,
                unsubscribingNotifications: false,
                successSubscribingNotifications: false,
                successUpdatingNotifications: false,
                successUnsubscribingNotifications: false,
                errorLoadingNotificationPreferences: undefined,
                errorUpdatingNotificationPreferences: undefined,
                errorSubscribeNotifications: undefined,
                errorUnsubscribingNotifications: undefined,
                notificationsPreferences: initialUserState.notificationsPreferences,
            };
        }

        case getNotificationPreferencesForUser.type:
        case UserAction.GET_NOTIFICATIONS_PREFERENCES:
        case UserAction.GET_NOTIFICATIONS_PREFERENCES_WITH_TOKEN: {
            return {
                ...state,
                loadingNotificationsPreferences: true,
                errorLoadingNotificationPreferences: undefined,
                successSubscribingNotifications: false,
                successUpdatingNotifications: false,
            };
        }

        case getNotificationPreferencesForUserSuccess.type:
        case UserAction.GET_NOTIFICATIONS_PREFERENCES_SUCCESS:
        case UserAction.GET_NOTIFICATIONS_PREFERENCES_WITH_TOKEN_SUCCESS: {
            return {
                ...state,
                notificationsPreferences: action.payload,
                loadingNotificationsPreferences: false,
            };
        }

        case getNotificationPreferencesForUserError.type:
        case UserAction.GET_NOTIFICATIONS_PREFERENCES_ERROR:
        case UserAction.GET_NOTIFICATIONS_PREFERENCES_WITH_TOKEN_ERROR: {
            return {
                ...state,
                errorLoadingNotificationPreferences: action.payload,
                loadingNotificationsPreferences: false,
            };
        }

        case updateNotificationPreferencesForUser.type:
        case UserAction.UPDATE_NOTIFICATIONS_PREFERENCES:
        case UserAction.UPDATE_NOTIFICATIONS_PREFERENCES_WITH_TOKEN: {
            return {
                ...state,
                updatingNotificationsPreferences: true,
                successUpdatingNotifications: false,
                errorUnsubscribingNotifications: undefined,
                successUnsubscribingNotifications: false,
            };
        }

        case updateNotificationPreferencesForUserSuccess.type:
        case UserAction.UPDATE_NOTIFICATIONS_PREFERENCES_SUCCESS:
        case UserAction.UPDATE_NOTIFICATIONS_PREFERENCES_WITH_TOKEN_SUCCESS: {
            return {
                ...state,
                notificationsPreferences: action.payload,
                successUpdatingNotifications: true,
                updatingNotificationsPreferences: false,
            };
        }

        case updateNotificationPreferencesForUserError.type:
        case UserAction.UPDATE_NOTIFICATIONS_PREFERENCES_ERROR:
        case UserAction.UPDATE_NOTIFICATIONS_PREFERENCES_WITH_TOKEN_ERROR: {
            return {
                ...state,
                errorUpdatingNotificationPreferences: true,
                updatingNotificationsPreferences: false,
            };
        }

        case UserAction.NOTIFICATIONS_SUBSCRIBE: {
            return {
                ...state,
                subscribingNotifications: true,
                successSubscribingNotifications: false,
                errorSubscribeNotifications: false,
                successUnsubscribingNotifications: false,
            };
        }

        case UserAction.NOTIFICATIONS_SUBSCRIBE_SUCCESS: {
            return {
                ...state,
                successSubscribingNotifications: true,
                subscribingNotifications: false,
            };
        }

        case UserAction.NOTIFICATIONS_SUBSCRIBE_ERROR: {
            return {
                ...state,
                errorSubscribeNotifications: action.payload,
                subscribingNotifications: false,
            };
        }

        case UserAction.NOTIFICATIONS_UNSUBSCRIBE:
        case UserAction.NOTIFICATIONS_UNSUBSCRIBE_WITH_TOKEN: {
            return {
                ...state,
                unsubscribingNotifications: true,
                errorSubscribeNotifications: false,
                successSubscribingNotifications: false,
                successUpdatingNotifications: false,
            };
        }

        case UserAction.NOTIFICATIONS_UNSUBSCRIBE_SUCCESS:
        case UserAction.NOTIFICATIONS_UNSUBSCRIBE_WITH_TOKEN_SUCCESS: {
            return {
                ...state,
                successUnsubscribingNotifications: true,
                unsubscribingNotifications: false,
            };
        }

        case UserAction.NOTIFICATIONS_UNSUBSCRIBE_ERROR:
        case UserAction.NOTIFICATIONS_UNSUBSCRIBE_WITH_TOKEN_ERROR: {
            return {
                ...state,
                errorSubscribeNotifications: action.payload,
                unsubscribingNotifications: false,
            };
        }

        default: {
            return state;
        }
    }
}
