import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import 'dayjs/locale/en-au';

export const initDayjs = () => {
    dayjs.extend(isSameOrBefore);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isToday);
    dayjs.extend(advancedFormat);
    dayjs.extend(utc);
    dayjs.extend(relativeTime);
    dayjs.extend(timezone);
    dayjs.extend(isoWeek);
    dayjs.extend(duration);
    dayjs.extend(calendar);
    dayjs.extend(localeData);
    dayjs.extend(isBetween);
    dayjs.extend(customParseFormat);
    dayjs.extend(localizedFormat);

    // TODO: this needs to be dynamic based on the user's locale.
    dayjs.locale('en-au');
};
