// Use environmentFromGlobal from EnvironmentService unless services cannot be injected

export function getEnvironmentVariable<T extends keyof NcEnv>(key: T): NcEnv[T] | undefined {
    // typeof check required for pre-render as window is not defined
    if (typeof window !== 'undefined') {
        return window.ncEnv?.[key];
    } else if (typeof global !== 'undefined') {
        return global.ncEnv?.[key];
    }
}
