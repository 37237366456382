<div
    [ngClass]="{
        wrapper: true,
        'is-error': error,
        'is-success': success && !focused,
        'is-focused': focused,
        'is-disabled': disabled,
        'internal-label': internalLabel && size === 'large',
        'input-not-empty': !isEmpty,
        'is-full-width': fullWidth,
        large: size === 'large',
        'is-textarea': type === 'textarea',
        'is-style-minimal': style === 'minimal',
        'is-style-borderless': style === 'borderless',
        'has-icon': icon,
        'is-clearable': clearable,
        'is-passive-loading': passiveLoading
    }"
>
    <div class="input-wrapper">
        <div class="input-focus-glow"></div>
        <up-icon class="icon" *ngIf="icon" [name]="icon" [color]="iconColor"> </up-icon>
        <input
            *ngIf="type !== 'textarea'"
            #element
            [upDisableInputMouseScroll]="type === 'number'"
            [autofocus]="autofocus"
            [attr.name]="name"
            [attr.autocomplete]="autocomplete"
            [attr.maxlength]="maxlength"
            [id]="uid"
            [value]="value || ''"
            (input)="onInput($event)"
            (blur)="onInputBlur()"
            (focus)="onInputFocus()"
            [type]="type"
            [disabled]="disabled"
            class="input"
            [placeholder]="internalLabel ? '' : placeholder"
        />
        <div class="trailing">
            <up-spinner class="passive-loader" [size]="'small'"> </up-spinner>
            <button type="button" *ngIf="clearable && !disabled" (click)="clear()" class="clearer">
                <span class="u-hide-visually">Clear text</span>
                <up-icon [size]="'small'" [color]="'currentColor'" [name]="'crossBold'"></up-icon>
            </button>
        </div>

        <label
            [ngClass]="{
                'form-label u-text-body--rg u-padding-bottom--sm u-padding-top--sm': true,
                'u-hide-visually': hideLabel
            }"
            [for]="uid"
        >
            {{ label }}
        </label>

        <textarea
            *ngIf="type === 'textarea'"
            #element
            [autofocus]="autofocus"
            [attr.maxlength]="maxlength"
            [cols]="cols"
            [rows]="rows"
            [id]="uid"
            [disabled]="disabled"
            (input)="onInput($event)"
            (blur)="onInputBlur()"
            (focus)="onInputFocus()"
            class="input input--textarea"
            [placeholder]="internalLabel || !placeholder ? '' : placeholder"
        ></textarea>
    </div>
</div>
