import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { replay } from '../../../operators/replay/replay.operator';
import { GetRatemyagentStatsUseCase } from '../../use-cases/ratemyagent/get-ratemyagent-stats/get-ratemyagent-stats.use-case';

@Component({
    selector: 'up-reviews-showcase',
    templateUrl: 'reviews-showcase.component.html',
    styleUrls: ['reviews-showcase.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsShowcaseComponent implements OnInit {
    public ratemyagentRating$: Observable<number>;
    public ratemyagentCount = 900;
    public googleReviewsRating = 4.9;
    public googleReviewsCount = 500;

    constructor(private getRatemyagentStatsUseCase: GetRatemyagentStatsUseCase) {}

    public ngOnInit(): void {
        this.ratemyagentRating$ = this.getRatemyagentStatsUseCase.execute().pipe(
            map(({ rating }) => rating),
            replay({ resetOnRefCountZero: false }),
        );
    }
}
