import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiSuccessResponse } from '../../../common/models/common.model';
import { AgencyDto } from '../../../common/models/dto/agency/agency.dto';
import { CreateUpdateAgencyDto } from '../../../common/models/dto/agency/create-update-agency.dto';

@Injectable()
export class AgencyResource {
    private readonly baseUrl = `/api/v2/agencies`;

    constructor(private http: HttpClient) {}

    public all(businessId?: string): Observable<AgencyDto[]> {
        return this.http.get<AgencyDto[]>(this.baseUrl, {
            params: { ...(businessId && { businessId }) },
        });
    }

    public create(agency: CreateUpdateAgencyDto): Observable<AgencyDto> {
        return this.http.post<AgencyDto>(`${this.baseUrl}/create`, agency);
    }

    public update(agencyId: string, body: CreateUpdateAgencyDto): Observable<AgencyDto> {
        return this.http.put<AgencyDto>(`${this.baseUrl}/${agencyId}`, body);
    }

    public triggerVaultSync(agencyId: string): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(`${this.baseUrl}/${agencyId}/vault/trigger-import`, undefined);
    }

    public get(agencyId: string): Observable<AgencyDto> {
        return this.http.get<AgencyDto>(`${this.baseUrl}/${agencyId}`);
    }
}
