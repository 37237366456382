import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PropertyType } from '../../../common/models/property.model';

type OnChangeFunction = (value: PropertyType[]) => void;

@Component({
    selector: 'up-property-type-selector',
    templateUrl: 'property-type-selector.component.html',
    styleUrls: ['property-type-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // Needed for Control Value Accessor
            // eslint-disable-next-line @angular-eslint/no-forward-ref
            useExisting: forwardRef(() => PropertyTypeSelectorComponent),
            multi: true,
        },
    ],
})
export class PropertyTypeSelectorComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() public error: boolean;
    public form: FormGroup;
    public anyControl: FormControl;
    public propertyTypes: PropertyType[];
    private destroy$ = new Subject<void>();
    private isSettingAnyToggle: boolean;
    private onChange: OnChangeFunction;

    constructor(private formBuilder: FormBuilder) {
        this.propertyTypes = <PropertyType[]>Object.keys(PropertyType);
    }

    public ngOnInit(): void {
        this.createForm();
        this.createAndHandleAnyControl();
    }

    public writeValue(propertyTypes: PropertyType[]): void {
        propertyTypes.forEach(propertyType => this.form.get(propertyType).setValue(true));
        if (propertyTypes.length === this.propertyTypes.length) {
            this.anyControl.setValue(true);
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public prettifyPropertyTypes(propertyType: PropertyType): string {
        return propertyType === PropertyType.SemiDetached ? 'Semi-detached' : propertyType;
    }

    public registerOnChange(fn: OnChangeFunction): void {
        this.onChange = fn;
    }

    public registerOnTouched(): void {}

    private createForm(): void {
        this.form = this.formBuilder.group({});
        // Create all types of properties
        this.propertyTypes.forEach(propertyType => this.form.addControl(propertyType, new FormControl()));
        this.form.valueChanges.subscribe(value => {
            // onChanges doesn't exist on ngOnInit
            this.onChange && this.onChange((<PropertyType[]>Object.keys(value)).filter(type => value[type]));
        });
    }

    private createAndHandleAnyControl(): void {
        this.anyControl = new FormControl();

        // Toggle all properties when "any" type is changed
        this.anyControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
            // Flag to ensure that when value of any toggle changes, it doesn't concurrently run the subscription
            // for form changes below
            if (this.isSettingAnyToggle) return;
            this.isSettingAnyToggle = true;
            this.propertyTypes.forEach(propertyType => this.form.get(propertyType).setValue(value));
            this.isSettingAnyToggle = false;
        });

        // Handles setting and unsetting "any" toggle when values change
        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(propertyTypesValues => {
            const propertyTypeAsArray = Object.keys(propertyTypesValues);
            if (this.isSettingAnyToggle) return;
            this.isSettingAnyToggle = true;
            const propertyTypeCount = propertyTypeAsArray.length;
            const selectedPropertyTypeCount = propertyTypeAsArray.filter(
                propertyType => propertyTypesValues[propertyType],
            ).length;
            this.anyControl.setValue(propertyTypeCount === selectedPropertyTypeCount);
            this.isSettingAnyToggle = false;
        });
    }
}
