import { InjectionToken } from '@angular/core';

export interface DropdownItemData {
    value: string;
    content: string;
}

export interface DropdownItemsData {
    options: DropdownItemData[];
    selectOption: (DropdownItemData) => void;
    selected: DropdownItemData;
    cancel: () => void;
    minWidth: number;
}

export const DROPDOWN_ITEMS_DATA = new InjectionToken<DropdownItemsData>('DROPDOWN_ITEMS_DATA');
