import { NgModule } from '@angular/core';

import { MetricGridListItemComponent } from './metric-grid-list-item/metric-grid-list-item.component';
import { MetricGridListComponent } from './metric-grid-list.component';

@NgModule({
    exports: [MetricGridListComponent, MetricGridListItemComponent],
    declarations: [MetricGridListComponent, MetricGridListItemComponent],
})
export class MetricGridListModule {}
