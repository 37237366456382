import { PercentPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ColorConstants, UP_COLOR_CONSTANTS } from '../../common/constants/colors.constants';
import {
    FirebaseAppInjection,
    FirebaseAppInjectionToken,
} from '../../common/injection-tokens/firebase-app.injection-token';
import {
    FirebaseRemoteConfigInjection,
    FirebaseRemoteConfigInjectionToken,
} from '../../common/injection-tokens/firebase-remote-config.injection-token';
import { SentryLib, SentryToken } from '../../common/injection-tokens/sentry.injection-token';
import { PrettyAppointmentTypePipe } from '../../common/pipes/pretty-appointment-type.pipe';
import { SentenceCasePipe } from '../../common/pipes/sentence-case.pipe';
import { AddressFinderService } from '../../common/services/address-finder.service';
import { AddressService } from '../../common/services/address.service';
import { AutofillMonitorService } from '../../common/services/autofill-monitor.service';
import { ExperimentService } from '../../common/services/experiment.service';
import { HistoryService } from '../../common/services/history.service';
import { ListingService } from '../../common/services/listing.service';
import { LocationService } from '../../common/services/location.service';
import { MarketPerformanceService } from '../../common/services/market-performance.service';
import { NavService } from '../../common/services/nav.service';
import { StorageService } from '../../common/services/storage.service';
import { TrackingService } from '../../common/services/tracking.service';
import { TwillioService } from '../../common/services/twillio.service';
import { UserNotificationsService } from '../../common/services/user-notifications.service';
import { UserService } from '../../common/services/user.service';
import { UtmService } from '../../common/services/utm.service';
import { CustomQueryEncoder } from '../../encoders/custom-query.encoder';

import { AgencyData } from './data/agency/agency.data';
import { ActivityResource } from './resources/activity.resource';
import { AddressFinderResource } from './resources/address-finder.resource';
import { AgencyResource } from './resources/agency.resource';
import { AgentResource } from './resources/agent.resource';
import { AppraisalResource } from './resources/appraisal.resource';
import { AutopilotResource } from './resources/autopilot.resource';
import { BootstrapResource } from './resources/bootstrap.resource';
import { BusinessContactResource } from './resources/business-contact.resource';
import { BuyerResource } from './resources/buyer.resource';
import { ConciergeResource } from './resources/concierge.resource';
import { ContactMergeResource } from './resources/contact-merge.resource';
import { CoreLogicResource } from './resources/core-logic.resource';
import { DemonstrationResource } from './resources/demonstration.resource';
import { DomainOAuthResource } from './resources/domain-oauth.resource';
import { EnvironmentResource } from './resources/environment.resource';
import { EventResource } from './resources/event.resource';
import { ExperimentsResource } from './resources/experiments.resource';
import { FirebaseResource } from './resources/firebase.resource';
import { InteractionResource } from './resources/interaction.resource';
import { LeadResource } from './resources/lead.resource';
import { ListingResource } from './resources/listing.resource';
import { MatchedLeadsResource } from './resources/matched-leads.resource';
import { ProductsResource } from './resources/products.resource';
import { PropertyAdditionalOwnersResource } from './resources/property-additional-owners.resource';
import { PropertyPricingPlanResource } from './resources/property-pricing-plan.resource';
import { PropertyReferralResource } from './resources/property-referral.resource';
import { PropertyReportResource } from './resources/property-report.resource';
import { PropertyResource } from './resources/property.resource';
import { PurchaseResource } from './resources/purchase.resource';
import { QuestionnaireResource } from './resources/questionnaire.resource';
import { ReaXmlResource } from './resources/rea-xml.resource';
import { ReminderResource } from './resources/reminder.resource';
import { SearchResource } from './resources/search.resource';
import { SettingsResource } from './resources/settings.resource';
import { SparkResource } from './resources/spark.resource';
import { StatsResource } from './resources/stats.resource';
import { StorageResource } from './resources/storage.resource';
import { SuburbResource } from './resources/suburb.resource';
import { TaskResource } from './resources/task.resource';
import { TeamsResource } from './resources/teams.resource';
import { TodoResource } from './resources/todo.resource';
import { TransactionResource } from './resources/transaction.resource';
import { UserResource } from './resources/user.resource';
import { VendorResource } from './resources/vendor.resource';
import { AppPlatformService } from './services/app-platform.service';
import { CanonicalService } from './services/canonical.service';
import { CoalescingComponentFactoryResolver } from './services/coalescing-component-factory-resolver.service';
import { ConversionService } from './services/conversion/conversion.service';
import { EnvironmentService } from './services/environment.service';
import { FeatureService } from './services/feature.service';
import { GalleryService } from './services/gallery.service';
import { GlobalProgressBarService } from './services/global-progress-bar.service';
import { GoogleTagManagerService } from './services/google-tag-manager.service';
import { KeyboardUserHelperService } from './services/keyboard-user-helper.service';
import { MediaService } from './services/media.service';
import { NotificationService } from './services/notification.service';
import { PerformanceService } from './services/performance.service';
import { PortalsService } from './services/portals.service';
import { ScriptLoaderService } from './services/script-loader.service';
import { ScrollBlockService } from './services/scroll-block.service';
import { ScrollToService } from './services/scroll-to.service';
import { SearchService } from './services/search.service';
import { ThemeService } from './services/theme.service';
import { TimeZoneService } from './services/time-zone.service';
import { TitleService } from './services/title.service';
import { TransferStateHelperService } from './services/transfer-state-helper.service';

@NgModule()
export class CoreModule {
    // http://angularfirst.com/the-ngmodule-forroot-convention/
    public static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                UserService,
                NavService,
                HistoryService,
                EnvironmentService,
                ExperimentService,
                AddressService,
                BuyerResource,
                UserNotificationsService,
                ActivityResource,
                AgencyResource,
                AgentResource,
                AppraisalResource,
                AutopilotResource,
                BootstrapResource,
                BuyerResource,
                CoreLogicResource,
                DomainOAuthResource,
                EnvironmentResource,
                EventResource,
                ExperimentsResource,
                LeadResource,
                ListingResource,
                ProductsResource,
                PropertyResource,
                PropertyReportResource,
                PurchaseResource,
                QuestionnaireResource,
                ReaXmlResource,
                ReminderResource,
                SearchResource,
                StatsResource,
                SuburbResource,
                TaskResource,
                UserResource,
                VendorResource,
                TwillioService,
                LocationService,
                MarketPerformanceService,
                MediaService,
                StorageResource,
                AutofillMonitorService,
                GlobalProgressBarService,
                { provide: UP_COLOR_CONSTANTS, useValue: ColorConstants },
                ThemeService,
                StorageService,
                CustomQueryEncoder,
                PercentPipe,
                GoogleTagManagerService,
                KeyboardUserHelperService,
                TodoResource,
                TitleService,
                ListingService,
                ScrollBlockService,
                GalleryService,
                { provide: SentryToken, useValue: SentryLib },
                AppPlatformService,
                PropertyPricingPlanResource,
                PropertyReferralResource,
                CoalescingComponentFactoryResolver,
                PerformanceService,
                TimeZoneService,
                ScriptLoaderService,
                TransactionResource,
                NotificationService,
                ScrollToService,
                CanonicalService,
                TransferStateHelperService,
                UtmService,
                InteractionResource,
                PortalsService,
                SentenceCasePipe,
                PrettyAppointmentTypePipe,
                FeatureService,
                TeamsResource,
                TrackingService,
                SearchService,
                FirebaseResource,
                AddressFinderResource,
                AddressFinderService,
                SettingsResource,
                MatchedLeadsResource,
                ConversionService,
                ContactMergeResource,
                ConciergeResource,
                SparkResource,
                PropertyAdditionalOwnersResource,
                BusinessContactResource,
                DemonstrationResource,
                AgencyData,
                { provide: FirebaseAppInjectionToken, useValue: FirebaseAppInjection },
                { provide: FirebaseRemoteConfigInjectionToken, useValue: FirebaseRemoteConfigInjection },
            ],
        };
    }
}
