import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { DotSize } from '../timeline/timeline.component';

@Component({
    selector: 'up-timeline-dot',
    templateUrl: 'timeline-dot.component.html',
    styleUrls: ['timeline-dot.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineDotComponent {
    @Input() public borderColor: string;
    @Input() public fillColor: string;
    @Input() public dotSize: DotSize;

    @HostBinding('class.is-dot-size-small')
    public get isDotSizeSmall(): boolean {
        return this.dotSize === 'small';
    }
}
