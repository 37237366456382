import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

// TODO: consider disabling this in non-E2E test environments
@Directive({
    selector: '[ncTestId]',
    standalone: true,
})
export class TestIdDirective implements AfterViewInit {
    @Input('ncTestId') public testId: string;
    private readonly testIdAttribute = 'data-test-id';

    constructor(private readonly elementRef: ElementRef<HTMLElement>, private readonly renderer2: Renderer2) {}

    public ngAfterViewInit(): void {
        this.renderer2.setAttribute(this.elementRef.nativeElement, this.testIdAttribute, this.testId);
    }
}
