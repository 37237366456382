import { Inject, Injectable } from '@angular/core';

import {
    QrcodeGeneratorFactory,
    QrcodeGeneratorFactoryToken,
} from '../../../common/injection-tokens/qrcode-generator.injection-token';

interface Config {
    errorCorrectionLevel?: ErrorCorrectionLevel;
    cellSize: number;
    margin: number;
}

@Injectable()
export class QrCodeGeneratorService {
    constructor(@Inject(QrcodeGeneratorFactoryToken) private readonly qrcodeGeneratorFactory: QrcodeGeneratorFactory) {}

    public generateDataUrlFor(link: string, config: Config): string | undefined {
        if (!link) return;

        const qr = this.qrcodeGeneratorFactory(0, config.errorCorrectionLevel || 'M');

        qr.addData(link);
        qr.make();

        return qr.createDataURL(config.cellSize, config.margin);
    }
}
