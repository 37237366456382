<div class="wrapper">
    <button (click)="onClickDismissModal()" class="close-button" aria-label="Close modal">
        <nc-icon [name]="'crossBold'" [color]="'currentColor'"></nc-icon>
    </button>
    <div *ngIf="isInitialising" class="u-text-align--center u-padding-vertical--7xl">
        <nc-spinner [size]="'large'"></nc-spinner>
    </div>
    <div class="modal-content-wrapper" [hidden]="isInitialising">
        <ng-container *ngIf="headerRef; else defaultHeader">
            <ng-container *ngTemplateOutlet="headerRef"></ng-container>
        </ng-container>
        <ng-template #defaultHeader>
            <nc-modal-header *ngIf="heading">
                <h2 nc-modal-heading>{{ heading }}</h2>
                <ng-container slot="subheading">
                    <ng-content select="[slot='subheading']"></ng-content>
                </ng-container>
            </nc-modal-header>
        </ng-template>
        <ng-content></ng-content>
        <ng-content select="nc-modal-content"></ng-content>
        <ng-content select="nc-modal-footer"></ng-content>
    </div>
</div>
