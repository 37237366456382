import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';

import { CollapserPanelComponent } from '../../../collapser-panel/components/collapser-panel/collapser-panel.component';
import { Appearance } from '../accordion/accordion.component';

export type AccordionPanelState = 'collapsed' | 'expanded';

@Component({
    selector: 'up-accordion-panel, nc-accordion-panel',
    templateUrl: 'accordion-panel.component.html',
    styleUrls: ['accordion-panel.component.scss'],
})
export class AccordionPanelComponent {
    @ViewChild(CollapserPanelComponent, { static: false }) public collapserPanel: CollapserPanelComponent;
    @Output() public stateChange = new EventEmitter<AccordionPanelState>();
    @Input() public isDisabled = false;
    public state: AccordionPanelState = 'collapsed';
    public _appearance: Appearance;

    public set appearance(appearance: Appearance) {
        this._appearance = appearance;
        this.changeDetectorRef.detectChanges();
    }

    @HostBinding('class.is-appearance-grey-header')
    public get isAppearanceGreyHeader(): boolean {
        return this._appearance === 'grey-header';
    }

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

    public onStateChange(state: AccordionPanelState): void {
        this.state = state;
        this.stateChange.emit(state);
    }

    public closePanel(): void {
        if (!this.collapserPanel) return;
        this.collapserPanel.closePanel();
    }

    public openPanel(): void {
        if (!this.collapserPanel) return;
        this.collapserPanel.openPanel();
    }
}
