import { Injector } from '@angular/core';
import { UIRouter } from '@uirouter/angular';

import { GlobalProgressBarService } from '../../modules/core/services/global-progress-bar.service';

export const globalProgressBarUiRouterHooks = (router: UIRouter, injector: Injector) => {
    router.transitionService.onSuccess({}, transition => {
        const globalProgressBarService = injector.get(GlobalProgressBarService);
        // Support declaration of progress from data object or resolver, where the resolver can be used to perform
        // more complex progress calculations for routes where experiments can affect the progress
        if (transition.$to().data && transition.$to().data.globalProgress) {
            const progress: number = transition.$to().data.globalProgress;
            globalProgressBarService.updateProgress(progress);
        } else {
            // Trying to inject/get a nonexistent resolver will result in an error being thrown, so we need to try
            // catch it
            try {
                const progress: number = transition.injector().get('globalProgress');
                globalProgressBarService.updateProgress(progress);
            } catch (error) {
                globalProgressBarService.updateProgress(0);
            }
        }
    });
};
