<nc-card *transloco="let t">
    <ng-container *ngIf="loading === false; else loadingPlaceholder">
        <div class="main">
            <div class="u-margin-bottom--lg controls">
                <nc-tag
                    [theme]="'solid'"
                    [style.color]="'accent1' | upColor : 'cssVar'"
                    class="u-text-transform--uppercase"
                >
                    {{ isForSale ? t('generic.forSale') : t('generic.sold') }}
                </nc-tag>
                <div class="controls-images-links">
                    <ng-container *ngTemplateOutlet="viewImagesLinks"></ng-container>
                </div>
            </div>
            <div class="address">
                <nc-split-formatted-address [formattedAddress]="formattedAddress"></nc-split-formatted-address>
            </div>
            <div class="meta u-margin-bottom--md u-text-body--sm">
                <up-property-attributes
                    class="u-display--inline-block"
                    [bedrooms]="bedrooms"
                    [bathrooms]="bathrooms"
                    [parking]="parking"
                    [landSize]="landSize"
                    [labelStyle]="'text'"
                    [size]="'small'"
                    [propertyType]="propertyType | upPrettyPropertyType"
                >
                </up-property-attributes>

                <div *ngIf="auctionDateTime as auction">
                    <span class="meta-divider">|</span>
                    <span>
                        Auction, <nc-date-time [dateTime]="auction" [format]="'dateLongTimeShort'"></nc-date-time>
                    </span>
                </div>
            </div>
            <div *ngIf="isForSale" class="u-text-body--lg u-text-weight--semi-bold">
                {{ price }}
            </div>
        </div>

        <div class="secondary">
            <ng-container *ngTemplateOutlet="viewImagesLinks"></ng-container>
        </div>

        <ng-template #viewImagesLinks>
            <div class="u-text-body--sm">
                <button nc-link class="u-margin-right--md" (click)="onOpenGallery()"> View all photos </button>
                <button *ngIf="hasFloorPlan" nc-link (click)="onOpenFloorPlan()"> View floorplan </button>
            </div>
        </ng-template>
    </ng-container>
</nc-card>

<ng-template #loadingPlaceholder>
    <div class="main">
        <div class="u-margin-bottom--lg controls">
            <up-placeholder class="hero-image" [width]="'110px'" [height]="'10px'"></up-placeholder>
            <div class="controls-images-links">
                <ng-container *ngTemplateOutlet="viewImagesLinks"></ng-container>
            </div>
        </div>
        <div class="address u-margin-bottom--sm">
            <up-placeholder class="u-margin-bottom--sm" [width]="'300px'" [height]="'36px'"></up-placeholder>
            <up-placeholder [width]="'280px'" [height]="'36px'"></up-placeholder>
        </div>
        <up-placeholder class="u-margin-bottom--lg" [width]="'230px'" [height]="'20px'"></up-placeholder>
        <up-placeholder [width]="'230px'" [height]="'18px'"></up-placeholder>
    </div>

    <div class="secondary">
        <ng-container *ngTemplateOutlet="viewImagesLinks"></ng-container>
    </div>

    <ng-template #viewImagesLinks>
        <div class="u-text-label--sm">
            <up-placeholder
                class="u-margin-right--md u-display--inline-block"
                [width]="'88px'"
                [height]="'18px'"
            ></up-placeholder>
            <up-placeholder class="u-display--inline-block" [width]="'88px'" [height]="'10px'"></up-placeholder>
        </div>
    </ng-template>
</ng-template>
