import { InjectionToken } from '@angular/core';
import { getRemoteConfig, fetchConfig, getAll, activate } from 'firebase/remote-config';

export interface FirebaseRemoteConfigInjection {
    getRemoteConfig: typeof getRemoteConfig;
    fetchConfig: typeof fetchConfig;
    activate: typeof activate;
    getAll: typeof getAll;
}

export const FirebaseRemoteConfigInjection = {
    getRemoteConfig,
    fetchConfig,
    getAll,
    activate,
};
export const FirebaseRemoteConfigInjectionToken = new InjectionToken<FirebaseRemoteConfigInjection>(
    'FirebaseRemoteConfigInjection',
);
