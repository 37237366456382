<up-isolated-container [showLogo]="true">
    <ng-container heading> Check your email </ng-container>
    <div>
        <div class="u-margin-bottom--2xl">
            <up-icon [name]="'person'" [color]="'currentColor'"></up-icon>
            {{ email }}
        </div>
        <div [ngSwitch]="mode" class="u-margin-bottom--sm">
            <img
                *ngSwitchCase="'shadow-sign-up'"
                width="83"
                height="33"
                [src]="'/static/images/components/check-email/email-sent.svg' | upCdnUrl"
                alt=""
            />
            <img
                *ngSwitchCase="'reset-password'"
                width="83"
                height="44"
                [src]="'/static/images/components/check-email/contact.svg' | upCdnUrl"
                alt=""
            />
        </div>
        <div [ngSwitch]="mode">
            <ng-container *ngSwitchCase="'shadow-sign-up'">
                <strong>Your account doesn't have a password yet.</strong> We've just sent you an email with a link to
                create one and sign in.
            </ng-container>
            <ng-container *ngSwitchCase="'reset-password'">
                <strong>We've just sent you an email with a link to reset your password.</strong> Get in touch with us
                if you continue to have issues signing in.
                <div *upFeature="'upsideMarketingPagesEnabled'" class="u-margin-top--sm">
                    <up-link [href]="'tel:1800877433'">Call us 1800 877 433</up-link>
                </div>
            </ng-container>
        </div>
    </div>
    <ng-container footer> <up-auth-footer></up-auth-footer> </ng-container>
</up-isolated-container>
