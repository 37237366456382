import { Component, Input } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { finalize } from 'rxjs/operators';

import { RequestCallBackFormData } from '../../../../common/components/request-call-back-form/request-call-back-form.component';
import { agent } from '../../../../common/models/agent.model';
import { TrackingService } from '../../../../common/services/tracking.service';
import { AutopilotResource } from '../../../../modules/core/resources/autopilot.resource';

@Component({
    selector: 'up-request-call',
    templateUrl: './request-call.component.html',
    styleUrls: ['./request-call.component.scss'],
})
export class RequestCallComponent {
    @Input() public agent: agent.SimpleAgent;
    public loading = false;
    public error: boolean;
    public hasAttemptedFormSubmit: boolean;
    private readonly defaultTrackingEvent = 'callbackRequestSubmitted';

    private get trackingEvent(): string | undefined {
        return this.stateService.params['trackingEvent'] || this.defaultTrackingEvent;
    }

    constructor(
        private autopilotResource: AutopilotResource,
        private stateService: StateService,
        private trackingService: TrackingService,
    ) {}

    public onFormSubmit(formData: RequestCallBackFormData): void {
        this.error = false;
        this.loading = true;

        this.trackingService.pushEventToDataLayer(this.trackingEvent);
        this.trackingService.trackDataEvent(this.trackingEvent, {
            data: JSON.stringify(formData),
        });

        this.autopilotResource
            .requestCall(formData)
            .pipe(
                finalize(() => {
                    this.hasAttemptedFormSubmit = false;
                    this.loading = false;
                }),
            )
            .subscribe(
                () => {
                    this.trackingService.trackEvent('callBackRequested');
                    this.stateService.go('request-call.complete');
                },
                () => (this.error = true),
            );
    }
}
