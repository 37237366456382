<div class="l-page-section c-hero hero">
    <div class="l-page-section__inner"> <h1 class="c-hero__heading">Privacy Policy</h1> </div>
</div>
<div class="l-page-section">
    <div class="l-page-section__inner l-padding-ends--medium">
        <h3 class="page-subheading">Upside Privacy Policy</h3>
        <p
            >Upside Realty Pty Ltd (ACN 619 316 602) and its subsidiaries (‘<strong>Upside</strong>’) acknowledge and
            respect the privacy of individuals. Personal information (‘<strong>Personal Information</strong>’) is
            information or an opinion about an identified, or reasonably identifiable, individual within the meaning of
            the Privacy Act 1988 (Cth) (‘<strong>Privacy Act</strong>’) as amended from time to time. Our Privacy Policy
            (‘<strong>Policy</strong>’) explains how we collect, use, disclose, retain and manage Personal Information
            in the course of our business, including but not limited to when you use Upside services or visit or
            interact with any Upside websites, online portals, applications, social media accounts and any other
            platform we use to deliver our services (collectively, ‘<strong>Upside Platforms</strong>’).</p
        >
        <p
            >Upside is committed to complying with the Privacy Act. It is your choice whether to provide us with your
            Personal Information however, if you choose not to, or where the Personal Information provided to us is
            inaccurate, we may be limited in our ability to engage with you, and provide you with access to our
            services.</p
        >
        <p
            >We reserve the right to make changes or updates to this Policy from time to time. Should we do so, we will
            update the Policy on our website located at
            <a href="http://www.upside.com.au">www.upside.com.au</a> (‘<strong>Website</strong>’). Please check the
            Website regularly to stay informed of any updates.</p
        >
        <p
            >Please read this Policy carefully. By using the Upside Platforms and any services we offer, you are
            agreeing to be bound by this Policy in respect of the Personal Information collected about you.</p
        >
        <h3 class="page-subheading l-margin-top">Collecting your personal information</h3>
        <p
            >Upside collects Personal Information from various individuals, including but not limited to vendors and
            purchasers, prospective vendors and purchasers, real estate agents and agencies, auctioneers, conveyancers
            and other specialist Upside and third party service providers.</p
        >
        <p
            >During the provision of Upside’s services, the types of Personal Information we collect from you will
            depend on the services you request. Personal Information will normally be collected directly from you
            through written and verbal communication. We generally collect Personal Information when you visit, use,
            register or interact with an account on the Upside Platforms, complete Upside forms, meet with Upside
            representatives either in person or digitally, participate in surveys, feedback, interviews, questionnaires
            or other market research activities, submit queries, interact with our advertisements, data and content,
            interact and follow our social media accounts, enter into a competition or promotion, or otherwise provide
            us with Personal Information in person or via telephone, email, fax, post or other means.</p
        >
        <p>Generally, the kinds of Personal Information we collect may include:</p>
        <ul class="page-list">
            <li
                >contact and identification details (e.g. name, addresses, email address, phone number, gender, date of
                birth, licence details);</li
            >
            <li>banking and payment details;</li>
            <li>information about your property;</li>
            <li
                >contextual information about your personal/family circumstances gained in your interactions with Upside
                representatives and specialists;</li
            >
            <li
                >information about your usage of Upside Platforms, including without limitation passive Information and
                cookies (as detailed below);</li
            >
            <li>any results of relevant forms, feedback or competitions in which you participate; or</li>
            <li>other information relevant in the circumstances.</li>
        </ul>
        <p
            >Sometimes we might collect your Personal Information from other sources such as government departments,
            internet service providers, publicly maintained records, including records publicly available through social
            media, or other third parties. Generally, we only collect your Personal Information in this way if it is
            unreasonable or impracticable to collect it from you directly, or if those sources are authorised to do
            so.</p
        >
        <h4 class="page-subheading-regular l-margin-top">Passive Information and cookies</h4>
        <p
            >When you visit or use Upside Platforms, we may passively collect certain information and other anonymised
            data using various technologies such as first and third party cookies and identifiers, internet tags, web
            beacons, and navigational data collection methods, including but not limited to, log files, server logs,
            clickstream and Google Analytics Advertising Features (Remarketing with Google Analytics, DoubleClick
            Platform integrations, and Google Analytics Demographics and Interest reporting) (together, ‘<strong
                >Passive Information</strong
            >’). Passive Information is not Personal Information. From time to time Upside may also collect from and
            share Passive Information with third parties.</p
        >
        <p
            >Passive Information is collected to help us improve our services, and for statistical and marketing
            purposes. Passive Information can include the type of browser and system you are using, whether you have
            interacted with any advertisements or links, the address of our websites you have come from and move to
            after your visit, the date and time of your visit and your server’s Internet Protocol (IP) address.</p
        >
        <p
            >You can set your browser to accept or reject all cookies, or to notify you whenever cookies are offered so
            that you can decide each time whether to accept them. For further help on how to opt-out of the use of your
            Passive Information by DoubleClick visit
            <a href="http://www.google.com/ads/preferences/?hl=en">http://www.google.com/ads/preferences/?hl=en</a>, and
            similarly for how to opt-out of Google Analytics visit
            <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.</p
        >
        <p
            >Should you reject all cookies, your ability to use Upside Platforms or services may be limited as certain
            features may not function correctly.</p
        >
        <h3 class="page-subheading l-margin-top">Our use and disclosure of personal information</h3>
        <h4 class="page-subheading-regular l-margin-top">Use</h4>
        <p
            >Our primary purpose for collecting, holding, and using your Personal Information is to provide you with the
            best possible experience with the Upside Platforms and services. Specific primary purposes include, but are
            not limited to:</p
        >
        <ul class="page-list">
            <li>confirming your identity and communicating with you;</li>
            <li
                >providing or assisting you with the Upside services you request, including those of Upside specialists
                and customer experience team;</li
            >
            <li>making improvements to the Upside Platforms and services;</li>
            <li
                >using marketing or direct marketing to inform you of products, services and events that may be relevant
                to you, including those provided by Upside or a third party;</li
            >
            <li>collecting information relating to quality and compliance issues; and</li>
            <li>satisfying statutory legal obligations.</li>
        </ul>
        <p>For any of the above purposes we may contact you by electronic or other means.</p>
        <p
            >Notwithstanding, we may use Personal Information for purposes related to the primary purposes above, and as
            permitted or required by law.</p
        >
        <h4 class="page-subheading-regular l-margin-top">Disclosure</h4>
        <p
            >We disclose your Personal Information for the same purposes for which we collect it. That is, generally, we
            will only disclose your Personal Information for a purpose related to the provision, improvement and
            analysis of the Upside Platforms and services, if we have obtained your consent, or otherwise as set out in
            this Policy. This may include disclosing Personal Information to:</p
        >
        <ul class="page-list">
            <li>Upside representatives and specialists;</li>
            <li>Upside’s insurers and professional advisors;</li>
            <li
                >any contractors, suppliers or any other third parties engaged by Upside to perform functions or
                activities on our behalf, or assist us in providing you with Upside services, including without
                limitation, valuing, appraising, marketing, photographing and other media services, auctioning,
                conveyancing, listing services in connection with property sales, and/or direct marketing; or</li
            >
            <li
                >Upside partners who offer a service for which you have requested more information, and are not covered
                by the Upside platform (such as Finance). Should you request more information from one of Upside's
                partner a commission may be paid to Upside; or
            </li>
            <li
                >any entity with which Upside merges or combines, or to which Upside divests or sells, part of its
                business.</li
            >
        </ul>
        <p
            >These disclosures are done on a confidential basis or otherwise in accordance with law. You should note
            that third parties may also collect, hold, use and disclose your Personal Information in accordance with
            their own privacy policies and procedures and these may be different from our own.</p
        >
        <h4 class="page-subheading-regular l-margin-top">Overseas Disclosure</h4>
        <p
            >We may disclose Personal Information to overseas recipients for the same purposes for which we collect it.
            In respect of hosting services, these recipients are located in Japan and the United States of America, but
            may also be located in other countries. Our contracts with these parties include an obligation for them to
            comply with an applicable law or binding scheme which effectively upholds principles for fair handling of
            Personal Information that are substantially to the Australian Privacy Principles.</p
        >
        <p
            >We will take all reasonable steps necessary to ensure transferred Personal Information is kept secure as
            required by applicable data privacy laws. However, you acknowledge that, by agreeing to the disclosure of
            your Personal Information to these entities outside of Australia, we will no longer be required to take
            reasonable steps to ensure the overseas recipient’s compliance with the Australian privacy law in relation
            to your Personal Information and we will not be liable to you for any breach of the Australian privacy law
            by these overseas recipients and, on this basis, you consent to such transfer, processing, storage and/or
            disclosure.</p
        >
        <h4 class="page-subheading-regular l-margin-top">Security</h4>
        <p
            >Your Upside account is protected by a password for your privacy and security. You must take care to ensure
            you protect your Personal Information (for example, by protecting your usernames and passwords) and you must
            notify us as soon as possible after you become aware of any security breaches.</p
        >
        <p
            >We will take all reasonable steps to protect the Personal Information we hold about you from unauthorised
            access, use and disclosure, however we cannot guarantee the absolute security of that Personal Information,
            or that our systems will be completely free from third party interception or are incorruptible from viruses.
            We do not guarantee that Personal Information you send from your computer to us over the Internet will be
            protected by any form of encryption (encoding software), and do not ensure or warrant the security or
            privacy of your Personal Information, including payment and account details. You transmit your Personal
            Information to us at your own risk.</p
        >
        <p
            >If we no longer require the use of your Personal Information, we will take reasonable steps to destroy or
            permanently de-identify it.</p
        >
        <h4 class="page-subheading-regular l-margin-top">Third Party Links</h4>
        <p
            >The Upside Platforms may contain links to other websites of third parties. A link to a third party website
            is not an endorsement of the accuracy or trustworthiness of that website or its data or content, and any
            information collected by such websites will be subject to that website’s privacy policy. We cannot and do
            not assume any responsibility for the privacy or security practices of any other websites that you may be
            able to access through Upside Platforms.</p
        >
        <p
            >Upside Platforms, including without limitation its social media accounts, may be accessible through or
            contain connections to areas where you may be able to publicly post information, communicate with others,
            review products and submit media content.  Prior to posting in these areas please read our Platform Terms
            and this Privacy Policy carefully.  All the information you post may be accessible to anyone with Internet
            access and any Personal Information you include in your posting may be read, collected and used by
            others.</p
        >
        <h4 class="page-subheading-regular l-margin-top">Controlling your Personal Information</h4>
        <p
            >We will allow you to access, edit, update and/or delete Personal Information that we hold about you, unless
            we are legally permitted or required to deny you to access, and/ or retain, the Personal Information, or you
            make requests that are unreasonably repetitive, require Upside to make a disproportionate technical effort
            (for example developing an entirely new system), risk the privacy of others, or would be highly impractical
            to comply with. If you wish to access, update or delete Personal Information we hold about you, you should
            contact us at <a href="mailto:privacy@upside.com.au">privacy@upside.com.au</a>.</p
        >
        <p
            >In the event you do not wish to receive marketing communications, you can opt-out through any opt-out
            mechanism contained in a marketing communication to you, or by contacting us via the contact address set out
            above. For example:</p
        >
        <ul class="page-list">
            <li
                >To stop receiving email correspondence from us, simply click on the link contained in each email to
                unsubscribe.</li
            >
            <li>To stop receiving the SMS service reply to any SMS with the word “STOP”.</li>
        </ul>
        <h4 class="page-subheading-regular l-margin-top">Consent</h4>
        <p
            >You consent to us, our agents, contractors, authorised employees and any related parties collecting,
            holding, using, disclosing and managing your Personal Information in accordance with this Policy for the
            purposes contemplated herein.</p
        >
        <p
            >Once this policy has been revised, your continued engagement of Upside Platforms, services, and the
            provision of further information to us, constitutes your acceptance of the revised policy and you consent to
            us collecting, holding, using, disclosing and managing your Personal Information in the way described in
            this Policy or permitted under the Privacy Act.</p
        >
    </div>
</div>
