import { NgModule } from '@angular/core';

import { DisclosureIndicatorModule } from '../disclosure-indicator/disclosure-indicator.module';

import { SelectListComponent } from './components/select-list/select-list.component';
import { SelectListButtonComponent } from './components/select-list-button/select-list-button.component';
import { SelectListItemComponent } from './components/select-list-item/select-list-item.component';

@NgModule({
    imports: [DisclosureIndicatorModule],
    declarations: [SelectListComponent, SelectListItemComponent, SelectListButtonComponent],
    exports: [SelectListComponent, SelectListItemComponent, SelectListButtonComponent],
})
export class SelectListModule {}
