import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Result } from '../../use-cases/get-inspection-check-in-data/get-inspection-check-in-data.use-case';

@Component({
    selector: 'nc-no-open-available',
    templateUrl: 'no-open-available.component.html',
    styleUrls: ['no-open-available.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoOpenAvailableComponent {
    @Input() public agent: Result.Agent;
}
