import { Injectable } from '@angular/core';

import { twillio } from '../models/twillio.model';

@Injectable()
export class TwillioService {
    public errorResponseHandler<T extends twillio.ResponseBase>(response: T): T {
        if (response.success) {
            return response;
        } else {
            throw response;
        }
    }
}
