import { Pipe, PipeTransform } from '@angular/core';
import { escapeRegExp } from 'lodash-es';

/**
 * Wraps all found string partials in <mark> tags.
 * Must be used with [innerHTML]
 */
@Pipe({
    name: 'ncHighlightTextPartial',
})
export class HighlightTextPartialPipe implements PipeTransform {
    public transform(value: string, partial: string): string {
        if (!partial || !value) return value;

        const regExp = new RegExp(escapeRegExp(partial), 'gi');

        return value.replace(regExp, '<mark>$&</mark>');
    }
}
