import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';

import { EnvironmentService } from '../../modules/core/services/environment.service';
import { GeoLocationDto } from '../models/dto/location/geo-location.dto';

import { ErrorHandlerService } from './error-handler.service';
import { WindowRef } from './window.service';

interface StreetViewImage {
    url: string;
    imageDate?: string;
}

@Injectable()
/** @Deprecated
 * Do not add anything to this service, needs to be migrated over to GoogleMapService
 * **/
export class StreetViewService {
    public readonly placeholderImage = '/static/images/placeholder_property.jpg';
    private readonly googleStreetViewService: google.maps.StreetViewService;
    private readonly googleLatLng: google.maps.LatLng;

    constructor(
        public windowRef: WindowRef,
        @Inject(PLATFORM_ID) private platformId: Object,
        private generalLogger: ErrorHandlerService,
        private environmentService: EnvironmentService,
    ) {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        this.logIfGoogleUndefined();
        this.googleStreetViewService =
            this.windowRef.nativeWindow.google &&
            this.windowRef.nativeWindow.google.maps &&
            new this.windowRef.nativeWindow.google.maps.StreetViewService();
        this.googleLatLng =
            this.windowRef.nativeWindow.google &&
            this.windowRef.nativeWindow.google.maps &&
            this.windowRef.nativeWindow.google.maps.LatLng;
    }

    public getStreetViewImage(location: GeoLocationDto, size = '640x640'): Observable<StreetViewImage> {
        return new Observable<StreetViewImage>(observer => {
            const baseStreetViewUrl = `https://maps.googleapis.com/maps/api/streetview?size=${size}&location=`;
            // Always show placeholder image as the real image gets fetched or if it errors out
            observer.next({ url: this.placeholderImage });
            if (isPlatformServer(this.platformId) || !this.googleLatLng || !location) {
                observer.complete();
                return;
            }
            const lat = this.parseCoordinateAsNumber(location.latitude);
            const long = this.parseCoordinateAsNumber(location.longitude);
            const target = new this.windowRef.nativeWindow.google.maps.LatLng(lat, long);
            this.googleStreetViewService.getPanorama({ location: target, radius: 50 }, (result, status) => {
                if (status === this.windowRef.nativeWindow.google.maps.StreetViewStatus.OK) {
                    const heading = this.windowRef.nativeWindow.google.maps.geometry.spherical.computeHeading(
                        result.location.latLng,
                        target,
                    );
                    const lat = result.location.latLng.lat();
                    const long = result.location.latLng.lng();
                    const googleMapsApiKey = this.environmentService.environmentFromGlobal('googleMapsApiKey');
                    const url = `${baseStreetViewUrl}${lat},${long}&heading=${heading}&key=${googleMapsApiKey}`;
                    const imageDate = result.imageDate;
                    observer.next({ url, imageDate });
                }
                observer.complete();
            });
        });
    }

    public getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        const deg2rad = deg => deg * (Math.PI / 180);
        const R = 6371; // Radius of the earth in km
        const dLat = deg2rad(lat2 - lat1); // deg2rad below
        const dLon = deg2rad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c; // Distance in km
        return d.toFixed(1);
    }

    private logIfGoogleUndefined(): void {
        if (!this.windowRef.nativeWindow.google) {
            this.generalLogger.warning('google is not defined');
        }
    }

    private parseCoordinateAsNumber(coordinate: number | string): number {
        return typeof coordinate === 'string' ? parseFloat(coordinate) : coordinate;
    }
}
