import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { AgencyResource } from '../../resources/agency.resource';

@Injectable()
export class AgencyData {
    constructor(private agencyResource: AgencyResource) {}

    public triggerVaultSync(agencyId: string): Observable<void> {
        return this.agencyResource.triggerVaultSync(agencyId).pipe(map(() => undefined));
    }
}
