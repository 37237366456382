import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environmentApi } from '../../../common/models/environment-api.model';
import { environment } from '../../../common/models/environment.model';

const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

const BASE_URL = '/api/v2/environment';

@Injectable()
export class EnvironmentResource {
    constructor(private http: HttpClient) {}

    public env(): Observable<environment.config> {
        return this.http.get<environmentApi.env.get.Response>(`${BASE_URL}`, HEADERS_CONFIG).pipe(
            map(environment => ({
                ...environment,
                organisation: {
                    ...environment.organisation,
                    featureConfiguration: this.flattenFeatureConfiguration(
                        environment.organisation.featureConfiguration,
                    ),
                },
            })),
        );
    }

    public setAppVersion(version: string) {
        return this.http.post<environmentApi.app.post.Response>(
            `${BASE_URL}/app/version/`,
            { version },
            HEADERS_CONFIG,
        );
    }

    public getAppVersion() {
        return this.http.get<environmentApi.app.get.Response>(`${BASE_URL}/app/version/`, HEADERS_CONFIG);
    }

    private flattenFeatureConfiguration(unflattened: object): environment.FeatureConfiguration {
        const convertObjToDotNotation = (obj: object, parent: string[] = [], keyValue: object = {}) => {
            Object.keys(obj).forEach(key => {
                const keyPath = [...parent, key];

                if (typeof obj[key] === 'object') {
                    Object.assign(keyValue, convertObjToDotNotation(obj[key], keyPath, keyValue));
                } else {
                    keyValue[keyPath.join('.')] = obj[key];
                }
            });

            return keyValue;
        };

        return <environment.FeatureConfiguration>convertObjToDotNotation(unflattened);
    }
}
