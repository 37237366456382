import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TrackingDirective } from './tracking.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [TrackingDirective],
    exports: [TrackingDirective],
})
export class TrackingModule {}
