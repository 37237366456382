import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { kebabCase } from 'lodash-es';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { checkInPath } from '../../common/constants/check-in.constants';
import { CheckInQr } from '../../common/models/check-in-qr.model';
import { AppraisalQuestionnaire } from '../../common/models/domain/property/appraisal-questionnaire.model';
import { phaseApi } from '../../common/models/phase-api.model';
import { PropertyAttributes } from '../../common/models/property-attributes.model';
import { Property } from '../../common/models/property.model';
import { reminder } from '../../common/models/reminder.model';
import { task } from '../../common/models/task.model';
import { todo } from '../../common/models/todo.model';
import { EnvironmentService } from '../../modules/core/services/environment.service';
import { QrCodeGeneratorService } from '../../modules/qr-code-generator/services/qr-code-generator.service';
import { replay } from '../../operators/replay/replay.operator';
import { untilDefined } from '../../operators/untilDefined.operator';
import { AppState } from '../apps-state.model';

import {
    AcquisitionData,
    internalProperty,
    ListingExpectationData,
    PricingData,
    ProspectData,
    updateHideFullAddress,
    updateHideSoldPrice,
    updateHideUnderOfferStatus,
    VendorSources,
    updateVendorSources,
    updateJustListedSmsNotificationEnabled,
    updateJustSoldNotificationEnabled,
    updateJustListedEmailNotificationEnabled,
    remove,
    RemoveData,
    unarchive,
    updateAppraisalQuestionnaire,
    updateVendorPortalEnabled,
    updateAttributes,
    updateIsContractOfSaleAlwaysVisible,
} from './internal-property.actions';
import { InternalPropertyState } from './internal-property.reducer';
import * as internalPropertySelectors from './internal-property.selectors';
import {
    loadingTodoSelector,
    selectLoadingProperty,
    selectIsUpdatingHideFullAddress,
    selectIsUpdatingHideUnderOfferStatus,
    selectIsUpdatingHideSoldPrice,
    selectErrorLoadingProperty,
    selectIsUpdatingJustSoldNotificationEnabled,
    selectIsUpdatingJustListedSmsNotificationEnabled,
    selectIsUpdatingJustListedEmailNotificationEnabled,
    selectRemoveStatus,
    selectUnarchiveStatus,
    selectAppraisalQuestionnaire,
    selectAppraisalQuestionnaireStatus,
    selectIsUpdatingVendorPortalEnabled,
    selectAttributes,
    selectAttributesStatus,
    selectIsLoadingAttributes,
    selectArchivedDocuments,
    selectUnarchivedDocuments,
    selectPropertyId,
    selectIsContractOfSaleAlwaysVisible,
    selectIsUpdatingIsContractOfSaleAlwaysVisible,
    selectHasBrochureContractOfSale,
} from './internal-property.selectors';

@Injectable()
export class InternalPropertyFacade {
    public internalPropertyState$: Observable<InternalPropertyState> = this.store.select('internalProperty');
    public property$ = this.store.select(internalPropertySelectors.propertySelector);
    public propertyId$ = this.store.select(selectPropertyId).pipe(untilDefined());
    public propertyIfAvailable$ = this.property$.pipe(filter(property => !!property));
    public timezone$ = this.propertyIfAvailable$.pipe(map(p => p.timeZone));
    public todoReminders$: Observable<reminder.SimpleReminder[]> = this.store.select(
        internalPropertySelectors.todoRemindersSelector,
    );
    public todoMeetings$: Observable<todo.Appointment[]> = this.store.select(
        internalPropertySelectors.todoMeetingsSelector,
    );
    public todoTasks$: Observable<task.TaskMinimal[]> = this.store
        .select(internalPropertySelectors.todoTasksSelector)
        .pipe(map(tasks => tasks.filter(propertyTask => propertyTask.group !== task.Group.System)));
    public loadingProperty$ = this.store.select(selectLoadingProperty);
    public loadingTodo$ = this.store.select(loadingTodoSelector);
    public isUpdatingHideFullAddress$ = this.store.select(selectIsUpdatingHideFullAddress);
    public isUpdatingHideUnderOfferStatus$ = this.store.select(selectIsUpdatingHideUnderOfferStatus);
    public isUpdatingHideSoldPrice$ = this.store.select(selectIsUpdatingHideSoldPrice);
    public errorLoadingProperty$ = this.store.select(selectErrorLoadingProperty);
    public isUpdatingJustSoldNotificationEnabled$ = this.store.select(selectIsUpdatingJustSoldNotificationEnabled);
    public isUpdatingJustListedSmsNotificationEnabled$ = this.store.select(
        selectIsUpdatingJustListedSmsNotificationEnabled,
    );
    public isUpdatingJustListedEmailNotificationEnabled$ = this.store.select(
        selectIsUpdatingJustListedEmailNotificationEnabled,
    );
    public isUpdatingVendorPortalEnabled = this.store.select(selectIsUpdatingVendorPortalEnabled);
    public removeStatus$ = this.store.select(selectRemoveStatus);
    public unarchiveStatus$ = this.store.select(selectUnarchiveStatus);
    public appraisalQuestionnaire$ = this.store.select(selectAppraisalQuestionnaire);
    public appraisalQuestionnaireStatus$ = this.store.select(selectAppraisalQuestionnaireStatus);
    public readonly attributes$ = this.store.select(selectAttributes);
    public readonly isLoadingAttributes$ = this.store.select(selectIsLoadingAttributes);
    public readonly attributesStatus$ = this.store.select(selectAttributesStatus);
    public readonly archivedDocuments$ = this.store.select(selectArchivedDocuments);
    public readonly unarchivedDocuments$ = this.store.select(selectUnarchivedDocuments);
    public readonly checkInQR$: Observable<CheckInQr> = this.propertyIfAvailable$.pipe(
        map(property => {
            if (!property.shared) {
                return undefined;
            }
            return {
                url: this.qrCodeGeneratorService.generateDataUrlFor(
                    `${this.environmentService.config.organisation.host}/${checkInPath}/${property.shared}`,
                    { cellSize: 12, margin: 12 },
                ),
                name: `${kebabCase(property.address?.formattedAddress) || 'check-in'}-qr`,
            };
        }),
        replay({ resetOnRefCountZero: false }),
    );
    public readonly isContractOfSaleAlwaysVisible$ = this.store.select(selectIsContractOfSaleAlwaysVisible);
    public readonly isUpdatingIsContractOfSaleAlwaysVisible$ = this.store.select(
        selectIsUpdatingIsContractOfSaleAlwaysVisible,
    );
    public readonly hasBrochureContractOfSale$ = this.store.select(selectHasBrochureContractOfSale);

    constructor(
        private store: Store<AppState>,
        private readonly qrCodeGeneratorService: QrCodeGeneratorService,
        private readonly environmentService: EnvironmentService,
    ) {}

    public get(propertyId: string): void {
        this.store.dispatch(new internalProperty.Get(propertyId));
    }

    public update(propertyId: string, property: Partial<Property>): void {
        this.store.dispatch(new internalProperty.Update({ propertyId, property }));
    }

    public refresh(): void {
        this.store.dispatch(new internalProperty.Refresh());
    }

    public getTodo(propertyId: string): void {
        this.store.dispatch(new internalProperty.GetTodo(propertyId));
    }

    public reset(): void {
        this.store.dispatch(new internalProperty.Reset());
    }

    public updateVendorSources(propertyId: string, vendorSources: VendorSources): void {
        this.store.dispatch(updateVendorSources({ propertyId, vendorSources }));
    }

    public updateAcquisitionData(propertyId: string, property: AcquisitionData): void {
        this.store.dispatch(new internalProperty.UpdateAcquisitionData({ propertyId, property }));
    }

    public updateListingExpectationData(propertyId: string, property: ListingExpectationData): void {
        this.store.dispatch(new internalProperty.UpdateListingExpectationData({ propertyId, property }));
    }

    public updateProspectData(propertyId: string, property: ProspectData): void {
        this.store.dispatch(new internalProperty.UpdateProspectData({ propertyId, property }));
    }

    public setPhaseInvalid(propertyId: string, request: phaseApi.invalid.post.Request): void {
        this.store.dispatch(new internalProperty.SetPhaseInvalid({ propertyId, request }));
    }

    public setPhaseLostAndSold(propertyId: string, request: phaseApi.lostAndSold.post.Request): void {
        this.store.dispatch(new internalProperty.SetPhaseLostAndSold({ propertyId, request }));
    }

    public deleteSalesAssistant(propertyId: string): void {
        this.store.dispatch(new internalProperty.DeleteSalesAssistant(propertyId));
    }

    public updatePricingData(propertyId: string, pricingData: PricingData): void {
        this.store.dispatch(new internalProperty.UpdatePricingData({ propertyId, pricingData }));
    }

    public updateHideFullAddress(propertyId: string, hideFullAddress: boolean): void {
        this.store.dispatch(updateHideFullAddress({ propertyId, hideFullAddress }));
    }

    public updateHideUnderOfferStatus(propertyId: string, hideUnderOffer: boolean): void {
        this.store.dispatch(updateHideUnderOfferStatus({ propertyId, hideUnderOffer }));
    }

    public updateHideSoldPrice(propertyId: string, hideSoldPrice: boolean): void {
        this.store.dispatch(updateHideSoldPrice({ propertyId, hideSoldPrice }));
    }

    public updateJustListedSmsNotificationEnabled(propertyId: string, enabled: boolean): void {
        this.store.dispatch(updateJustListedSmsNotificationEnabled({ propertyId, enabled }));
    }

    public updateJustSoldNotificationEnabled(propertyId: string, enabled: boolean): void {
        this.store.dispatch(updateJustSoldNotificationEnabled({ propertyId, enabled }));
    }

    public updateJustListedEmailNotificationEnabled(propertyId: string, enabled: boolean): void {
        this.store.dispatch(updateJustListedEmailNotificationEnabled({ propertyId, enabled }));
    }

    public updateVendorPortalEnabled(propertyId: string, enabled: boolean): void {
        this.store.dispatch(updateVendorPortalEnabled({ propertyId, enabled }));
    }

    public remove(data: RemoveData): void {
        this.store.dispatch(remove({ data }));
    }

    public unarchive(): void {
        this.store.dispatch(unarchive());
    }

    public updateAppraisalQuestionnaire(data: AppraisalQuestionnaire): void {
        this.store.dispatch(updateAppraisalQuestionnaire({ payload: data }));
    }

    public updateAttributes(data: PropertyAttributes): void {
        this.store.dispatch(updateAttributes({ payload: data }));
    }

    public updateIsContractOfSaleAlwaysVisible(propertyId: string, isAlwaysVisible: boolean): void {
        this.store.dispatch(updateIsContractOfSaleAlwaysVisible({ propertyId, isAlwaysVisible }));
    }
}
