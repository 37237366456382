<div class="content">
    <div class="secondary-links" *upFeature="'upsideMarketingPagesEnabled'">
        <a class="upside-logo-link" [uiSref]="'home'">
            <img alt="Upside home" [lazyLoad]="'/static/images/logo/logo_upside_navy_w_redAccent.svg' | upCdnUrl" />
        </a>
        <nc-upside-social-links
            class="u-padding-left--xl social-links"
            [trackingPrefix]="'footer'"
            *upFeature="'upsideMarketingPagesEnabled'"
        >
        </nc-upside-social-links>
    </div>

    <div class="primary-links">
        <span class="copyright" *upFeature="'upsideMarketingPagesEnabled'"
            >&copy; {{ currentYear }} Upside Realty Pty Ltd</span
        >
        <ul>
            <li class="primary-link-item" *ngFor="let link of primaryLinks">
                <a
                    class="primary-link-anchor"
                    *ngIf="link.href"
                    [dataEvent]="link.trackingEvent"
                    [attr.target]="link.target"
                    [href]="link.href"
                >
                    {{ link.name }}
                </a>
                <a
                    class="primary-link-anchor"
                    *ngIf="link.uiSref"
                    [dataEvent]="link.trackingEvent"
                    [attr.target]="link.target"
                    [uiSref]="link.uiSref"
                >
                    {{ link.name }}
                </a>
            </li>
        </ul>
    </div>
</div>
