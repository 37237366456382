import { Activity } from './activity.model';
import { EventContext } from './event-context.model';

export interface Appointment {
    id: string;
    type: Appointment.Type;
    dateTime: string;
    duration: string;
    travelTime: string;
    property: string;
    note?: string;
    history?: Activity.DisplayActivity[];
    cancelled?: boolean;
    complete?: boolean;
    context?: EventContext.EnrichedContext;
    onlineMeeting?: Appointment.OnlineMeeting;
}

export namespace Appointment {
    /** @Deprecated - use AppointmentType.Enum instead **/
    export enum Type {
        AGENT_PRE_APPRAISAL = 'AGENT_PRE_APPRAISAL',
        AGENT_APPRAISAL_VISIT = 'AGENT_APPRAISAL_VISIT',
        AGENT_FOLLOW_UP = 'AGENT_FOLLOW_UP',
        AGENT_LISTING_VISIT = 'AGENT_LISTING_VISIT',
        AGENT_CAMPAIGN_PROGRESS = 'AGENT_CAMPAIGN_PROGRESS',
        AGENT_PRE_AUCTION_VISIT = 'AGENT_PRE_AUCTION_VISIT',
        AGENT_PUBLIC_OPEN = 'AGENT_PUBLIC_OPEN',
        AGENT_PRIVATE_OPEN = 'AGENT_PRIVATE_OPEN',
        AGENT_POST_SETTLEMENT = 'AGENT_POST_SETTLEMENT',
        AGENT_FINAL_INSPECTION = 'AGENT_FINAL_INSPECTION',
        BUILDING_INSPECTION = 'BUILDING_INSPECTION',
        BANK_VALUATION = 'BANK_VALUATION',
        MID_SETTLEMENT_INSPECTION = 'MID_SETTLEMENT_INSPECTION',
        AGENT_OTHER = 'AGENT_OTHER',
        AUCTIONEER_AUCTION = 'AUCTIONEER_AUCTION',
        PHOTOGRAPHER_VISIT = 'PHOTOGRAPHER_VISIT',
        SET_TO_SELL = 'SET_TO_SELL',
        PRICE_ALIGNMENT = 'PRICE_ALIGNMENT',
    }

    export enum OnlineMeetingType {
        ZOOM = 'ZOOM',
        SKYPE = 'SKYPE',
        SLACK = 'SLACK',
        GOOGLE_HANGOUTS = 'GOOGLE_HANGOUTS',
        FREE_CONFERENCE = 'FREE_CONFERENCE',
        CISCO_WEBEX = 'CISCO_WEBEX',
        FACEBOOK = 'FACEBOOK',
        YOUTUBE = 'YOUTUBE',
        OTHER = 'OTHER',
    }

    export interface OnlineMeeting {
        type: OnlineMeetingType;
        url?: string;
    }
}
