import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { UpsideCommonModule } from '../../../common/common';
import { TestIdDirective } from '../../../common/directive/test-id/test-id.directive';
import { SharedVendorModule } from '../../../modules/shared-vendor/shared-vendor.module';
import { ConversionCommonModule } from '../common/conversion-common.module';

import { PhoneVerificationComponent } from './phone-verification/phone-verification.component';
import { PropertyReportComponent } from './property-report.component';
import { propertyReportStates } from './property-report.states';
import { ReportPropertyDataComponent } from './report-property-data/report-property-data.component';
import { UpdateVerificationPhoneNumberComponent } from './update-verification-phone-number/update-verification-phone-number.component';

@NgModule({
    imports: [
        SharedVendorModule,
        UpsideCommonModule,
        ConversionCommonModule,
        UIRouterModule.forChild({ states: propertyReportStates }),
        TestIdDirective,
    ],
    declarations: [
        PropertyReportComponent,
        ReportPropertyDataComponent,
        PhoneVerificationComponent,
        UpdateVerificationPhoneNumberComponent,
    ],
})
export class PropertyReportModule {}
