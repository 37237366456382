import { animate, AnimationEvent, keyframes, state, style, transition, trigger } from '@angular/animations';
import { FocusTrap, FocusTrapFactory } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    HostListener,
    Inject,
    Injector,
    OnDestroy,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import {
    UP_MODAL_CONTROLS,
    UP_MODAL_DATA,
    UP_MODAL_DIALOG_HOST_REF,
    UP_MODAL_LIFECYCLE,
} from '../../../../common/constants/modal-data.constant';
import { modal } from '../../../../common/models/modal.model';
import { ScrollBlockService } from '../../../core/services/scroll-block.service';
import { ModalHostDirective } from '../../directives/modal-host/modal-host.directive';
import { InternalModalControls, ModalService } from '../../services/modal/modal.service';

const animationStateIn = 'in';
const animationStateOut = 'out';

@Component({
    selector: 'up-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    animations: [
        trigger('fadeInState', [
            state(animationStateIn, style({ opacity: 1 })),
            state(animationStateOut, style({ opacity: 0 })),
            transition(`${animationStateOut} <=> ${animationStateIn}`, animate('0.3s cubic-bezier(0, 0, 0.2, 1)')),
        ]),
        trigger('slideInState', [
            state(
                animationStateOut,
                style({
                    transform: 'translateY(15%)',
                    overflow: 'hidden',
                }),
            ),
            transition(`${animationStateOut} => ${animationStateIn}`, [
                animate('0.3s cubic-bezier(0, 0, 0.2, 1)', style({ transform: 'translateY(0)' })),
            ]),
            transition(`${animationStateIn} => ${animationStateOut}`, [
                animate('0.3s cubic-bezier(0, 0, 0.2, 1)', style({ transform: 'translateY(15%)' })),
            ]),
        ]),
        trigger('attentionState', [
            transition('* => active', [
                animate(
                    '0.5s',
                    keyframes([
                        style({ transform: 'translate3d(0, 0, 0)' }),
                        style({ transform: 'translate3d(-5px, 0, 0)' }),
                        style({ transform: 'translate3d(5px, 0, 0)' }),
                        style({ transform: 'translate3d(-5px, 0, 0)' }),
                        style({ transform: 'translate3d(5px, 0, 0)' }),
                        style({ transform: 'translate3d(0, 0, 0)' }),
                    ]),
                ),
            ]),
        ]),
    ],
})
export class ModalComponent implements AfterViewInit, OnDestroy {
    @ViewChild('modal', { static: false }) private modalElement: ElementRef;
    @ViewChild('modalOutOfBounds', { static: false }) private modalOutOfBoundsElement: ElementRef;
    @ViewChild(ModalHostDirective, { static: false }) public modalHost: ModalHostDirective;
    @ViewChild('dialogHost', { static: false }) private dialogHostElement: ElementRef;
    public isVisible: boolean;
    public ariaHidden = true;
    public content: string;
    public readonly ariaLabelledBy: string = 'modal-title';
    public animationStateIn = animationStateIn;
    public animationStateOut = animationStateOut;
    public attentionAnimationState: undefined | 'active';
    private activeElement: HTMLElement;
    private trappedFocus: FocusTrap;
    private internalModalControls: InternalModalControls;
    private dialogComponentRef: ComponentRef<any>;
    private destroy$: Subject<void>;
    private modalLifecycle: Subject<modal.Lifecycle>;
    private showAnimationDone$: Subject<void>;
    private closeAnimationDone$: Subject<void>;
    private defaultDismissOverride: Function;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private modalService: ModalService,
        private focusTrap: FocusTrapFactory,
        private componentFactoryResolver: ComponentFactoryResolver,
        private scrollBlockService: ScrollBlockService,
    ) {
        this.destroy$ = new Subject<void>();
        this.showAnimationDone$ = new Subject<void>();
        this.closeAnimationDone$ = new Subject<void>();
    }

    public ngAfterViewInit(): void {
        this.modalService._modalShow$.pipe(takeUntil(this.destroy$)).subscribe(config => {
            this.modalLifecycle = new Subject<modal.Lifecycle>();
            this.internalModalControls = config.internalModalControls;
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(config.component);
            const injector = Injector.create([
                { provide: UP_MODAL_DATA, useValue: config.data },
                { provide: UP_MODAL_LIFECYCLE, useValue: this.modalLifecycle.asObservable() },
                {
                    provide: UP_MODAL_CONTROLS,
                    useValue: <modal.Controls>{
                        defaultDismissOverrideWithFn: fn => (this.defaultDismissOverride = fn),
                        close: (type: modal.CloseType, data?: any) => this.modalService.close(type, data),
                    },
                },
                {
                    provide: UP_MODAL_DIALOG_HOST_REF,
                    useValue: this.dialogHostElement,
                },
            ]);
            const viewContainerRef = this.modalHost.viewContainerRef;
            viewContainerRef.clear();
            // Let clear() method above clear out the DOM before creating new component to prevent some janky
            // shifting of elements when the new dialog gets shown just as the old one is removed at the same time
            setTimeout(() => {
                this.dialogComponentRef = viewContainerRef.createComponent(componentFactory, 0, injector);
                this.show();
            });
        });
        this.modalService._modalClose$
            .pipe(takeUntil(this.destroy$))
            .subscribe(event => this.close(event.type, event.data));
    }

    public onClick(event: MouseEvent): void {
        const clickTarget: HTMLElement = <HTMLElement>event.target;
        if (clickTarget === this.modalOutOfBoundsElement.nativeElement) {
            this.attentionAnimationState = 'active';
        }
    }

    @HostListener('window:keydown', ['$event'])
    public onKeydown(event: KeyboardEvent) {
        if (!this.isVisible) return;
        if (event.key === 'Escape') {
            if (this.defaultDismissOverride) {
                this.defaultDismissOverride();
            } else {
                this.close(modal.CloseType.Dismiss);
            }
        }
    }

    public ngOnDestroy(): void {
        this.internalModalControls?.close(modal.CloseType.Dismiss, undefined);
        this.destroy$.next();
        this.destroy$.complete();
    }

    public onAnimationDone(event: AnimationEvent): void {
        if (event.fromState === animationStateOut && event.toState === animationStateIn) {
            this.showAnimationDone$.next();
        } else if (event.fromState === animationStateIn && event.toState === animationStateOut) {
            this.closeAnimationDone$.next();
        }
    }

    public onAttentionAnimationDone(): void {
        this.attentionAnimationState = undefined;
    }

    private close(type: modal.CloseType, data?: any): void {
        // A race condition can occur where ngOnDestroy is called before ngOnInit
        if (!this.modalLifecycle) return;
        // Don't attempt to queue up a close if the dialog doesn't exist or is already closed, this prevents creating
        // a subscription to closeAnimationDone$ that then gets called later when a new modal gets closed causing
        // unexpected behavior.
        if (!this.dialogComponentRef || this.dialogComponentRef.hostView.destroyed) return;
        this.defaultDismissOverride = undefined;
        this.modalLifecycle.next(modal.Lifecycle.OnBeforeClose);
        this.closeAnimationDone$.pipe(first()).subscribe(() => {
            this.isVisible = false;
            if (this.activeElement) {
                this.activeElement.focus();
            }
            this.scrollBlockService.unblock();
            this.dialogComponentRef.destroy();
            this.internalModalControls.close(type, data);
            this.modalLifecycle.next(modal.Lifecycle.OnAfterClose);
            this.modalLifecycle.complete();
        });

        this.ariaHidden = true;
        // Prevent rare race condition where trappedFocus is not yet initialised
        if (this.trappedFocus) {
            this.trappedFocus.destroy();
        }
        this.modalLifecycle.next(modal.Lifecycle.OnClose);
    }

    private show(): void {
        this.modalLifecycle.next(modal.Lifecycle.OnBeforeShow);
        this.showAnimationDone$.pipe(first()).subscribe(() => this.modalLifecycle.next(modal.Lifecycle.OnAfterShow));
        this.isVisible = true;
        this.ariaHidden = false;
        this.modalLifecycle.next(modal.Lifecycle.OnShow);
        // Need to wait until next event tick so that the changes to isVisible and ariaHidden are reflected in the
        // DOM before trying to trap focus, otherwise elements would not yet be visible
        setTimeout(() => this.a11y());
    }

    private a11y(): void {
        // Cache last focused element
        this.activeElement = <HTMLElement>this.document.activeElement;
        // Disable scrolling
        this.scrollBlockService.block();
        // Attach correct id to modal heading
        const nativeModalElement: HTMLElement = this.modalElement.nativeElement;
        const firstHeading = nativeModalElement.querySelector('h1, h2, h3, h4, h5, h6');
        if (firstHeading) {
            this.renderer.setAttribute(firstHeading, 'id', this.ariaLabelledBy);
        }
        // Trap focus inside modal
        this.trappedFocus = this.focusTrap.create(nativeModalElement);
        this.trappedFocus.focusFirstTabbableElement();
    }
}
