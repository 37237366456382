import { NgModule } from '@angular/core';

import { MiniAgentContactComponent } from '../../common/components/mini-agent-contact/mini-agent-contact.component';
import { RouteLoadingBarComponent } from '../../common/components/route-loader/route-loading-bar.component';
import { RouteLoadingBarService } from '../../common/services/route-loading-bar.service';
import { SharedModule } from '../../common/shared.module';
import { CountrySelectorModule } from '../country-selector/country-selector.module';
import { NavigationModule } from '../navigation/navigation.module';
import { PermissionsModule } from '../permissions/permissions.module';
import { UpsideSocialLinksModule } from '../upside-social-links/upside-social-links.module';

import { AgentAppraisalCallbackBannerComponent } from './components/agent-appraisal-callback-banner/agent-appraisal-callback-banner.component';
import { AspectRatioComponent } from './components/aspect-ratio/aspect-ratio.component';
import { FloatingAgentComponent } from './components/floating-agent/floating-agent.component';
import { GetPropertyReportStepsSectionComponent } from './components/get-property-report-steps-section/get-property-report-steps-section.component';
import { MarketingHeroSectionComponent } from './components/marketing-hero-section/marketing-hero-section.component';
import { NavBasicComponent } from './components/nav-basic/nav-basic.component';
import { NavLandingComponent } from './components/nav-landing/nav-landing.component';
import { NavPhoneComponent } from './components/nav-phone/nav-phone.component';
import { ScrollToDirective } from './directives/scroll-to/scroll-to.directive';
import { IsPropertyTypeUnitPipe } from './pipes/is-property-type-unit.pipe';
import { TrimPipe } from './pipes/trim.pipe';

@NgModule({
    imports: [SharedModule, NavigationModule, UpsideSocialLinksModule, CountrySelectorModule, PermissionsModule],
    exports: [
        MiniAgentContactComponent,
        IsPropertyTypeUnitPipe,
        NavLandingComponent,
        NavPhoneComponent,
        GetPropertyReportStepsSectionComponent,
        MarketingHeroSectionComponent,
        NavBasicComponent,
        AspectRatioComponent,
        TrimPipe,
        RouteLoadingBarComponent,
        SharedModule,
        ScrollToDirective,
        AgentAppraisalCallbackBannerComponent,
        FloatingAgentComponent,
    ],
    declarations: [
        MiniAgentContactComponent,
        IsPropertyTypeUnitPipe,
        NavLandingComponent,
        NavPhoneComponent,
        GetPropertyReportStepsSectionComponent,
        MarketingHeroSectionComponent,
        NavBasicComponent,
        AspectRatioComponent,
        TrimPipe,
        RouteLoadingBarComponent,
        ScrollToDirective,
        AgentAppraisalCallbackBannerComponent,
        FloatingAgentComponent,
    ],
    providers: [RouteLoadingBarService],
})
export class SharedVendorModule {}
