export namespace Suppliers {
    export enum EntityType {
        Individual = 'INDIVIDUAL',
        Agency = 'AGENCY',
    }

    export interface Supplier {
        id: string;
        companyName: string;
        types: SupplierType[];
        website?: string;
        entityType: EntityType;
        standardContact: SupplierContact;
        escalationContact?: SupplierContact;
    }

    export interface SupplierContact {
        email?: string;
        firstName?: string;
        lastName?: string;
        phoneNumber?: string;
    }

    export enum SupplierType {
        Agent = 'AGENT',
        Stylist = 'STYLIST',
        Photographer = 'PHOTOGRAPHER',
        Auctioneer = 'AUCTIONEER',
        Valuer = 'VALUER',
        Copywriter = 'COPYWRITER',
        Marketing = 'MARKETING',
    }

    export interface EntitySuppliers {
        auctioneer?: Supplier;
        marketing?: Supplier;
        photographer?: Supplier;
    }

    export interface EntitySupplierIds {
        auctioneer?: string;
        marketing?: string;
        photographer?: string;
    }
}
