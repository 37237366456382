import { Injectable } from '@angular/core';

@Injectable()
export class BudgetPricingUtilities {
    public generateBudgetPrices(): number[] {
        const prices = [];
        let currentMax = 0;
        while (currentMax < 12000000) {
            if (currentMax < 1000000) {
                currentMax = currentMax + 50000;
            } else if (currentMax < 2000000) {
                currentMax = currentMax + 100000;
            } else if (currentMax < 3000000) {
                currentMax = currentMax + 250000;
            } else if (currentMax < 4000000) {
                currentMax = currentMax + 500000;
            } else {
                currentMax = currentMax + 1000000;
            }
            prices.push(currentMax);
        }
        return prices;
    }
}
