import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../../common/components/icon/icon.module';
import { CollapserPanelModule } from '../collapser-panel/collapser-panel.module';
import { HiddenContentIndicatorModule } from '../hidden-content-indicator/hidden-content-indicator.module';

import { AccordionComponent } from './components/accordion/accordion.component';
import { AccordionHeadingComponent } from './components/accordion-heading/accordion-heading.component';
import { AccordionPanelComponent } from './components/accordion-panel/accordion-panel.component';

@NgModule({
    imports: [CommonModule, CollapserPanelModule, IconModule, HiddenContentIndicatorModule],
    declarations: [AccordionComponent, AccordionPanelComponent, AccordionHeadingComponent],
    exports: [AccordionComponent, AccordionPanelComponent, AccordionHeadingComponent],
})
export class AccordionModule {}
