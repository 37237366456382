import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, PLATFORM_ID, ViewChild } from '@angular/core';

@Component({
    selector: 'up-select-option',
    templateUrl: './select-option.component.html',
})
export class SelectOptionComponent implements AfterViewInit {
    @Input() public value: any;
    @ViewChild('content', { static: false }) public contentRef: ElementRef;

    public content: string;

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

    public ngAfterViewInit(): void {
        const textContent = isPlatformBrowser(this.platformId) ? this.contentRef.nativeElement.innerText : '';

        this.content = textContent ? textContent : this.value;
    }
}
