import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first } from 'rxjs/operators';

import { AppState } from '../apps-state.model';

import { GetSuburbs } from './suburbs.actions';
import { allSuburbsSelector, isLoadingSuburbsSelector, suburbState } from './suburbs.selector';

@Injectable()
export class SuburbsFacade {
    public suburbState$ = this.store.select(suburbState);
    public allSuburbsWhenLoaded$ = this.store.select(allSuburbsSelector).pipe(filter(suburbs => !!suburbs.length));
    public isLoadingSuburbs$ = this.store.select(isLoadingSuburbsSelector);

    constructor(private store: Store<AppState>) {}

    public loadSuburbsIfNeeded(): void {
        this.suburbState$
            .pipe(
                first(),
                filter(({ loading, all }) => !loading && !all.length),
            )
            .subscribe(() => this.store.dispatch(new GetSuburbs()));
    }
}
