import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'up-nav-pane-container',
    templateUrl: 'nav-pane-container.component.html',
    styleUrls: ['nav-pane-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavPaneContainerComponent {
    @HostBinding('class.is-grower')
    @Input()
    public grow: boolean;
}
