import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { StatsApi } from '../../../common/models/stats.model';

const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

@Injectable()
class StatsResource {
    public BASE_URL = `/api/v2/stats`;

    constructor(private http: HttpClient) {}

    public getCountryStats(country): Observable<StatsApi.Country.GET.Response> {
        return this.http.get<StatsApi.Country.GET.Response>(`${this.BASE_URL}/${country}/`, HEADERS_CONFIG).pipe(
            startWith({
                totalFeesSaved: 1000000,
            }),
        );
    }
}

export { StatsResource };
