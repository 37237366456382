import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
    selector: 'h2[nc-modal-heading]',
    templateUrl: 'modal-heading.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalHeadingComponent {
    @HostBinding('class.u-text-heading--md')
    public readonly isTextHeadingMd = true;
}
