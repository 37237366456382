import { Inject, Injectable, InjectionToken } from '@angular/core';

import { experiments } from '../models/experiments.model';

@Injectable()
export class ServerExperimentsService {
    constructor(@Inject(SERVER_EXPERIMENTS_QUERY) private serverExpObj: string) {}
    public getExperiments() {
        return JSON.parse(this.serverExpObj || '{}');
    }
}

export const SERVER_EXPERIMENTS_QUERY = new InjectionToken<experiments.ServerExperimentsQuery>(
    'SERVER_EXPERIMENT_QUERY',
);
export const ServerExperimentsServiceDIToken = new InjectionToken<ServerExperimentsService>('ServerExperimentsService');
