import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { MarketingChooseYourOwnAdventureModule } from '../marketing-choose-your-own-adventure/marketing-choose-your-own-adventure.module';
import { SharedVendorModule } from '../shared-vendor/shared-vendor.module';

import { PostOfiPageComponent } from './components/post-ofi-page/post-ofi-page.component';
import { conversionFunnelPostOfiStates } from './conversion-funnel-post-ofi.states';

@NgModule({
    imports: [
        SharedVendorModule,
        UIRouterModule.forChild({ states: conversionFunnelPostOfiStates }),
        MarketingChooseYourOwnAdventureModule,
    ],
    declarations: [PostOfiPageComponent],
})
export class ConversionFunnelPostOfiModule {}
