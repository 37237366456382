import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';

import { WindowRef } from '../../../../common/services/window.service';

@Injectable()
export class ScriptInjectorService {
    private renderer2: Renderer2;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        @Inject(DOCUMENT) private document: Document,
        private windowRef: WindowRef,
        private rendererFactory2: RendererFactory2,
    ) {
        this.renderer2 = this.rendererFactory2.createRenderer(this.document, null);
    }

    public inject(script: string): HTMLElement | undefined {
        if (!isPlatformBrowser(this.platformId)) return;

        const scriptElement = this.renderer2.createElement('script');
        this.renderer2.setAttribute(scriptElement, 'type', 'text/javascript');
        this.renderer2.setProperty(scriptElement, 'innerHTML', script);
        this.renderer2.appendChild(this.document.head, scriptElement);

        return scriptElement;
    }
}
