import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { EnvironmentService } from '../../../modules/core/services/environment.service';
import { agentProfiles } from '../../models/domain/agent/agent-profiles.model';
import { environment } from '../../models/environment.model';

@Component({
    selector: 'up-agent-contact-card',
    templateUrl: 'agent-contact-card.component.html',
    styleUrls: ['agent-contact-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentContactCardComponent {
    @Input() public agent: agentProfiles.Profile;
    @Input() public caption: string;
    public get useTransparentImage(): boolean {
        return this.environmentService.currentOrgGrouping === environment.GroupedOrganisationName.Upside;
    }
    public get showRMARating(): boolean {
        return this.environmentService.currentOrgGrouping === environment.GroupedOrganisationName.Upside;
    }

    constructor(private environmentService: EnvironmentService) {}
}
