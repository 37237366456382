import { InjectionToken } from '@angular/core';

import { ThemeColors } from '../../modules/core/services/theme.service';
import { colors } from '../../settings/base-theme';

/** @deprecated Deprecated in favor of theme.service */
export interface UpColorConstants extends ThemeColors {}

/** @deprecated Deprecated in favor of theme.service */
export const ColorConstants: UpColorConstants = colors;
/** @deprecated Deprecated in favor of theme.service */
export const UP_COLOR_CONSTANTS = new InjectionToken<UpColorConstants>('UP_COLOR_CONSTANTS');
