import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'ul[nc-select-list], ol[nc-select-list]',
    templateUrl: 'select-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectListComponent {
    @Input() public appearance: 'minimal' | 'emphasised' = 'minimal';

    public get isAppearanceMinimal(): boolean {
        return this.appearance === 'minimal';
    }

    public get isAppearanceEmphasised(): boolean {
        return this.appearance === 'emphasised';
    }
}
