import { NgModule } from '@angular/core';

import { SharedModule } from '../../../common/shared.module';
import { AgentHeadshotModule } from '../../../components/agent-headshot/agent-headshot.module';
import { AccordionModule } from '../../accordion/accordion.module';

import { AgentProfileDialogComponent } from './agent-profile-dialog/agent-profile-dialog.component';
import { CoreLogicEstimateTermsDialogComponent } from './estimate-terms-dialog/core-logic-estimate-terms-dialog.component';

@NgModule({
    imports: [AgentHeadshotModule, SharedModule, AccordionModule],
    declarations: [AgentProfileDialogComponent, CoreLogicEstimateTermsDialogComponent],
    entryComponents: [AgentProfileDialogComponent, CoreLogicEstimateTermsDialogComponent],
})
export class PropertyReportDialogsModule {}
