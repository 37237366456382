import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'nc-modal-content',
    templateUrl: 'modal-content.component.html',
    styleUrls: ['modal-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalContentComponent {
    @Input() public size: 'small' | 'medium' = 'medium';

    @HostBinding('class.is-size-small')
    public get isSizeSmall(): boolean {
        return this.size === 'small';
    }

    @HostBinding('class.is-size-medium')
    public get isSizeMedium(): boolean {
        return this.size === 'medium';
    }
}
