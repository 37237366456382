import { isPlatformBrowser } from '@angular/common';
import { Injector, PLATFORM_ID } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UrlService } from '@uirouter/angular';
import { of, zip } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { ExperimentService } from '../common/services/experiment.service';
import { UserResource } from '../modules/core/resources/user.resource';
import { AppPlatformService } from '../modules/core/services/app-platform.service';
import { EnvironmentService } from '../modules/core/services/environment.service';
import { FirebaseService } from '../modules/core/services/firebase.service';
import { GoogleTagManagerService } from '../modules/core/services/google-tag-manager.service';
import { PerformanceService } from '../modules/core/services/performance.service';
import { ThemeService } from '../modules/core/services/theme.service';
import { FacebookPixelService } from '../modules/facebook-pixel/services/facebook-pixel/facebook-pixel.service';
import { marks, measures } from '../settings/performance';

export function sharedInitializerFactory(urlService: UrlService, injector: Injector): () => Promise<any> {
    const platformId = injector.get(PLATFORM_ID);
    const experimentService = injector.get(ExperimentService);
    const userResource = injector.get(UserResource);
    const environmentService = injector.get(EnvironmentService);
    const themeService = injector.get(ThemeService);
    const googleTagManagerService = injector.get(GoogleTagManagerService);
    const appPlatformService = injector.get(AppPlatformService);
    const performanceService = injector.get(PerformanceService);
    const facebookPixelService = injector.get(FacebookPixelService);
    const firebaseService = injector.get(FirebaseService);
    const translocoService = injector.get(TranslocoService);

    // Defer ui-router from performing initial url sync to prevent it from prematurely firing off a route's 'onSuccess'
    // hooks before this initializer promise resolves as ui-router also uses Angular's APP_INITIALIZER hook.
    // https://github.com/ui-router/angular/issues/206
    // https://ui-router.github.io/ng2/docs/latest/classes/url.urlservice.html#deferintercept
    urlService.deferIntercept();

    appPlatformService.listen();

    facebookPixelService.initialiseDynamicPixelFromUrlParams();

    // Ensure to swallow all errors for each item in the promise to prevent Promise.all from exiting
    // due to any of them failing
    const waitFor = () => {
        // NOTE: Do not return undefined in of() as it will cause the resulting observable to complete immediately
        // without waiting for the others

        const tasks = [
            environmentService.config$,
            themeService.setStyleTheme(),
            themeService.setupFavicon(),
            firebaseService.fetchRemoteConfig$(),
            translocoService.load('en'),
        ];

        if (isPlatformBrowser(platformId)) {
            tasks.push(
                // Fetch all experiments before angular loads
                experimentService.fetchExperiments().pipe(catchError(() => of({}))),
                // Fetch user data before angular loads
                userResource.current().pipe(catchError(() => of({}))),
                googleTagManagerService.init$(),
            );
        }

        return zip(...tasks);
    };

    // APP_INITIALIZER only takes a promise
    return () =>
        waitFor()
            .pipe(
                // Always reinitialise ui-router regardless of whether the initializerService resolves or not to be safe
                finalize(() => {
                    urlService.listen();
                    urlService.sync();
                    performanceService.mark(marks.angularFinishBoot);
                    performanceService.measure(
                        measures.angularBootDuration,
                        marks.angularStartBoot,
                        marks.angularFinishBoot,
                    );
                }),
            )
            .toPromise();
}
