import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { GetSuburbsUseCase } from '../../common/use-cases/suburbs/get-suburbs.use-case';

import * as SuburbActions from './suburbs.actions';
import { GetSuburbsError, GetSuburbsSuccess } from './suburbs.actions';

@Injectable()
export class SuburbsEffects {
    constructor(private actions: Actions, private getSuburbsUseCase: GetSuburbsUseCase) {}

    public getSuburbs: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType<SuburbActions.GetSuburbs>(SuburbActions.GET_SUBURBS),
            switchMap(() => {
                return this.getSuburbsUseCase.execute().pipe(
                    map(
                        result =>
                            new GetSuburbsSuccess({
                                profiled: result.profiled,
                                all: result.all,
                            }),
                    ),
                    catchError(() => of(new GetSuburbsError())),
                );
            }),
        ),
    );
}
