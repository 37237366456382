import { HttpErrorResponse } from '@angular/common/http';
import { Action, createAction, props, union } from '@ngrx/store';

import { error } from '../../common/models/error.model';
import { userApi } from '../../common/models/user-api.model';

export enum UserAction {
    RESET_NOTIFICATIONS_PREFERENCES_STATE = '[User] Reset Notifications Preferences State',
    GET_NOTIFICATIONS_PREFERENCES = '[User] Get Notifications Preferences',
    GET_NOTIFICATIONS_PREFERENCES_SUCCESS = '[User] Get Notifications Preferences Success',
    GET_NOTIFICATIONS_PREFERENCES_ERROR = '[User] Get Notifications Preferences Error',
    GET_NOTIFICATIONS_PREFERENCES_WITH_TOKEN = '[User] Get Notifications Preferences With Token',
    GET_NOTIFICATIONS_PREFERENCES_WITH_TOKEN_SUCCESS = '[User] Get Notifications Preferences With Token Success',
    GET_NOTIFICATIONS_PREFERENCES_WITH_TOKEN_ERROR = '[User] Get Notifications Preferences With Token Error',
    UPDATE_NOTIFICATIONS_PREFERENCES = '[User] Update Notifications Preferences',
    UPDATE_NOTIFICATIONS_PREFERENCES_SUCCESS = '[User] Update Notifications Preferences Success',
    UPDATE_NOTIFICATIONS_PREFERENCES_ERROR = '[User] Update Notifications Preferences Error',
    UPDATE_NOTIFICATIONS_PREFERENCES_WITH_TOKEN = '[User] Update Notifications Preferences With Token',
    UPDATE_NOTIFICATIONS_PREFERENCES_WITH_TOKEN_SUCCESS = '[User] Update Notifications Preferences With Token Success',
    UPDATE_NOTIFICATIONS_PREFERENCES_WITH_TOKEN_ERROR = '[User] Update Notifications Preferences With Token Error',
    NOTIFICATIONS_SUBSCRIBE = '[User] Notifications Subscribe',
    NOTIFICATIONS_SUBSCRIBE_SUCCESS = '[User] Notifications Subscribe Success',
    NOTIFICATIONS_SUBSCRIBE_ERROR = '[User] Notifications Subscribe Error',
    NOTIFICATIONS_UNSUBSCRIBE = '[User] Notifications Unsubscribe',
    NOTIFICATIONS_UNSUBSCRIBE_SUCCESS = '[User] Notifications Unsubscribe Success',
    NOTIFICATIONS_UNSUBSCRIBE_ERROR = '[User] Notifications Unsubscribe Error',
    NOTIFICATIONS_UNSUBSCRIBE_WITH_TOKEN = '[User] Notifications Unsubscribe With Token',
    NOTIFICATIONS_UNSUBSCRIBE_WITH_TOKEN_SUCCESS = '[User] Notifications Unsubscribe With Token Success',
    NOTIFICATIONS_UNSUBSCRIBE_WITH_TOKEN_ERROR = '[User] Notifications Unsubscribe With Token Error',
}

export const getNotificationPreferencesForUser = createAction(
    '[User] Get Notification Preferences For User',
    props<{ userId: string }>(),
);

export const getNotificationPreferencesForUserSuccess = createAction(
    '[User] Get Notification Preferences For User Success',
    props<{ payload: userApi.notifications.preferences.User.GET.Response }>(),
);

export const getNotificationPreferencesForUserError = createAction(
    '[User] Get Notification Preferences For User Error',
    props<{ payload: HttpErrorResponse }>(),
);

export const updateNotificationPreferencesForUser = createAction(
    '[User] Update Notification Preferences For User',
    props<{ userId: string; preferences: userApi.notifications.preferences.User.PUT.Request }>(),
);

export const updateNotificationPreferencesForUserSuccess = createAction(
    '[User] Update Notification Preferences For User Success',
    props<{ payload: userApi.notifications.preferences.User.GET.Response }>(),
);

export const updateNotificationPreferencesForUserError = createAction(
    '[User] Update Notification Preferences For User Error',
    props<{ payload: HttpErrorResponse }>(),
);

export namespace userActions {
    export class ResetNotificationsPreferencesState implements Action {
        public readonly type = UserAction.RESET_NOTIFICATIONS_PREFERENCES_STATE;
    }

    export class GetNotificationsPreferences implements Action {
        public readonly type = UserAction.GET_NOTIFICATIONS_PREFERENCES;
    }

    export class GetNotificationsPreferencesSuccess implements Action {
        public readonly type = UserAction.GET_NOTIFICATIONS_PREFERENCES_SUCCESS;
        constructor(public payload: userApi.notifications.preferences.get.Response) {}
    }

    export class GetNotificationsPreferencesError implements Action {
        public readonly type = UserAction.GET_NOTIFICATIONS_PREFERENCES_ERROR;
        constructor(public payload: HttpErrorResponse) {}
    }

    export class GetNotificationsPreferencesWithToken implements Action {
        public readonly type = UserAction.GET_NOTIFICATIONS_PREFERENCES_WITH_TOKEN;
        constructor(public payload: string) {}
    }

    export class GetNotificationsPreferencesWithTokenSuccess implements Action {
        public readonly type = UserAction.GET_NOTIFICATIONS_PREFERENCES_WITH_TOKEN_SUCCESS;
        constructor(public payload: userApi.notifications.preferences.token.get.Response) {}
    }

    export class GetNotificationsPreferencesWithTokenError implements Action {
        public readonly type = UserAction.GET_NOTIFICATIONS_PREFERENCES_WITH_TOKEN_ERROR;
        constructor(public payload: HttpErrorResponse) {}
    }

    export class UpdateNotificationsPreferences implements Action {
        public readonly type = UserAction.UPDATE_NOTIFICATIONS_PREFERENCES;
        constructor(public payload: userApi.notifications.preferences.put.Request) {}
    }

    export class UpdateNotificationsPreferencesSuccess implements Action {
        public readonly type = UserAction.UPDATE_NOTIFICATIONS_PREFERENCES_SUCCESS;
        constructor(public payload: userApi.notifications.preferences.put.Response) {}
    }

    export class UpdateNotificationsPreferencesError implements Action {
        public readonly type = UserAction.UPDATE_NOTIFICATIONS_PREFERENCES_ERROR;
        constructor(public payload: HttpErrorResponse) {}
    }

    export class UpdateNotificationsPreferencesWithToken implements Action {
        public readonly type = UserAction.UPDATE_NOTIFICATIONS_PREFERENCES_WITH_TOKEN;
        constructor(
            public payload: {
                token: string;
                preferences: userApi.notifications.preferences.token.put.Request;
            },
        ) {}
    }

    export class UpdateNotificationsPreferencesWithTokenSuccess implements Action {
        public readonly type = UserAction.UPDATE_NOTIFICATIONS_PREFERENCES_WITH_TOKEN_SUCCESS;
        constructor(public payload: userApi.notifications.preferences.token.put.Response) {}
    }

    export class UpdateNotificationsPreferencesWithTokenError implements Action {
        public readonly type = UserAction.UPDATE_NOTIFICATIONS_PREFERENCES_WITH_TOKEN_ERROR;
        constructor(public payload: HttpErrorResponse) {}
    }

    export class NotificationsSubscribe implements Action {
        public readonly type = UserAction.NOTIFICATIONS_SUBSCRIBE;
        constructor(public payload: userApi.notifications.subscribe.post.Request) {}
    }

    export class NotificationsSubscribeSuccess implements Action {
        public readonly type = UserAction.NOTIFICATIONS_SUBSCRIBE_SUCCESS;
    }

    export class NotificationsSubscribeError implements Action {
        public readonly type = UserAction.NOTIFICATIONS_SUBSCRIBE_ERROR;
        constructor(public payload: error.HttpResponse<userApi.notifications.subscribe.post.ErrorCodes>) {}
    }

    export class NotificationsUnsubscribe implements Action {
        public readonly type = UserAction.NOTIFICATIONS_UNSUBSCRIBE;
    }

    export class NotificationsUnsubscribeSuccess implements Action {
        public readonly type = UserAction.NOTIFICATIONS_UNSUBSCRIBE_SUCCESS;
    }

    export class NotificationsUnsubscribeError implements Action {
        public readonly type = UserAction.NOTIFICATIONS_UNSUBSCRIBE_ERROR;
        constructor(public payload: HttpErrorResponse) {}
    }

    export class NotificationsUnsubscribeWithToken implements Action {
        public readonly type = UserAction.NOTIFICATIONS_UNSUBSCRIBE_WITH_TOKEN;
        constructor(public payload: string) {}
    }

    export class NotificationsUnsubscribeWithTokenSuccess implements Action {
        public readonly type = UserAction.NOTIFICATIONS_UNSUBSCRIBE_WITH_TOKEN_SUCCESS;
    }

    export class NotificationsUnsubscribeWithTokenError implements Action {
        public readonly type = UserAction.NOTIFICATIONS_UNSUBSCRIBE_WITH_TOKEN_ERROR;
        constructor(public payload: HttpErrorResponse) {}
    }
}

const actions = union({
    getNotificationPreferencesForUser,
    getNotificationPreferencesForUserSuccess,
    getNotificationPreferencesForUserError,
    updateNotificationPreferencesForUser,
    updateNotificationPreferencesForUserSuccess,
    updateNotificationPreferencesForUserError,
});

export type UserActions =
    | userActions.ResetNotificationsPreferencesState
    | userActions.GetNotificationsPreferences
    | userActions.GetNotificationsPreferencesSuccess
    | userActions.GetNotificationsPreferencesError
    | userActions.GetNotificationsPreferencesWithToken
    | userActions.GetNotificationsPreferencesWithTokenSuccess
    | userActions.GetNotificationsPreferencesWithTokenError
    | userActions.UpdateNotificationsPreferences
    | userActions.UpdateNotificationsPreferencesSuccess
    | userActions.UpdateNotificationsPreferencesError
    | userActions.UpdateNotificationsPreferencesWithToken
    | userActions.UpdateNotificationsPreferencesWithTokenSuccess
    | userActions.UpdateNotificationsPreferencesWithTokenError
    | userActions.NotificationsSubscribe
    | userActions.NotificationsSubscribeSuccess
    | userActions.NotificationsSubscribeError
    | userActions.NotificationsUnsubscribe
    | userActions.NotificationsUnsubscribeSuccess
    | userActions.NotificationsUnsubscribeError
    | userActions.NotificationsUnsubscribeWithToken
    | userActions.NotificationsUnsubscribeWithTokenSuccess
    | userActions.NotificationsUnsubscribeWithTokenError
    | typeof actions;
