import { Component, Input } from '@angular/core';

import { nav } from '../../../../common/models/nav.model';

@Component({
    selector: 'up-nav-bar-cta',
    templateUrl: './nav-bar-cta.component.html',
})
export class NavBarCtaComponent {
    @Input() public item: nav.BarCta;
}
