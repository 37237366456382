import { Directive, HostBinding, Input } from '@angular/core';

type HideOn = 'min' | 'mobile' | 'tablet' | 'desktop';

@Directive({
    selector: '[upNavHideOnBreakpoint]',
})
export class NavHideOnBreakpointDirective {
    @Input() public upNavHideOnBreakpoint: HideOn[] = [];

    @HostBinding('class.u-nav-hide--min')
    public get classNavHideMin(): boolean {
        return this.upNavHideOnBreakpoint.includes('min');
    }

    @HostBinding('class.u-nav-hide--mobile')
    public get classNavHideMobile(): boolean {
        return this.upNavHideOnBreakpoint.includes('mobile');
    }

    @HostBinding('class.u-nav-hide--tablet')
    public get classNavHideTablet(): boolean {
        return this.upNavHideOnBreakpoint.includes('tablet');
    }

    @HostBinding('class.u-nav-hide--desktop')
    public get classNavHideDesktop(): boolean {
        return this.upNavHideOnBreakpoint.includes('desktop');
    }
}
