import { Injector } from '@angular/core';
import { UIRouter } from '@uirouter/core';

import { RouteLoadingBarService } from '../../common/services/route-loading-bar.service';

export const routeLoaderUiRouterHooks = (router: UIRouter, injector: Injector) => {
    router.transitionService.onBefore(
        { entering: state => !!state.lazyLoad },
        transition => {
            const routeLoaderService = injector.get(RouteLoadingBarService);
            routeLoaderService.startLoading();
            const done = () => routeLoaderService.loadingComplete();
            transition.promise.then(done, done);
        },
        // priority: 1 is necessary because the lazy load hook itself is a priority 0 onBefore hook.
        // https://github.com/ui-router/angular/issues/173
        { priority: 1 },
    );
};
