import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, switchMap, take } from 'rxjs/operators';

import { Suppliers } from '../../common/models/suppliers.model';
import { AppState } from '../apps-state.model';

import {
    getAgentSuppliers,
    getAllSuppliers,
    getPropertySuppliers,
    resetCachedSuppliers,
    updateAgentSuppliers,
    updatePropertySuppliers,
} from './internal-suppliers.actions';
import {
    selectAgentSuppliers,
    selectAllSuppliers,
    selectIsLoadingAgentSuppliers,
    selectIsLoadingAllSuppliers,
    selectIsLoadingPropertySuppliers,
    selectIsUpdatingAgentSuppliers,
    selectIsUpdatingPropertySuppliers,
    selectPropertySupplierByType,
    selectPropertySuppliers,
    selectUpdateAgentSuppliersSuccess,
    selectUpdatePropertySuppliersSuccess,
} from './internal-suppliers.selectors';

@Injectable()
export class InternalSuppliersFacade {
    public allSuppliers$ = this.store.select(selectAllSuppliers);
    public isLoadingAllSuppliers$ = this.store.select(selectIsLoadingAllSuppliers);
    public isLoadingPropertySuppliers$ = this.store.select(selectIsLoadingPropertySuppliers);
    public isLoadingAgentSuppliers$ = this.store.select(selectIsLoadingAgentSuppliers);
    public isUpdatingPropertySuppliers$ = this.store.select(selectIsUpdatingPropertySuppliers);
    public isUpdatingAgentSuppliers$ = this.store.select(selectIsUpdatingAgentSuppliers);
    public updatePropertySuppliersSuccess$ = this.store
        .select(selectUpdatePropertySuppliersSuccess)
        .pipe(filter(success => typeof success !== 'undefined'));
    public updateAgentSuppliersSuccess$ = this.store
        .select(selectUpdateAgentSuppliersSuccess)
        .pipe(filter(success => typeof success !== 'undefined'));

    constructor(private store: Store<AppState>) {}

    public getAllSuppliers(): void {
        this.store.dispatch(getAllSuppliers());
    }

    public getPropertySuppliers(propertyId: string): void {
        this.store.dispatch(getPropertySuppliers({ propertyId }));
    }

    public getAgentSuppliers(agentId: string): void {
        this.store.dispatch(getAgentSuppliers({ agentId }));
    }

    public updatePropertySuppliers(propertyId: string, suppliers: Suppliers.EntitySupplierIds): void {
        this.store.dispatch(updatePropertySuppliers({ propertyId, suppliers }));
    }

    public updateAgentSuppliers(agentId: string, suppliers: Suppliers.EntitySupplierIds): void {
        this.store.dispatch(updateAgentSuppliers({ agentId, suppliers }));
    }

    // Only makes the request if the property suppliers haven't already been fetched before
    public getPropertySuppliersIfNeeded(propertyId: string): void {
        // If we're already in the process of fetching the suppliers list for a property, wait until it has completed
        this.isLoadingPropertySuppliers$
            .pipe(
                first(loading => !loading),
                switchMap(() => this.propertySuppliers(propertyId)),
                take(1),
                // If the suppliers have been fetched it will return an array (empty or otherwise). We can therefore
                // just check if it's falsy to know whether we should fetch them or not.
                filter(suppliers => !suppliers),
            )
            .subscribe(() => this.getPropertySuppliers(propertyId));
    }

    public propertySuppliers(propertyId: string): Observable<Suppliers.EntitySuppliers> {
        return this.store.select(selectPropertySuppliers, { propertyId });
    }

    public propertySupplierByType(propertyId: string, type: Suppliers.SupplierType): Observable<Suppliers.Supplier> {
        const supplierTypeMap: { [supplier in Suppliers.SupplierType]?: keyof Suppliers.EntitySuppliers } = {
            [Suppliers.SupplierType.Auctioneer]: 'auctioneer',
            [Suppliers.SupplierType.Marketing]: 'marketing',
            [Suppliers.SupplierType.Photographer]: 'photographer',
        };

        return this.store.select(selectPropertySupplierByType, { propertyId, type: supplierTypeMap[type] });
    }

    public agentSuppliers(agentId: string): Observable<Suppliers.EntitySuppliers> {
        return this.store.select(selectAgentSuppliers, { agentId });
    }

    public resetCachedSuppliers(): void {
        this.store.dispatch(resetCachedSuppliers());
    }
}
