import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injector, PLATFORM_ID } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { StateService } from '@uirouter/angular';
import UAParser from 'ua-parser-js';

import { ExperimentService } from '../../common/services/experiment.service';
import { TrackingService } from '../../common/services/tracking.service';
import { UtmService } from '../../common/services/utm.service';
import { ExperimentsResource } from '../../modules/core/resources/experiments.resource';

export const experimentsRouterHooks = (router: UIRouter, injector: Injector) => {
    router.transitionService.onSuccess({}, transition => {
        const trackingService: TrackingService = injector.get(TrackingService);
        const document = injector.get(DOCUMENT);
        const platformId = injector.get(PLATFORM_ID);
        const experimentService = injector.get(ExperimentService);
        const stateService = injector.get(StateService);
        const experimentsResource = injector.get(ExperimentsResource);
        const utmService = injector.get(UtmService);

        if (isPlatformBrowser(platformId)) {
            trackingService.trackPageView();
            // Automatically send GA experiment variation if user visits route with an experiment
            experimentService.experiments.forEach(experiment => {
                const experimentRouteRegExp = new RegExp(experiment.route);
                const name = transition.$to().name;
                const transitionPath = stateService.href(name, {});
                const regExpPathMatch = transitionPath.match(experimentRouteRegExp);
                const isMatchingRouteRegExp = !!(regExpPathMatch && regExpPathMatch.length);

                if (isMatchingRouteRegExp) {
                    const variationPart = experiment.var ? `variation${experiment.var}` : 'control';
                    const subname = `${experiment.id}-${variationPart}`;
                    trackingService.trackDataEvent('experimentVariation', { subname });

                    const uaParser = new UAParser();
                    const { ua, os, device, cpu, browser } = uaParser.getResult();
                    const de = document.documentElement;
                    const body = document.getElementsByTagName('body')[0];
                    const screenWidth = window.innerWidth || de.clientWidth || body.clientWidth;
                    const screenHeight = window.innerHeight || de.clientHeight || body.clientHeight;
                    experimentsResource
                        .participate({
                            name: experiment.id,
                            variation: experiment.var,
                            route: experiment.route,
                            userAgent: ua,
                            os: os && os.name,
                            osVersion: os && os.version,
                            device: device && device.model,
                            deviceType: device && device.type,
                            deviceVendor: device && device.vendor,
                            cpu: cpu && cpu.architecture,
                            screenWidth,
                            screenHeight,
                            browser: browser && browser.name,
                            browserVersion: browser && browser.version,
                            utm: utmService.getStoredUtmCodes(),
                        })
                        .subscribe();
                }
            });
        }
    });
};
