import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RawParams, StateService } from '@uirouter/angular';
import jwtDecode from 'jwt-decode';
import { delay, filter, first } from 'rxjs/operators';

import { loginFeatureDisabled } from '../../../common/constants/error-messages.constants';
import { UpValidators } from '../../../common/form-validators/validators';
import { environment } from '../../../common/models/environment.model';
import { userApi } from '../../../common/models/user-api.model';
import { ErrorHandlerService } from '../../../common/services/error-handler.service';
import { TrackingService } from '../../../common/services/tracking.service';
import { UserService, UserT } from '../../../common/services/user.service';
import { WindowRef } from '../../../common/services/window.service';
import { isInternal } from '../../../common/utilities/roles.utilities';
import { UserResource } from '../../../modules/core/resources/user.resource';
import { EnvironmentService } from '../../../modules/core/services/environment.service';
import { NotificationService } from '../../../modules/core/services/notification.service';
import { ThemeService } from '../../../modules/core/services/theme.service';

enum SignInMethod {
    Google,
    EmailPassword,
}

@Component({
    selector: 'up-internal-sign-in',
    templateUrl: './internal-sign-in.component.html',
    styleUrls: ['internal-sign-in.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalSignInComponent implements OnInit {
    public checkingSignedInState = true;
    public signInForm: FormGroup;
    public hasAttemptedSignIn: boolean;
    private _signingIn: boolean;

    private get redirectToParam(): string {
        return this.stateService.params['redirect_to'];
    }

    private get redirectToParamsParam(): string {
        return this.stateService.params['redirect_to_params'];
    }

    private get nextParam(): string {
        return this.stateService.params['next'];
    }

    constructor(
        private userResource: UserResource,
        private windowRef: WindowRef,
        private trackingService: TrackingService,
        private stateService: StateService,
        private formBuilder: FormBuilder,
        private environmentService: EnvironmentService,
        private notificationService: NotificationService,
        private errorHandlerService: ErrorHandlerService,
        private themeService: ThemeService,
        private changeDetectorRef: ChangeDetectorRef,
        private userService: UserService,
    ) {
        this.signInForm = this.formBuilder.group({
            email: ['', [Validators.required, UpValidators.strictEmailValidator]],
            password: ['', Validators.required],
        });
    }

    public set signingIn(value: boolean) {
        this._signingIn = value;
        if (value) {
            this.signInForm.disable();
        } else {
            this.signInForm.enable();
        }
    }

    public get signingIn(): boolean {
        return this._signingIn;
    }

    public get displayName(): string {
        const groupedOrgName = this.environmentService.orgNameAsGrouping(
            this.environmentService.config.organisation.name,
        );
        switch (groupedOrgName) {
            case environment.GroupedOrganisationName.Upside: {
                return 'Upside';
            }

            default: {
                return 'NurtureCloud';
            }
        }
    }

    public ngOnInit(): void {
        this.redirectIfLoggedIn();
    }

    public isControlError(formControlName: string): boolean {
        return this.hasAttemptedSignIn && this.signInForm.get(formControlName).invalid;
    }

    public onGoogleSignIn(credentials: google.accounts.id.CredentialResponse): void {
        const { credential } = credentials;
        const { exp, email } = <{ exp: number; email: string }>jwtDecode(credential);

        this.signingIn = true;
        this.changeDetectorRef.detectChanges();

        this.userResource
            .signInGoogle({
                idToken: credential,
                expiresAt: exp * 1000,
                email,
            })
            .subscribe(
                u => this.onSuccessSignIn(u),
                e => {
                    this.onErrorSignIn(e, SignInMethod.Google);
                    this.signingIn = false;
                    this.changeDetectorRef.detectChanges();
                },
            );
    }

    public signIn() {
        this.hasAttemptedSignIn = true;
        if (this.signInForm.invalid) return;
        this.signingIn = true;
        this.userResource.signIn(this.signInForm.value).subscribe(
            u => this.onSuccessSignIn(u),
            e => {
                this.onErrorSignIn(e, SignInMethod.EmailPassword);
                // Only reset loading state if it fails, otherwise let it load until page redirects
                this.signingIn = false;
                this.changeDetectorRef.detectChanges();
            },
        );
    }

    private onSuccessSignIn(user: UserT): void {
        this.trackingService.trackUser();

        if (isInternal(user)) {
            if (this.nextParam) {
                this.windowRef.nativeWindow.location.href = this.nextParam;
                return;
            } else if (this.redirectToParam) {
                let paramsAsJson: RawParams;
                try {
                    paramsAsJson = JSON.parse(decodeURIComponent(this.redirectToParamsParam));
                } catch (e) {
                    // Do nothing, don't care if can't parse json
                }
                this.stateService
                    .go(this.redirectToParam, paramsAsJson)
                    // Go to internal if it cannot go to redirect, i.e. route doesn't exist
                    .catch(() => this.stateService.go('internal'));
                return;
            } else {
                this.stateService.go('internal');
                return;
            }
        } else {
            // If they're not an internal user then send them back to the vendor app
            this.stateService.go('home');
            return;
        }
    }

    private onErrorSignIn(error: HttpErrorResponse, signInMethod: SignInMethod): void {
        const fe = this.errorHandlerService.getFormattedError(error);
        let errorMessage: string = fe.message;

        switch (fe.code) {
            case userApi.signIn.post.ErrorCodes.Unauthorized:
                if (signInMethod === SignInMethod.EmailPassword) {
                    errorMessage = 'Your email or password was incorrect, please try again';
                } else {
                    errorMessage = 'Sign in with Google failed, please try again';
                }
                break;
            case userApi.signIn.post.ErrorCodes.LoginFeatureDisabled:
                errorMessage = loginFeatureDisabled;
                break;
        }

        this.notificationService.error(errorMessage);
    }

    private redirectIfLoggedIn(): void {
        const authData$ = this.userService.userAuthDetailsUpdated$.pipe(first());

        authData$
            .pipe(filter(authData => !authData.authenticated))
            .subscribe(() => (this.checkingSignedInState = false));

        authData$
            .pipe(
                filter(authData => authData.authenticated),
                // Prevent abrupt flashing of page and redirect if user is already logged in
                delay(2000),
            )
            .subscribe(u => {
                this.checkingSignedInState = false;
                this.onSuccessSignIn(u);
            });
    }
}
