import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    HostBinding,
    Inject,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';

import { UP_MODAL_CONTROLS } from '../../../../common/constants/modal-data.constant';
import { modal } from '../../../../common/models/modal.model';
import { ModalContentComponent } from '../modal-content/modal-content.component';
import { ModalFooterComponent } from '../modal-footer/modal-footer.component';

export type Size = 'x-small' | 'small' | 'medium' | 'large';

@Component({
    selector: 'nc-modal-dialog-v2',
    templateUrl: 'modal-dialog-v2.component.html',
    styleUrls: ['modal-dialog-v2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalDialogV2Component {
    @Output() public clickDismiss = new EventEmitter<void>();
    // Note: this component does not yet support the `fullScreenAtXs` functionality from the existing modal.
    @Input() public heading: string;
    @Input() public size: Size = 'medium';
    @Input() public headerRef: TemplateRef<any>;
    @Input() public isInitialising = false;
    @ContentChild(ModalFooterComponent)
    private modalFooterComponent: ModalFooterComponent;
    @ContentChild(ModalContentComponent)
    private modalContentComponent: ModalContentComponent;

    @HostBinding('class.has-footer')
    private get hasFooter(): boolean {
        return !!this.modalFooterComponent;
    }

    @HostBinding('class.has-content')
    private get hasContent(): boolean {
        return !!this.modalContentComponent;
    }

    @HostBinding('class.is-size-x-small')
    private get isSizeXSmall(): boolean {
        return this.size === 'x-small';
    }

    @HostBinding('class.is-size-small')
    private get isSizeSmall(): boolean {
        return this.size === 'small';
    }

    @HostBinding('class.is-size-medium')
    private get isSizeMedium(): boolean {
        return this.size === 'medium';
    }

    @HostBinding('class.is-size-large')
    private get isSizeLarge(): boolean {
        return this.size === 'large';
    }

    constructor(@Inject(UP_MODAL_CONTROLS) private modalControls: modal.Controls) {}

    public onClickDismissModal(): void {
        if (this.clickDismiss.observed) {
            this.clickDismiss.emit();
        } else {
            this.modalControls.close();
        }
    }
}
