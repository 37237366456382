import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from '../../store/apps-state.model';
import { GetTasks } from '../../store/task/task.actions';
import { TaskState } from '../../store/task/task.reducer';

@Injectable()
class TaskService {
    constructor(private store: Store<AppState>) {}

    public getTasksFromStore(): Observable<[TaskState]> {
        return this.store.select('tasks');
    }

    public loadTasksIntoStore(propertyId: string) {
        this.store.dispatch(new GetTasks(propertyId));
    }
}

export { TaskService };
