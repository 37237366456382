import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';

import { UpValidators } from '../../../../common/form-validators/validators';
import { AddressT } from '../../../../common/models/address.model';
import { TrackingService } from '../../../../common/services/tracking.service';
import { ConversionService } from '../../../../modules/core/services/conversion/conversion.service';
import { ConversionFacade } from '../../../../store/conversion/conversion.facade';

@Component({
    selector: 'up-report-property-data',
    templateUrl: 'report-property-data.component.html',
})
export class ReportPropertyDataComponent implements OnInit {
    @Input() public address: AddressT;
    @Input() public step: number;
    public form: FormGroup;
    public formSubmissionAttempted: boolean;

    constructor(
        private formBuilder: FormBuilder,
        private conversionFacade: ConversionFacade,
        private stateService: StateService,
        private conversionService: ConversionService,
        private trackingService: TrackingService,
    ) {}

    public ngOnInit(): void {
        this.conversionService.routeGuard(!this.address);

        this.createForm();
    }

    public getFieldError(fieldControlName: string): string {
        const field = this.form.get(fieldControlName);
        const errors = field.errors ? Object.keys(field.errors) : [];
        // since it's not possible for any of the fields to simultaneously have multiple errors,
        // ie. more than max, less than max, and required, then only the first error in the error
        // object needs to be displayed
        return this.formSubmissionAttempted && errors.length ? errors[0] : undefined;
    }

    public submit(): void {
        this.formSubmissionAttempted = true;

        if (this.form.valid) {
            const formValue = this.form.value;
            // convert all property data from strings to integers;
            Object.keys(formValue).forEach(key => (formValue[key] = parseInt(formValue[key])));

            this.conversionFacade.setAvmPropertyDetails(formValue);
            this.stateService.go('^.capture-details', { address: this.address });
        }
    }

    public skip(): void {
        this.trackingService.trackDataEvent('conversion', {
            subname: `skipPropertyDetails`,
        });
        this.stateService.go('^.capture-details', { address: this.address });
    }

    private createForm(): void {
        this.form = this.formBuilder.group({
            bedrooms: ['', [Validators.min(0), Validators.max(20), Validators.required, UpValidators.integerValidator]],
            bathrooms: [
                '',
                [Validators.min(0), Validators.max(20), Validators.required, UpValidators.integerValidator],
            ],
            carSpaces: [
                '',
                [Validators.min(0), Validators.max(20), Validators.required, UpValidators.integerValidator],
            ],
        });
    }
}
