import { Pipe, PipeTransform } from '@angular/core';

import { PropertyType } from '../models/property.model';

@Pipe({ name: 'upPrettyPropertyType' })
export class PrettyPropertyTypePipe implements PipeTransform {
    public transform(value: PropertyType | string): string {
        switch (value) {
            case PropertyType.SemiDetached:
                return 'Semi-detached';
            case PropertyType.BlockOfUnits:
                return 'Block of units';
            case PropertyType.OffThePlan:
                return 'Off the plan';
            default:
                return value;
        }
    }
}
