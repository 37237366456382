<div #focusables (blur)="dropdownItemsData.cancel()">
    <div
        role="option"
        tabindex="0"
        [id]="'dropdown-option-' + i"
        #focusable
        class="dropdown-item u-padding-vertical--2xs u-padding-horizontal--xs u-display--block u-text-align--left"
        (click)="onOptionSelected(option)"
        (keydown)="onKeyDown($event, option)"
        *ngFor="let option of dropdownItemsData.options; let i = index"
    >
        {{ option.content }}
    </div>
</div>
