import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../../common/components/icon/icon.module';
import { SpinnerModule } from '../spinner/spinner.module';

import { AlertComponent } from './components/alert/alert.component';

@NgModule({
    imports: [IconModule, CommonModule, SpinnerModule],
    exports: [AlertComponent],
    declarations: [AlertComponent],
})
export class AlertModule {}
