import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ThemeModule } from '../../../modules/theme/theme.module';

import { IconContainerComponent } from './components/icon-container/icon-container.component';
import { IconComponent } from './icon.component';

@NgModule({
    imports: [CommonModule, ThemeModule],
    exports: [IconComponent, IconContainerComponent],
    declarations: [IconComponent, IconContainerComponent],
})
export class IconModule {}
