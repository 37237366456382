import { FocusableOption } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
} from '@angular/core';

import { IconName } from '../../../../common/components/icon/icon.component';
import { MenuComponent } from '../menu/menu.component';

@Component({
    selector: '[nc-menu-list-item-content]',
    templateUrl: 'menu-list-item-content.component.html',
    styleUrls: ['menu-list-item-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuListItemContentComponent implements FocusableOption, OnDestroy, OnInit {
    @Input() public iconName: IconName;
    @HostBinding('class.is-indented')
    @Input()
    public indent = false;
    @HostBinding('attr.tabindex')
    public readonly tabindex = 0;
    private isHostDisabled = false;

    @HostBinding('attr.role') public hostRole = 'menuitem';
    @HostBinding('attr.disabled') public get disabledAttr(): true | null {
        if (this.isHostDisabled) {
            return true;
        } else {
            return this.isLoading ? true : null;
        }
    }
    @Input() public isLoading = false;
    // Using @HostBinding('attr.disabled') prevents the usage of the native disabled attribute,
    // this reimplements the native disabled attribute and also ensuring it works with the host binding
    // tied to the loading state above to maintain a manually disabled state where needed
    @Input()
    public set disabled(isDisabled: boolean) {
        this.isHostDisabled = coerceBooleanProperty(isDisabled);
        this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', String(this.isHostDisabled));
    }

    @HostBinding('class.is-appearance-large')
    public get isAppearanceLarge(): boolean {
        return this.menuComponent.appearance === 'large';
    }

    @HostBinding('class.is-appearance-small')
    public get isAppearanceSmall(): boolean {
        return this.menuComponent.appearance === 'small';
    }

    constructor(
        public readonly elementRef: ElementRef<HTMLAnchorElement | HTMLButtonElement>,
        private readonly renderer: Renderer2,
        private readonly menuComponent: MenuComponent,
    ) {}

    public focus(): void {
        this.elementRef.nativeElement.focus();
    }

    public ngOnInit(): void {
        this.menuComponent.registerMenuItem(this);
    }

    public ngOnDestroy(): void {
        this.menuComponent.deregisterMenuItem(this);
    }
}
