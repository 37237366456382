import { Pipe, PipeTransform } from '@angular/core';

/*
 *
 * Given a full filename path (including directory), this pipe
 * will return only the filename and extension.
 *
 * eg. static/legal-docs/document.docx --> document.docx
 *
 * */

@Pipe({
    name: 'upFilename',
})
class FilenamePipe implements PipeTransform {
    public transform(path: string): string {
        if (!path) return '';
        const pathElements = path.split('/');
        return pathElements[pathElements.length - 1];
    }
}

export { FilenamePipe };
