import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { NguiMapModule } from '@ngui/map';

import { UpsideCommonModule } from '../../../common/common';
import { TestIdDirective } from '../../../common/directive/test-id/test-id.directive';
import { SharedModule } from '../../../common/shared.module';
import { SharedVendorModule } from '../../../modules/shared-vendor/shared-vendor.module';

import { AddressPinComponent } from './address-pin/address-pin.component';
import { AppraisalLandingComponent } from './appraisal-landing/appraisal-landing.component';
import { ConversionAddressFoundComponent } from './conversion-address-found/conversion-address-found.component';
import { ConversionCaptureDetailsComponent } from './conversion-capture-details/conversion-capture-details.component';
import { ConversionCapturePhoneNumberComponent } from './conversion-capture-phone-number/conversion-capture-phone-number.component';
import { ConversionCompleteComponent } from './conversion-complete/conversion-complete.component';
import { ConversionQuestionnaireComponent } from './conversion-questionnaire/conversion-questionnaire.component';
import { ConversionStepComponent } from './conversion-step/conversion-step.component';
import { ConversionStepContainerComponent } from './conversion-step-container/conversion-step-container.component';
import { ConversionStepCtaWrapperComponent } from './conversion-step-cta-wrapper/conversion-step-cta-wrapper.component';

@NgModule({
    imports: [
        CommonModule,
        UpsideCommonModule,
        NguiMapModule.forRoot({ apiUrl: 'https://maps.google.com/maps/api/js?key=MY_GOOGLE_API_KEY' }),
        SharedModule,
        SharedVendorModule,
        GoogleMapsModule,
        TestIdDirective,
    ],
    declarations: [
        ConversionStepComponent,
        ConversionAddressFoundComponent,
        ConversionCaptureDetailsComponent,
        ConversionCompleteComponent,
        AddressPinComponent,
        ConversionQuestionnaireComponent,
        ConversionCapturePhoneNumberComponent,
        ConversionStepCtaWrapperComponent,
        AppraisalLandingComponent,
        ConversionStepContainerComponent,
    ],
    entryComponents: [ConversionCapturePhoneNumberComponent],
    providers: [],
    exports: [
        ConversionStepComponent,
        AddressPinComponent,
        ConversionCapturePhoneNumberComponent,
        ConversionStepCtaWrapperComponent,
        ConversionStepContainerComponent,
    ],
})
export class ConversionCommonModule {}
