<svg
    class="c-icon"
    #svg
    [ngClass]="{
        'c-icon--light': color === 'light',
        'c-icon--negative': color === 'negative',
        'c-icon--positive': color === 'positive',
        'c-icon--inverse': color === 'inverse',
        'c-icon--accent': color === 'accent',
        'c-icon--current-color': color === 'current-color' || color === 'currentColor',
        'c-icon--2x-small': size === '2x-small',
        'c-icon--x-small': size === 'x-small',
        'c-icon--small': size === 'small',
        'c-icon--large': size === 'large',
        'c-icon--x-large': size === 'x-large'
    }"
>
    <use class="u-pointer-events--none" [attr.href]="href" />
</svg>
