import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SpinnerModule } from '../spinner/spinner.module';
import { ThemeModule } from '../theme/theme.module';

import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';

@NgModule({
    imports: [SpinnerModule, CommonModule, ThemeModule],
    exports: [LoadingOverlayComponent],
    declarations: [LoadingOverlayComponent],
})
export class LoadingOverlayModule {}
