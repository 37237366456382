import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { finalize, retry } from 'rxjs/operators';

import { EnvironmentService } from '../../../../modules/core/services/environment.service';

@Injectable({ providedIn: 'root' })
export class IconSpriteService {
    private renderer: Renderer2;
    private spriteLoading = false;
    private readonly spriteContainerId = 'icon-sprite';

    private get spriteLoaded(): boolean {
        return !!this.document.getElementById(this.spriteContainerId);
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private environmentService: EnvironmentService,
        private httpClient: HttpClient,
        private rendererFactory: RendererFactory2,
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public load(): void {
        if (this.spriteLoading || this.spriteLoaded) return;

        this.spriteLoading = true;
        const spritePath = this.environmentService.prefixCdnUrl('/static/icon-sprite.svg');
        this.httpClient
            .get(spritePath, { responseType: 'text' })
            .pipe(
                retry(3),
                finalize(() => (this.spriteLoading = false)),
            )
            .subscribe(svg => {
                const div = this.renderer.createElement('div');
                this.renderer.setProperty(div, 'id', this.spriteContainerId);
                this.renderer.setProperty(div, 'innerHTML', svg);
                this.renderer.setStyle(div, 'display', 'none');
                this.renderer.appendChild(this.document.body, div);
            });
    }
}
