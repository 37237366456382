import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { Observable, Subject } from 'rxjs';
import { filter, first, map, skip, takeUntil } from 'rxjs/operators';

import { RequestCallBackFormData } from '../../../../common/components/request-call-back-form/request-call-back-form.component';
import { GetStartedFacade } from '../store/get-started.facade';

@Component({
    selector: 'up-get-started-callback',
    templateUrl: './get-started-callback.component.html',
})
export class GetStartedCallbackComponent implements OnInit, OnDestroy {
    public isLoading$: Observable<boolean>;
    public isError$: Observable<boolean>;
    public readonly errorMessage =
        'Whoops, your call back could not be requested. Please try again or call us on 1800 877 433.';
    private destroy$: Subject<void> = new Subject<void>();

    constructor(private getStartedFacade: GetStartedFacade, private stateService: StateService) {}

    public ngOnInit(): void {
        this.handleHasRequestedCallback();
        this.getStartedFacade.setHasStartedFlow(true);

        this.isLoading$ = this.getStartedFacade.loadingRequestCallback$;
        this.isError$ = this.getStartedFacade.errorRequestingCallback$.pipe(map((error: HttpErrorResponse) => !!error));
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    public onFormSubmit(formData: RequestCallBackFormData): void {
        this.getStartedFacade.requestCallback(formData);
    }

    private handleHasRequestedCallback(): void {
        // We take the first emission here and, if it is truthy, ie. the user has
        // requested a callback already, we redirect them back to the services page
        this.getStartedFacade.hasRequestedCallback$
            .pipe(
                first(),
                filter(hasRequestedCallback => hasRequestedCallback),
            )
            .subscribe(() => this.stateService.go('conversion.get-started'));

        // Here we send the user to the callback complete page if they have requested a callback.
        // We can skip the initial emission from the store as it is handled above, and we
        // only care about subsequent emissions after the user lands on this page.
        this.getStartedFacade.hasRequestedCallback$
            .pipe(
                takeUntil(this.destroy$),
                skip(1),
                filter(callbackRequested => callbackRequested),
            )
            .subscribe(() => this.stateService.go('conversion.get-started.complete'));
    }
}
