import { NgModule } from '@angular/core';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';

import { SharedModule } from '../../common/shared.module';
import { ConversionModule } from '../../components/conversion/conversion.module';
import { GetStartedModule } from '../../components/conversion/get-started/get-started.module';
import { LandingModule } from '../../components/landing/landing.module';
import { UIRouterConfigFn } from '../../ui-router/vendor-ui-router.config';
import { BrochureModule } from '../brochure/brochure.module';
import { ConversionFunnelPostOfiModule } from '../conversion-funnel-post-ofi/conversion-funnel-post-ofi.module';
import { four04FutureStates } from '../four04/four04.future-states';
import { SelfCheckInModule } from '../self-check-in/self-check-in.module';
import { SharedVendorModule } from '../shared-vendor/shared-vendor.module';
import { smsUnsubscribeFutureStates } from '../sms-unsubscribe/sms-unsubscribe.future-states';
import { ZendeskModule } from '../zendesk/zendesk.module';

import { VendorAppComponent } from './vendor-app.component';
import { vendorAppState } from './vendor-app.state';

export const states: Ng2StateDeclaration[] = [...vendorAppState, ...smsUnsubscribeFutureStates, ...four04FutureStates];

@NgModule({
    imports: [
        UIRouterModule.forChild({
            states,
            config: UIRouterConfigFn,
        }),
        SharedModule,
        SharedVendorModule,
        ZendeskModule,
        ConversionModule,
        GetStartedModule,
        ConversionFunnelPostOfiModule,
        SelfCheckInModule,
        BrochureModule,
        LandingModule,
    ],
    exports: [VendorAppComponent],
    declarations: [VendorAppComponent],
})
export class VendorAppModule {}
