import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';

@NgModule({
    imports: [CommonModule],
    exports: [ProgressBarComponent],
    declarations: [ProgressBarComponent],
})
export class ProgressBarModule {}
