import { checkInPath } from '../../common/constants/check-in.constants';
import { UpStateDeclaration } from '../../common/models/router.model';
import { NoopComponent } from '../../components/noop/noop.component';

import { CheckInComponent } from './components/check-in/check-in.component';
import { CheckInConfirmedComponent } from './components/check-in-confirmed/check-in-confirmed.component';

export const selfCheckInStates: UpStateDeclaration[] = [
    {
        name: 'self-check-in',
        parent: 'vendor',
        redirectTo: 'self-check-in.check-in',
    },
    {
        name: 'self-check-in.check-in',
        url: `/${checkInPath}/{propertyToken}?inspectionId`,
        params: {
            propertyToken: null,
            inspectionId: {
                value: null,
                dynamic: true,
                inherit: false,
            },
        },
        views: {
            'navbar@vendor-app': {
                component: NoopComponent,
            },
            'content@vendor-app': {
                component: CheckInComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
        data: {
            meta: {
                title: 'Self Check-in',
            },
        },
    },
    {
        name: 'self-check-in.check-in.confirmed',
        url: '/confirmed/?brochureToken&address&timezone&date&duration&name&phoneNumber&email',
        params: {
            brochureToken: null,
            address: null,
            timezone: null,
            date: null,
            duration: null,
            name: null,
            phoneNumber: null,
            email: null,
        },
        views: {
            'navbar@vendor-app': {
                component: NoopComponent,
            },
            'content@vendor-app': {
                component: CheckInConfirmedComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
        data: {
            meta: {
                title: 'Self Check-in Confirmed',
            },
        },
    },
];
