import { Injectable } from '@angular/core';
import { StateObject } from '@uirouter/core';
import { cloneDeep } from 'lodash-es';
import md5 from 'md5';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { UpStateDeclarationData } from '../../../common/models/router.model';

export interface BreadcrumbStateConfig {
    label?: string;
    skip?: boolean;
    disable?: boolean;
}

export interface Breadcrumb {
    name: string;
    params: { [key: string]: any };
    config: BreadcrumbStateConfig;
}

type Cache = Map<string, Breadcrumb>;

@Injectable({ providedIn: 'root' })
export class BreadcrumbsService {
    public breadcrumbs$: Observable<Breadcrumb[]>;
    private breadcrumbsSubject = new ReplaySubject<Breadcrumb[]>(1);
    private cacheSubject = new BehaviorSubject<Cache>(new Map());
    private readonly maxCacheSize = 20;

    constructor() {
        this.breadcrumbs$ = combineLatest([this.breadcrumbsSubject, this.cacheSubject]).pipe(
            map(([breadcrumbs, cache]) =>
                breadcrumbs.map(breadcrumb => {
                    const cacheKey = this.hashForBreadcrumb(breadcrumb);
                    const cachedBreadcrumb = cache.get(cacheKey);
                    return cachedBreadcrumb || breadcrumb;
                }),
            ),
        );
    }

    public createBreadcrumbs(states: StateObject[], allParams: { [paramName: string]: any }): void {
        const breadcrumbs: Breadcrumb[] = states
            // Don't want to show abstract routes, they do nothing
            .filter(state => !state.abstract)
            .map(state => {
                const routeSpecificParams = Object.entries(allParams)
                    // Only store params that are relevant to each state, since params are inherited it can contain
                    // inherited params that are not used by the specific route, and `params` property on the state
                    // object declares of all params that are relevant to that particular state
                    .filter(([key]) => Object.keys(state.params).includes(key))
                    .reduce(
                        (prev, [key, value]) => ({
                            ...prev,
                            [key]: value,
                        }),
                        {},
                    );

                // StateObject's `data` property uses prototypal inheritance to give child routes access to parent's data
                // we don't want this here when reading the `breadcrumb` property as we just want the data that was
                // defined for the current route declaration, so spread it to remove its prototypal inheritance
                const data: UpStateDeclarationData = { ...state.data };
                // Spread breadcrumb so we can clone it to prevent mutations to the actual state
                const config = { ...data.breadcrumb };
                return {
                    name: state.name,
                    params: routeSpecificParams,
                    config,
                };
            });
        this.breadcrumbsSubject.next(breadcrumbs);
    }

    public update(routeName: string, updatedConfig: BreadcrumbStateConfig, persist?: boolean): void {
        combineLatest([this.breadcrumbsSubject, this.cacheSubject])
            .pipe(first())
            .subscribe(([breadcrumbs, cache]) => {
                const matchingBreadcrumb = breadcrumbs.find(b => b.name === routeName);
                if (!matchingBreadcrumb) return;

                if (persist) {
                    // Clean up cache if it gets too big
                    if (cache.size >= this.maxCacheSize) {
                        const firstItemKey = Array.from(cache.keys())[0];
                        cache.delete(firstItemKey);
                    }

                    const clonedBreadcrumb = cloneDeep(matchingBreadcrumb);
                    clonedBreadcrumb.config = {
                        ...clonedBreadcrumb.config,
                        ...updatedConfig,
                    };

                    const cacheKey = this.hashForBreadcrumb(clonedBreadcrumb);
                    cache.set(cacheKey, clonedBreadcrumb);

                    this.cacheSubject.next(cache);
                } else {
                    matchingBreadcrumb.config = {
                        ...matchingBreadcrumb.config,
                        ...updatedConfig,
                    };
                    this.breadcrumbsSubject.next(breadcrumbs);
                }
            });
    }

    private hashForBreadcrumb(breadcrumb: Breadcrumb): string {
        // Sort params by their keys so it's always in the same order so the hash is consistent
        const params = Object.entries(breadcrumb.params).sort((a, b) => a[0].localeCompare(b[0]));
        return md5(JSON.stringify({ name: breadcrumb.name, params }));
    }
}
