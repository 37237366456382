import { Component, Input } from '@angular/core';

import { agent } from '../../models/agent.model';

@Component({
    selector: 'up-agent-rating-card',
    templateUrl: 'agent-rating-card.component.html',
    styleUrls: ['agent-rating-card.component.scss'],
})
export class AgentRatingCardComponent {
    @Input() public agent: agent.SimpleAgent;
}
