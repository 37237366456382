import { Action } from '@ngrx/store';

export const GET_TASKS = 'GET_TASKS';
export const TASKS_LOADED = 'TASKS_LOADED';

export class GetTasks implements Action {
    public readonly type = GET_TASKS;

    constructor(public payload: string) {}
}

export class TasksLoaded implements Action {
    public readonly type = TASKS_LOADED;

    constructor(public payload: string) {}
}

export type TaskAction = GetTasks | TasksLoaded;
