<ul *ngIf="items?.length" class="nav-bar-dropdown" #container [uiSrefActiveEq]="isActiveCSSClass">
    <ng-container *ngFor="let item of items">
        <li *ngIf="isItemVisible(item.visibility)">
            <div
                class="u-margin-horizontal--lg u-margin-vertical--xs"
                *ngIf="item.decoration === decorationEnum.Divider"
            >
                <hr class="c-horizontal-rule" />
            </div>
            <a
                class="nav-bar-dropdown-item"
                *ngIf="item.uiSref"
                #focusable
                [class.style-link]="item.style === styleEnum.Link"
                [dataEvent]="item.trackingEvent"
                [uiSref]="item.uiSref"
                [uiParams]="item.uiParams"
                [uiSrefActiveEq]="'is-active'"
            >
                <ng-container *ngTemplateOutlet="dropdownLinkContent"></ng-container>
            </a>
            <a
                class="nav-bar-dropdown-item"
                *ngIf="item.href"
                #focusable
                [class.style-link]="item.style === styleEnum.Link"
                [dataEvent]="item.trackingEvent"
                [href]="item.href"
            >
                <ng-container *ngTemplateOutlet="dropdownLinkContent"></ng-container>
            </a>
            <button
                class="nav-bar-dropdown-item"
                *ngIf="item.click"
                #focusable
                [class.style-link]="item.style === styleEnum.Link"
                [dataEvent]="item.trackingEvent"
                (click)="item.click()"
            >
                <ng-container *ngTemplateOutlet="dropdownLinkContent"></ng-container>
            </button>
            <ng-template #dropdownLinkContent>
                <up-icon
                    class="u-margin-right--sm"
                    *ngIf="item.icon"
                    [color]="'current-color'"
                    [name]="item.icon"
                ></up-icon>
                {{ item.title }}
                <up-pill class="u-margin-left--xs" *ngIf="item.new" [size]="'small'" [color]="'brand-secondary'">
                    New
                </up-pill>
            </ng-template>
        </li>
    </ng-container>
</ul>
