import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { media } from '../../../common/models/media.model';
import { transactionApi } from '../../../common/models/transaction-api.model';

@Injectable()
export class TransactionResource {
    private readonly baseUrl = '/api/v2';

    constructor(private httpClient: HttpClient) {}

    public getForProperty(propertyId: string) {
        return this.httpClient.get<transactionApi.transactions.get.Response>(
            `${this.baseUrl}/property/${propertyId}/transactions/`,
        );
    }

    public cancel(transactionId: string) {
        return this.httpClient.post<transactionApi.cancel.post.Response>(
            `${this.baseUrl}/transaction/${transactionId}/cancel`,
            undefined,
        );
    }

    public debitDeferred(transactionId: string, receipt: media.File) {
        return this.httpClient.post<transactionApi.debitDeferred.post.Response>(
            `${this.baseUrl}/transaction/${transactionId}/debit-deferred`,
            <transactionApi.debitDeferred.post.Request>{ receipt },
        );
    }

    public complete(transactionId: string, receipt: media.File) {
        return this.httpClient.post<transactionApi.complete.post.Response>(
            `${this.baseUrl}/transaction/${transactionId}/complete`,
            <transactionApi.complete.post.Request>{ receipt },
        );
    }

    public triggerInvoice(transactionId: string) {
        return this.httpClient.post<transactionApi.triggerInvoice.post.Response>(
            `${this.baseUrl}/transaction/${transactionId}/trigger-invoice`,
            undefined,
        );
    }

    public triggerDirectDebit(transactionId: string) {
        return this.httpClient.post<transactionApi.TriggerDirectDebit.POST.Response>(
            `${this.baseUrl}/transaction/${transactionId}/trigger-direct-debit`,
            undefined,
        );
    }

    public createForProperty(propertyId: string, body: transactionApi.createForProperty.POST.Request) {
        return this.httpClient.post<transactionApi.createForProperty.POST.Response>(
            `${this.baseUrl}/property/${propertyId}/transaction/`,
            body,
        );
    }

    public updateProducts(transactionId: string, body: transactionApi.UpdateProducts.PATCH.Request) {
        return this.httpClient.patch<transactionApi.UpdateProducts.PATCH.Response>(
            `${this.baseUrl}/transaction/${transactionId}`,
            body,
        );
    }
}
