import { Transition } from '@uirouter/angular';

import { getStandardUpsideMetaDescription } from '../../../common/utilities/meta.utilities';
import { featureGuard } from '../../../ui-router/feature-guard';
import { FooterMegaComponent } from '../../footer/footer-mega/footer-mega.component';
import { NavMinimalComponent } from '../../nav/nav-minimal/nav-minimal.component';
import { NoopComponent } from '../../noop/noop.component';
import { AppraisalBookerComponent } from '../common/appraisal-booker/appraisal-booker.component';
import { AppraisalLandingComponent } from '../common/appraisal-landing/appraisal-landing.component';
import { ConversionAddressFoundComponent } from '../common/conversion-address-found/conversion-address-found.component';
import { ConversionCaptureDetailsComponent } from '../common/conversion-capture-details/conversion-capture-details.component';
import { ConversionCapturePhoneNumberComponent } from '../common/conversion-capture-phone-number/conversion-capture-phone-number.component';
import { ConversionCompleteComponent } from '../common/conversion-complete/conversion-complete.component';
import { ConversionQuestionnaireComponent } from '../common/conversion-questionnaire/conversion-questionnaire.component';
import {
    addressResolveFn,
    onEnterAddFacebookTrackingParams,
    propertyAnswerTokenResolveFn,
    propertyIdResolveFn,
    serviceStatusResolveFn,
    stepResolveFn,
    submissionErrorResolveFn,
    userResolveFn,
} from '../common/resolvers';
import { NcConversionFunnelStateDeclaration } from '../conversion.states';

import { AppraisalRequestedComponent } from './appraisal-requested/appraisal-requested.component';
import { AppraisalComponent } from './appraisal.component';

const totalSteps = 5;

export function funnelNameResolveFn() {
    return 'appraisal';
}

export function appraisalRequestedResolveFn(trans) {
    return trans.params().appraisalAlreadyRequested;
}

export const appraisalStates: NcConversionFunnelStateDeclaration[] = [
    {
        name: 'conversion.appraisal',
        url: '/appraisal',
        views: {
            conversion: {
                component: AppraisalComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
        redirectTo: 'conversion.appraisal.address',
        data: {
            meta: {
                title: 'Free Property Market Appraisal',
                description: getStandardUpsideMetaDescription(),
            },
            featureGuard: featureGuard({ featureFlag: 'vendorAppraisalRequestsEnabled' }),
        },
    },
    {
        name: 'conversion.appraisal.address',
        url: '/address',
        params: {
            submissionError: '',
            appraisalAlreadyRequested: false,
        },
        views: {
            appraisal: {
                component: AppraisalLandingComponent,
            },
            'footer@vendor-app': {
                component: FooterMegaComponent,
            },
        },
        resolve: [
            {
                token: 'submissionError',
                deps: [Transition],
                resolveFn: submissionErrorResolveFn,
            },
            {
                token: 'appraisalAlreadyRequested',
                deps: [Transition],
                resolveFn: appraisalRequestedResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
        ],
        data: {
            navConfig: {
                hideCta: true,
            },
            featureGuard: featureGuard({ featureFlag: 'vendorAppraisalRequestsEnabled' }),
        },
    },
    {
        name: 'conversion.appraisal.address-found',
        url: '/address-found',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            appraisal: {
                component: ConversionAddressFoundComponent,
            },
        },
        params: {
            address: undefined,
            step: 1,
        },
        resolve: [
            {
                token: 'address',
                deps: [Transition],
                resolveFn: addressResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
        ],
        data: {
            globalProgress: 1 / totalSteps,
        },
    },
    {
        name: 'conversion.appraisal.capture-details',
        url: '/details?agent&suburb',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            appraisal: {
                component: ConversionCaptureDetailsComponent,
            },
        },
        params: {
            user: undefined,
            address: undefined,
            agent: undefined,
            suburb: undefined,
            step: 2,
        },
        resolve: [
            {
                token: 'user',
                deps: [Transition],
                resolveFn: userResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
            {
                token: 'address',
                deps: [Transition],
                resolveFn: addressResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
        ],
        data: {
            globalProgress: 2 / totalSteps,
        },
        onEnter: onEnterAddFacebookTrackingParams,
    },
    {
        name: 'conversion.appraisal.phone',
        url: '/contact',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            appraisal: {
                component: ConversionCapturePhoneNumberComponent,
            },
        },
        params: {
            user: undefined,
            step: 2,
        },
        resolve: [
            {
                token: 'user',
                deps: [Transition],
                resolveFn: userResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
        ],
        data: {
            globalProgress: 2 / totalSteps,
        },
    },
    {
        name: 'conversion.appraisal.complete',
        url: '/complete',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            appraisal: {
                component: ConversionCompleteComponent,
            },
        },
        params: {
            user: undefined,
            showCompleteState: false,
            serviceStatus: undefined,
        },
        resolve: [
            {
                token: 'user',
                deps: [Transition],
                resolveFn: userResolveFn,
            },
            {
                token: 'serviceStatus',
                deps: [Transition],
                resolveFn: serviceStatusResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
        ],
        data: {
            globalProgress: 2 / totalSteps,
        },
    },
    {
        name: 'conversion.appraisal.book-time',
        url: '/book-time',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            appraisal: {
                component: AppraisalBookerComponent,
            },
        },
        params: {
            address: undefined,
            step: 3,
        },
        resolve: [
            {
                token: 'address',
                deps: [Transition],
                resolveFn: addressResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
        ],
        data: {
            globalProgress: 3 / totalSteps,
        },
    },
    {
        name: 'conversion.appraisal.questionnaire',
        url: '/questionnaire?agent&suburb',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            appraisal: {
                component: ConversionQuestionnaireComponent,
            },
        },
        params: {
            address: undefined,
            propertyAnswerToken: undefined,
            propertyId: undefined,
            agent: undefined,
            suburb: undefined,
            step: 4,
        },
        resolve: [
            {
                token: 'address',
                deps: [Transition],
                resolveFn: addressResolveFn,
            },
            {
                token: 'propertyAnswerToken',
                deps: [Transition],
                resolveFn: propertyAnswerTokenResolveFn,
            },
            {
                token: 'propertyId',
                deps: [Transition],
                resolveFn: propertyIdResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
        ],
        data: {
            globalProgress: 4 / totalSteps,
        },
        onEnter: onEnterAddFacebookTrackingParams,
    },
    {
        name: 'conversion.appraisal.requested',
        url: '/requested',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            appraisal: {
                component: AppraisalRequestedComponent,
            },
            'footer@vendor-app': {
                component: FooterMegaComponent,
            },
        },
        params: {
            propertyId: undefined,
            address: undefined,
        },
        resolve: [
            {
                token: 'propertyId',
                deps: [Transition],
                resolveFn: propertyIdResolveFn,
            },
            {
                token: 'address',
                deps: [Transition],
                resolveFn: addressResolveFn,
            },
        ],
        data: {
            globalProgress: 5 / totalSteps,
        },
    },
];
