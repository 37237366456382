import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { IconModule } from '../../common/components/icon/icon.module';
import { HiddenContentIndicatorModule } from '../hidden-content-indicator/hidden-content-indicator.module';
import { MenuModule } from '../menu/menu.module';

import { CountrySelectorComponent } from './components/country-selector/country-selector.component';

@NgModule({
    imports: [MenuModule, IconModule, CommonModule, HiddenContentIndicatorModule, LazyLoadImageModule],
    exports: [CountrySelectorComponent],
    declarations: [CountrySelectorComponent],
})
export class CountrySelectorModule {}
