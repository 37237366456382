import { Pipe, PipeTransform } from '@angular/core';

import { EnvironmentService } from '../../../modules/core/services/environment.service';

@Pipe({
    name: 'imageResize',
})
export class ImageResizePipe implements PipeTransform {
    constructor(private readonly environmentService: EnvironmentService) {}

    public transform(imageUrl: any, width: number, height: number): string {
        if (!imageUrl) return;
        if (imageUrl.includes('googleusercontent')) {
            return `${imageUrl}=w${width}-h${height}`;
        } else if (imageUrl.includes('bucket-api.domain.com.au')) {
            return `${imageUrl}/${height}x${width}`;
        } else if (imageUrl.includes('dynamics.net/s3/')) {
            return `${imageUrl}?width=${width}&height=${height}`;
        } else if (imageUrl.includes('storage.nurturecloud.app')) {
            return `${this.environmentService.config.organisation.cloudflareImagesRootUrl}width=${width},height=${height}/${imageUrl}`;
        } else {
            return imageUrl;
        }
    }
}
