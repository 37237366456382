import { phase } from '../../models/phase.model';
import { LeadStatus, Property } from '../../models/property.model';

export function canMovePropertyFromPoolToProspect(property?: Property): boolean {
    if (!property) return false;

    return (
        property.leadStatus === LeadStatus.Pool &&
        [
            phase.Phases.AgentLead,
            phase.Phases.Prospect,
            phase.Phases.Pipeline,
            phase.Phases.Research,
            phase.Phases.Appraisal,
        ].includes(property.phase)
    );
}

export function prettyAreaUnitOfMeasurement(value: Property.AreaUnitOfMeasurement): string {
    switch (value) {
        case Property.AreaUnitOfMeasurement.SquareMetres:
            return 'm²';
        case Property.AreaUnitOfMeasurement.Acres:
            return 'ac';
        case Property.AreaUnitOfMeasurement.Hectares:
            return 'ha';
        default:
            return value;
    }
}
