<a
    *ngIf="upSref"
    [uiSref]="upSref"
    [uiParams]="upSrefParams"
    [uiOptions]="upSrefOptions"
    [attr.target]="target"
    class="c-link"
    ><ng-container *ngTemplateOutlet="contentTemplate"></ng-container
></a>
<a *ngIf="href" [href]="href" class="c-link" [attr.target]="target"
    ><ng-container *ngTemplateOutlet="contentTemplate"></ng-container
></a>
<button (click)="click()" *ngIf="!upSref && !href" type="button" class="c-link"
    ><ng-container *ngTemplateOutlet="contentTemplate"></ng-container
></button>
<ng-template #contentTemplate><ng-content></ng-content></ng-template>
