import { ModuleWithProviders, NgModule } from '@angular/core';

import { ScriptInjectorService } from './service/script-injector/script-injector.service';

@NgModule({ providers: [ScriptInjectorService] })
export class ScriptInjectorModule {
    public static forRoot(): ModuleWithProviders<ScriptInjectorModule> {
        return {
            ngModule: ScriptInjectorModule,
            providers: [ScriptInjectorService],
        };
    }
}
