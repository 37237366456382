import { animate, state, style, transition, trigger } from '@angular/animations';

// -nt suffix is used for no transition states
export type CollapseAnimationState = 'disabled' | 'collapsed' | 'collapsed-nt' | 'expanded' | 'expanded-nt';

const params = { timing: '225ms cubic-bezier(0.4,0.0,0.2,1)' };

export const collapseAnimation = trigger('collapse', [
    // Subpixel value is to work around safari optimising elements that have a height of 0 by not always immediately
    // rendering contents inside and causes issue with manipulating/querying the DOM
    state('collapsed, collapsed-nt', style({ height: '0.05px' })),
    state('expanded, expanded-nt, disabled', style({ height: '*' })),
    transition('expanded <=> collapsed', animate('{{ timing }}'), { params }),
    transition('expanded-nt => collapsed', animate('{{ timing }}'), { params }),
    transition('collapsed-nt => expanded', animate('{{ timing }}'), { params }),
]);
