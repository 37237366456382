import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

import { TrackingService } from '../../../../common/services/tracking.service';

@Component({
    selector: 'nc-post-ofi-page',
    templateUrl: 'post-ofi-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostOfiPageComponent implements OnInit {
    constructor(@Inject(PLATFORM_ID) private platformId: object, private trackingService: TrackingService) {}

    public ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.trackingService.trackEvent('postOfiPageView');
        }
    }
}
