import { Injector } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { UpStateDeclaration } from '../../../common/models/router.model';
import { get404RedirectTargetState } from '../../../common/utilities/routing/get-404-redirect-target-state/get-404-redirect-target-state.util';
import { PermissionsService } from '../services/permissions/permissions.service';

export const permissionUiRouterHook = (uiRouter: UIRouter, injector: Injector) => {
    uiRouter.transitionService.onEnter({}, transition => {
        const permission = (<UpStateDeclaration>transition.to()).data?.permission;

        if (!permission) return true;

        const permissionsService = injector.get(PermissionsService);

        return firstValueFrom(
            permissionsService.has(permission).pipe(
                map(hasPermission => {
                    if (hasPermission) return true;

                    return get404RedirectTargetState(transition);
                }),
            ),
        );
    });
};
