import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StorageResource } from '../../modules/core/resources/storage.resource';
import { cloudStorage } from '../models/cloud-storage.model';
import { MediaTagName } from '../models/domain/media/media-tag-name.model';
import { media } from '../models/media.model';

export type UploadUrlGenerator = (
    mediaTag: MediaTagName,
    fileMeta: cloudStorage.FileUploadMetaData,
) => Observable<media.UrlResponse>;

@Injectable()
export class StorageService {
    constructor(private storageResource: StorageResource) {}

    public getPropertyFileUploadUrlGenerator(propertyId: string): UploadUrlGenerator {
        return (mediaTag, fileMeta) => this.storageResource.getPropertyFileUploadUrl(propertyId, mediaTag, fileMeta);
    }

    public getBuyerFileUploadUrlGenerator(propertyId: string, buyerId: string): UploadUrlGenerator {
        return (mediaTag, fileMeta) =>
            this.storageResource.getBuyerFileUploadUrl(propertyId, buyerId, mediaTag, fileMeta);
    }

    public getUserFileUploadUrlGenerator(userId: string): UploadUrlGenerator {
        return (mediaTag, fileMeta) => this.storageResource.getUserFileUploadUrl(userId, mediaTag, fileMeta);
    }
}
