import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { isKeyboardUserClassName } from '../../../settings/focusables';

@Injectable()
export class KeyboardUserHelperService {
    private _isKeyboardUser$ = new BehaviorSubject<boolean>(false);

    public get isKeyboardUser$(): Observable<boolean> {
        return this._isKeyboardUser$.asObservable();
    }

    constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(DOCUMENT) private document: Document) {}

    // Adds class 'is-keyboard-user' to the body when a keyboard user is detected to show focus states on interactable
    // elements. This prevents the focus halo from appearing when users are just clicking about.
    public showFocusState(event: KeyboardEvent): void {
        if (!isPlatformBrowser(this.platformId)) return;

        this.isKeyboardUser$
            .pipe(
                take(1),
                filter(is => !is && event.code === 'Tab'),
            )
            .subscribe(() => {
                this.document.body.classList.add(isKeyboardUserClassName);
                this._isKeyboardUser$.next(true);
            });
    }
}
