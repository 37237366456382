import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UpsideCommonModule } from '../../common/common';
import { SharedModule } from '../../common/shared.module';
import { NavigationModule } from '../../modules/navigation/navigation.module';

import { NavBarComponent } from './common/nav-bar/nav-bar.component';
import { NavBarCtaComponent } from './common/nav-bar-cta/nav-bar-cta.component';
import { NavBarDropdownComponent } from './common/nav-bar-dropdown/nav-bar-dropdown.component';
import { NavBarItemComponent } from './common/nav-bar-item/nav-bar-item.component';
import { NavBarUserComponent } from './common/nav-bar-user/nav-bar-user.component';
import { NavPaneComponent } from './common/nav-pane/nav-pane.component';
import { NavPanePrimaryItemComponent } from './common/nav-pane-primary-item/nav-pane-primary-item.component';
import { NavPaneSecondaryItemComponent } from './common/nav-pane-secondary-item/nav-pane-secondary-item.component';

@NgModule({
    imports: [CommonModule, UpsideCommonModule, A11yModule, SharedModule, NavigationModule],
    exports: [
        NavBarComponent,
        NavBarItemComponent,
        NavBarDropdownComponent,
        NavBarCtaComponent,
        NavBarUserComponent,
        NavPaneComponent,
        NavPanePrimaryItemComponent,
        NavPaneSecondaryItemComponent,
    ],
    declarations: [
        NavBarComponent,
        NavBarItemComponent,
        NavBarDropdownComponent,
        NavBarCtaComponent,
        NavBarUserComponent,
        NavPaneComponent,
        NavPanePrimaryItemComponent,
        NavPaneSecondaryItemComponent,
    ],
})
export class NavCommonModule {}
