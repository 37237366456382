<section class="u-padding-bottom--3xl">
    <div class="inner">
        <up-container>
            <nav *ngIf="showLogo">
                <a class="u-display--block u-margin-bottom--4xl logo-link" [uiSref]="'home'">
                    <img
                        class="logo"
                        [src]="'/static/images/organisations/logos/' + themeName + '-regular.svg' | upCdnUrl"
                        alt="{{ orgName }} Home"
                    />
                </a>
            </nav>

            <header class="u-margin-bottom--xl">
                <h1 class="u-margin-bottom--sm u-text-heading--lg"> <ng-content select="[heading]"></ng-content> </h1>

                <ng-content select="[caption]"></ng-content>
            </header>

            <div class="u-margin-bottom--xl"> <ng-content></ng-content> </div>

            <footer class="u-text-body--sm"> <ng-content select="[footer]"></ng-content> </footer>
        </up-container>
    </div>
</section>
