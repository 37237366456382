import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../../../common/shared.module';
import { ConversionCommonModule } from '../conversion-common.module';

import { AgentReviewCardComponent } from './agent-review-card/agent-review-card.component';
import { AppraisalBookerCalendarComponent } from './appraisal-booker-calendar/appraisal-booker-calendar.component';
import { AppraisalBookerDayComponent } from './appraisal-booker-day/appraisal-booker-day.component';
import { AppraisalBookerTimesComponent } from './appraisal-booker-times/appraisal-booker-times.component';
import { AppraisalBookerComponent } from './appraisal-booker.component';

@NgModule({
    imports: [CommonModule, ConversionCommonModule, SharedModule],
    declarations: [
        AppraisalBookerDayComponent,
        AppraisalBookerCalendarComponent,
        AppraisalBookerTimesComponent,
        AppraisalBookerComponent,
        AgentReviewCardComponent,
    ],
    entryComponents: [AppraisalBookerComponent],
})
export class AppraisalBookerModule {}
