import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { productsApi } from '../../../common/models/products-api.model';
import { Products } from '../../../common/models/products.model';

const baseUrl = `/api/v2`;
const headersConfig = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

@Injectable()
export class ProductsResource {
    constructor(private http: HttpClient) {}

    public get(productId: Products.ProductId) {
        return this.http.get<productsApi.product.get.Response>(`${baseUrl}/products/${productId}`, headersConfig);
    }

    public getForProperty(propertyId: string) {
        return this.http.get<productsApi.forProperty.get.Response>(
            `${baseUrl}/property/${propertyId}/products/`,
            headersConfig,
        );
    }

    public all() {
        return this.http.get<productsApi.products.get.Response>(`${baseUrl}/products`, headersConfig);
    }

    public update(productId: string, product: productsApi.update.post.Request) {
        return this.http.put<productsApi.update.post.Response>(
            `${baseUrl}/products/${productId}`,
            product,
            headersConfig,
        );
    }

    public create(product: productsApi.create.post.Request) {
        return this.http.post<productsApi.create.post.Response>(`${baseUrl}/products/create`, product, headersConfig);
    }

    public getForAgent(agentId: string) {
        return this.http.get<productsApi.forAgent.get.Response>(`${baseUrl}/products/agent/${agentId}/`, headersConfig);
    }

    public updateAgentFee(productId: string, agentId: string, product: productsApi.agentFee.post.Request) {
        return this.http.post<productsApi.agentFee.post.Response>(
            `${baseUrl}/products/${productId}/agent/${agentId}/`,
            product,
            headersConfig,
        );
    }
}
