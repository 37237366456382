import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IconName } from '../icon/icon.component';

export interface Tab {
    label: string;
    value: string;
    iconName?: IconName;
}

@Component({
    selector: 'up-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
    @Input() public tabs: Tab[] = [];
    @Input() public activeTabIndex = 0;
    @Input() public showBottomBorder: boolean;
    @Input() public disabled: boolean;
    @Output() public tabSelected: EventEmitter<Tab> = new EventEmitter<Tab>();

    public onTabSelected(tab: Tab): void {
        this.tabSelected.emit(tab);
        this.activeTabIndex = this.tabs.indexOf(tab);
    }

    public isTabActive(tab: Tab): boolean {
        if (!this.tabs || !this.tabs[this.activeTabIndex] || !tab) return false;
        return tab.value === this.tabs[this.activeTabIndex].value;
    }
}
