import {
    AfterContentInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    HostBinding,
    Input,
    OnDestroy,
    QueryList,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';

import { AccordionPanelComponent } from '../accordion-panel/accordion-panel.component';

export type Appearance = 'plain' | 'grey-header';

@Component({
    selector: 'up-accordion, nc-accordion',
    templateUrl: 'accordion.component.html',
    styleUrls: ['accordion.component.scss'],
})
export class AccordionComponent implements OnDestroy, AfterContentInit {
    // flag to allow multiple open panels
    @Input() public multi: boolean;
    @Input() public heading: string;
    @ContentChildren(AccordionPanelComponent) private accordionPanels: QueryList<AccordionPanelComponent>;
    private destroy$ = new Subject<void>();
    private readonly appearance$ = new BehaviorSubject<Appearance>('plain');

    @Input()
    public set appearance(appearance: Appearance) {
        this.appearance$.next(appearance);
    }

    @HostBinding('class.is-appearance-grey-header')
    public get isAppearanceGreyHeader(): boolean {
        return this.appearance$.value === 'grey-header';
    }

    @HostBinding('class.is-appearance-plain')
    public get isAppearancePlain(): boolean {
        return this.appearance$.value === 'plain';
    }

    constructor(private readonly changeDetectionRef: ChangeDetectorRef) {}

    public ngAfterContentInit(): void {
        this.accordionPanels.forEach(panel => {
            panel.stateChange
                .pipe(
                    takeUntil(this.destroy$),
                    filter(state => state === 'expanded'),
                )
                .subscribe(() => this.panelOpened(panel));
        });

        combineLatest([this.appearance$, this.accordionPanels.changes.pipe(startWith(this.accordionPanels.toArray()))])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([appearance, accordionPanels]) =>
                accordionPanels?.forEach(accordionPanel => (accordionPanel.appearance = appearance)),
            );
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private panelOpened(panelComponent: AccordionPanelComponent): void {
        if (this.multi) return;

        this.accordionPanels.forEach(panel => {
            if (panel !== panelComponent) {
                panel.closePanel();
            }
        });
    }
}
