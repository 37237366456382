import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { first, map } from 'rxjs/operators';

import { conversion } from '../../../../common/models/conversion.model';
import { error } from '../../../../common/models/error.model';
import { User } from '../../../../common/models/user.model';
import { AddressService } from '../../../../common/services/address.service';
import { ErrorHandlerService } from '../../../../common/services/error-handler.service';
import { TrackingService } from '../../../../common/services/tracking.service';
import { UserService, UserT } from '../../../../common/services/user.service';
import { UtmService } from '../../../../common/services/utm.service';
import { AppraisalResource } from '../../../../modules/core/resources/appraisal.resource';
import {
    ConversionNextState,
    ConversionRequestStatus,
    ConversionService,
} from '../../../../modules/core/services/conversion/conversion.service';
import { propertyReport } from '../../../../modules/property-report/property-report.model';
import { ConversionFacade } from '../../../../store/conversion/conversion.facade';

type ServiceStatus = 'Servicable' | 'Fringe' | 'Unservicable';

@Component({
    selector: 'up-conversion-complete',
    templateUrl: './conversion-complete.component.html',
    styleUrls: ['./conversion-complete.component.scss'],
})
export class ConversionCompleteComponent implements OnInit {
    @Input() public user: User;
    @Input() public serviceStatus: ServiceStatus;
    @Input() public funnelName: conversion.Funnel;
    public loading = true;
    public authData: UserT;
    private readonly staffNotAllowedErrorMessage =
        'This action is only meant for customers. You are logged in with a staff account. Please log out and try again.';
    private readonly genericErrorMessage = 'Error communicating with server';

    constructor(
        private stateService: StateService,
        private conversionService: ConversionService,
        private userService: UserService,
        private utmService: UtmService,
        private addressService: AddressService,
        private trackingService: TrackingService,
        private appraisalResource: AppraisalResource,
        private conversionFacade: ConversionFacade,
        private errorHandlerService: ErrorHandlerService,
    ) {
        this.userService.userAuthDetailsUpdated$.subscribe(authData => (this.authData = authData));
    }

    public ngOnInit() {
        if (!this.user) {
            this.conversionService.goToFunnelEntry(this.funnelName);
            return;
        }

        switch (this.funnelName) {
            case conversion.Funnel.Appraisal:
                this.appraisalComplete();
                break;
            case conversion.Funnel.PropertyReport:
                this.propertyReportComplete();
                break;
            case conversion.Funnel.Booking:
                this.bookingComplete();
                break;
            case conversion.Funnel.Calendar:
                this.calendarComplete();
                break;
            default:
                break;
        }
    }

    private bookingComplete(): void {
        this.conversionFacade.appraisalState$.pipe(first()).subscribe(({ bookedTime }) => {
            if (bookedTime) {
                this.requestAppraisalWithBookedTime(bookedTime.clone().utc().format());
            } else {
                this.conversionService.goToFunnelEntry(this.funnelName);
            }
        });
    }

    private calendarComplete(): void {
        this.conversionFacade.appraisalState$.pipe(first()).subscribe(({ bookedTime, agent }) => {
            if (bookedTime && agent) {
                this.requestAppraisalWithBookedTime(bookedTime.clone().utc().format(), agent.id);
            } else {
                this.conversionService.goToFunnelEntry(this.funnelName);
            }
        });
    }

    private appraisalComplete() {
        if (!this.user) {
            this.conversionService.goToFunnelEntry(this.funnelName);
        } else {
            this.requestAppraisal();
        }
    }

    private propertyReportComplete() {
        let liveAVMPropertyDetails: propertyReport.CoreLogicLiveAvmPropertyDetails;

        this.conversionFacade.propertyDetails$
            .pipe(first())
            .subscribe(propertyDetails => (liveAVMPropertyDetails = propertyDetails));

        // attach Live AVM property details
        this.conversionService.propertyReport(this.user, liveAVMPropertyDetails).subscribe(
            result => {
                if (result.status === ConversionRequestStatus.ConversionReportDailyRequestLimitExceeded) {
                    this.conversionService.goToFunnelEntry(this.funnelName, {
                        propertyReportDailyRequestLimitExceeded: true,
                    });
                    return;
                }
                if (result.status === ConversionRequestStatus.ConversionRequestFailure) {
                    if (result.nextState === ConversionNextState.NoUser) {
                        this.conversionService.goToFunnelEntry(this.funnelName);
                    } else if (result.nextState === ConversionNextState.AlreadyRequested) {
                        this.conversionService.goToFunnelEntry(this.funnelName, {
                            propertyReportAlreadyRequested: true,
                        });
                    } else if (result.nextState === ConversionNextState.SubmissionFailed) {
                        this.conversionService.goToFunnelEntry(this.funnelName, {
                            submissionError: this.genericErrorMessage,
                        });
                    }
                } else if (result.status === ConversionRequestStatus.ConversionRequestSuccess) {
                    this.conversionFacade.setReportPropertyToken(result.propertyToken);
                    this.stateService.go('^.questionnaire', {
                        address: this.user.address,
                        propertyAnswerToken: result.propertyAnswerToken,
                        propertyId: result.propertyId,
                        funnelName: this.funnelName,
                    });
                } else if (result.status === ConversionRequestStatus.ConversionStaffNotAllowed) {
                    this.conversionService.goToFunnelEntry(this.funnelName, {
                        submissionError: this.staffNotAllowedErrorMessage,
                    });
                }
            },
            (error: HttpErrorResponse) => this.handleConversionSubmissionError(error),
        );
    }

    private trackServiceStatus(serviceStatus: ServiceStatus): void {
        if (serviceStatus === 'Servicable') {
            this.trackingService.trackEvent('serviceableAppraisal');
        } else if (serviceStatus === 'Fringe') {
            this.trackingService.trackEvent('fringeAppraisal');
        } else {
            this.trackingService.trackEvent('nonServiceableAppraisal');
        }
    }

    private requestAppraisalWithBookedTime(bookedTime: string, forAgent?: string): void {
        const { firstName, lastName, email, phoneNumber, address } = this.user;
        this.appraisalResource
            .requestAppraisal({
                user: {
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                },
                utm: this.utmService.getStoredUtmCodes(),
                dateTime: bookedTime,
                address,
                agent: forAgent,
            })
            .subscribe(
                ({ body, status }) => {
                    // if the appraisal already existed and booking was not created in this request, then
                    // the booking already exists (since this request always sends a booking dateTime) and
                    // we should send them back to the start with the appraisalAlreadyRequested error
                    if (status === 200 && !body.bookingCreated) {
                        this.conversionService.goToFunnelEntry(this.funnelName, { appraisalAlreadyRequested: true });
                        return;
                    }

                    // Response is slightly different depending on user auth state
                    this.serviceStatus = body.service || body.property.service;
                    this.trackingService.trackEvent('appraisalRequested');
                    this.trackServiceStatus(this.serviceStatus);

                    this.stateService.go('^.questionnaire', {
                        address: this.user.address,
                        propertyAnswerToken: body.propertyAnswerToken,
                        propertyId: body.property && body.property.id,
                        funnelName: this.funnelName,
                    });
                    this.conversionService.resetConversionState();
                },
                (error: HttpErrorResponse) => {
                    const errorCode = this.errorHandlerService.getErrorCode(error);

                    // In the case that the user's time is booked between the time they select a time and complete
                    // their appraisal request, we send them back to the time picker screen to select a new time
                    if (errorCode === 'CALENDAR_TIME_UNAVAILABLE') {
                        this.stateService.go('^.book-time', {
                            address: this.user.address,
                            calendarTimeUnavailable: true,
                        });
                    } else {
                        this.handleConversionSubmissionError(error);
                    }
                },
            );
    }

    private requestAppraisal() {
        const suburbId = this.addressService.createSuburbIdFromAddress(this.user.address);
        this.conversionService.getSuburbAgent(suburbId, this.user.address.geoLocation);
        const { firstName, lastName, email, phoneNumber, address } = this.user;
        this.appraisalResource
            .requestAppraisal({
                user: {
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                },
                utm: this.utmService.getStoredUtmCodes(),
                address,
            })
            .subscribe(
                ({ body, status }) => {
                    // If the appraisal already exists and is already booked, we should send them back
                    // to the start of the flow with the 'appraisalAlreadyRequested' error. Otherwise,
                    // they can proceed to the calendar to pick a time, regardless of whether the appraisal
                    // was created in this request or not.
                    if (status === 200 && body.booked) {
                        this.conversionService.goToFunnelEntry(this.funnelName, { appraisalAlreadyRequested: true });
                        return;
                    }
                    // Response is slightly different depending on user auth state
                    this.serviceStatus = body.service || body.property.service;
                    this.trackingService.trackEvent('appraisalRequested');
                    this.trackServiceStatus(this.serviceStatus);
                    if (this.serviceStatus === 'Servicable' || this.serviceStatus === 'Fringe') {
                        this.conversionService
                            .getAppraisalStoreState()
                            .pipe(
                                first(state => !state.isLoadingAgent),
                                map(state => state.isBookable),
                            )
                            .subscribe(isBookable => {
                                if (isBookable) {
                                    this.stateService.go('conversion.appraisal.book-time');
                                } else {
                                    this.stateService.go('^.questionnaire', {
                                        address: this.user.address,
                                        propertyAnswerToken: body.propertyAnswerToken,
                                        propertyId: body.property && body.property.id,
                                        funnelName: this.funnelName,
                                    });
                                    this.conversionService.resetConversionState();
                                }
                            });
                    } else {
                        this.loading = false;
                    }
                },
                (error: HttpErrorResponse) => this.handleConversionSubmissionError(error),
            );
    }

    private handleConversionSubmissionError(httpErrorResponse: HttpErrorResponse): void {
        const errorCode = this.errorHandlerService.getErrorCode(httpErrorResponse);
        const errorMessageMap = {
            [error.Codes.StaffNotAllowed]: this.staffNotAllowedErrorMessage,
        };
        const submissionError = errorMessageMap[errorCode] || this.genericErrorMessage;

        this.conversionService.goToFunnelEntry(this.funnelName, { submissionError });
    }
}
