<label
    class="checkbox-container"
    [ngClass]="{
        'style-boxed': style === 'boxed',
        'is-style-icon-tag': style === 'icon-tag',
        'is-checked': checked,
        'is-focused': focused,
        'is-disabled': disabled,
        'is-error': error
    }"
    [for]="uid"
>
    <input
        #input
        class="checkbox u-hide-visually"
        type="checkbox"
        (click)="onInputClick($event)"
        (change)="onInputChange($event)"
        (focus)="onFocus()"
        (blur)="onBlur()"
        [id]="uid"
        [name]="name"
        [disabled]="disabled"
        [checked]="checked"
    />

    <div class="label-visual">
        <div class="input-focus-glow"></div>
        <svg class="checkmark-icon" width="12" height="9">
            <path
                d="M4.22 6.365L10.294.293a1 1 0 0 1 1.414 1.414l-6.78 6.78a1 1 0 0 1-1.414 0l-3.22-3.22a1 1 0 0 1 1.414-1.415l2.514 2.513z"
                fill-rule="nonzero"
            />
        </svg>
    </div>
    <div class="label-icon" *ngIf="style === 'icon-tag'">
        <ng-content select="[slot='icon']"></ng-content>
    </div>
    <div class="label-content u-padding-left--xs u-text-line-height--x-small"> <ng-content></ng-content> </div>
</label>
