import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { location } from '../../../../common/models/location.model';
import { GoogleMapService, StaticMapUrlConfig } from '../../../core/services/google-map.service';
import { ThemeService } from '../../../core/services/theme.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'img[nc-static-map]',
    templateUrl: 'static-map.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaticMapComponent {
    @Input() public coordinates: location.LatLong;
    @Input() public size: StaticMapUrlConfig['size'] = { width: 400, height: 300 };
    @Input() public scale: StaticMapUrlConfig['scale'] = 1;
    @Input() public zoom: StaticMapUrlConfig['zoom'] = 16;

    @HostBinding('src')
    public get staticMapUrl(): string {
        return this.googleMapService.getStaticMapUrl({
            center: this.coordinates,
            // Todo: It's worth considering have a separate StaticMapPinComponent (used here via ContentChildren) that
            //  can manually render custom markers.
            markers: [
                {
                    color: this.themeService.colors.standard3,
                    coordinates: this.coordinates,
                },
            ],
            zoom: this.zoom,
            size: this.size,
            scale: this.scale,
        });
    }

    constructor(private googleMapService: GoogleMapService, private themeService: ThemeService) {}
}
