import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { UIRouterGlobals } from '@uirouter/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { AddressT } from '../../../../common/models/address.model';
import { agent } from '../../../../common/models/agent.model';
import { conversion } from '../../../../common/models/conversion.model';
import { AddressService } from '../../../../common/services/address.service';
import { UserService, UserT } from '../../../../common/services/user.service';
import { doTranslation } from '../../../../common/utilities/i18n/do-translation.util';
import { SuburbResource } from '../../../../modules/core/resources/suburb.resource';
import { ConversionService } from '../../../../modules/core/services/conversion/conversion.service';
import { ConversionFacade } from '../../../../store/conversion/conversion.facade';
import { GetAddressFromAddressFinderResultIdUseCase } from '../../use-cases/get-address-from-address-finder-result-id/get-address-from-address-finder-result-id.use-case';

@Component({
    selector: 'up-conversion-address-found',
    templateUrl: './conversion-address-found.component.html',
})
export class ConversionAddressFoundComponent implements OnInit {
    @Input() public address: AddressT;
    @Input() public funnelName: conversion.Funnel;
    @Input() public step: number;
    public funnelNameEnum = conversion.Funnel;
    public authData: UserT;
    public loading: boolean;
    public agent$: Observable<agent.SimpleAgent>;
    public readonly isLoadingAddressFinderResult$ = new BehaviorSubject(false);
    public readonly mapIconSize = new google.maps.Size(26, 32);

    private get addressFinderResultId(): string {
        return this.uiRouterGlobals.params['addressFinderResultId'];
    }

    constructor(
        private stateService: StateService,
        private conversionService: ConversionService,
        private userService: UserService,
        private conversionFacade: ConversionFacade,
        private addressService: AddressService,
        private suburbResource: SuburbResource,
        private readonly uiRouterGlobals: UIRouterGlobals,
        private readonly getAddressFromAddressFinderResultIdUseCase: GetAddressFromAddressFinderResultIdUseCase,
    ) {
        this.userService.userAuthDetailsUpdated$.subscribe(response => {
            this.authData = response;
        });
    }

    public ngOnInit() {
        if (this.conversionService.hasStoredAddress()) {
            this.address = this.conversionService.getConversionState().address;
        }

        if (this.addressFinderResultId) {
            this.isLoadingAddressFinderResult$.next(true);
            this.getAddressFromAddressFinderResultIdUseCase
                .execute({
                    addressFinderResultId: this.addressFinderResultId,
                })
                .subscribe({
                    next: address => {
                        this.address = address;
                        this.isLoadingAddressFinderResult$.next(false);
                    },
                    error: () => {
                        this.conversionService.goToFunnelEntry(this.funnelName, {
                            submissionError: doTranslation('conversion.propertyReport.addressFound.error'),
                        });
                    },
                });
        }

        if (!this.address && !this.addressFinderResultId) {
            this.conversionService.goToFunnelEntry(this.funnelName);
        }

        if (this.funnelName === conversion.Funnel.Calendar) {
            this.agent$ = this.conversionFacade.appraisalState$.pipe(
                map(({ agent }) => agent),
                filter(agent => !!agent),
                map(({ firstName, lastName, avatar, rating, suburb }) => ({
                    firstName,
                    lastName,
                    rmaRating: rating,
                    photoUrl: avatar && avatar.servingUrl,
                    position: `${suburb ? suburb.name + ' local' : 'Local'} property agent`,
                })),
            );
        }
    }

    public editAddress() {
        this.stateService.go('^.address');
    }

    public next() {
        this.conversionService.storeConversionState(this.address);
        this.conversionFacade.setAddress(this.address);

        switch (this.funnelName) {
            case conversion.Funnel.Booking: {
                const suburbId = this.addressService.createSuburbIdFromAddress(this.address);
                this.loading = true;
                this.suburbResource
                    .getSuburbAgent(suburbId, this.address.geoLocation)
                    .pipe(
                        map(
                            ({ agent, calendar }) =>
                                !!(agent && Object.keys(agent).length && calendar.availableTimes.length),
                        ),
                        catchError(() => of(false)),
                    )
                    .subscribe(isServiceable => {
                        // If the property is not serviceable we kick the user out of the booking flow and
                        // in to the regular appraisal flow. This is done because in the booking flow the user
                        // needs to submit a time along with the appraisal request, which can't be done if the
                        // property is unserviceable. In the regular appraisal flow however, the user can book
                        // an appraisal without needing to set a time.
                        const next = isServiceable ? '^.book-time' : 'conversion.appraisal.capture-details';
                        this.stateService.go(next, { address: this.address });
                    });
                break;
            }

            case conversion.Funnel.PropertyReport: {
                this.stateService.go('^.property-details', { address: this.address });
                break;
            }

            default: {
                this.stateService.go('^.capture-details', { address: this.address });
                break;
            }
        }
    }
}
