import { propertyReport } from '../../modules/property-report/property-report.model';

import { AddressT } from './address.model';
import { Business } from './business.model';
import { ApiSuccessResponse } from './common.model';
import { AgencyDto } from './dto/agency/agency.dto';
import { interaction } from './interaction.model';
import { media } from './media.model';
import { Property, ServiceT } from './property.model';
import { Teams } from './teams.model';
import { twillio } from './twillio.model';
import { InternalUser, NewUser, user, UserBase } from './user.model';

export namespace userApi {
    export interface ShadowUserReportCreatedResponse {
        propertyAnswerToken?: string;
        id?: string;
        propertyToken?: string;
        userId?: string;
    }

    export interface InternalUserReportCreatedResponse {
        property: string; // property ID
    }

    export type VendorUserReportCreatedResponse = Property;

    interface UserBaseResponse extends UserBase {
        id: string;
        roles: user.Role[];
        dateOfBirth?: string;
        homeAddress?: string;
        authenticated?: boolean;
        emailConfirmed: boolean;
        phoneVerified?: boolean;
        created?: string;
        avatar?: media.File;
        business?: Business;
        canSeeSmsFeature?: boolean;
        buyerModuleEnabled?: boolean;
        activePipeBulkEmailEnabled?: boolean;
    }

    export interface UserResponse extends UserBaseResponse {
        doNotContact?: boolean;
    }

    export namespace Shadow {
        export namespace Appraisal {
            export namespace POST {
                export interface Request {
                    address: AddressT;
                    phoneNumber: string;
                    email: string;
                    utm: string;
                    firstName: string;
                    lastName: string;
                }
                export interface Response {
                    propertyAnswerToken?: string;
                    service: ServiceT;
                    id?: string;
                }
            }
        }
        export namespace AppraisalWithToken {
            export namespace POST {
                export interface Request {
                    phoneNumber?: string;
                }
                export interface Response {
                    propertyAnswerToken: string;
                    service: ServiceT;
                }
            }
        }
        export namespace Report {
            export namespace POST {
                export interface Request {
                    address: AddressT;
                    phoneNumber: string;
                    email: string;
                    utm: string;
                    firstName: string;
                    lastName: string;
                    propertyDetails?: propertyReport.CoreLogicLiveAvmPropertyDetails;
                }
                // Response depends on who is calling the endpoint
                export type Response =
                    | ShadowUserReportCreatedResponse
                    | InternalUserReportCreatedResponse
                    | VendorUserReportCreatedResponse;
            }
        }
    }

    export namespace SignUpWithRoles {
        export namespace POST {
            export interface Request extends NewUser {}
            export interface Response extends InternalUser {}
        }
    }

    export namespace setPassword {
        export namespace post {
            export enum ErrorCode {
                UnconfirmedUser = 'UNCONFIRMED_USER',
            }
            export interface Request {
                password: string;
            }
            export interface Response {
                ok: boolean;
            }
        }
    }

    export namespace confirmRegisteredEmail {
        export namespace get {
            export interface Request {
                email: string;
            }
            export interface Response {
                requireAuth: boolean;
                exists: boolean;
            }
        }
    }

    export namespace myShadow {
        export namespace get {
            export type Response = UserResponse;
        }
    }

    export namespace signIn {
        export namespace post {
            export interface Request {
                email: string;
                password: string;
            }
            export enum ErrorCodes {
                Unauthorized = 'UNAUTHORIZED',
                LoginFeatureDisabled = 'LOGIN_FEATURE_DISABLED',
            }
            export type Response = UserResponse;
        }
    }

    export namespace googleSignIn {
        export namespace post {
            export enum ErrorCodes {
                Unauthorized = 'UNAUTHORIZED',
            }
            export type Response = UserResponse;
        }
    }

    export namespace checkToken {
        export namespace get {
            export interface Response extends ApiSuccessResponse {}
            export enum ErrorCodes {
                TokenConsumed = 'TOKEN_CONSUMED',
                TokenAuthExpired = 'TOKEN_AUTH_EXPIRED',
            }
        }
    }

    export namespace notifications {
        export namespace triggerSms {
            export namespace post {
                export enum ErrorCodes {
                    PropertyNotReadyForListingNotification = 'PROPERTY_NOT_READY_FOR_LISTING_NOTIFICATION',
                    ListingNotificationAlreadySent = 'LISTING_NOTIFICATION_ALREADY_SENT',
                }

                export interface Response extends ApiSuccessResponse {}
            }
        }

        export namespace preferences {
            export namespace get {
                export interface Response extends user.Notifications {}
            }

            export namespace put {
                export interface Request extends user.Notifications {}
                export interface Response extends user.Notifications {}
            }

            export namespace token {
                export namespace get {
                    export interface Response extends user.Notifications {}
                }

                export namespace put {
                    export interface Request extends user.Notifications {}
                    export interface Response extends user.Notifications {}
                }
            }

            export namespace User {
                export namespace GET {
                    export interface Response extends user.Notifications {}
                }

                export namespace PUT {
                    export interface Request extends user.Notifications {}
                    export interface Response extends user.Notifications {}
                }
            }
        }

        export namespace subscribe {
            export namespace post {
                export enum ErrorCodes {
                    ConfirmedUserCannotSignUp = 'CONFIRMED_USER_CANNOT_SIGN_UP',
                    UnconfirmedUserMustConfirm = 'UNCONFIRMED_USER_MUST_CONFIRM',
                }

                export interface Request extends user.Notifications {}
                export interface Response extends ApiSuccessResponse {}
            }
        }

        export namespace unsubscribe {
            export namespace post {
                export interface Response extends ApiSuccessResponse {}
            }

            export namespace token {
                export namespace post {
                    export interface Response extends ApiSuccessResponse {}
                }
            }
        }
    }

    export namespace smsVerify {
        export namespace post {
            export interface Request {
                userId: string;
                areaCode?: string;
                phoneNumber?: string;
            }

            export interface Response extends twillio.ResponseBase {}
        }
    }

    export namespace smsVerifyCheck {
        export namespace post {
            export interface Request {
                areaCode?: string;
                code: string;
                phoneNumber?: string;
            }

            export interface Response extends twillio.ResponseBase {}
        }
    }

    export namespace addRole {
        export namespace post {
            export type Response = ApiSuccessResponse;
            export enum ErrorCodes {
                Forbidden = 'FORBIDDEN',
                Unauthorized = 'UNAUTHORIZED',
                UserAlreadyHasRole = 'USER_ALREADY_HAS_ROLE',
            }
        }
    }

    export namespace removeRole {
        export namespace post {
            export type Response = ApiSuccessResponse;
            export enum ErrorCodes {
                Forbidden = 'FORBIDDEN',
                Unauthorized = 'UNAUTHORIZED',
                UserMissingRole = 'USER_MISSING_ROLE',
                CannotRemoveRole = 'CANNOT_REMOVE_ROLE',
            }
        }
    }

    export namespace readUrl {
        export namespace get {
            export type Response = media.UrlResponse;
        }
    }

    export namespace updateUser {
        export namespace post {
            export type Request = user.CrmUser;
            export enum ErrorCodes {
                UserNotAgentEditable = 'USER_NOT_AGENT_EDITABLE',
                UnknownUser = 'UNKNOWN_USER',
                ValidationFailed = 'VALIDATION_FAILED',
            }
        }
    }

    export namespace updateCrmUser {
        export namespace post {
            export type Request = Partial<InternalUser>;
            export enum ErrorCode {
                StaffNotAllowed = 'STAFF_NOT_ALLOWED',
                UnknownUser = 'UNKNOWN_USER',
                ValidationFailed = 'VALIDATION_FAILED',
            }
        }
    }

    export namespace isCustomer {
        export namespace get {
            export interface Response {
                isCustomer: boolean;
            }
        }
    }

    export namespace manualPhoneVerify {
        export namespace post {
            export type Response = ApiSuccessResponse;
        }
    }

    export namespace signInAsUser {
        export namespace post {
            export type Response = UserResponse;
        }
    }

    export namespace interactions {
        export namespace get {
            export interface Response {
                interactions: interaction.Interaction[];
                participantNames: interaction.ParticipantsNames;
                propertyAddresses: interaction.PropertyAddresses;
            }
        }
    }

    export namespace UserAssociations {
        export namespace Get {
            export interface Response {
                teams: Teams.UserTeamAssociation[];
                business: Business;
                agencies: AgencyDto[];
            }
        }
    }

    export namespace Current {
        export namespace GET {
            export type Response = UserBaseResponse;
        }
    }
}
