<up-container>
    <up-grid>
        <up-grid-col [col@sm]="4" class="step-col">
            <up-media-object>
                <img
                    figure
                    [lazyLoad]="'/static/images/illustrations/mouse.svg' | upCdnUrl"
                    alt=""
                    width="48"
                    height="48"
                />
                <div body>
                    <div class="u-text-heading--rg u-margin-bottom--xs"> <div> 01. </div> Find your property </div>
                    <div> Enter your address to find your property and provide some basic details. </div>
                </div>
            </up-media-object>
        </up-grid-col>
        <up-grid-col [col@sm]="4" class="step-col">
            <up-media-object>
                <img
                    figure
                    [lazyLoad]="'/static/images/illustrations/envelope-open.svg' | upCdnUrl"
                    alt=""
                    width="48"
                    height="48"
                />
                <div body>
                    <div class="u-text-heading--rg u-margin-bottom--xs"> <div> 02. </div> Check your email </div>
                    <div> We'll send the report to your email so you can easily come back to it later. </div>
                </div>
            </up-media-object>
        </up-grid-col>
        <up-grid-col [col@sm]="4" class="step-col">
            <up-media-object>
                <img
                    figure
                    [lazyLoad]="'/static/images/illustrations/clipboard-appraisal.svg' | upCdnUrl"
                    alt=""
                    width="48"
                    height="48"
                />
                <div body>
                    <div class="u-text-heading--rg u-margin-bottom--xs"> <div> 03. </div> View your report </div>
                    <div> Simply click the link in the email to view the full report, it's that easy. </div>
                </div>
            </up-media-object>
        </up-grid-col>
    </up-grid>
</up-container>
