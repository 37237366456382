import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

import { GoogleService } from '../../../common/services/google.service';

@UntilDestroy()
@Component({
    selector: 'nc-google-sign-in',
    template: '',
    styleUrls: ['google-sign-in.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleSignInComponent implements OnInit, AfterViewInit {
    @Output() public success: EventEmitter<google.accounts.id.CredentialResponse> =
        new EventEmitter<google.accounts.id.CredentialResponse>();

    constructor(private readonly elementRef: ElementRef<HTMLElement>, private readonly googleService: GoogleService) {}

    public ngOnInit(): void {
        this.googleService.loggedInUserCredentialsChange$
            .pipe(
                untilDestroyed(this),
                filter(credentials => !!credentials),
            )
            .subscribe(credentials => this.success.emit(credentials));
    }

    public ngAfterViewInit(): void {
        this.renderButton();
    }

    private renderButton(): void {
        this.googleService.renderGoogleSignInButton(this.elementRef.nativeElement);
    }
}
