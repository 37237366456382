
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../../common/components/icon/icon.module';
import { TrackingModule } from '../../common/directive/tracking.module';

import { UpsideSocialLinksComponent } from './components/upside-social-links/upside-social-links.component';

@NgModule({
    imports: [CommonModule, IconModule, TrackingModule],
    exports: [UpsideSocialLinksComponent],
    declarations: [UpsideSocialLinksComponent],
})
export class UpsideSocialLinksModule {}
