import { Pipe, PipeTransform } from '@angular/core';

import { user } from '../../models/user.model';
import { getPrettyUserRole } from '../../utilities/auth/get-pretty-user-role.util';

@Pipe({
    name: 'upPrettifyRole',
})
export class PrettifyRolePipe implements PipeTransform {
    public transform(role: user.Role): string {
        return getPrettyUserRole(role);
    }
}
