import { BreakpointObserver } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    PLATFORM_ID,
    Renderer2,
} from '@angular/core';
import throttle from 'lodash-es/throttle';
import { Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { UP_MODAL_CONTROLS } from '../../../../common/constants/modal-data.constant';
import { MediaQueries } from '../../../../common/models/media-queries.model';
import { modal } from '../../../../common/models/modal.model';
import { WindowRef } from '../../../../common/services/window.service';
import mediaQueries from '../../../../settings/media-queries';

@Component({
    selector: 'nc-modal-dialog, up-modal-dialog',
    templateUrl: './modal-dialog.component.html',
    styleUrls: ['./modal-dialog.component.scss'],
})
/** @deprecated Use `ModalDialogV2Component` instead. */
export class ModalDialogComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input('fullScreen@mn') public fullScreenAtXs: boolean;
    @Input('size') public size: 'small' | 'default' | 'large' = 'default';
    @Output() public close: EventEmitter<void> = new EventEmitter<void>();
    @HostBinding('class.is-full-screen-@mn') public fullScreenAtMnClass: boolean;
    @HostBinding('class.is-size-small') public sizeSmallClass: boolean;
    @HostBinding('class.is-size-default') public sizeDefaultClass: boolean;
    @HostBinding('class.is-size-large') public sizeLargeClass: boolean;
    private readonly throttledOnWindowResize: Function;
    private destroy$ = new Subject<void>();
    private isTabletOrDesktop: boolean;

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        @Inject(UP_MODAL_CONTROLS) private modalControls: modal.Controls,
        private breakpointObserver: BreakpointObserver,
        private renderer2: Renderer2,
        private windowRef: WindowRef,
        private elementRef: ElementRef,
    ) {
        this.throttledOnWindowResize = throttle(() => this.updateModalMinHeight(), 300);
    }

    public ngOnInit(): void {
        this.fullScreenAtMnClass = this.fullScreenAtXs;
        this.sizeSmallClass = this.size === 'small';
        this.sizeDefaultClass = this.size === 'default';
        this.sizeLargeClass = this.size === 'large';

        const breakpoint = `(min-width: ${mediaQueries[MediaQueries.XSmall]}px)`;
        this.breakpointObserver
            .observe([breakpoint])
            .pipe(
                takeUntil(this.destroy$),
                map(({ matches }) => matches),
                // Since breakpointObserver no longer emits immediately, replicate the behaviour by starting
                // the stream with the current breakpoint isMatched value. See:
                // https://github.com/angular/components/issues/13852
                // https://github.com/angular/components/pull/11007
                startWith(this.breakpointObserver.isMatched([breakpoint])),
            )
            .subscribe(matches => (this.isTabletOrDesktop = matches));
    }

    public ngAfterViewInit(): void {
        this.updateModalMinHeight();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    @HostListener('window:resize')
    public windowResize(): void {
        this.throttledOnWindowResize();
    }

    public onClose(): void {
        if (this.close.observers.length) {
            this.close.emit();
        } else {
            this.modalControls.close(modal.CloseType.Dismiss);
        }
    }

    private updateModalMinHeight(): void {
        if (!isPlatformBrowser(this.platformId)) return;
        const innerHeight = this.windowRef.nativeWindow.innerHeight;
        const nextMinHeight = !this.isTabletOrDesktop && this.fullScreenAtXs ? `${innerHeight}px` : 'auto';

        this.renderer2.setStyle(this.elementRef.nativeElement, 'min-height', nextMinHeight);
    }
}
