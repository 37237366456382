import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LocalitySearchResponseDto } from '../../../common/models/dto/locality-search/locality-search-response.dto';

@Injectable({ providedIn: 'root' })
export class LocalitySearchResource {
    private baseUrl = '/api/v2/locality-search';

    constructor(private readonly httpClient: HttpClient) {}

    public searchSuburbAndAddress(query: string): Observable<LocalitySearchResponseDto> {
        return this.httpClient.get<LocalitySearchResponseDto>(`${this.baseUrl}`, { params: { query } });
    }
}
