import { Transition } from '@uirouter/angular';

import { UpStateDeclaration } from '../../common/models/router.model';
import { FooterMegaComponent } from '../../components/footer/footer-mega/footer-mega.component';
import { InternalSignInComponent } from '../../components/internal/internal-sign-in/internal-sign-in.component';
import { NoopComponent } from '../../components/noop/noop.component';

import { CheckEmailComponent } from './check-email/check-email.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UserSetPasswordComponent } from './set-password/set-password.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { UserComponent } from './user.component';

export function tokenParamFn(trans) {
    return trans.params().token;
}

export function redirectToResolveFn(trans) {
    return trans.params().redirect_to;
}

export function redirectParamsResolveFn(trans: Transition) {
    return trans.params().redirectParams;
}

export function propertyIdResolveFn(trans) {
    return trans.params().id;
}

export function passwordModeResolveFn(trans) {
    return trans.params().passwordMode;
}

export function emailResolveFn(trans) {
    return trans.params().email;
}

export function nextResolveFn(trans) {
    return trans.params().next;
}

export function msgResolveFn(trans) {
    return trans.params().msg;
}

export function modeResolveFn(trans) {
    return trans.params().mode;
}

export const UserStates: UpStateDeclaration[] = [
    {
        name: 'user',
        url: '/user',
        abstract: true,
        views: {
            'content@vendor-app': {
                component: UserComponent,
            },
        },
        parent: 'vendor',
    },
    {
        name: 'user.signin',
        url: '/signin?next&msg&email&redirect_to&id&redirectParams',
        views: {
            'content@vendor-app': {
                component: SignInComponent,
            },
            'navbar@vendor-app': {
                component: NoopComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
        data: {
            meta: {
                title: 'Sign In',
                description: `Sign in. Sell your home with an experienced local Upside agent. Total transparency, award-winning service and great results.`,
            },
        },
        resolve: [
            {
                token: 'email',
                deps: [Transition],
                resolveFn: emailResolveFn,
            },
            {
                token: 'id',
                deps: [Transition],
                resolveFn: propertyIdResolveFn,
            },
            {
                token: 'redirect_to',
                deps: [Transition],
                resolveFn: redirectToResolveFn,
            },
            {
                token: 'next',
                deps: [Transition],
                resolveFn: nextResolveFn,
            },
            {
                token: 'msg',
                deps: [Transition],
                resolveFn: msgResolveFn,
            },
            {
                token: 'redirectParams',
                deps: [Transition],
                resolveFn: redirectParamsResolveFn,
            },
        ],
    },
    {
        name: 'user.forgot-password',
        url: '/forgot-password',
        views: {
            'content@vendor-app': {
                component: ForgotPasswordComponent,
            },
            'navbar@vendor-app': {
                component: NoopComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
        data: {
            meta: {
                title: 'Forgot your Password? Request a new Password',
                description: `Reset Password. Sell your home with an experienced local Upside agent. Total transparency, award-winning service and great results.`,
            },
        },
        params: {
            email: undefined,
        },
        resolve: [
            {
                token: 'email',
                deps: [Transition],
                resolveFn: emailResolveFn,
            },
        ],
    },
    {
        name: 'user.complete-signup',
        url: '/complete-signup/:token?redirect_to=redirectLink&id=propertyId&redirectParams',
        views: {
            'content@vendor-app': {
                component: UserSetPasswordComponent,
            },
            'navbar@vendor-app': {
                component: NoopComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
        params: {
            passwordMode: 'set',
        },
        resolve: [
            {
                token: 'token',
                deps: [Transition],
                resolveFn: tokenParamFn,
            },
            {
                token: 'redirectTo',
                deps: [Transition],
                resolveFn: redirectToResolveFn,
            },
            {
                token: 'propertyId',
                deps: [Transition],
                resolveFn: propertyIdResolveFn,
            },
            {
                token: 'passwordMode',
                deps: [Transition],
                resolveFn: passwordModeResolveFn,
            },
            {
                token: 'redirectParams',
                deps: [Transition],
                resolveFn: redirectParamsResolveFn,
            },
        ],
    },
    {
        name: 'user.reset-password',
        url: '/reset-password/:token',
        views: {
            'content@vendor-app': {
                component: UserSetPasswordComponent,
            },
            'navbar@vendor-app': {
                component: NoopComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
        params: {
            passwordMode: 'reset',
        },
        resolve: [
            {
                token: 'token',
                deps: [Transition],
                resolveFn: tokenParamFn,
            },
            {
                token: 'passwordMode',
                deps: [Transition],
                resolveFn: passwordModeResolveFn,
            },
        ],
    },
    {
        name: 'user.signin-internal',
        url: '/signin-internal?redirect_to&redirect_to_params&next',
        views: {
            'navbar@vendor-app': {
                component: NoopComponent,
            },
            'content@vendor-app': {
                component: InternalSignInComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
    },
    {
        name: 'user.check-email',
        url: '/check-email',
        views: {
            'content@vendor-app': {
                component: CheckEmailComponent,
            },
            'navbar@vendor-app': {
                component: NoopComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
        params: {
            email: undefined,
            mode: undefined,
        },
        resolve: [
            {
                token: 'email',
                deps: [Transition],
                resolveFn: emailResolveFn,
            },
            {
                token: 'mode',
                deps: [Transition],
                resolveFn: modeResolveFn,
            },
        ],
    },
    /**
     * Note: the 'user.notifications' and 'user.notifications-unsubscribe' routes are hidden in the UI by use of the
     * 'weeklySuburbNotificationsEnabled' feature flag, however these routes are intentionally not feature guarded. If
     * a user does somehow get to one of these routes externally (eg. via an accidental link in an email, an old email,
     * etc.), we don't want to block access as the user's next likely action if they believe they can't unsubscribe will
     * be to block our emails. So, out of extra caution, we're keeping these routes accessible if the URL is known, but
     * it will be otherwise be entirely hidden in the UI (depending on the feature flag).
     * **/
    {
        name: 'user.notifications',
        url: '/notifications?token&user',
        params: {
            token: undefined,
            user: undefined,
            immediatelyShowForm: undefined,
        },
        views: {
            'content@vendor-app': {
                component: NotificationsComponent,
            },
            'footer@vendor-app': {
                component: FooterMegaComponent,
            },
        },
        data: {
            meta: {
                title: 'Notification Settings',
                description: `Weekly market activity subscription preferences. Sell your home with an experienced local Upside agent. Total transparency, award-winning service and great results.`,
            },
        },
    },
    {
        name: 'user.notifications-unsubscribe',
        url: '/notifications/unsubscribe?token',
        params: {
            token: undefined,
            unsubscribe: true,
        },
        views: {
            'content@vendor-app': {
                component: NotificationsComponent,
            },
            'footer@vendor-app': {
                component: FooterMegaComponent,
            },
        },
        data: {
            meta: {
                title: 'Notifications Settings',
            },
        },
    },
];
