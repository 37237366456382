import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { navigation } from '../navigation.model';
import { NavigationService } from '../navigation.service';

@Component({
    selector: 'up-nav-bar-dynamic-cta',
    templateUrl: 'nav-bar-dynamic-cta.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [style({ opacity: 0 }), animate('0.3s ease-in', style({ opacity: 1 }))]),
            transition(':leave', [animate('0.3s ease-out', style({ opacity: 0 }))]),
        ]),
    ],
})
export class NavBarDynamicCtaComponent implements OnInit, OnDestroy {
    public hideGetStartedCta$: Observable<boolean>;
    public overrideCta$: Observable<navigation.OverrideCta>;
    private destroy$ = new Subject<void>();
    @HostBinding('class.u-display--none') private hideSelf: boolean;

    constructor(private navigationService: NavigationService) {}

    public ngOnInit(): void {
        const navConfig$ = this.navigationService.navConfig$;
        this.hideGetStartedCta$ = navConfig$.pipe(map(({ hideCta }) => hideCta));
        this.hideGetStartedCta$.pipe(takeUntil(this.destroy$)).subscribe(hide => (this.hideSelf = hide));
        this.overrideCta$ = navConfig$.pipe(map(({ overrideCta }) => overrideCta));
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
