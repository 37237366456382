import { UIRouter } from '@uirouter/angular';

import { UpStateDeclaration } from '../../common/models/router.model';

export const featureGuardUiRouterHooks = (router: UIRouter) => {
    router.transitionService.onBefore({}, transition => {
        const featureGuard = (<UpStateDeclaration> transition.to()).data?.featureGuard;
        return featureGuard ? featureGuard(transition) : true;
    });
};
