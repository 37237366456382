<div *ngIf="loading" class="u-text-align--center u-padding-top--7xl"> <up-spinner [size]="'large'"></up-spinner> </div>
<div id="conversion-result" *ngIf="!loading">
    <up-container id="conversion-soft-error" class="u-text-body--rg u-padding-top--5xl u-padding-bottom--xl">
        <up-grid [align]="'center'">
            <up-grid-col [col@sm]="6">
                <ng-container *ngIf="serviceStatus === 'Unservicable'">
                    <up-address-pin
                        class="u-margin-bottom--lg"
                        [address]="user.address.formattedAddress"
                    ></up-address-pin>
                    <h1 id="appraisal-unserviceable-area-message" class="u-text-heading--lg u-margin-bottom--md">
                        Sorry, Upside is not available in your area
                    </h1>
                    <div class="u-margin-bottom--sm">
                        <ng-container *ngIf="user.address.governingDistrict !== 'NSW'">
                            We'd love to appraise your property but unfortunately we haven't rolled out in your area
                            yet. We'll keep you updated about our launch in your suburb.
                        </ng-container>
                        <ng-container *ngIf="user.address.governingDistrict === 'NSW'">
                            <div class="u-margin-bottom--sm">
                                Thanks for requesting an Upside property appraisal. We’re busy expanding across
                                Australia and yet to reach your area, but we will be in touch as soon as we get there.
                            </div>
                        </ng-container>
                    </div>
                    <div class="u-margin-bottom--md">
                        <ng-container *ngIf="authData.authenticated">
                            <up-button
                                [style]="'primary'"
                                [uiSref]="'my-properties'"
                                [arrowIcon]="true"
                                [size]="'large'"
                                >View My Properties</up-button
                            >
                        </ng-container>
                        <ng-container *ngIf="!authData.authenticated">
                            <div class="u-margin-bottom--sm">
                                In the meantime, you can make the most of our free online value estimate. Get an
                                estimate for what your property could be worth, view its sales history and see the
                                recent sale prices of comparable properties in your area.
                            </div>
                            <div class="u-margin-top--sm">
                                <up-button
                                    [style]="'primary'"
                                    [uiSref]="'conversion.property-report.address'"
                                    [arrowIcon]="true"
                                    [size]="'large'"
                                    >Get Free Property Report</up-button
                                >
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </up-grid-col>
        </up-grid>
    </up-container>
</div>
