import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/angular';

export interface HistoryState {
    name: string;
    params?: {
        [key: string]: any;
    };
}

@Injectable()
export class HistoryService {
    private fromState: HistoryState;

    constructor(private stateService: StateService) {}

    public _setFromState(fromState: HistoryState): void {
        this.fromState = fromState;
    }

    public hasFromState(): boolean {
        return !!this.fromState;
    }

    public getFromState(): HistoryState {
        if (!this.hasFromState()) return;
        return this.fromState;
    }

    public back(fallbackRoute: string): void {
        if (!this.hasFromState()) {
            this.stateService.go(fallbackRoute);
        } else {
            this.stateService.go(this.fromState.name, this.fromState.params);
        }
    }
}
