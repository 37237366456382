import { Pipe, PipeTransform } from '@angular/core';
import approximateNumber from 'approximate-number';
import { isNumber } from 'lodash-es';

@Pipe({
    name: 'shortNumber',
})
export class ShortNumberPipe implements PipeTransform {
    public transform(value: number, precision = 4): string {
        if (!isNumber(value)) return value;

        // TODO: should switch to the Intl.NumberFormat API when it has better browser support
        return approximateNumber(value, { precision, capital: true });
    }
}
