import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { FirebaseApp, FirebaseOptions } from '@firebase/app';
import { RemoteConfig } from '@firebase/remote-config';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, tap, map, first, switchMap } from 'rxjs/operators';

import {
    FirebaseAppInjection,
    FirebaseAppInjectionToken,
} from '../../../common/injection-tokens/firebase-app.injection-token';
import {
    FirebaseRemoteConfigInjection,
    FirebaseRemoteConfigInjectionToken,
} from '../../../common/injection-tokens/firebase-remote-config.injection-token';
import { ErrorHandlerService } from '../../../common/services/error-handler.service';
import { getEnvironmentVariable } from '../../../utilities/get-environment-variable';

import { EnvironmentService } from './environment.service';

export interface FirebaseRemoteConfigFeatureFlags {
    PropertyHighlightsModalEnabled: boolean;
}

@Injectable({ providedIn: 'root' })
export class FirebaseService {
    private readonly firebaseConfig: FirebaseOptions = {
        apiKey: getEnvironmentVariable('firebaseApiKey'),
        authDomain: getEnvironmentVariable('firebaseAuthDomain'),
        databaseURL: getEnvironmentVariable('firebaseDatabaseURL'),
        projectId: getEnvironmentVariable('firebaseProjectId'),
        storageBucket: getEnvironmentVariable('firebaseStorageBucket'),
        messagingSenderId: getEnvironmentVariable('firebaseMessagingSenderId'),
        appId: getEnvironmentVariable('firebaseAppId'),
    };
    private readonly firebaseApp?: FirebaseApp;
    private readonly remoteConfig?: RemoteConfig;
    private readonly _remoteConfigValues$: BehaviorSubject<FirebaseRemoteConfigFeatureFlags> =
        new BehaviorSubject<FirebaseRemoteConfigFeatureFlags>(<FirebaseRemoteConfigFeatureFlags>{});

    public readonly remoteConfigValues$: Observable<FirebaseRemoteConfigFeatureFlags> =
        this._remoteConfigValues$.asObservable();

    constructor(
        @Inject(FirebaseAppInjectionToken) private firebaseAppInjection: FirebaseAppInjection,
        @Inject(FirebaseRemoteConfigInjectionToken)
        private firebaseRemoteConfigInjection: FirebaseRemoteConfigInjection,
        @Inject(PLATFORM_ID) private platformId: Object,
        private errorHandlerService: ErrorHandlerService,
        private environmentService: EnvironmentService,
    ) {
        if (isPlatformServer(this.platformId)) return;

        try {
            this.firebaseApp = firebaseAppInjection.initializeApp(this.firebaseConfig);
            this.remoteConfig = firebaseRemoteConfigInjection.getRemoteConfig(this.firebaseApp);
        } catch (error) {
            errorHandlerService.sendError(error);
        }
    }

    public fetchRemoteConfig$(): Observable<void> {
        if (isPlatformServer(this.platformId)) return of(undefined);

        this.environmentService.config$
            .pipe(
                first(),
                tap(({ organisation }) => {
                    const minimumFetchIntervalMillis = this.environmentService.isProduction(organisation.name)
                        ? // Fetch at most once an hour, even if you call fetch multiple times, it will not fetch until this timeout is
                          // over. This is to ensure we don't get rate limited, as noted by the documentation:
                          // https://firebase.google.com/docs/remote-config/get-started?platform=web#throttling
                          60 * 60 * 1000
                        : // In development environment we can use a much lower value for easier testing as traffic is
                          // significantly lower and we're unlikely to get rate limited
                          10 * 60 * 1000;
                    this.remoteConfig.settings.minimumFetchIntervalMillis = minimumFetchIntervalMillis;
                }),
                // Trigger a background fetch to get new values if minimumFetchIntervalMillis has expired, we don't want to wait
                // for this as it can take over 500ms. When this completes it stores any downloaded configs into the cache for
                // the next activation call
                switchMap(() => from(this.firebaseRemoteConfigInjection.fetchConfig(this.remoteConfig))),
            )
            .subscribe({
                error: e => this.errorHandlerService.sendError(e),
            });

        // Trigger an activate which activates any flags already available in the cache, note that this does not wait
        // for the fetch above and just activates anything that is immediately available in the cache, this strategy
        // removes the dependency on Firebase's RemoteConfig fetch network request duration from the app initialisation.
        // This results in newly downloaded values being applied on next app initialisation, i.e. a refresh, but greatly
        // reduces the initialisation blocking task duration from 500ms+ down to ~30ms.
        return from(this.firebaseRemoteConfigInjection.activate(this.remoteConfig)).pipe(
            map(() => this.firebaseRemoteConfigInjection.getAll(this.remoteConfig)),
            tap({
                next: values => {
                    const entries = Object.entries(values);
                    const normalisedEntries = entries.map(([key, value]) => [key, value.asBoolean()]);
                    const normalisedValues = Object.fromEntries(normalisedEntries);
                    this._remoteConfigValues$.next(normalisedValues);
                },
            }),
            catchError(error => {
                // This needs to be placed in here to ensure it catches errors from the tap as well
                this.errorHandlerService.sendError(error);
                return of({});
            }),
            map(() => undefined),
        );
    }
}
