import { I18nPluralPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({ name: 'ncFormatDurationFromSeconds' })
export class FormatDurationFromSecondsPipe implements PipeTransform {
    constructor(private i18nPluralPipe: I18nPluralPipe) {}

    public transform(durationInSeconds: number): string {
        if (typeof durationInSeconds !== 'number') return;

        const asMillis = durationInSeconds * 1000;
        const duration = dayjs.duration(asMillis);
        const asHours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
        const pluralUnit = (singular: string, value: number) =>
            this.i18nPluralPipe.transform(value, {
                '=1': `# ${singular}`,
                other: `# ${singular}s`,
            });
        const formattedHours = asHours ? `${pluralUnit('hour', asHours)}` : '';
        const formattedMinutes = minutes ? `${pluralUnit('minute', minutes)}` : '';
        const formattedSeconds = pluralUnit('second', duration.seconds());

        return [formattedHours, formattedMinutes, formattedSeconds].filter(f => !!f).join(' ');
    }
}
