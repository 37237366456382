import { LayoutModule } from '@angular/cdk/layout';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UIRouterModule } from '@uirouter/angular';

import { SharedModule } from '../../../common/shared.module';
import { MarketingChooseYourOwnAdventureModule } from '../../../modules/marketing-choose-your-own-adventure/marketing-choose-your-own-adventure.module';
import { NavigationModule } from '../../../modules/navigation/navigation.module';
import { SharedVendorModule } from '../../../modules/shared-vendor/shared-vendor.module';

import { GetStartedCallbackComponent } from './get-started-callback/get-started-callback.component';
import { GetStartedCallbackCompleteComponent } from './get-started-callback-complete/get-started-callback-complete.component';
import { GetStartedServiceComponent } from './get-started-service/get-started-service.component';
import { GetStartedServicesComponent } from './get-started-services/get-started-services.component';
import { GetStartedComponent } from './get-started.component';
import { GetStartedService } from './get-started.service';
import { GetStartedStates } from './get-started.states';
import { NavGetStartedComponent } from './nav-get-started/nav-get-started.component';
import { GetStartedEffects } from './store/get-started.effects';
import { GetStartedFacade } from './store/get-started.facade';
import { getStartedReducer } from './store/get-started.reducer';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: GetStartedStates }),
        SharedVendorModule,
        StoreModule.forFeature('getStarted', getStartedReducer),
        EffectsModule.forFeature([GetStartedEffects]),
        NavigationModule,
        LayoutModule,
        SharedModule,
        MarketingChooseYourOwnAdventureModule,
    ],
    providers: [GetStartedFacade, GetStartedService],
    declarations: [
        GetStartedComponent,
        GetStartedServiceComponent,
        GetStartedServicesComponent,
        GetStartedCallbackComponent,
        GetStartedCallbackCompleteComponent,
        NavGetStartedComponent,
    ],
})
export class GetStartedModule {}
