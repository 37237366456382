<nav [cdkTrapFocus]="openOnMobile && paneBehaviourState === PaneBehaviourState.Mobile" class="nav-pane">
    <button
        *ngIf="paneBehaviourState === PaneBehaviourState.Tablet"
        class="pane-tablet-opener"
        [attr.aria-label]="paneTabletState === PaneTabletState.Collapsed ? 'Open Menu' : 'Close Menu'"
        (click)="togglePaneTabletState()"
    >
        <up-hidden-content-indicator
            class="u-display--block pane-open-state-indicator"
            [open]="paneTabletState === PaneTabletState.Expanded"
        >
        </up-hidden-content-indicator>
    </button>

    <ng-content select="up-nav-pane-container, up-nav-pane-divider"> </ng-content>

    <up-nav-pane-container *ngIf="(isKeyboardUser$ | async) && paneBehaviourState === PaneBehaviourState.Mobile">
        <button (click)="setMobileNavOpen(false)" up-nav-pane-button> Close Navigation Menu </button>
    </up-nav-pane-container>
</nav>
