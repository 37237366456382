import { UpStateDeclaration } from '../../common/models/router.model';

export const smsUnsubscribeFutureStates: UpStateDeclaration[] = [
    {
        name: 'vendor.sms-unsubscribe.**',
        url: '/sms-unsubscribe',
        loadChildren: () =>
            import(
                /*webpackChunkName: 'sms-unsubscribe-module'*/
                './sms-unsubscribe.module'
            ).then(module => module.SmsUnsubscribeModule),
    },
];
