import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { PropertiesDto } from '../../../common/models/dto/property/properties.dto';
import { VendorApi } from '../../../common/models/vendor-api.model';

const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};
const BASE_URL = `/api/v2/vendor`;

@Injectable()
export class VendorResource {
    constructor(private http: HttpClient) {}

    public vendorProperties(id: string): Observable<PropertiesDto> {
        return this.http.get<PropertiesDto>(`${BASE_URL}/${id}/properties`, HEADERS_CONFIG);
    }

    public myProperties() {
        return this.http.get<VendorApi.MyProperties.GET.Response>(`${BASE_URL}/my-properties`);
    }
}
