import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NguiMapModule } from '@ngui/map';
import { UIRouterModule } from '@uirouter/angular';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MomentModule } from 'ngx-moment';

import { NoopComponent } from '../components/noop/noop.component';
import { PlaceholderComponent } from '../components/placeholder/placeholder.component';
import { SimpleCarouselComponent } from '../components/simple-carousel/simple-carousel.component';
import { VerticallyCenteredLayoutComponent } from '../components/vertically-centered-layout/vertically-centered-layout.component';
import { AlertModule } from '../modules/alert/alert.module';
import { AvatarModule } from '../modules/avatar/avatar.module';
import { BackgroundImageModule } from '../modules/background-image/background-image.module';
import { ButtonModule } from '../modules/button/button.module';
import { CardModule } from '../modules/card/card.module';
import { CdnUrlModule } from '../modules/cdn-url/cdn-url.module';
import { CollapserPanelModule } from '../modules/collapser-panel/collapser-panel.module';
import { DateModule } from '../modules/date/date.module';
import { HiddenContentIndicatorModule } from '../modules/hidden-content-indicator/hidden-content-indicator.module';
import { LoadingOverlayModule } from '../modules/loading-overlay/loading-overlay.module';
import { LocalisationModule } from '../modules/localisation/localisation.module';
import { LocationModule } from '../modules/location/location.module';
import { MediaObjectModule } from '../modules/media-object/media-object.module';
import { MenuModule } from '../modules/menu/menu.module';
import { ModalModule } from '../modules/modal/modal.module';
import { SpinnerModule } from '../modules/spinner/spinner.module';
import { TagModule } from '../modules/tag/tag.module';
import { ThemeModule } from '../modules/theme/theme.module';
import { TranslocoRootModule } from '../modules/transloco-root/transloco-root.module';
import { SuburbsSelectorComponent } from '../modules/user/suburbs-selector/suburbs-selector.component';

import { ActionableItemComponent } from './components/actionable-item/actionable-item.component';
import { AddressFinderComponent } from './components/address-finder/address-finder.component';
import { AgentContactCardComponent } from './components/agent-contact-card/agent-contact-card.component';
import { AgentExperienceStatsComponent } from './components/agent-experience-stats/agent-experience-stats.component';
import { AgentProfileCardComponent } from './components/agent-profile-card/agent-profile-card.component';
import { AgentRatingCardComponent } from './components/agent-rating-card/agent-rating-card.component';
import { AgentSocialLinksComponent } from './components/agent-social-links/agent-social-links.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { AvatarSectionComponent } from './components/avatar-section/avatar-section.component';
import { BackNavComponent } from './components/back-nav/back-nav.component';
import { BackdropFloatingOrgCardComponent } from './components/backdrop-floating-org-card/backdrop-floating-org-card.component';
import { BannerSectionComponent } from './components/banner-section/banner-section.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ButtonComponent } from './components/button/button.component';
import { BuyerFeedbackComponent } from './components/buyer-feedback/buyer-feedback.component';
import { CaptionedImageComponent } from './components/captioned-image/captioned-image.component';
import { CarouselIndicatorsComponent } from './components/carousel/carousel-indicators/carousel-indicators.component';
import { CarouselSlideDirective } from './components/carousel/carousel-slide/carousel-slide.directive';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ChipComponent } from './components/chip/chip.component';
import { ComparablePropertiesCardComponent } from './components/comparable-properties-card/comparable-properties-card.component';
import { ComparablePropertyCardComponent } from './components/comparable-property-card/comparable-property-card.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ContainerComponent } from './components/container/container.component';
import { ContentAccentComponent } from './components/content-accent/content-accent.component';
import { ContentFigureSectionComponent } from './components/content-figure-section/content-figure-section.component';
import { CreateSubscriptionCtaComponent } from './components/create-subscription-cta/create-subscription-cta.component';
import { DropdownModule } from './components/dropdown/dropdown.module';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { EmptyStateIllustrationComponent } from './components/empty-state-illustration/empty-state-illustration.component';
import { EnvRibbonComponent } from './components/env-ribbon/env-ribbon.component';
import { EventDotComponent } from './components/event-dot/event-dot.component';
import { CheckboxComponent } from './components/forms/checkbox/checkbox.component';
import { HelpTextComponent } from './components/forms/help-text/help-text.component';
import { InputComponent } from './components/forms/input/input.component';
import { RadioComponent } from './components/forms/radio/radio.component';
import { RadioGroupComponent } from './components/forms/radio-group/radio-group.component';
import { SelectOptionComponent } from './components/forms/select/select-option/select-option.component';
import { SelectComponent } from './components/forms/select/select.component';
import { SwitchComponent } from './components/forms/switch/switch.component';
import { GlobalProgressBarComponent } from './components/global-progress-bar/global-progress-bar.component';
import { GridModule } from './components/grid/grid.module';
import { HeroComponent } from './components/hero/hero.component';
import { HorizontalRuleComponent } from './components/horizontal-rule/horizontal-rule.component';
import { HrComponent } from './components/hr/hr.component';
import { IconModule } from './components/icon/icon.module';
import { IncludedFeaturesListComponent } from './components/included-features-list/included-features-list.component';
import { IsolatedContainerComponent } from './components/isolated-container/isolated-container.component';
import { LinkModule } from './components/link/link.module';
import { Link2Component } from './components/link-2/link-2.component';
import { ListModule } from './components/list/list.module';
import { MapMarkerComponent } from './components/map-marker/map-marker.component';
import { MarketPerformanceSummaryBlurbComponent } from './components/market-performance-summary-blurb/market-performance-summary-blurb.component';
import { MetricGridListModule } from './components/metric-grid-list/metric-grid-list.module';
import { MiniCalendarComponent } from './components/mini-calendar/mini-calendar.component';
import { MiniHrComponent } from './components/mini-hr/mini-hr.component';
import { OuterContainerComponent } from './components/outer-container/outer-container.component';
import { PasswordVisibilityToggleComponent } from './components/password-visibility-toggle/password-visibility-toggle.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PillComponent } from './components/pill/pill.component';
import { PropertyAlertCardComponent } from './components/property-alert-card/property-alert-card.component';
import { PropertyAlertCardReadMoreDialogComponent } from './components/property-alert-card-read-more-dialog/property-alert-card-read-more-dialog.component';
import { PropertyAttributesComponent } from './components/property-attributes/property-attributes.component';
import { PropertyCardComponent } from './components/property-card/property-card.component';
import { PropertyCardNavComponent } from './components/property-card-nav/property-card-nav.component';
import { PropertyExplorerSectionComponent } from './components/property-explorer-section/property-explorer-section.component';
import { PropertyFeaturesComponent } from './components/property-features/property-features.component';
import { PropertyGridComponent } from './components/property-grid/property-grid.component';
import { PropertyListingCardComponent } from './components/property-listing-card/property-listing-card.component';
import { PropertyMapComponent } from './components/property-map/property-map.component';
import { PropertyMediaObjectComponent } from './components/property-media-object/property-media-object.component';
import { PropertyShowcaseItemDirective } from './components/property-showcase/property-showcase-item.directive';
import { PropertyShowcaseComponent } from './components/property-showcase/property-showcase.component';
import { PropertySoldCardComponent } from './components/property-sold-card/property-sold-card.component';
import { PropertyStatusBadgeComponent } from './components/property-status-badge/property-status-badge.component';
import { RatemyagentRatingPillComponent } from './components/ratemyagent-rating-pill/ratemyagent-rating-pill.component';
import { RatingComponent } from './components/rating/rating.component';
import { RequestCallBackFormComponent } from './components/request-call-back-form/request-call-back-form.component';
import { ReviewsShowcaseComponent } from './components/reviews-showcase/reviews-showcase.component';
import { RmaRatingComponent } from './components/rma-rating/rma-rating.component';
import { TabsModule } from './components/tabs/tabs.module';
import { TimelineComponent } from './components/timeline/timeline.component';
import { TimelineDotComponent } from './components/timeline-dot/timeline-dot.component';
import { TimelineItemComponent } from './components/timeline-item/timeline-item.component';
import { UpsellReportBannerSectionComponent } from './components/upsell-report-banner-section/upsell-report-banner-section.component';
import { VendorReviewComponent } from './components/vendor-review/vendor-review.component';
import { ClickStopPropagationDirective } from './directive/click-stop-propagtion.directive';
import { ContrastDirective } from './directive/contrast.directive';
import { DeferLoadDirective } from './directive/defer-load.directive';
import { DisableInputMouseScrollDirective } from './directive/disable-input-mouse-scroll.directive';
import { FeatureDirective } from './directive/feature.directive';
import { TrackingModule } from './directive/tracking.module';
import { pikadayFactory, PikadayFactoryToken } from './injection-tokens/pikaday-factory.injection-token';
import { AbsoluteNumberPipe } from './pipes/absolute-number.pipe';
import { ArrayAsStringPipe } from './pipes/array-as-string.pipe';
import { AvmConfidenceDisplayPipe } from './pipes/avm-confidence-display.pipe';
import { CapitaliseStringPipe } from './pipes/capitalise.pipe';
import { FilenamePipe } from './pipes/filename.pipe';
import { NcPrettyDomainPropertyTypePipe } from './pipes/formatted-property-type.pipe';
import { HighlightTextPartialPipe } from './pipes/highlight-text-partial/highlight-text-partial.pipe';
import { ImageResizePipe } from './pipes/image-resize/image-resize.pipe';
import { ImageSizePipe } from './pipes/image-size.pipe';
import { InitialPipe } from './pipes/initial.pipe';
import { IsNumberPipe } from './pipes/is-number/is-number.pipe';
import { IsThemePipe } from './pipes/is-theme.pipe';
import { MomentConvertToTzPipe } from './pipes/moment-convert-to-tz.pipe';
import { NullishDefaultPipe } from './pipes/nullish-default.pipe';
import { ObjectKeysPipe } from './pipes/object-keys.pipe';
import { ParseIntPipe } from './pipes/parse-int.pipe';
import { PrettifyRolePipe } from './pipes/prettify-role/prettify-role.pipe';
import { PrettyAppointmentTypePipe } from './pipes/pretty-appointment-type.pipe';
import { PrettyPropertyTypePipe } from './pipes/pretty-property-type.pipe';
import { PrettyReminderTypePipe } from './pipes/pretty-reminder-type/pretty-reminder-type.pipe';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { SequentialArrayPipe } from './pipes/sequential-array.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { SnipStringPipe } from './pipes/snip-string.pipe';
import { UpFormattedPrettySuburbPipe } from './pipes/suburb-name-from.pipe';
import { ThemeNameStringTemplatePipe } from './pipes/theme-name-string-template.pipe';
import { BudgetPricingUtilities } from './utilities/budget-pricing.utilities';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        UIRouterModule,
        ReactiveFormsModule,
        TrackingModule,
        LazyLoadImageModule,
        MomentModule,
        NguiMapModule,
        OverlayModule,
        DropdownModule,
        IconModule,
        TabsModule,
        LinkModule,
        GridModule,
        CardModule,
        BackgroundImageModule,
        AvatarModule,
        MediaObjectModule,
        SpinnerModule,
        AvatarModule,
        CdnUrlModule,
        ButtonModule,
        ThemeModule,
        LoadingOverlayModule,
        TagModule,
        DateModule,
        CollapserPanelModule,
        HiddenContentIndicatorModule,
        ModalModule,
        LocalisationModule,
        TranslocoRootModule,
        AlertModule,
    ],
    declarations: [
        RmaRatingComponent,
        ActionableItemComponent,
        AvatarSectionComponent,
        RequestCallBackFormComponent,
        IsolatedContainerComponent,
        RatemyagentRatingPillComponent,
        AgentRatingCardComponent,
        UpsellReportBannerSectionComponent,
        AddressFinderComponent,
        VerticallyCenteredLayoutComponent,
        AutocompleteComponent,
        VendorReviewComponent,
        BannerSectionComponent,
        HeroComponent,
        MiniHrComponent,
        ObjectKeysPipe,
        CreateSubscriptionCtaComponent,
        PropertyCardComponent,
        EmptyStateComponent,
        ImageSizePipe,
        InitialPipe,
        ShortNumberPipe,
        ParseIntPipe,
        ImageResizePipe,
        AbsoluteNumberPipe,
        BuyerFeedbackComponent,
        ConfirmationDialogComponent,
        AvmConfidenceDisplayPipe,
        DeferLoadDirective,
        CaptionedImageComponent,
        ContentAccentComponent,
        PropertyMapComponent,
        ComparablePropertiesCardComponent,
        PropertyAlertCardComponent,
        PropertyListingCardComponent,
        PropertySoldCardComponent,
        PropertyCardNavComponent,
        SimpleCarouselComponent,
        SnipStringPipe,
        PlaceholderComponent,
        ContentFigureSectionComponent,
        PropertyExplorerSectionComponent,
        PropertyGridComponent,
        SequentialArrayPipe,
        PaymentComponent,
        MapMarkerComponent,
        IncludedFeaturesListComponent,
        AgentProfileCardComponent,
        PropertyFeaturesComponent,
        ComparablePropertyCardComponent,
        CapitaliseStringPipe,
        PropertyShowcaseComponent,
        PropertyShowcaseItemDirective,
        AgentExperienceStatsComponent,
        AgentSocialLinksComponent,
        BackNavComponent,
        MarketPerformanceSummaryBlurbComponent,
        MomentConvertToTzPipe,
        FilenamePipe,
        AlertDialogComponent,
        ButtonComponent,
        CheckboxComponent,
        ChipComponent,
        ContainerComponent,
        HelpTextComponent,
        InputComponent,
        OuterContainerComponent,
        RadioComponent,
        RadioGroupComponent,
        SelectComponent,
        SelectOptionComponent,
        SwitchComponent,
        GlobalProgressBarComponent,
        PillComponent,
        CarouselComponent,
        CarouselSlideDirective,
        CarouselIndicatorsComponent,
        RatingComponent,
        EventDotComponent,
        HrComponent,
        PasswordVisibilityToggleComponent,
        DisableInputMouseScrollDirective,
        EnvRibbonComponent,
        NcPrettyDomainPropertyTypePipe,
        PrettyPropertyTypePipe,
        SuburbsSelectorComponent,
        ArrayAsStringPipe,
        PrettyPropertyTypePipe,
        SuburbsSelectorComponent,
        UpFormattedPrettySuburbPipe,
        MiniCalendarComponent,
        AgentContactCardComponent,
        SentenceCasePipe,
        ContrastDirective,
        NoopComponent,
        TimelineComponent,
        TimelineItemComponent,
        PrettyAppointmentTypePipe,
        PropertyAttributesComponent,
        PropertyStatusBadgeComponent,
        PropertyAlertCardReadMoreDialogComponent,
        PropertyMediaObjectComponent,
        IsThemePipe,
        ThemeNameStringTemplatePipe,
        FeatureDirective,
        EmptyStateIllustrationComponent,
        NullishDefaultPipe,
        HorizontalRuleComponent,
        Link2Component,
        TimelineDotComponent,
        BreadcrumbsComponent,
        ClickStopPropagationDirective,
        HighlightTextPartialPipe,
        BackdropFloatingOrgCardComponent,
        PrettifyRolePipe,
        IsNumberPipe,
        PrettyReminderTypePipe,
        ReviewsShowcaseComponent,
    ],
    exports: [
        NguiMapModule,
        FormsModule,
        CommonModule,
        RmaRatingComponent,
        ActionableItemComponent,
        AvatarSectionComponent,
        RequestCallBackFormComponent,
        IsolatedContainerComponent,
        RatemyagentRatingPillComponent,
        AgentRatingCardComponent,
        UpsellReportBannerSectionComponent,
        AddressFinderComponent,
        ReactiveFormsModule,
        VerticallyCenteredLayoutComponent,
        UIRouterModule,
        AutocompleteComponent,
        VendorReviewComponent,
        BannerSectionComponent,
        HeroComponent,
        MiniHrComponent,
        LazyLoadImageModule,
        ObjectKeysPipe,
        CreateSubscriptionCtaComponent,
        PropertyCardComponent,
        EmptyStateComponent,
        ImageSizePipe,
        InitialPipe,
        ShortNumberPipe,
        ParseIntPipe,
        ImageResizePipe,
        AbsoluteNumberPipe,
        BuyerFeedbackComponent,
        ConfirmationDialogComponent,
        AvmConfidenceDisplayPipe,
        DeferLoadDirective,
        CaptionedImageComponent,
        ContentAccentComponent,
        PropertyMapComponent,
        ComparablePropertiesCardComponent,
        PropertyAlertCardComponent,
        PropertyListingCardComponent,
        PropertySoldCardComponent,
        SimpleCarouselComponent,
        SnipStringPipe,
        MomentModule,
        PlaceholderComponent,
        ContentFigureSectionComponent,
        PropertyExplorerSectionComponent,
        PropertyGridComponent,
        ListModule,
        SequentialArrayPipe,
        TrackingModule,
        MapMarkerComponent,
        IncludedFeaturesListComponent,
        AgentProfileCardComponent,
        PropertyFeaturesComponent,
        ComparablePropertyCardComponent,
        CapitaliseStringPipe,
        PropertyShowcaseComponent,
        PropertyShowcaseItemDirective,
        AgentExperienceStatsComponent,
        AgentSocialLinksComponent,
        BackNavComponent,
        MarketPerformanceSummaryBlurbComponent,
        MetricGridListModule,
        MomentConvertToTzPipe,
        FilenamePipe,
        AlertDialogComponent,
        ButtonComponent,
        CheckboxComponent,
        ChipComponent,
        ContainerComponent,
        HelpTextComponent,
        InputComponent,
        OuterContainerComponent,
        RadioComponent,
        RadioGroupComponent,
        SelectComponent,
        SelectOptionComponent,
        SwitchComponent,
        GlobalProgressBarComponent,
        PillComponent,
        CarouselComponent,
        CarouselSlideDirective,
        CarouselIndicatorsComponent,
        RatingComponent,
        CardModule,
        DropdownModule,
        IconModule,
        EventDotComponent,
        TabsModule,
        LinkModule,
        GridModule,
        HrComponent,
        PasswordVisibilityToggleComponent,
        DisableInputMouseScrollDirective,
        EnvRibbonComponent,
        PaymentComponent,
        PrettyPropertyTypePipe,
        SuburbsSelectorComponent,
        ArrayAsStringPipe,
        PrettyPropertyTypePipe,
        SuburbsSelectorComponent,
        UpFormattedPrettySuburbPipe,
        MiniCalendarComponent,
        AgentContactCardComponent,
        SentenceCasePipe,
        ContrastDirective,
        NcPrettyDomainPropertyTypePipe,
        NoopComponent,
        TimelineComponent,
        TimelineItemComponent,
        PrettyAppointmentTypePipe,
        PropertyAttributesComponent,
        PropertyStatusBadgeComponent,
        PropertyAlertCardReadMoreDialogComponent,
        PropertyMediaObjectComponent,
        IsThemePipe,
        ThemeNameStringTemplatePipe,
        FeatureDirective,
        EmptyStateIllustrationComponent,
        NullishDefaultPipe,
        HorizontalRuleComponent,
        Link2Component,
        TimelineDotComponent,
        BreadcrumbsComponent,
        ClickStopPropagationDirective,
        MenuModule,
        BackgroundImageModule,
        AvatarModule,
        MediaObjectModule,
        SpinnerModule,
        AvatarModule,
        HighlightTextPartialPipe,
        CdnUrlModule,
        ButtonModule,
        BackdropFloatingOrgCardComponent,
        PrettifyRolePipe,
        LocationModule,
        IsNumberPipe,
        ThemeModule,
        LoadingOverlayModule,
        TagModule,
        DateModule,
        CollapserPanelModule,
        HiddenContentIndicatorModule,
        ModalModule,
        AlertModule,
        PrettyReminderTypePipe,
        ReviewsShowcaseComponent,
    ],
    providers: [
        BudgetPricingUtilities,
        { provide: PikadayFactoryToken, useValue: pikadayFactory },
        PrettifyRolePipe,
        PrettyReminderTypePipe,
        ShortNumberPipe,
    ],
})
export class SharedModule {}
