import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GetMediaTagsResponseDto } from '../../../common/models/dto/media/get-media-tags-response.dto';

@Injectable({ providedIn: 'root' })
export class MediaTagResource {
    private readonly baseUrl = '/api/v2';

    constructor(private readonly httpClient: HttpClient) {}

    public getAll(): Observable<GetMediaTagsResponseDto> {
        return this.httpClient.get<GetMediaTagsResponseDto>(`${this.baseUrl}/media-tag`);
    }
}
