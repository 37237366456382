import { Component, HostBinding, Input } from '@angular/core';
import moment from 'moment';

@Component({
    selector: 'up-agent-experience-stats',
    templateUrl: 'agent-experience-stats.component.html',
    styleUrls: ['agent-experience-stats.component.scss'],
})
export class AgentExperienceStatsComponent {
    @Input() public totalSales: number;
    @Input() public startingYear: number;
    @Input() public size: 'small' | 'medium' = 'medium';

    @HostBinding('class.is-experience-hidden')
    public get shouldHideExperience(): boolean {
        return !this.startingYear || !this.totalSales;
    }

    public get yearsExperience(): number {
        return moment().year() - this.startingYear;
    }
}
