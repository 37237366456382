import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { debounce } from 'lodash-es';
import * as mediaQueries from 'src/main/client/app/settings/media-queries.js';

import { GeoLocation } from '../../../../common/models/domain/location/geo-location.model';
import { location } from '../../../../common/models/location.model';
import { doTranslation } from '../../../../common/utilities/i18n/do-translation.util';

@Component({
    selector: 'nc-property-image',
    templateUrl: 'property-image.component.html',
    styleUrls: ['property-image.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyImageComponent {
    // Unresized image URL
    // Todo: consider adding an input for already resized images?
    @Input() public rawImageUrl: string;
    @Input() public staticMapCoordinates?: GeoLocation;
    @Input() public formattedAddress?: string;
    @Input() public useScrim? = false;
    public renderImageWidth?: number;
    public renderImageHeight?: number;
    public readonly renderImageScale = 2;
    public readonly mediaQueries = mediaQueries;
    public readonly printImageSizePx = 1600;
    private readonly imageThresholdBufferPx = 400;
    private readonly observeResizeDebounceTimeMs = 300;
    private readonly debounceOnObserveResize = debounce(
        resizeObserverEntry => this.updateRenderImageSizes(resizeObserverEntry),
        this.observeResizeDebounceTimeMs,
        { leading: true },
    );

    public get photoImageAlt(): string {
        return this.formattedAddress
            ? doTranslation('propertyImage.imageAlt.address', { address: this.formattedAddress })
            : doTranslation('propertyImage.imageAlt.noAddress');
    }

    public get mapImageAlt(): string {
        return this.formattedAddress
            ? doTranslation('propertyImage.mapAlt.address', { address: this.formattedAddress })
            : doTranslation('propertyImage.mapAlt.noAddress');
    }

    public get mapCoordinates(): location.LatLong {
        return {
            lat: this.staticMapCoordinates?.latitude,
            lng: this.staticMapCoordinates?.longitude,
        };
    }

    public onObserveResize(resizeObserverEntry: ResizeObserverEntry): void {
        this.debounceOnObserveResize(resizeObserverEntry);
    }

    private updateRenderImageSizes(resizeObserverEntry: ResizeObserverEntry): void {
        const { contentRect } = resizeObserverEntry;
        const { width, height } = contentRect;
        const { width: newWidth, height: newHeight } = this.getSizeThresholds(width, height);

        this.renderImageWidth = newWidth;
        this.renderImageHeight = newHeight;
    }

    private getSizeThresholds(width: number, height: number): { width: number; height: number } {
        const adjustedWidth = (Math.floor(width / this.imageThresholdBufferPx) + 1) * this.imageThresholdBufferPx;
        const adjustedHeight = (Math.floor(height / this.imageThresholdBufferPx) + 1) * this.imageThresholdBufferPx;

        return { width: adjustedWidth, height: adjustedHeight };
    }
}
