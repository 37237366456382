import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

// Todo: consider merging this with a general meta service
@Injectable()
export class CanonicalService {
    private renderer2: Renderer2;

    constructor(@Inject(DOCUMENT) private document: Document, private rendererFactory2: RendererFactory2) {
        this.renderer2 = this.rendererFactory2.createRenderer(this.document, null);
    }

    public removeCanonicalLinks(): void {
        const canonicalLinks = Array.from(this.document.querySelectorAll('[rel=canonical]'));
        canonicalLinks.forEach(link => this.renderer2.removeChild(link.parentElement, link));
    }

    public setCanonicalLink(url: string): void {
        // Since only one canonical link should exist on a page, make sure to clear any existing
        // canonical link before setting a new one
        this.removeCanonicalLinks();

        const link = this.renderer2.createElement('link');

        this.renderer2.setAttribute(link, 'rel', 'canonical');
        this.renderer2.setAttribute(link, 'href', url);
        this.renderer2.appendChild(this.document.head, link);
    }
}
