<div class="u-text-label--sm">
    <up-mini-hr *ngIf="style === 'line'" class="u-margin-right--sm"> </up-mini-hr>
    <svg aria-hidden="true" class="heartbeat u-margin-right--sm" *ngIf="style === 'heartbeat'" width="30" height="17">
        <path
            d="M4.748 10.692l2.034-2.668 3.541 8.25c.377.88 1.65.844 1.975-.056l4.401-12.064 3.7 8.932c.288.695 1.201.874 1.735.34l2.285-2.31h4.365c.585 0 1.06-.47 1.06-1.048 0-.58-.475-1.049-1.06-1.049h-4.786c-.283 0-.555.113-.754.312l-1.502 1.465L17.594.783c-.368-.889-1.652-.86-1.979.045l-4.393 12.166-3.218-7.52c-.32-.748-1.34-.868-1.828-.216L3.392 9.02H1.216c-.585 0-1.06.47-1.06 1.049s.475 1.048 1.06 1.048H3.84c.258 0 .71-.174.909-.424z"
            [attr.fill]="color"
            fill-rule="nonzero"
        />
    </svg>
    <ng-content></ng-content>
</div>
