import { TargetState, Transition } from '@uirouter/angular';

import { four04State } from '../../../../modules/four04/four04.states';

export function get404RedirectTargetState(transition: Transition): TargetState {
    const targetUrl = transition.router.stateService.href(transition.to(), transition.params(), transition.options());
    /**
     *  Since UI-Router won't update the URL until after the transition has succeeded, if we redirect to
     *  the 404 page the user would see the 404 page component, but the URL would be stuck on the page they
     *  came from. This is confusing and is not consistent with our current pattern of handling 404 URLs.
     *
     *  To work around this, we dynamically register a new temporary 404 state on-the-fly which has the URL
     *  of the target state they are not permitted to access and send the user there instead. This makes
     *  sure the URL is updated as expected. We also make sure to deregister this temporary state if they
     *  navigate again elsewhere in the app (eg. navigate back to the previous page).
     */
    const temporary404State = transition.router.stateRegistry.register({
        ...four04State,
        url: targetUrl,
        // It is important that the new state is a child of the 404 top-level state, or the state won't be
        // passed the providers it is expecting.
        name: '404.temporary-not-found-state',
        onExit: transition => {
            transition.router.stateRegistry.deregister(transition.from().name);
        },
    });

    return transition.router.stateService.target(temporary404State.name, undefined, { location: true });
}
