<h3 class="u-text-heading--sm u-text-weight--semi-bold u-text-letter-spacing--large u-margin-bottom--xs"
    >Key features</h3
>
<ul class="key-features">
    <li class="u-display--inline-block key-feature" *ngFor="let keyFeature of keyFeatures">
        <nc-tag>
            <nc-icon
                *ngIf="keyFeature | ncPropertyFeatureToIconName as iconName"
                class="u-margin-right--2xs"
                [size]="'small'"
                [name]="iconName"
            ></nc-icon>
            {{ keyFeature | upSentenceCase | uppercase }}</nc-tag
        >
    </li>
</ul>
