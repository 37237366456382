import { InjectionToken } from '@angular/core';
import { initializeApp } from 'firebase/app';

export interface FirebaseAppInjection {
    initializeApp: typeof initializeApp;
}

export const FirebaseAppInjection = {
    initializeApp,
};
export const FirebaseAppInjectionToken = new InjectionToken<FirebaseAppInjection>('FirebaseAppInjection');
