import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Input,
    OnInit,
    PLATFORM_ID,
    QueryList,
    Renderer2,
    ViewChildren,
} from '@angular/core';
import { StateService } from '@uirouter/angular';

import { AOSToken } from '../../../../common/injection-tokens/aos.injection-token';
import { ImagesLoadedToken } from '../../../../common/injection-tokens/images-loaded.injection-token';
import { AddressT } from '../../../../common/models/address.model';
import { aos } from '../../../../common/models/aos.model';
import { environment } from '../../../../common/models/environment.model';
import { imagesLoaded } from '../../../../common/models/images-loaded.model';
import { TrackingService } from '../../../../common/services/tracking.service';
import { FeatureFigureWrapComponent } from '../../../../components/feature-figure-wrap/feature-figure-wrap.component';
import { ConversionFacade } from '../../../../store/conversion/conversion.facade';
import { AccordionPanelState } from '../../../accordion/components/accordion-panel/accordion-panel.component';
import { ConversionService } from '../../../core/services/conversion/conversion.service';
import { EnvironmentService } from '../../../core/services/environment.service';
import { ThemeName } from '../../../core/services/theme.service';

@Component({
    selector: 'nc-property-report-landing',
    templateUrl: 'property-report-landing.component.html',
    styleUrls: ['property-report-landing.component.scss', 'property-report-landing.theme.component.scss'],
})
export class PropertyReportLandingComponent implements OnInit, AfterViewInit {
    @Input() public accentText: string;
    @Input() public ctaButtonText: string;
    @Input() public headingText: string;
    @Input() public descriptionText: string;
    @Input() public bgImgDesktopSrc: string;
    @Input() public bgImgMobileSrc: string;
    @Input() public submissionError: string;
    @Input() public propertyReportAlreadyRequested: boolean;
    @Input() public propertyReportDailyRequestLimitExceeded: boolean;
    @ViewChildren(FeatureFigureWrapComponent, { read: ElementRef }) public featureFigureWraps: QueryList<ElementRef>;
    @ViewChildren('aos', { read: ElementRef }) public aosEls: QueryList<ElementRef>;
    public ThemeName = ThemeName;
    public GroupedOrganisation = environment.GroupedOrganisationName;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(ImagesLoadedToken) private imagesloaded: imagesLoaded.ImagesLoaded,
        @Inject(AOSToken) private AOS: aos.AOS,
        private renderer: Renderer2,
        private stateService: StateService,
        private conversionService: ConversionService,
        private trackingService: TrackingService,
        private conversionFacade: ConversionFacade,
        private environmentService: EnvironmentService,
    ) {}

    public get orgDisplayName(): string {
        return this.environmentService.config.organisation.displayName;
    }

    public ngOnInit(): void {
        this.conversionService.resetConversionState();
        this.conversionFacade.resetState();
    }

    public setAddress(address?: AddressT): void {
        if (!address) return;

        this.trackingService.trackDataEvent('conversion-property-report-landing-capture-address', {
            address: address.formattedAddress,
        });
        this.conversionService.storeConversionState(address);
        this.stateService.go('conversion.property-report.address-found', { address });
    }

    public ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            // Wait for all feature images to load before trying to init Animate On Scroll to prevent it from trying to
            // calculate the container element's position and dimensions prematurely
            const featureFigureWrapNativeElements = this.featureFigureWraps.toArray().map(el => el.nativeElement);
            this.imagesloaded(featureFigureWrapNativeElements, () => this.initImageScrollAnimation());
        } else {
            // Disable Animate On Scroll for SSR by removing data-aos attributes on relevant elements
            const aosNativeElement = this.aosEls.toArray().map(el => el.nativeElement);
            aosNativeElement.forEach(el => this.renderer.removeAttribute(el, 'data-aos'));
        }
    }

    public onPanelStateChange(state: AccordionPanelState, panelTitle: string): void {
        if (state !== 'expanded') return;

        this.trackingService.trackDataEvent('propertyReportLanding', {
            subname: 'faqExpanded',
            data: panelTitle,
        });
    }

    private initImageScrollAnimation(): void {
        this.AOS.init({
            offset: 200,
            easing: 'ease-out-quart',
            duration: 600,
            once: true,
        });
    }
}
