import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConciergeApi } from '../../../common/models/concierge-api.model';

@Injectable()
export class ConciergeResource {
    private readonly baseUrl = '/api/v2/users/concierge';

    constructor(private httpClient: HttpClient) {}

    public getAllUsers(assigned?: boolean) {
        return this.httpClient.get<ConciergeApi.AllUsers.GET.Response>(`${this.baseUrl}`, {
            params: { ...(typeof assigned === 'boolean' && { assigned: String(assigned) }) },
        });
    }
}
