import { AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Breakpoints, Controls, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import findIndex from 'lodash-es/findIndex';
import { Moment } from 'moment';

import { Glide, GlideFactory, GlideFactoryToken } from '../../../../../common/injection-tokens/glide.injection-token';

export interface DateConfig {
    date: Moment;
    isDisabled: boolean;
}

@Component({
    selector: 'up-appraisal-booker-calendar',
    templateUrl: './appraisal-booker-calendar.component.html',
    styleUrls: ['./appraisal-booker-calendar.component.scss'],
})
export class AppraisalBookerCalendarComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() public dateConfigs: DateConfig[];
    @Output() public change: EventEmitter<Moment> = new EventEmitter<Moment>();
    public monthRangeDisplay: string;
    public selectedDate: Moment;
    private calendar: Glide;
    private previousSelectedDate: Moment;

    constructor(@Inject(GlideFactoryToken) private glideFactory: GlideFactory) {}

    public ngOnInit(): void {
        if (!(this.dateConfigs || this.dateConfigs.length)) {
            throw new TypeError('dateConfigs must not be empty or undefined');
        }
        const lastDate = this.dateConfigs[this.dateConfigs.length - 1].date;
        const fromMonth = this.dateConfigs[0].date.format('MMMM');
        const toMonth = lastDate.format('MMMM');
        const toYear = lastDate.format('YYYY');
        this.monthRangeDisplay = `${fromMonth === toMonth ? fromMonth : `${fromMonth} - ${toMonth}`} ${toYear}`;
        this.calendar = this.glideFactory('.glide', {
            focusAt: 'center',
            rewind: false,
            startAt: this.getFirstAvailableDateIndex(),
            gap: 0,
            animationDuration: 200,
            animationTimingFunc: 'ease-out',
            breakpoints: {
                360: { perView: 3 },
                960: { perView: 5 },
                99999: { perView: 7 },
            },
        });
        this.calendar.on(['build.after', 'run.after'], () => {
            this.selectedDate = this.dateConfigs[this.calendar.index].date;
            // These events will fire on page resize due to the carousel needing to recalculate all its layout, this
            // will prevent it from re-emitting the same date value if it hasn't changed
            if (this.previousSelectedDate === this.selectedDate) return;
            this.change.emit(this.selectedDate);
            this.previousSelectedDate = this.selectedDate;
        });
    }

    public ngAfterViewInit(): void {
        this.calendar.mount({ Controls, Breakpoints, Swipe });
    }

    public selectDate(date: Moment): void {
        const slideIndex = findIndex(this.dateConfigs, x => date.isSame(x.date, 'day'));
        this.calendar.go(`=${slideIndex}`);
    }

    public paginate(direction: 'next' | 'prev'): void {
        const currentIndex = this.calendar.index;
        const pageSize = this.calendar.settings.perView;
        const offset = direction === 'next' ? pageSize : -pageSize;
        const maxIndex = this.dateConfigs.length - 1;
        let finalIndex = currentIndex + offset;
        if (finalIndex < 0) {
            finalIndex = 0;
        } else if (finalIndex > maxIndex) {
            finalIndex = maxIndex;
        }
        this.calendar.go(`=${finalIndex}`);
    }

    public isDateActive(date: Moment): boolean {
        return date.isSame(this.dateConfigs[this.calendar.index].date, 'day');
    }

    public ngOnDestroy(): void {
        if (this.calendar) {
            this.calendar.destroy();
        }
    }

    private getFirstAvailableDateIndex(): number {
        // Index 0 of the array is today, we want to initially select the date that's 48 hours ahead of today, so we use
        // the an index of 2;
        const dateIn48HoursIndex = 2;
        const isDateIn48HoursAvailable = !this.dateConfigs[dateIn48HoursIndex].isDisabled;
        const earliestSuitableDate = this.dateConfigs.filter(dateConfig => !dateConfig.isDisabled)[0];
        // If for some reason not a single date is available, just default to selecting the first date
        const earliestAvailableDateIndex = !!earliestSuitableDate
            ? this.dateConfigs.findIndex(date => date.date.isSame(earliestSuitableDate.date, 'day'))
            : 0;
        return isDateIn48HoursAvailable ? dateIn48HoursIndex : earliestAvailableDateIndex;
    }
}
