<up-outer-container class="outer-wrapper">
    <div class="wrapper">
        <up-container class="content">
            <div class="content-wrapper">
                <div [hidden]="!hasContentAccent" class="u-margin-bottom--md">
                    <ng-content select="up-content-accent"></ng-content>
                </div>
                <div> <ng-content select="[heading]"></ng-content> </div>
                <div class="u-margin-top--xl"> <ng-content select="[body]"></ng-content> </div>
            </div>
        </up-container>
        <div class="background">
            <div
                class="background-image desktop"
                [backgroundImage]="imgSrcDesktop"
                [backgroundImagePosition]="imgPosDesktop"
            >
            </div>
            <div
                class="background-image mobile"
                [backgroundImage]="imgSrcMobile"
                [backgroundImagePosition]="imgPosMobile"
            >
            </div>
        </div>
    </div>
</up-outer-container>
