import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';

type Mode = 'reset-password' | 'shadow-sign-up';

@Component({
    selector: 'up-check-email',
    templateUrl: 'check-email.component.html',
})
export class CheckEmailComponent implements OnInit {
    @Input() public email: string;
    // content of component changes depending on which version to show
    @Input() public mode: Mode;

    constructor(private stateService: StateService) {}

    public ngOnInit(): void {
        if (!this.email || !this.mode) {
            this.stateService.go('user.signin', {}, { location: 'replace' });
        }
    }
}
