<label
    [ngClass]="{
        'switch-container': true,
        'is-checked': checked,
        'is-focused': focused,
        'is-disabled': disabled
    }"
>
    <div *ngIf="labelPosition === 'before'" class="label-content u-padding-right--xs">
        <ng-content *ngTemplateOutlet="contentProjectionTemplate"></ng-content>
    </div>
    <input
        #input
        class="switch u-hide-visually"
        type="checkbox"
        (click)="onInputClick($event)"
        (change)="onInputChange($event)"
        (focus)="onFocus()"
        (blur)="onBlur()"
        [id]="uid"
        [name]="name"
        [checked]="checked"
        [disabled]="disabled"
    />

    <div class="label-visual"> <div class="knob"></div> <div class="track"></div> </div>
    <div *ngIf="labelPosition === 'after'" class="label-content u-padding-left--xs">
        <ng-content *ngTemplateOutlet="contentProjectionTemplate"></ng-content>
    </div>
</label>
<ng-template #contentProjectionTemplate> <ng-content></ng-content> </ng-template>
