import { Action, createAction, union } from '@ngrx/store';

import { appraisalReport } from '../../common/models/appraisal-report.model';
import { Property } from '../../common/models/property.model';
import { todo } from '../../common/models/todo.model';

export const GET_PROPERTY = 'GET_PROPERTY';
export const PROPERTY_LOADED = 'PROPERTY_LOADED';
export const GET_APPRAISAL_REPORT = 'GET_APPRAISAL_REPORT';
export const PROPERTY_GENERAL_ERROR = 'PROPERTY_GENERAL_ERROR';
export const ELECT_CONVEYANCER_CHOICE = 'ELECT_CONVEYANCER_CHOICE';
export const ELECT_PROVIDED_CONVEYANCER = 'ELECT_PROVIDED_CONVEYANCER';
export const GET_APPRAISAL_REPORT_SUCCESS = 'GET_APPRAISAL_REPORT_SUCCESS';
export const GET_APPRAISAL_REPORT_ERROR = 'GET_APPRAISAL_REPORT_ERROR';
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS';
export const GET_APPOINTMENTS_ERROR = 'GET_APPOINTMENTS_ERROR';

export class GetPropertyAppointments implements Action {
    public readonly type = GET_APPOINTMENTS;
    constructor(public payload: string) {}
}

export class GetPropertyAppointmentsSuccess implements Action {
    public readonly type = GET_APPOINTMENTS_SUCCESS;
    constructor(public payload: todo.Appointment[]) {}
}

export class GetPropertyAppointmentsError implements Action {
    public readonly type = GET_APPOINTMENTS_ERROR;
    constructor(public payload: todo.Appointment[]) {}
}

export class GetProperty implements Action {
    public readonly type = GET_PROPERTY;

    constructor(public payload: string) {}
}

export class PropertyLoaded implements Action {
    public readonly type = PROPERTY_LOADED;

    constructor(public payload: Property) {}
}

export class ElectConveyancerChoice implements Action {
    public readonly type = ELECT_CONVEYANCER_CHOICE;
}

export class ElectProvidedConveyancer implements Action {
    public readonly type = ELECT_PROVIDED_CONVEYANCER;
}

export class GetAppraisalReport implements Action {
    public readonly type = GET_APPRAISAL_REPORT;
    constructor(public payload: string) {}
}

export class GetAppraisalReportError implements Action {
    public readonly type = GET_APPRAISAL_REPORT_ERROR;
    constructor(public payload: string) {}
}

export class GetValuationSuccess implements Action {
    public readonly type = GET_APPRAISAL_REPORT_SUCCESS;

    constructor(public payload: appraisalReport.AppraisalReport) {}
}

export class PropertyGeneralError implements Action {
    public readonly type = PROPERTY_GENERAL_ERROR;
}

export const resetStore = createAction('[Property Store] Reset Store');

const actions = union({ resetStore });

export type PropertyAction =
    | GetProperty
    | PropertyLoaded
    | ElectConveyancerChoice
    | ElectProvidedConveyancer
    | GetAppraisalReport
    | GetValuationSuccess
    | PropertyGeneralError
    | GetPropertyAppointments
    | GetPropertyAppointmentsSuccess
    | GetPropertyAppointmentsError
    | GetAppraisalReportError
    | typeof actions;
