import { Component, Input } from '@angular/core';

type Type = 'default' | 'error' | 'success';

@Component({
    selector: 'nc-help-text, up-help-text',
    templateUrl: './help-text.component.html',
    styleUrls: ['./help-text.component.scss'],
})
export class HelpTextComponent {
    @Input() public type: Type = 'default';
}
