import { CommonModule, CurrencyPipe, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxCurrencyModule } from 'ngx-currency';
import { MomentModule } from 'ngx-moment';

import { AddressFormatterComponent } from '../components/address-formatter/address-formatter.component';
import { FeatureFigureWrapComponent } from '../components/feature-figure-wrap/feature-figure-wrap.component';
import { PropertyDetailsComponent } from '../components/property-details/property-details.component';
import { SectionHeroComponent } from '../components/section-hero/section-hero.component';
import { UserInterceptor } from '../interceptors/user.interceptor';
import { PropertyReportDialogsModule } from '../modules/property-report/dialogs/property-report-dialogs.module';
import { QrCodeGeneratorModule } from '../modules/qr-code-generator/qr-code-generator.module';
import { UserDialogsModule } from '../modules/user/dialogs/user-dialogs.module';
import { ConversionFacade } from '../store/conversion/conversion.facade';
import { InternalPropertyFacade } from '../store/internal-property/internal-property.facade';
import { InternalSuppliersFacade } from '../store/internal-suppliers/internal-suppliers.facade';
import { PropertyFacade } from '../store/property/property.facade';
import { SuburbsFacade } from '../store/suburbs/suburbs.facade';
import { UserFacade } from '../store/user/user.facade';

import { ConstantsModule } from './constants/constants';
import { TrackingModule } from './directive/tracking.module';
import { AOS, AOSToken } from './injection-tokens/aos.injection-token';
import { glideFactory, GlideFactoryToken } from './injection-tokens/glide.injection-token';
import { imagesLoaded, ImagesLoadedToken } from './injection-tokens/images-loaded.injection-token';
import { AbbreviateDatePipe } from './pipes/abbreviate-date.pipe';
import { FilterByPipe } from './pipes/filter-by';
import { MediaTagOnlyPipe } from './pipes/media-tag-only.pipe';
import { MediaTagPipe } from './pipes/media-tag.pipe';
import { OfferStatusPipe } from './pipes/offer-status.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { PropertyTypePipe } from './pipes/property-type.pipe';
import { CarouselService } from './services/carousel.service';
import { DecapitaliseService } from './services/decapitalise.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { GoogleService } from './services/google.service';
import { InternalUserService } from './services/internal-user.service';
import { LocalStorageService } from './services/local-storage.service';
import { PropertyService } from './services/property.service';
import { StreetViewService } from './services/street-view.service';
import { SuburbService } from './services/suburb.service';
import { TaskService } from './services/task.service';
import { TrackingService } from './services/tracking.service';
import { WindowRef } from './services/window.service';
import { SharedModule } from './shared.module';

function convertObservableToPromise(observable) {
    return new Promise((resolve, reject) => {
        observable.subscribe(
            response => resolve(response), // Success
            error => reject(error),
        );
    });
}

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        UIRouterModule,
        MomentModule,
        ConstantsModule,
        ReactiveFormsModule,
        LazyLoadImageModule,
        TrackingModule,
        PropertyReportDialogsModule,
        SharedModule,
        UserDialogsModule,
        QrCodeGeneratorModule,
    ],
    declarations: [
        PropertyDetailsComponent,
        MediaTagPipe,
        MediaTagOnlyPipe,
        AbbreviateDatePipe,
        PropertyTypePipe,
        OfferStatusPipe,
        OrderByPipe,
        FilterByPipe,
        OfferStatusPipe,
        SectionHeroComponent,
        AddressFormatterComponent,
        FeatureFigureWrapComponent,
    ],
    exports: [
        NgxCurrencyModule,
        CommonModule,
        MomentModule,
        FormsModule,
        PropertyDetailsComponent,
        MediaTagPipe,
        MediaTagOnlyPipe,
        AbbreviateDatePipe,
        PropertyTypePipe,
        OfferStatusPipe,
        UIRouterModule,
        OrderByPipe,
        FilterByPipe,
        ReactiveFormsModule,
        SectionHeroComponent,
        AddressFormatterComponent,
        LazyLoadImageModule,
        FeatureFigureWrapComponent,
        TrackingModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UserInterceptor,
            multi: true,
        },
        Location,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: LOCALE_ID, useValue: 'en-AU' },
        { provide: ImagesLoadedToken, useValue: imagesLoaded },
        { provide: AOSToken, useValue: AOS },
        { provide: GlideFactoryToken, useValue: glideFactory },
        WindowRef,
        TrackingService,
        CarouselService,
        PropertyService,
        TaskService,
        DecapitaliseService,
        StreetViewService,
        ErrorHandlerService,
        GoogleService,
        CurrencyPipe,
        InternalUserService,
        SuburbService,
        LocalStorageService,
        InternalPropertyFacade,
        ConversionFacade,
        UserFacade,
        SuburbsFacade,
        PropertyFacade,
        InternalSuppliersFacade,
    ],
})

/** @deprecated Deprecated in favor of shared.module.ts. */
class UpsideCommonModule {}
export { UpsideCommonModule, convertObservableToPromise };
