import { activePipeSuccessPathWithParam } from '../../common/constants/active-pipe.constants';
import { UpStateDeclaration } from '../../common/models/router.model';

export const activePipeOauthSuccessFutureStates: UpStateDeclaration[] = [
    {
        name: 'active-pipe-oauth-success.**',
        url: activePipeSuccessPathWithParam,
        loadChildren: () =>
            import(/*webpackChunkName: 'active-pipe-oauth-success-module'*/ './active-pipe-oauth-success.module').then(
                module => module.ActivePipeOauthSuccessModule,
            ),
    },
];
