import { Injector } from '@angular/core';
import { UIRouter } from '@uirouter/angular';

import { modal } from '../../common/models/modal.model';
import { ModalService } from '../../modules/modal/services/modal/modal.service';

export const modalUiRouteHooks = (router: UIRouter, injector: Injector) => {
    const modalService = injector.get(ModalService);
    // Close modals on navigation
    router.transitionService.onSuccess({}, () => {
        modalService.close(modal.CloseType.Dismiss);
    });
};
