<div
    class="wrapper"
    [ngClass]="{
        'is-small': size === 'small',
        'is-large': size === 'large',
        'is-round': style === 'round',
        'is-x-large': size === 'x-large',
        'is-theme-translucent': theme === 'translucent',
        'is-theme-solid': theme === 'solid'
    }"
>
    <div #background class="background"> </div>
    <div
        class="content"
        [ngClass]="{
            'light-text': activeSolidThemeTextColor === SolidThemeTextColor.Light,
            'dark-text': activeSolidThemeTextColor === SolidThemeTextColor.Dark
        }"
    >
        <span class="icon-wrapper"><ng-content select="[slot='icon']"></ng-content></span>
        <ng-content></ng-content>
        <button type="button" *ngIf="isDeletable" class="u-margin-left--xs" (click)="onDelete()">
            <nc-icon
                aria-label="Delete"
                [color]="'currentColor'"
                [size]="size === 'medium' ? 'x-small' : 'small'"
                [name]="'crossBold'"
            >
            </nc-icon>
        </button>
    </div>
</div>
