import { Injector } from '@angular/core';
import { UIRouter } from '@uirouter/angular';

import { NavigationService } from '../../modules/navigation/navigation.service';

export const navigationServiceUiRouterHooks = (router: UIRouter, injector: Injector) => {
    router.transitionService.onSuccess({}, transition => {
        const navigationService: NavigationService = injector.get(NavigationService);
        const to = transition.$to();

        navigationService.closeMobilePane();
        navigationService.closeDropdowns();
        navigationService.collapseTabletPane();
        // After any successful route transition, update the global navigation service configuration
        // if there are any declared in the state declaration.
        navigationService.setNavConfig(to.data && to.data.navConfig);
    });
};
