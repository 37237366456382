import { Injectable } from '@angular/core';

import { MediaTagName } from '../../../common/models/domain/media/media-tag-name.model';
import { media } from '../../../common/models/media.model';

@Injectable()
export class MediaService {
    public getUrlOfFirstMediaWithTag(medias: media.File[], tag: MediaTagName): string {
        const firstMatchingMedia = this.getFirstMatchingMediaWithTag(medias, tag);
        return firstMatchingMedia ? firstMatchingMedia.url : undefined;
    }

    public shouldTagHaveServingUrl(tag: MediaTagName): boolean {
        switch (tag) {
            case 'Images':
            case 'Avatar':
            case 'Brochure':
            case 'BrochureSecondary':
            case 'BrochureTertiary':
            case 'Floorplan':
            case 'PortalFloorplan':
            case 'MainImage':
            case 'OnlineImage':
            case 'Signboard': {
                return true;
            }
            default: {
                return false;
            }
        }
    }

    private getFirstMatchingMediaWithTag(medias: media.File[], tag: MediaTagName): media.File {
        return medias.find(({ tags }) => tags && tags.includes(tag));
    }
}
