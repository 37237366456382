import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiSuccessResponse } from '../../../common/models/common.model';
import { domainOAuth } from '../../../common/models/domain-oauth.model';

const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

const BASE_URL = `/api/v2/domain/oauth`;

@Injectable()
export class DomainOAuthResource {
    constructor(private http: HttpClient) {}

    public checkAuthorisation(organisation: String): Observable<domainOAuth.api.checkAuthorisation.get.Response> {
        return this.http.get<domainOAuth.api.checkAuthorisation.get.Response>(
            `${BASE_URL}/${organisation}/check-authorisation`,
            HEADERS_CONFIG,
        );
    }

    public createAccessToken(organisation: String, code: String): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(
            `${BASE_URL}/${organisation}/create-access-token/${code}`,
            HEADERS_CONFIG,
        );
    }
}
