import { featureGuard } from '../../../ui-router/feature-guard';
import { NoopComponent } from '../../noop/noop.component';
import { NcConversionFunnelStateDeclaration } from '../conversion.states';

import { GetStartedCallbackComponent } from './get-started-callback/get-started-callback.component';
import { GetStartedCallbackCompleteComponent } from './get-started-callback-complete/get-started-callback-complete.component';
import { GetStartedServicesComponent } from './get-started-services/get-started-services.component';
import { GetStartedComponent } from './get-started.component';
import { NavGetStartedComponent } from './nav-get-started/nav-get-started.component';

export const GetStartedStates: NcConversionFunnelStateDeclaration[] = [
    {
        name: 'conversion.get-started',
        url: '/get-started',
        views: {
            'navbar@vendor-app': {
                component: NavGetStartedComponent,
            },
            conversion: {
                component: GetStartedComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
        redirectTo: 'conversion.get-started.services',
        data: {
            featureGuard: featureGuard({ featureFlag: 'upsideMarketingPagesEnabled' }),
        },
    },
    {
        name: 'conversion.get-started.services',
        views: {
            content: {
                component: GetStartedServicesComponent,
            },
        },
        data: {
            meta: {
                title: 'How can we assist you?',
                description: `How can we assist you? Sell your home with an experienced local Upside agent. Total transparency, award-winning service and great results.`,
            },
        },
    },
    {
        name: 'conversion.get-started.callback',
        url: '/callback',
        views: {
            content: {
                component: GetStartedCallbackComponent,
            },
        },
        data: {
            meta: {
                title: 'Request a callback',
                description: `Please provide us with the details and a friendly member of the team will call you back. Sell your home with an experienced local Upside agent. Total transparency, award-winning service and great results.`,
            },
        },
    },
    {
        name: 'conversion.get-started.complete',
        url: '/complete',
        views: {
            content: {
                component: GetStartedCallbackCompleteComponent,
            },
        },
        data: {
            meta: {
                title: 'Callback booked',
            },
        },
    },
];
