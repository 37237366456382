<ng-container *ngIf="address">
    <up-container class="appraisal-requested-page">
        <up-content-accent class="u-margin-bottom--sm" [style]="'heartbeat'">Confirmation</up-content-accent>
        <header class="u-margin-bottom--xl">
            <h1 class="u-text-heading--lg u-display--inline">Your appraisal has been booked</h1>
            <up-icon
                class="tick u-text-color--positive u-margin-left--xs"
                [name]="'checkCircleFill'"
                [color]="'currentColor'"
            ></up-icon>
        </header>

        <up-grid class="u-padding-bottom--3xl" [gutters]="'medium'">
            <up-grid-col [col]="12" [col@sm]="8">
                <div class="process u-margin-bottom--lg">
                    <div class="process-label u-text-label--sm"> Appointment </div>
                    <div class="process-content">
                        <div> {{ bookedTime$ | async | amDateFormat : 'ddd, Do MMMM YYYY, h:mm A' }} </div>
                        <div> {{ address.formattedAddress }} </div>
                    </div>
                </div>
                <ngui-map
                    zoom="13"
                    class="map u-margin-bottom--lg"
                    [center]="address.geoLocation | ncGeoLocationToLatLng"
                    [disableDefaultUI]="true"
                    [disableDoubleClickZoom]="true"
                    [draggable]="false"
                    [scrollwheel]="false"
                >
                    <custom-marker [position]="[address.geoLocation?.latitude, address.geoLocation?.longitude]">
                        <up-map-marker></up-map-marker>
                    </custom-marker>
                </ngui-map>
                <div class="process u-margin-bottom--lg">
                    <div class="process-label u-text-label--sm"> Next Steps </div>
                    <div class="process-content">
                        <ol
                            up-timeline
                            [dotColor]="'brand2Regular' | upColor : 'cssVar'"
                            [lineColor]="'brand2Regular' | upColor : 'cssVar'"
                        >
                            <li up-timeline-item [dotFilled]="true">
                                {{ agentDisplayName$ | async }} will give you a courtesy call to confirm the details.
                            </li>
                            <li up-timeline-item>
                                {{ agentDisplayName$ | async }} will visit your property and discuss the local market.
                                They’ll also complete your property profile so they can deliver you the most accurate
                                appraisal.
                            </li>
                            <li up-timeline-item> {{ agentDisplayName$ | async }} will send you your appraisal </li>
                        </ol>
                    </div>
                </div>
                <div class="process u-margin-bottom--lg">
                    <div class="process-label u-text-label--sm"> What do I need to have? </div>
                    <div class="process-content">
                        Please ensure you have valid <strong>proof of ID</strong> ready and a
                        <strong>recent rates notice</strong> to verify your ownership of the property.
                    </div>
                </div>
                <up-button
                    *ngIf="authData?.authenticated"
                    [style]="'plain'"
                    [upSref]="'dashboard.appraisal'"
                    [upSrefParams]="{ id: propertyId }"
                    [arrowIcon]="true"
                    >View My Property</up-button
                >
            </up-grid-col>
            <up-grid-col [col@sm]="4">
                <ng-container *ngIf="agent$ | async">
                    <up-agent-contact-card
                        *ngIf="agentProfile$ | async as agentProfile; else noAgentProfile"
                        [caption]="'If you need to reschedule'"
                        [agent]="agentProfile"
                    ></up-agent-contact-card>
                    <ng-template #noAgentProfile>
                        <div *ngIf="!errorLoadingAgentProfile" class="u-text-align--center u-padding-vertical--3xl">
                            <up-spinner></up-spinner>
                        </div>
                    </ng-template>
                </ng-container>
            </up-grid-col>
        </up-grid>
    </up-container>
    <nc-vendor-reviews-section class="u-margin-bottom--6xl-r"></nc-vendor-reviews-section>
</ng-container>
