import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { StateService } from '@uirouter/angular';

import { UpValidators } from '../../../../common/form-validators/validators';
import { conversion } from '../../../../common/models/conversion.model';
import { User } from '../../../../common/models/user.model';
import { UserService } from '../../../../common/services/user.service';
import { ConversionService } from '../../../../modules/core/services/conversion/conversion.service';

@Component({
    selector: 'up-conversion-capture-phone-number',
    templateUrl: './conversion-capture-phone-number.component.html',
    styleUrls: ['./conversion-capture-phone-number.component.scss'],
})
export class ConversionCapturePhoneNumberComponent implements OnInit {
    @Input() public user: User;
    @Input() public step: number;
    @Input() public funnelName: conversion.Funnel;
    public formSubmittedAttempt = false;
    public authData: any;
    public phoneNumberInput = new FormControl(null, [Validators.required, UpValidators.phoneNumberValidator]);

    constructor(
        private userService: UserService,
        private stateService: StateService,
        private conversionService: ConversionService,
    ) {
        this.userService.userAuthDetailsUpdated$.subscribe(authData => (this.authData = authData));
    }

    public ngOnInit(): void {
        if (!this.user) {
            this.conversionService.goToFunnelEntry(this.funnelName);
            return;
        }
        if (
            this.authData.authenticated &&
            typeof this.authData.phoneNumber === 'string' &&
            this.authData.phoneNumber.length > 0
        ) {
            this.user.phoneNumber = this.authData.phoneNumber;
            this.stateService.go('^.complete', { user: this.user });
        }
    }

    public next(): void {
        this.formSubmittedAttempt = true;
        if (this.phoneNumberInput.invalid) {
            return;
        }
        this.user.phoneNumber = this.phoneNumberInput.value;
        this.stateService.go('^.complete', { user: this.user });
    }
}
