import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

import { EnvironmentService } from './environment.service';

@Injectable()
export class TitleService {
    constructor(private environmentService: EnvironmentService, private title: Title) {}

    public setInternalTitle(): void {
        const title = `${this.environmentService.config.organisation.displayName} | NurtureCloud`;
        this.title.setTitle(title);
    }

    public setTitleWithTrailingOrgName(title: string): void {
        this.environmentService.config$
            .pipe(map(({ organisation }) => organisation.displayName))
            .subscribe(displayName => this.title.setTitle(`${title} | ${displayName}`));
    }
}
