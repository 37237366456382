import { FocusKeyManager } from '@angular/cdk/a11y';
import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    Optional,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { MenuAppearance } from '../../models/menu-appearance.model';
import { MenuContainerComponent } from '../menu-container/menu-container.component';
import { MenuListItemContentComponent } from '../menu-list-item-content/menu-list-item-content.component';

@UntilDestroy()
@Component({
    selector: 'nc-menu',
    templateUrl: 'menu.component.html',
    styleUrls: ['menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements AfterContentInit, OnDestroy {
    @Input() public appearance: MenuAppearance = 'small';
    @Input() public isInitialising = false;
    @Input() public columns: 1 | 2 = 1;
    @Output() public readonly open = new EventEmitter<void>();
    @Input('class') public panelClass: string;
    @ViewChild(TemplateRef, { static: false }) public templateRef: TemplateRef<any>;
    @HostBinding('class.nc-menu') public readonly isClassMenu = true;
    public readonly menuItems$ = new BehaviorSubject<MenuListItemContentComponent[]>([]);
    private focusKeyManager: FocusKeyManager<MenuListItemContentComponent>;
    private readonly isOpen$ = new BehaviorSubject(false);

    @HostBinding('class.is-appearance-large')
    public get isAppearanceLarge(): boolean {
        return this.appearance === 'large';
    }

    @HostBinding('class.is-appearance-small')
    public get isAppearanceSmall(): boolean {
        return this.appearance === 'small';
    }

    @HostBinding('class.is-columns-2')
    public get isColumnsTwo(): boolean {
        return this.columns === 2;
    }

    constructor(
        public readonly elementRef: ElementRef<HTMLElement>,
        @Optional() private readonly menuContainerComponent: MenuContainerComponent,
    ) {}

    public ngAfterContentInit(): void {
        this.initFocusManager();

        if (this.menuContainerComponent) {
            this.setIsOpen(true);
        }

        this.isOpen$
            .pipe(
                untilDestroyed(this),
                filter(isOpen => isOpen),
            )
            .subscribe(() => {
                setTimeout(() => this.focusKeyManager?.setFirstItemActive());
                this.open.next();
            });
    }

    public ngOnDestroy(): void {
        this.focusKeyManager?.destroy();
    }

    public setIsOpen(isOpen: boolean): void {
        this.isOpen$.next(isOpen);
    }

    public onKeydown(keyboardEvent: KeyboardEvent): void {
        switch (keyboardEvent.key) {
            // Ignore these are they are handled in the menu container component
            case 'Escape':
            case 'Tab':
                break;
            default:
                this.focusKeyManager.onKeydown(keyboardEvent);
        }
    }

    public registerMenuItem(menuItem: MenuListItemContentComponent): void {
        const menuItems = this.menuItems$.value;
        const newMenuItems = [...menuItems, menuItem]
            // The menu items could be registered out of order, so we need to sort them by their position
            // in the DOM to ensure the focus manager works intuitively
            .sort((a, b) =>
                a.elementRef.nativeElement.compareDocumentPosition(b.elementRef.nativeElement) &
                Node.DOCUMENT_POSITION_FOLLOWING
                    ? -1
                    : 1,
            );
        this.menuItems$.next(newMenuItems);
        this.initFocusManager();
        this.focusKeyManager.setFirstItemActive();
    }

    public deregisterMenuItem(menuItem: MenuListItemContentComponent): void {
        this.menuItems$.next(this.menuItems$.value.filter(item => item !== menuItem));
        this.initFocusManager();
    }

    private initFocusManager(): void {
        this.focusKeyManager = new FocusKeyManager<MenuListItemContentComponent>(this.menuItems$.value)
            .withWrap()
            .withVerticalOrientation(true);
    }
}
