import { UpStateDeclaration } from '../../common/models/router.model';
import { NoopComponent } from '../../components/noop/noop.component';
import { featureGuard } from '../../ui-router/feature-guard';
import { NavBasicComponent } from '../shared-vendor/components/nav-basic/nav-basic.component';

import { PostOfiPageComponent } from './components/post-ofi-page/post-ofi-page.component';

export const conversionFunnelPostOfiStates: UpStateDeclaration[] = [
    {
        name: 'conversion.post-ofi',
        url: '/post-ofi',
        views: {
            'navbar@vendor-app': {
                component: NavBasicComponent,
            },
            conversion: {
                component: PostOfiPageComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
        data: {
            featureGuard: featureGuard({ featureFlag: 'upsideMarketingPagesEnabled' }),
            meta: {
                title: 'How else can we help you?',
                description: `How else can we help you? Sell your home with an experienced local Upside agent. Total transparency, award-winning service and great results.`,
            },
        },
    },
];
