import { InjectionToken } from '@angular/core';
import x from '@glidejs/glide/dist/glide.modular.esm';

export interface Glide {
    readonly index: number;
    readonly settings: any;
    readonly type: string;
    readonly disabled: boolean;
    mount: (modules: any) => void;
    update: (settings: any) => void;
    destroy: () => void;
    on: (event: string | string[], callback: Function) => void;
    go: (pattern: string) => void;
    pause: () => void;
    play: (force: number) => void;
    disable: () => void;
    enable: () => void;
    isType: (type: string) => void;
}

export interface GlideOptions {
    animationDuration?: number;
    animationTimingFunc?: string;
    autoplay?: boolean | number;
    bound?: boolean;
    breakpoints?: {
        [breakpoint: number]: GlideOptions;
    };
    classes?: object;
    direction?: 'ltr' | 'rtl';
    dragThreshold?: boolean | number;
    focusAt?: number | 'center';
    gap?: number;
    hoverpause?: boolean;
    keyboard?: boolean;
    peek?: number | { before?: number; after?: number };
    perTouch?: boolean | number;
    perView?: number;
    rewind?: boolean;
    rewindDuration?: number;
    startAt?: number;
    swipeThreshold?: boolean | number;
    throttle?: number;
    touchAngle?: number;
    touchRatio?: number;
    type?: 'slide' | 'carousel';
}

export type GlideModule = () => object;

export type GlideFactory = (selector: string, options: any) => Glide;
export const glideFactory: GlideFactory = (selector: string, options: GlideOptions = {}) => new x(selector, options);
export const GlideFactoryToken = new InjectionToken<GlideFactory>('Glide');
