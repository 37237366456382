import { Action } from '@ngrx/store';

import { SuburbDetails } from '../../common/models/suburb-details.model';

export const GET_SUBURBS = '[Suburbs] Get Suburbs';
export const GET_SUBURBS_SUCCESS = '[Suburbs] Get Suburbs Success';
export const GET_SUBURBS_ERROR = '[Suburbs] Get Suburbs Error';

export class GetSuburbs implements Action {
    public readonly type = GET_SUBURBS;
}

export class GetSuburbsSuccess implements Action {
    public readonly type = GET_SUBURBS_SUCCESS;

    constructor(
        public payload: {
            profiled: SuburbDetails[];
            all: SuburbDetails[];
        },
    ) {}
}

export class GetSuburbsError implements Action {
    public readonly type = GET_SUBURBS_ERROR;
}

export type SuburbsAction = GetSuburbs | GetSuburbsError | GetSuburbsSuccess;
