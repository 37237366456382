import { createSelector } from '@ngrx/store';

import { AppState } from '../../../../store/apps-state.model';

import { GetStartedState } from './get-started.reducer';

const getStartedState = (state: AppState) => state.getStarted;
export const returnToSelector = createSelector(getStartedState, (state: GetStartedState) => state.returnTo);
export const loadingRequestCallbackSelector = createSelector(
    getStartedState,
    (state: GetStartedState) => state.loadingRequestCallback,
);
export const hasRequestedCallbackSelector = createSelector(
    getStartedState,
    (state: GetStartedState) => state.hasRequestedCallback,
);
export const hasStartedFlowSelector = createSelector(getStartedState, (state: GetStartedState) => state.hasStartedFlow);
export const errorRequestingCallbackSelector = createSelector(
    getStartedState,
    (state: GetStartedState) => state.errorRequestingCallback,
);
