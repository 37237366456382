import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MatchedLeadListingResponseDto } from '../../../common/models/dto/matched-leads/matched-lead-listing-response.dto';
import { MatchedLeadsQueryDto } from '../../../common/models/dto/matched-leads/matched-leads-query.dto';
import { MatchedLeadsResponseDto } from '../../../common/models/dto/matched-leads/matched-leads-response.dto';
import { PropertiesWithMatchedLeadsResponseDto } from '../../../common/models/dto/matched-leads/properties-with-matched-leads-response.dto';
import { PropertyWithMatchedLeadsSourceDto } from '../../../common/models/dto/matched-leads/property-with-matched-leads-source.dto';
import { PropertyWithMatchedLeadsTypeDto } from '../../../common/models/dto/matched-leads/property-with-matched-leads-type.dto';
import { stripNilValues } from '../../../common/utilities/data/data.utilities';

@Injectable()
export class MatchedLeadsResource {
    private readonly baseUrl = '/api/v2/matched-leads';

    constructor(private httpClient: HttpClient) {}

    public getInternalPropertiesWithMatchedLeads(
        agentId: string,
        type: PropertyWithMatchedLeadsTypeDto,
        source: PropertyWithMatchedLeadsSourceDto,
    ): Observable<PropertiesWithMatchedLeadsResponseDto> {
        return this.httpClient.get<PropertiesWithMatchedLeadsResponseDto>(
            `${this.baseUrl}/internal/agent/${agentId}/matched-leads/type/${type}/source/${source}`,
        );
    }

    public getExternalPropertiesWithMatchedLeads(
        agentId: string,
        type: PropertyWithMatchedLeadsTypeDto,
    ): Observable<PropertiesWithMatchedLeadsResponseDto> {
        return this.httpClient.get<PropertiesWithMatchedLeadsResponseDto>(
            `${this.baseUrl}/external/agent/${agentId}/matched-leads/type/${type}`,
        );
    }

    public getAgentInternalPropertyMatchedLeads(
        agentId: string,
        propertyId: string,
        filters?: MatchedLeadsQueryDto,
    ): Observable<MatchedLeadsResponseDto> {
        return this.httpClient.get<MatchedLeadsResponseDto>(
            `${this.baseUrl}/internal/agent/${agentId}/matched-leads/${propertyId}`,
            {
                params: stripNilValues(filters),
            },
        );
    }

    public getAgentExternalPropertyMatchedLeads(
        agentId: string,
        propertyId: string,
        filters?: MatchedLeadsQueryDto,
    ): Observable<MatchedLeadsResponseDto> {
        return this.httpClient.get<MatchedLeadsResponseDto>(
            `${this.baseUrl}/external/agent/${agentId}/matched-leads/${propertyId}`,
            {
                params: stripNilValues(filters),
            },
        );
    }

    public getExternalMatchedLeadListing(listingId: string): Observable<MatchedLeadListingResponseDto> {
        return this.httpClient.get<MatchedLeadListingResponseDto>(`${this.baseUrl}/${listingId}/external`);
    }

    public getInternalMatchedLeadListing(listingId: string): Observable<MatchedLeadListingResponseDto> {
        return this.httpClient.get<MatchedLeadListingResponseDto>(`${this.baseUrl}/${listingId}/internal`);
    }
}
