import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiSuccessResponse } from '../../../common/models/common.model';
import { EmptyDto } from '../../../common/models/dto/response/empty.dto';
import { BusinessUserSettingsDto } from '../../../common/models/dto/settings/business-user-settings.dto';
import { GetBusinessSettingsResponseDto } from '../../../common/models/dto/settings/get-business-settings-response.dto';
import { UpdateAgentSettingsDto } from '../../../common/models/dto/settings/update-agent-settings.dto';
import { UpdateBusinessSettingsRequestDto } from '../../../common/models/dto/settings/update-business-settings-request.dto';
import { UpdateBusinessUserSettingsDto } from '../../../common/models/dto/settings/update-business-user-settings.dto';
import { SettingsApi } from '../../../common/models/settings-api.model';

@Injectable()
export class SettingsResource {
    private readonly baseUrl = '/api/v2/settings';

    constructor(private httpClient: HttpClient) {}

    public getForAgent(agentId: string) {
        return this.httpClient.get<SettingsApi.Agent.GET.Response>(`${this.baseUrl}/agent/${agentId}`);
    }

    public setForAgent(agentId: string, settings: UpdateAgentSettingsDto): Observable<ApiSuccessResponse> {
        return this.httpClient.post<ApiSuccessResponse>(`${this.baseUrl}/agent/${agentId}`, { settings });
    }

    public getForAgency(agencyId: string) {
        return this.httpClient.get<SettingsApi.Agency.GET.Response>(`${this.baseUrl}/agency/${agencyId}`);
    }

    public setForAgency(agencyId: string, body: SettingsApi.Agency.POST.Request) {
        return this.httpClient.post<SettingsApi.Agency.POST.Response>(`${this.baseUrl}/agency/${agencyId}`, body);
    }

    public getForBusinessUser(userId: string): Observable<BusinessUserSettingsDto> {
        return this.httpClient.get<BusinessUserSettingsDto>(`${this.baseUrl}/business-user/${userId}`);
    }

    public setForBusinessUser(userId: string, body: UpdateBusinessUserSettingsDto): Observable<ApiSuccessResponse> {
        return this.httpClient.post<ApiSuccessResponse>(`${this.baseUrl}/business-user/${userId}`, body);
    }

    public getForBusiness(businessId: string): Observable<GetBusinessSettingsResponseDto> {
        return this.httpClient.get<GetBusinessSettingsResponseDto>(`${this.baseUrl}/business/${businessId}`);
    }

    public updateForBusiness(businessId: string, body: UpdateBusinessSettingsRequestDto): Observable<EmptyDto> {
        return this.httpClient.post<EmptyDto>(`${this.baseUrl}/business/${businessId}`, body);
    }
}
