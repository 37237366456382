import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ContactMergeApi } from '../../../common/models/contact-merge-api.model';

@Injectable()
export class ContactMergeResource {
    private readonly baseUrl = '/api/v2/user/merge';

    constructor(private httpClient: HttpClient) {}

    public merge(body: ContactMergeApi.Merge.POST.Request, dryRun = true) {
        return this.httpClient.post<ContactMergeApi.Merge.POST.Response>(`${this.baseUrl}`, body, {
            params: { dryRun: String(dryRun) },
        });
    }

    public validate(body: ContactMergeApi.Validate.POST.Request) {
        return this.httpClient.post<ContactMergeApi.Validate.POST.Response>(`${this.baseUrl}/validate`, body);
    }

    public info(body: ContactMergeApi.Info.POST.Request) {
        return this.httpClient.post<ContactMergeApi.Info.POST.Response>(`${this.baseUrl}/info`, body);
    }
}
