import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TransitionOptions } from '@uirouter/core';

import { DataEvent } from '../../directive/tracking.directive';
export type ButtonStyle =
    | 'plain'
    | 'plain-dark'
    | 'plain-light'
    | 'primary'
    | 'primary-dark'
    | 'primary-positive'
    | 'secondary'
    | 'secondary-dark'
    | 'secondary-light'
    | 'neutral';
type ButtonType = 'button' | 'submit';
export type ButtonSize = 'small' | 'regular' | 'large';
type ArrowPosition = 'left' | 'right';
enum ButtonMinWidth {
    Small = 'small',
    Large = 'large',
}

type ArrowDirection = 'left' | 'right';

/**
 * @Deprecated - Use <button> or <a> with the [up-button] directive instead
 * */
@Component({
    selector: 'up-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
    @Input() public arrowIcon: boolean;
    @Input() public arrowPosition: ArrowPosition = 'right';
    @Input() public arrowDirection: ArrowDirection = 'right';
    @Input() public disabled: boolean;
    @Input() public fullWidth: boolean;
    @Input() public href: string;
    // @deprecated: Use size input instead
    @Input() public large: boolean;
    @Input() public upSref: string;
    @Input() public upSrefParams: object;
    @Input() public upSrefOptions: TransitionOptions;
    @Input() public style: ButtonStyle = 'plain';
    @Input() public target: string;
    @Input() public rel: string;
    // HTML Button type attribute
    @Input() public type: ButtonType = 'button';
    @Input() public loading: boolean;
    @Input() public size: ButtonSize = 'regular';
    @Input() public minWidth: ButtonMinWidth;
    // This is a required input for buttons with uiSref targets. The inner button must also have the dataEvent
    // bound to it or the event gets dropped. This is not the case for regular buttons and anchor tags with hrefs
    @Input() public dataEvent: DataEvent;
    @Input() public download: boolean;
    @Output() public onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    @HostBinding('class.full-width')
    public get isFullWidth(): boolean {
        return this.fullWidth;
    }
    @HostBinding('class.min-width--small')
    public get isMinWidthSmall(): boolean {
        return this.minWidth === ButtonMinWidth.Small;
    }
    @HostBinding('class.min-width--large')
    public get isMinWidthLarge(): boolean {
        return this.minWidth === ButtonMinWidth.Large;
    }

    public click(mouseEvent: MouseEvent): void {
        this.onClick.emit(mouseEvent);
    }
}
