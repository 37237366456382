import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import { delay, startWith } from 'rxjs/operators';

import { SelectOptionComponent } from './select-option/select-option.component';

let selectCount = 0;

@UntilDestroy()
@Component({
    selector: 'up-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // Needed for Control Value Accessor
            // eslint-disable-next-line @angular-eslint/no-forward-ref
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
})
export class SelectComponent implements ControlValueAccessor, AfterContentInit, OnInit, AfterViewInit, OnDestroy {
    @Input() public multiple = false;
    @Input() public fullWidth: boolean;
    @Input() public size: 'small' | 'medium' = 'medium';
    @Input() public name = '';
    @Input() public autofocus = false;
    @Input() public error = false;
    @Input() public success = false;
    @Input() public label = '';
    @Input() public id: string;
    @Output() public blur: EventEmitter<void> = new EventEmitter();
    @Output() public focus: EventEmitter<void> = new EventEmitter();
    @ContentChildren(SelectOptionComponent) public optionsRef: QueryList<SelectOptionComponent>;
    @HostBinding('class.full-width') public fullWidthClass: boolean;

    public uid = '';
    public options: SelectOptionComponent[] = [];
    public isDisabled = false;
    public isFocused = false;
    public onChange: Function = () => {};
    public onTouched: Function = () => {};
    public value: any;
    private writeValue$ = new ReplaySubject<any>();

    constructor(private changeDetectorRef: ChangeDetectorRef) {
        selectCount++;

        this.uid = `select-${selectCount}`;
    }

    public ngOnInit(): void {
        this.fullWidthClass = this.fullWidth;

        this.uid = this.id || this.uid;

        // Note: a label is required. If you want to hide it,
        // use the hideLabel property
        if (!this.label) {
            throw new Error('Labels are required with inputs');
        }
    }

    public ngAfterContentInit(): void {
        this.optionsRef.changes
            .pipe(untilDestroyed(this), startWith(this.optionsRef.toArray()))
            .subscribe(options => (this.options = options));
    }

    public ngAfterViewInit(): void {
        // `writeValue$` is emitted before the component has fully rendered, meaning the `option` elements within the
        // `select` has not yet rendered, so if value is set before then, it won't set to the `select` element to show
        // the matching `option`. So starting the first subscription within `ngAfterViewInit` ensures that the element
        // has already fully rendered with the `option`s within the `select` element when value gets set for the first
        // time. `delay(0)` prevents `ExpressionChangedAfterItHasBeenCheckedError` error by deferring the setting of
        // value and triggering change detection to the next frame and not falling within Angular's rendering cycle
        // checks.
        this.writeValue$.pipe(delay(0)).subscribe(value => {
            this.value = value;
            this.changeDetectorRef.markForCheck();
        });
    }

    public ngOnDestroy(): void {
        this.writeValue$.complete();
    }

    public onInputFocus(): void {
        this.isFocused = true;
        this.focus.emit();
    }

    public onInputBlur(): void {
        this.isFocused = false;
        this.onTouched();
        this.blur.emit();
    }

    public onInput(event): void {
        const value = event.target.value;

        this.onChange(value);
    }

    // value accessor methods
    public writeValue(value): void {
        this.writeValue$.next(value);
    }

    public registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
