import { Component } from '@angular/core';
import moment from 'moment';

import { FeatureService } from '../../core/services/feature.service';

@Component({
    selector: 'up-auth-footer',
    templateUrl: 'auth-footer.component.html',
    styleUrls: ['auth-footer.component.scss'],
})
export class AuthFooterComponent {
    public currentYear = moment().year();

    public get thirdPartyPrivacyUrl(): string {
        return this.featureService.urlConfiguration.privacyPolicyPage;
    }

    public get thirdPartyTermsUrl(): string {
        return this.featureService.urlConfiguration.termsAndConditionsPage;
    }

    constructor(private featureService: FeatureService) {}
}
