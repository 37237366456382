import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Business } from '../models/business.model';
import { media } from '../models/media.model';
import { user } from '../models/user.model';
import { getPrimaryRole, hasMatchingRoles } from '../utilities/auth/auth.utilities';

export interface UserT {
    roles?: user.Role[];
    authenticated?: boolean;
    emailConfirmed?: boolean;
    created?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    id?: string;
    phoneVerified?: boolean;
    avatar?: media.File;
    business?: Business;
    canSeeSmsFeature?: boolean;
    buyerModuleEnabled?: boolean;
    businessSmsEnabled?: boolean;
    activePipeBulkEmailEnabled?: boolean;
    openDatabaseEnabled?: boolean;
    openBuyerDatabaseEnabled?: boolean;
}

// NOTE: This service is used in the UserInterceptor which seems to cause a issues where the store being injected into
// here is of a different instance from the rest of the app, so AVOID injecting/using the store within this service,
// this includes services that references the store, like a facade.
@Injectable()
export class UserService {
    public tempAuthData: UserT = { authenticated: null, roles: [] };
    private userAuthDetailsSource = new BehaviorSubject<UserT>(this.tempAuthData);
    public userAuthDetailsUpdated$ = this.userAuthDetailsSource.asObservable();
    public readonly currentUserName$: Observable<string>;
    public readonly currentUserPhoneNumber$: Observable<string>;
    public readonly currentUserId$: Observable<string>;
    public readonly currentUserRoles$: Observable<user.Role[]>;
    public readonly currentUserPrimaryRole$: Observable<user.Role | undefined>;
    public readonly currentUserBusiness$: Observable<Business | undefined>;
    public readonly currentUserBusinessId$: Observable<string | undefined>;
    public readonly currentUserHasBusiness$: Observable<boolean>;

    constructor() {
        this.currentUserName$ = this.userAuthDetailsSource.pipe(
            map(user => {
                const firstName = user?.firstName?.trim() || '';
                const lastName = user?.lastName?.trim() || '';

                return `${firstName} ${lastName}`.trim() || undefined;
            }),
        );
        this.currentUserId$ = this.userAuthDetailsSource.pipe(map(user => user?.id));
        this.currentUserRoles$ = this.userAuthDetailsSource.pipe(map(user => user?.roles));
        this.currentUserPrimaryRole$ = this.userAuthDetailsSource.pipe(
            map(user => user?.roles && getPrimaryRole(user.roles)),
        );
        this.currentUserBusiness$ = this.userAuthDetailsSource.pipe(map(user => user?.business));
        this.currentUserHasBusiness$ = this.currentUserBusiness$.pipe(map(business => !!business));
        this.currentUserPhoneNumber$ = this.userAuthDetailsSource.pipe(map(user => user?.phoneNumber));
        this.currentUserBusinessId$ = this.currentUserBusiness$.pipe(map(business => business?.id));
    }

    public setUserAuthDetails(user: UserT) {
        user.roles = !user.roles ? [] : user.roles;
        this.tempAuthData = { ...user };
        this.userAuthDetailsSource.next(user);
    }

    public clearCurrentUser(): void {
        this.setUserAuthDetails({ authenticated: false });
    }

    public isUserOfRole(roles: user.Role[], matchAll?: boolean): Observable<boolean> {
        return this.userAuthDetailsUpdated$.pipe(
            filter(authData => authData.authenticated),
            map(authData => authData.roles),
            map(userRoles => hasMatchingRoles(userRoles, roles, matchAll)),
        );
    }

    public isUserOfPrimaryRole(roles: user.Role | user.Role[]): Observable<boolean> {
        const providedRoles = Array.isArray(roles) ? roles : [roles];

        return this.currentUserPrimaryRole$.pipe(map(role => providedRoles.includes(role)));
    }
}
