import { Pipe, PipeTransform } from '@angular/core';

import { ThemeService } from '../../modules/core/services/theme.service';

/** @Deprecated - Use {@link LocalisedAssetPipe} instead. */
@Pipe({
    name: 'upThemeNameStringTemplate',
})
export class ThemeNameStringTemplatePipe implements PipeTransform {
    constructor(private themeService: ThemeService) {}

    public transform(value: string): string {
        return value.replace('#themeName#', <string>(<unknown>this.themeService.themeName));
    }
}
