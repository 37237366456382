import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

const BASE_URL = `/api/v2/event`;
const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

@Injectable()
class EventResource {
    constructor(private http: HttpClient) {}

    public trackEvent(body) {
        return this.http.put(`${BASE_URL}/websiteEvent/`, body, HEADERS_CONFIG);
    }

    public trackPageView(body) {
        return this.http.put(`${BASE_URL}/websitePageView/`, body, HEADERS_CONFIG);
    }

    public trackError(body) {
        return this.http.put(`${BASE_URL}/websiteError/`, body, HEADERS_CONFIG);
    }
}

export { EventResource };
