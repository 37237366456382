import { isPlatformServer } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnChanges,
    Output,
    PLATFORM_ID,
    QueryList,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { throttle } from 'lodash-es';

import { WindowRef } from '../../services/window.service';
import { InputStyle } from '../forms/input/input.component';
import { IconName } from '../icon/icon.component';

let autocompleteCount = 0;

export type AutoCompleteStyle = InputStyle | 'halo';

type DropdownItemsPosition = 'below' | 'above';

@Component({
    selector: 'up-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent implements OnChanges {
    @Input() public id: string;
    @Input() public valueWith: (v: any) => any = v => v;
    @Input() public displayWith: (v: any) => string = v => v;
    @Input() public filteredItems: any[] = [];
    @Input() public maxListLength = 15;
    @Input() public minFilterLength = 1;
    @Input() public placeholder = 'Enter search term';
    @Input() public showSearchButton = false;
    @Input() public icon: IconName;
    @Input() public iconColor: string;
    @Input() public style: AutoCompleteStyle = 'full';
    @Input() public loading: boolean;
    @Input() public disabled: boolean;
    @Input() public error: boolean;
    @Input() public label = 'Search';
    @Input() public value: any;
    @Input() public buttonIcon: IconName = 'magnifyingGlass';
    @Input() public clearable: boolean;
    @Input() public passiveLoading: boolean;

    @Output() public termChanged = new EventEmitter<string>();
    @Output() public valueSelected = new EventEmitter<any>();
    @Output() public focus = new EventEmitter<void>();
    @Output() public blur = new EventEmitter<void>();

    @ViewChild('dropdown') public dropdownRef: ElementRef;
    @ViewChild('autocompleteWrapper') public autocompleteWrapperRef: ElementRef;
    @ViewChildren('dropdownItem') public dropdownItemsRef: QueryList<ElementRef>;

    public term = '';
    public activeItemIndex = 0;
    public dropdownVisible: boolean;
    public isFocused: boolean;
    public dropdownItemsPosition: DropdownItemsPosition = 'below';
    public calculatingDropdownPosition: boolean;
    public showHint = false;
    private readonly autocompleteId = `nc-autocomplete-${++autocompleteCount}`;
    private readonly throttledUpdateDropdownItemsPosition;

    public get isListVisible(): boolean {
        return this.dropdownVisible && !this.calculatingDropdownPosition && !this.loading;
    }

    public get isListAbove(): boolean {
        return this.dropdownItemsPosition === 'above';
    }

    @HostBinding('id')
    public get componentId(): string {
        return this.id || this.autocompleteId;
    }

    public get inputId(): string {
        return `${this.componentId}-input`;
    }

    constructor(
        private renderer2: Renderer2,
        private hostElementRef: ElementRef,
        private windowRef: WindowRef,
        @Inject(PLATFORM_ID) private platformId: object,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        this.throttledUpdateDropdownItemsPosition = throttle(() => this.updateDropdownItemsPosition(), 300);
    }

    @HostListener('window:resize') public onWindowResize(): void {
        this.throttledUpdateDropdownItemsPosition();
    }

    @HostListener('window:scroll') public onWindowScroll(): void {
        this.throttledUpdateDropdownItemsPosition();
    }

    private updateDropdownItemsPosition(previousWasEmpty?: boolean): void {
        if (!this.dropdownRef || !this.autocompleteWrapperRef || isPlatformServer(this.platformId)) return;

        // We need to track whether the filtered list was previously empty so that we can continue hiding it (using
        // this 'calculatingDropdownPosition' property) until the dropdown position has been rendered inside the
        // setTimeout callback in the next frame. This prevents 'flickering' as the list value gets updated. We only
        // need to track this if the list was previously empty because if the list is already visible, it won't update
        // to its new position until after the setTimeout callback has triggered anyway, so there won't be a flicker.
        if (previousWasEmpty) {
            this.calculatingDropdownPosition = true;
        }

        // Force skip to the next render so that the position of the dropdown can be calculated after the list has
        // actually rendered. If we don't, we either won't have a dropdown to calculate from at all, or it will be stale.
        setTimeout(() => {
            const wrapperBoundingBox = (<HTMLElement>this.autocompleteWrapperRef.nativeElement).getBoundingClientRect();
            const dropdownBoundingBox = (<HTMLElement>this.dropdownRef.nativeElement).getBoundingClientRect();
            const viewportBottom = this.windowRef.nativeWindow.innerHeight;
            const potentialDropdownBottomPosition = wrapperBoundingBox.bottom + dropdownBoundingBox.height;
            const minimumTopSpaceToShowListAbove = 250;
            const hasEnoughTopSpace = wrapperBoundingBox.top > minimumTopSpaceToShowListAbove;

            this.dropdownItemsPosition =
                potentialDropdownBottomPosition > viewportBottom && hasEnoughTopSpace ? 'above' : 'below';
            this.calculatingDropdownPosition = false;

            this.changeDetectorRef.detectChanges();
        });
    }

    public ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges.filteredItems) {
            const previousWasEmpty = !(
                simpleChanges.filteredItems.previousValue && simpleChanges.filteredItems.previousValue.length
            );
            this.updateDropdownItemsPosition(previousWasEmpty);
            this.setDropdownVisibility(!!this.term);
        }

        if (simpleChanges['value']) {
            this.term = this.displayWith(simpleChanges['value'].currentValue) || '';
            // Unsure what is going on with CD here, but the view does not get updated when term is set until next CD
            // cycle unless using a setTimeout here...
            setTimeout(() => {
                this.changeDetectorRef.detectChanges();
            });
        }
    }

    public onTermChanged(term: string): void {
        this.term = term;
        this.activeItemIndex = 0;
        this.termChanged.emit(this.term);
        this.renderer2.setProperty(this.dropdownRef.nativeElement, 'scrollTop', 0);
        this.showHint = false;
        this.changeDetectorRef.detectChanges();
    }

    public onKeyDown(keyboardEvent: KeyboardEvent): void {
        // Don't do anything if there's no search term or filterable items
        if (!this.term?.length || !this.filteredItems?.length) {
            return;
        }
        switch (keyboardEvent.keyCode) {
            // Up arrow
            case 38:
                keyboardEvent.preventDefault();
                this.handleUpArrowPressed();
                this.updateDropdownScrollPosition();
                this.focusItem();
                break;
            // down arrow
            case 40:
                keyboardEvent.preventDefault();
                this.handleDownArrowPressed();
                this.updateDropdownScrollPosition();
                this.focusItem();
                break;
            // enter key
            case 13:
                keyboardEvent.preventDefault();
                this.onValueSelected(this.filteredItems?.[this.activeItemIndex]);
                break;
            // escape key
            case 27:
                this.resetInput();
                break;
            default:
                break;
        }
    }

    public onValueSelected(value?: string) {
        // emits either passed value or first match in filter list

        if (!this.filteredItems?.length || !this.isListVisible) {
            this.showHint = true;
            this.changeDetectorRef.detectChanges();

            return;
        }

        const selectedValue = value ? value : this.filteredItems[0];

        this.term = this.displayWith(selectedValue);
        this.valueSelected.emit(selectedValue);
        this.changeDetectorRef.detectChanges();
        this.setDropdownVisibility(false);
    }

    public onFocus(): void {
        this.isFocused = true;
        this.focus.emit();
        this.changeDetectorRef.detectChanges();
    }

    public onBlur(): void {
        this.isFocused = false;
        this.blur.emit();
        this.changeDetectorRef.detectChanges();
    }

    public resetInput(): void {
        this.onTermChanged('');
    }

    public setDropdownVisibility(visible: boolean): void {
        this.dropdownVisible = visible;
        this.changeDetectorRef.detectChanges();
    }

    // Emulate mouse based onBlur of the input to allow clicking on the dropdown elements
    @HostListener('document:click', ['$event'])
    public onClick(event: MouseEvent): void {
        const isClickedOnChildren = (<HTMLElement>this.hostElementRef.nativeElement).contains(
            <HTMLElement>event.target,
        );
        if (!isClickedOnChildren) {
            this.setDropdownVisibility(false);
        }
    }

    // Emulate keyboard based onBlur of the input but allow finer grain control of blur state that is separate
    // from mouse event
    @HostListener('keydown', ['$event'])
    public onKeydown(event: KeyboardEvent): void {
        if (event.key === 'Tab' || event.key === 'Escape') {
            this.isFocused = false;
            this.setDropdownVisibility(false);
        }
    }

    private focusItem(): void {
        if (this.filteredItems?.length) {
            const focusedItem = this.dropdownItemsRef.toArray()[this.activeItemIndex];

            if (focusedItem) {
                focusedItem.nativeElement.focus();
            }
        }
    }

    private updateDropdownScrollPosition() {
        // adjust scrollbox position if focused element is above or below the list bounds

        if (this.activeItemIndex === -1) return;

        const activeItemBoundingBox = this.dropdownItemsRef
            .toArray()
            [this.activeItemIndex].nativeElement.getBoundingClientRect();
        const dropdownBoundingBox = this.dropdownRef.nativeElement.getBoundingClientRect();

        if (activeItemBoundingBox.top < dropdownBoundingBox.top) {
            const newTop = activeItemBoundingBox.height * this.activeItemIndex;

            this.renderer2.setProperty(this.dropdownRef.nativeElement, 'scrollTop', newTop);
        }

        if (activeItemBoundingBox.bottom > dropdownBoundingBox.bottom) {
            let newBottom = 0;
            if (this.activeItemIndex === this.dropdownItemsRef.toArray().length - 1) {
                newBottom = activeItemBoundingBox.height * this.activeItemIndex;
            } else {
                newBottom =
                    activeItemBoundingBox.height * this.activeItemIndex -
                    dropdownBoundingBox.height +
                    activeItemBoundingBox.height;
            }

            this.renderer2.setProperty(this.dropdownRef.nativeElement, 'scrollTop', newBottom);
        }
    }

    private handleUpArrowPressed() {
        this.activeItemIndex--;

        if (this.activeItemIndex < 0) {
            this.activeItemIndex = this.filteredItems?.length - 1;
            this.changeDetectorRef.detectChanges();
        }
    }

    private handleDownArrowPressed() {
        this.activeItemIndex++;

        if (this.activeItemIndex >= this.filteredItems?.length) {
            this.activeItemIndex = 0;
            this.changeDetectorRef.detectChanges();
        }
    }
}
