import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'up-create-subscription-cta',
    templateUrl: 'create-subscription-cta.component.html',
    styleUrls: ['create-subscription-cta.component.scss'],
})
export class CreateSubscriptionCtaComponent {
    @Output() public createAlert = new EventEmitter<void>();

    public onCreateAlert(): void {
        this.createAlert.emit();
    }
}
