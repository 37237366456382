import { UIRouter } from '@uirouter/angular';

// Backwards compatibility with legacy dashboard url and route names
export const vendorDashboardLegacyUrlHandler = (router: UIRouter) => {
    router.transitionService.onBefore(
        { to: 'dashboard' },
        transition => {
            const paramsInUrl = router.urlService.search();
            // Gets id from url search param: ?id=xxxxxx
            const id = paramsInUrl['id'];
            // id from route declaration param (/my-properties/xxxxxx/...)
            const legacyUrlRoute = transition.params()['id'];

            switch (legacyUrlRoute) {
                // Fallback for legacy dashboard URL: /my-properties/tracker?id=xxxxxx
                case 'tracker':
                    return router.stateService.target('dashboard', { id }, { location: 'replace' });

                // Fallback for legacy dashboard URL: /my-properties/weekly-campaign-overview?id=xxxxxx&reportId=yyyyyy
                case 'weekly-campaign-overview':
                    return router.stateService.target(
                        'dashboard.weekly-campaign-overview',
                        { id, reportId: paramsInUrl['reportId'] },
                        { location: 'replace' },
                    );

                // Fallback for legacy dashboard URL: /my-properties/appraisal?id=xxxxxx
                case 'appraisal':
                    return router.stateService.target('dashboard.appraisal.report', { id }, { location: 'replace' });

                default:
                    return true;
            }
            // Priority 1 is required to ensure it triggers before the authentication redirect
        },
        { priority: 1 },
    );

    // Fallback for legacy dashboard route name
    router.transitionService.onError({ to: 'dashboard.**' }, transition => {
        const targetState = transition.targetState();
        const nameOfFailedRoute = targetState.identifier();
        const id = targetState.params()['id'];
        // Is legacy phase route name?
        if (nameOfFailedRoute !== 'dashboard.phase') return;
        router.stateService.go('dashboard', { id }, { location: 'replace' });
    });
};
