import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Host,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    TemplateRef,
} from '@angular/core';

import { DotAlignment, DotSize, LineStyle, TimelineComponent } from '../timeline/timeline.component';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[up-timeline-item], li[nc-timeline-item]',
    templateUrl: 'timeline-item.component.html',
    styleUrls: ['timeline-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineItemComponent implements OnChanges, OnInit {
    @Input() public dotFilled: boolean;
    @Input() public dotColor: string;
    @Input() public dotAlignment: 'top' | 'center';
    @Input() public dotSize: DotSize;
    @Input() public lineColor: string;
    @Input() public lineStyle: LineStyle;
    @Input() public contentPadding: boolean;
    @Input() public dotTemplate?: TemplateRef<any>;
    public _dotFilled: boolean;
    public _dotColor: string;
    public _lineColor: string;
    public _lineStyle: LineStyle;
    public _dotAlignment: DotAlignment;
    public _dotSize: DotSize;
    public _contentPadding: boolean;

    @HostBinding('class.is-show-line-for-single-item')
    public get isShowLineForSingleItem(): boolean {
        return this.parent.showLineForSingleItem;
    }

    @HostBinding('class.is-dot-size-small')
    public get isDotSizeSmall(): boolean {
        return this._dotSize === 'small';
    }

    constructor(@Host() private parent: TimelineComponent, private changeDetectorRef: ChangeDetectorRef) {
        this.parent._changeNotifier.subscribe(() => this.applyConfig());
    }

    public ngOnInit(): void {
        this.applyConfig();
    }

    public ngOnChanges(): void {
        this.applyConfig();
    }

    private applyConfig(): void {
        this._dotFilled = this.dotFilled || this.parent.dotFilled;
        this._dotColor = this.dotColor || this.parent.dotColor;
        this._lineColor = this.lineColor || this.parent.lineColor;
        this._lineStyle = this.lineStyle || this.parent.lineStyle;
        this._dotAlignment = this.dotAlignment || this.parent.dotAlignment;
        this._contentPadding = this.contentPadding || this.parent.contentPadding;
        this._dotSize = this.dotSize || this.parent.dotSize;
        this.changeDetectorRef.detectChanges();
    }
}
