import { Injectable } from '@angular/core';

import { LocalStorageService } from '../../../../common/services/local-storage.service';
import { CheckInUser } from '../../models/check-in-user.model';

export interface StoredUser {
    user: CheckInUser;
    date: string;
}

@Injectable()
export class SavedSessionService {
    private readonly localStorageKey = 'checked-in-user';

    constructor(private localStorageService: LocalStorageService) {}

    public storeSession(user: CheckInUser): void {
        this.localStorageService.setItemAsJSON(this.localStorageKey, {
            user,
            date: new Date(),
        });
    }

    public retrieveSession(): StoredUser {
        return this.localStorageService.getItemAsJSON<StoredUser>(this.localStorageKey);
    }

    public clearSession(): void {
        this.localStorageService.removeItem(this.localStorageKey);
    }
}
