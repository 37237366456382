<up-conversion-step [step]="step" [heading]="'What is your phone number?'">
    <ng-container helpText>
        Lastly, we just need your phone number so we can contact you to book the appointment.
    </ng-container>
    <ng-container body>
        <form (submit)="next()">
            <div class="c-form-group u-margin-bottom--xl">
                <up-input
                    [id]="'phone-number-input'"
                    [formControl]="phoneNumberInput"
                    [type]="'text'"
                    [size]="'large'"
                    [internalLabel]="true"
                    [error]="formSubmittedAttempt && phoneNumberInput.invalid"
                    [label]="'Phone Number'"
                >
                </up-input>
                <up-help-text [type]="'error'" *ngIf="formSubmittedAttempt && phoneNumberInput.invalid">
                    Please enter a valid phone number
                </up-help-text>
            </div>
            <up-conversion-step-cta-wrapper>
                <up-button id="submit-phone-number-button" [type]="'submit'" [style]="'primary'" [arrowIcon]="true">
                    Next
                </up-button>
            </up-conversion-step-cta-wrapper>
        </form>
    </ng-container>
</up-conversion-step>
