import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount, startWith } from 'rxjs/operators';

type Style = 'light' | 'dark';

let componentInstanceCount = 0;

@Component({
    selector: 'nc-property-attributes, up-property-attributes',
    templateUrl: 'property-attributes.component.html',
    styleUrls: ['property-attributes.component.scss'],
})
export class PropertyAttributesComponent implements OnInit {
    @Input() public bedrooms: number;
    @Input() public bathrooms: number;
    @Input() public parking: number;
    // TODO: rename to reflect that the unit of measurement is expected in square meters
    @Input() public landSize: number;
    @Input() public formattedLandSize: string;
    @Input() public propertyType: string;
    @Input() public style: Style = 'dark';
    @Input() public size: 'regular' | 'small' | 'x-small';
    @Input() public labelStyle: 'icon' | 'text' = 'icon';
    public largerScreen$: Observable<boolean>;
    public readonly propertyAttributeAriaDescribeById = `nc-property-attributes-description-${++componentInstanceCount}`;

    constructor(private breakpointObserver: BreakpointObserver) {}

    public ngOnInit(): void {
        const breakpoint = '(min-width: 370px)';
        this.largerScreen$ = this.breakpointObserver.observe([breakpoint]).pipe(
            map(({ matches }) => matches),
            startWith(this.breakpointObserver.isMatched([breakpoint])),
            publishReplay(1),
            refCount(),
        );
    }

    // LandSize is only shown if it is greater than 0, as usually if it is returned as 0 it is
    //  equivalent to being unknown. The other attributes can actually be 0, so they are still
    // displayed if they are 0.
    public get showLandSize(): boolean {
        return !!this.formattedLandSize || (typeof this.landSize !== 'undefined' && this.landSize > 0);
    }
}
