import { Component, Input } from '@angular/core';

import { ThemeService } from '../../../modules/core/services/theme.service';

/** @Deprecated - Use hr[up-hr] instead */
@Component({
    selector: 'up-hr',
    template: `
        <hr class="c-horizontal-rule" [style.border-color]="color" />
    `,
    styleUrls: ['hr.component.scss'],
})
export class HrComponent {
    @Input() public color = this.themeService.colors.neutral4;

    constructor(private themeService: ThemeService) {}
}
