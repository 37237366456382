<div class="background">
    <div class="container">
        <div class="u-margin-bottom--lg logo-container">
            <nc-brand-logo class="logo"></nc-brand-logo>
        </div>
        <div>
            <h2 class="u-text-heading--section u-margin-bottom--md">
                Welcome back <br />
                {{ fullName }}
            </h2>
            <div>
                <div>
                    {{ address }}
                </div>
                <div>
                    {{ date | ncDateFormat: 'dddd, Do MMMM YYYY' }}
                </div>
            </div>
        </div>
        <hr class="u-margin-vertical--lg" up-hr />
        <div>
            <div class="u-text-body--sm u-text-weight--semi-bold u-margin-bottom--sm u-text-color--brand-primary">
                Would you like to check-in as:
            </div>
            <div class="button-group">
                <button nc-button [style]="'primary'" [size]="'large'" (click)="onCurrentUserClick()">
                    {{ fullName }}
                </button>
                <button nc-button [style]="'secondary'" [size]="'large'" (click)="onNewUserClick()">
                    Someone else
                </button>
            </div>
        </div>
    </div>
</div>
