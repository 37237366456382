<section>
    <h3 *ngIf="heading; else customHeading" class="heading">
        {{ heading }}
    </h3>

    <ng-template #customHeading>
        <ng-container *ngTemplateOutlet="headingRef"></ng-container>
    </ng-template>

    <ul class="menu-group-list">
        <ng-content></ng-content>
    </ul>
</section>
