import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import contrast from 'contrast';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { Color } from '../../../../../modules/core/services/theme.service';
import { replay } from '../../../../../operators/replay/replay.operator';
import { WindowRef } from '../../../../services/window.service';

export type Size = 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
export type Style = 'opaque' | 'faded';

@Component({
    selector: 'nc-icon-container',
    templateUrl: 'icon-container.component.html',
    styleUrls: ['icon-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconContainerComponent implements OnInit {
    @Input() public size: Size = 'medium';
    @Input('size@xs') public sizeAtXs?: Size;
    @Input() public style: Style = 'faded';
    @Input() public shape: 'circle' | 'rounded-square' = 'circle';
    @HostBinding('class.is-show-border')
    @Input()
    public isBordered = false;
    public readonly color$ = new BehaviorSubject<Color | string | undefined>(undefined);
    public autoIconWrapperColor$: Observable<Color>;

    @Input()
    public set color(color: string) {
        this.color$.next(color);
    }

    @HostBinding('class.is-size-small-at-xs')
    public get sizeSmallAtXs(): boolean {
        return this.sizeAtXs === 'small';
    }

    @HostBinding('class.is-size-medium-at-xs')
    public get sizeMediumAtXs(): boolean {
        return this.sizeAtXs === 'medium';
    }

    @HostBinding('class.is-size-large-at-xs')
    public get sizeLargeAtXs(): boolean {
        return this.sizeAtXs === 'large';
    }

    @HostBinding('class.is-size-x-large-at-xs')
    public get sizeXLargeAtXs(): boolean {
        return this.sizeAtXs === 'x-large';
    }

    constructor(
        @Inject(PLATFORM_ID) private readonly platformId: Object,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly windowRef: WindowRef,
    ) {}

    public ngOnInit(): void {
        this.autoIconWrapperColor$ = this.color$.pipe(
            map(color => {
                if (this.style !== 'opaque' || !isPlatformBrowser(this.platformId) || !color) return undefined;

                let parsedColor = color;

                if (color.startsWith('var(--')) {
                    const variable = color.slice(4, -1);

                    parsedColor = this.windowRef.nativeWindow
                        .getComputedStyle(this.document.documentElement)
                        .getPropertyValue(variable);

                    if (!parsedColor) return undefined;
                }

                return contrast(parsedColor) === 'dark' ? ('textInverse' as const) : ('text' as const);
            }),
            filter(color => !!color),
            distinctUntilChanged(),
            replay(),
        );
    }
}
