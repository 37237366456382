import { NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';

import { NavCommonModule } from './nav-common.module';
import { NavMinimalComponent } from './nav-minimal/nav-minimal.component';

/**
 * @Deprecated
 * Build new navs with NavigationModule instead.
 */
@NgModule({
    imports: [NavCommonModule, SharedModule],
    exports: [NavMinimalComponent],
    declarations: [NavMinimalComponent],
})
export class NavModule {}
