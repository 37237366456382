<nc-card class="details">
    <ng-container *ngIf="loading === false; else loadingPlaceholder">
        <h3 class="u-text-heading--rg">{{ title }}</h3>
        <up-property-attributes
            class="u-margin-bottom--lg"
            [bedrooms]="bedrooms"
            [bathrooms]="bathrooms"
            [parking]="parking"
            [landSize]="landSize"
            [labelStyle]="'text'"
            [size]="'small'"
        >
        </up-property-attributes>
        <div class="copy-body u-margin-bottom--xl"> {{ body | upTrim }} </div>
        <up-listing-key-details class="u-margin-bottom--xl" [features]="features"></up-listing-key-details>
        <up-listing-key-features class="u-margin-bottom--xl" [features]="features"></up-listing-key-features>
        <div class="details-button-group">
            <button nc-button [style]="'primary'" (click)="onOpenGallery()"> Photos </button>
            <button *ngIf="hasFloorPlan" nc-button [style]="'secondary'" (click)="onOpenFloorPlan()">
                Floorplan
            </button>
        </div>
    </ng-container>
</nc-card>

<ng-template #loadingPlaceholder>
    <up-placeholder class="u-margin-bottom--md" [width]="'400px'" [height]="'18px'"></up-placeholder>
    <up-placeholder class="u-margin-bottom--lg" [width]="'200px'" [height]="'18px'"></up-placeholder>

    <up-placeholder class="u-margin-bottom--xs" [width]="'100%'" [height]="'14px'"></up-placeholder>
    <up-placeholder class="u-margin-bottom--xs" [width]="'100%'" [height]="'14px'"></up-placeholder>
    <up-placeholder class="u-margin-bottom--xs" [width]="'100%'" [height]="'14px'"></up-placeholder>
    <up-placeholder class="u-margin-bottom--xs" [width]="'100%'" [height]="'14px'"></up-placeholder>
    <up-placeholder class="u-margin-bottom--xs" [width]="'100%'" [height]="'14px'"></up-placeholder>
    <up-placeholder class="u-margin-bottom--xs" [width]="'100%'" [height]="'14px'"></up-placeholder>
    <up-placeholder class="u-margin-bottom--xs" [width]="'100%'" [height]="'14px'"></up-placeholder>
    <up-placeholder class="u-margin-bottom--lg" [width]="'100%'" [height]="'14px'"></up-placeholder>

    <up-placeholder class="u-margin-bottom--xs" [width]="'100%'" [height]="'14px'"></up-placeholder>
    <up-placeholder class="u-margin-bottom--xs" [width]="'100%'" [height]="'14px'"></up-placeholder>
    <up-placeholder class="u-margin-bottom--xs" [width]="'100%'" [height]="'14px'"></up-placeholder>
    <up-placeholder class="u-margin-bottom--xs" [width]="'100%'" [height]="'14px'"></up-placeholder>
    <up-placeholder class="u-margin-bottom--xs" [width]="'100%'" [height]="'14px'"></up-placeholder>
    <up-placeholder class="u-margin-bottom--xs" [width]="'100%'" [height]="'14px'"></up-placeholder>
    <up-placeholder class="u-margin-bottom--xs" [width]="'100%'" [height]="'14px'"></up-placeholder>
    <up-placeholder class="u-margin-bottom--xs" [width]="'100%'" [height]="'14px'"></up-placeholder>
</ng-template>
