import { Ng2StateDeclaration } from '@uirouter/angular';

// Keep both future-states.aot.ts and future-states.jit.ts in sync with their respective loadChildren declaration
export const futureStates: Ng2StateDeclaration[] = [
    {
        name: 'my-properties.**',
        url: '/my-properties',
        loadChildren: () =>
            import(/*webpackChunkName: 'dashboard-module'*/ './modules/dashboard/dashboard.module').then(
                module => module.DashboardModule,
            ),
    },
    {
        name: 'dashboard.**',
        url: '/my-properties',
        loadChildren: () =>
            import(/*webpackChunkName: 'dashboard-module'*/ './modules/dashboard/dashboard.module').then(
                module => module.DashboardModule,
            ),
    },
    {
        name: 'loggedOutDashboard.**',
        url: '/property',
        loadChildren: () =>
            import(/*webpackChunkName: 'dashboard-module'*/ './modules/dashboard/dashboard.module').then(
                module => module.DashboardModule,
            ),
    },
    {
        name: 'internal.**',
        url: '/internal',
        loadChildren: () =>
            import(/*webpackChunkName: 'internal-app-module'*/ './modules/internal-app/internal-app.module').then(
                module => module.InternalAppModule,
            ),
    },
];
