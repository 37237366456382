import { first, map } from 'rxjs/operators';

import { UpStateDeclaration } from '../../common/models/router.model';
import { user } from '../../common/models/user.model';
import { UserService } from '../../common/services/user.service';

import { DemoSplashPageComponent } from './components/demo-splash-page/demo-splash-page.component';

export const demonstrationStates: UpStateDeclaration[] = [
    {
        name: 'demonstration',
        url: '/demo',
        component: DemoSplashPageComponent,
        // Todo: implement the restrict route guard here, which is currently only implemented for internal pages. For
        //  now the route restriction is handled manually here.
        onEnter: transition => {
            const injector = transition.injector();
            const userService = injector.get<UserService>(UserService);

            return userService.userAuthDetailsUpdated$
                .pipe(
                    first(),
                    map(({ roles, authenticated }) =>
                        authenticated && roles?.includes(user.Role.Demo)
                            ? true
                            : transition.router.stateService.target('404', {}, { location: false }),
                    ),
                )
                .toPromise();
        },
    },
];
