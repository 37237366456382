import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { collapseAnimation, CollapseAnimationState } from '../../../../common/animations/collapse.animation';

let inputCount = 0;

export type CollapserPanelState = 'collapsed' | 'expanded';

@Component({
    selector: 'nc-collapser-panel, up-collapser-panel',
    templateUrl: 'collapser-panel.component.html',
    styleUrls: ['collapser-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [collapseAnimation],
})
export class CollapserPanelComponent implements OnInit {
    @Output() public stateChange = new EventEmitter<CollapserPanelState>();
    @Output() public triggerClick = new EventEmitter<void>();
    // Todo: make this just 'state' and allow it to dynamically update the component at any time rather than only
    //  on initialisation.
    @Input() public initialState: CollapserPanelState = 'collapsed';
    @Input() public disabled: boolean;
    @HostBinding('class.is-header-after-content')
    @Input()
    public headerAfterContent = false;
    @Input() public manuallyHandleCollapseState = false;
    public uid: string;
    public state: CollapserPanelState;
    public expanderInteractable: boolean;
    public animationState: CollapseAnimationState;
    @HostBinding('class.is-animating')
    private isAnimating = false;

    public get isOpen(): boolean {
        return this.state === 'expanded';
    }

    constructor(private changeDetectorRef: ChangeDetectorRef) {
        inputCount++;
        this.uid = `panel-${inputCount}`;
    }

    public ngOnInit(): void {
        this.animationState = this.initialState;
        this.state = this.initialState;
        this.expanderInteractable = this.initialState === 'expanded';
        this.changeDetectorRef.detectChanges();
    }

    public onTriggerClick(): void {
        this.triggerClick.emit();

        if (this.manuallyHandleCollapseState) return;

        this.toggleCollapseState();
    }

    public closePanel(noTransition?: boolean, emitEvent = true): void {
        this.state = 'collapsed';
        this.animationState = noTransition ? 'collapsed-nt' : 'collapsed';
        if (emitEvent) {
            this.stateChange.emit('collapsed');
        }
        this.changeDetectorRef.detectChanges();
    }

    public openPanel(noTransition?: boolean, emitEvent = true): void {
        this.state = 'expanded';
        this.animationState = noTransition ? 'expanded-nt' : 'expanded';
        if (emitEvent) {
            this.stateChange.emit('expanded');
        }
        this.expanderInteractable = true;
        this.changeDetectorRef.detectChanges();
    }

    public onCollapseAnimDone(): void {
        this.isAnimating = false;
        this.expanderInteractable = this.state === 'expanded';
    }

    public onCollapseAnimStart(): void {
        this.isAnimating = true;
    }

    private toggleCollapseState(): void {
        this.state === 'expanded' ? this.closePanel() : this.openPanel();
        this.changeDetectorRef.detectChanges();
    }
}
