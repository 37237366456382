import { Component, Input } from '@angular/core';

@Component({
    selector: 'up-conversion-step-cta-wrapper',
    templateUrl: 'conversion-step-cta-wrapper.component.html',
    styleUrls: ['conversion-step-cta-wrapper.component.scss'],
})
export class ConversionStepCtaWrapperComponent {
    @Input('alignment@mn') public alignmentAtMn: 'justified' | 'centered' = 'justified';
}
