import { NgModule } from '@angular/core';

import { IconModule } from '../../common/components/icon/icon.module';

import { DisclosureIndicatorComponent } from './components/disclosure-indicator/disclosure-indicator.component';

@NgModule({
    imports: [IconModule],
    exports: [DisclosureIndicatorComponent],
    declarations: [DisclosureIndicatorComponent],
})
export class DisclosureIndicatorModule {}
