import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { bootstrap } from '../../../common/models/bootstrap.model';

const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

const BASE_URL = '/api/v2';

@Injectable()
export class BootstrapResource {
    constructor(private http: HttpClient) {}

    public products(): Observable<bootstrap.api.products.get.Response> {
        return this.http.get<bootstrap.api.products.get.Response>(`${BASE_URL}/bootstrap/products`, HEADERS_CONFIG);
    }

    public suppliers(): Observable<bootstrap.api.suppliers.get.Response> {
        return this.http.get<bootstrap.api.suppliers.get.Response>(`${BASE_URL}/bootstrap/suppliers`, HEADERS_CONFIG);
    }

    public surroundingSuburbs(): Observable<bootstrap.api.suppliers.get.Response> {
        return this.http.get<bootstrap.api.suppliers.get.Response>(
            `${BASE_URL}/bootstrap/suburbs-surrounding`,
            HEADERS_CONFIG,
        );
    }

    public vendor(vendor: bootstrap.api.vendor.post.Request): Observable<bootstrap.api.vendor.post.Response> {
        return this.http.post<bootstrap.api.vendor.post.Response>(
            `${BASE_URL}/bootstrap/vendor`,
            vendor,
            HEADERS_CONFIG,
        );
    }

    public suburbs(suburbs: bootstrap.api.suburbs.post.Request): Observable<void> {
        return this.http.post<void>(`${BASE_URL}/bootstrap/suburbs`, suburbs, {
            ...HEADERS_CONFIG,
            headers: new HttpHeaders().set('Content-Type', 'text/csv'),
        });
    }

    public demographics(demographics: bootstrap.api.demographics.post.Request): Observable<void> {
        return this.http.post<void>(`${BASE_URL}/bootstrap/demographics`, demographics, {
            ...HEADERS_CONFIG,
            headers: new HttpHeaders().set('Content-Type', 'text/csv'),
        });
    }
}
