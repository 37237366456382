import {
    AfterContentInit,
    Component,
    ContentChildren,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    Output,
    QueryList,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { merge, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import { RadioComponent } from '../radio/radio.component';

@Component({
    selector: 'up-radio-group',
    templateUrl: 'radio-group.component.html',
    styleUrls: ['radio-group.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // Required as part of NG_VALUE_ACCESSOR
            // eslint-disable-next-line @angular-eslint/no-forward-ref
            useExisting: forwardRef(() => RadioGroupComponent),
            multi: true,
        },
    ],
})
export class RadioGroupComponent implements ControlValueAccessor, AfterContentInit, OnDestroy {
    @Input() public compareValueFn: (a?: unknown, b?: unknown) => boolean;
    @Input() public disabled: boolean;
    public get value(): any {
        return this._value;
    }
    @Input() public set value(newValue: any) {
        if (this._value !== newValue) {
            this._value = newValue;
            this.updateSelectedRadioFromValue();
        }
    }
    public get name(): string {
        return this._name;
    }
    @Input() public set name(value: string) {
        this._name = value;
        this.updateRadioComponentNames();
    }
    @Output() public touch = new EventEmitter<void>();
    public controlValueAccessorChangeFn: (value: any) => void = () => {};
    public controlValueAccessorOnTouchedFn: () => any = () => {};
    @ContentChildren(
        // Required for accessing child RadioComponent
        // eslint-disable-next-line @angular-eslint/no-forward-ref
        forwardRef(() => RadioComponent),
        { descendants: true },
    )
    public radioComponents: QueryList<RadioComponent>;
    private _name: string;
    private _value: any;
    private selectedRadio: RadioComponent;
    private destroy$ = new Subject<void>();

    public ngAfterContentInit() {
        this.setupRadioValueWatch();
        this.updateRadioComponentNames();
        this.updateSelectedRadioFromValue();
    }

    // Implementation of ControlValueAccessor
    public writeValue(value: any) {
        this.value = value;
        this.updateSelectedRadioFromValue();
    }

    // Implementation of ControlValueAccessor
    public registerOnChange(fn: (value: any) => void) {
        this.controlValueAccessorChangeFn = fn;
    }

    // Implementation of ControlValueAccessor
    public registerOnTouched(fn: any) {
        this.controlValueAccessorOnTouchedFn = fn;
    }

    // Implementation of ControlValueAccessor
    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
        this.updateRadioDisabledState();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private onTouch() {
        if (this.controlValueAccessorOnTouchedFn) {
            this.controlValueAccessorOnTouchedFn();
            this.touch.emit();
        }
    }

    private setupRadioValueWatch(): void {
        const radioComponentsChanges$ = this.radioComponents.changes.pipe(takeUntil(this.destroy$));

        radioComponentsChanges$
            .pipe(
                tap(() => this.updateSelectedRadioFromValue()),
                // Changes does not emit the initial value by the time this is called in ngAfterContentInit so
                // explicitly get it to emit the current value of radioComponents
                startWith(this.radioComponents),
                map((radios: QueryList<RadioComponent>) => radios.map(radio => radio._onInputChange)),
                switchMap(radioChanges => merge(...radioChanges)),
            )
            .subscribe(value => {
                this.controlValueAccessorChangeFn(value);
                this.onTouch();
                this.radioComponents.forEach(radio => {
                    if (radio.value !== value) {
                        radio.checked = false;
                    }

                    radio.markForCheck();
                });
            });

        this.updateRadioDisabledState();
        radioComponentsChanges$.subscribe(() => this.updateRadioDisabledState());
    }

    private updateRadioDisabledState(): void {
        this.radioComponents?.forEach(radioComponent => {
            radioComponent.disabled = this.disabled;
            radioComponent.markForCheck();
        });
    }

    private updateRadioComponentNames(): void {
        if (this.radioComponents) {
            this.radioComponents.forEach(radioComponent => {
                radioComponent.name = this.name;
                radioComponent.markForCheck();
            });
        }
    }

    private updateSelectedRadioFromValue(): void {
        const isAlreadySelected = (this.selectedRadio && this.selectedRadio.value) === this.value;
        if (this.radioComponents && !isAlreadySelected) {
            this.selectedRadio = undefined;
            this.radioComponents.forEach(radioComponent => {
                // Fixes ExpressionChangedAfterItHasBeenCheckedError due to this method being called in
                // ngAfterContentInit and it causing change detection to run again, however it's required to be called
                // within the hook as that's the only time that the child radioComponents will be available
                setTimeout(() => {
                    const radioIsCurrentValue = this.compareValueFn
                        ? this.compareValueFn(this.value, radioComponent.value)
                        : this.value === radioComponent.value;
                    radioComponent.setValue(radioIsCurrentValue);
                    if (radioIsCurrentValue) {
                        this.selectedRadio = radioComponent;
                    }
                    radioComponent.markForCheck();
                });
            });
        }
    }
}
