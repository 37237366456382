import { HttpErrorResponse } from '@angular/common/http';

import { Property } from '../../common/models/property.model';
import { reminder } from '../../common/models/reminder.model';
import { Store } from '../../common/models/store.model';
import { task } from '../../common/models/task.model';
import { todo } from '../../common/models/todo.model';

import {
    InternalPropertyActionsUnion,
    InternalPropertyActionType,
    updateHideFullAddress,
    updateHideFullAddressError,
    updateHideFullAddressSuccess,
    updateHideSoldPrice,
    updateHideSoldPriceError,
    updateHideSoldPriceSuccess,
    updateHideUnderOfferStatus,
    updateHideUnderOfferStatusError,
    updateHideUnderOfferStatusSuccess,
    updateJustListedSmsNotificationEnabled,
    updateJustListedSmsNotificationEnabledError,
    updateJustListedSmsNotificationEnabledSuccess,
    updateJustListedEmailNotificationEnabled,
    updateJustListedEmailNotificationEnabledError,
    updateJustListedEmailNotificationEnabledSuccess,
    updateJustSoldNotificationEnabled,
    updateJustSoldNotificationEnabledError,
    updateJustSoldNotificationEnabledSuccess,
    updateVendorSources,
    updateVendorSourcesError,
    updateVendorSourcesSuccess,
    remove,
    removeSuccess,
    removeError,
    unarchive,
    unarchiveSuccess,
    unarchiveError,
    updateAppraisalQuestionnaire,
    updateAppraisalQuestionnaireSuccess,
    updateAppraisalQuestionnaireError,
    updateVendorPortalEnabled,
    updateVendorPortalEnabledSuccess,
    updateVendorPortalEnabledError,
    updateAttributes,
    updateAttributesSuccess,
    updateAttributesError,
    updateIsContractOfSaleAlwaysVisible,
    updateIsContractOfSaleAlwaysVisibleSuccess,
    updateIsContractOfSaleAlwaysVisibleError,
} from './internal-property.actions';

interface Todo {
    reminders: reminder.SimpleReminder[];
    meetings: todo.Appointment[];
    tasks: task.TaskMinimal[];
}

export interface InternalPropertyState {
    loadingProperty: boolean;
    loadingPropertyError?: HttpErrorResponse;
    updatingProperty: boolean;
    updatingPropertyError?: HttpErrorResponse;
    property?: Property;
    loadingTodo: boolean;
    loadingTodoError?: HttpErrorResponse;
    // reminders, meetings and tasks all fall under "todo" for the property
    todo: Todo;
    updatingAcquisitionData: boolean;
    updatingAcquisitionDataError?: boolean;
    updatingVendorSources?: boolean;
    updatingVendorSourcesError?: boolean;
    updatingListingExpectationData: boolean;
    updatingListingExpectationDataError?: boolean;
    updatingProspectData: boolean;
    updatingProspectDataError?: HttpErrorResponse;
    loadingSetPhaseInvalid: boolean;
    loadingSetPhaseInvalidError?: boolean;
    loadingSetPhaseLostAndSold: boolean;
    loadingSetPhaseLostAndSoldError?: boolean;
    deletingSalesAssistant: boolean;
    deletingSalesAssistantError?: HttpErrorResponse;
    loadingActivity: boolean;
    loadingActivityError?: HttpErrorResponse;
    updatingPricingData: boolean;
    updatingPricingDataError?: HttpErrorResponse;
    isUpdatingHideFullAddress?: boolean;
    isUpdatingHideUnderOfferStatus?: boolean;
    isUpdatingHideSoldPrice?: boolean;
    isUpdatingJustSoldNotificationEnabled?: boolean;
    isUpdatingJustListedSmsNotificationEnabled?: boolean;
    isUpdatingJustListedEmailNotificationEnabled?: boolean;
    isUpdatingVendorPortalEnabled?: boolean;
    isUpdatingIsContractOfSaleAlwaysVisible?: boolean;
    removeStatus: Store.Status;
    unarchiveStatus: Store.Status;
    appraisalQuestionnaireStatus: Store.Status;
    attributesStatus: Store.Status;
}

export const initialInternalPropertyState: InternalPropertyState = {
    loadingProperty: false,
    updatingProperty: false,
    property: undefined,
    loadingTodo: false,
    todo: {
        reminders: [],
        meetings: [],
        tasks: [],
    },
    updatingAcquisitionData: false,
    updatingListingExpectationData: false,
    updatingProspectData: false,
    loadingSetPhaseInvalid: false,
    loadingSetPhaseLostAndSold: false,
    deletingSalesAssistant: false,
    loadingActivity: false,
    updatingPricingData: false,
    removeStatus: 'idle',
    unarchiveStatus: 'idle',
    appraisalQuestionnaireStatus: 'idle',
    attributesStatus: 'idle',
};

export function internalPropertyReducer(
    state: InternalPropertyState = initialInternalPropertyState,
    action: InternalPropertyActionsUnion,
): InternalPropertyState {
    switch (action.type) {
        case InternalPropertyActionType.GET: {
            return {
                ...state,
                todo: { ...state.todo },
                loadingProperty: true,
                loadingPropertyError: undefined,
            };
        }
        case InternalPropertyActionType.GET_SUCCESS: {
            return {
                ...state,
                todo: { ...state.todo },
                loadingProperty: false,
                property: action.payload,
            };
        }
        case InternalPropertyActionType.GET_ERROR: {
            return {
                ...state,
                todo: { ...state.todo },
                loadingProperty: false,
                loadingPropertyError: action.payload,
            };
        }
        case InternalPropertyActionType.UPDATE: {
            return {
                ...state,
                todo: { ...state.todo },
                updatingProperty: true,
                updatingPropertyError: undefined,
            };
        }
        case InternalPropertyActionType.UPDATE_SUCCESS: {
            return {
                ...state,
                todo: { ...state.todo },
                updatingProperty: false,
                property: action.payload,
            };
        }
        case InternalPropertyActionType.UPDATE_ERROR: {
            return {
                ...state,
                todo: { ...state.todo },
                updatingProperty: false,
                updatingPropertyError: action.payload,
            };
        }
        case InternalPropertyActionType.GET_TODO: {
            return {
                ...state,
                todo: { ...state.todo },
                loadingTodo: true,
                loadingTodoError: undefined,
            };
        }
        case InternalPropertyActionType.GET_TODO_SUCCESS: {
            return {
                ...state,
                loadingTodo: false,
                todo: action.payload,
            };
        }
        case InternalPropertyActionType.GET_TODO_ERROR: {
            return {
                ...state,
                todo: { ...state.todo },
                loadingTodo: false,
                loadingTodoError: action.payload,
            };
        }
        case InternalPropertyActionType.RESET: {
            return initialInternalPropertyState;
        }

        case InternalPropertyActionType.UPDATE_ACQUISITION_DATA: {
            return {
                ...state,
                updatingAcquisitionData: true,
                updatingAcquisitionDataError: false,
            };
        }
        case InternalPropertyActionType.UPDATE_ACQUISITION_DATA_SUCCESS: {
            return {
                ...state,
                updatingAcquisitionData: false,
                property: action.payload,
            };
        }
        case InternalPropertyActionType.UPDATE_ACQUISITION_DATA_ERROR: {
            return {
                ...state,
                updatingAcquisitionData: false,
                updatingAcquisitionDataError: true,
            };
        }

        case updateVendorSources.type: {
            return {
                ...state,
                updatingVendorSources: true,
                updatingVendorSourcesError: false,
            };
        }
        case updateVendorSourcesSuccess.type: {
            return {
                ...state,
                updatingVendorSources: false,
                property: action.property,
            };
        }
        case updateVendorSourcesError.type: {
            return {
                ...state,
                updatingVendorSources: false,
                updatingVendorSourcesError: true,
            };
        }

        case InternalPropertyActionType.UPDATE_LISTING_EXPECTATION_DATA: {
            return {
                ...state,
                updatingListingExpectationData: true,
                updatingListingExpectationDataError: false,
            };
        }
        case InternalPropertyActionType.UPDATE_LISTING_EXPECTATION_DATA_SUCCESS: {
            return {
                ...state,
                updatingListingExpectationData: false,
                property: action.payload,
            };
        }
        case InternalPropertyActionType.UPDATE_LISTING_EXPECTATION_DATA_ERROR: {
            return {
                ...state,
                updatingListingExpectationData: false,
                updatingListingExpectationDataError: true,
            };
        }

        case InternalPropertyActionType.UPDATE_PROSPECT_DATA: {
            return {
                ...state,
                updatingProspectData: true,
                updatingProspectDataError: undefined,
            };
        }
        case InternalPropertyActionType.UPDATE_PROSPECT_DATA_SUCCESS: {
            const property = state.property;
            if (state.property) state.property.leadScore = action.payload.leadScore;
            return {
                ...state,
                updatingProspectData: false,
                property,
            };
        }
        case InternalPropertyActionType.UPDATE_PROSPECT_DATA_ERROR: {
            return {
                ...state,
                updatingProspectData: false,
                updatingProspectDataError: action.payload,
            };
        }

        case InternalPropertyActionType.SET_PHASE_INVALID: {
            return {
                ...state,
                loadingSetPhaseInvalid: true,
                loadingSetPhaseInvalidError: false,
            };
        }
        case InternalPropertyActionType.SET_PHASE_INVALID_SUCCESS: {
            return {
                ...state,
                loadingSetPhaseInvalid: false,
            };
        }
        case InternalPropertyActionType.SET_PHASE_INVALID_ERROR: {
            return {
                ...state,
                loadingSetPhaseInvalid: false,
                loadingSetPhaseInvalidError: true,
            };
        }

        case InternalPropertyActionType.SET_PHASE_LOST_AND_SOLD: {
            return {
                ...state,
                loadingSetPhaseLostAndSold: true,
                loadingSetPhaseLostAndSoldError: false,
            };
        }
        case InternalPropertyActionType.SET_PHASE_LOST_AND_SOLD_SUCCESS: {
            return {
                ...state,
                loadingSetPhaseLostAndSold: false,
            };
        }
        case InternalPropertyActionType.SET_PHASE_LOST_AND_SOLD_ERROR: {
            return {
                ...state,
                loadingSetPhaseLostAndSold: false,
                loadingSetPhaseLostAndSoldError: true,
            };
        }

        case InternalPropertyActionType.DELETE_SALES_ASSISTANT: {
            return {
                ...state,
                deletingSalesAssistant: true,
                deletingSalesAssistantError: undefined,
            };
        }
        case InternalPropertyActionType.DELETE_SALES_ASSISTANT_SUCCESS: {
            return {
                ...state,
                deletingSalesAssistant: false,
            };
        }
        case InternalPropertyActionType.DELETE_SALES_ASSISTANT_ERROR: {
            return {
                ...state,
                deletingSalesAssistant: false,
                deletingSalesAssistantError: action.payload,
            };
        }

        case InternalPropertyActionType.UPDATE_PRICING_DATA: {
            return {
                ...state,
                updatingPricingData: true,
                updatingPricingDataError: undefined,
            };
        }
        case InternalPropertyActionType.UPDATE_PRICING_DATA_SUCCESS: {
            return {
                ...state,
                updatingPricingData: false,
                property: action.payload,
            };
        }
        case InternalPropertyActionType.UPDATE_PRICING_DATA_ERROR: {
            return {
                ...state,
                updatingPricingData: false,
                updatingPricingDataError: action.payload,
            };
        }

        case updateHideFullAddress.type: {
            return {
                ...state,
                isUpdatingHideFullAddress: true,
            };
        }
        case updateHideFullAddressSuccess.type: {
            return {
                ...state,
                property: action.property,
                isUpdatingHideFullAddress: false,
            };
        }
        case updateHideFullAddressError.type: {
            return {
                ...state,
                isUpdatingHideFullAddress: false,
            };
        }

        case updateHideUnderOfferStatus.type: {
            return {
                ...state,
                isUpdatingHideUnderOfferStatus: true,
            };
        }
        case updateHideUnderOfferStatusSuccess.type: {
            return {
                ...state,
                property: action.property,
                isUpdatingHideUnderOfferStatus: false,
            };
        }
        case updateHideUnderOfferStatusError.type: {
            return {
                ...state,
                isUpdatingHideUnderOfferStatus: false,
            };
        }

        case updateHideSoldPrice.type: {
            return {
                ...state,
                isUpdatingHideSoldPrice: true,
            };
        }
        case updateHideSoldPriceSuccess.type: {
            return {
                ...state,
                property: action.property,
                isUpdatingHideSoldPrice: false,
            };
        }
        case updateHideSoldPriceError.type: {
            return {
                ...state,
                isUpdatingHideSoldPrice: false,
            };
        }

        case updateJustSoldNotificationEnabled.type: {
            return {
                ...state,
                isUpdatingJustSoldNotificationEnabled: true,
            };
        }
        case updateJustSoldNotificationEnabledSuccess.type: {
            return {
                ...state,
                property: action.property,
                isUpdatingJustSoldNotificationEnabled: false,
            };
        }
        case updateJustSoldNotificationEnabledError.type: {
            return {
                ...state,
                isUpdatingJustSoldNotificationEnabled: false,
            };
        }

        case updateVendorPortalEnabled.type: {
            return {
                ...state,
                isUpdatingVendorPortalEnabled: true,
            };
        }
        case updateVendorPortalEnabledSuccess.type: {
            return {
                ...state,
                property: action.property,
                isUpdatingVendorPortalEnabled: false,
            };
        }
        case updateVendorPortalEnabledError.type: {
            return {
                ...state,
                isUpdatingVendorPortalEnabled: false,
            };
        }

        case updateJustListedSmsNotificationEnabled.type: {
            return {
                ...state,
                isUpdatingJustListedSmsNotificationEnabled: true,
            };
        }
        case updateJustListedSmsNotificationEnabledSuccess.type: {
            return {
                ...state,
                property: action.property,
                isUpdatingJustListedSmsNotificationEnabled: false,
            };
        }
        case updateJustListedSmsNotificationEnabledError.type: {
            return {
                ...state,
                isUpdatingJustListedSmsNotificationEnabled: false,
            };
        }

        case updateJustListedEmailNotificationEnabled.type: {
            return {
                ...state,
                isUpdatingJustListedEmailNotificationEnabled: true,
            };
        }
        case updateJustListedEmailNotificationEnabledSuccess.type: {
            return {
                ...state,
                property: action.property,
                isUpdatingJustListedEmailNotificationEnabled: false,
            };
        }
        case updateJustListedEmailNotificationEnabledError.type: {
            return {
                ...state,
                isUpdatingJustListedEmailNotificationEnabled: false,
            };
        }

        case remove.type: {
            return {
                ...state,
                removeStatus: 'loading',
            };
        }
        case removeSuccess.type: {
            return {
                ...state,
                removeStatus: 'success',
                property: action.property,
            };
        }
        case removeError.type: {
            return {
                ...state,
                removeStatus: 'error',
            };
        }

        case unarchive.type: {
            return {
                ...state,
                unarchiveStatus: 'loading',
            };
        }
        case unarchiveSuccess.type: {
            return {
                ...state,
                unarchiveStatus: 'success',
                property: action.property,
            };
        }
        case unarchiveError.type: {
            return {
                ...state,
                unarchiveStatus: 'error',
            };
        }

        case updateAppraisalQuestionnaire.type: {
            return {
                ...state,
                appraisalQuestionnaireStatus: 'loading',
            };
        }
        case updateAppraisalQuestionnaireSuccess.type: {
            return {
                ...state,
                appraisalQuestionnaireStatus: 'success',
                property: action.property,
            };
        }
        case updateAppraisalQuestionnaireError.type: {
            return {
                ...state,
                appraisalQuestionnaireStatus: 'error',
            };
        }

        case updateAttributes.type: {
            return {
                ...state,
                attributesStatus: 'loading',
            };
        }
        case updateAttributesSuccess.type: {
            return {
                ...state,
                attributesStatus: 'success',
                property: action.payload,
            };
        }
        case updateAttributesError.type: {
            return {
                ...state,
                attributesStatus: 'error',
            };
        }

        case updateIsContractOfSaleAlwaysVisible.type: {
            return {
                ...state,
                isUpdatingIsContractOfSaleAlwaysVisible: true,
            };
        }

        case updateIsContractOfSaleAlwaysVisibleSuccess.type: {
            return {
                ...state,
                property: action.property,
                isUpdatingIsContractOfSaleAlwaysVisible: false,
            };
        }

        case updateIsContractOfSaleAlwaysVisibleError.type: {
            return {
                ...state,
                isUpdatingIsContractOfSaleAlwaysVisible: false,
            };
        }

        default: {
            return state;
        }
    }
}
