import { isNil } from 'lodash-es';

import { Aspect } from '../../models/domain/property/aspect.model';
import { PropertyRatePaymentFrequency } from '../../models/domain/property-attributes/property-rate-payment-frequency.model';
import { PropertyAttributes } from '../../models/property-attributes.model';
import { Property, PropertyType } from '../../models/property.model';

export namespace PropertyAttributesMapper {
    export function toDto(propertyAttributes: PropertyAttributes): Partial<Property> {
        const { generalDetails, basicDetails, features, rates, externalLinks, address } = propertyAttributes;
        const {
            ruralSubtype,
            retirementSubtypes,
            landSizeUnitOfMeasurement,
            internalSize,
            landSize,
            approximateAreas,
            ...restBasicDetails
        } = basicDetails || {};
        const { otherFeatures, ...restFeatures } = features || {};

        return {
            address,
            features: {
                ...restFeatures,
                aspect: Aspect.toDto(generalDetails?.aspect),
                hotWater: generalDetails?.hotWater,
                energyRating: generalDetails?.energyRating,
                internalSize,
                isNewDevelopment: basicDetails?.isNewDevelopment,
                councilRates: isNil(rates?.councilPerYear)
                    ? undefined
                    : {
                        value: rates.councilPerYear,
                        frequency: 'YEARLY' as const,
                    },
                waterRates: isNil(rates?.waterPerYear)
                    ? undefined
                    : {
                        value: rates.waterPerYear,
                        frequency: 'YEARLY' as const,
                    },
                strataRates: (() => {
                    if (isNil(rates?.strata)) return undefined;

                    return {
                        value: rates.strata,
                        frequency: PropertyRatePaymentFrequency.toDto(rates.strataFrequency),
                    };
                })(),
                otherFeatures: otherFeatures?.join(','),
            },
            attributes: {
                stringAttributes: {
                    APPROXIMATE_AREAS: approximateAreas,
                    KEY_SAFE: generalDetails?.keySafe,
                    ALARM_CODE: generalDetails?.alarmCode,
                    SELLING_REASON: generalDetails?.sellingReason,
                    REPAIRS_RENOVATIONS: generalDetails?.repairsAndRenovations,
                },
                dateTimeAttributes: {
                    BUILT: generalDetails?.builtDateTime,
                    SETTLEMENT: generalDetails?.settlementDateTime,
                },
                areaAttributes: {
                    LAND_SIZE: {
                        value: landSize,
                        uom: landSizeUnitOfMeasurement,
                    },
                },
                intAttributes: {
                    FRONTAGE_SIZE: generalDetails?.frontage,
                },
            },
            ...restBasicDetails,
            ...(ruralSubtype && { ruralPropertySubtype: ruralSubtype }),
            ...(retirementSubtypes?.length && { retirementPropertySubtype: retirementSubtypes }),
            ...(externalLinks && {
                videoUrl: externalLinks.videoUrl,
                virtualTourUrl: externalLinks.virtualTourUrl,
                threeDimensionalFloorPlan: externalLinks.threeDimensionFloorPlanUrl,
                externalPropertySite: externalLinks.externalPropertyWebsiteUrl,
                externalCustomPropertySite: externalLinks.externalCustomPropertyWebsiteUrl,
                externalOffers: externalLinks.externalOffersUrl,
            }),
        };
    }

    export function fromDto(propertyDto: Partial<Property>): PropertyAttributes {
        const { features, attributes, address } = propertyDto;
        const {
            energyRating,
            hotWater,
            internalSize,
            landSize,
            structuredInternalSize,
            structuredLandSize,
            isNewDevelopment,
            otherFeatures,
            councilRates,
            strataRates,
            waterRates,
            aspect,
            ...restFeatures
        } = features || {};

        return {
            address,
            generalDetails: {
                hotWater,
                energyRating,
                keySafe: attributes?.stringAttributes?.KEY_SAFE,
                alarmCode: attributes?.stringAttributes?.ALARM_CODE,
                frontage: attributes?.intAttributes?.FRONTAGE_SIZE,
                aspect: Aspect.fromDto(aspect),
                builtDateTime: attributes?.dateTimeAttributes?.BUILT,
                settlementDateTime: attributes?.dateTimeAttributes?.SETTLEMENT,
                sellingReason: attributes?.stringAttributes?.SELLING_REASON,
                repairsAndRenovations: attributes?.stringAttributes?.REPAIRS_RENOVATIONS,
            },
            basicDetails: {
                type: <PropertyType>propertyDto.type,
                ruralSubtype: propertyDto.ruralPropertySubtype,
                retirementSubtypes: propertyDto.retirementPropertySubtype,
                bedrooms: propertyDto.bedrooms,
                bathrooms: propertyDto.bathrooms,
                toilets: propertyDto.toilets,
                ensuites: propertyDto.ensuites,
                parkingGarage: propertyDto.parkingGarage,
                parkingCarport: propertyDto.parkingCarport,
                parkingOther: propertyDto.parkingOther,
                isNewDevelopment,
                internalSize,
                landSize: attributes?.areaAttributes?.LAND_SIZE?.value,
                landSizeUnitOfMeasurement: attributes?.areaAttributes?.LAND_SIZE?.uom,
                approximateAreas: attributes?.stringAttributes?.APPROXIMATE_AREAS,
            },
            features: {
                ...restFeatures,
                otherFeatures: otherFeatures
                    ?.split(',')
                    .map(feature => feature.trim())
                    .filter(feature => !!feature),
            },
            rates: {
                councilPerYear:
                    !isNil(councilRates?.value) && councilRates?.frequency === 'YEARLY'
                        ? councilRates.value
                        : undefined,
                waterPerYear:
                    !isNil(waterRates?.value) && waterRates?.frequency === 'YEARLY' ? waterRates.value : undefined,
                ...(!isNil(strataRates?.value) && strataRates?.frequency
                    ? {
                        strata: strataRates.value,
                        strataFrequency: PropertyRatePaymentFrequency.fromDto(strataRates.frequency),
                    }
                    : undefined),
            },
            externalLinks: {
                videoUrl: propertyDto.videoUrl,
                virtualTourUrl: propertyDto.virtualTourUrl,
                threeDimensionFloorPlanUrl: propertyDto.threeDimensionalFloorPlan,
                externalCustomPropertyWebsiteUrl: propertyDto.externalCustomPropertySite,
                externalPropertyWebsiteUrl: propertyDto.externalPropertySite,
                externalOffersUrl: propertyDto.externalOffers,
            },
        };
    }
}
