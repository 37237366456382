import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { first } from 'rxjs/operators';

import { NavigationService } from '../../navigation/navigation.service';

interface ScrollToOptions {
    focusTarget?: HTMLElement;
}

@Injectable()
export class ScrollToService {
    private renderer2: Renderer2;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        @Inject(DOCUMENT) private document: Document,
        private rendererFactory2: RendererFactory2,
        private navigationService: NavigationService,
    ) {
        this.renderer2 = this.rendererFactory2.createRenderer(this.document, null);
    }

    public scrollTo(scrollToTarget: HTMLElement, scrollToOptions?: ScrollToOptions): void {
        if (!isPlatformBrowser(this.platformId)) return;

        const focusTarget = scrollToOptions?.focusTarget || scrollToTarget;
        // scrollIntoView() is preferred here because the scroll() method does not take into account dynamic page
        // content such as lazy-loaded images (that aren't pre-allocating their height correctly) and externally loaded
        // data. If scroll() is used, as the page scrolls the page content may shift and therefore the target scroll
        // position can become wildly incorrect. scrollIntoView(), however, will automatically adjust for dynamic page
        // content.
        const scrollTargetIntoView = () =>
            scrollToTarget.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });

        this.navigationService.activeNavComponent$.pipe(first()).subscribe(activeNavComponent => {
            if (activeNavComponent) {
                const actualTop = scrollToTarget.style.top;
                const actualPosition = scrollToTarget.style.position;
                const navHeight = activeNavComponent.elementRef.nativeElement.getBoundingClientRect().height;

                // same frame, the user won't see the content shifting at all, but the scrollIntoView method will use the
                // new value to determine where to scroll to.
                this.renderer2.setStyle(scrollToTarget, 'position', 'relative');
                this.renderer2.setStyle(scrollToTarget, 'top', `${-navHeight}px`);

                scrollTargetIntoView();

                // Revert style changes which are no longer needed
                this.renderer2.setStyle(scrollToTarget, 'position', actualPosition);
                this.renderer2.setStyle(scrollToTarget, 'top', actualTop);
            } else {
                scrollTargetIntoView();
            }
        });

        // Make sure to focus on the target element for accessibility
        this.renderer2.setAttribute(focusTarget, 'tabindex', '-1');
        focusTarget.focus({ preventScroll: true });
    }
}
