import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { merge } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { PropertyAttributesMapper } from '../../common/mappers/property-attributes/property-attributes.mapper';
import { AppraisalQuestionnaire } from '../../common/models/domain/property/appraisal-questionnaire.model';
import { phase } from '../../common/models/phase.model';
import { Property } from '../../common/models/property.model';
import { doTranslation } from '../../common/utilities/i18n/do-translation.util';
import { PropertyResource } from '../../modules/core/resources/property.resource';
import { NotificationService } from '../../modules/core/services/notification.service';
import { AppState } from '../apps-state.model';

import {
    internalProperty,
    InternalPropertyActionType,
    remove,
    removeError,
    removeSuccess,
    unarchive,
    unarchiveError,
    unarchiveSuccess,
    updateAppraisalQuestionnaire,
    updateAppraisalQuestionnaireError,
    updateAppraisalQuestionnaireSuccess,
    updateAttributes,
    updateAttributesError,
    updateAttributesSuccess,
    updateHideFullAddress,
    updateHideFullAddressError,
    updateHideFullAddressSuccess,
    updateHideSoldPrice,
    updateHideSoldPriceError,
    updateHideSoldPriceSuccess,
    updateHideUnderOfferStatus,
    updateHideUnderOfferStatusError,
    updateHideUnderOfferStatusSuccess,
    updateIsContractOfSaleAlwaysVisible,
    updateIsContractOfSaleAlwaysVisibleError,
    updateIsContractOfSaleAlwaysVisibleSuccess,
    updateJustListedEmailNotificationEnabled,
    updateJustListedEmailNotificationEnabledError,
    updateJustListedEmailNotificationEnabledSuccess,
    updateJustListedSmsNotificationEnabled,
    updateJustListedSmsNotificationEnabledError,
    updateJustListedSmsNotificationEnabledSuccess,
    updateJustSoldNotificationEnabled,
    updateJustSoldNotificationEnabledError,
    updateJustSoldNotificationEnabledSuccess,
    updateVendorPortalEnabled,
    updateVendorPortalEnabledError,
    updateVendorPortalEnabledSuccess,
    updateVendorSources,
    updateVendorSourcesError,
    updateVendorSourcesSuccess,
} from './internal-property.actions';
import { propertySelector } from './internal-property.selectors';

@Injectable()
export class InternalPropertyEffects {
    constructor(
        private actions: Actions,
        private propertyResource: PropertyResource,
        private store: Store<AppState>,
        private notificationService: NotificationService,
    ) {}

    public getInternalProperty: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType<internalProperty.Get>(InternalPropertyActionType.GET),
            switchMap(action =>
                this.propertyResource.get(action.payload).pipe(
                    map(response => new internalProperty.GetSuccess(response)),
                    catchError(error => of(new internalProperty.GetError(error))),
                ),
            ),
        ),
    );

    public updateInternalProperty: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType<internalProperty.Update>(InternalPropertyActionType.UPDATE),
            switchMap(action =>
                this.propertyResource.updateProperty(action.payload.propertyId, action.payload.property).pipe(
                    this.notificationService.withNotification({ errorMessage: 'Error updating property' }),
                    map(response => new internalProperty.UpdateSuccess(response)),
                    catchError(error => of(new internalProperty.UpdateError(error))),
                ),
            ),
        ),
    );

    public getInternalPropertyTodo: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType<internalProperty.GetTodo>(InternalPropertyActionType.GET_TODO),
            switchMap(action =>
                this.propertyResource.getAllPropertyTodo(action.payload).pipe(
                    this.notificationService.withNotification({ errorMessage: 'Error loading property todo' }),
                    map(response => new internalProperty.GetTodoSuccess(response)),
                    catchError(error => of(new internalProperty.GetTodoError(error))),
                ),
            ),
        ),
    );

    public updateAcquisitionData: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType<internalProperty.UpdateAcquisitionData>(InternalPropertyActionType.UPDATE_ACQUISITION_DATA),
            switchMap(action =>
                this.propertyResource.updateProperty(action.payload.propertyId, action.payload.property).pipe(
                    this.notificationService.withNotification({
                        successMessage: 'Successfully updated Acquisition details',
                        errorMessage: 'Error updating Acquisition details',
                    }),
                    map(response => new internalProperty.UpdateAcquisitionDataSuccess(response)),
                    catchError(() => of(new internalProperty.UpdateAcquisitionDataError())),
                ),
            ),
        ),
    );

    public updateVendorSources$ = createEffect(() =>
        this.actions.pipe(
            ofType(updateVendorSources),
            switchMap(({ propertyId, vendorSources }) =>
                this.propertyResource.updateProperty(propertyId, vendorSources).pipe(
                    this.notificationService.withNotification({
                        successMessage: 'Successfully updated Vendor sources',
                        errorMessage: 'Error updating Vendor sources',
                    }),
                    map(property => updateVendorSourcesSuccess({ property })),
                    catchError(() => of(updateVendorSourcesError())),
                ),
            ),
        ),
    );

    public updateListingExpectationData: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType<internalProperty.UpdateListingExpectationData>(
                InternalPropertyActionType.UPDATE_LISTING_EXPECTATION_DATA,
            ),
            switchMap(action =>
                this.propertyResource.updateProperty(action.payload.propertyId, action.payload.property).pipe(
                    this.notificationService.withNotification({
                        successMessage: 'Successfully updated Listing expectations',
                        errorMessage: 'Error updating Listing expectations',
                    }),
                    map(response => new internalProperty.UpdateListingExpectationDataSuccess(response)),
                    catchError(() => of(new internalProperty.UpdateListingExpectationDataError())),
                ),
            ),
        ),
    );

    public refresh: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType<internalProperty.Refresh>(InternalPropertyActionType.REFRESH),
            withLatestFrom(this.store.select(propertySelector)),
            switchMap(([, property]) => {
                const id = property?.id;
                return id ? of(new internalProperty.Get(id)) : of({ type: 'NOOP_ACTION' });
            }),
        ),
    );

    public updateProspectData: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType<internalProperty.UpdateProspectData>(InternalPropertyActionType.UPDATE_PROSPECT_DATA),
            switchMap(action =>
                this.propertyResource.updateLeadScore(action.payload.propertyId, action.payload.property).pipe(
                    switchMap(() => [
                        new internalProperty.UpdateProspectDataSuccess(action.payload.property),
                        new internalProperty.Refresh(),
                    ]),
                    catchError(error => of(new internalProperty.UpdateProspectDataError(error))),
                ),
            ),
        ),
    );

    public setPhaseInvalid: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType<internalProperty.SetPhaseInvalid>(InternalPropertyActionType.SET_PHASE_INVALID),
            switchMap(action =>
                this.propertyResource.setPropertyPhaseInvalid(action.payload.propertyId, action.payload.request).pipe(
                    this.notificationService.withNotification({
                        successMessage: 'Successfully set property Invalid reason',
                        errorMessage: 'Error setting property Invalid reason',
                    }),
                    switchMap(() => [new internalProperty.SetPhaseInvalidSuccess(), new internalProperty.Refresh()]),
                    catchError(() => of(new internalProperty.SetPhaseInvalidError())),
                ),
            ),
        ),
    );

    public setPhaseLostAndSold: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType<internalProperty.SetPhaseLostAndSold>(InternalPropertyActionType.SET_PHASE_LOST_AND_SOLD),
            switchMap(action =>
                this.propertyResource.setPropertyPhaseLost(action.payload.propertyId, action.payload.request).pipe(
                    this.notificationService.withNotification({
                        successMessage: 'Successfully set Lost and Sold details',
                        errorMessage: 'Error setting Lost and Sold details',
                    }),
                    switchMap(() => [
                        new internalProperty.SetPhaseLostAndSoldSuccess(),
                        new internalProperty.Refresh(),
                    ]),
                    catchError(() => of(new internalProperty.SetPhaseLostAndSoldError())),
                ),
            ),
        ),
    );

    public deleteSalesAssistant: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType<internalProperty.DeleteSalesAssistant>(InternalPropertyActionType.DELETE_SALES_ASSISTANT),
            switchMap(action =>
                this.propertyResource.deleteSalesAssistant(action.payload).pipe(
                    switchMap(() => [
                        new internalProperty.DeleteSalesAssistantSuccess(),
                        new internalProperty.Refresh(),
                    ]),
                    catchError(error => of(new internalProperty.DeleteSalesAssistantError(error))),
                ),
            ),
        ),
    );

    public updatePricingData: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType<internalProperty.UpdatePricingData>(InternalPropertyActionType.UPDATE_PRICING_DATA),
            switchMap(action =>
                this.propertyResource.updateProperty(action.payload.propertyId, action.payload.pricingData).pipe(
                    map(response => new internalProperty.UpdatePricingDataSuccess(response)),
                    catchError(error => of(new internalProperty.UpdatePricingDataError(error))),
                ),
            ),
        ),
    );

    public updateHideFullAddress$: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(updateHideFullAddress),
            switchMap(({ propertyId, hideFullAddress }) =>
                this.propertyResource.updateProperty(propertyId, { hideFullAddress }).pipe(
                    map(property => updateHideFullAddressSuccess({ property })),
                    catchError(error => {
                        this.notificationService.httpError(error, {}, 'Error updating address visibility status');

                        return of(updateHideFullAddressError());
                    }),
                ),
            ),
        ),
    );

    public updateHideUnderOfferStatus$: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(updateHideUnderOfferStatus),
            switchMap(({ propertyId, hideUnderOffer }) =>
                this.propertyResource
                    .updateProperty(propertyId, {
                        attributes: {
                            boolAttributes: {
                                UNDER_OFFER_HIDDEN: hideUnderOffer,
                            },
                        },
                    })
                    .pipe(
                        map(property => updateHideUnderOfferStatusSuccess({ property })),
                        catchError(error => {
                            this.notificationService.httpError(
                                error,
                                {},
                                'Error updating under offer status visibility',
                            );

                            return of(updateHideUnderOfferStatusError());
                        }),
                    ),
            ),
        ),
    );

    public updateHideSoldPrice$: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(updateHideSoldPrice),
            switchMap(({ propertyId, hideSoldPrice }) =>
                this.propertyResource
                    .updateProperty(propertyId, {
                        attributes: {
                            boolAttributes: {
                                HIDE_SOLD_PRICE: hideSoldPrice,
                            },
                        },
                    })
                    .pipe(
                        map(property => updateHideSoldPriceSuccess({ property })),
                        catchError(error => {
                            this.notificationService.httpError(error, {}, 'Error updating sold price visibility');

                            return of(updateHideSoldPriceError());
                        }),
                    ),
            ),
        ),
    );

    public updateJustSoldNotificationEnabled$: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(updateJustSoldNotificationEnabled),
            switchMap(({ propertyId, enabled }) =>
                this.propertyResource
                    .updateProperty(propertyId, {
                        attributes: {
                            boolAttributes: {
                                SEND_JUST_SOLD_EMAIL_NOTIFICATION: enabled,
                            },
                        },
                    })
                    .pipe(
                        this.notificationService.withNotification({
                            successMessage: 'Updated notification setting successfully!',
                            errorMessage: 'Error updating notification setting',
                        }),
                        map(property => updateJustSoldNotificationEnabledSuccess({ property })),
                        catchError(() => of(updateJustSoldNotificationEnabledError())),
                    ),
            ),
        ),
    );

    public updateJustListedSmsNotificationEnabled$: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(updateJustListedSmsNotificationEnabled),
            switchMap(({ propertyId, enabled }) =>
                this.propertyResource
                    .updateProperty(propertyId, {
                        attributes: {
                            boolAttributes: {
                                SEND_JUST_LISTED_SMS_NOTIFICATION: enabled,
                            },
                        },
                    })
                    .pipe(
                        this.notificationService.withNotification({
                            successMessage: 'Updated notification setting successfully!',
                            errorMessage: 'Error updating notification setting',
                        }),
                        map(property => updateJustListedSmsNotificationEnabledSuccess({ property })),
                        catchError(() => of(updateJustListedSmsNotificationEnabledError())),
                    ),
            ),
        ),
    );

    public updateJustListedEmailNotificationEnabled$ = createEffect(() =>
        this.actions.pipe(
            ofType(updateJustListedEmailNotificationEnabled),
            switchMap(({ propertyId, enabled }) =>
                this.propertyResource
                    .updateProperty(propertyId, {
                        attributes: {
                            boolAttributes: {
                                SEND_JUST_LISTED_WEEKLY_EMAIL_NOTIFICATION: enabled,
                            },
                        },
                    })
                    .pipe(
                        this.notificationService.withNotification({
                            successMessage: 'Updated notification setting successfully!',
                            errorMessage: 'Error updating notification setting',
                        }),
                        map(property => updateJustListedEmailNotificationEnabledSuccess({ property })),
                        catchError(() => of(updateJustListedEmailNotificationEnabledError())),
                    ),
            ),
        ),
    );

    public updateVendorPortalEnabled$ = createEffect(() =>
        this.actions.pipe(
            ofType(updateVendorPortalEnabled),
            switchMap(({ propertyId, enabled }) =>
                this.propertyResource
                    .updateProperty(propertyId, {
                        attributes: {
                            boolAttributes: {
                                VENDOR_PORTAL_ENABLED: enabled,
                            },
                        },
                    })
                    .pipe(
                        this.notificationService.withNotification({
                            successMessage: `Property Portal ${enabled ? 'enabled' : 'disabled'} successfully!`,
                            errorMessage: `Error ${enabled ? 'enabling' : 'disabling'} Property Portal`,
                        }),
                        map(property => updateVendorPortalEnabledSuccess({ property })),
                        catchError(() => of(updateVendorPortalEnabledError())),
                    ),
            ),
        ),
    );

    public remove$ = createEffect(() =>
        this.actions.pipe(
            ofType(remove),
            withLatestFrom(this.store.select(propertySelector)),
            switchMap(([{ data }, { id }]) => {
                const request$ =
                    'archiveReason' in data
                        ? this.propertyResource
                            .updateProperty(id, {
                                archived: data.archiveReason,
                            })
                            .pipe(
                                this.notificationService.withNotification({
                                    errorMessage: 'Error archiving property',
                                    successMessage: 'Property archived successfully!',
                                }),
                            )
                        : this.propertyResource
                            .setPhase(id, {
                                ...data,
                                phase: phase.Phases.LostAndSold,
                            })
                            .pipe(
                                this.notificationService.withNotification({
                                    errorMessage: 'Error moving property to Lost and Sold',
                                    successMessage: 'Property moved to Lost and Sold successfully!',
                                }),
                            );

                return request$.pipe(
                    map(property => removeSuccess({ property })),
                    catchError(() => of(removeError())),
                );
            }),
        ),
    );

    public unarchive$ = createEffect(() =>
        this.actions.pipe(
            ofType(unarchive),
            withLatestFrom(this.store.select(propertySelector)),
            switchMap(([, { id }]) =>
                this.propertyResource.updateProperty(id, { archived: 'NotArchived' }).pipe(
                    this.notificationService.withNotification({
                        errorMessage: 'Error unarchiving property',
                        successMessage: 'Property unarchived successfully!',
                    }),
                    map(property => unarchiveSuccess({ property })),
                    catchError(() => of(unarchiveError())),
                ),
            ),
        ),
    );

    public updateAppraisalQuestionnaire$ = createEffect(() =>
        this.actions.pipe(
            ofType(updateAppraisalQuestionnaire),
            withLatestFrom(this.store.select(propertySelector)),
            switchMap(([{ payload }, { id }]) => {
                const { appraisalNote, priceOpinion, listingExpectations, ...rest } = payload;
                const mappedAttributes = PropertyAttributesMapper.toDto(rest);
                const mappedQuestionnaireDetails = {
                    appraisalNote,
                    attributes: {
                        stringAttributes: {
                            LIKELIHOOD_OF_SELLING: listingExpectations?.likelihoodOfSelling,
                        },
                        intAttributes: {
                            PRICE_EXPECTATION: listingExpectations?.expectedSellingPrice,
                        },
                    },
                    ...this.getAgentPriceOpinion(priceOpinion),
                    expectedSellingDate: listingExpectations?.expectedSellingDate,
                };

                return this.propertyResource
                    .updateProperty(id, merge(mappedAttributes, mappedQuestionnaireDetails))
                    .pipe(
                        this.notificationService.withNotification({
                            successMessage: 'Appraisal questionnaire updated successfully!',
                            errorMessage: 'Error updating appraisal questionnaire',
                        }),
                        map(property => updateAppraisalQuestionnaireSuccess({ property })),
                        catchError(() => of(updateAppraisalQuestionnaireError())),
                    );
            }),
        ),
    );

    public updateAttributes$ = createEffect(() =>
        this.actions.pipe(
            ofType(updateAttributes),
            withLatestFrom(this.store.select(propertySelector)),
            switchMap(([{ payload }, { id }]) =>
                this.propertyResource.updateProperty(id, PropertyAttributesMapper.toDto(payload)).pipe(
                    this.notificationService.withNotification({
                        successMessage: 'Attributes updated successfully!',
                        errorMessage: 'Error updating attributes',
                    }),
                    map(property => updateAttributesSuccess({ payload: property })),
                    catchError(() => of(updateAttributesError())),
                ),
            ),
        ),
    );

    public updateIsContractOfSaleAlwaysVisible$ = createEffect(() =>
        this.actions.pipe(
            ofType(updateIsContractOfSaleAlwaysVisible),
            switchMap(({ propertyId, isAlwaysVisible }) =>
                this.propertyResource
                    .updateProperty(propertyId, {
                        contractOfSaleAlwaysVisible: isAlwaysVisible,
                    })
                    .pipe(
                        this.notificationService.withNotification({
                            successMessage: doTranslation('internalProperty.updateContractOfSaleAlwaysVisible.success'),
                            errorMessage: doTranslation('internalProperty.updateContractOfSaleAlwaysVisible.error'),
                        }),
                        map(property => updateIsContractOfSaleAlwaysVisibleSuccess({ property })),
                        catchError(() => of(updateIsContractOfSaleAlwaysVisibleError())),
                    ),
            ),
        ),
    );

    private getAgentPriceOpinion(
        data?: AppraisalQuestionnaire['priceOpinion'],
    ): Pick<Property, 'agentPriceOpinion' | 'agentPriceOpinionMin' | 'agentPriceOpinionMax'> {
        if (!data) return;

        const hasFixedOpinion = !!data.value;

        return {
            agentPriceOpinionMin: hasFixedOpinion ? 0 : data.low || 0,
            agentPriceOpinionMax: hasFixedOpinion ? 0 : data.high || 0,
            agentPriceOpinion: hasFixedOpinion ? data.value : 0,
        };
    }
}
