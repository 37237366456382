import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { RawParams, StateService } from '@uirouter/angular';
import { finalize, first, map } from 'rxjs/operators';

import { environment } from '../../../common/models/environment.model';
import { userApi } from '../../../common/models/user-api.model';
import { user } from '../../../common/models/user.model';
import { ErrorHandlerService } from '../../../common/services/error-handler.service';
import { TrackingService } from '../../../common/services/tracking.service';
import { UserService } from '../../../common/services/user.service';
import { UserResource } from '../../core/resources/user.resource';
import { EnvironmentService } from '../../core/services/environment.service';
const minPasswordLength = 6;

type PasswordMode = 'set' | 'reset';

@Component({
    selector: 'up-conversion-set-password',
    templateUrl: './set-password.component.html',
})
export class UserSetPasswordComponent implements OnInit {
    @Input() public token: string;
    //state to be redirected to
    @Input() public redirectTo = 'dashboard';
    @Input() public propertyId: string;
    @Input() public passwordMode: PasswordMode;
    @Input() public redirectParams: string;
    public loading = false;
    public setPasswordFailed = false;
    public tokenExpired = false;
    public formSubmitAttempted: boolean;
    public get decodedRedirectUrl(): string {
        return decodeURIComponent(this.redirectTo);
    }
    public passwordControl = new FormControl('', [Validators.required, Validators.minLength(minPasswordLength)]);
    public isAuthenticated: boolean;

    public get isUpside(): boolean {
        return (
            this.environmentService.orgNameAsGrouping(this.environmentService.config.organisation.name) ===
            environment.GroupedOrganisationName.Upside
        );
    }

    constructor(
        private userResource: UserResource,
        private stateService: StateService,
        private trackingService: TrackingService,
        private userService: UserService,
        private errorHandlerService: ErrorHandlerService,
        private environmentService: EnvironmentService,
    ) {}

    public ngOnInit(): void {
        this.userService.userAuthDetailsUpdated$
            .pipe(
                first(),
                map(({ authenticated }) => !!authenticated),
            )
            .subscribe(authenticated => (this.isAuthenticated = authenticated));

        if (this.passwordMode === 'set') {
            this.checkToken();
        }
    }

    public submit(): void {
        this.formSubmitAttempted = true;

        if (this.passwordControl.invalid) return;

        if (this.passwordMode === 'set') {
            this.setPassword();
        } else if (this.passwordMode === 'reset') {
            this.resetPassword();
        }
    }

    public shouldShowError(): boolean {
        return this.formSubmitAttempted && this.passwordControl.invalid;
    }

    private checkToken() {
        this.loading = true;
        this.userResource
            .checkToken({ token: this.token })
            .pipe(finalize(() => (this.loading = false)))
            .subscribe(
                () => (this.setPasswordFailed = false),
                (response: HttpErrorResponse) => {
                    if (!response.error) return;

                    const { code } = response.error;

                    if (code === userApi.checkToken.get.ErrorCodes.TokenConsumed) {
                        // if the user is authenticated, we send them to their respective redirect routes, otherwise we
                        // send them to the login page
                        if (this.isAuthenticated) {
                            this.redirectIfAuthenticated();
                        } else {
                            this.stateService.go('user.signin');
                        }
                    } else if (code === userApi.checkToken.get.ErrorCodes.TokenAuthExpired) {
                        this.tokenExpired = true;
                    }
                },
            );
    }

    private setPassword(): void {
        this.loading = true;
        this.userResource
            .signUpFromShadow({
                token: this.token,
                password: this.passwordControl.value,
            })
            .subscribe(
                () => this.redirectIfAuthenticated(),
                () => {
                    this.setPasswordFailed = true;
                    this.loading = false;
                },
            );
    }

    private redirectIfAuthenticated(): void {
        this.trackingService.trackUser();
        this.userService.userAuthDetailsUpdated$.pipe(first(authData => authData.authenticated)).subscribe(authData => {
            let state: string;
            let params: RawParams;
            const isVendor = authData.roles && authData.roles.includes(user.Role.Vendor);
            if (!!this.decodedRedirectUrl && !!this.propertyId) {
                state = this.decodedRedirectUrl;
                params = { id: this.propertyId };
                // TODO: Possibly unreachable code due to default value of redirectTo, check if can be removed
            } else if (!!this.propertyId && isVendor) {
                state = 'dashboard';
                params = { id: this.propertyId };
            } else if (!!this.decodedRedirectUrl) {
                // Allow redirection to pages other than dashboard or property
                state = this.decodedRedirectUrl;
                if (this.redirectParams) {
                    try {
                        const parsedParams = JSON.parse(decodeURIComponent(this.redirectParams));
                        params = { ...parsedParams };
                    } catch (e) {
                        this.errorHandlerService.sendError(e);
                    }
                }
                // TODO: Possibly unreachable code due to default value of redirectTo, check if can be removed
            } else if (isVendor) {
                state = 'my-properties';
                // TODO: Possibly unreachable code due to default value of redirectTo, check if can be removed
            } else {
                state = 'home';
            }

            this.stateService.go(state, params);
        });
    }

    private resetPassword(): void {
        this.loading = true;
        this.userResource
            .resetPassword({
                token: this.token,
                password: this.passwordControl.value,
            })
            .subscribe(
                () => {
                    this.trackingService.trackUser();
                    this.stateService.go('my-properties');
                },
                () => {
                    this.setPasswordFailed = true;
                    this.loading = false;
                },
            );
    }
}
