import { Injector } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { UIRouter } from '@uirouter/angular';

import { environment } from '../../common/models/environment.model';
import { EnvironmentService } from '../../modules/core/services/environment.service';
import { TitleService } from '../../modules/core/services/title.service';

export const metaUiRouterHooks = (router: UIRouter, injector: Injector) => {
    router.transitionService.onSuccess({}, transition => {
        const environmentService: EnvironmentService = injector.get(EnvironmentService);
        const metaService: Meta = injector.get(Meta);
        const titleService: TitleService = injector.get(TitleService);
        const isUpside = environmentService.currentOrgGrouping === environment.GroupedOrganisationName.Upside;
        // These meta are all cleared when a transition completes successfully. Components / state files should be used
        // to override and add to the default meta (see below).
        const removeableSelectors = [
            'property="og:title"',
            'property="og:site_name"',
            'property="og:url"',
            'property="og:description"',
            'name="twitter:card"',
            'name="twitter:site"',
            'name="twitter:image"',
            'name="twitter:creator"',
            'name="twitter:description"',
        ];
        const defaultMeta = [
            {
                name: 'description',
                content: environmentService.environmentFromGlobal('metaDescription'),
            },
            { property: 'og:image', content: environmentService.environmentFromGlobal('metaOgImage') },
        ];

        const to = transition.to();

        removeableSelectors.forEach(tag => metaService.removeTag(tag));
        defaultMeta.forEach(tag => metaService.updateTag(tag));

        // Override default meta with values passed via state declarations
        if (to.data?.meta) {
            const { title, description, robots } = to.data.meta;
            if (title) {
                titleService.setTitleWithTrailingOrgName(title);
            }

            // Temporarily disable per page description meta for non-upside orgs
            // content for these metatags must not be undefined or it will break pre-render
            if (description && isUpside) {
                metaService.updateTag({ name: 'description', content: description });
            }

            if (robots) {
                metaService.updateTag({ name: 'robots', content: robots });
            } else {
                metaService.removeTag('name="robots"');
            }
        }
    });
};
