import {
    ChangeDetectorRef,
    Directive,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import { Permission } from '../../models/permission.model';
import { PermissionsService } from '../../services/permissions/permissions.service';

@UntilDestroy()
@Directive({ selector: '[ncPermission]' })
export class PermissionDirective implements OnInit, OnChanges {
    @Input() public ncPermission: Permission;
    @Input() public ncPermissionElse: TemplateRef<unknown>;
    @Input() public ncPermissionLoading: TemplateRef<unknown>;
    private permission$ = new BehaviorSubject<Permission | undefined>(undefined);

    constructor(
        private permissionsService: PermissionsService,
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        // Start with the loading Ref if one is available. It will be shown until the permission has resolved.
        if (this.ncPermissionLoading) {
            this.updateViewRef(this.ncPermissionLoading);
        }

        this.permission$
            .pipe(
                switchMap(permission =>
                    permission
                        ? this.permissionsService.has(permission)
                        : // If there is no permission function provided, treat it as though the user has permission
                          of(true),
                ),
                // Skip redundant view updates and CD cycles if the permission outcome hasn't changed
                distinctUntilChanged(),
                untilDestroyed(this),
            )
            .subscribe(has => this.updateViewRef(has ? this.templateRef : this.ncPermissionElse));
    }

    public ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges['ncPermission']) {
            this.permission$.next(simpleChanges['ncPermission'].currentValue);
        }
    }

    private updateViewRef(templateRef?: TemplateRef<unknown>): void {
        this.viewContainerRef.clear();

        if (templateRef) {
            this.viewContainerRef.createEmbeddedView(templateRef);
        }

        this.changeDetectorRef.markForCheck();
    }
}
