import { Transition } from '@uirouter/angular';

import { getStandardUpsideMetaDescription } from '../../../common/utilities/meta.utilities';
import { PropertyReportLandingComponent } from '../../../modules/property-report-landing/components/property-report-landing/property-report-landing.component';
import { FooterMegaComponent } from '../../footer/footer-mega/footer-mega.component';
import { NavMinimalComponent } from '../../nav/nav-minimal/nav-minimal.component';
import { NoopComponent } from '../../noop/noop.component';
import { ConversionAddressFoundComponent } from '../common/conversion-address-found/conversion-address-found.component';
import { ConversionCaptureDetailsComponent } from '../common/conversion-capture-details/conversion-capture-details.component';
import { ConversionCompleteComponent } from '../common/conversion-complete/conversion-complete.component';
import { ConversionQuestionnaireComponent } from '../common/conversion-questionnaire/conversion-questionnaire.component';
import {
    addressResolveFn,
    onEnterAddFacebookTrackingParams,
    propertyAnswerTokenResolveFn,
    propertyIdResolveFn,
    propertyReportDailyRequestLimitExceededResolveFn,
    stepResolveFn,
    submissionErrorResolveFn,
    userResolveFn,
} from '../common/resolvers';
import { NcConversionFunnelStateDeclaration } from '../conversion.states';

import { PhoneVerificationComponent } from './phone-verification/phone-verification.component';
import { PropertyReportComponent } from './property-report.component';
import { ReportPropertyDataComponent } from './report-property-data/report-property-data.component';

const totalSteps = 5;

export function funnelNameResolveFn() {
    return 'property-report';
}

export function propertyReportRequestedResolveFn(trans) {
    return trans.params().propertyReportAlreadyRequested;
}

export function navBackgroundColorResolveFn(transition: Transition) {
    return transition.params().navBackgroundColor;
}

export function navStickyResolveFn(transition: Transition) {
    return transition.params().navSticky;
}

export function redirectToResolveFn(transition: Transition) {
    return transition.params().redirectTo;
}

export function redirectParamsResolveFn(transition: Transition) {
    return transition.params().redirectParams;
}

export function userIdResolveFn(transition: Transition) {
    return transition.params().userId;
}

export const propertyReportStates: NcConversionFunnelStateDeclaration[] = [
    {
        name: 'conversion.property-report',
        url: '/property-report',
        views: {
            conversion: {
                component: PropertyReportComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
        redirectTo: 'conversion.property-report.address',
    },
    {
        name: 'conversion.property-report.address',
        url: '/address',
        views: {
            'property-report': {
                component: PropertyReportLandingComponent,
            },
            'footer@vendor-app': {
                component: FooterMegaComponent,
            },
        },
        params: {
            submissionError: '',
            propertyReportAlreadyRequested: false,
            propertyReportDailyRequestLimitExceeded: false,
        },
        resolve: [
            {
                token: 'submissionError',
                deps: [Transition],
                resolveFn: submissionErrorResolveFn,
            },
            {
                token: 'propertyReportAlreadyRequested',
                deps: [Transition],
                resolveFn: propertyReportRequestedResolveFn,
            },
            {
                token: 'propertyReportDailyRequestLimitExceeded',
                deps: [Transition],
                resolveFn: propertyReportDailyRequestLimitExceededResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
        ],
        data: {
            meta: {
                title: 'Free Online Property Valuation Report: Get Market Insights',
                description:
                    'Want a free online property estimate or review the performance of nearby suburbs? Get your personalised report by visiting the Upside website online today!',
            },
            navConfig: {
                hideCta: true,
            },
        },
    },
    {
        name: 'conversion.property-report.address-found',
        url: '/address-found/{addressFinderResultId}',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            'property-report': {
                component: ConversionAddressFoundComponent,
            },
        },
        params: {
            address: undefined,
            step: 1,
            addressFinderResultId: {
                squash: true,
                value: null,
            },
        },
        resolve: [
            {
                token: 'address',
                deps: [Transition],
                resolveFn: addressResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
        ],
        data: {
            meta: {
                title: 'Free Online Property Value Report',
                description:
                    'How much is your home worth? Get a fast and free property value report. Find your estimated value range, comparable sales information and more. Our Upside real estate agents can provide a more detailed appraisal of your property’s value.',
            },
            globalProgress: 1 / totalSteps,
        },
    },
    {
        name: 'conversion.property-report.property-details',
        url: '/property-details',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            'property-report': {
                component: ReportPropertyDataComponent,
            },
        },
        params: {
            address: undefined,
            step: 2,
        },
        resolve: [
            {
                token: 'address',
                deps: [Transition],
                resolveFn: addressResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
        ],
        data: {
            meta: {
                title: 'Free Online Property Value Report',
                description: getStandardUpsideMetaDescription(),
            },
            globalProgress: 2 / totalSteps,
        },
    },
    {
        name: 'conversion.property-report.capture-details',
        url: '/details?agent&suburb',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            'property-report': {
                component: ConversionCaptureDetailsComponent,
            },
        },
        params: {
            address: undefined,
            agent: undefined,
            suburb: undefined,
            step: 3,
        },
        resolve: [
            {
                token: 'address',
                deps: [Transition],
                resolveFn: addressResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
        ],
        data: {
            meta: {
                title: 'Free Online Property Value Report',
                description: getStandardUpsideMetaDescription(),
            },
            globalProgress: 3 / totalSteps,
        },
        onEnter: onEnterAddFacebookTrackingParams,
    },
    {
        name: 'conversion.property-report.complete',
        url: '/complete',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            'property-report': {
                component: ConversionCompleteComponent,
            },
        },
        params: {
            user: undefined,
        },
        resolve: [
            {
                token: 'user',
                deps: [Transition],
                resolveFn: userResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
        ],
        data: {
            meta: {
                title: 'Free Online Property Value Report',
                description: getStandardUpsideMetaDescription(),
            },
            globalProgress: 3 / totalSteps,
        },
    },
    {
        name: 'conversion.property-report.questionnaire',
        url: '/questionnaire?agent&suburb',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            'property-report': {
                component: ConversionQuestionnaireComponent,
            },
        },
        params: {
            address: undefined,
            propertyAnswerToken: undefined,
            propertyId: undefined,
            agent: undefined,
            suburb: undefined,
            step: 4,
        },
        resolve: [
            {
                token: 'address',
                deps: [Transition],
                resolveFn: addressResolveFn,
            },
            {
                token: 'propertyAnswerToken',
                deps: [Transition],
                resolveFn: propertyAnswerTokenResolveFn,
            },
            {
                token: 'propertyId',
                deps: [Transition],
                resolveFn: propertyIdResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
        ],
        data: {
            globalProgress: 4 / totalSteps,
        },
        onEnter: onEnterAddFacebookTrackingParams,
    },
    {
        name: 'conversion.property-report.phone-verification',
        url: '/phone-verification?userId&redirectTo&redirectParams',
        params: {
            navBackgroundColor: 'transparent',
            navSticky: false,
        },
        resolve: [
            {
                token: 'backgroundColor',
                deps: [Transition],
                resolveFn: navBackgroundColorResolveFn,
            },
            {
                token: 'sticky',
                deps: [Transition],
                resolveFn: navStickyResolveFn,
            },
            {
                token: 'redirectTo',
                deps: [Transition],
                resolveFn: redirectToResolveFn,
            },
            {
                token: 'redirectParams',
                deps: [Transition],
                resolveFn: redirectParamsResolveFn,
            },
            {
                token: 'userId',
                deps: [Transition],
                resolveFn: userIdResolveFn,
            },
        ],
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            'property-report': {
                component: PhoneVerificationComponent,
            },
        },
    },
];
