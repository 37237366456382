import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

import { NoopComponent } from '../../../components/noop/noop.component';

@Injectable()
export class ScrollBlockService {
    private overlayRef: OverlayRef;

    constructor(private overlay: Overlay) {}

    public block(): void {
        this.unblock();
        this.overlayRef = this.overlay.create({ scrollStrategy: this.overlay.scrollStrategies.block() });
        this.overlayRef.attach(new ComponentPortal(NoopComponent));
    }

    public unblock(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = undefined;
        }
    }
}
