import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { WindowRef } from '../../../common/services/window.service';

import { EnvironmentService } from './environment.service';

@Injectable()
export class GoogleTagManagerService {
    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(DOCUMENT) private document: Document,
        private windowRef: WindowRef,
        private environmentService: EnvironmentService,
    ) {}

    public init$(): Observable<any> {
        return this.environmentService.config$.pipe(
            map(env => env.organisation.googleTagManagerId),
            tap(googleTagManagerId => {
                if (!isPlatformBrowser(this.platformId) || !googleTagManagerId) return;
                // Script provided by Google Tag Manager
                /* eslint-disable */
                // prettier-ignore
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
                        // prettier-ignore
                        {'gtm.start': new Date().getTime(),event:'gtm.js'}
                        // prettier-ignore
                    );var f=d.getElementsByTagName(s)[0],
                        // @ts-ignore
                        // prettier-ignore
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        // prettier-ignore
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        // prettier-ignore
                    })(this.windowRef.nativeWindow,this.document,'script','dataLayer',googleTagManagerId);
                /* eslint-enable */
            }),
        );
    }
}
