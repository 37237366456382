import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'upTrim',
})
export class TrimPipe implements PipeTransform {
    public transform(str: string): string {
        if (!str) return;
        return str.trim();
    }
}
