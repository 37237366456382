import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';

interface Options {
    async?: boolean;
    id?: string;
}

@Injectable()
export class ScriptLoaderService {
    private scripts: { [key: string]: Observable<void> } = {};
    private renderer: Renderer2;

    constructor(@Inject(DOCUMENT) private document: Document, private rendererFactory: RendererFactory2) {
        this.renderer = this.rendererFactory.createRenderer(this.document, null);
    }

    public load(src: string, options?: Options): Observable<void> {
        const defaultOptions = { async: true };
        const mergedOptions = { ...defaultOptions, ...options };
        const addedScripts = this.scripts[src];
        if (addedScripts) return addedScripts;

        const subject = new ReplaySubject<void>();
        // Observable should complete after a single emit
        const observable = subject.asObservable().pipe(first());

        this.scripts[src] = observable;

        const scriptElement: HTMLScriptElement = this.renderer.createElement('script');
        scriptElement.src = src;
        scriptElement.type = 'text/javascript';
        if (mergedOptions.id) {
            scriptElement.id = mergedOptions.id;
        }
        scriptElement.async = mergedOptions.async;
        scriptElement.addEventListener('load', () => {
            subject.next();
            subject.complete();
        });
        scriptElement.addEventListener('error', e => subject.error(e));
        const head = this.document.getElementsByTagName('head')[0];
        this.renderer.appendChild(head, scriptElement);

        return observable;
    }
}
