<ng-container *ngIf="categorisedTimeSlots">
    <up-alert class="u-margin-bottom--md" [type]="'warning'" [size]="'small'" *ngIf="timezone !== browserTz">
        Please note that times are displayed in <strong>{{ timezone }}</strong> timezone.
    </up-alert>
    <ng-container *ngIf="hasAnyTimeSlots; else hasAnyTimeSlotsElse">
        <div
            class="u-margin-bottom--md"
            role="radiogroup"
            [attr.aria-labelledby]="'heading-' + category"
            *ngFor="let category of (categorisedTimeSlots | keys)"
        >
            <h2 [id]="'heading-' + category" class="u-text-label--sm u-margin-bottom--md">
                Select available time - {{ category }}
            </h2>
            <div class="times" *ngIf="categorisedTimeSlots[category].length">
                <div class="time" *ngFor="let timeSlot of categorisedTimeSlots[category]">
                    <label class="radio">
                        <input
                            class="u-hide-visually"
                            type="radio"
                            [value]="timeSlot"
                            [formControl]="timeSlotControl"
                        />
                        <span class="radio-hitbox u-text-body--sm">{{ formatTime(timeSlot) }}</span>
                    </label>
                </div>
            </div>
            <div
                class="empty-state u-text-align--center u-text-body--rg u-padding-horizontal--lg u-padding-vertical--xl"
                *ngIf="!categorisedTimeSlots[category].length"
            >
                Sorry, there are no time slots available in the {{ category }}.
            </div>
        </div>
    </ng-container>
    <ng-template #hasAnyTimeSlotsElse>
        <div class="empty-state u-text-align--center u-text-body--rg u-padding-horizontal--lg u-padding-vertical--xl">
            Sorry, there are no time slots available on this day.
        </div>
    </ng-template>
</ng-container>
