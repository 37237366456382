import { Pipe, PipeTransform } from '@angular/core';

import { EnvironmentService } from '../../../core/services/environment.service';

@Pipe({ name: 'upCdnUrl' })
export class CdnUrlPipe implements PipeTransform {
    constructor(private environmentService: EnvironmentService) {}

    public transform(value: any): any {
        return this.environmentService.prefixCdnUrl(value);
    }
}
