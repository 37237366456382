import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CheckInRequestDto } from '../../../common/models/dto/appointment/check-in-request.dto';
import { CheckInResponseDto } from '../../../common/models/dto/appointment/check-in-response.dto';

@Injectable({ providedIn: 'root' })
export class AppointmentResource {
    private readonly baseUrl = `/api/v2/appointment`;

    constructor(private http: HttpClient) {}

    public checkInToOfi(appointmentId, payload: CheckInRequestDto): Observable<CheckInResponseDto> {
        return this.http.post<CheckInResponseDto>(`${this.baseUrl}/ofi/${appointmentId}/check-in`, payload);
    }
}
