import { InjectionToken } from '@angular/core';

enum Service {
    Servicable = 'Servicable',
    Fringe = 'Fringe',
    Unservicable = 'Unservicable',
}

export interface ServiceConfig {
    Servicable: Service.Servicable;
    Fringe: Service.Fringe;
    Unservicable: Service.Unservicable;
}

export const SERVICE_DI_CONFIG = {
    Servicable: 'Servicable',
    Fringe: 'Fringe',
    Unservicable: 'Unservicable',
};

export const SERVICE_CONFIG = new InjectionToken<ServiceConfig>('app.constants.service');
