import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { appraisal } from '../../../common/models/appraisal.model';

const BASE_URL = `/api/v2/appraisal`;
const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

@Injectable()
export class AppraisalResource {
    constructor(private http: HttpClient) {}

    public requestAppraisal(
        request: appraisal.api.post.Request,
    ): Observable<HttpResponse<appraisal.api.post.Response>> {
        return this.http.post<appraisal.api.post.Response>(`${BASE_URL}/`, request, {
            ...HEADERS_CONFIG,
            observe: 'response',
        });
    }
}
