<up-nav>
    <up-nav-bar-marketing>
        <up-nav-bar-container [grow]="true"> <up-nav-bar-logo-link></up-nav-bar-logo-link> </up-nav-bar-container>
        <up-nav-bar-container>
            <a
                up-nav-bar-button-marketing
                [attr.aria-label]="'Close'"
                [uiSref]="returnTo.name"
                [uiParams]="returnTo.params"
            >
                <up-icon [name]="'crossBold'"></up-icon>
            </a>
        </up-nav-bar-container>
    </up-nav-bar-marketing>
</up-nav>
