import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'up-get-started-service',
    templateUrl: './get-started-service.component.html',
    styleUrls: ['./get-started-service.component.scss'],
})
export class GetStartedServiceComponent {
    @Input() public showDesktopVersion: boolean;

    @HostBinding('class.is-desktop-version')
    public get isDesktopVersion(): boolean {
        return this.showDesktopVersion;
    }
}
