import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
    selector: 'nc-reveal-call-cta',
    templateUrl: 'reveal-call-cta.component.html',
    styleUrls: ['reveal-call-cta.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('flipHide', [
            state('true', style({ transform: 'rotateX(180deg)' })),

            transition(
                'false => true',
                animate(
                    '400ms ease-out',
                    keyframes([
                        style({
                            transform: 'rotateX(0deg)',
                            offset: 0,
                        }),
                        style({
                            transform: 'rotateX(180deg)',
                            offset: 1,
                        }),
                    ]),
                ),
            ),
        ]),
        trigger('flipShow', [
            state('false', style({ transform: 'rotateX(180deg)' })),

            transition(
                'false => true',
                animate(
                    '400ms ease-out',
                    keyframes([
                        style({
                            transform: 'rotateX(-180deg)',
                            offset: 0,
                        }),
                        style({
                            transform: 'rotateX(0deg)',
                            offset: 1,
                        }),
                    ]),
                ),
            ),
        ]),
    ],
})
export class RevealCallCtaComponent {
    @Input() public trackingName: string;
    public isShowingNumber = false;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    public showNumber(): void {
        this.isShowingNumber = true;
        this.changeDetectorRef.detectChanges();
    }
}
