import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Moment } from 'moment';
import { Observable } from 'rxjs';

import { AddressT } from '../../common/models/address.model';
import { UserBase } from '../../common/models/user.model';
import { propertyReport } from '../../modules/property-report/property-report.model';
import { AppState } from '../apps-state.model';

import {
    GetAgentCalendarBySlug,
    GetReportSuburb,
    ResetState,
    ResetUserEnteredState,
    SetAddress,
    SetAppraisalBookedTime,
    SetReportAvmPropertyDetails,
    SetReportPropertyToken,
    SetUser,
} from './conversion.actions';
import { AppraisalState, ConversionState, ReportState } from './conversion.reducer';
import {
    appraisalStateSelector,
    conversionStateSelector,
    propertyDetailsSelector,
    reportStateSelector,
    userSelector,
} from './conversion.selector';

@Injectable()
export class ConversionFacade {
    public conversionState$: Observable<ConversionState> = this.store.select(conversionStateSelector);
    public reportState$: Observable<ReportState> = this.store.select(reportStateSelector);
    public appraisalState$: Observable<AppraisalState> = this.store.select(appraisalStateSelector);
    public propertyDetails$: Observable<propertyReport.CoreLogicLiveAvmPropertyDetails> =
        this.store.select(propertyDetailsSelector);
    public user$: Observable<UserBase> = this.store.select(userSelector);

    constructor(private store: Store<AppState>) {}

    public getReportSuburb(suburbId: string): void {
        this.store.dispatch(new GetReportSuburb(suburbId));
    }

    public setAvmPropertyDetails(propertyDetails: propertyReport.CoreLogicLiveAvmPropertyDetails): void {
        this.store.dispatch(new SetReportAvmPropertyDetails(propertyDetails));
    }

    public setAppraisalBookedTime(dateTime: Moment): void {
        this.store.dispatch(new SetAppraisalBookedTime(dateTime));
    }

    public setUser(user: UserBase): void {
        this.store.dispatch(new SetUser(user));
    }

    public setAddress(address: AddressT): void {
        this.store.dispatch(new SetAddress(address));
    }

    public setReportPropertyToken(propertyToken: string): void {
        this.store.dispatch(new SetReportPropertyToken(propertyToken));
    }

    public getAgentCalendarBySlug(slug: string): void {
        this.store.dispatch(new GetAgentCalendarBySlug(slug));
    }

    public resetState(): void {
        this.store.dispatch(new ResetState());
    }

    public resetUserEnteredState(): void {
        this.store.dispatch(new ResetUserEnteredState());
    }
}
