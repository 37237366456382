import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

import { UpsideCommonModule } from '../../common/common';
import { TestIdDirective } from '../../common/directive/test-id/test-id.directive';
import { AccordionModule } from '../accordion/accordion.module';
import { LocalisationModule } from '../localisation/localisation.module';
import { SharedVendorModule } from '../shared-vendor/shared-vendor.module';

import { PropertyReportLandingComponent } from './components/property-report-landing/property-report-landing.component';

@NgModule({
    imports: [
        UpsideCommonModule,
        SharedVendorModule,
        AccordionModule,
        LocalisationModule,
        TranslocoModule,
        TestIdDirective,
    ],
    exports: [PropertyReportLandingComponent],
    declarations: [PropertyReportLandingComponent],
})
export class PropertyReportLandingModule {}
