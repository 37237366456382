import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TodosDto } from '../../../common/models/dto/todo/todos.dto';
import { stripNilValues } from '../../../common/utilities/data/data.utilities';

@Injectable()
export class TodoResource {
    private readonly baseUrl = '/api/v2/todo';

    constructor(private httpClient: HttpClient) {}

    public getMyUpcoming(): Observable<TodosDto> {
        return this.httpClient.get<TodosDto>(`${this.baseUrl}/upcoming/my`);
    }

    public getMyDue(): Observable<TodosDto> {
        return this.httpClient.get<TodosDto>(`${this.baseUrl}/my`);
    }

    public getForAgentBetweenDates(agentId: string, start?: string, end?: string): Observable<TodosDto> {
        return this.httpClient.get<TodosDto>(`${this.baseUrl}/agent/${agentId}`, {
            params: {
                ...(start && { start }),
                ...(end && { end }),
            },
        });
    }

    public getForUserBetweenDates(userId: string, fromUnixMs?: number, toUnixMs?: number): Observable<TodosDto> {
        return this.httpClient.get<TodosDto>(`${this.baseUrl}/user/${userId}`, {
            params: stripNilValues({
                from: fromUnixMs,
                to: toUnixMs,
            }),
        });
    }

    public getForUserOverdueAndUpcoming(userId: string): Observable<TodosDto> {
        return this.httpClient.get<TodosDto>(`${this.baseUrl}/overdue-and-upcoming/user/${userId}`);
    }
}
