import { Injectable } from '@angular/core';

import { LatLongT } from '../models/address.model';
import { location as Location } from '../models/location.model';

@Injectable()
export class LocationService {
    public getLatLngLiteralFromLocation(location: LatLongT): Location.LatLong {
        return {
            lat: typeof location.lat === 'number' ? location.lat : parseFloat(location.lat),
            lng: typeof location.lng === 'number' ? location.lng : parseFloat(location.lng),
        };
    }

    public fitMarkersOnMap(
        map: google.maps.Map,
        locations: google.maps.LatLngLiteral[] = [],
        boundsPadding: google.maps.Padding | number = 24,
    ): void {
        if (!map || !locations.length) return;

        const latLngBounds = new google.maps.LatLngBounds();
        locations.forEach(c => latLngBounds.extend(c));
        map.fitBounds(latLngBounds, boundsPadding);
        // Prevents the map from zooming in too far and giving little context to the location
        if (map.getZoom() > 15) map.setZoom(15);
    }
}
