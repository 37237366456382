import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

@Component({
    selector: 'up-feature-figure-wrap',
    templateUrl: './feature-figure-wrap.component.html',
    styleUrls: ['./feature-figure-wrap.component.scss'],
})
export class FeatureFigureWrapComponent implements AfterViewInit {
    @Input() public src: string;
    @Input() public alt: string;
    @Input() public backgroundColor: string;
    @ViewChild('background', { static: false }) public backgroundElement: ElementRef;

    constructor(private renderer: Renderer2) {}

    public ngAfterViewInit(): void {
        if (typeof this.alt === 'undefined') {
            throw new TypeError('alt text cannot be undefined');
        }

        if (this.backgroundColor) {
            this.renderer.setStyle(this.backgroundElement.nativeElement, 'background-color', this.backgroundColor);
        }
    }
}
