import { Component, Input, TemplateRef } from '@angular/core';

import { IconName } from '../../../../common/components/icon/icon.component';

export type Type = 'positive' | 'negative' | 'warning' | 'info' | 'loading';
type Size = 'default' | 'small';

@Component({
    selector: 'nc-alert, up-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
    @Input() public type: Type;
    @Input() public size: Size = 'default';
    @Input() public customIcon: TemplateRef<unknown>;

    public get iconName(): IconName | undefined {
        switch (this.type) {
            case 'info':
                return 'infoCircleFill';
            case 'warning':
                return 'warningFill';
            case 'positive':
                return 'successFill';
            case 'negative':
                return 'crossCircleFill';
            default:
                return undefined;
        }
    }
}
