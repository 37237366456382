import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { WindowRef } from '../../../../common/services/window.service';

@Injectable({ providedIn: 'root' })
export class PrintService {
    private beforePrintEvent$: Observable<Event>;
    private afterPrintEvent$: Observable<Event>;
    private appRootDisplaySetting?: string;
    private elementCurrentlyBeingPrinted?: HTMLElement;
    private targetPrintElementRef?: ElementRef<HTMLElement>;
    private renderer2: Renderer2;
    private readonly appRoot: HTMLElement;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly windowRef: WindowRef,
        private readonly rendererFactory2: RendererFactory2,
    ) {
        this.renderer2 = this.rendererFactory2.createRenderer(this.document, null);
        this.appRoot = <HTMLElement>this.document.body.getElementsByTagName('nc-root')[0];

        this.setupPrintEvents();
    }

    public registerTarget(elementRef: ElementRef): void {
        if (this.targetPrintElementRef) {
            throw new Error(
                'Existing print target found. Unregister the existing print target before registering a new one.',
            );
        }

        this.targetPrintElementRef = elementRef;
    }

    public unregisterTarget(): void {
        this.targetPrintElementRef = undefined;
    }

    public printCurrentTarget(): void {
        if (!this.targetPrintElementRef) {
            throw new Error('No print target registered.');
        }

        this.print(this.targetPrintElementRef.nativeElement);
    }

    private setupPrintEvents(): void {
        this.beforePrintEvent$ = fromEvent(this.windowRef.nativeWindow, 'beforeprint');
        this.afterPrintEvent$ = fromEvent(this.windowRef.nativeWindow, 'afterprint');

        this.beforePrintEvent$
            .pipe(filter(() => !!(this.targetPrintElementRef && !this.elementCurrentlyBeingPrinted)))
            .subscribe(() => this.printCurrentTarget());

        this.afterPrintEvent$.subscribe(() => this.cleanUp());
    }

    private print(element: HTMLElement): void {
        const clonedElement = <HTMLElement>element.cloneNode(true);

        this.elementCurrentlyBeingPrinted = clonedElement;
        this.renderer2.appendChild(this.document.body, clonedElement);

        this.redrawClonedCanvasesWithOriginalContext(element, clonedElement);
        this.hideAppRoot();

        setTimeout(() => this.windowRef.nativeWindow.print(), 1);
    }

    // Cloned canvases are blank as they lose the context of the original canvas, so they need to be redrawn
    // using the original context.
    private redrawClonedCanvasesWithOriginalContext(originalElement: HTMLElement, clonedElement: HTMLElement): void {
        const originalCanvases = originalElement.getElementsByTagName('canvas');
        const clonedCanvases = clonedElement.getElementsByTagName('canvas');

        Array.from(originalCanvases).forEach((originalCanvas, index) => {
            const clonedCanvas = clonedCanvases[index];
            const clonedCanvasContext = clonedCanvas.getContext('2d');

            if (!originalCanvas.width || !originalCanvas.height) return;

            clonedCanvasContext.drawImage(originalCanvas, 0, 0);
        });
    }

    private hideAppRoot(): void {
        this.appRootDisplaySetting = this.appRoot.style.display;
        this.renderer2.setStyle(this.appRoot, 'display', 'none');
    }

    private cleanUp(): void {
        if (!this.elementCurrentlyBeingPrinted) return;

        this.renderer2.setStyle(this.appRoot, 'display', this.appRootDisplaySetting || '');
        this.renderer2.removeChild(this.document.body, this.elementCurrentlyBeingPrinted);

        this.elementCurrentlyBeingPrinted = undefined;
    }
}
