import { NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

import { IconModule } from '../../common/components/icon/icon.module';
import { ResizeObserverDirective } from '../../common/directive/resize-observer/resize-observer.directive';
import { SharedModule } from '../../common/shared.module';
import { StaticMapModule } from '../static-map/static-map.module';
import { ThemeModule } from '../theme/theme.module';

import { PropertyAvatarComponent } from './components/property-avatar/property-avatar.component';
import { PropertyImageComponent } from './components/property-image/property-image.component';

@NgModule({
    imports: [
        TranslocoModule,
        ThemeModule,
        SharedModule,
        IconModule,
        NgOptimizedImage,
        StaticMapModule,
        ResizeObserverDirective,
    ],
    declarations: [PropertyAvatarComponent, PropertyImageComponent],
    exports: [PropertyAvatarComponent, PropertyImageComponent],
})
export class PropertyImageModule {}
