<ng-container *transloco="let t">
    <ng-container *ngIf="loading === false; else loadingPlaceholder">
        <ng-container *ngIf="!!events?.length; else empty">
            <nc-card *ngFor="let event of events" class="event u-text-body--sm">
                <div class="event-content">
                    <div>
                        <ng-container [ngSwitch]="event.type">
                            <span *ngSwitchCase="EventType.Open"> Inspection: </span>
                            <span *ngSwitchCase="EventType.Auction"> Auction: </span>
                        </ng-container>
                        <nc-date-time [dateTime]="event.start" [format]="'dateLongTimeShort'"></nc-date-time>
                        <ng-container *ngIf="event.end as end">
                            -
                            <nc-date-time [dateTime]="end" [format]="'timeShort'"></nc-date-time>
                        </ng-container>
                    </div>
                    <div *ngIf="event.type === EventType.Open" class="event-inspection-link">
                        <a
                            nc-icon-button
                            [attr.aria-label]="t('propertyBrochure.eventsList.checkInToInspection')"
                            [uiSref]="'self-check-in.check-in'"
                            [uiParams]="{
                                inspectionId: event.id,
                                propertyToken: propertyToken
                            }"
                        >
                            <nc-icon [color]="'currentColor'" [name]="'openDoorFill'"></nc-icon>
                        </a>
                    </div>
                </div>
            </nc-card>
        </ng-container>
        <ng-template #empty>
            <nc-card class="event">
                <nc-icon
                    class="u-margin-right--xs u-text-color--x-light"
                    [color]="'currentColor'"
                    [name]="'calendarFill'"
                ></nc-icon>
                There are no upcoming inspections booked at the moment. If you would like to book a private inspection,
                please contact {{ agentFirstName }} {{ agentLastName }}.
            </nc-card>
        </ng-template>
    </ng-container>

    <ng-template #loadingPlaceholder>
        <nc-card *ngFor="let _ of 3 | sequentialArray" class="event">
            <up-placeholder [width]="'240px'" [height]="'18px'"></up-placeholder>
        </nc-card>
    </ng-template>
</ng-container>
