import { Pipe, PipeTransform } from '@angular/core';

import { IconName } from '../../../common/components/icon/icon.component';
import { BooleanPropertyFeatures, PropertyFeaturesT } from '../../../common/models/property.model';

@Pipe({ name: 'ncPropertyFeatureToIconName' })
export class PropertyFeatureToIconNamePipe implements PipeTransform {
    private readonly propertyFeatureToIconNameMap: Record<keyof BooleanPropertyFeatures, IconName> = {
        aboveGroundPool: 'aboveGroundPool',
        aircon: 'airCondition',
        alarm: 'alarm',
        balcony: 'balcony',
        bath: 'bath',
        broadband: 'router',
        builtInWardrobes: 'wardrobe',
        cableOrSatellite: 'satellite',
        ceilingInsulation: 'ceilingReverseArrows',
        cityViews: 'building',
        courtyard: 'tree',
        deck: 'floorboards',
        dishwasher: 'dishwasher',
        doubleGlazedWindows: 'windows',
        ductedCooling: 'ductedCooling',
        ductedHeating: 'ductedHeating',
        energyEfficientAppliances: 'washerStar',
        ensuite: 'sink',
        evaporativeCooling: 'fan',
        fireplace: 'fireplace',
        floorboards: 'floorboards',
        fullyFenced: 'fence',
        furnished: 'bed',
        garden: 'plant',
        gas: 'heater',
        gasHeating: 'gasHeater',
        greywaterSystem: 'waterWavesDrops',
        groundFloor: 'tiles',
        gym: 'dumbbell',
        heating: 'heater',
        hydronicHeating: 'coilHeatWaves',
        indoorSpa: 'rocks',
        inGroundPool: 'inGroundPool',
        intercom: 'intercom',
        internalLaundry: 'tshirt',
        isNewDevelopment: 'building',
        northFacing: 'compass',
        outdoorEntertainment: 'barbecue',
        outdoorSpa: 'rocks',
        payTV: 'computer',
        pergola: 'pergola',
        petFriendly: 'paw',
        remoteGarage: 'garage',
        reverseCycleAirCon: 'airConditionBounceArrow',
        rumpusRoom: 'dices',
        secureParking: 'car',
        separateDiningRoom: 'spoonKnife',
        shed: 'shed',
        smokingPermitted: 'cigarette',
        solarHotWater: 'solarHotWaterPanel',
        solarPanels: 'solarPanel',
        splitSystemAirCon: 'airConditionSplitArrow',
        splitSystemHeating: 'coilReverseArrow',
        study: 'books',
        tennisCourt: 'tennisBall',
        vacuumSystem: 'vacuumCleaner',
        wallInsulation: 'wallReverseArrows',
        waterEfficientAppliances: 'washerWaterDrop',
        waterEfficientFixtures: 'faucet',
        waterTank: 'waterTank',
        waterViews: 'sunset',
        workshop: 'tools',
    };

    public transform(feature: keyof PropertyFeaturesT): IconName {
        return this.propertyFeatureToIconNameMap[feature];
    }
}
