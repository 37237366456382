<div *transloco="let t" class="u-text-body--rg">
    <up-marketing-hero-section
        class="u-margin-bottom--2xl"
        [imgSrcDesktop]="
            bgImgDesktopSrc ||
            ('/static/images/photography/property-report-landing-hero-desktop.jpg'
                | ncLocalisedAsset : ['orgGroup']
                | upCdnUrl)
        "
        [imgSrcMobile]="
            bgImgMobileSrc ||
            ('/static/images/photography/property-report-landing-hero-mobile.jpg'
                | ncLocalisedAsset : ['orgGroup']
                | upCdnUrl)
        "
    >
        <up-content-accent tagline [style]="'heartbeat'">{{ accentText || 'Property Report' }}</up-content-accent>
        <h1 class="u-text-heading--xl" [class.raywhite-heading]="ThemeName.RayWhite | upIsTheme" heading>
            <ng-container *ngIf="headingText; else defaultHeading">
                {{ headingText }}
            </ng-container>
            <ng-template #defaultHeading>
                <ng-container *ncEnvironment="{ orgGroup: GroupedOrganisation.Upside }">
                    {{ t('propertyReportLanding.heading.upside') }}
                </ng-container>
                <ng-container *ncEnvironment="{ orgGroup: GroupedOrganisation.RayWhite }">
                    <ng-container *ncEnvironment="{ country: 'AU' }">
                        {{ t('propertyReportLanding.heading.rayWhite.au') }}
                    </ng-container>
                    <ng-container *ncEnvironment="{ country: 'NZ' }">
                        {{ t('propertyReportLanding.heading.rayWhite.nz') }}
                    </ng-container>
                </ng-container>
            </ng-template>
        </h1>
        <div body>
            <div class="capture-address-form u-margin-bottom--lg">
                <nc-address-finder
                    [ctaLabel]="ctaButtonText || 'Get Started'"
                    [ncTestId]="'property-report-landing-hero-address-finder'"
                    (addressSelect)="setAddress($event)"
                >
                </nc-address-finder>
            </div>
            <div class="u-text-body--lg">
                <ng-container *ngIf="descriptionText; else defaultDescription">
                    {{ descriptionText }}
                </ng-container>
                <ng-template #defaultDescription>
                    <ng-container *ncEnvironment="{ orgGroup: GroupedOrganisation.Upside }">
                        Find out the value of your property and see comparable sales, suburb performance and more.
                        <strong> It takes seconds. </strong>
                    </ng-container>
                    <ng-container *ncEnvironment="{ orgGroup: GroupedOrganisation.RayWhite }">
                        <ng-container *ncEnvironment="{ country: 'AU' }">
                            {{ t('propertyReportLanding.description.rayWhite.au') }}
                        </ng-container>

                        <ng-container *ncEnvironment="{ country: 'NZ' }">
                            {{ t('propertyReportLanding.description.rayWhite.nz') }}
                        </ng-container>
                    </ng-container>
                </ng-template>
            </div>
            <up-alert
                [type]="'negative'"
                [size]="'small'"
                *ngIf="submissionError || propertyReportAlreadyRequested || propertyReportDailyRequestLimitExceeded"
                class="u-margin-top--md"
            >
                <ng-container *ngIf="submissionError"> {{ submissionError }} </ng-container>
                <ng-container *ngIf="propertyReportAlreadyRequested">
                    You have already requested a property report for this property.
                    <a class="c-link" [uiSref]="'my-properties'">Take me to my properties</a>
                </ng-container>
                <ng-container *ngIf="propertyReportDailyRequestLimitExceeded">
                    You have exceeded the daily limit for Property Reports, please try again later.
                </ng-container>
            </up-alert>
        </div>
    </up-marketing-hero-section>
    <up-get-property-report-steps-section class="u-margin-bottom--4xl-r"></up-get-property-report-steps-section>
    <up-container class="u-margin-bottom--7xl-r"> <hr class="c-horizontal-rule" /> </up-container>
    <up-container class="u-margin-bottom--7xl-r">
        <up-grid>
            <up-grid-col [col@sm]="5" class="u-margin-bottom--xl">
                <up-content-accent class="u-margin-bottom--lg">Property Report</up-content-accent>
                <h2 class="u-text-heading--section u-margin-bottom--md">
                    What's in the {{ orgDisplayName }} property report?
                </h2>
                <div class="u-text-body--lg">
                    The {{ orgDisplayName }} Property Report gives you an in-depth understanding of your property and
                    the market with comprehensive data including property value estimates, comparable sales and
                    historical suburb performance.
                </div>
            </up-grid-col>
        </up-grid>
        <up-grid>
            <up-grid-col>
                <up-grid [align]="'end'">
                    <up-grid-col [col@xs]="5">
                        <up-media-object #aos data-aos="fade-up" class="u-padding-bottom--2xl" [stacked]="true">
                            <div figure>
                                <up-feature-figure-wrap
                                    [src]="
                                        '/static/images/screens/property-report-property-value-screen.jpg'
                                            | ncLocalisedAsset : ['orgGroup', 'country']
                                            | upCdnUrl
                                    "
                                    [alt]="'Property estimate screen'"
                                ></up-feature-figure-wrap>
                            </div>
                            <div body>
                                <ng-container *ncEnvironment="{ country: 'AU' }">
                                    <h3 class="u-text-heading--md u-margin-bottom--xs"> Property Value Estimate </h3>
                                    <div>
                                        Get a property value estimate from Australia's leading real estate data
                                        provider. See how the value has changed over the years with past sales.
                                    </div>
                                </ng-container>
                                <ng-container *ncEnvironment="{ country: 'NZ' }">
                                    <h3 class="u-text-heading--md u-margin-bottom--xs"> Property Council Valuation </h3>
                                    <div>
                                        Get the latest council valuation for your property. See how the value has
                                        changed over the years with past sales.
                                    </div>
                                </ng-container>
                            </div>
                        </up-media-object>
                    </up-grid-col>
                    <up-grid-col [col@xs]="1"></up-grid-col>
                </up-grid>
            </up-grid-col>
            <up-grid-col>
                <up-grid>
                    <up-grid-col [col@xs]="5">
                        <up-media-object
                            #aos
                            data-aos="fade-up"
                            class="u-padding-bottom--2xl feature-offset--lg"
                            [stacked]="true"
                        >
                            <div figure>
                                <up-feature-figure-wrap
                                    [src]="
                                        '/static/images/screens/property-report-comparables-screen.jpg'
                                            | ncLocalisedAsset : ['orgGroup', 'country']
                                            | upCdnUrl
                                    "
                                    [alt]="'Comparable sales screen'"
                                ></up-feature-figure-wrap>
                            </div>
                            <div body>
                                <h3 class="u-text-heading--md u-margin-bottom--xs">Recent Sales and Listings</h3>
                                <div>
                                    Discover the latest sales and listings nearby to better understand the local market
                                    and get an idea of the market value of your property.
                                </div>
                            </div>
                        </up-media-object>
                    </up-grid-col>
                </up-grid>
            </up-grid-col>
            <up-grid-col>
                <up-grid [align]="'end'">
                    <up-grid-col [col@xs]="5">
                        <up-media-object #aos data-aos="fade-up" class="feature-offset--sm" [stacked]="true">
                            <div figure>
                                <up-feature-figure-wrap
                                    [src]="
                                        '/static/images/screens/property-report-suburb-performance-screen.jpg'
                                            | ncLocalisedAsset : ['orgGroup', 'country']
                                            | upCdnUrl
                                    "
                                    [alt]="'Suburb Performance and Insights screen'"
                                ></up-feature-figure-wrap>
                            </div>
                            <div body>
                                <h3 class="u-text-heading--md u-margin-bottom--xs">
                                    Suburb Performance and Insights
                                </h3>
                                <div>
                                    See how the property market in your suburb has changed over the last 10 years
                                    including median property prices, average days on market and auction clearance rates
                                    for both houses and units.
                                </div>
                            </div>
                        </up-media-object>
                    </up-grid-col>
                    <up-grid-col [col@xs]="1"></up-grid-col>
                </up-grid>
            </up-grid-col>
        </up-grid>
    </up-container>

    <up-container class="u-margin-bottom--7xl-r"> <hr class="c-horizontal-rule" /> </up-container>

    <up-container class="u-margin-bottom--7xl-r" [size]="'small'">
        <h2 class="u-text-heading--md u-margin-bottom--xl">Frequently asked questions</h2>
        <div class="u-text-label--sm u-margin-bottom--lg u-text-color--brand-secondary"> Property estimate </div>
        <up-accordion class="u-margin-bottom--2xl" [multi]="false">
            <up-accordion-panel (stateChange)="onPanelStateChange($event, 'What is a property value estimate?')">
                <ng-container headerContent>What is a property value estimate?</ng-container>

                <div bodyContent>
                    <p>
                        The {{ orgDisplayName }} property value estimate is an estimated value of a given property based
                        on various factors such as property details, sales history, recent sales in the area and market
                        trends. This information is provided by Australia's leading real estate data provider.
                    </p>
                </div>
            </up-accordion-panel>
            <up-accordion-panel (stateChange)="onPanelStateChange($event, 'How much is my house worth?')">
                <ng-container headerContent>How much is my house worth?</ng-container>

                <div bodyContent>
                    <p>
                        Your house value is determined by factors such as location, local sales, block size, number of
                        bedrooms and bathrooms, the age and condition of the property and other external features such
                        as a swimming pool.
                    </p>
                    <p>
                        A free online estimate is a quick way to get an understanding of what your house might be worth,
                        however this only takes into account a few factors. The best way to know the value of your
                        property is to have a professional come to your house, with the right service for this dependent
                        on your needs. If you're considering selling and want to know what price you should advertise
                        your house, the best option is to have a
                        <ng-container
                            *ngTemplateOutlet="appraisalLink; context: { content: 'free market appraisal' }"
                        ></ng-container>
                        from your local agent. If you want to know what your house is worth for legal or financial
                        reasons, you will need a licensed valuer to provide a valuation. This will be a much more
                        conservative value, and isn't what is used if you're looking to sell.
                    </p>
                </div>
            </up-accordion-panel>
            <up-accordion-panel (stateChange)="onPanelStateChange($event, 'What is my property value?')">
                <ng-container headerContent>What is my property value?</ng-container>

                <div bodyContent>
                    <p>
                        The value of your property can be split into two - legal value and sale value. Legal value is
                        more conservative and is determined through a formal valuation process completed by a registered
                        valuer which includes a detailed report and costs a fee. Sale value, however, is determined
                        through a
                        <ng-container
                            *ngTemplateOutlet="appraisalLink; context: { content: 'market appraisal' }"
                        ></ng-container>
                        by your local agent. The agent comes to your house and uses their experience to look at your
                        property from the perspective of the market. This provides a guide to your property value and
                        possible selling price.
                    </p>
                </div>
            </up-accordion-panel>
            <up-accordion-panel (stateChange)="onPanelStateChange($event, 'What is a valuation?')">
                <ng-container headerContent>What is a valuation?</ng-container>

                <div bodyContent>
                    <p>
                        A valuation provides a formal and conservative value of your home to be used for legal or
                        banking purposes rather than to sell a home. To obtain a valuation, a formal report is created
                        by an accredited valuer and is usually completed over 2-3 business days. Generally there is a
                        $300-$500 fee associated with property valuations.
                    </p>
                </div>
            </up-accordion-panel>
            <up-accordion-panel (stateChange)="onPanelStateChange($event, 'What is an appraisal?')">
                <ng-container headerContent>What is an appraisal?</ng-container>

                <div bodyContent>
                    <p>
                        Appraisals are to be used as more of a guide to property value, and allows you to get a better
                        understanding of a possible selling price.
                    </p>
                    <p>
                        An appraisal can be carried out by any real estate professional with no fee for the service. The
                        process of an appraisal is based on the real estate professional's knowledge of the market in
                        the area and their assessment of the property.
                        <ng-container *upFeature="'vendorAppraisalRequestsEnabled'">
                            <up-link [upSref]="'conversion.appraisal.address'"
                                >Get an appraisal from {{ orgDisplayName }}</up-link
                            >.
                        </ng-container>
                    </p>
                </div>
            </up-accordion-panel>
            <up-accordion-panel (stateChange)="onPanelStateChange($event, 'Is it free to get your house valued?')">
                <ng-container headerContent>Is it free to get your house valued?</ng-container>

                <div bodyContent>
                    <p>
                        {{ orgDisplayName }} offers the services of both a free online value estimate, as well as a
                        <ng-container
                            *ngTemplateOutlet="appraisalLink; context: { content: 'free market appraisal' }"
                        ></ng-container>
                        from your local {{ orgDisplayName }} agent. Valuations however, which are conservative values
                        provided for legal or banking reasons, incur a fee and can only be carried out by accredited
                        valuers.
                    </p>
                </div>
            </up-accordion-panel>
        </up-accordion>
        <div class="u-text-label--sm u-margin-bottom--lg u-text-color--brand-secondary"> Property Photos </div>
        <up-accordion class="u-margin-bottom-lg" [multi]="false">
            <up-accordion-panel (stateChange)="onPanelStateChange($event, 'Why do you have photos of my property?')">
                <ng-container headerContent>Why do you have photos of my property?</ng-container>

                <div bodyContent>
                    <p>
                        The photos used in our value estimate reports are taken from partner services and were obtained
                        from historical data of property activity, such as your property being listed for sale, or being
                        put up for rent. If there was no recent activity, we may utilise a street view image instead.
                    </p>
                    <p>
                        Keep in mind that some photos may not necessarily reflect recent changes you have made to the
                        property. The timing of the photo does not reflect the time of the data used to determine the
                        estimated value.
                    </p>
                </div>
            </up-accordion-panel>
            <up-accordion-panel (stateChange)="onPanelStateChange($event, 'Why is the photo of the wrong property?')">
                <ng-container headerContent>Why is the photo of the wrong property?</ng-container>

                <div bodyContent>
                    <p>
                        The photos used in our value estimate reports are taken from partner services and were obtained
                        from historical data of property activity, such as your property being listed for sale, or being
                        put up for rent. Occasionally, this data is incorrectly uploaded by agents when advertising a
                        property and therefore may continue to be associated with your address.
                    </p>
                    <p> This does not impact the quality of the data being used to determining the estimated value. </p>
                </div>
            </up-accordion-panel>
            <up-accordion-panel (stateChange)="onPanelStateChange($event, 'Why is the photo out of date?')">
                <ng-container headerContent>Why is the photo out of date?</ng-container>

                <div bodyContent>
                    <p>
                        Most photos come from when your property was previously listed for sale or up for rent. If it
                        has been a while since either of these occurred, the photo may be outdated. Wherever possible,
                        we will use street view images instead and are constantly working on ways to improve the quality
                        and recency of property photos within our report. The age of the photo does not reflect the age
                        of the data used to determine the estimated value.
                    </p>
                </div>
            </up-accordion-panel>
        </up-accordion>
    </up-container>

    <up-upsell-report-banner-section></up-upsell-report-banner-section>
</div>

<ng-template #appraisalLink let-content="content">
    <ng-container *ncEnvironment="{ orgGroup: GroupedOrganisation.Upside }">
        <a nc-link [uiSref]="'conversion.appraisal.address'">{{ content }}</a>
    </ng-container>
    <ng-container *ncEnvironment="{ orgGroup: GroupedOrganisation.RayWhite }">
        <a *ncEnvironment="{ country: 'AU' }" nc-link target="_blank" [href]="'https://www.raywhite.com/contact/'">
            {{ content }}
        </a>
        <a *ncEnvironment="{ country: 'NZ' }" nc-link target="_blank" [href]="'https://raywhite.co.nz/find-an-agent/'">
            {{ content }}
        </a>
    </ng-container>
</ng-template>
