<nc-conversion-step-container
    class="u-margin-top--4xl"
    *ngIf="isLoadingAddressFinderResult$ | async; else addressFound"
>
    <up-placeholder [width]="'100px'" [height]="'22px'" [display]="'block'"></up-placeholder>
    <up-placeholder class="u-margin-top--xs" [width]="'70%'" [height]="'32px'" [display]="'block'"></up-placeholder>
    <up-placeholder class="u-margin-top--sm" [width]="'90%'" [height]="'24px'" [display]="'block'"></up-placeholder>
    <up-placeholder class="u-margin-top--xs" [width]="'40%'" [height]="'20px'" [display]="'block'"></up-placeholder>
    <up-placeholder class="u-margin-top--md" [width]="'70%'" [height]="'20px'" [display]="'block'"></up-placeholder>
    <up-placeholder class="u-margin-top--lg" [width]="'100%'" [height]="'170px'" [display]="'block'"></up-placeholder>
    <up-placeholder class="u-margin-top--xl" [width]="'110px'" [height]="'36px'" [display]="'block'"></up-placeholder>
</nc-conversion-step-container>

<ng-template #addressFound>
    <up-conversion-step [backRoute]="'^.address'" [step]="step" [heading]="'We’ve found your property'">
        <ng-container helpText>
            <ng-container *ngIf="funnelName !== funnelNameEnum.Calendar; else isCalendarFunnel">
                Great, we’ve matched your address with a property and your local agent.
            </ng-container>
            <ng-template #isCalendarFunnel> Great, we’ve matched your address with a property. </ng-template>
        </ng-container>
        <ng-container body>
            <up-address-pin
                class="u-margin-bottom--md"
                [address]="address?.formattedAddress"
                (clear)="editAddress()"
            ></up-address-pin>
            <google-map
                [center]="{ lat: address?.geoLocation?.latitude, lng: address?.geoLocation?.longitude }"
                [zoom]="13"
                [height]="170"
                [options]="{
                    disableDefaultUI: true,
                    disableDoubleClickZoom: true,
                    gestureHandling: 'none',
                    scrollwheel: false
                }"
            >
                <map-marker
                    [clickable]="false"
                    [position]="{ lat: address?.geoLocation?.latitude, lng: address?.geoLocation?.longitude }"
                    [icon]="{
                        url: '/static/images/illustrations/map-pin.svg' | upCdnUrl,
                        scaledSize: mapIconSize
                    }"
                >
                </map-marker>
            </google-map>

            <up-agent-rating-card
                class="u-display--block u-margin-bottom--xl u-margin-top--md"
                *ngIf="funnelName === funnelNameEnum.Calendar && (agent$ | async) as agent"
                [agent]="agent"
            >
            </up-agent-rating-card>

            <up-conversion-step-cta-wrapper class="u-margin-top--xl">
                <up-button
                    [ncTestId]="'conversion-address-found-confirm-button'"
                    id="confirm-address-button"
                    (onClick)="next()"
                    [style]="'primary'"
                    [loading]="loading"
                    [arrowIcon]="true"
                >
                    Next
                </up-button>
            </up-conversion-step-cta-wrapper>
        </ng-container>
    </up-conversion-step>
</ng-template>
