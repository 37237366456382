import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
    name: 'ncTimeFromNow',
})
export class TimeFromNowPipe implements PipeTransform {
    public transform(value: string, withoutSuffix = false): string {
        return dayjs(value).fromNow(withoutSuffix);
    }
}
