<up-collapser-panel #panel [initialState]="'collapsed'" (stateChange)="onCollapserPanelStateChange($event)">
    <div class="toggle" header>
        <ng-content select="div[slot='trigger'][up-nav-pane-button]"></ng-content>
    </div>
    <div class="items" content>
        <ng-content></ng-content>
    </div>
</up-collapser-panel>
<ng-template #indicator>
    <up-icon [name]="panel.state.startsWith('expanded') ? 'minus' : 'plusBoldLarge'" [size]="'small'"></up-icon>
</ng-template>
