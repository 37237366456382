import { ModuleWithProviders, NgModule } from '@angular/core';

import { PermissionDirective } from './directives/permission/permission.directive';
import { PermissionsService } from './services/permissions/permissions.service';

@NgModule({
    declarations: [PermissionDirective],
    exports: [PermissionDirective],
})
export class PermissionsModule {
    public static forRoot(): ModuleWithProviders<PermissionsModule> {
        return {
            ngModule: PermissionsModule,
            providers: [PermissionsService],
        };
    }
}
