import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { RouteLoadingBarAnimationState } from '../components/route-loader/route-loading-bar.component';

@Injectable()
export class RouteLoadingBarService {
    public progressConfigUpdate$: Observable<RouteLoadingBarAnimationState>;
    private progressConfigUpdate = new Subject<RouteLoadingBarAnimationState>();

    constructor() {
        this.progressConfigUpdate$ = this.progressConfigUpdate.asObservable();
    }

    public startLoading(): void {
        this.progressConfigUpdate.next('loading');
    }

    public loadingComplete(): void {
        this.progressConfigUpdate.next('complete');
    }
}
