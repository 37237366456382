<nc-backdrop-floating-org-card>
    <ng-container slot="headingText"
        >Welcome to <br />
        {{ displayName }}</ng-container
    >

    <nc-google-sign-in class="u-margin-bottom--xl" (success)="onGoogleSignIn($event)"></nc-google-sign-in>

    <div class="u-text-color--light u-text-body--sm u-margin-bottom--md"> Or enter your details below </div>

    <form [formGroup]="signInForm" (ngSubmit)="signIn()">
        <div class="u-margin-bottom--sm">
            <up-input
                [autofocus]="true"
                [formControlName]="'email'"
                [size]="'large'"
                [type]="'email'"
                [fullWidth]="true"
                [internalLabel]="true"
                [error]="isControlError('email')"
                [label]="'Email address'"
            >
            </up-input>
            <up-help-text [type]="'error'" *ngIf="isControlError('email')">
                Please enter a valid email address
            </up-help-text>
        </div>

        <up-password-visibility-toggle class="u-margin-bottom--lg">
            <up-input
                [formControlName]="'password'"
                [size]="'large'"
                [type]="'password'"
                [fullWidth]="true"
                [internalLabel]="true"
                [error]="isControlError('password')"
                [label]="'Password'"
            >
            </up-input>
        </up-password-visibility-toggle>
        <div class="u-text-align--right">
            <up-button
                class="sign-in-button"
                [loading]="signingIn || checkingSignedInState"
                [type]="'submit'"
                [style]="'primary'"
                >Log In</up-button
            >
        </div>
    </form>
</nc-backdrop-floating-org-card>
