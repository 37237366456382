import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ncInitialsOfName' })
export class InitialsOfNamePipe implements PipeTransform {
    public transform(value?: string): string {
        if (!value) return value;

        const nameSplit = value.split(' ');
        const firstWord = nameSplit[0];
        const lastWord = nameSplit.length > 1 ? nameSplit[nameSplit.length - 1] : '';

        return `${firstWord ? firstWord[0] : ''}${lastWord ? lastWord[0] : ''}`.toUpperCase();
    }
}
