import { appraisalReport } from '../../common/models/appraisal-report.model';
import { Property } from '../../common/models/property.model';
import { todo } from '../../common/models/todo.model';

import * as PropertyActions from './property.actions';
import { PropertyAction, resetStore } from './property.actions';

export interface PropertyState extends Property {
    report: appraisalReport.AppraisalReport;
    reportRequestCompleted: boolean;
    allAppointments: todo.Appointment[];
    appointmentRequestCompleted: boolean;

    loadingProperty: boolean;
    loadingAppointments: boolean;
    loadingAppraisalReport: boolean;
}

const initialPropertyState = {
    id: null,
    active: null,
    address: null,
    bedrooms: null,
    bathrooms: null,
    ensuites: null,
    parking: null,
    parkingGarage: null,
    parkingCarport: null,
    parkingOther: null,
    salePrice: null,
    salePriceMin: null,
    publishSalePrice: null,
    type: null,
    media: null,
    valuations: {},
    saleHistory: [],
    comparableProperties: null,
    propertyAttributes: null, //deprecated?
    phase: null,
    shared: null,
    states: null,
    agent: null,
    salesRegion: null,
    opens: null,
    features: null,
    listingUrl: null,
    vendor: null,
    created: null,
    timeZone: null,
    attributes: null,
    service: null,
    conveyancer: null,
    coreLogic: null,
    utm: null,
    campaignStatus: null,
    auctionStatus: null,
    campaignType: null,
    vendorMedium: null,
    expectedSellingDate: null,
    report: undefined,
    reportRequestCompleted: false,
    allAppointments: [],
    appointmentRequestCompleted: false,
    verified: false,
    archivedMedia: [],
    loadingProperty: false,
    loadingAppointments: false,
    loadingAppraisalReport: false,
    isDatabase: false,
};

export function propertyReducer(state: PropertyState = initialPropertyState, action: PropertyAction) {
    switch (action.type) {
        case resetStore.type: {
            return {
                ...initialPropertyState,
            };
        }
        case PropertyActions.GET_PROPERTY:
            return {
                ...state,
                loadingProperty: true,
            };
        case PropertyActions.PROPERTY_LOADED:
            return {
                ...state,
                ...action.payload,
                loadingProperty: false,
            };
        case PropertyActions.ELECT_CONVEYANCER_CHOICE:
            return { ...state };
        case PropertyActions.ELECT_PROVIDED_CONVEYANCER:
            return { ...state };
        case PropertyActions.GET_APPRAISAL_REPORT:
            return {
                ...state,
                reportRequestCompleted: false,
                loadingAppraisalReport: true,
            };
        case PropertyActions.GET_APPRAISAL_REPORT_SUCCESS:
            return {
                ...state,
                report: action.payload,
                reportRequestCompleted: true,
                valuations: {
                    ...state.valuations,
                    appraisal: action.payload.valuation,
                },
                loadingAppraisalReport: false,
            };
        case PropertyActions.GET_APPRAISAL_REPORT_ERROR:
            return {
                ...state,
                report: undefined,
                reportRequestCompleted: true,
                loadingAppraisalReport: false,
            };
        case PropertyActions.GET_APPOINTMENTS:
            return {
                ...state,
                appointmentRequestCompleted: false,
                loadingAppointments: true,
            };
        case PropertyActions.GET_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                allAppointments: action.payload,
                appointmentRequestCompleted: true,
                loadingAppointments: false,
            };
        case PropertyActions.GET_APPOINTMENTS_ERROR:
            return {
                ...state,
                allAppointments: [],
                appointmentRequestCompleted: true,
                loadingAppointments: false,
            };
        default:
            return state;
    }
}
