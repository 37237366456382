import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Tab } from '../tabs.component';

@Component({
    selector: 'up-tab',
    templateUrl: './tab.component.html',
    styleUrls: ['./tab.component.scss'],
})
export class TabComponent {
    @Input() public tab: Tab;
    @Input() public active: boolean;
    @Input() public disabled: boolean;
    @Output() public selected: EventEmitter<Tab> = new EventEmitter<Tab>();

    public selectTab(): void {
        this.selected.emit(this.tab);
    }
}
