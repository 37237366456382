import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { reaXmlApi } from '../../../common/models/rea-xml-api.model';

const BASE_URL = '/api/v2/rea/xml';

@Injectable()
export class ReaXmlResource {
    constructor(private http: HttpClient) {}

    public status(id: string): Observable<reaXmlApi.getById.get.Response> {
        return this.http.get<reaXmlApi.getById.get.Response>(`${BASE_URL}/${id}/status`);
    }

    public triggerUpdate(id: string): Observable<reaXmlApi.triggerUpdate.get.Response> {
        return this.http.post<reaXmlApi.triggerUpdate.get.Response>(`${BASE_URL}/${id}/trigger-upload`, undefined);
    }
}
