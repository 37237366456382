<up-autocomplete
    #autocomplete
    class="suburbs-autocomplete"
    [icon]="'locationPinFill'"
    [iconColor]="'brand2Regular' | upColor"
    [filteredItems]="filteredSuburbs"
    (termChanged)="onTermChange($event)"
    (valueSelected)="onSelect($event)"
    [displayWith]="suburbDetailsDisplayNameDisplayWith"
    [disabled]="selectedSuburbs?.length >= maxSelectedSuburbs"
    [loading]="isLoadingSuburbList"
    [placeholder]="'Search suburbs'"
    [error]="error"
>
</up-autocomplete>
<ul class="selected-suburbs u-margin-top--lg" *ngIf="selectedSuburbs?.length">
    <li *ngFor="let suburb of selectedSuburbs">
        <up-tag [style]="'round'" [size]="'large'" (delete)="onDelete(suburb)">{{ suburb.displayName }}</up-tag>
    </li>
</ul>
