import { InjectionToken } from '@angular/core';

interface FileTagConfigT {
    name: string;
    value: string;
}

const FILE_TAG_DI_CONFIG = [
    { value: 'AgentContract', name: 'Agent contract' },
    { value: 'PersonalIdentification', name: 'Personal Identification' },
    { value: 'OwnershipIdentification', name: 'Ownership Identification' },
    { value: 'ContractOfSale', name: 'Contract Of Sale' },
    { value: 'StatementOfInformation', name: 'Statement Of Information' },
    { value: 'DirectDebitReceipt', name: 'Direct Debit Receipt' },
    { value: 'WeeklyCampaignReport', name: 'Campaign Report' },
    { value: 'Invoice', name: 'Invoice' },
    { value: 'BiddersReport', name: 'Bidders Report' },
    { value: 'LegalDocument', name: 'Other' },
];

let FILE_TAG_CONFIG = new InjectionToken<FileTagConfigT>('app.constants.file-tags');

export { FileTagConfigT, FILE_TAG_DI_CONFIG, FILE_TAG_CONFIG };
