import { Injector } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { first } from 'rxjs/operators';

import { convertObservableToPromise } from '../../common/common';
import { UserService } from '../../common/services/user.service';

export const vendorRouteAuthRestrictionUiRouterHooks = (router: UIRouter, injector: Injector) => {
    // Todo: Replace this with a routeRestrictions mechanism specified within the state declaration
    router.transitionService.onBefore({}, transition => {
        const userService: UserService = injector.get(UserService);
        const createRedirectTarget = () =>
            transition.router.stateService.target('user.signin', {
                redirect_to: transition.to().name,
                id: transition.params().id,
                redirectParams: JSON.stringify(transition.params()),
            });

        let splitName = transition.to().name.split('.');
        if (splitName.length > 0 && (splitName[0] === 'dashboard' || splitName[0] === 'my-properties')) {
            //TODO:REMOVE THIS
            // Get second element in stream, as the first is always set to false when userAuthDetails is initialised
            return convertObservableToPromise(userService.userAuthDetailsUpdated$.pipe(first()))
                .then((authData: any): any => {
                    if (authData.authenticated === false) {
                        return createRedirectTarget();
                    } else if (authData.authenticated === true) {
                        return true;
                    } else {
                        return createRedirectTarget();
                    }
                })
                .catch(() => {
                    return createRedirectTarget();
                });
        }
    });
};
