import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { PropertyFeaturesT } from '../../../../common/models/property.model';

@Component({
    selector: 'up-listing-key-features',
    templateUrl: 'listing-key-features.component.html',
    styleUrls: ['listing-key-features.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListingKeyFeaturesComponent {
    @Input() public features: PropertyFeaturesT;
    @HostBinding('class.u-display--none')
    private get hasNoFeatures(): boolean {
        return !this.keyFeatures.length;
    }

    public get keyFeatures(): (keyof PropertyFeaturesT)[] {
        if (!this.features) return [];

        return Object.entries(this.features)
            .filter(([, value]) => value === true)
            .map(([feature]) => <keyof PropertyFeaturesT>feature);
    }
}
