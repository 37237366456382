import { Pipe, PipeTransform } from '@angular/core';

/*
 *
 * Capitalises any string passed through the pipe.
 *
 * Will captalise characters at the start and any that
 * follow a space, dash or apostrophe
 *
 * */

@Pipe({
    name: 'capitaliseString',
})
export class CapitaliseStringPipe implements PipeTransform {
    public transform(word: string): string {
        if (!word) return '';

        const normalizedWord = word.toLowerCase();
        let transformedWord = '';

        for (let i = 0; i < normalizedWord.length; i++) {
            if (i === 0) {
                transformedWord += normalizedWord[i].toUpperCase();
            } else {
                const previousLetter = normalizedWord[i - 1];

                if (previousLetter === ' ' || previousLetter === '-' || previousLetter === '\'') {
                    transformedWord += normalizedWord[i].toUpperCase();
                } else {
                    transformedWord += normalizedWord[i];
                }
            }
        }

        return transformedWord;
    }
}
