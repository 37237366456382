import { HttpErrorResponse } from '@angular/common/http';

import { HistoryState } from '../../../../common/services/history.service';

import { GetStartedActionsUnion, GetStartedActionType } from './get-started.actions';

export interface GetStartedState {
    returnTo?: HistoryState;
    loadingRequestCallback: boolean;
    errorRequestingCallback?: HttpErrorResponse;
    hasRequestedCallback: boolean;
    hasStartedFlow: boolean;
}

export const initialGetStartedState: GetStartedState = {
    returnTo: undefined,
    loadingRequestCallback: false,
    errorRequestingCallback: undefined,
    hasRequestedCallback: false,
    hasStartedFlow: false,
};

export function getStartedReducer(
    state: GetStartedState = initialGetStartedState,
    action: GetStartedActionsUnion,
): GetStartedState {
    switch (action.type) {
        case GetStartedActionType.SET_RETURN_TO: {
            return {
                ...state,
                returnTo: action.payload,
            };
        }

        case GetStartedActionType.REQUEST_CALLBACK: {
            return {
                ...state,
                loadingRequestCallback: true,
                errorRequestingCallback: undefined,
            };
        }

        case GetStartedActionType.REQUEST_CALLBACK_SUCCESS: {
            return {
                ...state,
                loadingRequestCallback: false,
                hasRequestedCallback: true,
            };
        }

        case GetStartedActionType.REQUEST_CALLBACK_ERROR: {
            return {
                ...state,
                loadingRequestCallback: false,
                errorRequestingCallback: action.payload,
            };
        }

        case GetStartedActionType.SET_HAS_STARTED_FLOW: {
            return {
                ...state,
                hasStartedFlow: action.payload,
            };
        }

        case GetStartedActionType.SET_HAS_REQUESTED_CALLBACK: {
            return {
                ...state,
                hasRequestedCallback: action.payload,
            };
        }

        default: {
            return state;
        }
    }
}
