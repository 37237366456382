import { Injector } from '@angular/core';
import { UIRouter } from '@uirouter/angular';

import { BreadcrumbsService } from '../../modules/core/services/breadcrumbs.service';

export const breadcrumbsRouterHooks = (router: UIRouter, injector: Injector) => {
    const breadcrumbService = injector.get(BreadcrumbsService);
    router.transitionService.onBefore({}, transition => {
        breadcrumbService.createBreadcrumbs(transition.$to()?.path, transition.params());
    });
};
