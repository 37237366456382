import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'nc-returning-user',
    templateUrl: 'returning-user.component.html',
    styleUrls: ['returning-user.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReturningUserComponent {
    @Input() public address: string;
    @Input() public date: string;
    @Input() public firstName: string;
    @Input() public lastName: string;
    @Output() public currentUserClick = new EventEmitter<void>();
    @Output() public newUserClick = new EventEmitter<void>();

    public get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    public onCurrentUserClick(): void {
        this.currentUserClick.emit();
    }

    public onNewUserClick(): void {
        this.newUserClick.emit();
    }
}
