import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, first, map, startWith } from 'rxjs/operators';

import { NotificationService } from '../../../modules/core/services/notification.service';
import { replay } from '../../../operators/replay/replay.operator';
import { MediaTagName } from '../../models/domain/media/media-tag-name.model';
import { MediaTag } from '../../models/domain/media/media-tag.model';
import { GetMediaTagsUseCase } from '../../use-cases/media/get-media-tags/get-media-tags.use-case';
import { doTranslation } from '../../utilities/i18n/do-translation.util';

@Injectable({ providedIn: 'root' })
export class MediaTagsService {
    private readonly mediaTags$ = new BehaviorSubject<MediaTag.Model[]>([]);
    private readonly status$ = new BehaviorSubject<'idle' | 'loading' | 'success' | 'error'>('idle');
    private readonly mediaTagsWhenLoaded$: Observable<MediaTag.Model[]>;

    constructor(
        private readonly notificationService: NotificationService,
        private readonly getMediaTagsUseCase: GetMediaTagsUseCase,
    ) {
        this.mediaTagsWhenLoaded$ = combineLatest([
            this.mediaTags$,
            this.status$.pipe(map(status => status === 'success')),
        ]).pipe(
            filter(([_, isLoaded]) => isLoaded),
            map(([mediaTags]) => mediaTags),
            replay(),
        );
    }

    public getMediaTagDisplayName(mediaTagName: MediaTagName): Observable<string> {
        this.loadMediaTagsIfNotLoaded();

        return this.mediaTagsWhenLoaded$.pipe(
            first(),
            map(mediaTags => mediaTags.find(mediaTag => mediaTag.name === mediaTagName)?.displayName || mediaTagName),
            startWith(doTranslation('generic.loadingStringPlaceholder')),
        );
    }

    public getMediaTags(): Observable<MediaTag.Model[]> {
        this.loadMediaTagsIfNotLoaded();

        return this.mediaTagsWhenLoaded$;
    }

    private loadMediaTags(): void {
        this.status$.next('loading');

        this.getMediaTagsUseCase
            .execute()
            .pipe(
                this.notificationService.withNotification({
                    errorMessage: doTranslation('mediaTags.fetch.error'),
                }),
            )
            .subscribe({
                next: mediaTags => {
                    this.mediaTags$.next(mediaTags);
                    this.status$.next('success');
                },
                error: () => {
                    this.status$.next('error');
                },
            });
    }

    private loadMediaTagsIfNotLoaded(): void {
        if (['loading', 'success'].includes(this.status$.value)) return;

        this.loadMediaTags();
    }
}
