import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { AppPlatform } from '../../common/models/app-platform.model';
import { NavService } from '../../common/services/nav.service';
import { TrackingService } from '../../common/services/tracking.service';
import { AppPlatformService } from '../core/services/app-platform.service';
import { KeyboardUserHelperService } from '../core/services/keyboard-user-helper.service';
import { ThemeName, ThemeService } from '../core/services/theme.service';
import { NavigationService } from '../navigation/navigation.service';

@Component({
    selector: 'up-vendor-app',
    templateUrl: './vendor-app.component.html',
    styleUrls: ['vendor-app.component.scss'],
})
export class VendorAppComponent {
    public isNotStickyNav$: Observable<boolean>;
    public themeName: ThemeName;
    private stopWatchingForNavPaneSecondary$ = new Subject<void>();

    constructor(
        private Tracking: TrackingService,
        navService: NavService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private themeService: ThemeService,
        private keyboardUserHelperService: KeyboardUserHelperService,
        private renderer2: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private appPlatformService: AppPlatformService,
        private navigationService: NavigationService,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.Tracking.trackTimeOnSite();
            this.handleIsDashboardClass();
        }

        this.isNotStickyNav$ = navService.isSticky$.pipe(map(x => !x));
        this.themeName = this.themeService.themeName;
    }

    @HostListener('keyup', ['$event'])
    public keyboardUserHelper(event: KeyboardEvent) {
        this.keyboardUserHelperService.showFocusState(event);
    }

    // Toggles 'is-dashboard' class on HTML depending on app context
    private handleIsDashboardClass(): void {
        this.appPlatformService.platform$
            .pipe(map(platform => platform === AppPlatform.VendorDashboard))
            .subscribe(isDashboard => {
                const html = this.document.documentElement;
                if (isDashboard) {
                    this.renderer2.addClass(html, 'is-dashboard');
                    this.handleNavPaneSecondaryClass();
                } else {
                    this.renderer2.removeClass(html, 'is-dashboard');
                    this.stopWatchingForNavPaneSecondary$.next();
                }
            });
    }

    private handleNavPaneSecondaryClass(): void {
        this.navigationService.hasNavPaneSecondary$
            .pipe(takeUntil(this.stopWatchingForNavPaneSecondary$))
            .subscribe(has => {
                const html = this.document.documentElement;
                if (has) {
                    this.renderer2.addClass(html, 'has-nav-pane-secondary');
                } else {
                    this.renderer2.removeClass(html, 'has-nav-pane-secondary');
                }
            });
    }
}
