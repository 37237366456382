<up-container class="u-padding-vertical--4xl-r">
    <h2 class="u-text-heading--md u-text-color--brand-primary-light u-margin-bottom--2xl">
        Weekly market activity subscription preferences
        <span *ngIf="editingForAnotherUser$ | async as user"> for {{ user }} </span>
    </h2>
    <div class="u-text-align--center" *ngIf="notificationPreferencesState.loadingNotificationsPreferences">
        <up-spinner [size]="'large'"></up-spinner>
    </div>
    <ng-container *ngIf="notificationPreferencesState.loadingNotificationsPreferences === false">
        <up-create-subscription-cta *ngIf="hideForm" (createAlert)="createAlert()"></up-create-subscription-cta>
        <form *ngIf="!hideForm" [formGroup]="form" (ngSubmit)="onSubmit()">
            <fieldset class="u-margin-bottom--xl">
                <legend class="u-text-heading--sm">Suburbs</legend>
                <p class="u-margin-bottom--xl"> Add the suburbs that you want to receive weekly market updates for </p>
                <up-suburbs-selector [formControlName]="'suburbs'" [error]="isFormControlError('suburbs')">
                </up-suburbs-selector>
                <up-help-text class="u-margin-top--sm" *ngIf="isFormControlError('suburbs')" [type]="'error'">
                    Please select at least one suburb.
                </up-help-text>
            </fieldset>

            <ng-container [formGroupName]="'propertyPreference'">
                <fieldset class="u-margin-bottom--2xl">
                    <legend class="u-text-heading--sm">Property type</legend>
                    <p class="u-margin-bottom--lg"> Select the types of properties you’re interested in </p>
                    <up-property-type-selector
                        [formControlName]="'propertyTypes'"
                        [error]="isFormControlError('propertyPreference.propertyTypes')"
                    ></up-property-type-selector>
                    <up-help-text
                        class="u-margin-top--sm"
                        *ngIf="isFormControlError('propertyPreference.propertyTypes')"
                        [type]="'error'"
                    >
                        Please select at least one property type.
                    </up-help-text>
                </fieldset>

                <fieldset class="property-details u-margin-bottom--xl">
                    <legend class="u-text-heading--sm">Property details</legend>
                    <p class="u-margin-bottom--xl">
                        Customise your property preferences further or leave as the default to receive all properties
                        for the selected Property Types
                    </p>
                    <up-select
                        [formControlName]="'priceMin'"
                        [label]="'Minimum price'"
                        [fullWidth]="true"
                        [error]="
                            hasAttemptedFormSubmit &&
                            (isPriceMinGreaterThanMaxValidationError || isPriceMinEqualsMaxValidationError)
                        "
                    >
                        <up-select-option [value]="0">No minimum price</up-select-option>
                        <up-select-option *ngFor="let price of priceOptions" [value]="price">
                            Minimum of {{ price | currency : 'AUD' : 'symbol-narrow' : '1.0-0' }}
                        </up-select-option>
                    </up-select>
                    <div class="u-margin-bottom--md">
                        <up-help-text
                            class="u-margin-top--sm"
                            *ngIf="hasAttemptedFormSubmit && isPriceMinGreaterThanMaxValidationError"
                            [type]="'error'"
                        >
                            Minimum cannot be greater than maximum.
                        </up-help-text>
                        <up-help-text
                            class="u-margin-top--sm"
                            *ngIf="hasAttemptedFormSubmit && isPriceMinEqualsMaxValidationError"
                            [type]="'error'"
                        >
                            Minimum cannot be the same as maximum.
                        </up-help-text>
                    </div>
                    <up-select
                        [formControlName]="'priceMax'"
                        [label]="'Maximum price'"
                        [fullWidth]="true"
                        [error]="
                            hasAttemptedFormSubmit &&
                            (isPriceMaxLesserThanMinValidationError || isPriceMinEqualsMaxValidationError)
                        "
                    >
                        <up-select-option [value]="null">No maximum price</up-select-option>
                        <up-select-option *ngFor="let price of priceOptions" [value]="price">
                            Maximum of {{ price | currency : 'AUD' : 'symbol-narrow' : '1.0-0' }}
                        </up-select-option>
                    </up-select>
                    <div class="u-margin-bottom--md">
                        <up-help-text
                            class="u-margin-top--sm"
                            *ngIf="hasAttemptedFormSubmit && isPriceMaxLesserThanMinValidationError"
                            [type]="'error'"
                        >
                            Maximum cannot be lower than minimum.
                        </up-help-text>
                        <up-help-text
                            class="u-margin-top--sm"
                            *ngIf="hasAttemptedFormSubmit && isPriceMinEqualsMaxValidationError"
                            [type]="'error'"
                        >
                            Maximum cannot be the same as minimum.
                        </up-help-text>
                    </div>
                    <up-select
                        class="u-margin-bottom--md"
                        [formControlName]="'bedroomsMin'"
                        [label]="'Number of bedrooms'"
                        [fullWidth]="true"
                    >
                        <up-select-option [value]="0">Any number of bedrooms</up-select-option>
                        <up-select-option [value]="1">1+ bedrooms</up-select-option>
                        <up-select-option [value]="2">2+ bedrooms</up-select-option>
                        <up-select-option [value]="3">3+ bedrooms</up-select-option>
                        <up-select-option [value]="4">4+ bedrooms</up-select-option>
                        <up-select-option [value]="5">5+ bedrooms</up-select-option>
                    </up-select>
                    <up-select
                        class="u-margin-bottom--md"
                        [formControlName]="'bathroomsMin'"
                        [label]="'Number of bathrooms'"
                        [fullWidth]="true"
                    >
                        <up-select-option [value]="0">Any number of bathrooms</up-select-option>
                        <up-select-option [value]="1">1+ bathrooms</up-select-option>
                        <up-select-option [value]="2">2+ bathrooms</up-select-option>
                        <up-select-option [value]="3">3+ bathrooms</up-select-option>
                        <up-select-option [value]="4">4+ bathrooms</up-select-option>
                        <up-select-option [value]="5">5+ bathrooms</up-select-option>
                    </up-select>
                    <up-select
                        class="u-margin-bottom--md"
                        [formControlName]="'parkingMin'"
                        [label]="'Number of parking'"
                        [fullWidth]="true"
                    >
                        <up-select-option [value]="0">Any number of parking</up-select-option>
                        <up-select-option [value]="1">1+ parking</up-select-option>
                        <up-select-option [value]="2">2+ parking</up-select-option>
                        <up-select-option [value]="3">3+ parking</up-select-option>
                        <up-select-option [value]="4">4+ parking</up-select-option>
                        <up-select-option [value]="5">5+ parking</up-select-option>
                    </up-select>
                    <up-select
                        class="u-margin-bottom--md"
                        [formControlName]="'developmentPreference'"
                        [label]="'Development preference'"
                        [fullWidth]="true"
                    >
                        <up-select-option [value]="'All'">New and established</up-select-option>
                        <up-select-option [value]="'New'">New constructions only</up-select-option>
                        <up-select-option [value]="'Existing'">Established properties only</up-select-option>
                    </up-select>
                </fieldset>
            </ng-container>

            <fieldset class="property-alerts u-margin-bottom--xl" *upFeature="'newListingAlertEnabled'">
                <legend class="u-text-heading--sm u-margin-bottom--2xs"> New listing alert </legend>
                <up-switch [labelPosition]="'before'" [formControlName]="'sendListingNotification'">
                    Get immediate SMS alerts with details of new properties that meet your requirements
                </up-switch>
            </fieldset>

            <fieldset class="property-alerts u-margin-bottom--xl" *upFeature="'justSoldNotificationsEnabled'">
                <legend class="u-text-heading--sm u-margin-bottom--2xs"> Just sold alert </legend>
                <up-switch [labelPosition]="'before'" [formControlName]="'sendJustSoldNotification'">
                    Get email notifications of properties that are sold in your suburbs
                </up-switch>
            </fieldset>

            <fieldset
                class="user-details u-margin-bottom--5xl-r"
                *ngIf="form.controls['user'].enabled"
                [formGroupName]="'user'"
            >
                <legend class="u-text-heading--sm u-margin-bottom--xl">Your details</legend>
                <up-input
                    [formControlName]="'firstName'"
                    [label]="'First name'"
                    [size]="'large'"
                    [internalLabel]="true"
                    [error]="isFormControlError('user.firstName')"
                    [fullWidth]="true"
                ></up-input>
                <div class="u-margin-bottom--md">
                    <up-help-text
                        class="u-margin-top--sm"
                        *ngIf="isFormControlError('user.firstName')"
                        [type]="'error'"
                    >
                        Please provide a first name.
                    </up-help-text>
                </div>
                <up-input
                    [formControlName]="'lastName'"
                    [label]="'Last name'"
                    [size]="'large'"
                    [internalLabel]="true"
                    [error]="isFormControlError('user.lastName')"
                    [fullWidth]="true"
                ></up-input>
                <div class="u-margin-bottom--md">
                    <up-help-text
                        class="u-margin-top--sm"
                        *ngIf="isFormControlError('user.firstName')"
                        [type]="'error'"
                    >
                        Please provide a last name.
                    </up-help-text>
                </div>
                <up-input
                    [formControlName]="'email'"
                    [label]="'Email'"
                    [size]="'large'"
                    [internalLabel]="true"
                    [error]="isFormControlError('user.email')"
                    [fullWidth]="true"
                ></up-input>
                <div class="u-margin-bottom--md">
                    <up-help-text
                        class="u-margin-top--sm"
                        *ngIf="isFormControlError('user.firstName')"
                        [type]="'error'"
                    >
                        Please provide a valid email address.
                    </up-help-text>
                </div>
                <up-input
                    [formControlName]="'phoneNumber'"
                    [label]="'Mobile phone'"
                    [size]="'large'"
                    [internalLabel]="true"
                    [error]="isFormControlError('user.phoneNumber')"
                    [fullWidth]="true"
                ></up-input>
                <div class="u-margin-bottom--md">
                    <up-help-text
                        class="u-margin-top--sm"
                        *ngIf="isFormControlError('user.firstName')"
                        [type]="'error'"
                    >
                        Please provide a valid phone number.
                    </up-help-text>
                </div>
                <div class="u-text-color--light u-text-body--sm u-margin-top--md">
                    By proceeding you agree to our
                    <a class="c-link" target="_blank" uiSref="terms-and-conditions">Terms & Conditions</a> and
                    <a class="c-link" uiSref="privacy-policy" target="_blank">Privacy Policy.</a>
                </div>
            </fieldset>
            <div class="alerts">
                <up-alert
                    class="u-margin-bottom--lg"
                    *ngIf="hasAttemptedFormSubmit && isFormInvalid"
                    [type]="'negative'"
                    [size]="'small'"
                >
                    There are some errors the form, please fix them to continue:
                    <ul class="o-list">
                        <li class="o-list__item" *ngIf="isFormControlError('suburbs')">
                            Please select at least one suburb.
                        </li>
                        <li class="o-list__item" *ngIf="isFormControlError('propertyPreference.propertyTypes')">
                            Please select at least one property type.
                        </li>
                        <li
                            class="o-list__item"
                            *ngIf="hasAttemptedFormSubmit && isPriceMaxLesserThanMinValidationError"
                        >
                            Maximum price cannot be lower than minimum price.
                        </li>
                        <li class="o-list__item" *ngIf="hasAttemptedFormSubmit && isPriceMinEqualsMaxValidationError">
                            Maximum price cannot be the same as minimum price.
                        </li>
                        <li class="o-list__item" *ngIf="isFormControlError('user')">
                            Please ensure your details are valid.
                        </li>
                    </ul>
                </up-alert>
                <up-alert
                    class="u-margin-bottom--lg"
                    *ngIf="
                        form.pristine &&
                        !!notificationPreferencesState.errorSubscribeNotifications &&
                        notificationPreferencesState.errorSubscribeNotifications?.error?.code !==
                            SubscribeErrorCodes.ConfirmedUserCannotSignUp &&
                        notificationPreferencesState.errorSubscribeNotifications?.error?.code !==
                            SubscribeErrorCodes.UnconfirmedUserMustConfirm
                    "
                    [type]="'negative'"
                    [size]="'small'"
                >
                    Oops, something went wrong trying to saving your notification settings. Please try again.
                </up-alert>
                <up-alert
                    class="u-margin-bottom--lg"
                    *ngIf="
                        form.pristine &&
                        notificationPreferencesState.errorSubscribeNotifications?.error?.code ===
                            SubscribeErrorCodes.ConfirmedUserCannotSignUp
                    "
                    [type]="'warning'"
                    [size]="'small'"
                >
                    It seems like you already have an account. Please
                    <up-link [upSref]="'user.signin'" [upSrefParams]="{ redirect_to: 'user.notifications' }"
                        >sign in</up-link
                    >
                    to update your settings.
                </up-alert>
                <up-alert
                    class="u-margin-bottom--lg"
                    *ngIf="
                        form.pristine &&
                        notificationPreferencesState.errorSubscribeNotifications?.error?.code ===
                            SubscribeErrorCodes.UnconfirmedUserMustConfirm
                    "
                    [type]="'warning'"
                    [size]="'small'"
                >
                    It seems like you already have an account. Please check your email for confirmation.
                </up-alert>
                <up-alert
                    class="u-margin-bottom--lg"
                    *ngIf="
                        form.pristine &&
                        (notificationPreferencesState.successSubscribingNotifications ||
                            notificationPreferencesState.successUpdatingNotifications)
                    "
                    [type]="'positive'"
                    [size]="'small'"
                >
                    Your alert settings have been successfully saved!
                </up-alert>
            </div>
            <div class="controls">
                <up-button
                    class="u-margin-right--sm"
                    *ngIf="(isSubscribed$ | async) && !hideUnsubscribe"
                    (onClick)="confirmUnsubscribe()"
                    [style]="'secondary-dark'"
                    [loading]="notificationPreferencesState.unsubscribingNotifications"
                    [disabled]="
                        notificationPreferencesState.updatingNotificationsPreferences ||
                        notificationPreferencesState.subscribingNotifications
                    "
                >
                    Unsubscribe
                </up-button>
                <up-button
                    [type]="'submit'"
                    [style]="'primary-dark'"
                    [loading]="
                        notificationPreferencesState.updatingNotificationsPreferences ||
                        notificationPreferencesState.subscribingNotifications
                    "
                    [disabled]="notificationPreferencesState.unsubscribingNotifications"
                >
                    Save
                </up-button>
            </div>
        </form>
    </ng-container>
</up-container>
