<up-button
    *ngIf="item.uiSref"
    [loading]="item.loading && item.loading()"
    [upSref]="item.uiSref"
    [dataEvent]="item.trackingEvent"
    [style]="item.style || 'primary'"
>
    {{ item.title }}
</up-button>
<up-button
    *ngIf="item.click"
    [loading]="item.loading && item.loading()"
    (onClick)="item.click()"
    [dataEvent]="item.trackingEvent"
    [style]="item.style || 'primary'"
>
    {{ item.title }}
</up-button>
