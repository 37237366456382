import { CommonModule, I18nPluralPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { IconModule } from '../../common/components/icon/icon.module';

import { DateTimeComponent } from './components/date-time/date-time.component';
import { CalendarTimeFormatPipe } from './pipes/calendar-time-format/calendar-time-format.pipe';
import { DateFormatPipe } from './pipes/date-format/date-format.pipe';
import { FormatDurationFromSecondsPipe } from './pipes/format-duration/format-duration.pipe';
import { TimeFromNowPipe } from './pipes/time-from-now/time-from-now.pipe';

@NgModule({
    imports: [CommonModule, IconModule, MatTooltipModule],
    declarations: [
        DateFormatPipe,
        DateTimeComponent,
        TimeFromNowPipe,
        CalendarTimeFormatPipe,
        FormatDurationFromSecondsPipe,
    ],
    providers: [DateFormatPipe, CalendarTimeFormatPipe, FormatDurationFromSecondsPipe, I18nPluralPipe],
    exports: [
        DateFormatPipe,
        DateTimeComponent,
        TimeFromNowPipe,
        CalendarTimeFormatPipe,
        FormatDurationFromSecondsPipe,
    ],
})
export class DateModule {}
