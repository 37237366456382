import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { combineLatest } from 'rxjs';

import { UserService } from '../../../../common/services/user.service';
import { WindowRef } from '../../../../common/services/window.service';
import { EnvironmentService } from '../../../core/services/environment.service';
import { ScriptInjectorService } from '../../../script-injector/service/script-injector/script-injector.service';

@Injectable()
export class IntercomService {
    constructor(
        @Inject(PLATFORM_ID) private platformId,
        @Inject(DOCUMENT) private document: Document,
        private windowRef: WindowRef,
        private scriptInjectorService: ScriptInjectorService,
        private environmentService: EnvironmentService,
        private userService: UserService,
    ) {}

    public initialiseIntercom(): void {
        if (!isPlatformBrowser(this.platformId)) return;

        combineLatest([this.userService.userAuthDetailsUpdated$, this.userService.currentUserName$]).subscribe(
            ([authDetails, fullName]) => {
                if (authDetails.authenticated) {
                    this.startIntercom(authDetails.id, authDetails.email, fullName);
                } else {
                    this.shutdownIntercom();
                }
            },
        );
    }

    private startIntercom(userId: string, email?: string, name?: string): void {
        const intercomAppId = this.environmentService.environmentFromGlobal('intercomAppId');
        if (!userId || !intercomAppId) return;

        this.shutdownIntercom();

        const intercomConfig: Intercom_.IntercomSettings = {
            app_id: intercomAppId,
            name,
            email,
            user_id: userId,
        };

        const intercomInstance = this.windowRef.nativeWindow.Intercom;
        if (intercomInstance) {
            intercomInstance('boot', intercomConfig);
        } else {
            this.windowRef.nativeWindow.intercomSettings = intercomConfig;

            const script = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${intercomAppId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
            this.scriptInjectorService.inject(script);
        }
    }

    private shutdownIntercom(): void {
        const intercomInstance = this.windowRef.nativeWindow.Intercom;
        if (!intercomInstance) return;

        intercomInstance('shutdown');
        this.windowRef.nativeWindow.intercomSettings = undefined;
    }
}
