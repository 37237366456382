import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, Optional, PLATFORM_ID, ViewChild } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { UrlService } from '@uirouter/core';
import { Response } from 'express';

import { ParallaxFactory, ParallaxFactoryToken } from '../../common/injection-tokens/parallax-factory.injection-token';
import { TrackingService } from '../../common/services/tracking.service';
import { EnvironmentService } from '../core/services/environment.service';
import { ThemeName, ThemeService } from '../core/services/theme.service';
import { TitleService } from '../core/services/title.service';

@Component({
    selector: 'up-four04',
    templateUrl: 'four04.component.html',
    styleUrls: ['four04.component.scss'],
})
export class Four04Component implements AfterViewInit, OnInit {
    public themeName: ThemeName;
    public orgName: string;
    @ViewChild('illus', { static: false }) private illustration: ElementRef;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(ParallaxFactoryToken) private parallaxFactory: ParallaxFactory,
        @Optional() @Inject(RESPONSE) private response: Response,
        private themeService: ThemeService,
        private environmentService: EnvironmentService,
        private titleService: TitleService,
        private trackingService: TrackingService,
        private urlService: UrlService,
    ) {}

    // Need to use `UrlService` and look at the actual URL rather than the state as the 404 page is not part of the
    // internal or vendor app state, it's just a child of the root state, and the transition to the 404 is generally
    // done with `location: false` option to retain the URL in the browser
    public get isInternal(): boolean {
        return this.urlService.path().startsWith('/internal');
    }

    public ngOnInit(): void {
        this.orgName = this.environmentService.config.organisation.name;
        this.themeName = this.themeService.themeName;
        this.titleService.setTitleWithTrailingOrgName('404');
        this.trackingService.trackEvent('web404');
        this.response?.status(404);
    }

    public ngAfterViewInit(): void {
        if (!isPlatformBrowser(this.platformId)) return;
        this.parallaxFactory(this.illustration.nativeElement, {});
    }
}
