import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { UserResource } from '../../modules/core/resources/user.resource';
import { NotificationService } from '../../modules/core/services/notification.service';

import {
    getNotificationPreferencesForUser,
    getNotificationPreferencesForUserError,
    getNotificationPreferencesForUserSuccess,
    updateNotificationPreferencesForUser,
    updateNotificationPreferencesForUserError,
    updateNotificationPreferencesForUserSuccess,
    UserAction,
    UserActions,
    userActions,
} from './user.actions';

@Injectable()
export class UserEffects {
    constructor(
        private actions: Actions<UserActions>,
        private userResource: UserResource,
        private notificationService: NotificationService,
    ) {}

    public getNotificationsPreferences = createEffect(() =>
        this.actions.pipe(
            ofType<userActions.GetNotificationsPreferences>(UserAction.GET_NOTIFICATIONS_PREFERENCES),
            switchMap(() =>
                this.userResource.getNotificationsPreferences().pipe(
                    map(response => new userActions.GetNotificationsPreferencesSuccess(response)),
                    catchError((response: HttpErrorResponse) => {
                        this.errorLoadingPreferencesNotification(response);
                        return of(new userActions.GetNotificationsPreferencesError(response));
                    }),
                ),
            ),
        ),
    );

    public getNotificationsPreferencesWithToken = createEffect(() =>
        this.actions.pipe(
            ofType<userActions.GetNotificationsPreferencesWithToken>(
                UserAction.GET_NOTIFICATIONS_PREFERENCES_WITH_TOKEN,
            ),
            map(action => action.payload),
            switchMap(token =>
                this.userResource.getNotificationsPreferencesWithToken(token).pipe(
                    map(response => new userActions.GetNotificationsPreferencesWithTokenSuccess(response)),
                    catchError((response: HttpErrorResponse) => {
                        this.errorLoadingPreferencesNotification(response);
                        return of(new userActions.GetNotificationsPreferencesWithTokenError(response));
                    }),
                ),
            ),
        ),
    );

    public updateNotificationsPreferences = createEffect(() =>
        this.actions.pipe(
            ofType<userActions.UpdateNotificationsPreferences>(UserAction.UPDATE_NOTIFICATIONS_PREFERENCES),
            map(action => action.payload),
            switchMap(preferences =>
                this.userResource.putNotificationsPreferences(preferences).pipe(
                    map(response => new userActions.UpdateNotificationsPreferencesSuccess(response)),
                    catchError((response: HttpErrorResponse) =>
                        of(new userActions.UpdateNotificationsPreferencesError(response)),
                    ),
                ),
            ),
        ),
    );

    public updateNotificationsPreferencesWithToken = createEffect(() =>
        this.actions.pipe(
            ofType<userActions.UpdateNotificationsPreferencesWithToken>(
                UserAction.UPDATE_NOTIFICATIONS_PREFERENCES_WITH_TOKEN,
            ),
            map(action => action.payload),
            switchMap(({ token, preferences }) =>
                this.userResource.putNotificationsPreferencesWithToken(token, preferences).pipe(
                    map(response => new userActions.UpdateNotificationsPreferencesWithTokenSuccess(response)),
                    catchError((response: HttpErrorResponse) =>
                        of(new userActions.UpdateNotificationsPreferencesWithTokenError(response)),
                    ),
                ),
            ),
        ),
    );

    public notificationsSubscribe = createEffect(() =>
        this.actions.pipe(
            ofType<userActions.NotificationsSubscribe>(UserAction.NOTIFICATIONS_SUBSCRIBE),
            map(action => action.payload),
            switchMap(preferences =>
                this.userResource.postNotificationsSubscribe(preferences).pipe(
                    map(() => new userActions.NotificationsSubscribeSuccess()),
                    catchError((response: HttpErrorResponse) =>
                        of(new userActions.NotificationsSubscribeError(response)),
                    ),
                ),
            ),
        ),
    );

    public notificationsUnsubscribe = createEffect(() =>
        this.actions.pipe(
            ofType<userActions.NotificationsUnsubscribe>(UserAction.NOTIFICATIONS_UNSUBSCRIBE),
            switchMap(() =>
                this.userResource.postNotificationsUnsubscribe().pipe(
                    switchMap(() => [
                        new userActions.NotificationsUnsubscribeSuccess(),
                        new userActions.GetNotificationsPreferences(),
                    ]),
                    catchError((response: HttpErrorResponse) => {
                        this.errorUnsubscribingNotification(response);
                        return of(new userActions.NotificationsUnsubscribeError(response));
                    }),
                ),
            ),
        ),
    );

    public notificationsUnsubscribeWithToken = createEffect(() =>
        this.actions.pipe(
            ofType<userActions.NotificationsUnsubscribeWithToken>(UserAction.NOTIFICATIONS_UNSUBSCRIBE_WITH_TOKEN),
            map(action => action.payload),
            switchMap(token =>
                this.userResource.postNotificationUnsubscribeWithToken(token).pipe(
                    switchMap(() => [
                        new userActions.NotificationsUnsubscribeWithTokenSuccess(),
                        new userActions.ResetNotificationsPreferencesState(),
                    ]),
                    catchError((response: HttpErrorResponse) => {
                        this.errorUnsubscribingNotification(response);
                        return of(new userActions.NotificationsUnsubscribeWithTokenError(response));
                    }),
                ),
            ),
        ),
    );

    public getNotificationsPreferencesForUser = createEffect(() =>
        this.actions.pipe(
            ofType(getNotificationPreferencesForUser.type),
            switchMap(({ userId }) => {
                return this.userResource.getNotificationsPreferencesForUser(userId).pipe(
                    map(preferences => getNotificationPreferencesForUserSuccess({ payload: preferences })),
                    catchError(error => {
                        this.errorLoadingPreferencesNotification(error);
                        return of(getNotificationPreferencesForUserError({ payload: error }));
                    }),
                );
            }),
        ),
    );

    public updateNotificationsPreferencesForUser = createEffect(() =>
        this.actions.pipe(
            ofType(updateNotificationPreferencesForUser.type),
            switchMap(({ userId, preferences }) => {
                return this.userResource.updateNotificationsPreferencesForUser(userId, preferences).pipe(
                    map(preferences => updateNotificationPreferencesForUserSuccess({ payload: preferences })),
                    catchError(error => of(updateNotificationPreferencesForUserError({ payload: error }))),
                );
            }),
        ),
    );

    private errorUnsubscribingNotification(response: HttpErrorResponse): void {
        this.notificationService.httpError(response, undefined, 'Error unsubscribing, please try again');
    }

    private errorLoadingPreferencesNotification(response: HttpErrorResponse): void {
        this.notificationService.httpError(
            response,
            undefined,
            'Error loading subscription preferences, please try again',
        );
    }
}
