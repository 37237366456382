import { NgModule } from '@angular/core';

import { CdnUrlModule } from '../cdn-url/cdn-url.module';
import { LocalisationModule } from '../localisation/localisation.module';

import { BrandLogoComponent } from './components/brand-logo.component';

@NgModule({
    imports: [CdnUrlModule, LocalisationModule],
    declarations: [BrandLogoComponent],
    exports: [BrandLogoComponent],
})
export class BrandLogoModule {}
