import { Pipe, PipeTransform } from '@angular/core';

// generates an array populated from 0 to length count
// eg. 3 | sequential-array -> [0, 1, 2]

@Pipe({
    name: 'sequentialArray',
})
export class SequentialArrayPipe implements PipeTransform {
    public transform(count: number): number[] {
        return new Array(count).fill(0).map((x, i) => i);
    }
}
