<nc-conversion-step-container>
    <div class="u-margin-bottom--md">
        <div class="nav u-text-label--rg" *ngIf="step">
            <a class="back-button u-margin-right--md" *ngIf="backRoute" [uiSref]="backRoute">
                <up-icon aria-label="'Go back'" [name]="'arrowLeft'"></up-icon>
            </a>
            Step {{ step }}
        </div>
        <h1 class="u-text-heading--lg u-margin-bottom--sm">{{ heading }}</h1>
        <div class="help-text u-text-body--lg"><ng-content select="[helpText]"></ng-content></div>
    </div>
    <div> <ng-content select="[body]"></ng-content> </div>
</nc-conversion-step-container>
