import { isNil } from 'lodash-es';
import { Observable, ReplaySubject } from 'rxjs';
import { share } from 'rxjs/operators';

export function replay<T>(options?: { resetOnRefCountZero: boolean }): (source: Observable<T>) => Observable<T> {
    return source$ =>
        source$.pipe(
            share({
                connector: () => new ReplaySubject(1),
                resetOnError: false,
                resetOnComplete: false,
                resetOnRefCountZero: isNil(options) ? true : options.resetOnRefCountZero,
            }),
        );
}
