import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EnvironmentService } from '../../modules/core/services/environment.service';
import { ScriptLoaderService } from '../../modules/core/services/script-loader.service';

@Injectable()
export class GoogleService {
    // Emits only after the user explicitly actions the Google authentication flow
    public readonly loggedInUserCredentialsChange$: Observable<google.accounts.id.CredentialResponse | undefined>;
    private readonly _loggedInUserCredentialsChange$ = new Subject<google.accounts.id.CredentialResponse | undefined>();
    private hasLoadedGapiLib = false;

    constructor(
        @Inject(PLATFORM_ID) private readonly platformId: Object,
        private readonly environmentService: EnvironmentService,
        private readonly scriptLoaderService: ScriptLoaderService,
    ) {
        this.loggedInUserCredentialsChange$ = this._loggedInUserCredentialsChange$.asObservable();

        if (isPlatformBrowser(this.platformId)) {
            this.initGoogleIdentityServices();
        }
    }

    public renderGoogleSignInButton(parentElement: HTMLElement): void {
        google.accounts.id.renderButton(parentElement, {
            type: 'standard',
            size: 'large',
        });
    }

    public loadGapiLib(): Observable<void> {
        if (this.hasLoadedGapiLib) return of(undefined);

        return this.scriptLoaderService
            .load('https://apis.google.com/js/api.js')
            .pipe(tap(() => (this.hasLoadedGapiLib = true)));
    }

    private initGoogleIdentityServices(): void {
        google.accounts.id.initialize({
            client_id: this.environmentService.config.organisation.googleOAuthClientId,
            ux_mode: 'popup',
            context: 'signin',
            callback: credentialResponse => this._loggedInUserCredentialsChange$.next(credentialResponse),
        });
    }
}
