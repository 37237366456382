import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injector, PLATFORM_ID } from '@angular/core';
import { UIRouter } from '@uirouter/angular';

import { WindowRef } from '../../common/services/window.service';

// HACK: Used to work around ui-router inconsistency with handling navigation to document fragments
export const fragmentScrollHooks = (router: UIRouter, injector: Injector) => {
    const document = injector.get(DOCUMENT);
    const platformId = injector.get(PLATFORM_ID);
    const windowRef = injector.get(WindowRef);

    if (!isPlatformBrowser(platformId)) return;
    router.transitionService.onSuccess({}, transition => {
        // Let the page render before trying to find the element
        setTimeout(() => {
            // Get hash from params
            const fragmentId = transition.params()['#'];
            const matchingElement = document.getElementById(fragmentId);
            if (!matchingElement) return;
            // Offset attribute allows for easy offsetting of final scroll position without additional work
            // TODO: this could be improved by allowing for different offsets depending on screen size, which
            //  is often the case.
            const offset = parseInt(matchingElement.getAttribute('fragment-offset')) || 0;
            const documentPosition = (document.scrollingElement || document.documentElement).scrollTop;
            const elementPosition = matchingElement.getBoundingClientRect().top;
            const top = documentPosition + elementPosition - offset;

            // Focus element for accessibility
            matchingElement.focus();
            windowRef.nativeWindow.scroll({
                top,
                left: 0,
                // Must use smooth scroll behaviour or this will not work
                behavior: 'smooth',
            });
        });
    });
};
