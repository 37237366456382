import { Transition } from '@uirouter/angular';
import { filter, map, take } from 'rxjs/operators';

import { getStandardUpsideMetaDescription } from '../../../common/utilities/meta.utilities';
import { ConversionFacade } from '../../../store/conversion/conversion.facade';
import { FooterMegaComponent } from '../../footer/footer-mega/footer-mega.component';
import { NavMinimalComponent } from '../../nav/nav-minimal/nav-minimal.component';
import { NoopComponent } from '../../noop/noop.component';
import { AppraisalRequestedComponent } from '../appraisal/appraisal-requested/appraisal-requested.component';
import { AppraisalBookerComponent } from '../common/appraisal-booker/appraisal-booker.component';
import { ConversionAddressFoundComponent } from '../common/conversion-address-found/conversion-address-found.component';
import { ConversionCaptureDetailsComponent } from '../common/conversion-capture-details/conversion-capture-details.component';
import { ConversionCapturePhoneNumberComponent } from '../common/conversion-capture-phone-number/conversion-capture-phone-number.component';
import { ConversionCompleteComponent } from '../common/conversion-complete/conversion-complete.component';
import { ConversionQuestionnaireComponent } from '../common/conversion-questionnaire/conversion-questionnaire.component';
import {
    addressResolveFn,
    propertyAnswerTokenResolveFn,
    propertyIdResolveFn,
    serviceStatusResolveFn,
    stepResolveFn,
    submissionErrorResolveFn,
    userResolveFn,
} from '../common/resolvers';
import { NcConversionFunnelStateDeclaration } from '../conversion.states';

import { CalendarFunnelEnterAddressComponent } from './calendar-funnel-enter-address/calendar-funnel-enter-address.component';
import { CalendarFunnelComponent } from './calendar-funnel.component';

const totalSteps = 6;

export function funnelNameResolveFn() {
    return 'calendar';
}

export function appraisalRequestedResolveFn(trans) {
    return trans.params().appraisalAlreadyRequested;
}

export function calendarTimeUnavailableResolveFn(transition: Transition): boolean {
    return transition.params().calendarTimeUnavailable;
}

export async function agentCalendarRedirectFn(transition: Transition): Promise<string> {
    // where 'agentCalendar' refers to the token for the 'hasAgentCalendarResolveFn' resolver function below
    const hasAgentCalendar: boolean = await transition.injector().getAsync('hasAgentCalendar');
    // either we successfully get the agent calendar and they can go through the calendar funnel, or we redirect them
    // to the regular appraisal flow
    return hasAgentCalendar ? 'conversion.calendar.book-time' : 'conversion.booking';
}

export function hasAgentCalendarResolveFn(
    transition: Transition,
    conversionFacade: ConversionFacade,
): Promise<boolean> {
    const slug = transition.params().agentSlug;
    conversionFacade.getAgentCalendarBySlug(slug);
    return conversionFacade.appraisalState$
        .pipe(
            filter(({ isLoadingAgent }) => !isLoadingAgent),
            // return true if we have an agent calendar (ie. no error loading the calendar)
            map(({ loadingAgentError }) => !loadingAgentError),
            take(1),
        )
        .toPromise();
}

export const calendarFunnelStates: NcConversionFunnelStateDeclaration[] = [
    {
        name: 'conversion.calendar',
        url: '/calendar/{agentSlug}',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            conversion: {
                component: CalendarFunnelComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
        redirectTo: agentCalendarRedirectFn,
        params: {
            agentSlug: undefined,
        },
        resolve: [
            {
                token: 'hasAgentCalendar',
                deps: [Transition, ConversionFacade],
                resolveFn: hasAgentCalendarResolveFn,
            },
        ],
        data: {
            meta: {
                title: 'Free Property Market Appraisal',
                description: getStandardUpsideMetaDescription(),
            },
        },
    },
    {
        name: 'conversion.calendar.book-time',
        url: '/book-time',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            calendar: {
                component: AppraisalBookerComponent,
            },
        },
        params: {
            step: 1,
            calendarTimeUnavailable: false,
            submissionError: '',
            appraisalAlreadyRequested: false,
        },
        resolve: [
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
            {
                token: 'calendarTimeUnavailable',
                deps: [Transition],
                resolveFn: calendarTimeUnavailableResolveFn,
            },
            {
                token: 'submissionError',
                deps: [Transition],
                resolveFn: submissionErrorResolveFn,
            },
            {
                token: 'appraisalAlreadyRequested',
                deps: [Transition],
                resolveFn: appraisalRequestedResolveFn,
            },
        ],
        data: {
            globalProgress: 1 / totalSteps,
        },
    },
    {
        name: 'conversion.calendar.address',
        url: '/address',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            calendar: {
                component: CalendarFunnelEnterAddressComponent,
            },
        },
        params: {
            step: 2,
        },
        resolve: [
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
        ],
        data: {
            globalProgress: 2 / totalSteps,
        },
    },
    {
        name: 'conversion.calendar.address-found',
        url: '/address-found',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            calendar: {
                component: ConversionAddressFoundComponent,
            },
        },
        params: {
            address: undefined,
            step: 3,
        },
        resolve: [
            {
                token: 'address',
                deps: [Transition],
                resolveFn: addressResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
        ],
        data: {
            globalProgress: 3 / totalSteps,
        },
    },
    {
        name: 'conversion.calendar.capture-details',
        url: '/details',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            calendar: {
                component: ConversionCaptureDetailsComponent,
            },
        },
        params: {
            user: undefined,
            address: undefined,
            step: 4,
        },
        resolve: [
            {
                token: 'user',
                deps: [Transition],
                resolveFn: userResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
            {
                token: 'address',
                deps: [Transition],
                resolveFn: addressResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
        ],
        data: {
            globalProgress: 4 / totalSteps,
        },
    },
    {
        name: 'conversion.calendar.phone',
        url: '/contact',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            calendar: {
                component: ConversionCapturePhoneNumberComponent,
            },
        },
        params: {
            user: undefined,
            step: 4,
        },
        resolve: [
            {
                token: 'user',
                deps: [Transition],
                resolveFn: userResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
        ],
        data: {
            globalProgress: 4 / totalSteps,
        },
    },
    {
        name: 'conversion.calendar.complete',
        url: '/complete',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            calendar: {
                component: ConversionCompleteComponent,
            },
        },
        params: {
            user: undefined,
            showCompleteState: false,
            serviceStatus: undefined,
        },
        resolve: [
            {
                token: 'user',
                deps: [Transition],
                resolveFn: userResolveFn,
            },
            {
                token: 'serviceStatus',
                deps: [Transition],
                resolveFn: serviceStatusResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
        ],
        data: {
            globalProgress: 5 / totalSteps,
        },
    },
    {
        name: 'conversion.calendar.questionnaire',
        url: '/questionnaire',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            calendar: {
                component: ConversionQuestionnaireComponent,
            },
        },
        params: {
            address: undefined,
            propertyAnswerToken: undefined,
            propertyId: undefined,
            step: 5,
        },
        resolve: [
            {
                token: 'address',
                deps: [Transition],
                resolveFn: addressResolveFn,
            },
            {
                token: 'propertyAnswerToken',
                deps: [Transition],
                resolveFn: propertyAnswerTokenResolveFn,
            },
            {
                token: 'propertyId',
                deps: [Transition],
                resolveFn: propertyIdResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
            {
                token: 'step',
                deps: [Transition],
                resolveFn: stepResolveFn,
            },
        ],
        data: {
            globalProgress: 5 / totalSteps,
        },
    },
    {
        name: 'conversion.calendar.requested',
        url: '/requested',
        views: {
            'navbar@vendor-app': {
                component: NavMinimalComponent,
            },
            calendar: {
                component: AppraisalRequestedComponent,
            },
            'footer@vendor-app': {
                component: FooterMegaComponent,
            },
        },
        params: {
            propertyId: undefined,
        },
        resolve: [
            {
                token: 'propertyId',
                deps: [Transition],
                resolveFn: propertyIdResolveFn,
            },
            {
                token: 'funnelName',
                resolveFn: funnelNameResolveFn,
            },
        ],
        data: {
            globalProgress: 6 / totalSteps,
        },
    },
];
