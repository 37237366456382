import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiSuccessResponse } from '../../../common/models/common.model';
import { CrmIdentityDto } from '../../../common/models/dto/user/crm-identity.dto';
import { TriggerUserVaultImportDto } from '../../../common/models/dto/user/trigger-user-vault-import.dto';
import { UpdateCrmUserDto } from '../../../common/models/dto/user/update-crm-user.dto';
import { UserSubscriptionDto } from '../../../common/models/dto/user/user-subscription.dto';
import { UserDto } from '../../../common/models/dto/user/user.dto';
import { interaction } from '../../../common/models/interaction.model';
import { userApi } from '../../../common/models/user-api.model';
import { user } from '../../../common/models/user.model';

const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};
const BASE_URL = '/api/v2/user';
export const FORGOT_PASSWORD_URL = `${BASE_URL}/forgot-password`;
export const SIGN_OUT_URL = `${BASE_URL}/signout`;
export const SIGN_IN_URL = `${BASE_URL}/signin`;
export const USER_AUTH_URL = `${BASE_URL}/current`;
export const SIGN_IN_GOOGLE_URL = `${BASE_URL}/googleSignIn`;
export const RESET_PASSWORD_URL = `${BASE_URL}/reset-password`;
export const SIGN_UP_URL = `${BASE_URL}/signup`;
export const SIGN_UP_WITH_ROLES = `${SIGN_UP_URL}-with-roles`;
export const signInAsConciergeUrlSegment = `signInAsConcierge`;
export const signInAsUserUrl = (userId: string) => `${BASE_URL}/${userId}/signInAsUser`;

@Injectable()
export class UserResource {
    constructor(private http: HttpClient) {}

    public current() {
        return this.http.get<userApi.Current.GET.Response>(USER_AUTH_URL, HEADERS_CONFIG);
    }

    public getUser(userId: string): Observable<CrmIdentityDto> {
        return this.http.get<CrmIdentityDto>(`${BASE_URL}/${userId}`, HEADERS_CONFIG);
    }
    // Signout interceptor needs to be included
    public signOut(body = null) {
        return this.http.post(SIGN_OUT_URL, body, HEADERS_CONFIG);
    }

    public signIn(authData: userApi.signIn.post.Request): Observable<userApi.signIn.post.Response> {
        return this.http.post<userApi.signIn.post.Response>(SIGN_IN_URL, authData, HEADERS_CONFIG);
    }

    public signInGoogle(tokenObject) {
        return this.http.post<userApi.googleSignIn.post.Response>(
            SIGN_IN_GOOGLE_URL,
            { ...tokenObject, clientType: 'browser' },
            HEADERS_CONFIG,
        );
    }

    public confirmRegisteredEmail(
        req: userApi.confirmRegisteredEmail.get.Request,
    ): Observable<userApi.confirmRegisteredEmail.get.Response> {
        return this.http.get<userApi.confirmRegisteredEmail.get.Response>(
            `${BASE_URL}/check/${req.email}/`,
            HEADERS_CONFIG,
        );
    }

    public requestShadowPropertyReport({
        address,
        phoneNumber,
        email,
        utm,
        firstName,
        lastName,
        propertyDetails,
    }: userApi.Shadow.Report.POST.Request): Observable<HttpResponse<userApi.Shadow.Report.POST.Response>> {
        return this.http.post(
            `${BASE_URL}/shadow/report`,
            {
                address,
                phoneNumber,
                email,
                utm,
                firstName,
                lastName,
                propertyDetails,
            },
            { ...HEADERS_CONFIG, observe: 'response' },
        );
    }

    public forgotPassword({ email }) {
        return this.http.post(
            FORGOT_PASSWORD_URL,
            {
                email,
            },
            HEADERS_CONFIG,
        );
    }

    public resetPassword({ password, token }) {
        return this.http.post(
            RESET_PASSWORD_URL,
            {
                password,
                token,
            },
            HEADERS_CONFIG,
        );
    }

    public signUpFromShadow({ password, token }) {
        return this.http.post(
            SIGN_UP_URL,
            {
                password,
            },
            { ...HEADERS_CONFIG, params: new HttpParams().set('token', token) },
        );
    }

    public checkToken({ token }): Observable<userApi.checkToken.get.Response> {
        return this.http.get<userApi.checkToken.get.Response>(
            `${BASE_URL}/shadow/check?token=${token}`,
            HEADERS_CONFIG,
        );
    }

    public setPassword(id: string, password: string): Observable<userApi.setPassword.post.Response> {
        return this.http.post<userApi.setPassword.post.Response>(
            `${BASE_URL}/${id}/set-password`,
            { password },
            HEADERS_CONFIG,
        );
    }

    public signUpWithRoles(
        user: userApi.SignUpWithRoles.POST.Request,
    ): Observable<userApi.SignUpWithRoles.POST.Response> {
        return this.http.post<userApi.SignUpWithRoles.POST.Response>(
            `${BASE_URL}/signup-with-roles`,
            user,
            HEADERS_CONFIG,
        );
    }

    public triggerBuyerAdvantageSmsNotification(
        propertyId: string,
    ): Observable<userApi.notifications.triggerSms.post.Response> {
        return this.http.post<userApi.notifications.triggerSms.post.Response>(
            `${BASE_URL}/notifications/sms/${propertyId}`,
            {},
            HEADERS_CONFIG,
        );
    }

    public myShadow(userToken: string): Observable<userApi.myShadow.get.Response> {
        return this.http.get<userApi.myShadow.get.Response>(`${BASE_URL}/myShadow?token=${userToken}`, HEADERS_CONFIG);
    }

    public getNotificationsPreferences(): Observable<userApi.notifications.preferences.get.Response> {
        return this.http.get<userApi.notifications.preferences.get.Response>(
            `${BASE_URL}/notifications/preferences`,
            HEADERS_CONFIG,
        );
    }

    public putNotificationsPreferences(
        notifications: userApi.notifications.preferences.put.Request,
    ): Observable<userApi.notifications.preferences.put.Response> {
        return this.http.put<userApi.notifications.preferences.put.Response>(
            `${BASE_URL}/notifications/preferences`,
            notifications,
            HEADERS_CONFIG,
        );
    }

    public getNotificationsPreferencesWithToken(
        token: string,
    ): Observable<userApi.notifications.preferences.token.get.Response> {
        return this.http.get<userApi.notifications.preferences.token.get.Response>(
            `${BASE_URL}/notifications/preferences/token/${token}`,
            HEADERS_CONFIG,
        );
    }

    public putNotificationsPreferencesWithToken(
        token: string,
        notificationsPreferences: userApi.notifications.preferences.token.put.Request,
    ): Observable<userApi.notifications.preferences.token.put.Response> {
        return this.http.put<userApi.notifications.preferences.token.put.Response>(
            `${BASE_URL}/notifications/preferences/token/${token}`,
            notificationsPreferences,
            HEADERS_CONFIG,
        );
    }

    public getNotificationsPreferencesForUser(userId: string) {
        return this.http.get<userApi.notifications.preferences.User.GET.Response>(
            `${BASE_URL}/${userId}/notifications/preferences`,
            HEADERS_CONFIG,
        );
    }

    public updateNotificationsPreferencesForUser(
        userId: string,
        notificationsPreferences: userApi.notifications.preferences.User.PUT.Request,
    ) {
        return this.http.put<userApi.notifications.preferences.User.PUT.Response>(
            `${BASE_URL}/${userId}/notifications/preferences`,
            notificationsPreferences,
            HEADERS_CONFIG,
        );
    }

    public postNotificationsSubscribe(
        notificationsPreferences: userApi.notifications.subscribe.post.Request,
    ): Observable<userApi.notifications.subscribe.post.Response> {
        return this.http.post<userApi.notifications.subscribe.post.Response>(
            `${BASE_URL}/notifications/subscribe`,
            notificationsPreferences,
            HEADERS_CONFIG,
        );
    }

    public postNotificationsUnsubscribe(): Observable<userApi.notifications.unsubscribe.post.Response> {
        return this.http.post<userApi.notifications.unsubscribe.post.Response>(
            `${BASE_URL}/notifications/unsubscribe`,
            {},
            HEADERS_CONFIG,
        );
    }

    public postNotificationUnsubscribeWithToken(
        token: string,
    ): Observable<userApi.notifications.unsubscribe.token.post.Response> {
        return this.http.post<userApi.notifications.unsubscribe.token.post.Response>(
            `${BASE_URL}/notifications/unsubscribe/token/${token}`,
            {},
            HEADERS_CONFIG,
        );
    }

    public addRole(userId: string, role: user.Role): Observable<userApi.addRole.post.Response> {
        return this.http.post<userApi.addRole.post.Response>(
            `${BASE_URL}/${userId}/role/${role}/add`,
            undefined,
            HEADERS_CONFIG,
        );
    }

    public removeRole(userId: string, role: user.Role): Observable<userApi.removeRole.post.Response> {
        return this.http.post<userApi.removeRole.post.Response>(
            `${BASE_URL}/${userId}/role/${role}/remove`,
            undefined,
            HEADERS_CONFIG,
        );
    }

    public smsVerify(
        userId: string,
        phoneNumber: string,
        areaCode?: string,
    ): Observable<userApi.smsVerify.post.Response> {
        return this.http.post<userApi.smsVerify.post.Response>(
            `${BASE_URL}/sms-verify`,
            <userApi.smsVerify.post.Request>{
                userId,
                areaCode,
                phoneNumber,
            },
            HEADERS_CONFIG,
        );
    }

    public smsVerifyCheck(
        userId: string,
        code: string,
        phoneNumber: string,
        areaCode?: string,
    ): Observable<userApi.smsVerifyCheck.post.Response> {
        return this.http.post<userApi.smsVerifyCheck.post.Response>(
            `${BASE_URL}/sms-verify-check`,
            <userApi.smsVerifyCheck.post.Request>{
                userId,
                areaCode,
                code,
                phoneNumber,
            },
            HEADERS_CONFIG,
        );
    }

    public updateUser(userId: string, user: userApi.updateUser.post.Request): Observable<CrmIdentityDto> {
        return this.http.put<CrmIdentityDto>(`${BASE_URL}/${userId}`, user, HEADERS_CONFIG);
    }

    public updateCrmUser(userId: string, user: UpdateCrmUserDto): Observable<CrmIdentityDto> {
        return this.http.put<CrmIdentityDto>(`${BASE_URL}/${userId}/crm`, user, HEADERS_CONFIG);
    }

    public isCustomer(userId: string) {
        return this.http.get<userApi.isCustomer.get.Response>(`${BASE_URL}/${userId}/customer/`, HEADERS_CONFIG);
    }

    public manualPhoneVerification(userId: string) {
        return this.http.post<userApi.manualPhoneVerify.post.Response>(
            `${BASE_URL}/phone-verify-manual/${userId}`,
            undefined,
            HEADERS_CONFIG,
        );
    }

    public signInAsUser(userId: string) {
        return this.http.post<userApi.signInAsUser.post.Response>(signInAsUserUrl(userId), undefined, HEADERS_CONFIG);
    }

    public interaction(
        userId: string,
        options: {
            propertyId?: string;
            state?: interaction.State;
            includeHistory?: boolean;
        } = {},
    ) {
        return this.http.get<userApi.interactions.get.Response>(`${BASE_URL}/${userId}/interactions/`, {
            ...HEADERS_CONFIG,
            params: { ...options, includeHistory: String(!!options?.includeHistory) },
        });
    }

    public getSubscription(userId: string): Observable<UserSubscriptionDto> {
        return this.http.get<UserSubscriptionDto>(`${BASE_URL}/${userId}/subscription`);
    }

    public updateSubscription(userId: string, body: UserSubscriptionDto): Observable<UserSubscriptionDto> {
        return this.http.post<UserSubscriptionDto>(`${BASE_URL}/${userId}/subscription`, body);
    }

    public getUserAssociations(userId: string) {
        return this.http.get<userApi.UserAssociations.Get.Response>(`${BASE_URL}/associations/${userId}`);
    }

    public signInAsConcierge(conciergeId: string, teamId: string): Observable<UserDto> {
        return this.http.post<UserDto>(`${BASE_URL}/${conciergeId}/${signInAsConciergeUrlSegment}`, { teamId });
    }

    public triggerVaultImport(userId: string, body: TriggerUserVaultImportDto): Observable<ApiSuccessResponse> {
        return this.http.post<ApiSuccessResponse>(`${BASE_URL}/${userId}/vault/trigger-import`, body);
    }
}
