import { UpStateDeclaration } from '../../common/models/router.model';
import { NoopComponent } from '../../components/noop/noop.component';

import { BrochureComponent } from './components/brochure/brochure.component';

export const brochureStates: UpStateDeclaration[] = [
    {
        name: 'brochure',
        url: '/brochure',
        parent: 'vendor',
        abstract: true,
        views: {
            'navbar@vendor-app': {
                component: NoopComponent,
            },
            'content@vendor-app': {
                component: BrochureComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
        data: {
            meta: {
                title: 'Property Brochure',
            },
        },
    },
    {
        name: 'brochure.preview',
        url: '/preview/{propertyId}',
        params: {
            propertyId: null,
        },
    },
    {
        name: 'brochure.view',
        url: '/{token}',
        params: {
            token: null,
        },
    },
];
