import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SuburbResource } from '../../../modules/core/resources/suburb.resource';
import { UseCase } from '../../models/core/use-case.model';
import { SuburbDetails } from '../../models/suburb-details.model';

export interface Output {
    all: SuburbDetails[];
    profiled: SuburbDetails[];
}

@Injectable({ providedIn: 'root' })
export class GetSuburbsUseCase implements UseCase<Output> {
    constructor(private suburbResource: SuburbResource) {}

    public execute(): Observable<Output> {
        return this.suburbResource.getSuburbs().pipe(
            map(suburbs => ({
                all: suburbs,
                profiled: suburbs.filter(suburb => suburb.isProfile),
            })),
        );
    }
}
