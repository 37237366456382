import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DemonstrationApi } from '../../../common/models/demonstration-api.model';
import { user } from '../../../common/models/user.model';

const baseUrl = '/api/v2/demo';
export const impersonateBaseUrl = `${baseUrl}/impersonate`;

@Injectable()
export class DemonstrationResource {
    constructor(private httpClient: HttpClient) {}

    public impersonateBusinessUser(countryState: string, primaryRole: user.Role) {
        return this.httpClient.get<DemonstrationApi.ImpersonateBusinessUser.GET.Response>(
            `${impersonateBaseUrl}/state/${countryState}/role/${primaryRole}`,
        );
    }

    public getAvailableStatesAndRoles() {
        return this.httpClient.get<DemonstrationApi.AvailableStatesAndRoles.GET.Response>(
            `${baseUrl}/available-states-and-roles`,
        );
    }
}
