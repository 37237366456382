import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'nc-check-in-card',
    templateUrl: 'check-in-card.component.html',
    styleUrls: ['check-in-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckInCardComponent {
    @HostBinding('class.is-full-screen-@xs')
    @Input('fullScreen@xs')
    public isFullScreenAtXs = false;

    @Input() public loading: boolean;
}
