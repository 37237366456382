import { AddressT } from './address.model';
import { Business } from './business.model';
import { BusinessDto } from './dto/business/business.dto';
import { media } from './media.model';
import { PropertyType } from './property.model';

export namespace user {
    export enum Role {
        Staff = 'staff',
        Admin = 'admin',
        /**@deprecated*/
        Finance = 'finance',
        Vendor = 'vendor',
        Buyer = 'buyer',
        SalesManager = 'salesManager',
        Agent = 'agent',
        User = 'user',
        SalesAdmin = 'salesadmin',
        Supplier = 'supplier',
        SalesAssociate = 'salesassociate',
        Concierge = 'concierge',
        ConciergeAdmin = 'conciergeadmin',
        Demo = 'demo',
        OrgAdmin = 'orgadmin',
        AgencyAdmin = 'agencyadmin',
    }

    export enum BuyerTypes {
        Buyer = 'Buyer',
        Seller = 'Seller',
        Investor = 'Investor',
        Other = 'Other',
    }

    export enum DevelopmentPreference {
        All = 'All',
        Existing = 'Existing',
        New = 'New',
    }

    export enum OtherContactTag {
        Home = 'Home',
        Work = 'Work',
        Mobile = 'Mobile',
        Partner = 'Partner',
        Postal = 'Postal',
        Direct = 'Direct',
        Fax = 'Fax',
        Name = 'Name',
        Other = 'Other',
    }

    export interface OtherContact {
        content: string;
        comment?: string;
        tag?: OtherContactTag;
    }

    export interface OtherStructuredAddress {
        address: AddressT;
        tag?: OtherContactTag;
    }

    export interface OtherNameContact {
        firstName: string;
        lastName: string;
        tag?: OtherContactTag;
        title?: string;
    }

    export interface Notifications {
        propertyPreference: {
            bathroomsMin?: number;
            bedroomsMin?: number;
            developmentPreference: 'All' | 'Existing' | 'New';
            parkingMin?: number;
            priceMin?: number;
            priceMax?: number;
            propertyTypes: PropertyType[];
        };
        sendListingNotification: boolean;
        sendJustSoldNotification: boolean;
        sendWeeklyEmail: boolean;
        suburbs: string[];
        user?: UserBase;
        userTypes: BuyerTypes[];
        subscribedAt?: string;
    }

    interface CrmDetails {
        crmFirstName?: string;
        crmLastName?: string;
        legalName?: string;
        otherNames?: OtherNameContact[];
        displayName?: string;
        crmPhoneNumber?: string;
        crmPhoneNumberAreaCode?: string;
        primaryStructuredAddress?: AddressT;
        otherStructuredAddresses?: OtherStructuredAddress[];
        otherEmails?: OtherContact[];
        otherPhoneNumbers?: OtherContact[];
        dateOfBirth?: string;
        company?: string;
        greeting?: string;
        doNotContact?: boolean;
    }

    /** @Deprecated - use CrmUser in /models/domain/user/crm-user.model.ts instead **/
    export interface CrmUser extends InternalUser, CrmDetails {
        crmPhoneNumberTag?: string;
        crmPhoneNumberComment?: string;
        platform?: boolean;
        phoneVerified?: boolean;
        myDesktopExternalIds?: string[];
        vaultExternalIds?: string[];
        businessContactType?: Business.ContactType;
        archived?: boolean;
    }

    export interface BusinessContact extends CrmDetails {
        id: string;
        userId: string;
        business?: Business;
        businessContactType?: Business.ContactType;
        accessibleBy?: string[];
        externalIds?: Business.ExternalContactIds;
    }

    export interface Subscription {
        allEmail?: boolean;
        marketingEmail?: boolean;
        weeklySuburbEmail?: boolean;
        listingNotificationSms?: boolean;
        justSoldNotification?: boolean;
    }

    export interface Minimal {
        id: string;
        name: string; // platform name
    }
}

export interface UserBase {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
}

export interface User extends UserBase {
    address: AddressT;
    utm: any;
}

export interface NewUser extends UserBase {
    roles: user.Role[];
}

export interface InternalUser {
    email?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    id?: string;
    created?: string;
    authenticated?: boolean;
    emailConfirmed?: boolean;
    roles: user.Role[];
    avatar?: media.File;
    business?: BusinessDto;
}
