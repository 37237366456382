import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    DoCheck,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { CanUpdateErrorState, ErrorStateMatcher, mixinErrorState } from '@angular/material/core';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash-es';
import { BehaviorSubject, Observable, of, combineLatest, timer, Subject } from 'rxjs';
import { catchError, debounce, distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators';

import { AddressT } from '../../../../common/models/address.model';
import { BuyerLocality } from '../../../../common/models/domain/buyer/buyer-locality.model';
import { doTranslation } from '../../../../common/utilities/i18n/do-translation.util';
import { mapSome } from '../../../../operators/map-some.operator';
import { replay } from '../../../../operators/replay/replay.operator';
import { SuburbsFacade } from '../../../../store/suburbs/suburbs.facade';
import { NotificationService } from '../../../core/services/notification.service';
import {
    AddressSearchResult,
    LocalitySearchResult,
    LocalitySearchResults,
    SuburbSearchResult,
} from '../../models/locality-search-results.model';
import { GetAddressDetailsUseCase } from '../../use-cases/get-address-details/get-address-details.use-case';
import { GetLocalitySearchResultsUseCase } from '../../use-cases/get-locality-search-results/get-locality-search-results.use-case';

class MatLocalityAutocompleteBase {
    public stateChanges: Subject<void>;

    constructor(
        public _elementRef: ElementRef,
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl,
    ) {}
}
const MatLocalityAutocompleteMixinBase = mixinErrorState(MatLocalityAutocompleteBase);

@UntilDestroy()
@Component({
    selector: 'nc-mat-locality-autocomplete',
    templateUrl: 'mat-locality-autocomplete.component.html',
    styleUrls: ['mat-locality-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: MatLocalityAutocompleteComponent,
        },
    ],
})
export class MatLocalityAutocompleteComponent
    extends MatLocalityAutocompleteMixinBase
    implements
        MatFormFieldControl<BuyerLocality.Model | undefined>,
        OnInit,
        ControlValueAccessor,
        OnDestroy,
        CanUpdateErrorState,
        DoCheck,
        AfterViewInit {
    public readonly isLoadingSearchResults$ = new BehaviorSubject(false);
    public readonly value$ = new BehaviorSubject<BuyerLocality.Model | undefined>(undefined);
    public readonly trackSearchResultBy = (index: number, searchResult: LocalitySearchResult) => searchResult.id;
    public readonly isDisabled$ = new BehaviorSubject(false);
    public suburbSearchResults$: Observable<SuburbSearchResult[]>;
    public addressSearchResults$: Observable<AddressSearchResult[]>;
    public isLoading$: Observable<boolean>;
    private readonly isLoadingAddressDetails$ = new BehaviorSubject(false);
    private readonly searchDebounceTimeMs = 300;
    private readonly isManuallyDisabled$ = new BehaviorSubject(false);
    private readonly searchQueryChange$ = new BehaviorSubject('');
    private readonly addressSelectionChange$ = new BehaviorSubject<AddressSearchResult>(undefined);
    @HostBinding() public id = `nc-mat-locality-autocomplete-${MatLocalityAutocompleteComponent.nextId++}`;
    public readonly stateChanges = new Subject<void>();
    public focused = false;
    @Input('aria-describedby') public userAriaDescribedBy: string;
    private onChange: (selection?: BuyerLocality.Model) => void;
    private onTouched: Function;
    @ViewChild('queryInput') private queryInput: ElementRef<HTMLInputElement>;
    @ViewChild(MatAutocomplete) private matAutocomplete: MatAutocomplete;
    private _placeholder: string;
    private _required: boolean;
    private static nextId = 0;

    @Input()
    public get value(): BuyerLocality.Model | undefined {
        return this.value$.value;
    }

    public set value(selection: BuyerLocality.Model | undefined) {
        this.writeValue(selection);
        this.stateChanges.next();
    }

    @Input()
    public get placeholder(): string {
        return this._placeholder;
    }
    public set placeholder(placeholder: string) {
        this._placeholder = placeholder;
        this.stateChanges.next();
    }

    public get empty(): boolean {
        return !this.queryInput?.nativeElement.value;
    }

    public get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    @Input()
    public get required(): boolean {
        return this._required;
    }

    public set required(required: boolean) {
        this._required = coerceBooleanProperty(required);
        this.stateChanges.next();
    }

    @Input()
    public get disabled(): boolean {
        return this.isDisabled$.value;
    }

    public set disabled(disabled: boolean) {
        const isDisabled = coerceBooleanProperty(disabled);
        this.setDisabledState(isDisabled);
    }

    constructor(
        @Optional() @Self() public readonly ngControl: NgControl,
        @Optional() public readonly parentFormGroup: FormGroupDirective,
        @Optional() private readonly parentForm: NgForm,
        private readonly elementRef: ElementRef,
        private readonly getLocalitySearchResultsUseCase: GetLocalitySearchResultsUseCase,
        private readonly getAddressDetailsUseCase: GetAddressDetailsUseCase,
        private readonly defaultErrorStateMatcher: ErrorStateMatcher,
        private readonly notificationService: NotificationService,
        private readonly suburbsFacade: SuburbsFacade,
    ) {
        super(elementRef, defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);

        if (!isNil(this.ngControl)) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }

    public ngOnInit(): void {
        this.fetchAndTriggerChangeWithAddressDetails();
        this.setupLoadingState();
        this.setupSearchResults();
        this.setupDisabledState();
        this.handleValueChanges();
    }

    public ngOnDestroy(): void {
        this.stateChanges.complete();
    }

    public ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    public setDisabledState(isDisabled: boolean) {
        if (isDisabled) {
            this.isManuallyDisabled$.next(true);
        } else {
            this.isManuallyDisabled$.next(false);
        }

        this.stateChanges.next();
    }

    public writeValue(value?: BuyerLocality.Model): void {
        const nextValue = (() => {
            if (!value) return undefined;

            if (!value.address && !value.suburbId) {
                throw Error('Invalid locality value');
            }

            return value;
        })();

        this.value$.next(nextValue);
    }

    public ngAfterViewInit(): void {
        this.updateQueryInputTextIfNeeded();
    }

    public onFocus(): void {
        this.focused = true;
        this.stateChanges.next();
    }

    public setDescribedByIds(ids: string[]) {
        this.userAriaDescribedBy = ids.join(' ');
    }

    public registerOnChange(fn: any) {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    public onContainerClick(event: MouseEvent): void {
        this.queryInput.nativeElement.focus();
        // Stop the click continuing to propagate away from the input which may
        // cause the autocomplete panel to close
        event.stopPropagation();
    }

    // Partial / non-search selection result values are not allowed, so when the autocomplete is
    // closed we should make sure to clear the text input so the user doesn't think their invalid
    // input is valid.
    public onAutocompleteClosed(): void {
        if (this.value$.value || this.isLoadingAddressDetails$.value) return;

        this.queryInput.nativeElement.value = '';
        this.searchQueryChange$.next('');

        this.stateChanges.next();
    }

    public onQueryInputChange(event: Event): void {
        const inputEvent = <InputEvent>event;
        const input = <HTMLInputElement>inputEvent.target;
        const value = <string | undefined | LocalitySearchResult>input.value;

        if (this.value$.value || !value) {
            input.value = inputEvent.data;
            this.searchQueryChange$.next(inputEvent.data);
            this.value$.next(undefined);
        } else if (typeof value === 'string') {
            this.searchQueryChange$.next(value);
        }
    }

    public onBlurInput(): void {
        this.onTouched?.();
        this.focused = false;
        this.stateChanges.next();
    }

    public onSelectSearchResult(searchResult: LocalitySearchResult): void {
        this.queryInput.nativeElement.value = searchResult.displayValue;

        if (searchResult.type === 'suburb') {
            this.suburbSelected(searchResult.id);
        } else {
            this.addressSelectionChange$.next(searchResult);
        }
    }

    private fetchAndTriggerChangeWithAddressDetails(): void {
        this.addressSelectionChange$
            .pipe(
                filter(selection => !!selection),
                tap(() => this.isLoadingAddressDetails$.next(true)),
                switchMap(selection =>
                    selection
                        ? this.getAddressDetailsUseCase.execute({ addressId: selection.id }).pipe(
                            this.notificationService.withNotification({
                                errorMessage: doTranslation('localityAutocomplete.errorLoadingAddressDetails'),
                            }),
                            catchError(() => of(undefined)),
                        )
                        : of(undefined),
                ),
            )
            .subscribe(address => {
                this.isLoadingAddressDetails$.next(false);
                this.addressSelected(address);
            });
    }

    private setupLoadingState(): void {
        this.isLoading$ = combineLatest([
            this.isLoadingAddressDetails$,
            this.isLoadingSearchResults$,
            this.suburbsFacade.isLoadingSuburbs$,
        ]).pipe(mapSome(is => is));
    }

    private setupSearchResults(): void {
        const emptyResult: LocalitySearchResults = {
            suburbs: [],
            addresses: [],
        };
        const searchResults$ = this.searchQueryChange$.pipe(
            distinctUntilChanged(),
            tap(query => {
                if (query) this.isLoadingSearchResults$.next(true);
            }),
            debounce(query => timer(!query ? 0 : this.searchDebounceTimeMs)),
            switchMap(query =>
                query
                    ? this.getLocalitySearchResultsUseCase.execute({ query }).pipe(
                        this.notificationService.withNotification({
                            errorMessage: doTranslation('localityAutocomplete.errorSearching'),
                        }),
                        catchError(() => of(emptyResult)),
                    )
                    : of(emptyResult),
            ),
            tap(() => this.isLoadingSearchResults$.next(false)),
            replay({ resetOnRefCountZero: false }),
        );

        this.suburbSearchResults$ = searchResults$.pipe(map(({ suburbs }) => suburbs));
        this.addressSearchResults$ = searchResults$.pipe(map(({ addresses }) => addresses));
    }

    private setupDisabledState(): void {
        // This is not great, but the Material control interface requires the disabled value on demand, so using a
        // derived Observable for isDisabled isn't feasible. Instead, we can update a BehaviorSubject which will allow
        // us to get the disabled value via the BehaviorSubject's `value` property while still being able to avoid
        // manually running change detection cycles.
        combineLatest([this.isManuallyDisabled$, this.isLoadingAddressDetails$, this.suburbsFacade.isLoadingSuburbs$])
            .pipe(mapSome(is => is))
            .subscribe(isDisabled => this.isDisabled$.next(isDisabled));
    }

    private handleValueChanges(): void {
        this.value$.pipe(untilDestroyed(this)).subscribe(selection => {
            this.onChange?.(selection);
            // Trigger a search query change to clear the results dropdown
            this.searchQueryChange$.next('');
        });
    }

    private suburbSelected(suburbId: string): void {
        this.value$.next({ suburbId });
    }

    private addressSelected(address: AddressT): void {
        this.value$.next({ address });
    }

    // Watches for changes to the value and sets the input text value if it hasn't already been
    // set, i.e. it was set from an external source rather than through the user selecting the value
    // in the dropdown.
    private updateQueryInputTextIfNeeded(): void {
        this.value$
            .pipe(
                filter(value => !!value && !this.queryInput.nativeElement.value),
                switchMap(value => {
                    if (value.address) {
                        return of(value.address.formattedAddress);
                    } else if (value.suburbId) {
                        this.suburbsFacade.loadSuburbsIfNeeded();

                        return this.suburbsFacade.allSuburbsWhenLoaded$.pipe(
                            map(allSuburbs => {
                                const matchingSuburb = allSuburbs?.find(suburb => suburb.id === value.suburbId);
                                const fallback = value.suburbId;

                                if (!matchingSuburb) return fallback;

                                return (
                                    [matchingSuburb.name, matchingSuburb.governingDistrict, matchingSuburb.postalArea]
                                        .filter(has => !!has)
                                        .join(' ') || fallback
                                );
                            }),
                            first(),
                        );
                    } else {
                        return of('');
                    }
                }),
            )
            .subscribe(displayValue => {
                this.queryInput.nativeElement.value = displayValue;
                this.stateChanges.next();
            });
    }
}
