// Todo (WEB-728): we need to fix this so it's not hard-coded both here and in media-queries.scss.

module.exports = {
    xxSmall: 321,
    xSmall: 600,
    small: 960,
    medium: 1280,
    large: 1920,
    // breakpoint when the internal nav tablet sidebar expands into the desktop version
    navSidebarExpanded: 1300,
};
