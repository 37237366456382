import { AfterContentInit, Component, ContentChild } from '@angular/core';

import { InputComponent } from '../forms/input/input.component';

@Component({
    selector: 'up-password-visibility-toggle',
    templateUrl: 'password-visibility-toggle.component.html',
    styleUrls: ['password-visibility-toggle.component.scss'],
})
export class PasswordVisibilityToggleComponent implements AfterContentInit {
    @ContentChild(InputComponent, { static: false }) private passwordComponentRef: InputComponent;

    public get passwordVisible(): boolean {
        return !!(this.passwordComponentRef && this.passwordComponentRef.type === 'text');
    }

    public ngAfterContentInit(): void {
        if (!this.passwordComponentRef || this.passwordComponentRef.type !== 'password') {
            throw new Error('Password input component must be provided to password visibility toggler!');
        }
    }

    public toggleVisibility(): void {
        this.passwordComponentRef.type = this.passwordVisible ? 'password' : 'text';
    }
}
