import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { logEvent, getAnalytics } from '@firebase/analytics';
import { first } from 'rxjs/operators';

import { EventResource } from '../../modules/core/resources/event.resource';
import { AnalyticsPage } from '../models/domain/analytics/analytics-page.model';

import { ErrorHandlerService } from './error-handler.service';
import { ExperimentService } from './experiment.service';
import { UserService } from './user.service';
import { UtmService } from './utm.service';
import { WindowRef } from './window.service';

interface TrackingEventErrorObject {
    data?: any;
    error: string;
}

@Injectable()
export class TrackingService {
    private firstLoad = true;

    private get experimentId(): string {
        return this.experimentService.experimentId;
    }

    constructor(
        private windowRef: WindowRef,
        private utmService: UtmService,
        private eventResource: EventResource,
        @Inject(PLATFORM_ID) private platformId: Object,
        private generalLogger: ErrorHandlerService,
        private experimentService: ExperimentService,
        private userService: UserService,
    ) {
        this.updateUserIdToDataLayer();
    }

    public trackAnalyticsPageEvent(eventName: AnalyticsPage): void {
        logEvent(getAnalytics(), 'page_view', {
            firebase_screen: eventName,
        });
    }

    public trackEvent(eventName: any): void {
        if (!isPlatformBrowser(this.platformId)) return;
        const body = this.generateTrackingBody();
        body.event_name = eventName;
        this.pushEventToDataLayer(eventName);
        this.eventResource.trackEvent(body).subscribe(
            () => {},
            e => this.generalLogger.warning(this.createTrackingEventErrorObject(e, eventName), 'Track event failed.'),
        );
    }

    public trackDataEvent(eventName: string, data: Object): void {
        if (!isPlatformBrowser(this.platformId)) return;
        const body = {
            event_name: eventName,
            ...this.generateTrackingBody(),
            ...data,
        };
        this.eventResource.trackEvent(body).subscribe(
            () => {},
            e => this.generalLogger.warning(this.createTrackingEventErrorObject(e, data), 'Track data event failed.'),
        );
    }

    public trackPageView(): void {
        if (!isPlatformBrowser(this.platformId)) return;
        this.eventResource.trackPageView(this.generateTrackingBody()).subscribe(
            () => {},
            e => this.generalLogger.warning(this.createTrackingEventErrorObject(e), 'Track page view failed.'),
        );
    }

    public trackUser(): void {
        if (!isPlatformBrowser(this.platformId)) return;
        if (this.windowRef.nativeWindow.dataLayer && this.experimentId) {
            this.windowRef.nativeWindow.dataLayer.push({ userId: this.experimentId });
        }
    }

    public trackTimeOnSite(): void {
        if (!isPlatformBrowser(this.platformId)) return;
        if (!this.firstLoad) return;
        this.firstLoad = false;

        setTimeout(() => this.trackEvent('stayed15seconds'), 15000);
        setTimeout(() => this.trackEvent('stayed30seconds'), 30000);
        setTimeout(() => this.trackEvent('stayed45seconds'), 45000);
        setTimeout(() => this.trackEvent('stayed60seconds'), 60000);
    }

    public generateTrackingBody(): any {
        if (!isPlatformBrowser(this.platformId)) return;
        const utm = this.utmService.getStoredUtmCodes();
        return {
            ...utm,
            referrer: this.windowRef.nativeWindow.document.referrer,
            userAgent: this.windowRef.nativeWindow.navigator.userAgent,
            host: this.windowRef.nativeWindow.location.host,
            pathname: this.windowRef.nativeWindow.location.pathname,
            query: this.windowRef.nativeWindow.location.search,
            userId: this.experimentId,
        };
    }

    public pushEventToDataLayer(eventName: string): void {
        if (isPlatformBrowser(this.platformId) && this.windowRef.nativeWindow.dataLayer) {
            this.userService.currentUserId$.pipe(first()).subscribe(user_id => {
                this.windowRef.nativeWindow.dataLayer.push({
                    userId: this.experimentId,
                    event: eventName,
                    user_id,
                });
            });
        }
    }

    private updateUserIdToDataLayer(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.userService.currentUserId$.subscribe(user_id => {
                if (!this.windowRef.nativeWindow.dataLayer) {
                    this.windowRef.nativeWindow.dataLayer = [];
                }

                this.windowRef.nativeWindow.dataLayer.push({ user_id });
            });
        }
    }

    private createTrackingEventErrorObject({ error }: HttpErrorResponse, data?: any): TrackingEventErrorObject {
        const errorMessage = (error && error.code) || 'Tracking service failed with unknown error.';

        let errorBody: TrackingEventErrorObject = {
            error: errorMessage,
        };
        if (data) {
            errorBody['data'] = data;
        }

        return errorBody;
    }
}
