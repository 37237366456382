import { isPlatformServer } from '@angular/common';
import { Injector, PLATFORM_ID } from '@angular/core';
import { UIRouter } from '@uirouter/core';

import { ErrorHandlerService } from '../../common/services/error-handler.service';
import { ServerRequestServiceDIToken } from '../../common/services/server-request.service';
import { CanonicalService } from '../../modules/core/services/canonical.service';
import { EnvironmentService } from '../../modules/core/services/environment.service';

/**
 * Add canonical tag to the head for pre-rendered page for SEO purposes
 * As recommended by Google, it doesn't hurt for all pages to have a canonical link to themselves, and is
 * recommended if the page uses query params. Since URLs with params are distinctly different, it's important
 * that the canonical link links to the "clean" version without params.
 *
 * Read more:
 * http://www.thesempost.com/using-rel-canonical-on-all-pages-for-duplicate-content-protection/
 */
export const prerenderCanonicalLinkUiRouterHooks = (router: UIRouter, injector: Injector) => {
    router.transitionService.onSuccess({}, () => {
        if (!isPlatformServer(injector.get(PLATFORM_ID))) return;

        const errorHandlerService: ErrorHandlerService = injector.get(ErrorHandlerService);
        const environmentService = injector.get(EnvironmentService);
        const { host } = environmentService.config.organisation;
        const canonicalService: CanonicalService = injector.get(CanonicalService);

        try {
            const serverRequestService = injector.get(ServerRequestServiceDIToken);
            const path = serverRequestService.url === '/' ? '' : serverRequestService.url;
            const pathWithoutQueryStrings = path.split('?')[0];

            canonicalService.setCanonicalLink(`${host}${pathWithoutQueryStrings}`);
        } catch (error) {
            errorHandlerService.handleError(error);
        }
    });
};
