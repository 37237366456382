import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TrackingModule } from '../../directive/tracking.module';
import { IconModule } from '../icon/icon.module';

import { TabComponent } from './tab/tab.component';
import { TabsComponent } from './tabs.component';

@NgModule({
    imports: [CommonModule, IconModule, TrackingModule],
    exports: [TabsComponent],
    declarations: [TabsComponent, TabComponent],
})
export class TabsModule {}
