import { ChangeDetectionStrategy, Component } from '@angular/core';
import moment from 'moment';

import { footer } from '../../../common/models/footer.model';
import { FeatureService } from '../../../modules/core/services/feature.service';

@Component({
    selector: 'up-footer-copyright',
    templateUrl: './footer-copyright.component.html',
    styleUrls: ['./footer-copyright.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterCopyrightComponent {
    public currentYear = moment().year();

    public get primaryLinks(): footer.Link[] {
        const upsideMarketingPagesEnabled = this.featureService.hasFeature('upsideMarketingPagesEnabled');
        return [
            upsideMarketingPagesEnabled
                ? {
                    name: 'Privacy',
                    uiSref: 'privacy-policy',
                    trackingEvent: 'footerPrivacyPolicy',
                }
                : {
                    name: 'Privacy',
                    href: this.featureService.urlConfiguration.privacyPolicyPage,
                },
            upsideMarketingPagesEnabled
                ? {
                    name: 'Terms',
                    uiSref: 'terms-and-conditions',
                    trackingEvent: 'footerTermsAndConditions',
                }
                : {
                    name: 'Terms',
                    href: this.featureService.urlConfiguration.termsAndConditionsPage,
                },
        ].filter(v => !!v);
    }

    constructor(private featureService: FeatureService) {}
}
