import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { SharedVendorModule } from '../shared-vendor/shared-vendor.module';

import { DemoSplashPageComponent } from './components/demo-splash-page/demo-splash-page.component';
import { demonstrationStates } from './demonstration.states';

@NgModule({
    imports: [
        SharedVendorModule,
        UIRouterModule.forChild({
            states: demonstrationStates,
        }),
    ],
    declarations: [DemoSplashPageComponent],
})
export class DemonstrationModule {}
