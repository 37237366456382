<div
    class="wrapper"
    [ngClass]="{
        'is-x-small': size === 'x-small',
        'is-small': size === 'small',
        'is-large': size === 'large',
        'is-x-large': size === 'x-large',
        'is-shape-rounded-square': shape === 'rounded-square'
    }"
    [style.color]="color$ | async"
>
    <div class="background" [style.opacity]="style === 'faded' ? 0.1 : 1"> </div>
    <span [style.color]="(autoIconWrapperColor$ | async) ? (autoIconWrapperColor$ | async | upColor : 'cssVar') : null">
        <ng-content></ng-content>
    </span>
</div>
