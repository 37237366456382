<button
    [attr.aria-expanded]="disabled ? undefined : state === 'expanded'"
    [attr.aria-controls]="disabled ? undefined : 'expander-' + uid"
    [id]="'trigger-' + uid"
    [disabled]="disabled"
    type="button"
    (click)="onTriggerClick()"
    class="trigger u-display--block"
>
    <div class="header-content"> <ng-content select="[header], [slot='header']"></ng-content> </div>
</button>
<div
    [attr.aria-labelledby]="disabled ? undefined : 'trigger-' + uid"
    class="expander"
    [class.is-interactable]="expanderInteractable"
    [@collapse]="animationState"
    (@collapse.done)="onCollapseAnimDone()"
    (@collapse.start)="onCollapseAnimStart()"
    [id]="'expander-' + uid"
>
    <div class="inner-content"> <ng-content select="[content], [slot='content']"></ng-content> </div>
</div>
