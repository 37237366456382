import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { OwnersDto } from '../../../common/models/dto/property/owners.dto';
import { SimpleUpdateUserRequestDto } from '../../../common/models/dto/user/simple-update-user-request.dto';

@Injectable({ providedIn: 'root' })
export class PropertyAdditionalOwnersResource {
    private readonly baseUrl = '/api/v2/property';

    constructor(private httpClient: HttpClient) {}

    public getAdditionalOwners(propertyId: string): Observable<OwnersDto> {
        return this.httpClient.get<OwnersDto>(`${this.baseUrl}/${propertyId}/additional-owners`);
    }

    public addAdditionalOwner(
        propertyId: string,
        userId: string,
        body?: SimpleUpdateUserRequestDto,
    ): Observable<OwnersDto> {
        return this.httpClient.put<OwnersDto>(`${this.baseUrl}/${propertyId}/additional-owners/user/${userId}`, body);
    }

    public deleteAdditionalOwner(propertyId: string, userId: string): Observable<OwnersDto> {
        return this.httpClient.delete<OwnersDto>(
            `${this.baseUrl}/${propertyId}/additional-owners/user/${userId}`,
            // delete() by default does not return the JSON response body
            { observe: 'body' },
        );
    }
}
