import { SuburbDetails } from '../../common/models/suburb-details.model';

import { GET_SUBURBS, GET_SUBURBS_ERROR, GET_SUBURBS_SUCCESS, SuburbsAction } from './suburbs.actions';

export interface SuburbState {
    loading: boolean;
    error: boolean;
    profiled: SuburbDetails[];
    all: SuburbDetails[];
}

export const initialSuburbsState: SuburbState = {
    loading: false,
    error: false,
    profiled: [],
    all: [],
};

export function suburbsReducer(state: SuburbState = initialSuburbsState, action: SuburbsAction) {
    switch (action.type) {
        case GET_SUBURBS:
            return {
                ...state,
                loading: true,
                error: false,
            };

        case GET_SUBURBS_SUCCESS:
            const { profiled, all } = action.payload;
            return {
                ...state,
                profiled,
                all,
                loading: false,
            };

        case GET_SUBURBS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };

        default:
            return state;
    }
}
