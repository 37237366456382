<div class="u-text-color--light">
    <ng-container *upFeature="'upsideMarketingPagesEnabled'">
        &copy; {{ currentYear }} Upside Realty Pty Ltd
    </ng-container>
    <ul class="footer-links">
        <li>
            <a *upFeature="'upsideMarketingPagesEnabled'; else thirdPartyPrivacy" [uiSref]="'privacy-policy'"
                >Privacy</a
            >
            <ng-template #thirdPartyPrivacy>
                <a [href]="thirdPartyPrivacyUrl">Privacy</a>
            </ng-template>
        </li>
        <li>
            <a *upFeature="'upsideMarketingPagesEnabled'; else thirdPartyTerms" [uiSref]="'terms-and-conditions'"
                >Terms</a
            >
            <ng-template #thirdPartyTerms>
                <a [href]="thirdPartyTermsUrl">Terms</a>
            </ng-template>
        </li>
        <li *upFeature="'upsideMarketingPagesEnabled'"><a [uiSref]="'sitemap'">Sitemap</a></li>
    </ul>
</div>
