import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'nc-agent',
    templateUrl: 'agent.component.html',
    styleUrls: ['agent.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentComponent {
    @Input() public loading = false;
    @Input() public firstName: string;
    @Input() public lastName: string;
    @Input() public agency: string;
    @Input() public phoneNumber: string;
    @Input() public email: string;
    @Input() public avatarUrl: string;
}
