import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { BackgroundImageModule } from '../background-image/background-image.module';
import { CdnUrlModule } from '../cdn-url/cdn-url.module';
import { InitialsOfNameModule } from '../initials-of-name/initials-of-name.module';

import { AvatarComponent } from './components/avatar/avatar.component';
import { AvatarHeaderComponent } from './components/avatar-header/avatar-header.component';

@NgModule({
    imports: [CommonModule, BackgroundImageModule, LazyLoadImageModule, CdnUrlModule, InitialsOfNameModule],
    exports: [AvatarComponent, AvatarHeaderComponent],
    declarations: [AvatarComponent, AvatarHeaderComponent],
})
export class AvatarModule {}
