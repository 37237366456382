<up-conversion-step
    id="conversion-capture-details"
    [backRoute]="'^.address-found'"
    [step]="step"
    [heading]="'Confirm your details'"
>
    <ng-container helpText>
        <ng-container *ngIf="showSignInForm">
            It looks like you already have an account. Please sign in to continue.
        </ng-container>
        <ng-container *ngIf="!showSignInForm">
            <ng-container *ngIf="funnelName === 'appraisal'">
                Almost there, we just need to get a few details from you
            </ng-container>
            <ng-container *ngIf="funnelName === 'property-report'">
                Complete your contact details and we'll email you a link to your property report.
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container body>
        <form *ngIf="!showSignInForm" [formGroup]="userDetailsForm" id="sign-up-form" (ngSubmit)="submit()">
            <div class="c-form-group">
                <up-input
                    [ncTestId]="'conversion-capture-details-first-name-input'"
                    [id]="'sign-up-first-name-input'"
                    [type]="'text'"
                    [formControlName]="'firstName'"
                    [label]="'First Name'"
                    [size]="'large'"
                    [internalLabel]="true"
                    [error]="formSubmittedAttempt && userDetailsForm.get('firstName').invalid"
                >
                </up-input>
                <up-help-text [type]="'error'" *ngIf="formSubmittedAttempt && userDetailsForm.get('firstName').invalid">
                    Please enter your first name
                </up-help-text>
            </div>
            <div class="c-form-group">
                <up-input
                    [ncTestId]="'conversion-capture-details-last-name-input'"
                    [id]="'sign-up-last-name-input'"
                    [type]="'text'"
                    [formControlName]="'lastName'"
                    [label]="'Last Name'"
                    [size]="'large'"
                    [internalLabel]="true"
                    [error]="formSubmittedAttempt && userDetailsForm.get('lastName').invalid"
                >
                </up-input>
                <up-help-text [type]="'error'" *ngIf="formSubmittedAttempt && userDetailsForm.get('lastName').invalid">
                    Please enter your last name
                </up-help-text>
            </div>
            <div class="c-form-group">
                <up-input
                    [ncTestId]="'conversion-capture-details-email-input'"
                    [id]="'sign-up-email-input'"
                    [type]="'email'"
                    [formControlName]="'email'"
                    [label]="'Email Address'"
                    [size]="'large'"
                    [internalLabel]="true"
                    [error]="formSubmittedAttempt && userDetailsForm.get('email').invalid"
                >
                </up-input>
                <up-help-text [type]="'error'" *ngIf="formSubmittedAttempt && userDetailsForm.get('email').invalid">
                    Please enter your email address
                </up-help-text>
            </div>
            <div class="c-form-group">
                <up-input
                    [ncTestId]="'conversion-capture-details-phone-number-input'"
                    [id]="'phone-number-input'"
                    [formControlName]="'phoneNumber'"
                    [type]="'tel'"
                    [size]="'large'"
                    [internalLabel]="true"
                    [error]="formSubmittedAttempt && userDetailsForm.get('phoneNumber').invalid"
                    [label]="'Mobile Number'"
                >
                </up-input>
                <up-help-text
                    [type]="'error'"
                    *ngIf="formSubmittedAttempt && userDetailsForm.get('phoneNumber').invalid"
                >
                    Please enter a valid phone number
                </up-help-text>
            </div>
            <div class="u-text-color--light u-text-body--xs u-margin-bottom--xl">
                By continuing you agree to our
                <up-link
                    [target]="'_blank'"
                    [upSref]="'terms-and-conditions'"
                    *upFeature="'upsideMarketingPagesEnabled'; else thirdPartyTerms"
                    >Terms & Conditions</up-link
                >
                <ng-template #thirdPartyTerms>
                    <up-link [target]="'_blank'" [href]="thirdPartyTermsUrl">Terms & Conditions</up-link>
                </ng-template>
                and
                <up-link
                    [target]="'_blank'"
                    [upSref]="'privacy-policy'"
                    *upFeature="'upsideMarketingPagesEnabled'; else thirdPartyPrivacy"
                    >Privacy Policy.</up-link
                >
                <ng-template #thirdPartyPrivacy>
                    <up-link [target]="'_blank'" [href]="thirdPartyPrivacyUrl">Privacy Policy.</up-link>
                </ng-template>
            </div>
            <up-conversion-step-cta-wrapper>
                <up-button
                    [ncTestId]="'conversion-capture-details-submit-button'"
                    [type]="'submit'"
                    [style]="'primary'"
                    [arrowIcon]="true"
                >
                    Next
                </up-button>
            </up-conversion-step-cta-wrapper>
        </form>
        <form *ngIf="showSignInForm" [formGroup]="loginForm" id="sign-in-form" (ngSubmit)="submit()">
            <div class="c-form-group">
                <label class="u-hide-visually" for="sign-in-email">Email</label>
                <input
                    id="sign-in-email"
                    class="c-form-input-field"
                    formControlName="email"
                    type="text"
                    placeholder="Email"
                    name="email"
                    [ngClass]="{
                        'c-form-input-field--invalid': formSubmittedAttempt && loginForm.get('email').invalid
                    }"
                />
                <div
                    *ngIf="formSubmittedAttempt && loginForm.get('email').invalid"
                    class="form-message-error u-margin-top--sm"
                    >Please your email</div
                >
            </div>
            <div class="c-form-group">
                <label class="u-hide-visually" for="sign-in-password">Password</label>
                <input
                    id="sign-in-password"
                    formControlName="password"
                    type="password"
                    placeholder="Password"
                    name="password"
                    [ngClass]="{
                        'c-form-input-field': true,
                        'c-form-input-field--invalid': formSubmittedAttempt && loginForm.get('password').invalid
                    }"
                />
                <div
                    *ngIf="formSubmittedAttempt && loginForm.get('password').invalid"
                    class="form-message-error u-margin-top--sm"
                    >Please enter your password</div
                >
            </div>
            <div class="u-text-align--right">
                <a class="c-link" [uiSref]="'user.forgot-password'">Forgot Password?</a>
            </div>
            <up-alert
                *ngIf="signInError"
                [type]="'negative'"
                id="sign-in-incorrect-password-alert"
                class="u-margin-top--lg"
            >
                The email and password combination you have provided is incorrect, please try again or
                <up-link id="sign-up-with-new-email" (click)="showSignUpForm()">sign up with a new email.</up-link>
            </up-alert>
            <up-conversion-step-cta-wrapper>
                <up-button [type]="'submit'" [style]="'primary'" [arrowIcon]="true"> Next </up-button>
            </up-conversion-step-cta-wrapper>
        </form>
    </ng-container>
</up-conversion-step>
