<div
    class="inner"
    [ngClass]="{
        'no-default-content-padding': !_contentPadding,
        'dot-align-center': _dotAlignment === 'center'
    }"
>
    <div>
        <ng-content select="[leading]"></ng-content>
    </div>
    <div class="decoration">
        <div class="dot">
            <up-timeline-dot
                *ngIf="!dotTemplate; else dotTemplate"
                [dotSize]="_dotSize"
                [borderColor]="_dotColor"
                [fillColor]="_dotFilled ? _dotColor : undefined"
            >
            </up-timeline-dot>
        </div>
        <div class="line" [ngStyle]="{ borderColor: _lineColor, borderStyle: _lineStyle }"></div>
    </div>
    <div class="content">
        <ng-content></ng-content>
    </div>
</div>
