import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import moment, { Moment, MomentSetObject } from 'moment-timezone';

interface CategorisedTimeSlots {
    morning: Moment[];
    afternoon: Moment[];
    evening: Moment[];
}

@Component({
    selector: 'up-appraisal-booker-times',
    templateUrl: './appraisal-booker-times.component.html',
    styleUrls: ['./appraisal-booker-times.component.scss'],
})
export class AppraisalBookerTimesComponent implements OnChanges, OnInit {
    @Input() public timezone: string;
    @Input() public timeSlots: Moment[];
    @Input() public disabled: boolean;
    @Output() public select: EventEmitter<Moment> = new EventEmitter<Moment>();
    public browserTz: string;
    public categorisedTimeSlots: CategorisedTimeSlots;
    public timeSlotControl = new UntypedFormControl('');

    public ngOnInit(): void {
        this.browserTz = moment.tz.guess();
        this.timeSlotControl.valueChanges.subscribe((value: Moment) => this.select.emit(value));
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.timeSlots) {
            // Reset form when new set of dates come through
            this.timeSlotControl.reset('', { emitEvent: false });
            this.categorisedTimeSlots = {
                morning: this.getTimesBetween(this.timeSlots, '08:00:00', '11:59:59'),
                afternoon: this.getTimesBetween(this.timeSlots, '12:00:00', '16:59:59'),
                evening: this.getTimesBetween(this.timeSlots, '17:00:00', '20:59:59'),
            };
        }
    }

    public formatTime(time: Moment): string {
        return moment(time).tz(this.timezone).format('h:mm A');
    }

    public get hasAnyTimeSlots(): boolean {
        return Object.values(this.categorisedTimeSlots).some(slots => slots.length);
    }

    public parseTimeToMomentSetObject(time: string): MomentSetObject {
        const [hours, minutes, seconds] = time.split(':');
        return { hours: parseInt(hours), minutes: parseInt(minutes), seconds: parseInt(seconds) };
    }

    private getTimesBetween(timeSlots: Moment[], start: string, end: string): Moment[] {
        const timeFormat = 'HH:mm:ss';
        return (timeSlots || []).filter(ts => {
            // Normalised these dates so that they all have the same date, but only different times, as we only care
            // about comparing the time
            const normalisedTimeSlot = moment
                .tz(this.timezone)
                .set(this.parseTimeToMomentSetObject(ts.format(timeFormat)));
            const normalisedStartTime = moment.tz(this.timezone).set(this.parseTimeToMomentSetObject(start));
            const normalisedEndTime = moment.tz(this.timezone).set(this.parseTimeToMomentSetObject(end));

            return normalisedTimeSlot.isBetween(normalisedStartTime, normalisedEndTime, 'minutes', '[]');
        });
    }
}
