import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ContactInformationForPropertySearchDto } from '../../../common/models/dto/property-search/contact-information-for-property-search.dto';
import { GetContactInformationForPropertySearchDto } from '../../../common/models/dto/property-search/get-contact-information-for-property-search.dto';
import { SearchApi } from '../../../common/models/search-api.model';
import { CustomQueryEncoder } from '../../../encoders/custom-query.encoder';

@Injectable()
export class SearchResource {
    private readonly baseUrl = '/api/v2';

    constructor(private httpClient: HttpClient, private customQueryEncoder: CustomQueryEncoder) {}

    public fullTextSearch(query: SearchApi.FullTextSearch.GET.Request = {}) {
        return this.httpClient.get<SearchApi.FullTextSearch.GET.Response>(`${this.baseUrl}/full-text-search`, {
            params: this.getCustomEncodedHttpParams(query),
        });
    }

    public userSearch(query: SearchApi.UserSearch.GET.Request) {
        return this.httpClient.get<SearchApi.UserSearch.GET.Response>(`${this.baseUrl}/user-search`, {
            params: this.getCustomEncodedHttpParams(query),
        });
    }

    public propertySearch(query: SearchApi.PropertySearch.GET.Request) {
        return this.httpClient.get<SearchApi.PropertySearch.GET.Response>(`${this.baseUrl}/property-search`, {
            params: this.getCustomEncodedHttpParams(query),
        });
    }

    public getPropertySearchContactInformation(
        businessId: string,
        body: GetContactInformationForPropertySearchDto,
    ): Observable<ContactInformationForPropertySearchDto> {
        return this.httpClient.post<ContactInformationForPropertySearchDto>(
            `${this.baseUrl}/business/${businessId}/property-search/contact-information`,
            body,
        );
    }

    private getCustomEncodedHttpParams(query: { [key: string]: any }): HttpParams | {} {
        if (!query) return {};

        return Object.entries(query).reduce(
            (prev, [key, value]) => prev.append(key, value),
            new HttpParams({
                encoder: this.customQueryEncoder,
            }),
        );
    }
}
