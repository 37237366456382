<up-isolated-container [showLogo]="true">
    <ng-container heading> Reset Password </ng-container>
    <form content class="u-padding-bottom--3xl" (ngSubmit)="forgotPassword()">
        <up-alert class="u-margin-bottom--xl" [size]="'small'" [type]="'warning'" *ngIf="emailFailed">
            We were unable to reset your password.
            <ng-container *upFeature="'upsideMarketingPagesEnabled'; else genericSupport">
                Please contact us on
                <up-link [href]="'tel:1800877433'">1800 877 433.</up-link>
            </ng-container>
            <ng-template #genericSupport>
                Please try again later.
            </ng-template>
        </up-alert>

        <div>
            <up-input
                [formControl]="emailControl"
                [size]="'large'"
                [type]="'email'"
                [fullWidth]="true"
                [internalLabel]="true"
                [error]="formSubmissionAttempted && emailControl.invalid"
                [label]="'Email address'"
            >
            </up-input>

            <up-help-text *ngIf="formSubmissionAttempted && emailControl.invalid" [type]="'error'">
                Please enter a valid email address.
            </up-help-text>
        </div>

        <up-button
            class="u-margin-top--lg"
            [style]="'primary'"
            [loading]="loading"
            [fullWidth]="true"
            [type]="'submit'"
        >
            Reset Password
        </up-button>
    </form>
    <ng-container footer> <up-auth-footer></up-auth-footer> </ng-container>
</up-isolated-container>
