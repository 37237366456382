import { Component, Inject, Input } from '@angular/core';

import { UP_COLOR_CONSTANTS, UpColorConstants } from '../../constants/colors.constants';

type Size = 'regular' | 'large';

// Todo: Make the name of this component more generic. It doesn't have to be used only with a ratemyagent rating.
@Component({
    selector: 'up-ratemyagent-rating-pill',
    templateUrl: './ratemyagent-rating-pill.component.html',
    styleUrls: ['./ratemyagent-rating-pill.component.scss'],
})
export class RatemyagentRatingPillComponent {
    @Input() public rating: number;
    @Input() public size: Size = 'regular';

    constructor(@Inject(UP_COLOR_CONSTANTS) public upColorConstants: UpColorConstants) {}
}
