import { Pipe, PipeTransform } from '@angular/core';

import { Color, ThemeService } from '../../../core/services/theme.service';

@Pipe({
    name: 'upColor',
})
export class ColorPipe implements PipeTransform {
    constructor(private themeService: ThemeService) {}

    public transform(value: Color, colorType: 'hex' | 'cssVar' = 'hex'): string {
        const color = this.themeService.colors[value];
        if (!color) throw new Error(`upColor: ${value} is not a valid color`);
        if (colorType === 'hex') {
            return this.themeService.colors[value];
        } else if (colorType === 'cssVar') {
            return `var(${this.themeService.themeColorAsCssVariable(value)})`;
        } else {
            return undefined;
        }
    }
}
