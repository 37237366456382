/* eslint-disable import/order */

import './set-cdn-url';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import { initSentry } from './init-sentry';

initSentry();

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { safePerformanceMark } from './common/utilities/performance.utilities';
import { RootModule } from './root.module';
import { marks } from './settings/performance';
import { initDayjs } from './init-dayjs';
import { isProduction } from './common/utilities/is-production.util';
import { Chart } from 'chart.js';

// TODO: Disabling the animations for now since ChartJS3+ introduces
//  some strange ones and we'll need to review/tweak them.
Chart.defaults.animation = false;

initDayjs();

function main() {
    safePerformanceMark(marks.angularStartBoot);

    if (isProduction()) {
        enableProdMode();
    }

    return (
        platformBrowserDynamic()
            .bootstrapModule(RootModule, {
                preserveWhitespaces: true,
            })
            // eslint-disable-next-line no-console
            .catch(err => console.error(err))
    );
}

function bootloader(main) {
    if (document.readyState === 'complete') {
        main();
    } else {
        document.addEventListener('DOMContentLoaded', main);
    }
}

bootloader(main);
