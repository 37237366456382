import { Directive, HostListener, Input } from '@angular/core';

import { TrackingService } from '../services/tracking.service';

interface DataEventObject {
    name: string;
    subname?: string;
    data?: unknown;
}

export type DataEvent = DataEventObject | string;

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[dataEvent]',
})
export class TrackingDirective {
    @Input('dataEvent') public dataEvent: DataEvent;

    constructor(private trackingService: TrackingService) {}

    @HostListener('click')
    public onMouseClick(): void {
        if (!this.dataEvent) return;

        if (typeof this.dataEvent === 'string') {
            this.trackingService.trackEvent(this.dataEvent);
        } else if (typeof this.dataEvent === 'object' && this.dataEvent.name) {
            const { name, ...rest } = this.dataEvent;
            this.trackingService.trackDataEvent(name, rest);
        }
    }
}
