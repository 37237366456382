import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { StateService } from '@uirouter/angular';
import { first } from 'rxjs/operators';

import { UpValidators } from '../../../../common/form-validators/validators';
import { AddressT } from '../../../../common/models/address.model';
import { conversion } from '../../../../common/models/conversion.model';
import { User, UserBase } from '../../../../common/models/user.model';
import { UserService, UserT } from '../../../../common/services/user.service';
import { UserResource } from '../../../../modules/core/resources/user.resource';
import { ConversionService } from '../../../../modules/core/services/conversion/conversion.service';
import { FeatureService } from '../../../../modules/core/services/feature.service';
import { AppState } from '../../../../store/apps-state.model';
import { SetUser } from '../../../../store/conversion/conversion.actions';
import { ConversionFacade } from '../../../../store/conversion/conversion.facade';

@Component({
    selector: 'up-conversion-capture-details',
    templateUrl: './conversion-capture-details.component.html',
    styleUrls: ['./conversion-capture-details.component.scss'],
})
export class ConversionCaptureDetailsComponent implements OnInit {
    @Input() public address: AddressT;
    @Input() public user: UserBase;
    @Input() public funnelName: conversion.Funnel;
    @Input() public step: number;
    public authData: UserT;
    public formSubmittedAttempt = false;
    public userDetailsForm: FormGroup;
    public loginForm: FormGroup;
    public isUserRegistered: boolean;
    public showSignInForm: boolean;
    public signInError: boolean;

    constructor(
        private stateService: StateService,
        private formBuilder: FormBuilder,
        private userResource: UserResource,
        private conversionService: ConversionService,
        private userService: UserService,
        private store: Store<AppState>,
        private conversionFacade: ConversionFacade,
        private featureService: FeatureService,
    ) {}

    public get thirdPartyPrivacyUrl(): string {
        return this.featureService.urlConfiguration.privacyPolicyPage;
    }

    public get thirdPartyTermsUrl(): string {
        return this.featureService.urlConfiguration.termsAndConditionsPage;
    }

    public ngOnInit(): void {
        this.userService.userAuthDetailsUpdated$.subscribe(authData => (this.authData = authData));
        this.createForms();

        if (this.conversionService.hasStoredAddress()) {
            this.address = this.conversionService.getConversionState().address;
        } else if (this.address) {
            // Store the address if it has been passed in via params
            this.conversionService.storeConversionState(this.address);
        }

        if (!this.address) {
            this.conversionService.goToFunnelEntry(this.funnelName);
            return;
        } else if (this.authData.authenticated) {
            this.userDetailsForm.value.email = this.authData.email;
            this.userDetailsForm.value.firstName = this.authData.firstName;
            this.userDetailsForm.value.lastName = this.authData.lastName;
            this.userDetailsForm.value.phoneNumber = this.authData.phoneNumber;
            if (
                (this.funnelName === conversion.Funnel.Appraisal || this.funnelName === conversion.Funnel.Booking) &&
                !this.authData.phoneNumber
            ) {
                this.stateService.go('^.phone', {
                    user: this.getUser(),
                });
            } else {
                this.stateService.go('^.complete', {
                    user: this.getUser(),
                    funnelName: this.funnelName,
                });
            }
        } else if (this.user) {
            // if a user is provided to the component, we pre-fill the form
            this.userDetailsForm.patchValue({ ...this.user });
        }

        if (this.funnelName === conversion.Funnel.Booking || this.funnelName === conversion.Funnel.Calendar) {
            // In the case where the user had to reselect their time due to it becoming unavailable after
            // selecting it, we may already have the user data in the store so we can prefill the form for them.
            this.conversionFacade.user$.pipe(first()).subscribe(user => this.userDetailsForm.patchValue({ ...user }));
        }
    }

    public submit() {
        this.formSubmittedAttempt = true;
        if (!this.userDetailsForm.valid) {
            return;
        }
        if (this.isUserRegistered) {
            this.signIn();
            return;
        }
        this.userResource.confirmRegisteredEmail({ email: this.getUser().email }).subscribe(
            (result: any) => {
                this.isUserRegistered = result.requireAuth;
                this.formSubmittedAttempt = false;
                if (this.isUserRegistered) {
                    this.loginForm.controls.email.setValue(this.userDetailsForm.value.email);
                    this.showSignInForm = true;
                } else {
                    this.next();
                }
            },
            () =>
                this.conversionService.goToFunnelEntry(this.funnelName, {
                    submissionError: 'Error communicating with server',
                }),
        );
    }

    public showSignUpForm() {
        this.userDetailsForm.value.email = '';
        this.isUserRegistered = false;
        this.showSignInForm = false;
    }

    private createForms(): void {
        this.userDetailsForm = this.formBuilder.group({
            firstName: ['', [Validators.required, UpValidators.noWhiteSpacesOnlyValidator]],
            lastName: ['', [Validators.required, UpValidators.noWhiteSpacesOnlyValidator]],
            email: ['', [Validators.required, UpValidators.strictEmailValidator]],
            phoneNumber: ['', [Validators.required, UpValidators.phoneNumberValidator]],
        });
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, UpValidators.strictEmailValidator]],
            password: ['', Validators.required],
        });
    }

    private signIn() {
        if (this.loginForm.invalid) {
            return;
        }
        const email = this.loginForm.value.email.trim();
        const password = this.loginForm.value.password;
        this.userResource.signIn({ email, password }).subscribe(
            () => this.next(),
            () => (this.signInError = true),
        );
    }

    private getUser(): User {
        return {
            address: this.address,
            ...this.userDetailsForm.value,
            email: this.userDetailsForm.value.email.trim(),
        };
    }

    private next(): void {
        const { email, phoneNumber, lastName, firstName } = this.getUser();
        this.store.dispatch(new SetUser({ email, phoneNumber, lastName, firstName }));
        this.stateService.go('^.complete', { user: this.getUser() });
    }
}
