<div
    *ngIf="isItemVisible(item.visibility)"
    [ngClass]="{
        'nav-bar-item': true,
        'is-active': isActive,
        'is-important': item.important
    }"
    [uiSrefActiveEq]="'is-active'"
    (mouseenter)="showDropdown()"
    (mouseleave)="hideDropdown()"
>
    <button
        class="nav-bar-item-text nav-bar-item-text--dropdown"
        aria-haspopup="true"
        [attr.aria-expanded]="isDropdownVisible"
        (keyup)="onItemKeyup($event)"
        *ngIf="!item.uiSref && !item.href && !item.click"
    >
        <ng-container *ngTemplateOutlet="navItemContent"></ng-container>
    </button>
    <a
        class="nav-bar-item-text"
        *ngIf="item.uiSref"
        [dataEvent]="item.trackingEvent"
        [uiSref]="item.uiSref"
        [uiParams]="item.uiParams"
    >
        <ng-container *ngTemplateOutlet="navItemContent"></ng-container>
    </a>
    <a class="nav-bar-item-text" *ngIf="item.href" [dataEvent]="item.trackingEvent" [href]="item.href">
        <ng-container *ngTemplateOutlet="navItemContent"></ng-container>
    </a>
    <button class="nav-bar-item-text" *ngIf="item.click" [dataEvent]="item.trackingEvent" (click)="item.click()">
        <ng-container *ngTemplateOutlet="navItemContent"></ng-container>
    </button>
    <ng-template #navItemContent>
        <up-icon class="u-margin-right--sm" *ngIf="item.icon" [color]="'current-color'" [name]="item.icon"></up-icon
        >{{ item.title }}
    </ng-template>
    <up-nav-bar-dropdown
        class="nav-bar-item-dropdown"
        *ngIf="item.children?.length"
        [items]="item.children"
        [focusFirstItem]="focusOnFirstItemInDropdown"
        [isVisible]="isDropdownVisible"
        (onHide)="hideDropdown()"
        (onActiveStateChange)="onDropdownActiveStateChange($event)"
    ></up-nav-bar-dropdown>
</div>
