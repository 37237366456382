<up-card class="card u-shadow--card">
    <up-icon *ngIf="slug" class="plus" [size]="'small'" [name]="'plusBoldLarge'" [color]="'currentColor'"> </up-icon>

    <a
        class="avatar"
        [class.no-image]="noImages"
        target="_blank"
        *ngIf="slug; else noSlug"
        [uiSref]="'agent-profiles.agent-profile'"
        [uiParams]="{ slug: slug }"
    >
        <ng-container *ngTemplateOutlet="image"></ng-container>
    </a>

    <ng-template #noSlug>
        <div class="avatar" [class.no-image]="noImages"> <ng-container *ngTemplateOutlet="image"></ng-container> </div>
    </ng-template>

    <div class="content"> <ng-content></ng-content> </div>
</up-card>

<ng-template #image>
    <ng-container *ngIf="transparentProfileImage?.servingUrl; else noTransparentProfileImage">
        <img
            class="avatar-image"
            [src]="transparentProfileImage.servingUrl"
            [alt]="'Photo of Upside agent ' + firstName"
        />
    </ng-container>
    <ng-template #noTransparentProfileImage>
        <div
            class="avatar-fallback-image"
            *ngIf="avatar?.servingUrl; else fallbackLogo"
            [backgroundImage]="avatar?.servingUrl"
        ></div>
        <ng-template #fallbackLogo>
            <img
                class="fallback-logo"
                [src]="
                    '/static/images/organisations/logos/#themeName#-regular.svg' | upThemeNameStringTemplate | upCdnUrl
                "
                alt="{{ orgName }} logo"
            />
        </ng-template>
    </ng-template>
</ng-template>
