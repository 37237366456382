import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'upNullishDefault',
})
export class NullishDefaultPipe implements PipeTransform {
    public transform<T>(value: T | null | undefined, defaultValue?: T): T {
        // Todo: switch to `value ?? defaultValue` when nullish coalescing operator is supported by linter
        return typeof value === 'undefined' || value === null ? defaultValue : value;
    }
}
