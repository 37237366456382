import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { RequestCallBackFormData } from '../../../../common/components/request-call-back-form/request-call-back-form.component';
import { HistoryState } from '../../../../common/services/history.service';
import { AppState } from '../../../../store/apps-state.model';

import { getStartedActions } from './get-started.actions';
import * as getStartedSelectors from './get-started.selectors';

@Injectable()
export class GetStartedFacade {
    public returnTo$: Observable<HistoryState> = this.store.select(getStartedSelectors.returnToSelector);
    public loadingRequestCallback$: Observable<boolean> = this.store.select(
        getStartedSelectors.loadingRequestCallbackSelector,
    );
    public hasRequestedCallback$: Observable<boolean> = this.store.select(
        getStartedSelectors.hasRequestedCallbackSelector,
    );
    public hasStartedFlow$: Observable<boolean> = this.store.select(getStartedSelectors.hasStartedFlowSelector);
    public errorRequestingCallback$: Observable<HttpErrorResponse> = this.store.select(
        getStartedSelectors.errorRequestingCallbackSelector,
    );

    constructor(private store: Store<AppState>) {}

    public setReturnTo(returnTo: HistoryState): void {
        this.store.dispatch(new getStartedActions.SetReturnTo(returnTo));
    }

    public requestCallback(requestCallbackData: RequestCallBackFormData): void {
        this.store.dispatch(new getStartedActions.RequestCallback(requestCallbackData));
    }

    public setHasStartedFlow(started: boolean): void {
        this.store.dispatch(new getStartedActions.SetHasStartedFlow(started));
    }

    public setHasRequestedCallback(requestedCallback: boolean): void {
        this.store.dispatch(new getStartedActions.SetHasRequestedCallback(requestedCallback));
    }
}
