import { Component, OnDestroy, OnInit } from '@angular/core';

import { GetStartedService } from './get-started.service';
import { GetStartedFacade } from './store/get-started.facade';

@Component({
    selector: 'up-get-started',
    templateUrl: './get-started.component.html',
})
export class GetStartedComponent implements OnInit, OnDestroy {
    constructor(private getStartedFacade: GetStartedFacade, private getStartedService: GetStartedService) {}

    public ngOnInit(): void {
        this.setReturnToState();
    }

    public ngOnDestroy(): void {
        this.getStartedFacade.setHasStartedFlow(false);
    }

    private setReturnToState(): void {
        const returnTo = this.getStartedService.getValidReturnToState();
        this.getStartedFacade.setReturnTo(returnTo);
    }
}
