import { MediaTagDto } from '../../dto/media/media-tag.dto';

import { MediaTagName } from './media-tag-name.model';

export namespace MediaTag {
    export interface Model {
        name: MediaTagName;
        displayName: string;
        canAddMultiple: boolean;
        isBrochureAllowed: boolean;
    }

    export function fromDto(dto: MediaTagDto): Model {
        return {
            name: dto.name,
            displayName: dto.displayName,
            canAddMultiple: dto.canAddMultiple,
            isBrochureAllowed: dto.brochureAllowed,
        };
    }
}
