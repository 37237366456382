import { Injector } from '@angular/core';
import { StateObject, UIRouter } from '@uirouter/angular';

import { HistoryService } from '../../common/services/history.service';

// Recursively get all params from parent routes
const getAllInheritedParams: (stateObject: StateObject, params?: {}) => {} = (stateObject, params = {}) => {
    const inheritedParams = { ...params, ...stateObject.params };
    return stateObject.parent ? getAllInheritedParams(stateObject.parent, inheritedParams) : inheritedParams;
};

export const historyServiceUiRouterHooks = (router: UIRouter, injector: Injector) => {
    router.transitionService.onSuccess({}, transition => {
        const from = transition.$from();

        if (!from.name) return;

        const allParams = transition.params('from');

        // Ensure only valid params are stored, i.e. params from the route itself and inherited params
        const validParams = getAllInheritedParams(from);
        const params = Object.keys(validParams).reduce((acc, curr) => ({ ...acc, [curr]: allParams[curr] }), {});

        const historyService = injector.get(HistoryService);
        historyService._setFromState({ name: from.name, params });
    });
};
