import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { coreLogic } from '../../../common/models/core-logic.model';

const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

const BASE_URL = '/api/v2/corelogic/';

@Injectable()
export class CoreLogicResource {
    constructor(private http: HttpClient) {}

    public findByAddress(
        address: coreLogic.api.findByAddress.post.Request,
    ): Observable<coreLogic.api.findByAddress.post.Response> {
        return this.http.post<coreLogic.api.findByAddress.post.Response>(
            `${BASE_URL}findByAddress`,
            address,
            HEADERS_CONFIG,
        );
    }
}
