import { Component, Input, OnInit } from '@angular/core';

type Size = 'small' | 'regular';

type Type = 'pill' | 'stars';

@Component({
    selector: 'up-rma-rating',
    templateUrl: 'rma-rating.component.html',
    styleUrls: ['rma-rating.component.scss'],
})
export class RmaRatingComponent implements OnInit {
    @Input() public href: string;
    @Input() public rating: number;
    @Input() public size: Size = 'regular';
    @Input() public type: Type = 'pill';
    public logoWidth: number;
    public logoHeight: number;

    public ngOnInit(): void {
        this.logoWidth = this.size === 'small' ? 72 : 90;
        this.logoHeight = this.size === 'small' ? 21 : 26;
    }
}
