import { user } from '../../models/user.model';

export function getPrettyUserRole(role: user.Role): string {
    switch (role) {
        case user.Role.SalesAdmin:
            return 'Business Admin';
        case user.Role.Staff:
            return 'Staff';
        case user.Role.Agent:
            return 'Agent';
        case user.Role.User:
            return 'User';
        case user.Role.Buyer:
            return 'Buyer';
        case user.Role.Vendor:
            return 'Primary Contact';
        case user.Role.Admin:
            return 'Admin';
        case user.Role.SalesManager:
            return 'Sales Manager';
        case user.Role.Finance:
            return 'Finance';
        case user.Role.Supplier:
            return 'Supplier';
        case user.Role.SalesAssociate:
            return 'Sales Associate';
        case user.Role.ConciergeAdmin:
            return 'Concierge Admin';
        case user.Role.Concierge:
            return 'Concierge';
        case user.Role.Demo:
            return 'Demo';
        case user.Role.OrgAdmin:
            return 'Org Admin';
        case user.Role.AgencyAdmin:
            return 'Agency Admin';
        default:
            return role;
    }
}
