import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'up-nav-bar-container',
    templateUrl: 'nav-bar-container.component.html',
    styleUrls: ['nav-bar-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarContainerComponent {
    @HostBinding('class.is-grower')
    @Input()
    public grow: boolean;
    @Input() public contentAlignment: 'start' | 'center' | 'end' = 'start';

    @HostBinding('class.content-alignment--end')
    public get classContentAlignmentEnd(): boolean {
        return this.contentAlignment === 'end';
    }

    @HostBinding('class.content-alignment--start')
    public get classContentAlignmentStart(): boolean {
        return this.contentAlignment === 'start';
    }

    @HostBinding('class.content-alignment--center')
    public get classContentAlignmentCenter(): boolean {
        return this.contentAlignment === 'center';
    }
}
