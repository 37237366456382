import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ListingKeyDetailsComponent } from './components/listing-key-details/listing-key-details.component';

@NgModule({
    imports: [CommonModule],
    exports: [ListingKeyDetailsComponent],
    declarations: [ListingKeyDetailsComponent],
    providers: [],
})
export class ListingKeyDetailsModule {}
