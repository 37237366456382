import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { FocusTrap, FocusTrapFactory } from '@angular/cdk/a11y';
import { CdkPortalOutlet, ComponentPortal, DomPortal, TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Inject,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { NavigationService } from '../../../navigation/navigation.service';
import { MenuRef } from '../../menu-ref';
import { MenuCloseOrigin } from '../../models/menu-close-origin.model';

type MenuAnimationState = 'void' | 'visible' | 'hidden';

@Component({
    selector: 'nc-menu-container',
    templateUrl: 'menu-container.component.html',
    styleUrls: ['menu-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('menu', [
            state('void, hidden', style({ opacity: 0 })),
            state('visible', style({ opacity: 1 })),
            transition('* <=> *', animate('120ms ease-in')),
        ]),
    ],
})
export class MenuContainerComponent implements OnDestroy {
    @HostBinding('@menu') public menuAnimationState: MenuAnimationState = 'void';
    @ViewChild(CdkPortalOutlet, { static: true }) private cdkPortalOutlet: CdkPortalOutlet;
    private elementFocusedBeforeOpen: HTMLElement;
    private focusTrap: FocusTrap;
    private _animationStateChanged$ = new Subject<AnimationEvent>();
    public animationStateChanged$: Observable<AnimationEvent>;
    private closeOrigin: MenuCloseOrigin;
    private menuRef: MenuRef;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly focusTrapFactory: FocusTrapFactory,
        private readonly elementRef: ElementRef,
        private readonly navigationService: NavigationService,
    ) {
        this.animationStateChanged$ = this._animationStateChanged$.asObservable();
    }

    public ngOnDestroy(): void {
        this.focusTrap?.destroy();
    }

    @HostListener('click')
    public onClick(): void {
        this.triggerExit();
    }

    @HostListener('keydown', ['$event'])
    public onKeydown(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.menuRef?.close();
                break;
            case 'Tab':
                // prevent default tab behaviour as this will be handled once the menu is closed
                event.preventDefault();
                this.menuRef?.close(event.shiftKey ? 'shift-tab' : 'tab');
                break;
        }
    }

    @HostListener('@menu.done', ['$event'])
    public menuAnimDone(event: AnimationEvent): void {
        switch (event.toState) {
            case 'hidden':
            case 'void':
                this.restoreFocus();
                break;
            case 'visible':
                this.trapFocus();
                break;
        }

        this._animationStateChanged$.next(event);
    }

    @HostListener('@menu.start', ['$event'])
    public menuAnimStart(event: AnimationEvent): void {
        this._animationStateChanged$.next(event);
    }

    public attachPortal<T>(portal: ComponentPortal<T> | TemplatePortal | DomPortal, menuRef: MenuRef): void {
        this.menuRef = menuRef;

        if (portal instanceof ComponentPortal) {
            this.cdkPortalOutlet.attachComponentPortal(portal);
        } else if (portal instanceof TemplatePortal) {
            this.cdkPortalOutlet.attachTemplatePortal(portal);
        } else {
            portal.attach(this.cdkPortalOutlet);
        }

        this.savePreviouslyFocusedElement();
    }

    public triggerEnter(): void {
        this.menuAnimationState = 'visible';
    }

    public triggerExit(closeOrigin?: MenuCloseOrigin): void {
        this.menuAnimationState = 'hidden';
        this.closeOrigin = closeOrigin;
    }

    private savePreviouslyFocusedElement(): void {
        this.elementFocusedBeforeOpen = <HTMLElement>this.document.activeElement;
    }

    private trapFocus(): void {
        if (!this.focusTrap) {
            this.focusTrap = this.focusTrapFactory.create(this.elementRef.nativeElement);
        }
    }

    private restoreFocus(): void {
        if (!this.elementFocusedBeforeOpen || !this.closeOrigin) return;

        this.navigationService.focusNextElement(this.elementFocusedBeforeOpen, this.closeOrigin === 'shift-tab');
    }
}
