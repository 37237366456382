import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { TrackingService } from '../../../../common/services/tracking.service';
import { AutopilotResource } from '../../../../modules/core/resources/autopilot.resource';

import { getStartedActions, GetStartedActionType } from './get-started.actions';

@Injectable()
export class GetStartedEffects {
    constructor(
        private actions: Actions,
        private autopilotResource: AutopilotResource,
        private trackingService: TrackingService,
    ) {}

    public requestCallback: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType<getStartedActions.RequestCallback>(GetStartedActionType.REQUEST_CALLBACK),
            switchMap(action =>
                this.autopilotResource.requestCall(action.payload).pipe(
                    map(() => {
                        this.trackingService.trackEvent('callBackRequested');

                        return new getStartedActions.RequestCallbackSuccess();
                    }),
                    catchError(error => of(new getStartedActions.RequestCallbackError(error))),
                ),
            ),
        ),
    );
}
