import { NgModule } from '@angular/core';

import { EnvironmentDirective } from './directives/environment/environment.directive';
import { LocalisedAssetPipe } from './pipes/localised-asset/localised-asset.pipe';

@NgModule({
    exports: [LocalisedAssetPipe, EnvironmentDirective],
    declarations: [LocalisedAssetPipe, EnvironmentDirective],
})
export class LocalisationModule {}
