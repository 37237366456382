import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TransitionService, UrlService } from '@uirouter/angular';

import { dynamicPixelUrlQueryParam } from '../../../../common/constants/facebook-pixel.constants';
import { WindowRef } from '../../../../common/services/window.service';
import { ScriptInjectorService } from '../../../script-injector/service/script-injector/script-injector.service';

@Injectable()
export class FacebookPixelService {
    private readonly registeredDynamicPixels: Record<string, HTMLElement> = {};
    // Todo: this should be stored in the API
    private readonly urlParamAliasToPixelIdMap: Record<string, string> = {
        rw_castle_hill: '341932343928228',
        rw_vic: '1552012015042710',
    };
    private transitionHookFn: Function;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        @Inject(DOCUMENT) private document: Document,
        private windowRef: WindowRef,
        private urlService: UrlService,
        private transitionService: TransitionService,
        private scriptInjectorService: ScriptInjectorService,
    ) {}

    public initialiseDynamicPixelFromUrlParams(): void {
        if (!isPlatformBrowser(this.platformId)) return;

        const queryParams = this.urlService.search();
        const dynamicPixelUrlAlias = queryParams[dynamicPixelUrlQueryParam];
        const pixelId = this.urlParamAliasToPixelIdMap[dynamicPixelUrlAlias];

        if (!pixelId) return;

        this.createAndAddPixel(pixelId);
    }

    private createAndAddPixel(pixelId: string): void {
        if (this.registeredDynamicPixels[pixelId]) {
            throw Error(`A Pixel with the ID ${pixelId} has already been registered`);
        }

        const pixelScript = `var pixelCode = function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${pixelId}');`;
        const scriptElement = this.scriptInjectorService.inject(pixelScript);

        this.registeredDynamicPixels[pixelId] = scriptElement;
        // Todo: for now we rely on the onSuccess hook to trigger the initial PageView event. We can safely rely on
        //  it because this runs in the app initialiser before the first transition has succeeded. Once we make this
        //  an asynchronous task (ie. fetch the pixel ID from the API), we will need to manually trigger a PageView
        //  event as likely the transition will have succeeded by the time the hook is registered.
        this.initialiseTrackPageView();
    }

    private initialiseTrackPageView(): void {
        if (this.transitionHookFn) return;

        this.transitionHookFn = this.transitionService.onSuccess({}, () => {
            if (typeof this.windowRef.nativeWindow.fbq === 'undefined') return;

            this.windowRef.nativeWindow.fbq('track', 'PageView');
        });
    }
}
