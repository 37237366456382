import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GeoLocationDto } from '../../../common/models/dto/location/geo-location.dto';
import { GetSuburbListingsQueryDto } from '../../../common/models/dto/suburb/get-suburb-listings-query.dto';
import { ListingTypeDto } from '../../../common/models/dto/suburb/listing-type.dto';
import { SuburbCoreDetailsDto } from '../../../common/models/dto/suburb/suburb-details.dto';
import { SuburbExternalListingsResponseDto } from '../../../common/models/dto/suburb/suburb-external-listings-response.dto';
import { suburbApi } from '../../../common/models/suburb-api.model';
import { stripNilValues } from '../../../common/utilities/data/data.utilities';

const header = new HttpHeaders();
header.set('Content-Type', 'application/json');
header.set('Access-Control-Allow-Origin', '*');

const HEADERS_CONFIG = {
    headers: header,
    withCredentials: false,
};

const SUBURB_BASE_URL = '/api/v2/suburb';

@Injectable()
class SuburbResource {
    constructor(private http: HttpClient) {}

    public get(id: string): Observable<suburbApi.suburbById.get.Response> {
        return this.http.get<suburbApi.suburbById.get.Response>(`${SUBURB_BASE_URL}/${id}/`, HEADERS_CONFIG);
    }

    public getSuburbDataBySlug(slug: string): Observable<suburbApi.stats.get.Response> {
        return this.http.get<suburbApi.stats.get.Response>(`${SUBURB_BASE_URL}/stats/slug/${slug}`, HEADERS_CONFIG);
    }

    public getSuburbDataById(suburbId: string) {
        return this.http.get<suburbApi.stats.get.Response>(`${SUBURB_BASE_URL}/${suburbId}/stats`);
    }

    public getSuburbAgent(suburbId: string, coords?: GeoLocationDto): Observable<suburbApi.suburbAgent.get.Response> {
        const params = !!coords
            ? {
                lat: coords.latitude.toString(),
                lng: coords.longitude.toString(),
            }
            : undefined;
        return this.http.get<suburbApi.suburbAgent.get.Response>(`${SUBURB_BASE_URL}/${suburbId}/calendar`, {
            params,
            ...HEADERS_CONFIG,
        });
    }

    public setSuburbProfile(suburbId: string, profile: boolean): Observable<suburbApi.setProfile.post.Response> {
        return this.http.post<suburbApi.setProfile.post.Response>(
            `${SUBURB_BASE_URL}/${suburbId}/profile/${profile}/`,
            null,
            HEADERS_CONFIG,
        );
    }

    public getStateSuburbs(state: string) {
        return this.http.get<suburbApi.getStateSuburbs.get.Response>(`/api/v2/state/${state}/suburbs/`, HEADERS_CONFIG);
    }

    public setSuburbAgent(suburbId: string, agentId: string) {
        return this.http.post<suburbApi.setAgent.post.Response>(
            `${SUBURB_BASE_URL}/${suburbId}/manual-agent/`,
            { agent: agentId },
            HEADERS_CONFIG,
        );
    }

    /** Reverts the suburb agent state back to it's original agent.
     * Eg. if the suburb was previously automatically calculated as unserviceable, an agent was added manually, and then this endpoint was called, the suburb would revert back to the unserviceable state. On the other hand, if the the suburb was automatically assigned to an agent, manually set to a different agent, and then this endpoint called, the suburb would revert to the original agent.
     */
    public revertSuburbAgent(suburbId: string) {
        return this.http.delete<suburbApi.revertAgent.post.Response>(
            `${SUBURB_BASE_URL}/${suburbId}/manual-agent/`,
            HEADERS_CONFIG,
        );
    }

    public setSuburbMarketing(suburbId: string, hasMarketing: boolean) {
        return this.http.post<suburbApi.setMarketing.post.Response>(
            `${SUBURB_BASE_URL}/${suburbId}/marketing/${hasMarketing}/`,
            undefined,
            HEADERS_CONFIG,
        );
    }

    public clearMultiAgent(suburbId: string) {
        return this.http.delete<suburbApi.clearMultiAgent._delete.Response>(
            `${SUBURB_BASE_URL}/${suburbId}/multi-agent/`,
            HEADERS_CONFIG,
        );
    }

    public setMultiAgent(suburbId: string, request: suburbApi.setMultiAgent.post.Request) {
        return this.http.post<suburbApi.setMultiAgent.post.Response>(
            `${SUBURB_BASE_URL}/${suburbId}/multi-agent/`,
            request,
            HEADERS_CONFIG,
        );
    }

    public getAgentSuburbs(agentId: string) {
        return this.http.get<suburbApi.AgentSuburbs.GET.Response>(`/api/v2/agent/${agentId}/suburbs`);
    }

    public getSuburbs(): Observable<SuburbCoreDetailsDto[]> {
        return this.http.get<SuburbCoreDetailsDto[]>(`${SUBURB_BASE_URL}/`);
    }

    public getSuburbListings(
        suburbId: string,
        listingType: ListingTypeDto,
        query: GetSuburbListingsQueryDto,
    ): Observable<SuburbExternalListingsResponseDto> {
        return this.http.get<SuburbExternalListingsResponseDto>(
            `${SUBURB_BASE_URL}/${suburbId}/listings/${listingType}/`,
            {
                params: stripNilValues(query),
            },
        );
    }
}

export { SuburbResource };
