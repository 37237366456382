<label
    class="radio-container"
    [ngClass]="{
        'is-checked': checked,
        'is-focused': isFocused,
        'is-disabled': disabled,
        'radio--boxed-borderless': style === 'boxed-borderless',
        'radio--boxed': style === 'boxed',
        'radio--inline': style === 'inline',
        'radio--tab': style === 'tab',
        'radio--card': style === 'card',
        'radio--align-center': alignment === alignmentEnum.Center,
        'radio--align-top': alignment === alignmentEnum.Top,
        'radio--pill': style === 'pill'
    }"
>
    <input
        class="u-hide-visually"
        type="radio"
        [disabled]="disabled"
        [id]="id"
        [value]="value"
        [checked]="checked"
        [attr.name]="name"
        (change)="onInputChange($event)"
        (click)="onInputClick($event)"
        (focus)="onFocus()"
        (blur)="onBlur()"
    />
    <div class="radio-indicator"><div class="input-focus-glow"></div></div>
    <div class="radio-label"> <ng-content></ng-content> </div>
</label>
