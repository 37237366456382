import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StateService } from '@uirouter/angular';
import { UIRouterGlobals } from '@uirouter/core';
import dayjs from 'dayjs';
import { distinctUntilChanged, finalize, map, startWith } from 'rxjs/operators';

import { BuyerCategory } from '../../../../common/models/domain/appointment/buyer-category.model';
import { environment } from '../../../../common/models/environment.model';
import { minimumDelay } from '../../../../operators/minimum-delay/minimum-delay.operator';
import { EnvironmentService } from '../../../core/services/environment.service';
import { NotificationService } from '../../../core/services/notification.service';
import { TitleService } from '../../../core/services/title.service';
import { ModalService } from '../../../modal/services/modal/modal.service';
import { CheckInUser } from '../../models/check-in-user.model';
import { SavedSessionService } from '../../services/saved-session/saved-session.service';
import { CheckInUseCase } from '../../use-cases/check-in/check-in.use-case';
import {
    GetInspectionCheckInDataUseCase,
    Result,
} from '../../use-cases/get-inspection-check-in-data/get-inspection-check-in-data.use-case';
import { FinancePreApprovalDialogComponent } from '../finance-pre-approval-dialog/finance-pre-approval-dialog.component';

export enum State {
    CheckIn,
    ExistingUser,
    NoOpen,
}

@UntilDestroy()
@Component({
    selector: 'nc-check-in',
    templateUrl: 'check-in.component.html',
    styleUrls: ['check-in.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckInComponent implements OnInit {
    public form: FormGroup;
    public BuyerCategories = [
        BuyerCategory.Enum.FirstHomeBuyer,
        BuyerCategory.Enum.Downsize,
        BuyerCategory.Enum.Upsize,
        BuyerCategory.Enum.Investor,
        BuyerCategory.Enum.BuyThenSell,
    ];
    public GroupedOrganisation = environment.GroupedOrganisationName;
    public State = State;
    public currentState: State = State.CheckIn;
    public formSubmitAttempted = false;
    public submitting = false;
    public loading = true;
    public property: Result.Property;
    public opens: Result.Open[];
    public open: Result.Open;
    public agent: Result.Agent;
    public existingUser: CheckInUser;
    public date = dayjs();

    constructor(
        private formBuilder: FormBuilder,
        private modalService: ModalService,
        private checkInUseCase: CheckInUseCase,
        private notificationService: NotificationService,
        private stateService: StateService,
        private environmentService: EnvironmentService,
        private getInspectionCheckInDataUseCase: GetInspectionCheckInDataUseCase,
        private changeDetectorRef: ChangeDetectorRef,
        private uiRouterGlobals: UIRouterGlobals,
        private savedSession: SavedSessionService,
        private titleService: TitleService,
    ) {}

    public get orgName(): string {
        return this.environmentService.config.organisation.displayName;
    }

    public ngOnInit(): void {
        this.createForm();
        this.load();
        this.handleSelectedInspectionWhenParamsChanged();
    }

    public showFinancePreApprovalModal(): void {
        this.modalService.show(FinancePreApprovalDialogComponent).subscribe();
    }

    public onSubmit(): void {
        this.formSubmitAttempted = true;
        if (this.form.invalid) return;

        this.submitting = true;

        const { termsAndPrivacy, ...formValues } = this.form.value;
        this.checkInUseCase
            .execute({
                appointmentId: this.open.appointmentId,
                form: formValues,
            })
            .pipe(
                this.notificationService.withNotification({
                    errorMessage: 'Error checking in, please try again',
                }),
                finalize(() => {
                    this.submitting = false;
                    this.changeDetectorRef.detectChanges();
                }),
            )
            .subscribe(({ brochureToken }) => {
                const userValues = formValues['user'];
                this.savedSession.storeSession(userValues);
                this.stateService.go('self-check-in.check-in.confirmed', {
                    brochureToken,
                    address: this.property.address,
                    timezone: this.property.timezone,
                    date: this.open.startDateTime,
                    duration: this.open.duration,
                    name: `${userValues['firstName']} ${userValues['lastName']}`,
                    phoneNumber: userValues['phoneNumber'],
                    email: userValues['email'],
                });
            });
    }

    public onCheckInAsExistingUser(): void {
        this.form.patchValue({ user: this.existingUser });
        this.currentState = State.CheckIn;
    }

    public onCheckInAsNewUser(): void {
        this.currentState = State.CheckIn;
        this.savedSession.clearSession();
    }

    public isFieldRequired(path: string): boolean {
        return this.form.get(path).hasValidator(Validators.required);
    }

    private load(): void {
        this.loading = true;
        this.changeDetectorRef.detectChanges();
        this.getInspectionCheckInDataUseCase
            .execute({
                propertyToken: this.uiRouterGlobals.params['propertyToken'],
                inspectionId: this.uiRouterGlobals.params['inspectionId'],
            })
            .pipe(
                minimumDelay(1000),
                this.notificationService.withNotification({
                    errorMessage: 'Error loading open, please try again',
                }),
            )
            .subscribe(({ allOpens, selectedOpen, property, agent }) => {
                const existingSession = this.savedSession.retrieveSession();

                this.property = property;
                this.agent = agent;
                this.open = selectedOpen;
                this.opens = allOpens;

                if (!selectedOpen && !allOpens?.length) {
                    this.currentState = State.NoOpen;
                } else if (!!existingSession?.user) {
                    this.currentState = State.ExistingUser;
                    this.existingUser = existingSession.user;
                } else {
                    this.currentState = State.CheckIn;
                }

                this.titleService.setTitleWithTrailingOrgName(`Self Check-in for ${this.property.address}`);

                this.loading = false;
                this.changeDetectorRef.detectChanges();
            });
    }

    private createForm(): void {
        this.form = this.formBuilder.group({
            user: this.formBuilder.group({
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]],
                phoneNumber: ['', Validators.required],
            }),
            finance: this.formBuilder.group({
                approved: '',
                amount: '',
            }),
            category: '',
            termsAndPrivacy: [false, Validators.requiredTrue],
            locality: null,
        });

        const approvedControl = this.form.get('finance.approved');

        approvedControl.valueChanges.pipe(startWith(approvedControl.value), untilDestroyed(this)).subscribe(value => {
            if (value) {
                this.form.get('finance.amount').enable();
            } else {
                this.form.get('finance.amount').disable();
            }
        });
    }

    private handleSelectedInspectionWhenParamsChanged(): void {
        this.uiRouterGlobals.params$
            .pipe(
                untilDestroyed(this),
                map(({ inspectionId }) => inspectionId),
                distinctUntilChanged(),
            )
            .subscribe(inspectionId => {
                if (!inspectionId) {
                    this.open = undefined;
                } else if (this.opens?.length) {
                    this.open = this.opens.find(({ appointmentId }) => appointmentId === inspectionId);
                }

                this.changeDetectorRef.detectChanges();
            });
    }
}
