import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { questionnaireApi } from '../../../common/models/questionnaire-api.model';
import { questionnaire } from '../../../common/models/questionnaire.model';

const QUESTIONNAIRES_BASE_URL = `/api/v2/questionnaires`;
const QUESTIONNAIRE_BASE_URL = `/api/v2/questionnaire`;
const HEADERS_CONFIG = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
};

@Injectable()
export class QuestionnaireResource {
    constructor(private http: HttpClient) {}

    public getQuestionnaire(questionnaireId: string): Observable<questionnaireApi.questionnaires.get.Response> {
        return this.http.get<questionnaireApi.questionnaires.get.Response>(
            `${QUESTIONNAIRES_BASE_URL}/${questionnaireId}`,
            HEADERS_CONFIG,
        );
    }

    public getQuestionnaireAnswersForContext({
        questionnaireId,
        context,
        contextId,
    }: questionnaireApi.questionnaire.context.answers.get.Request): Observable<
        questionnaireApi.questionnaire.context.answers.get.Response
    > {
        return this.http.get<questionnaireApi.questionnaire.context.answers.get.Response>(
            `${QUESTIONNAIRE_BASE_URL}/${questionnaireId}/${context}/${contextId}/answers`,
        );
    }

    public saveQuestionnaireAnswerForContext({
        questionnaireId,
        context,
        contextId,
        questionId,
        answer,
    }: questionnaireApi.questionnaire.context.answers.post.Request): Observable<
        questionnaireApi.questionnaire.context.answers.post.Response
    > {
        if (context === questionnaire.Context.propertyShadow) {
            return this.http.post<questionnaireApi.questionnaire.context.answers.post.Response>(
                `${QUESTIONNAIRE_BASE_URL}/${questionnaireId}/property-shadow/answers/${questionId}`,
                { answer },
                { ...HEADERS_CONFIG, params: new HttpParams().set('token', contextId) },
            );
        } else {
            return this.http.post<questionnaireApi.questionnaire.context.answers.post.Response>(
                `${QUESTIONNAIRE_BASE_URL}/${questionnaireId}/${context}/${contextId}/answers/${questionId}`,
                { answer },
                HEADERS_CONFIG,
            );
        }
    }
}
