import { NgModule } from '@angular/core';

import { IconModule } from '../../common/components/icon/icon.module';

import { HiddenContentIndicatorComponent } from './components/hidden-content-indicator/hidden-content-indicator.component';

@NgModule({
    imports: [IconModule],
    exports: [HiddenContentIndicatorComponent],
    declarations: [HiddenContentIndicatorComponent],
})
export class HiddenContentIndicatorModule {}
