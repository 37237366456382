<button class="dropdown-trigger" [ncMenuTriggerFor]="countryDropdown" [menuConfig]="{ align: 'end', offsetY: 8 }">
    <img
        [alt]="'Flag of ' + (currentCountry$ | async).displayName"
        width="34"
        height="17"
        class="u-margin-right--sm"
        [lazyLoad]="'/static/images/logo/country-flag-' + (currentCountry$ | async).isoAlpha2 + '.svg'"
    />
    <div class="display-name">
        {{ (currentCountry$ | async).displayName }}
    </div>
    <div class="short-display-name">
        {{ (currentCountry$ | async).shortDisplayName }}
    </div>
    <nc-hidden-content-indicator [style]="'arrow-filled'" class="u-margin-left--xs"></nc-hidden-content-indicator>
</button>

<ng-template #countryDropdown>
    <nc-menu>
        <nc-menu-list [heading]="'Select country:'">
            <li *ngFor="let selectableCountry of selectableCountries$ | async">
                <a nc-menu-list-item-content [href]="selectableCountry.targetUrl">
                    {{ selectableCountry.displayName }}
                </a>
            </li>
        </nc-menu-list>
    </nc-menu>
</ng-template>
