<form [formGroup]="form" (ngSubmit)="submit()">
    <div class="update-controls">
        <up-input
            class="update-controls-input"
            [error]="hasAttemptedSubmit && form.get('phoneNumber').invalid"
            [formControlName]="'phoneNumber'"
            [type]="'text'"
            [label]="'Mobile number'"
            [size]="'large'"
            [internalLabel]="true"
            [icon]="'lock'"
            [iconColor]="Colors.brand2Regular"
            [fullWidth]="true"
        ></up-input>
        <up-button
            class="update-controls-action u-margin-left--sm"
            [size]="'large'"
            [style]="'primary-dark'"
            [loading]="isSubmitting"
            [type]="'submit'"
        >
            Save
        </up-button>
    </div>
</form>
<up-help-text *ngIf="hasAttemptedSubmit && form.get('phoneNumber').invalid" [type]="'error'">
    Please enter a valid phone number.
</up-help-text>
<up-alert class="u-margin-top--md" *ngIf="errorUpdatingPhoneNumber" [size]="'small'" [type]="'negative'">
    {{ errorUpdatingPhoneNumber }}
</up-alert>
