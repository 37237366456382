import { Injectable, NgModule } from '@angular/core';
import {
    Translation,
    TRANSLOCO_CONFIG,
    TRANSLOCO_LOADER,
    translocoConfig,
    TranslocoLoader,
    TranslocoModule,
    TRANSLOCO_TRANSPILER,
} from '@ngneat/transloco';
import { HashMap } from '@ngneat/transloco/lib/types';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { MessageFormatTranspiler, TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { Observable, of } from 'rxjs';

import enTranslation from '../../../static/i18n/en.json';
import { isProduction } from '../../common/utilities/is-production.util';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    public getTranslation(): Observable<Translation> {
        // Todo (WEB-1192): The translation has been temporarily embedded due to
        //  CORS issues fetching the translations from the CDN.
        return of(enTranslation);
    }
}

// Custom transpiler that escapes all params before passing them to MessageFormat. We often pass in strings as params
// that we don't control (e.g. a user's display name), so we need to make sure to escape them to prevent errors from
// MessageFormat.
@Injectable()
export class CustomTranspiler extends MessageFormatTranspiler {
    public transpile(value: any, params: HashMap = {}, translation: Translation): any {
        const escapedParams = Object.entries(params).reduce(
            (acc, [paramKey, paramValue]) => ({
                ...acc,
                [paramKey]: this.escape(paramValue),
            }),
            {},
        );

        return super.transpile(value, escapedParams, translation);
    }

    // Adapted from MessageFormat.escape(). Surrounds all curly braces in single quotes.
    // See: https://github.com/messageformat/messageformat/blob/main/packages/core/src/messageformat.ts#L176
    private escape(str: string): string {
        return String(str).replace(/[{}]/g, '\'$&\'');
    }
}

@NgModule({
    imports: [
        TranslocoMessageFormatModule.forRoot({
            locales: ['en-AU'],
            customFormatters: {
                lowerCase: (value, locale) => (typeof value === 'string' ? value.toLocaleLowerCase(locale) : value),
            },
        }),
        TranslocoLocaleModule.forRoot(),
    ],
    exports: [TranslocoModule],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: ['en'],
                defaultLang: 'en',
                reRenderOnLangChange: true,
                prodMode: isProduction(),
            }),
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
        { provide: TRANSLOCO_TRANSPILER, useClass: CustomTranspiler },
    ],
})
export class TranslocoRootModule {}
