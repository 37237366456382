import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StateService } from '@uirouter/core';

import { EnvironmentService } from '../../../modules/core/services/environment.service';
import { AddressT } from '../../models/address.model';
import { TrackingService } from '../../services/tracking.service';

@Component({
    selector: 'up-upsell-report-banner-section',
    templateUrl: 'upsell-report-banner-section.component.html',
    styleUrls: ['upsell-report-banner-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsellReportBannerSectionComponent {
    constructor(
        private stateService: StateService,
        private trackingService: TrackingService,
        private environmentService: EnvironmentService,
    ) {}

    public get orgDisplayName(): string {
        return this.environmentService.config.organisation.displayName;
    }

    public onAddressUpdated(address?: AddressT): void {
        if (!address) return;

        this.trackingService.trackDataEvent('upsellReportBannerAction', {
            subname: 'addressSubmitted',
            address: address.formattedAddress,
        });
        this.stateService.go('conversion.property-report.address-found', { address });
    }
}
