import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { TransitionService } from '@uirouter/core';

import {
    CollapserPanelComponent,
    CollapserPanelState,
} from '../../collapser-panel/components/collapser-panel/collapser-panel.component';
import { NavPaneButtonComponent } from '../nav-pane-button/nav-pane-button.component';

@Component({
    selector: 'up-nav-pane-secondary-nested-items',
    templateUrl: 'nav-pane-secondary-nested-items.component.html',
    styleUrls: ['nav-pane-secondary-nested-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavPaneSecondaryNestedItemsComponent implements OnInit, AfterViewInit, OnDestroy {
    @ContentChildren(NavPaneButtonComponent) public navPaneButtonComponents: QueryList<NavPaneButtonComponent>;
    @ViewChild('indicator') public indicatorTemplateRef: TemplateRef<HTMLElement>;
    @Output() public stateChange = new EventEmitter<CollapserPanelState>();
    @ViewChild(CollapserPanelComponent) private collapserPanelComponentRef: CollapserPanelComponent;
    private destroyOnFinish: Function;

    private get hasActiveChild(): boolean {
        return this.navPaneButtonComponents.toArray().some(c => c.isRelatedState);
    }

    constructor(private transitionService: TransitionService, private changeDetectorRef: ChangeDetectorRef) {}

    public ngOnInit() {
        this.destroyOnFinish = this.transitionService.onFinish(undefined, () => this.updateActiveState());
    }

    public ngOnDestroy(): void {
        if (this.destroyOnFinish) {
            this.destroyOnFinish();
        }
    }

    public ngAfterViewInit(): void {
        this.updateActiveState();
        this.setInitialCollapseState();
        this.injectIndicator();
    }

    public onCollapserPanelStateChange(state: CollapserPanelState): void {
        this.stateChange.emit(state);
    }

    public open(noTransition?: boolean, emitEvent = true): void {
        this.collapserPanelComponentRef.openPanel(noTransition, emitEvent);
    }

    public close(noTransition?: boolean, emitEvent = true): void {
        this.collapserPanelComponentRef.closePanel(noTransition, emitEvent);
    }

    private setInitialCollapseState(): void {
        // Prevents expressionChangedAfterItHasBeenCheckedError as setting expander state to open/close state can only
        // be done only after the component has load and able to determine the active state of the child elements
        setTimeout(() => {
            if (this.hasActiveChild) {
                this.open(true, false);
            } else {
                this.close(true, false);
            }
        });
    }

    private updateActiveState(): void {
        // Need to wait until child elements have updated before trying to access its active state
        setTimeout(() => {
            this.navPaneButtonComponents.toArray()[0].setActive(this.hasActiveChild);
        });
    }

    private injectIndicator(): void {
        // Prevents expressionChangedAfterItHasBeenCheckedError as the `name` input for the `up-icon` component inside
        // the template ref gets resolved half way through the change detection lifecycle
        setTimeout(() => {
            this.navPaneButtonComponents.toArray()[0].trailing.createEmbeddedView(this.indicatorTemplateRef);
            this.changeDetectorRef.detectChanges();
        });
    }
}
