import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import moment, { Moment } from 'moment-timezone';
import { combineLatest, Observable, Subject, of } from 'rxjs';
import {
    catchError,
    filter,
    finalize,
    first,
    map,
    publishReplay,
    refCount,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';

import { AddressT } from '../../../../common/models/address.model';
import { agent } from '../../../../common/models/agent.model';
import { conversion } from '../../../../common/models/conversion.model';
import { agentProfiles } from '../../../../common/models/domain/agent/agent-profiles.model';
import { UserBase } from '../../../../common/models/user.model';
import { AddressService } from '../../../../common/services/address.service';
import { TrackingService } from '../../../../common/services/tracking.service';
import { UtmService } from '../../../../common/services/utm.service';
import { AgentResource } from '../../../../modules/core/resources/agent.resource';
import { AppraisalResource } from '../../../../modules/core/resources/appraisal.resource';
import { ConversionService } from '../../../../modules/core/services/conversion/conversion.service';
import { ConversionFacade } from '../../../../store/conversion/conversion.facade';

import { DateConfig } from './appraisal-booker-calendar/appraisal-booker-calendar.component';

export interface TimeSlots {
    [date: string]: Moment[];
}

@Component({
    selector: 'up-appraisal-booker',
    templateUrl: './appraisal-booker.component.html',
    styleUrls: ['./appraisal-booker.component.scss'],
})
export class AppraisalBookerComponent implements OnInit, OnDestroy {
    @Input() public address: AddressT;
    @Input() public step: number;
    @Input() public funnelName: conversion.Funnel;
    @Input() public calendarTimeUnavailable: boolean;
    @Input() public submissionError: string;
    @Input() public appraisalAlreadyRequested: boolean;
    public dateConfigs$: Observable<DateConfig[]>;
    public timeSlots$: Observable<TimeSlots>;
    public activeDateKey: string;
    public activeDate: Moment;
    public isLoading: boolean;
    public isError: boolean;
    public hasAttemptedSubmit: boolean;
    public agent$: Observable<agent.CalendarAgent>;
    public conversionFunnelEnum = conversion.Funnel;
    public timezone$: Observable<string>;
    public agentProfile: agentProfiles.Profile;
    public user$: Observable<UserBase>;
    public isLoadingAgentProfile: boolean;
    public selectedTime: Moment;
    private timeSlotIntervalsMinutes = 30;
    private travelTimeMinutes = 60;
    private timeSlotDurationMinutes = 90;
    private dateKeyFormat = 'YYYY-MM-DD';
    private destroy$: Subject<void> = new Subject<void>();

    @HostBinding('class.is-appraisal-funnel')
    public get isAppraisalFunnel(): boolean {
        return this.funnelName === conversion.Funnel.Appraisal;
    }

    constructor(
        private addressService: AddressService,
        private conversionService: ConversionService,
        private stateService: StateService,
        private utmService: UtmService,
        private appraisalResource: AppraisalResource,
        private trackingService: TrackingService,
        private conversionFacade: ConversionFacade,
        private agentResource: AgentResource,
    ) {}

    public ngOnInit(): void {
        const appraisalState$ = this.conversionFacade.appraisalState$.pipe(takeUntil(this.destroy$));
        this.agent$ = appraisalState$.pipe(map(a => a.agent));

        if (this.funnelName === conversion.Funnel.Calendar) {
            appraisalState$.subscribe(({ agent, loadingAgentError }) => {
                if (!agent && loadingAgentError) {
                    this.stateService.go('conversion.booking', undefined, { location: 'replace' });
                }
            });

            // if the user has gone through the flow but the calendar time has become unavailable after they selected it
            // (eg. another user picked the same time and finished the flow first), then this flag will be true and we
            // should force a refresh of the agent's calendar data
            if (this.calendarTimeUnavailable) {
                this.agent$
                    .pipe(
                        filter(agent => !!agent),
                        take(1),
                    )
                    .subscribe(({ slug }) => this.conversionFacade.getAgentCalendarBySlug(slug));
            } else {
                // if the user isn't coming back to this page due to a time selection error then we should treat it
                // as though they just landed in the calendar funnel and clear any conversion state that exists
                this.conversionService.resetConversionState();
                this.conversionFacade.resetUserEnteredState();
            }
        } else {
            if (this.conversionService.hasStoredAddress()) {
                this.address = this.conversionService.getConversionState().address;
            }
            if (!this.address) {
                this.conversionService.routeGuard(true);
                return;
            }
        }
        const calendar$ = this.conversionService.getAppraisalStoreState().pipe(
            first(
                ({ calendar, isLoadingAgent }) =>
                    !!(calendar && calendar.availableTimes && calendar.availableTimes.length && !isLoadingAgent),
            ),
            map(state => state.calendar),
        );
        this.timezone$ = calendar$.pipe(map(calendar => calendar.timeZone));
        this.timeSlots$ = calendar$.pipe(
            map(({ availableTimes, timeZone }) => {
                const allTimeSlots: TimeSlots = {};
                availableTimes.forEach(availableTime => {
                    const dateKey = moment(availableTime.start).tz(timeZone).format(this.dateKeyFormat);
                    const timeSlots = this.createTimeSlotsFromAvailableTime(availableTime, timeZone);
                    // If date already exists, concat new times onto the date
                    allTimeSlots[dateKey] = allTimeSlots[dateKey] ? allTimeSlots[dateKey].concat(timeSlots) : timeSlots;
                });
                return allTimeSlots;
            }),
            // Prevents map from firing with every subscription
            publishReplay(1),
            refCount(),
        );
        this.dateConfigs$ = combineLatest([this.timezone$, this.timeSlots$]).pipe(
            map(([timezone, timeSlots]) => {
                const now = moment().tz(timezone);
                // Generate dates from 2 days before today, plus 30 days in the future
                const dates = this.generateDays(now, 30);
                return dates.map(date => {
                    const dateKey = moment(date).format(this.dateKeyFormat);
                    return {
                        date,
                        // If today, or in the past, or if date has no time slots
                        isDisabled: date.isSameOrBefore(now, 'day') || !timeSlots[dateKey],
                    };
                });
            }),
            publishReplay(1),
            refCount(),
        );

        if (this.funnelName !== conversion.Funnel.Calendar) {
            const suburbId = this.addressService.createSuburbIdFromAddress(this.address);
            this.conversionService.getSuburbAgent(suburbId, this.address.geoLocation);
        }

        if (this.isAppraisalFunnel) {
            this.agent$
                .pipe(
                    filter(agent => !!(agent && agent.slug)),
                    tap(() => (this.isLoadingAgentProfile = true)),
                    switchMap(({ slug }) => this.agentResource.profile(slug)),
                    catchError(() => of(undefined)),
                    finalize(() => (this.isLoadingAgentProfile = false)),
                    // Take first result to ensure stream completes and triggers the finalize. This is needed because even
                    // though the switchMap inner obs completes, the outer obs doesn't since it's still subscribed to
                    // appraisalState$.
                    take(1),
                )
                .subscribe(agentProfile => (this.agentProfile = agentProfile));
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public get hasError(): boolean {
        return this.calendarTimeUnavailable || !!this.submissionError || this.appraisalAlreadyRequested;
    }

    public onDateChange(dateTime: Moment): void {
        this.hasAttemptedSubmit = false;
        this.selectedTime = undefined;
        this.activeDate = moment(dateTime);
        this.activeDateKey = moment(dateTime).format(this.dateKeyFormat);
    }

    public onSelectTime(dateTime: Moment): void {
        this.selectedTime = moment(dateTime);
    }

    public next(): void {
        this.hasAttemptedSubmit = true;
        if (!this.selectedTime) return;

        this.isLoading = true;
        this.isError = false;

        switch (this.funnelName) {
            case conversion.Funnel.Calendar: {
                this.conversionFacade.setAppraisalBookedTime(moment(this.selectedTime));
                // if the user has been sent back to this component to select a valid time, we already have the address
                // so we can just send them straight to the capture details page
                const next = this.calendarTimeUnavailable ? '^.capture-details' : '^.address';
                this.stateService.go(next, { address: this.address });
                break;
            }

            case conversion.Funnel.Booking: {
                this.conversionFacade.setAppraisalBookedTime(moment(this.selectedTime));
                this.stateService.go('^.capture-details', { address: this.address });
                break;
            }

            default: {
                this.conversionFacade.conversionState$
                    .pipe(
                        first(),
                        map(state => ({
                            user: state.user,
                            utm: this.utmService.getStoredUtmCodes(),
                            address: this.address,
                            dateTime: moment(this.selectedTime).utc().format(),
                        })),
                        switchMap(request => this.appraisalResource.requestAppraisal(request)),
                        finalize(() => {
                            this.hasAttemptedSubmit = false;
                            this.isLoading = false;
                        }),
                        map(response => response.body),
                    )
                    .subscribe(
                        response => {
                            this.conversionFacade.setAppraisalBookedTime(moment(this.selectedTime));
                            this.trackingService.trackEvent('appraisal-booked');
                            this.stateService.go('^.questionnaire', {
                                address: this.address,
                                propertyAnswerToken: response.propertyAnswerToken,
                                propertyId: response.property && response.property.id,
                                funnelName: conversion.Funnel.Appraisal,
                            });
                            this.conversionService.resetConversionState();
                        },
                        () => (this.isError = true),
                    );
                break;
            }
        }
    }

    private generateDays(startingDate: Moment, numberOfDays: number): Moment[] {
        return Array.from({ length: numberOfDays }, (value, index) => {
            return moment(startingDate).add(index, 'days');
        });
    }

    private createTimeSlotsFromAvailableTime(availableTime: agent.AvailableTime, timezone: string): Moment[] {
        const availableTimeStart = moment(availableTime.start).tz(timezone);
        // Start time at the hour, i.e. if start time is 7:10, this will be 7:00
        const availableTimeStartAtHour = moment(availableTimeStart).set({ minutes: 0, seconds: 0 });
        const availableTimeEnd = moment(availableTimeStart).add(moment.duration(availableTime.duration));
        const timeSlots: Moment[] = [];
        // Create time slots at timeSlotIntervalsMinutes from start until end times
        for (
            let i = 0;
            moment(availableTimeStartAtHour)
                .add(i * this.timeSlotIntervalsMinutes, 'minutes')
                .isSameOrBefore(availableTimeEnd, 'second');
            i++
        ) {
            timeSlots.push(moment(availableTimeStartAtHour).add(i * this.timeSlotIntervalsMinutes, 'minutes'));
        }
        // Filter out non-valid times, i.e. times where travelTimeMinutes is included would be before the start
        // available time and times where timeSlotDurationMinutes is included would be after the end available time
        return timeSlots.filter(timeSlot => {
            // isSame does not seem to work as expected without precision, e.g. 'second'
            const isValidStartTime = moment(timeSlot)
                .subtract(this.travelTimeMinutes, 'minutes')
                .isSameOrAfter(availableTimeStart, 'second');
            const isValidEndTime = moment(timeSlot)
                .add(this.timeSlotDurationMinutes, 'minutes')
                .isSameOrBefore(availableTimeEnd, 'second');
            return isValidStartTime && isValidEndTime;
        });
    }
}
