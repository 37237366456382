<div class="l-page-section c-hero hero">
    <div class="l-page-section__inner"> <h1 class="c-hero__heading">Terms &amp; Conditions</h1> </div>
</div>
<div class="l-page-section">
    <div class="l-page-section__inner l-padding-ends--medium">
        <p
            >These terms and conditions of use (‘<strong>Platform Terms</strong>’) apply to the use of the following
            interactive platforms provided by Upside Realty Pty Ltd (ACN 619 316 602) and/ or its subsidiaries
            (‘<strong>Upside</strong>’) which include the website www.upside.com.au (‘<strong>Website</strong>’), any
            Upside mobile application (‘<strong>App</strong>’), any Upside social media accounts used by Upside from
            time to time, and software provided on or in connection with the foregoing (together, ‘<strong
                >Upside Platforms</strong
            >’). In these Platform Terms, “we” “us” and “our” refers to Upside and we refer to you as "you", "your", or
            "User".</p
        >
        <p
            >In the event that you enter into a signed agency agreement (‘<strong>Agency Agreement</strong>’) in respect
            of your Property between you and Upside as required by applicable law, and become a ‘Vendor’ for the
            purposes of real estate agency and associated services, you will also be subject to the terms and conditions
            of the Vendor Services Schedule (‘<strong>VSS</strong>’), available <a href="#">here</a>. The terms of the
            VSS form part of the Agency Agreement with the relevant licensed Upside entity (‘<strong
                >Upside Agency</strong
            >’) with which the Vendor has contracted in the relevant State. In such case, to the extent that the use of
            the Upside Platforms includes services that are only available to you once you have signed an Agency
            Agreement, such features are provided to you by Upside as service provider for the Upside Agency. To avoid
            doubt, a User is referred to as a ‘Vendor’ under the VSS.</p
        >
        <p
            >By accessing or using the Upside Platforms, you acknowledge that you have read, understood, and agree to be
            bound by these Platform Terms. Upside may change all or part of the Platform Terms at any time. If we do,
            the new Platform Terms will be posted on the Upside Platforms. Your subsequent or continued use of the
            Upside Platforms will constitute your acceptance of any changes. If you object to any changes to the
            Platform Terms, your exclusive remedy is to immediately discontinue your use of the Upside Platforms.</p
        >
    </div>
</div>
<div class="l-page-section l-page-section--alt">
    <div class="l-page-section__inner l-padding-ends--medium">
        <h3 class="page-subheading">1. User Account</h3>
        <p
            ><strong>1.1</strong> In order to access some of the features of the Upside Platforms you must create an
            Upside user account (‘<strong>User Account</strong>’). You must be at least 18 years of age in order to use
            the Upside Platforms. Registering and creating a User Account is free.</p
        >
        <p
            ><strong>1.2</strong> In order to complete registration of a User Account, you must provide certain
            information as set out on the registration page. You agree to provide accurate, current and complete
            information during the registration process, and to update such information to ensure it remains accurate,
            current and complete.</p
        >
        <p
            ><strong>1.3</strong> Please refer to our Privacy Policy for information relating to our collection, storage
            and use of the details you provide on registration. The Privacy Policy is available
            <a uiSref="privacy-policy">here</a>.</p
        >
        <p
            ><strong>1.4</strong> On registration of your User Account, you will need to create a username and password.
            You are solely responsible for the activity that occurs on your User Account (including unauthorised use of
            your credit card) and you must keep your User Account password secure. You must notify Upside immediately of
            any breach of security or unauthorised use of your User Account. Upside will not be liable for any losses
            caused by any unauthorised use of your User Account.</p
        >
        <p
            ><strong>1.5</strong> You acknowledge that Upside reserves the right to change the Upside Platforms; stop
            providing the Upside Platforms or features of the Upside Platforms, to you or to Users generally; or create
            usage limits for the Upside Platforms. Upside may temporarily or permanently suspend or terminate access to
            the Upside Platforms, including your User Account, without notice and liability for any reason, including if
            in our sole determination you violate any provision of these Platform Terms. All restrictions imposed on you
            and all disclaimers, indemnities and limitations of liability set out in these Platform Terms will survive
            termination of your access to the Upside Platforms and Upside Platforms.</p
        >
        <p
            ><strong>1.6</strong> We may elect to send you notices with respect to the Upside Platforms and your User
            Account by:</p
        >
        <ol class="page-list page-list--alpha">
            <li>e-mail message to the email address;</li>
            <li>letter to the postal mail address; or</li>
            <li>SMS to the mobile number,</li>
        </ol>
        <p>listed in your User Account.</p>
        <p
            ><strong>1.7</strong> You must not use the Upside Platforms to (or attempt to) upload, post, transmit or
            otherwise make available any text, illustrations, photos, audio, video, data or any other material
            (‘<strong>Material</strong>’), or do any activity that:</p
        >
        <ol class="page-list page-list--alpha">
            <li
                >is not your original work, or which in any way violates or infringes (or could reasonably be expected
                to violate or infringe) third-party rights of any kind, including without limitation any patent,
                copyright, moral rights, trade mark, trade dress and service mark rights, goodwill, trade secret rights
                and other intellectual property rights as may now exist or hereafter come into existence, and all
                applications, registrations, renewals and extensions thereof, under the laws of any state, country,
                territory or other jurisdiction;</li
            >
            <li>contains any information or data or content that you know is not correct and current;</li>
            <li>contains, promotes, or provides information about unlawful activities or conduct;</li>
            <li
                >could harm, abuse, harass, stalk, threaten or could reasonably be expected to be, defamatory, obscene,
                offensive, threatening, abusive, pornographic, vulgar, profane, indecent or otherwise unlawful,
                including Material that racially or religiously vilifies, incites violence or hatred, or is likely to
                offend, insult or humiliate others based on race, religion, ethnicity, gender, age, sexual orientation
                or any physical or mental disability;</li
            >
            <li
                >may create a risk of harm, loss, physical or mental injury, emotional distress, death, disability,
                disfigurement, or physical or mental illness to any person;</li
            >
            <li>may create a risk of any other loss or damage to any property;</li>
            <li>may constitute or contribute to a crime or tort;</li>
            <li
                >includes an image or personal information of another person or persons unless you have their
                consent;</li
            >
            <li>impersonates any person or entity;</li>
            <li>poses or creates a privacy or security risk to any person;</li>
            <li
                >transmits (or authorises the transmission of) unsolicited or bulk electronic messages to anyone, or
                otherwise constitutes an infringement of the Spam Act 2003 (Cth);</li
            >
            <li>solicits money, passwords or personal information from any person;</li>
            <li
                >you know or suspect (or ought reasonably to have known or suspected) to be false, misleading or
                deceptive;</li
            >
            <li
                >contains viruses, or other computer codes, files or programs designed to interrupt, limit or destroy
                the functionality of other computer software or hardware;</li
            >
            <li
                >mines data or conducts automated searches on the Upside Platforms or the data or content on the Upside
                Platforms, whether through the use of additional software or otherwise;</li
            >
            <li
                >incorporates any of the data or content on or accessed via the Upside Platforms with any other data or
                content, including but not limited to advertising or promotional material;</li
            >
            <li
                >creates links from the Upside Platforms to any other website on the internet, or frames or mirrors the
                Website, its content or data without our prior written consent;</li
            >
            <li
                >tampers with, hinders the operation of, or makes unauthorised modifications to the Upside Platforms or
                any data or content on the Upside Platforms; or</li
            >
            <li>is in breach of any applicable laws or regulations.</li>
        </ol>
        <p><strong>1.8</strong> Upside reserves the right (but has no obligation) to:</p>
        <ol class="page-list page-list--alpha">
            <li
                >review, modify, reformat, reject or remove any Material which you upload, post, transmit or otherwise
                make available (or attempt to upload, post, transmit or otherwise make available) on the Upside
                Platforms that, in our opinion, violates the Platform Terms or otherwise has the potential to harm,
                endanger or violate the rights of any person; and</li
            >
            <li
                >monitor use of the Upside Platforms and store or disclose any information that we collect, including
                but not limited to in order to investigate compliance with the Platform Terms or for the purposes of any
                police investigation or governmental request.</li
            >
        </ol>
        <p
            ><strong>1.9</strong> Upside reserves the right, in its sole discretion, to assert legal action with respect
            to Material or use of the Upside Platforms, that Upside reasonably believes is or might be in violation of
            these Platform Terms or any Upside policies. Upside’s failure or delay in taking such actions does not
            constitute a waiver of its rights to enforce these Platform Terms.</p
        >
        <p
            ><strong>1.10</strong> Upside will not be responsible for, and accepts no liability with respect to, any
            Material uploaded, posted, transmitted or otherwise made available on the Upside Platforms or posted on a
            third party platform about Upside and/ or the Upside Platforms by any User or any other person or third
            party other than us. For the avoidance of doubt, we will not be taken to have uploaded, posted, transmitted
            or otherwise made Material available on the Upside Platforms simply by facilitating others to post, transmit
            or otherwise make Material available. Furthermore, we do not endorse any opinion, advice or statement made
            by any User or any other person or entity other than us.</p
        >
    </div>
</div>

<div class="l-page-section">
    <div class="l-page-section__inner l-padding-ends--medium">
        <h3 class="page-subheading">2. Third party links and data</h3>
        <p
            ><strong>2.1</strong> The Upside Platforms may link to third party websites or social media pages which are
            not owned or operated by Upside. These links are provided for your convenience only. We do not endorse or
            approve the views, data or content of these third party websites or social media accounts and will not be
            held liable for any loss or damage suffered as a result of your use or the views, data or content contained
            therein.</p
        >
        <p
            ><strong>2.2</strong> The Upside Platforms may also contain text, illustrations, photos, audio, video, data
            or any other material provided by third parties (‘<strong>Third Party Content</strong>’). We do not have a
            practice of monitoring or making enquiries about Third Party Content. We are not responsible for, do not
            endorse and do not make representations, either expressly or impliedly, concerning Third Party Content.
            Third party Content does not represent the views of Upside. You rely on Third Party Content at your own
            risk.</p
        >
    </div>
</div>
<div class="l-page-section l-page-section--alt">
    <div class="l-page-section__inner l-padding-ends--medium">
        <h3 class="page-subheading">3. Payments</h3>
        <p
            ><strong>3.1</strong> Payments will be enabled by Upside via a third party service provider (‘<strong
                >Payment Provider</strong
            >’) to provide payment services and act as a payment facilitator, subject to any terms of the Payment
            Provider that apply to the preferred payment method designated in your User Account.</p
        >
        <p
            ><strong>3.2</strong> By registering and creating a User Account, you agree to be bound by the Payment
            Provider's terms and conditions and Privacy Policy which are located at
            <a href="https://stripe.com/au/privacy">https://stripe.com/au/privacy</a> and hereby consent and authorise
            Upside and the Payment Provider to share any information and payments instructions you provide with one
            another and, to the extent required to complete your transaction, with any other third party service
            provider(s).</p
        >
        <p
            ><strong>3.3</strong> Upside does not handle or hold your financial information, and as a consequence is not
            required to maintain PCI compliance. After the transaction has been processed you will receive a
            confirmation and receipt of payment.</p
        >
        <p
            ><strong>3.4</strong> You may be able to use the Upside Platform to order a free approximate market value
            report (‘<strong>AVMR</strong>’) to obtain an approximate guide of the current market value of your
            property. The AVMR is provided by a third party without a physical inspection of the relevant property and
            is an estimate only of the approximate market value of the property. The AVMR, is provided on an “as is”
            basis and Upside does not, to the extent permitted by the law, give or make any express or implied
            warranties, representations, conditions, statements or endorsements whatsoever regarding the accuracy of the
            AVMR. You accept that the accuracy of the AVMR is dependent on the information you and other third parties
            provide to Upside about your property and it remains your sole risk and responsibility to evaluate the
            accuracy.</p
        >
    </div>
</div>
<div class="l-page-section">
    <div class="l-page-section__inner l-padding-ends--medium">
        <h3 class="page-subheading">4. Limitation of liability</h3>
        <p
            ><strong>4.1</strong> To the maximum extent permitted by applicable law, Upside and its directors, officers,
            employees, contractors, subsidiaries, agents, licensors, managers and affiliated companies will not be
            liable for any direct, indirect, punitive, incidental, special, consequential or exemplary damages,
            including without limitation damages for loss of profits, revenue, goodwill, opportunity, business, data or
            other intangible losses, whether arising under contract, statute, negligence or other tort or otherwise,
            that result from the use of, or inability to use, the Upside Platforms including the data and content
            provided on or through the Upside Platforms. Under no circumstances will Upside be responsible for any
            damage, loss or injury resulting from hacking, tampering or other unauthorised access or use of the Upside
            Platforms.</p
        >
        <p
            ><strong>4.2</strong> Certain legislation, including the Australian Consumer Law may imply warranties or
            conditions or impose obligations upon us which cannot be excluded, restricted or modified except to a
            limited extent. These Platform Terms must be read subject to these statutory provisions. If these statutory
            provisions apply, to the extent to which Upside are able to do so, the liability of Upside and its
            affiliates, agents, directors, employees, suppliers, or licensors under those provisions will be limited, at
            its option to supplying the relevant Upside Platforms again; or the total amount of fees paid by you to us
            for Upside Platforms.</p
        >
        <p
            ><strong>4.3</strong> To the maximum extent permitted by applicable law, Upside assumes no liability or
            responsibility for:</p
        >
        <ol class="page-list page-list--alpha">
            <li>any errors, mistakes, or inaccuracies of content or data;</li>
            <li
                >any personal injury or property damage, of any nature whatsoever, resulting from your access to or use
                of the Upside Platforms;</li
            >
            <li
                >any unauthorised access to or use of our secure servers and/or any and all personal information stored
                therein;</li
            >
            <li>any interruption or cessation of transmission to or from the Upside Platforms;</li>
            <li
                >any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Upside
                Platforms by any third party;</li
            >
            <li
                >any errors or omissions in any content or data, or for any loss or damage incurred as a result of the
                use of any content or data posted, emailed, transmitted, or otherwise made available through the Upside
                Platforms; and/or</li
            >
            <li>any Material or the defamatory, offensive, or illegal conduct of any third party.</li>
        </ol>
    </div>
</div>
<div class="l-page-section l-page-section--alt">
    <div class="l-page-section__inner l-padding-ends--medium">
        <h3 class="page-subheading">5. Warranties</h3>
        <p
            ><strong>5.1</strong> Subject to any statutory guarantees that cannot be lawfully excluded, Upside expressly
            excludes all warranties, guarantees, conditions and representations, whether express or implied, in relation
            to any Upside Platforms supplied or provided by us (including as to performance, quality or fitness for use
            or purpose, or any warranty or representation that the products will be free from defects).</p
        >
        <p><strong>5.2</strong> To the maximum extent permitted by applicable law:</p>
        <ol class="page-list page-list--alpha">
            <li
                >all statutory and implied guarantees, warranties and conditions of any kind in connection with the
                Upside Platforms including without limitation, Third Party Content, provided on or through the Upside
                Platforms are expressly disclaimed;</li
            >
            <li
                >Upside do not warrant that your access to the Upside Platforms will be uninterrupted or that the Upside
                Platforms will operate error free, that any defects will be corrected, or that the Upside Platforms and
                their servers are free of computer viruses, trojans, spyware and other harmful material;</li
            >
            <li
                >Upside do not warrant that we will be able to prevent any illegal, harmful, unauthorised or
                inappropriate use, modification or alteration of the Upside Platforms, or that we will give you notice
                of such use, modification or alteration;</li
            >
            <li
                >Upside do not warrant that we will be able to prevent any illegal, harmful, unauthorised, incorrect or
                inappropriate use, modification or alteration of any content or data by third parties that is provided
                by Upside through the Upside Platforms or taken from data or content contained therein;</li
            >
            <li
                >Upside do not warrant or make any representations about the correctness, accuracy, timeliness,
                completeness, reliability, quality or otherwise of the Upside Platforms or the information contained
                therein. Your use of the Upside Platforms and the information contained therein at your own risk;
                and</li
            >
            <li
                >the Upside Platforms and the information contained therein are provided on an “as is” and “as
                available” basis without any warranties of any kind. We may change the Upside Platforms or limit or
                terminate your access to the Upside Platforms at any time without notice.</li
            >
        </ol>
    </div>
</div>
<div class="l-page-section">
    <div class="l-page-section__inner l-padding-ends--medium">
        <h3 class="page-subheading">6. Indemnities</h3>
        <p
            >As a condition of use of the Upside Platforms, you, at your sole cost and expense, will defend, indemnify
            and hold Upside, its affiliated and related entities, and any of their respective officers, directors,
            agents and employees, harmless from and against any third party claims, lawsuits, actions, proceedings or
            investigations from and against any fees, costs, sanctions, penalties, damages, losses or expenses
            (including but not limited to legal fees and costs) and third party claims incurred that we, or any of those
            parties incurs or suffers as a result of your breach of these Platform Terms, your use or access of Upside
            Platforms, any violation of any applicable law by you, or any other action, inaction or omission on your
            part relating to the Upside Platforms.</p
        >
    </div>
</div>
<div class="l-page-section l-page-section--alt">
    <div class="l-page-section__inner l-padding-ends--medium">
        <h3 class="page-subheading">7. Privacy</h3>
        <p
            ><strong>7.1</strong> As part of the process of using the Upside Platforms certain personal information will
            need to be collected, used and disclosed in order for us to provide the Upside Platforms to you. The Upside
            Privacy Policy, which is available <a href="/privacy-policy/">here</a>, applies to all Users and forms part
            of these Platform Terms. Use of the Upside Platforms and Upside Platforms confirms that you consent to, and
            authorise, the collection, use and disclosure of your personal information in accordance with the Upside
            Privacy Policy.</p
        >
        <p
            ><strong>7.2</strong> Third party service providers will provide their service pursuant to their own privacy
            policy. Prior to acceptance of any service from a third party you must review and agree to their terms of
            service, including their privacy policy.</p
        >
    </div>
</div>
<div class="l-page-section">
    <div class="l-page-section__inner l-padding-ends--medium">
        <h3 class="page-subheading">8. Intellectual property</h3>
        <p
            ><strong>8.1</strong> You acknowledge that Upside owns all right, title, and interest, including all
            intellectual property rights, in the Upside Platforms including the trade marks, trade dress, service marks,
            trade name and logos, and any content, data, software, technology and programming code, tools, or business
            methods used by Upside to develop, promote, market, sell, generate, or distribute the Upside Platforms and
            otherwise perform under these Platform Terms (collectively the ‘<strong>Upside IP</strong>’). Except as
            explicitly provided herein, nothing in these Platform Terms will be deemed to create a license, right, title
            or interest in or under any Upside IP, and you agree not to sell, license, rent, modify, distribute,
            transfer, download, display, copy, reproduce, transmit, publicly display, translate, reverse engineer,
            decompile or disassemble publicly perform, publish, adapt, edit or create derivative works from any Upside
            IP without the express written consent of Upside. Use of the Upside IP for any purpose not expressly
            permitted by these Platform Terms is strictly prohibited.</p
        >
        <p
            ><strong>8.2</strong> When you make text, illustrations, photos, audio, video, data or other material (‘User
            Content’) available on or through the Upside Platforms or any Upside promotional campaigns, you hereby:</p
        >
        <ol class="page-list page-list--alpha">
            <li>warrant that you have all necessary rights in respect of the same; and</li>
            <li
                >grant to Upside a worldwide, irrevocable, perpetual (or for the term of the protection), non-exclusive,
                transferable, royalty-free licence, with the right to sublicense, to use, view, copy, adapt, translate,
                modify, distribute, licence, sell, transfer, publicly display, publicly perform, transmit, stream,
                broadcast, access, view, and otherwise exploit such User Content on, through, by means of or to promote
                or market the Upside Platforms and Upside Platforms. Upside does not claim any ownership rights in any
                such User Content, and nothing in these Platform Terms will be deemed to restrict any rights that you
                may have to use and exploit any such User Content.</li
            >
        </ol>
        <p
            ><strong>8.3</strong> Trade marks, service marks, logos, trade names and any other proprietary designations
            of third parties used on or in connection with the Upside Platforms are used for identification purposes
            only and may be the property of their respective owners.</p
        >
        <p
            ><strong>8.4</strong> You shall not use or display the Upside IP in any way that states or implies that
            Upside has endorsed or approved your products or services. Any use of Upside IP shall comply with any Upside
            usage guidelines posted on the Upside Platforms.</p
        >
        <p
            ><strong>8.5</strong> All goodwill and improved reputation in respect of, and associated with, the Upside IP
            will vest to the sole benefit of Upside.</p
        >
        <p
            ><strong>8.6</strong> You may choose to or we may invite you to submit comments or ideas about the Upside
            Platforms, including without limitation about how to improve the Upside Platforms
            (‘<strong>Ideas</strong>’). The Ideas are not User Content as described above. Further, by submitting any
            Idea, you agree that your disclosure is gratuitous, unsolicited, without restriction and will not place
            Upside under any fiduciary or other obligation, and that we are free to use the Idea without any
            compensation to you, and/or to disclose the Idea on a non-confidential basis or otherwise to anyone. You
            further acknowledge that, by acceptance of your submission, Upside does not waive any rights to use similar
            or related ideas previously known to Upside, or developed by its employees, or obtained from sources other
            than you.</p
        >
    </div>
</div>
<div class="l-page-section l-page-section--alt">
    <div class="l-page-section__inner l-padding-ends--medium">
        <h3 class="page-subheading">9. General</h3>
        <p
            ><strong>9.1</strong> If any of the provisions set out in these Platform Terms are determined to be void or
            unenforceable then that provision must be read down to avoid that result, or if the provision cannot be read
            down then it must be severed and the rest of the Platform Terms will remain in full force and affect.</p
        >
        <p
            ><strong>9.2</strong> The failure of Upside to enforce any provision of these Platform Terms at any time or
            for any period of time will not be construed to be a waiver of such provision or of the rights of Upside to
            enforce each and every provision of these Platform Terms. A waiver of a power or right shall be ineffective
            unless in writing and executed by Upside.</p
        >
        <p
            ><strong>9.3</strong> These Platform Terms are governed by the laws of New South Wales, Australia. You and
            Upside submit to the exclusive jurisdiction of the courts of New South Wales, Australia.</p
        >
    </div>
</div>
