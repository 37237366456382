<button
    class="c-button"
    *ngIf="!upSref && !href"
    [disabled]="disabled || loading"
    [attr.type]="type"
    [ngClass]="{
        'is-disabled': disabled || loading,
        'button-anchored-icon': arrowIcon,
        'is-loading': loading,
        'c-button--large': large || size === 'large',
        'c-button--small': size === 'small',
        'c-button--full-width': fullWidth,
        'c-button--plain-dark': style === 'plain-dark',
        'c-button--plain-light': style === 'plain-light',
        'c-button--primary': style === 'primary',
        'c-button--primary-dark': style === 'primary-dark',
        'c-button--primary-positive': style === 'primary-positive',
        'c-button--secondary': style === 'secondary',
        'c-button--secondary-dark': style === 'secondary-dark',
        'c-button--secondary-light': style === 'secondary-light',
        'c-button--neutral': style === 'neutral'
    }"
    (click)="click($event)"
    ><ng-container *ngTemplateOutlet="contentTemplate"></ng-container
></button>
<a
    class="c-button"
    *ngIf="upSref"
    [ngClass]="{
        'is-disabled': disabled || loading,
        'is-loading': loading,
        'button-anchored-icon': arrowIcon,
        'c-button--large': large || size === 'large',
        'c-button--small': size === 'small',
        'c-button--full-width': fullWidth,
        'c-button--plain-dark': style === 'plain-dark',
        'c-button--plain-light': style === 'plain-light',
        'c-button--primary': style === 'primary',
        'c-button--primary-dark': style === 'primary-dark',
        'c-button--primary-positive': style === 'primary-positive',
        'c-button--secondary': style === 'secondary',
        'c-button--secondary-dark': style === 'secondary-dark',
        'c-button--secondary-light': style === 'secondary-light',
        'c-button--neutral': style === 'neutral'
    }"
    [dataEvent]="dataEvent"
    [uiSref]="!(disabled || loading) && upSref"
    [uiParams]="upSrefParams"
    [uiOptions]="upSrefOptions"
    [attr.target]="target"
    [attr.download]="download"
    ><ng-container *ngTemplateOutlet="contentTemplate"></ng-container
></a>
<a
    class="c-button"
    *ngIf="href"
    [ngClass]="{
        'is-disabled': disabled || loading,
        'is-loading': loading,
        'button-anchored-icon': arrowIcon,
        'c-button--large': large || size === 'large',
        'c-button--small': size === 'small',
        'c-button--full-width': fullWidth,
        'c-button--plain-dark': style === 'plain-dark',
        'c-button--plain-light': style === 'plain-light',
        'c-button--primary': style === 'primary',
        'c-button--primary-dark': style === 'primary-dark',
        'c-button--primary-positive': style === 'primary-positive',
        'c-button--secondary': style === 'secondary',
        'c-button--secondary-dark': style === 'secondary-dark',
        'c-button--secondary-light': style === 'secondary-light',
        'c-button--neutral': style === 'neutral'
    }"
    [href]="!(disabled || loading) && href"
    [attr.target]="target"
    [attr.download]="download"
    [attr.rel]="rel"
    ><ng-container *ngTemplateOutlet="contentTemplate"></ng-container
></a>
<ng-template #contentTemplate>
    <div *ngIf="loading" class="button-loader"> <up-spinner [size]="'small'"></up-spinner> </div>
    <div class="button-inner">
        <ng-template #iconTemplate>
            <up-icon
                [name]="arrowDirection === 'right' ? 'arrowRight' : 'arrowLeft'"
                [color]="'current-color'"
                [ngClass]="{
                    'u-margin-right--sm': arrowIcon && arrowPosition === 'left',
                    'u-margin-left--sm': arrowIcon && arrowPosition === 'right'
                }"
                class="c-button__icon"
            >
            </up-icon>
        </ng-template>
        <ng-container *ngIf="arrowIcon && arrowPosition === 'left'">
            <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
        </ng-container>
        <div class="c-button__content"> <ng-content></ng-content> </div>
        <ng-container *ngIf="arrowIcon && arrowPosition === 'right'">
            <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
        </ng-container>
    </div>
</ng-template>
