import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { FeatureKeys, FeatureListOperator, FeatureService } from '../../modules/core/services/feature.service';

/** @Deprecated - use PermissionDirective instead **/
@Directive({
    selector: '[upFeature]',
})
export class FeatureDirective implements OnInit {
    @Input() public upFeature: FeatureKeys | FeatureKeys[];
    @Input() public upFeatureWithOperator: FeatureListOperator;
    @Input() public upFeatureElse: TemplateRef<any>;
    @Input() public upFeatureInvert = false;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private featureService: FeatureService,
    ) {}

    public ngOnInit() {
        const condition = this.featureService.hasFeature(this.upFeature, this.upFeatureWithOperator);
        if (this.upFeatureInvert ? !condition : condition) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else if (this.upFeatureElse) {
            this.viewContainerRef.createEmbeddedView(this.upFeatureElse);
        }
    }
}
