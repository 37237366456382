import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GetInteractionDto } from '../../../common/models/dto/interaction/get-interaction.dto';
import { interactionApi } from '../../../common/models/interaction-api.model';

@Injectable()
export class InteractionResource {
    private readonly baseUrl = '/api/v2/interaction';

    constructor(private httpClient: HttpClient) {}

    public createInteraction(interaction: interactionApi.createInteraction.post.Request) {
        return this.httpClient.post<interactionApi.createInteraction.post.Response>(`${this.baseUrl}`, interaction);
    }

    public update(interactionId: string, body: interactionApi.update.POST.Request) {
        return this.httpClient.post<interactionApi.update.POST.Response>(`${this.baseUrl}/${interactionId}`, body);
    }

    public getActionItems() {
        return this.httpClient.get<interactionApi.ActionItems.GET.Response>(`${this.baseUrl}/action-items`);
    }

    public getCallOutcomes() {
        return this.httpClient.get<interactionApi.CallOutcomes.GET.Response>(`${this.baseUrl}/call-outcomes`);
    }

    public getById(interactionId: string, includeHistory?: boolean): Observable<GetInteractionDto> {
        return this.httpClient.get<GetInteractionDto>(`${this.baseUrl}/${interactionId}`, {
            params: {
                ...(typeof includeHistory === 'boolean' && { includeHistory: String(includeHistory) }),
            },
        });
    }
}
