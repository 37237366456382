import { Transition } from '@uirouter/angular';

import { featureGuard } from '../../../ui-router/feature-guard';
import { NoopComponent } from '../../noop/noop.component';
import { NcConversionFunnelStateDeclaration } from '../conversion.states';

import { RequestCallCompleteComponent } from './complete/request-call-complete.component';
import { RequestCallComponent } from './details/request-call.component';

export function agentResolveFn(trans: Transition): string {
    return trans.params().agent;
}

export function trackingEventResolveFn(trans: Transition): string {
    return trans.params().trackingEvent;
}

export const RequestCallStates: NcConversionFunnelStateDeclaration[] = [
    {
        parent: 'vendor',
        name: 'request-call',
        url: '/request-call',
        views: {
            'navbar@vendor-app': {
                component: NoopComponent,
            },
            'content@vendor-app': {
                component: NoopComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
        redirectTo: 'request-call.details',
        data: {
            featureGuard: featureGuard({ featureFlag: 'callbacksEnabled' }),
        },
    },
    {
        name: 'request-call.details',
        url: '/details',
        views: {
            'navbar@vendor-app': {
                component: NoopComponent,
            },
            'content@vendor-app': {
                component: RequestCallComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
        params: {
            agent: undefined,
            trackingEvent: undefined,
        },
        resolve: [
            {
                token: 'agent',
                deps: [Transition],
                resolveFn: agentResolveFn,
            },
            {
                token: 'trackingEvent',
                deps: [Transition],
                resolveFn: trackingEventResolveFn,
            },
        ],
        data: {
            meta: {
                title: 'Upside Real Estate | Request a call back',
                description:
                    'Please provide us with the details and a friendly member of the Upside team will call you back.',
            },
        },
    },
    {
        name: 'request-call.complete',
        url: '/complete',
        views: {
            'navbar@vendor-app': {
                component: NoopComponent,
            },
            'content@vendor-app': {
                component: RequestCallCompleteComponent,
            },
            'footer@vendor-app': {
                component: NoopComponent,
            },
        },
    },
];
