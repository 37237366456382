<up-isolated-container [showLogo]="true">
    <ng-container heading> Sign in </ng-container>
    <form content [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="u-padding-bottom--3xl">
        <!-- Failure state alerts -->
        <ng-container [ngSwitch]="loginState">
            <up-alert
                class="u-margin-bottom--xl"
                *ngSwitchCase="loginStateEnum.NoEmailMatch"
                [type]="'warning'"
                [size]="'small'"
            >
                The email that you've entered doesn't match any account.
            </up-alert>

            <up-alert
                class="u-margin-bottom--xl"
                *ngSwitchCase="loginStateEnum.PasswordInvalid"
                [type]="'warning'"
                [size]="'small'"
            >
                The password that you've entered is incorrect.
                <up-link [upSref]="'user.forgot-password'" [upSrefParams]="{ email: loginForm.get('email').value }">
                    Reset password?
                </up-link>
            </up-alert>

            <nc-alert
                class="u-margin-bottom--xl"
                *ngSwitchCase="loginStateEnum.LogInDisabled"
                [type]="'warning'"
                [size]="'small'"
            >
                {{ loginDisabled }}
            </nc-alert>

            <ng-container *ngSwitchCase="loginStateEnum.ConfirmEmailError">
                <ng-container *ngTemplateOutlet="genericSignInError"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="loginStateEnum.ForgotPasswordError">
                <ng-container *ngTemplateOutlet="genericSignInError"></ng-container>
            </ng-container>
        </ng-container>

        <div class="u-margin-bottom--sm">
            <up-input
                [formControlName]="'email'"
                [size]="'large'"
                [type]="'email'"
                [fullWidth]="true"
                [internalLabel]="true"
                [error]="formSubmissionAttempted && loginForm.get('email').invalid"
                [label]="'Email address'"
            >
            </up-input>
            <up-help-text [type]="'error'" *ngIf="shouldShowControlError('email')">
                Please enter a valid email address
            </up-help-text>
        </div>

        <up-password-visibility-toggle class="u-margin-bottom--md" *ngIf="loginForm.contains('password')" [@fadeIn]>
            <up-input
                [autofocus]="true"
                [formControlName]="'password'"
                [size]="'large'"
                [type]="'password'"
                [fullWidth]="true"
                [internalLabel]="true"
                [error]="formSubmissionAttempted && loginForm.get('password').invalid"
                [label]="'Password'"
            >
            </up-input>
        </up-password-visibility-toggle>

        <div class="form-controls u-margin-top--lg">
            <div class="u-text-body--sm">
                <div>
                    <a up-link [uiSref]="'user.forgot-password'" [uiParams]="{ email: loginForm.get('email').value }">
                        Can't sign in?
                    </a>
                </div>
                <div>
                    <a up-link [uiSref]="'user.signin-internal'">
                        Internal sign in
                    </a>
                </div>
            </div>
            <div class="sign-in-button-wrapper">
                <up-button [type]="'submit'" [loading]="loading" [fullWidth]="true" [style]="'primary'">
                    {{ loginState === loginStateEnum.EmailConfirmed ? 'Sign in' : 'Next' }}
                </up-button>
            </div>
        </div>
    </form>
    <ng-container *ngIf="themeName === Theme.Upside" footer> <up-auth-footer></up-auth-footer> </ng-container>
</up-isolated-container>

<ng-template #genericSignInError>
    <up-alert class="u-margin-bottom--xl" [type]="'warning'" [size]="'small'">
        Sorry, we are having trouble signing you in.
        <ng-container *upFeature="'upsideMarketingPagesEnabled'; else genericSupport"
            >Please contact us on <up-link [href]="'tel:1800877433'">1800 877 433.</up-link></ng-container
        >
        <ng-template #genericSupport>Please try again later.</ng-template>
    </up-alert>
</ng-template>
