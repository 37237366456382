import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { agentProfiles } from '../../../../../common/models/domain/agent/agent-profiles.model';

@Component({
    selector: 'up-agent-review-card',
    templateUrl: 'agent-review-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentReviewCardComponent {
    @Input() public agent: agentProfiles.Profile;
}
