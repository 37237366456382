<up-nav>
    <up-nav-bar-marketing>
        <up-nav-bar-container>
            <up-nav-bar-logo-link></up-nav-bar-logo-link>
        </up-nav-bar-container>

        <up-nav-bar-container [contentAlignment]="'end'" [grow]="true">
            <ul class="main-links-list">
                <li
                    *upFeature="'vendorPortalConfiguration.myPropertiesListEnabled'"
                    [upNavHideOnBreakpoint]="showPane ? ['min'] : []"
                >
                    <a class="main-link" up-nav-bar-button-marketing [uiSref]="'user.signin'" [dataEvent]="'navSignIn'">
                        Sign In
                    </a>
                </li>

                <ng-container *ngIf="isPropertyReportLandingState">
                    <li *ncPermission="canChangeCountryPermission" class="country-selector-item">
                        <div class="country-selector-divider"></div>
                        <nc-country-selector></nc-country-selector>
                    </li>
                </ng-container>
            </ul>

            <ng-container *ngIf="customCtaTemplate; else noCustomCta">
                <ng-container *ngTemplateOutlet="customCtaTemplate"></ng-container>
            </ng-container>
            <ng-template #noCustomCta>
                <up-nav-bar-dynamic-cta class="u-margin-left--md"></up-nav-bar-dynamic-cta>
            </ng-template>
        </up-nav-bar-container>
    </up-nav-bar-marketing>
</up-nav>
