import { Transition } from '@uirouter/angular';

import { UpStateDeclaration } from '../../common/models/router.model';
import { ConversionService } from '../../modules/core/services/conversion/conversion.service';

import { ConversionComponent } from './conversion.component';

export type NcConversionFunnelStateDeclaration = UpStateDeclaration & {
    data?: {
        globalProgress?: number;
    } & UpStateDeclaration['data'];
};

export const ConversionParentState: NcConversionFunnelStateDeclaration = {
    name: 'conversion',
    parent: 'vendor',
    abstract: true,
    views: {
        'content@vendor-app': {
            component: ConversionComponent,
        },
    },
    onExit: (transition: Transition) => {
        const conversionService = transition.injector().get<ConversionService>(ConversionService);

        conversionService.resetConversionState();
    },
};
