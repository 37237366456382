import { BuyerLocalityDto } from '../../dto/buyer/buyer-locality.dto';
import { FullBuyerLocalityDto } from '../../dto/buyer/full-buyer-locality.dto';

export namespace BuyerLocality {
    export type Model = BuyerLocalityDto;

    export function fromFullBuyerLocalityDto(dto?: FullBuyerLocalityDto): Model | undefined {
        if (dto?.address) {
            return {
                address: dto.address,
            };
        } else if (dto?.suburb?.id) {
            return {
                suburbId: dto.suburb.id,
            };
        } else {
            return undefined;
        }
    }
}
