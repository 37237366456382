import { TargetState, Transition } from '@uirouter/angular';

import { environment } from '../common/models/environment.model';
import { WindowRef } from '../common/services/window.service';
import { EnvironmentService } from '../modules/core/services/environment.service';
import { FeatureKeys, FeatureListOperator, FeatureService } from '../modules/core/services/feature.service';

type GuardDefinitionFunction = (environment: environment.config) => boolean;

export type FeatureGuardFunc = (transition: Transition) => boolean | TargetState;

interface BaseConfig {
    redirectTo?: string;
}

interface FeatureFlagConfig extends BaseConfig {
    featureFlag: FeatureKeys | FeatureKeys[];
    operator?: FeatureListOperator;
}

interface EnvironmentPredicateConfig extends BaseConfig {
    environmentPredicate: GuardDefinitionFunction;
}

function isFeatureConfig(config: FeatureFlagConfig | EnvironmentPredicateConfig): config is FeatureFlagConfig {
    return (config as FeatureFlagConfig).featureFlag !== undefined;
}

export const featureGuard = (config: FeatureFlagConfig | EnvironmentPredicateConfig): FeatureGuardFunc => (
    transition: Transition,
) => {
    const injector = transition.injector();
    const windowRef: WindowRef = injector.get(WindowRef);
    const featureService: FeatureService = injector.get(FeatureService);
    const environmentService: EnvironmentService = injector.get(EnvironmentService);
    const isInternalRoute = transition.to().name.startsWith('internal');
    const hasFeature = isFeatureConfig(config)
        ? featureService.hasFeature(config.featureFlag, config?.operator)
        : config.environmentPredicate(environmentService.config);

    if (hasFeature) return true;
    if (config?.redirectTo) return transition.router.stateService.target(config.redirectTo);
    if (isInternalRoute) return transition.router.stateService.target('internal');
    if (featureService.featureConfiguration.upsideMarketingPagesEnabled) {
        return transition.router.stateService.target('home');
    } else if (featureService.homeRouteName) {
        return transition.router.stateService.target(featureService.homeRouteName);
    } else {
        windowRef.nativeWindow.location.href = featureService.urlConfiguration.homePage;
        return false;
    }
};
