import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SuppliersApi } from '../../../common/models/suppliers-api.model';

@Injectable({ providedIn: 'root' })
export class SuppliersResource {
    private readonly baseUrl = '/api/v2';

    constructor(private httpClient: HttpClient) {}

    public getAll() {
        return this.httpClient.get<SuppliersApi.GetAll.GET.Response>(`${this.baseUrl}/supplier`);
    }

    public create(body: SuppliersApi.Create.POST.Request) {
        return this.httpClient.post<SuppliersApi.Create.POST.Response>(`${this.baseUrl}/supplier`, body);
    }

    public update(supplierId: string, supplier: SuppliersApi.Update.POST.Request) {
        return this.httpClient.post<SuppliersApi.Update.POST.Response>(
            `${this.baseUrl}/supplier/${supplierId}`,
            supplier,
        );
    }

    public getForAgent(agentId: string) {
        return this.httpClient.get<SuppliersApi.ForAgent.GET.Response>(`${this.baseUrl}/agent/${agentId}/suppliers`);
    }

    public updateForAgent(agentId: string, body: SuppliersApi.ForAgent.POST.Request) {
        return this.httpClient.post<SuppliersApi.ForAgent.POST.Response>(
            `${this.baseUrl}/agent/${agentId}/suppliers`,
            body,
        );
    }

    public getForProperty(propertyId: string) {
        return this.httpClient.get<SuppliersApi.ForProperty.GET.Response>(
            `${this.baseUrl}/property/${propertyId}/suppliers`,
        );
    }

    public updateForProperty(propertyId: string, body: SuppliersApi.ForProperty.POST.Request) {
        return this.httpClient.post<SuppliersApi.ForProperty.POST.Response>(
            `${this.baseUrl}/property/${propertyId}/suppliers`,
            body,
        );
    }
}
